import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DnsIcon from "@mui/icons-material/Dns";
import KeyIcon from "@mui/icons-material/Key";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import montymobileLogo from "../Assets/monty-mobile-logo.svg";
import { MULTI_TENANCY_CONFIG } from "../Service-Manager/APIs";
import { KEYCLOAK_MEMBERS_LOGIN } from "./Api";
import { loginConfig } from "./LoginConfig";
import "./SignIn.scss";
import axiosInstance from "../axiosInstance";
import axios from "axios";
const SignInKeyStore = (props) => {
  const t = props.t;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [environment, setEnvironment] = useState("");
  const [projectNamesOptions, setProjectNamesOptions] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenant, setTenant] = useState("");
  const [dbOptions, setDbOptions] = useState([]);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [loginKeyStore, setloginKeyStore] = useState(true);
  const [flip, setflip] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
     

      if (
        !loginKeyStore &&!flip&&
        username &&
        password &&
        tenant &&
        projectName &&
        environment
      ) {
        keyCloakLogin();
      } else if ((loginKeyStore ||flip)&&username && password) {
        keyCloakLogin();
      }
      // You can perform any action you want when the Enter key is pressed
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    username,
    password,
    environment,
    projectName,
    tenant,
    password,
    username,
    loginKeyStore,
    flip
  ]); // The empty dependency array ensures that this effect runs only once on mount

  useEffect(() => {
    localStorage.clear();

    document.body.classList.add("hide-scroll");
    localStorage.setItem("token", "");
    const { projects } = loginConfig || {};
    const mappedProjects =
      projects?.map((x) => ({
        value: x.name,
        text: x.name,
      })) || [];
    setProjectNamesOptions(mappedProjects);
    return () => {
      // 👇️ removing classes from body element
      // when the component unmounts
      document.body.classList.remove("hide-scroll");
    };
  }, [])

  useEffect(() => {
    // Check if the app has been opened before
    const hasBeenOpened = sessionStorage.getItem("appOpened");

    if (!hasBeenOpened) {
      // If not opened before, set the flag and force a reload
      sessionStorage.setItem("appOpened", "true");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("projectName", "KeyStore");
    setProjectName("KeyStore");
    localStorage.setItem("tenant", process.env.REACT_APP_BASE_TENANT);

    localStorage.setItem(
      "services",
      JSON.stringify({
        members: "/member",
        configuration: "/configuration",
        "data-seed": "/data-seed",
        "routing-management": "/routing-management",
        billing: "/billing",
        apiKey: "/apikey",
        catalog: "/catalog",
        member: "/member",
      })
    );
    localStorage.setItem(
      "baseService",
      JSON.stringify([
        {
          id: "3",
          name: "shortUrl",
          endpointName: "members",
          endpoint: "/member",
          pages: [
            {
              pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9b",
              name: "Short URL",
              icon: "link",
              path: "url-store-config",
              parent_page_name: [null],
              endpointName: "members",
              endpoint: "/member",
              hasChildren: true,
              children: [
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                  name: "URL Store",
                  icon: "link",
                  path: "url-store",
                  parentPageName: "Short URL",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9b"],
                },
                {
                  pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                  name: "URL Store Config",
                  icon: "storage",
                  path: "url-store-config",
                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9b"],
                  parentPageName: "Short URL",
                },
              ],
            },
          ],
        },

        {
          id: "4",
          name: "otp",
          endpointName: "keystore",
          endpoint: "/keystore",

          pages: [
            {
              pageId: "b78d3ff1-f5ef-4673-9706-ec8a823b7aaf",
              name: "OTP",
              icon: "key",
              path: "otp-store-config",
              parent_page_name: [null],
              hasChildren: true,
              children: [
                {
                  parentPageName: "OTP",
                  pageId: "0b00cb11-b456-486a-b684-3f5f011f6e60",
                  name: "OTP Store Config",
                  path: "otp-store-config",
                  icon: "storage",
                  parent_page_name: ["b78d3ff1-f5ef-4673-9706-ec8a823b7aaf"],
                },
                {
                  pageId: "8c8146d3-5fda-43ee-b2a2-9dd71c82ae3d",
                  name: "Pin",
                  icon: "room",
                  path: "pin",
                  parentPageName: "OTP",
                  parent_page_name: ["b78d3ff1-f5ef-4673-9706-ec8a823b7aaf"],
                },
              ],
            },
            // },
          ],
        },
      ])
    );
    localStorage.setItem(
      "baseUrl",
      "https://mm-omni-api-software-dev.montylocal.net"
    );
    localStorage.setItem(
      "config",
      JSON.stringify({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          Authorization: "bearer ",
          "Content-Type": "application/json",
          Tenant: process.env.REACT_APP_BASE_TENANT,
        },
      })
    );
    localStorage.setItem("envName", "Software Dev");
  }, []);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getEnvironment = (projectName) => {
    let objectOfProject = loginConfig?.projects?.find((x) => {
      return x.name === projectName;
    });

    if (objectOfProject) {
      setDbOptions(
        objectOfProject?.enviroments?.map((x) => ({
          text: x.name,
          value: x.name,
        }))
      );

      // Set tenant options based on the selected environment
      setTenantOptions([]);
      if (
        objectOfProject?.enviroments &&
        objectOfProject?.enviroments.length > 0
      ) {
        const selectedEnvironment = objectOfProject.enviroments.find(
          (env) => env.name === environment
        );

        if (selectedEnvironment) {
          setTenantOptions(
            selectedEnvironment.tenants.map((tenant) => ({
              text: tenant,
              value: tenant,
            }))
          );
        }
      }
    }

    if (!projectName || projectName.length === 0) {
      setDbOptions([]);
      setTenantOptions([]);
    }
  };

  const getServices = (enviroment) => {
    let objectOfProject = loginConfig?.projects?.find(
      (x) => x.name === projectName
    );
    let objectOfEnv = {};
    objectOfEnv = objectOfProject?.enviroments?.find(
      (x) => x.name === enviroment
    );
    let transformedObject = {};
    objectOfEnv?.services?.forEach((item) => {
      transformedObject[item.endpointName] = item.endpoint;
    });
    objectOfEnv?.services?.forEach((endpoint) => {
      const endpointName = endpoint.endpointName;
      const pages = endpoint.pages;

      pages.forEach((page) => {
        if (page.endpoint && page.endpointName) {
          transformedObject[page.endpointName] = page.endpoint;
        }
      });
    });
    objectOfEnv?.services?.forEach((item) => {
      transformedObject[item.endpointName] = item.endpoint;
    });

    localStorage.setItem("services", JSON.stringify(transformedObject));
    localStorage.setItem("baseService", JSON.stringify(objectOfEnv.services));
    localStorage.setItem("baseUrl", objectOfEnv.baseUrl);
    localStorage.setItem(
      "config",
      JSON.stringify({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          Authorization: "bearer ",
          "Content-Type": "application/json",
          Tenant: tenant,
        },
      })
    );

    // dispatch(setServices(transformedObject));
    // dispatch(setBaseService(objectOfEnv.services));
    // dispatch(setBaseUrl(objectOfEnv.baseUrl));
    // dispatch(
    //   setConfig({
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //       "Access-Control-Allow-Headers":
    //         "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //       Authorization: "bearer ",
    //       "Content-Type": "application/json",
    //       Tenant: tenant,
    //     },
    //   })
    // );
    // this.setState({
    //   tenantOptions: objectOfEnv?.tenants.map((x) => ({ text: x, value: x })),
    // });

    // objectOfEnv?.services?.forEach((endpoint) => {
    //   const endpointName = endpoint.endpointName;
    //   const pages = endpoint.pages;

    //   pages.forEach((page) => {
    //     if (page.endpoint && page.endpointName) {
    //       transformedObject[page.endpointName] = page.endpoint;
    //     }
    //   });
    // });
    // objectOfEnv?.services?.forEach((item) => {
    //   transformedObject[item.endpointName] = item.endpoint;
    // });
    // localStorage.setItem("services", JSON.stringify(transformedObject));
    // localStorage.setItem("baseService", JSON.stringify(objectOfEnv.services));
    // // localStorage.setItem("loginConfig", objectOfEnv.tenant);
    // localStorage.setItem("baseUrl", objectOfEnv.baseUrl);

    // localStorage.setItem(
    //   "config",
    //   JSON.stringify({
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //       "Access-Control-Allow-Headers":
    //         "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //       Authorization: "bearer ",
    //       "Content-Type": "application/json",
    //       Tenant: this.state.tenant[0],
    //     },
    //   })
    // );
  };
  const keyCloakLogin = () => {
    let newServices = JSON.parse(localStorage.getItem("services"));
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setLoading(true);

    const body = {
      Username: username,
      Password: password,
    };

    axios
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${KEYCLOAK_MEMBERS_LOGIN}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        // navigate(
        //   JSON.parse(localStorage.getItem("baseService")) &&
        //     JSON.parse(localStorage.getItem("baseService"))[0]?.pages[0]?.path,
        //   { replace: true }
        // );
        navigate("/url-store", { replace: true });
        // navigate("/api-key-service",{ replace: true });

        localStorage.setItem("token", res?.data?.data?.accessToken);
        localStorage.setItem("withoutBack", "null");
        localStorage.setItem("refreshToken", res?.data?.data?.refreshToken);

        // dispatch(setPages(loginConfig));
        // dispatch(setEnvirement(environment));
        // dispatch(setNameOfProject(projectName));
        // dispatch(setTenantOfProject(tenant));

        // signIn(res?.data?.data);
        // redirectBasedOnPath();
      })
      .catch((err) => {
        if (err?.response?.data?.Exception) {
          setErrMessage(err?.response?.data?.Exception);
        } else if (err?.response?.data?.message) {
          setErrMessage(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className="sign-in">
      <Box className="center">
        <img src={montymobileLogo} alt={"logo"} className="logo-style" />
      </Box>
      <Container>
        <Card className="original-card center">
          <Grid container>
            {/* <Grid item xs={6}>
              <Typography variant="h5" align="center">
                {loginKeyStore ? "Sign In to Service Manager Portal?" : ""}
              </Typography>
              <Grid container className="center">
                {loginKeyStore && (
                  <Button
                    variant="contained"
                    className="sign-card-btn"
                    onClick={() => {
                      setloginKeyStore(false);
                      setusername("");
                      setpassword("");
                      setErrMessage("");
                    }}
                  >
                    {t("SIGN IN")}
                  </Button>
                )}
              </Grid>
            </Grid> */}
            <Grid item xs={11}>
              <Typography variant="h5" align="center">
                {!loginKeyStore ? "Sign In to KeyStore-Channel?" : ""}
              </Typography>
              <Grid container className="center">
                {!loginKeyStore && (
                  <Button
                    variant="contained"
                    className="contained"
                    onClick={() => {
                      setloginKeyStore(true);
                      setusername("");
                      setpassword("");
                      setErrMessage("");
                    }}
                  >
                    {t("SIGN IN")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Container>

      <Box marginTop={10}>
        <Paper
          elevation={1}
          className={
            !loginKeyStore && !flip
              ? "paper flip-paper-main"
              : loginKeyStore
              ? "transition-paper-omni"
              : !loginKeyStore && flip
              ? "flip-paper paper"
              : ""
          }
        >
          <Box className={!flip ? "content-box" : "content-box-flip"}>
            <Box className="center" paddingTop={2}>
              <Typography
                variant="h6"
                align="left"
                paddingBottom={loginKeyStore ? 10 : 2}
              >
                {"Sign In to KeyStore-Channel"}
              </Typography>
            </Box>
            <Box
              className="center"
              paddingTop={0}
              paddingBottom={0}
              color={"red"}
            >
              <Typography variant="h7" align="center">
                {errMessage ? errMessage : ""}
              </Typography>
            </Box>
            <Box className="center">
              <TextField
                className="login-inputs"
                id="input-with-icon-textfield"
                placeholder={t("Username")}
                value={username}
                onChange={(e) => setusername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Box>

            <Box className="center" marginTop={3}>
              <TextField
                className="login-inputs"
                id="input-with-icon-textfield"
                placeholder={t("Password")}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpenIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        style={{ marginRight: "10px" }}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Box>
            {!loginKeyStore && !flip && (
              <>
                <Box className="center">
                  <Grid container>
                    <Select
                      value={projectName}
                      onChange={(e) => {
                        setProjectName(e.target.value);
                        localStorage.setItem("projectName", e.target.value);
                      }}
                      displayEmpty
                      variant="standard"
                      className="login-select custom-select"
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountTreeIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="" disabled>
                        {t("Select Project Name")}
                      </MenuItem>
                      {projectNamesOptions.map((project, index) => (
                        <MenuItem key={index} value={project.value}>
                          {project.text}
                        </MenuItem>
                      ))}
                    </Select>

                    <Select
                      value={environment}
                      onChange={(e) => {
                        setEnvironment(e.target.value);
                        getServices(e.target.value);
                        localStorage.setItem("envName", e.target.value);
                      }}
                      displayEmpty
                      disabled={!projectName}
                      variant="standard"
                      className="login-select-small custom-select"
                      onOpen={() => getEnvironment(projectName)}
                      startAdornment={
                        <InputAdornment position="start">
                          <DnsIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="" disabled>
                        {t("Select Environment")}
                      </MenuItem>
                      {dbOptions.map((project, index) => (
                        <MenuItem key={index} value={project?.value}>
                          {project?.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Box>

                <Box className="center">
                  <Select
                    value={tenant}
                    onChange={(e) => {
                      setTenant(`${e.target.value}`);
                      localStorage.setItem("tenant", e.target.value);
                      localStorage.setItem(
                        "config",
                        JSON.stringify({
                          headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                              "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                            "Access-Control-Allow-Headers":
                              "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                            Authorization: "bearer ",
                            "Content-Type": "application/json",
                            Tenant: e.target.value,
                          },
                        })
                      );
                    }}
                    displayEmpty
                    variant="standard"
                    className="login-select-big custom-select"
                    onOpen={() => getEnvironment(projectName)}
                    disabled={!projectName}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return "Select Tenant";
                      } else {
                        return `${selected?.substring(0, 25)}...`;
                      }
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      {t("Select Tenant")}
                    </MenuItem>
                    {tenantOptions.map((project, index) => (
                      <MenuItem key={index} value={project?.value}>
                        {project?.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
            <Box paddingBottom={5}>
              <Button
                onClick={keyCloakLogin}
                variant="contained"
                className="signin-btn"
                disabled={
                  !loginKeyStore && !flip
                    ? !username ||
                      !password ||
                      !tenant ||
                      !projectName ||
                      !environment
                    : !username || !password
                }
              >
                {t("SIGN IN")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default withTranslation("translations")(SignInKeyStore);
