import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: [],
  language: [],
  direction: {},
  channels: [],
  services:[],
};

const SystemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      return {
        ...state,
        currency: action.payload
      };
    },
    setLanguage: (state, action) => {
      return {
        ...state,
        language: action.payload
      };
    },
    setDirection: (state, action) => {
      return {
        ...state,
        direction: action.payload
      };
    },
    setChannels: (state, action) => {
      return {
        ...state,
        channels: action.payload
      };
    },
    setServices: (state, action) => {
      return {
        ...state,
        services: action.payload
      };
    },
  },
});

export const { setCurrency, setLanguage, setDirection, setChannels, setServices } = SystemSlice.actions;
export default SystemSlice.reducer;