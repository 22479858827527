import React from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import Table from "../../../Components/DynamicTable/Table";

const HistoryModal = ({ open, setOpen, title, onButtonClick, listHistory }) => {
  const tableDataAccounts = [
    {
      id: 1,
      startDate: "9/9/2023",
      endDate: "11/11/2023",
    },
    {
      id: 2,
      startDate: "8/8/2023",
      endDate: "12/12/2023",
    },
  ];
  let columnsAccounts = [
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      size="sm"
      //   firstActionBtnName={"Save"}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Table
            rowCountState={tableDataAccounts?.totalCount}
            data={tableDataAccounts}
            // isLoading={isLoading}
            columns={columnsAccounts}
            pageIndex={
              tableDataAccounts?.pageIndex ? tableDataAccounts?.pageIndex : 0
            }
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            disableHeaderColor
            disableActions
          />
        </>
      }
    />
  );
};

export default HistoryModal;
