import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Typography
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import ManagePin from "./ManagePin";
import { Add } from '@mui/icons-material';
const Pin = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  // Pin
  const [isModalOpenSwitchPin, setModalOpenPin] = useState(false);
  const [isCheckedSwitchPin, setCheckedSwitchPin] = useState(false);
  const [switchStatesPin, setswitchStatesPin] = useState({});
  const handleSwitchChangeSwitchPin = (rowId) => {
    setModalOpenPin(true);
    setCheckedSwitchPin(rowId);
  };

  const handleModalCloseSwitchPin = () => {
    setModalOpenPin(false);
  };
  const handleModalConfirmSwitchPin = () => {
    setModalOpenPin(false);
    // setCheckedSwitchPin(!isCheckedSwitchPin);
    setswitchStatesPin((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchPin]: !prevStates[isCheckedSwitchPin],
    }));
  };

  //

 

  const [Client, setClient] = React.useState("9mobile");

  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [Category, setCategory] = React.useState("9mobile");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const tableDataPin = [
  ];
  let columnsPin = [
    {
        field: "code",
        headerName: " Code",
        flex: 1,
      },
    {
      field: "client",
      headerName: " Client",
      flex: 1,
    },
    {
        field: "createdDate",
        headerName: " Created Date",
        flex: 1,
      },
    {
      field: "TimeToLive",
      headerName: "Time To Live",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesPin[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchPin(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  // Delete Modal Pin functions
  const [ConfirmOpenPin, setConfirmOpenPin] = useState(false);
  const [modalContentPin, setmodalContentPin] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalPin = (title, text, onButtonClick) => {
    setmodalContentPin({ title, text, onButtonClick });
    setConfirmOpenPin(true);
  };

  const handleConfirmModalPin = () => {
    setConfirmOpenPin(false);
  };

  //
  // Manage Routing
  const [ManagePinAddopen, setManagePinAddopen] = useState(false);
  const [ManagePinEditopen, setManagePinEditopen] = useState(false);

  const handleAddManagePinopen = () => {
    setManagePinAddopen(true);
  };

  const handleAddMangePinclose = () => {
    setManagePinAddopen(false);
  };
  const handleEditManagePinopen = () => {
    setManagePinEditopen(true);
  };

  const handleEditMangePinclose = () => {
    setManagePinEditopen(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <Typography className="BreadcrumbsPage">Pin</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddManagePinopen}
            >
              <Add sx={{ fontSize: "17px"}}  /> &nbsp;
              <Typography sx={{ fontSize: "14px",marginRight: "3px"  }}>Request Pin</Typography>
            </Button>
        </Grid>
      </Grid>
      <Grid container className="Top-spacing div-table">
        <Grid item xs={12}>
            <Paper variant="CardTable" sx={{borderTopLeftRadius:'20px'}}>
              <Table
                rowCountState={tableDataPin?.totalCount}
                data={tableDataPin}
                // isLoading={isLoading}
                columns={columnsPin}
                pageIndex={
                  tableDataPin?.pageIndex ? tableDataPin?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalPin}
                editFunction={handleEditManagePinopen}
                enableSearch={true}
                enableExportBtn={true}
                valueFilter={Client}
                setValueFilter={setClient}
                optionsFilter={["9mobile", "hassan", "test2"]}
                // placeholderFilter="Client"
                enableFilter={true}
                // enableFilterBtn={true}
              />
          </Paper>
        </Grid>
      </Grid>

      {/* Delete Pin Modal */}
      {handleConfirmModalPin && (
        <ConfirmationModal
          open={ConfirmOpenPin}
          onClose={handleConfirmModalPin}
          title="Delete"
          text="Are you sure you want to delete this Pin?"
          onButtonClick={handleConfirmModalPin}
        />
      )}
     

      {/* Manage Pin Modal */}
      {handleAddManagePinopen && (
        <ManagePin
          open={ManagePinAddopen}
          setOpen={setManagePinAddopen}
          title={"Add Request Pin"}
        />
      )}
      {handleEditManagePinopen && (
        <ManagePin
          open={ManagePinEditopen}
          setOpen={setManagePinEditopen}
          title={"Edit Request Pin "}
        />
      )}

      {/* Swtich Pin Modal */}
      {isModalOpenSwitchPin && (
        <ConfirmationModal
          open={isModalOpenSwitchPin}
          onClose={handleModalCloseSwitchPin}
          title="Change Status"
          text="Are you sure you want to change this Pin Status?"
          onButtonClick={handleModalConfirmSwitchPin}
        />
      )}
      <div>
      </div>
    </div>
  );
};

export default Pin;
