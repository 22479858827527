import ChatIcon from "@mui/icons-material/Chat";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import SmsIcon from "@mui/icons-material/Sms";
import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HorizantalTabs from "../../../Components/Tabs/ChannelTabs";
import MnpHlr from "./MnpHlr/MnpHlr";
import SMSOneWayRoutes from "./SmsOneWay/SmsOneWay";
import SMSTwoWayRoutes from "./SmsTwoWay/SMSTwoWayRoutes";
import { GET_ALL_SERVICES, MULTI_TENANCY_CONFIG } from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
function RoutesServices() {
  const [activeBtn, setActiveBtn] = useState("Sms");
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [allServices, setAllServices] = useState([]);
  const tabs = [
    {
      label: "Sms",
      icon: <SmsIcon className="chanel-icons" />,
    },
    {
      label: "Sms Two Way",
      icon: <ChatIcon className="chanel-icons" />,
    },
    {
      label: "MNP HLR",
      icon: <PodcastsIcon className="chanel-icons" />,
      //  disabled: true
    },
  ];
  const getAllServices = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    // setIsLoading(true);
    console.log(
      "ASfasfasfasfasfa",
      `${localStorage.getItem("baseUrl")}${GET_ALL_SERVICES}`
    );
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${GET_ALL_SERVICES}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setAllServices(res?.data?.data?.items);
        // setClientCategoryOptionsSearch([
        //   ...res?.data?.data?.clientCategories?.map((x, i) => ({
        //     label: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //   })),
        // ]);
        // setClientCategorySearch(
        //   res?.data?.data?.clientCategories
        //     ? res?.data?.data?.clientCategories[0]?.recordGuid
        //     : ""
        // );
        // setIsLoading(false);
        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllServices();
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const isLargeScreen = window.innerWidth > 729;
  const handleTabChange = (newTabLabel) => {
    setActiveBtn(newTabLabel);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const renderLargeScreenLayout = () => (
    <Box>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Routing</Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={8} xl={8}>
          <HorizantalTabs tabs={tabs} onTabChange={handleTabChange} />
        </Grid>
        <Grid item sx={12} md={12} lg={4} xl={4} className="end"></Grid>
      </Grid>
      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={12}>
          {activeBtn === "Sms" && (
            <SMSOneWayRoutes
              serviceGuid={
                allServices.find((x) => x?.tag === "ONE_WAY_SMS")?.recordGuid
              }
            />
          )}
          {activeBtn === "Sms Two Way" && (
            <SMSTwoWayRoutes
              serviceGuid={
                allServices.find((x) => x?.tag === "TWO_WAY_SMS")?.recordGuid
              }
            />
          )}
          {activeBtn === "MNP HLR" && (
            <MnpHlr
              serviceGuid={
                allServices.find((x) => x?.tag === "NUMBER_LOOKUP")?.recordGuid
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );

  const renderSmallScreenLayout = () => (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Routing</Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={8}
          xs={6}
          className={isXsScreen ? "" : "end"}
          sx={{
            mt: isXsScreen ? 2 : 0,
            order: isXsScreen ? 1 : 2,
          }}
        ></Grid>
      </Grid>
      <FormControl className="select-hover">
        <Select
          labelId="demo-simple-select-standard"
          id="demo-simple-select"
          value=""
          className="custom-select"
          variant="standard"
          displayEmpty
        >
          <MenuItem value="" disabled>
            {activeBtn}
          </MenuItem>
          <MenuItem>
            <VerticalTabs
              activeBtn={activeBtn}
              tabs={tabs}
              windowWidth={windowWidth}
              setActiveBtn={setActiveBtn}
            />
          </MenuItem>
        </Select>
      </FormControl>
      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={12}>
          {activeBtn === "Sms" && (
            <SMSOneWayRoutes
              serviceGuid={
                allServices.find((x) => x?.tag === "ONE_WAY_SMS")?.recordGuid
              }
            />
          )}
          {activeBtn === "Sms Two Way" && (
            <SMSTwoWayRoutes
              serviceGuid={
                allServices.find((x) => x?.tag === "TWO_WAY_SMS")?.recordGuid
              }
            />
          )}
          {activeBtn === "MNP HLR" && (
            <MnpHlr
              serviceGuid={
                allServices.find((x) => x?.tag === "NUMBER_LOOKUP")?.recordGuid
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );

  useEffect(() => {
    console.log(activeBtn);
    getAllServices();
  }, [activeBtn]);

  return (
    <Box>
      {windowWidth > 1499
        ? renderLargeScreenLayout()
        : renderSmallScreenLayout()}
    </Box>
  );
}
export default RoutesServices;
