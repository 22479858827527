import { Add } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../../../Components/DynamicTable/Table";
import axiosInstance from "../../../axiosInstance";
import {
  CHANGE_STATUS_CLIENT,
  EXPORT_CLIENTS_CSV,
  GET_ALL_CLIENTS,
  GET_ALL_RESELLERS,
  GET_PIN,
  MULTI_TENANCY_CONFIG,
  RESET_PASSWORD,
} from "../../APIs";
import { useNavigate, useParams } from "react-router-dom";
import { handleMessageError } from "../../../Utils/functions";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import PasswordInput from "../../../Components/TextFields/PasswordInput";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";

function ManageResellers() {
  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [tableDataAccounts, setTableDataAccounts] = useState({});

  const [clientsPaginationModel, clientSetPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [clientTableDataAccounts, clientSetTableDataAccounts] = useState({});

  const [loading, setLoading] = useState(false);

  const [viewClientsOpen, setViewClientsOpen] = useState(false);
  const [resellerStatusModalOpen, setResellerStatusModalOpen] = useState(false);
  const [resetPassOpen,setResetPassOpen] = useState(false);

  const [newPass,setNewPass] = useState("");
  const [usernameLogin, setUsernameLogin] = useState("");
  const [pin, setPin] = useState("");
  const [isLoadingSentMail, setIsLoadingSentMail] = useState(false);
 
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [oldStatus, setOldStatus] = useState(false);

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  const [search, setSearch] = useState(null);

  let columnsAccounts = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {(params?.row?.firstName ?? "") +
              " " +
              (params?.row?.lastName ?? "")}
          </>
        );
      },
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const rowId = params?.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.row?.isActive}
                  onChange={() => {
                    setSelectedReseller(rowId);
                    setResellerStatusModalOpen(true);
                    setOldStatus(params?.value);
                  }}
                />
              }
            />
          </Box>
        );
      },
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };

  const updateResellerStatus = async () => {
    setLoading(true);
    try {
      let service = JSON.parse(localStorage.getItem("services"));

      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let response = await axiosInstance.put(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${CHANGE_STATUS_CLIENT}`,
        {
          ClientGuid: selectedReseller,
          IsEnabled: !oldStatus,
        },
        MULTI_TENANCY_CONFIG
      );

      setResellerStatusModalOpen(false);
      setSelectedReseller(null);
      setOldStatus(false);
      setPaginationModel({
        ...paginationModel,
      });
    } catch (e) {
      console.log(e);
      setOpenMessageAlert(true);
      setMessageData({
        message: handleMessageError({ e, type: "validation" }),
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getAllResellers = async () => {
    setLoading(true);
    try {
      let service = JSON.parse(localStorage.getItem("services"));

      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let resellersResponse = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${GET_ALL_RESELLERS}?PageIndex=${
          parseInt(paginationModel?.page) + 1
        }&PageSize=${paginationModel?.pageSize}&TypeTag=RESELLER${
          search ? "&search=" + encodeURIComponent(search) : ""
        }`,
        MULTI_TENANCY_CONFIG
      );

      setTableDataAccounts({
        data: resellersResponse?.data?.data?.clients.map((item) => ({
          ...item,
        })),
        totalCount: resellersResponse?.data?.data?.totalRows,
        pageIndex: resellersResponse?.data?.data?.pageIndex,
      });
    } catch (e) {
      console.log(e);
      setOpenMessageAlert(true);
      setMessageData({
        message: handleMessageError({ e, type: "validation" }),
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getClientResellers = async () => {
    setLoading(true);
    try {
      let service = JSON.parse(localStorage.getItem("services"));

      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${GET_ALL_CLIENTS}?PageIndex=${
          parseInt(clientsPaginationModel?.page) + 1
        }&PageSize=${
          clientsPaginationModel?.pageSize
        }&ParentId=${selectedReseller}`,
        MULTI_TENANCY_CONFIG
      );

      clientSetTableDataAccounts({
        data: response?.data?.data?.clients.map((item) => ({
          ...item,
        })),
        totalCount: response?.data?.data?.totalRows,
        pageIndex: response?.data?.data?.pageIndex,
      });
    } catch (e) {
      console.log(e);
      setOpenMessageAlert(true);
      setMessageData({
        message: handleMessageError({ e, type: "validation" }),
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const exportResellers = () => {
    setLoading(true);
    let service = JSON.parse(localStorage.getItem("services"));

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${EXPORT_CLIENTS_CSV}?TypeTag=RESELLER${
          search ? "&search=" + encodeURIComponent(search) : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Resellers.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err === "error: 401") {
        }
        // toast.error("Something Went Wrong");
        setOpenMessageAlert(true);
        setMessageData({
          message: handleMessageError({ err, type: "validation" }),
          type: "error",
        });
      })
      .finally(() => {
        // this.setState({ loading: false });
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllResellers();
  }, [paginationModel]);

  useEffect(() => {
    if (selectedReseller) getClientResellers();
  }, [clientsPaginationModel]);

  const handleGetPin = (data) => {
    setIsLoadingSentMail(true);
    setUsernameLogin(data?.username);
    let newServices = JSON.parse(localStorage.getItem("services"));
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let dataForm = {
      UserName: data?.username,
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]}${GET_PIN}`,
        dataForm,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setResetPassOpen(true);
        setIsLoadingSentMail(false);
        setPin("");
        setNewPass("");

        // setGoToConfigPage(true);
        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((error) => {
        console.log(error)
        setIsLoadingSentMail(false);

        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const submitResetPass = () => {
    let newServices = JSON.parse(localStorage.getItem("services"));
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      UserName: usernameLogin,
      Pin: pin,
      NewPassword: newPass,
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${RESET_PASSWORD}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // setGoToConfigPage(true);
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully reseted !", type: "success" });
        setResetPassOpen(false);
        setUsernameLogin("");
        setPin("");
        setNewPass("");
        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((error) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
          type: "error",
        });
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">
              Management
            </Typography>
            <Typography className="breadcrumbactiveBtn">Resellers</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
          <Button
            variant="contained"
            className="contained"
            onClick={() => {
              navigate("/add-reseller");
            }}
          >
            <Add sx={{ fontSize: "17px" }} /> &nbsp;
            <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
              Add Reseller
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container className="Top-spacing div-table">
        <Grid item xs={12}>
          <Card variant="CardTable">
            <Table
              rowCountState={tableDataAccounts?.totalCount}
              data={tableDataAccounts?.data}
              largeActions={true}
              isLoading={loading}
              columns={columnsAccounts}
              pageIndex={
                tableDataAccounts?.pageIndex ? tableDataAccounts?.pageIndex : 0
              }
              setPaginationModel={setPaginationModel}
              paginationModel={paginationModel}

              enableExportBtn={true}
              exportFunction={() => {
                exportResellers();
              }}

              enableManageConfig={true}
              manageConfigFunction={(data) => { 
                navigate("/reseller-config/" + data?.recordGuid);
              }}

              enableEdit={true}
              editFunction={(data)=>{
                navigate("/reseller-details/" + data?.recordGuid)
              }}

              enableResetPassword={true}
              resetPasswordFunction={handleGetPin}

              enableSearch={true}
              setSearch={(val) => {
                setSearch(val);
                setPaginationModel({
                  ...paginationModel,
                  page: 0,
                });
              }}

              enableViewClients={true}
              viewClientsFunction={(data) => {
                setSelectedReseller(data?.recordGuid);
                setViewClientsOpen(true);
                clientSetPaginationModel({
                  ...clientsPaginationModel,
                  page: 0,
                });
              }}
            />
          </Card>
        </Grid>
      </Grid>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoadingSentMail}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CustomizedDialogs
        open={viewClientsOpen}
        size={"lg"}
        setOpen={() => {
          setViewClientsOpen(false);
          clientSetTableDataAccounts({});
        }}
        title={"Clients"}
        content={
          <Table
            disableHeaderColor={true}
            disableActions={true}
            rowCountState={clientTableDataAccounts?.totalCount}
            data={clientTableDataAccounts?.data}
            isLoading={loading}
            columns={[
              {
                field: "name",
                headerName: "Name",
                minWidth: 100,
                flex: 1,
              },
              {
                field: "username",
                headerName: "Username",
                flex: 1,
              },
              {
                field: "email",
                headerName: "Email",
                flex: 1,
              },
            ]}
            pageIndex={
              clientTableDataAccounts?.pageIndex
                ? clientTableDataAccounts?.pageIndex
                : 0
            }
            setPaginationModel={clientSetPaginationModel}
            paginationModel={clientsPaginationModel}
          />
        }
      ></CustomizedDialogs>

      <ConfirmationModal
        open={resellerStatusModalOpen}
        onClose={() => {
          setResellerStatusModalOpen(false);
          setSelectedReseller(null);
        }}
        title="Change Status"
        text="Are you sure you want to change this Reseller's Status?"
        onButtonClick={updateResellerStatus}
      />

      <CustomizedDialogs
      open={resetPassOpen}
      setOpen={setResetPassOpen}
      title={"Reset Password"}
      secondActionBtnName={"Save"}
      // secondActionBtn={() => setGoToConfigPage(true)}
      secondActionBtn={() => {
        submitResetPass();

        // setGoToConfigPage(true);
        // setShowPopup(false);
      }}
      firstActionBtnName={"Cancel"}
      firstActionBtnFct={() => {
        setResetPassOpen(false);
        setUsernameLogin("");
        setPin("");
        setNewPass("");
      }}
      // disableFirstBtn={
      //   !isEdit
      //     ? Country === "" || Operator === "" || Name === ""
      //     : Name === ""
      // }
      content={
        <div>
          <Typography
            style={{
              fontSize: "14px",
              color: "red",
              textAlign: "center",
            }}
          >
            A PIN has been sent to your email
          </Typography>
          <TextFieldForm
            placeholder={"Pin"}
            value={pin}
            setValue={setPin}
            positiveNumber={true}
          />
          <PasswordInput
            placeholder={"New Password"}
            value={newPass}
            setValue={setNewPass}
          />
        </div>
      }
    />
    

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ManageResellers;
