import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import {
  MANAGE_CITY,
  MANAGE_CURRENCIES,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

const ManageCurrencies = ({
  open,
  setOpen,
  title,
  isEdit,
  selectedCurrencies,
  CountryOptions,
  setRefreshTableCity,
  setPaginationModalCurrencies,
  paginationModalCurrencies,
}) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [symbol, setSymbol] = useState("");
  const [currencyEnum, setCurrencyEnum] = useState("");

  const [Country, setCountry] = useState("");
  const [switchState, setSwitchState] = useState(false);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [services, setServices] = useState(localStorage.getItem("services"));

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };

  useEffect(() => {
    {
      !isEdit && setName("");
      setCountry("");
      setCode("");
      setSymbol("");
      setCurrencyEnum("");
    }
  }, [open]);
  useEffect(() => {
    {
      isEdit && setName(selectedCurrencies?.name);
      setCountry(selectedCurrencies?.country);
      setCode(selectedCurrencies?.code);
      setSymbol(selectedCurrencies?.symbol);
      setCurrencyEnum(isEdit&&selectedCurrencies?.currencyEnum);
    }
  }, [open, isEdit]);

  useEffect(() => {
    {
      !selectedCurrencies && setName("");
      setCountry("");
      setCode("");
      setSymbol("");
      setCurrencyEnum("");
    }
  }, [selectedCurrencies]);
  useEffect(() => {
    if (isEdit && selectedCurrencies) {
      setName(selectedCurrencies?.name);
      setCountry(selectedCurrencies?.country);
      setCode(selectedCurrencies?.code);
      setSymbol(selectedCurrencies?.symbol);
      setCurrencyEnum(isEdit&&selectedCurrencies?.currencyEnum);
    }
  }, [selectedCurrencies, open,isEdit]);
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      code: code,
      name: name,
      symbol: symbol,
      // countryGuid: Country,
      currencyEnum: currencyEnum,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CURRENCIES}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });
        setPaginationModalCurrencies({
          page: 0,
          pageSize: paginationModalCurrencies?.pageSize
            ? paginationModalCurrencies?.pageSize
            : 10,
        });
        setOpen(false);
        setName("");
        setCountry("");
        setCode("");
        setSymbol("");
        setCurrencyEnum("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Name: name,
      RecordGuid: selectedCurrencies?.recordGuid,
      Symbol: symbol,
      Code: code,
      currencyEnum: currencyEnum,

    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CURRENCIES}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });
        setPaginationModalCurrencies({
          page: 0,
          pageSize: paginationModalCurrencies?.pageSize
            ? paginationModalCurrencies?.pageSize
            : 10,
        });
        setOpen(false);
        setName("");
        setCountry("");
        setCode("");
        setSymbol("");
        setCurrencyEnum("");

        // setSmsOneWaySearch("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitEdit();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={
          !isEdit
            ? name === "" ||
              Country === "" ||
              code === "" ||
              symbol === "" ||
              currencyEnum === ""
            : name === "" || code === "" || symbol === ""|| currencyEnum === ""
        }
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Code"}
                  value={code}
                  setValue={setCode}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Symbol"}
                  value={symbol}
                  setValue={setSymbol}
                />
              </Grid>
            </Grid>
            {console.log("currencyEnumcurrencyEnum",currencyEnum)}
            <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Currency Enum"}
                      value={currencyEnum}
                      setValue={setCurrencyEnum}
                      positiveNumber={true}
                      type="number"
                    />
                  </Grid>
                </Grid>
            {/* <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Status</span>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={switchState ? "Active " : "Inactive "}
                />
              </Grid>
            </Grid> */}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageCurrencies;
