import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/DescriptionInput";
  
  const ManageRequest = ({ open, setOpen, title, onButtonClick }) => {
    const [LongURL, setLongURL] = useState("");

    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Long URL"}
                  value={LongURL}
                  setValue={setLongURL}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageRequest;
  