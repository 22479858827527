import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import { Provider } from "react-redux";
  
  const ManageService = ({ open, setOpen, title, onButtonClick }) => {
    const [Tag, setTag] =useState("");
    const [TagOptions, setTagOptions] = useState(["tag 1","tag 2","tag 3","tag 4"]);
    const [TenantID, setTenantID] =useState("");
    const [TenantIDOptions, setTenantIDOptions] = useState(["Tenant 1","Tenant 2","Tenant 3","Tenant 4"]);
    const [Currency, setCurrency] =useState("");
    const [CurrencyOptions, setCurrencyOptions] = useState(["Dollar","Euro"]);
    const [ServiceCategory, setServiceCategory] = useState("");
    const [Provider, setProvider] =useState("");
    const [ProviderOptions, setProviderOptions] = useState(["Provider 1","Provider 2"]);
    const [ExternalID, setExternalID] = useState("");
    const [Quota, setQuota] = useState("");
    const [Price, setPrice] = useState("");
    const [ParrentId, setParrentId] = useState("");
    const [StatusId, setStatusId] = useState("");
    const [switchState, setSwitchState] = useState(false);
    const [switchIsOneTime, setSwitchIsOneTime] = useState(false);
    const [switchAccessNewSubscription, setSwitchAccessNewSubscription] = useState(false);
    const [isPublished, setisPublished] = useState(false);
    const [AllowMultipleSubscription, setAllowMultipleSubscription] = useState(false);
    const [HasMultipleBillingCycle, setHasMultipleBillingCycle] = useState(false);
  
    const handleSwitchChange = () => {
      setSwitchState(!switchState);
    };
    const handleisPublished = () => {
      setisPublished(!isPublished);
    };
    const handleSwitchIsOneTimeChange = () => {
      setSwitchIsOneTime(!switchIsOneTime);
    };
    const handleSwitchAccessNewSubscriptionChange = () => {
      setSwitchAccessNewSubscription(!switchAccessNewSubscription);
    };
    const handleAllowMultipleSubscription = () => {
      setAllowMultipleSubscription(!AllowMultipleSubscription);
    };
    const handleHasMultipleBillingCycle = () => {
      setHasMultipleBillingCycle(!HasMultipleBillingCycle);
    };
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Service Category"}
                  value={ServiceCategory}
                  setValue={setServiceCategory}
                />
              </Grid>
            </Grid>
            {/* <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Tag} setValue={setTag}  options={TagOptions} placeholder={"Tag"}/>
              </Grid>
            </Grid> */}
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Parrent Id"}
                  value={ParrentId}
                  setValue={setParrentId}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={TenantID} setValue={setTenantID}  options={TenantIDOptions} placeholder={"Tenant ID"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Quota"}
                  value={Quota}
                  setValue={setQuota}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Price"}
                  value={Price}
                  setValue={setPrice}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Currency} setValue={setCurrency}  options={CurrencyOptions} placeholder={"Currency"}/>
              </Grid>
            </Grid>


            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Currency} setValue={setCurrency}  options={CurrencyOptions} placeholder={"Currency"}/>
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Provider} setValue={setProvider}  options={ProviderOptions} placeholder={"Provider"}/>
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"External ID"}
                  value={ExternalID}
                  setValue={setExternalID}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Status Id"}
                  value={StatusId}
                  setValue={setStatusId}
                />
              </Grid>
            </Grid>
            
            {/* <Grid container>
                  <Grid item xs={6}>
                  <span style={{color:"#B3B3B3",fontSize:"15px"}}>Status</span>
                  <br/>
                    <FormControlLabel
                      control={<Switch checked={switchState} onChange={handleSwitchChange} />}
                      label={switchState ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <span style={{color:"#B3B3B3",fontSize:"15px"}}>Is One Time</span>
                    <FormControlLabel
                      control={<Switch checked={switchIsOneTime} onChange={handleSwitchIsOneTimeChange} />}
                      label={switchIsOneTime ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                  <span style={{color:"#B3B3B3",fontSize:"15px"}}>
                  Access New Subscription
                  </span>
                  <br/>
                    <FormControlLabel
                      control={<Switch checked={switchAccessNewSubscription} onChange={handleSwitchAccessNewSubscriptionChange} />}
                      label={switchAccessNewSubscription ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <span style={{color:"#B3B3B3",fontSize:"15px"}}>isPublished</span>
                    <FormControlLabel
                      control={<Switch checked={isPublished} onChange={handleisPublished} />}
                      label={isPublished ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                  <span style={{color:"#B3B3B3",fontSize:"15px"}}>Allow Multiple Subscription</span>
                  <br/>
                    <FormControlLabel
                      control={<Switch checked={AllowMultipleSubscription} onChange={handleAllowMultipleSubscription} />}
                      label={AllowMultipleSubscription ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <span style={{color:"#B3B3B3",fontSize:"15px"}}>Has Multiple Billing Cycle</span>
                    <FormControlLabel
                      control={<Switch checked={HasMultipleBillingCycle} onChange={handleHasMultipleBillingCycle} />}
                      label={HasMultipleBillingCycle ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                </Grid> */}
          </>
        }
      />
    );
  };
  
  export default ManageService;
  