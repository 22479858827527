import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { GET_ALL_SCOPES_API } from "../../../Authentication/Roles";
// import { HandleApiError } from "../../../Utils/Functions";
// import CustomizedDialogs from "../../../Components/Dialog/Dialog2";
// import TextFieldForm from "../../../Components/TextField/TextFieldForm";
import AddMenu from "./AddMenu";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import CloseIcon from "@mui/icons-material/Close";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";

const AddToMenu = ({
  AddToMenuToggle,
  SetAddToMenuToggle,
  menu,
  handleSubmitButton,
  handleSubmitActionButton,
  toggleType,
}) => {
  // const { token } = useSelector((state) => state?.authentication);
  let role = localStorage.getItem("roleName");
  let token = localStorage.getItem("token");
  const options = menu?.parentGuid ? ["action"] : ["child", "action"];
  const [active, SetActive] = useState(menu?.parentGuid ? "action" : "child");
  const [Scopes, SetScopes] = useState(false);
  const [SelectedScope, SetSelectedScope] = useState("");
  const [Menu, setMenu] = useState("");
  const [Name, setName] = useState("");
  const [Path, setPath] = useState("");
  const [Icon, setIcon] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  useEffect(() => {
    if (AddToMenuToggle) {
      SetSelectedScope("");
      setMenu("");
    }
  }, [AddToMenuToggle]);
  const handleSubmitMenu = async () => {
    let formData = {
      Uri: Path,
      IconUri: Icon,
      position: 100,
      group: 2,
      MenuDetail: [
        {
          Name: Name,
          Description: Name,
          LanguageCode: "en",
        },
      ],
    };
    let menus = 0;

    if (menu) {
      formData["ParentId"] = menu?.recordGuid;
      formData["DisplayOrder"] = -1;
    } else {
      formData["DisplayOrder"] = menus;
    }
    // return console.log(formData)
    handleSubmitButton(formData);
  };
  const handleSubmit = async () => {
    let formData = {
      MenuId: menu?.recordGuid,
      MenuAction: [
        {
          MenuActionDetail: [
            {
              Name: Menu,
              Description: Menu,
              LanguageCode: "en",
            },
          ],
        },
      ],
    };
    handleSubmitActionButton({ formData, SelectedScope });
  };

  const getAllScopes = async () => {
    try {
      let response = await GET_ALL_SCOPES_API({ token });
      SetScopes(
        response?.data?.data?.scopes?.map((item) => {
          return { value: item?.recordGuid, label: item?.scopeName };
        })
      );
    } catch (e) {
      // HandleApiError(e);
    }
  };

  useEffect(() => {
    getAllScopes();
  }, []);

  return (
    // <MuiModal
    //   open={AddToMenuToggle}
    //   handleClose={() => SetAddToMenuToggle(false)}
    //   title="Add Menu Actions"
    // >
    //   <Grid container columnSpacing={4} rowSpacing={2}>
    //     <Grid display={"flex"} item xs={12}>
    //       {options.map((option) => (
    //         <Button
    //           onClick={() => SetActive(option)}
    //           className={`mui-btn primary outlined ${
    //             active === option && "active"
    //           } ml-0`}
    //         >
    //           {option}
    //         </Button>
    //       ))}
    //     </Grid>
    //     {active == "child" && (
    //       <AddMenu menu={menu} SetAddToggle={() => SetAddToMenuToggle(false)} handleSubmitButton={handleSubmitButton} />
    //     )}
    //     {active == "action" && (
    //       <>
    //         <Grid item xs={12}>
    //           <TextField onChange={(e) => setMenu(e?.target?.value)} fullWidth variant="standard" label="Menu" />
    //         </Grid>
    //         <Grid item xs={12}>
    //           <Autocomplete
    //             disablePortal
    //             id="combo-box-demo"
    //             options={Scopes}
    //             getOptionLabel={(option) => option.name}
    //             renderInput={(params) => (
    //               <TextField {...params} variant="standard" label="Scope" />
    //             )}
    //             onChange={(e, newValue) => SetSelectedScope(newValue?.id)}
    //           />
    //         </Grid>
    //         <Grid item xs={12}>
    //           <Button onClick={handleSubmit} className="mui-btn primary filled">
    //             Add
    //           </Button>
    //         </Grid>
    //       </>
    //     )}
    //   </Grid>
    // </MuiModal>

    <div>
      <CustomizedDialogs
        // open={open}
        setOpen={SetAddToMenuToggle}
        open={AddToMenuToggle}
        title={toggleType === "Child" ? "Add Child" : "Add Menu Action"}
        firstActionBtnName={"Save"}
        firstActionBtnFct={
          toggleType === "Child" ? handleSubmitMenu : handleSubmit
        }
        // secondActionBtnName={"Cancel"}
        content={
          <Grid container>
            <Grid display={"flex"} item xs={12}>
              {/* {options.map((option) => (
                <Button
                  onClick={() => SetActive(option)}
                  className={`outlined ${active === option && "active"} ml-0`}
                  sx={{ marginRight: "10px", marginBottom: "10px" }}
                >
                  {option}
                </Button>
              ))} */}
            </Grid>
            {toggleType == "Child" && (
              <AddMenu
                menu={menu}
                SetAddToggle={() => SetAddToMenuToggle(false)}
                handleSubmitButton={handleSubmitButton}
                Name={Name}
                setName={setName}
                Path={Path}
                setPath={setPath}
                Icon={Icon}
                setIcon={setIcon}
              />
            )}
            {toggleType == "Action" && (
              <>
                {/* <Grid item xs={12}>
        <TextField onChange={(e) => setMenu(e?.target?.value)} fullWidth variant="standard" label="Menu" />
      </Grid> */}
        <Grid  container>
                  <Grid  item xs={12}>
                    <SelectFormNew
                      value={SelectedScope}
                      setValue={SetSelectedScope}
                      options={Scopes}
                      placeholder={"Scope"}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                    Menu
                  </span>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm value={Menu} setValue={setMenu} />
                  </Grid>
                </Grid>
              
              </>
            )}
          </Grid>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddToMenu;
