import Add from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { MyDropzone } from "../../../Components/Buttons/ImportButton";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import Table from "../../../Components/DynamicTable/Table";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import HorizantalTabs from "../../../Components/Tabs/ChannelTabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import { downloadCSV } from "../../../Utils/functions";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

import {
  ADD_COST_PLAN,
  CHANGE_STATUS_CURRENCIES,
  DELETE_RATE,
  DELETE_TAXES,
  EXPORT_ACCOUNTS_STATUS,
  EXPORT_ACCOUNTS_TYPE,
  EXPORT_CURRENCIES,
  EXPORT_EXCHANGE_RATES,
  EXPORT_TAXES,
  GET_ALL_ACCOUNTS_STATUS,
  GET_ALL_ACCOUNTS_TYPE,
  GET_ALL_CLIENTS_CATEGORY,
  GET_ALL_CLIENT_ACCOUNTS,
  GET_ALL_COSTS_PLANS,
  GET_ALL_COSTS_PLANS_PROVIDER,
  GET_ALL_COSTS_RATES,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_CURRENCIES,
  GET_ALL_CURRENCIES_DATA_SEED,
  GET_ALL_EXCHANGE_RATES,
  GET_ALL_LANGUAGES,
  GET_ALL_PROFIT_MARGIN,
  GET_ALL_PROVIDERS_CATEGORY,
  GET_ALL_RATES,
  GET_ALL_SYSYTEM_INFO,
  GET_ALL_TAXES,
  IMPORT_COSTS,
  IMPORT_RATE,
  MANAGE_CURRENCIES,
  MANAGE_EXCHANGE_RATES_DELETE,
  MULTI_TENANCY_CONFIG,
  MULTI_TENANCY_CONFIG_LANG,
  MULTI_TENANCY_CONFIG_SUBMIT_FILE,
  POPULATE_RATE_PLAN_PROFIT_MARGIN,
} from "../../APIs";
import ManageAccounts from "./ManageAccounts";
import ManageProfitMargin from "./ManageProfitMargin";
import ManageRates from "./ManageRates";
import ManageTaxes from "./ManageTaxes";
import ManageWallet from "./ManageWallet";
import ManageExchangeRates from "./ManageExcahangeRates";
import ManageCategory from "./ManageCategory";
import ManageCurrencies from "../DataSeed/ManageCurrencies";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";
const BillingConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchClick, setSearchClick] = useState(true);

  const [recordGuidRates, setRecordGuidRates] = useState("");
  const [serviceGuid, setServiceGuid] = useState("");
  const [recordGuidExchangeRates, setRecordGuidExchangeRates] = useState("");
  const [ClientCategoryOptions, setClientCategoryOptions] = useState([]);
  const [openImportFile, setopenImportFile] = useState(false);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [taxesTabs, setTaxesTabs] = useState([]);
  const [systemCurrency, setSystemCurrency] = useState("");
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openExportModalTaxes, setOpenExportModalTaxes] = useState(false);
  const [searchTaxesClick, setSearchTaxesClick] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchCurrencies, setSearchCurrencies] = useState("");
  const [tableDataCurrencies, setTableDataCurrencies] = useState([]);
  const [paginationModalCurrencies, setPaginationModalCurrencies] =
    React.useState({
      page: 0,
      pageSize: 5,
    });
  // const [activeBtn, setActiveBtn] = useState(
  //   localStorage.getItem("roleName") !== "support" &&
  //     localStorage.getItem("roleName") !== "userQA"
  //     ? "Type"
  //     : "Type"
  // );
  const [activeBtn, setActiveBtn] = useState("Type");
  const [activeBtnTaxesTab, setActiveBtnTaxesTab] = useState("");

  const [services, setServices] = useState(localStorage.getItem("services"));
  const [config, setConfig] = useState(localStorage.getItem("config"));
  const [file, setFile] = useState("");
  const [clientCategoryImport, setClientCategoryImport] = useState("");
  const [selectedRates, setSectedRates] = useState("");
  const [selectedExchangeRates, setSelectedExchangeRates] = useState("");
  const [alertMesssage, setAlertMessage] = useState("");
  const [openAlertExportRates, setOpenAlertExportRates] = useState(false);
  const [openAlertExportCost, setOpenAlertExportCost] = useState(false);
  const [openImportAlert, setOpenImportAlert] = useState(false);
  const [openImportSuccessAlert, setOpenImportSuccessAlert] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");

  const [importMessage, setImportMessage] = useState("");
  const [generateRport, setGenerateReport] = useState(false);
  const [refreshTableCurrencies, setRefreshTableCurrencies] = useState(false);

  const [openAlertExportRatesError, setOpenAlertExportRatesError] =
    useState(false);
  const [openAlertExportCostError, setOpenAlertExportCostError] =
    useState(false);
  const [providerCategoryImport, setProviderCategoryImport] = useState("");
  const [clientCategoryImportOptions, setClientCategoryImportOptions] =
    useState(false);
  const [providerCategoryImportOptions, setProviderCategoryImportOptions] =
    useState(false);
  const [searchRates, setSearchRates] = useState("");
  const [searchCosts, setSearchCosts] = useState("");
  const [clientCategoryRatesOptions, setClientCategoryRatesOptions] =
    useState(false);
  const [providerCategoryCostsOptions, setProviderCategoryCostsOptions] =
    useState(false);
  const [
    providerCategoryCostsOptionsCosts,
    setProviderCategoryCostsOptionsCosts,
  ] = useState(false);
  const [providerCategoryCosts, setProviderCategoryCosts] = useState("");
  const [
    providerCategoryCostsGenerateReport,
    setProviderCategoryCostsGenerateReport,
  ] = useState("");
  const [
    providerCategoryCostsGenerateReportProfitMargin,
    setProviderCategoryCostsGenerateReportProfitMargin,
  ] = useState("");

  const [providerCategoryCostsVsRates, setProviderCategoryCostsVsRates] =
    useState("");

  const [clientCategoryRates, setClientCategoryRates] = useState("");
  const [ActiveBtnHorizantal, setActiveBtnHorizantal] = useState("Type");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setPaginationModelRates({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelCosts({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelCostsVsRates({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelProfitMargin({
      page: 0,
      pageSize: 10,
    });
    // setSearchCosts("");
    // setSearchRates("");
  }, [clientCategoryRates, activeBtn]);

  const [paginationModelCostVsRate, setPaginationModelCostVsRate] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [paginationModelProfitMargin, setPaginationModelProfitMargin] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [paginationModelRates, setPaginationModelRates] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [isModalOpenSwitchCurrencies, setModalOpenSwitchCurrencies] =
    useState(false);
  const [currenciesOptions, setCurrenciesOptions] = useState(false);
  const [currencies, setCurrencies] = useState("");
  const [isCheckedSwitchCurrencies, setCheckedSwitchCurrencies] =
    useState(false);
  const [MangeCurrenciesAddopen, setMangeCurrenciesAddopen] = useState(false);
  const [modalContentCurrencies, setModalContentCurrencies] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [selectedCurrencies, setSelectedCurrencies] = useState("");
  const [ConfirmOpenCurrencies, setConfirmOpenCurrencies] = useState(false);

  const [clientAccountsOptions, setClientAccountsOptions] = useState([]);
  const [clientAccounts, setClientAccounts] = useState("");

  const [paginationModelCosts, setPaginationModelCosts] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModelCostsVsRates, setPaginationModelCostsVsRates] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [refreshCosts, setRefreshCosts] = React.useState(false);
  // Accounts
  const [isModalOpenSwitchAccounts, setModalOpenSwitchAccounts] =
    useState(false);
  const [isCheckedSwitchAccounts, setCheckedSwitchAccounts] = useState(false);
  const [openEditProfitMargin, setOpenEditProfitMargin] = useState(false);
  const [MangeCurrenciesEditopen, setMangeCurrenciesEditopen] = useState(false);

  const [switchStatesAccounts, setswitchStatesAccounts] = useState({});
  const handleSwitchChangeSwitchAccounts = (rowId) => {
    setModalOpenSwitchAccounts(true);
    setCheckedSwitchAccounts(rowId);
  };
  const handleConfirmModalCurrenciesDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CURRENCIES}?RecordGuid=${selectedCurrencies?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenCurrencies(false);
        setIsLoading(false);
        setRefreshTableCurrencies(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalCurrencies({
          page: 0,
          pageSize: paginationModalCurrencies?.pageSize
            ? paginationModalCurrencies?.pageSize
            : 10,
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalCurrenciesDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleConfirmModalCurrencies = () => {
    setConfirmOpenCurrencies(false);
  };
  const handleEditMangeCurrenciesopen = (data) => {
    setMangeCurrenciesEditopen(true);
    setSelectedCurrencies(data);
  };
  const handleSwitchChangeSwitchCurrencies = (rowId) => {
    setModalOpenSwitchCurrencies(true);
    setCheckedSwitchCurrencies(rowId?.recordGuid);
    setSelectedCurrencies(rowId);
  };
  const handleModalConfirmSwitchCurrencies = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchCurrencies,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_CURRENCIES}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCurrencies(true);
        setModalOpenSwitchCurrencies(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchCurrencies(isCheckedSwitchCurrencies);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  let columnsCurrencies = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    // {
    //   headerName: "Country",
    //   field: "country",
    //   flex: 1,
    // },
    {
      headerName: "Symbol",
      field: "symbol",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Currency Enum",
      field: "currencyEnum",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={data?.isActive}
                  onChange={() => handleSwitchChangeSwitchCurrencies(data)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  const OpenConfirmModalCurrencies = (data, title, text, onButtonClick) => {
    setModalContentCurrencies({ title, text, onButtonClick });
    setConfirmOpenCurrencies(true);

    setSelectedCurrencies(data);
  };
  const handleAddMangeCurrenciesopen = () => {
    setMangeCurrenciesAddopen(true);
  };

  const handleModalCloseSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
  };
  const handleModalConfirmSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
    // setCheckedSwitchAccounts(!isCheckedSwitchAccounts);
    setswitchStatesAccounts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchAccounts]: !prevStates[isCheckedSwitchAccounts],
    }));
  };

  //

  // Rates
  const [isModalOpenSwitchRates, setModalOpenSwitchRates] = useState(false);
  const [isCheckedSwitchRates, setCheckedSwitchRates] = useState(false);
  const [switchStatesRates, setSwitchStatesRates] = useState({});
  const handleSwitchChangeSwitchRates = (rowId) => {
    setModalOpenSwitchRates(true);
    setCheckedSwitchRates(rowId);
  };
  const exportAccountsType = () => {
    setIsLoading(true);
    setRefreshTableAccountsType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_ACCOUNTS_TYPE}?exportToCsv=true`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Accounts Type.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterCurrencies = () => {
    setIsLoading(true);
    setRefreshTableCurrencies(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CURRENCIES_DATA_SEED}?PageIndex=${
          resetPagination ? 1 : paginationModalCurrencies?.page + 1
        }&PageSize=${paginationModalCurrencies?.pageSize}${
          searchCurrencies ? `&SearchKeyword=${searchCurrencies}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCurrencies({
          data: res?.data?.data?.currencies ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCurrencies();
        }
        setTableDataCurrencies({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const exportAccountsStatus = () => {
    setIsLoading(true);
    setRefreshTableAccountsStatus(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_ACCOUNTS_STATUS}?exportToCsv=true`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Accounts Status.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const exportExchangeRates = (filteredData) => {
    setIsLoading(true);
    setRefreshTableExchangeRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_EXCHANGE_RATES}?exportToCsv=true&CurrencyGuid=${
          filteredData ? searchClick : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Exchange Rates.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
        setOpenExportModal(false);
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportTaxes = (filteredData) => {
    setIsLoading(true);
    setRefreshTableTaxes(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_TAXES}?exportToCsv=true&CountryGuid=${
          searchTaxesClick ? searchTaxesClick : countryOptions[0].value
        }&FromDate=${
          filteredData && startDate
            ? transformDateFormatWithoutTimePost(startDate)
            : ""
        }&ToDate=${
          filteredData && endDate
            ? transformDateFormatWithoutTimePost(endDate)
            : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenMessageAlert(true);
        setOpenExportModalTaxes(false);
        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Taxes.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportCategory = () => {
    setIsLoading(true);
    setRefreshTableTaxes(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_EXCHANGE_RATES}?exportToCsv=true`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Category.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleModalCloseSwitchRates = () => {
    setConfirmOpenRates(false);
  };
  const handleOpenEditProfitMarginModal = () => {
    setOpenEditProfitMargin(!openEditProfitMargin);
  };
  const handleModalConfirmSwitchRates = () => {
    setModalOpenSwitchRates(false);
    // setCheckedSwitchRates(!isCheckedSwitchRates);
    setSwitchStatesRates((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchRates]: !prevStates[isCheckedSwitchRates],
    }));
  };
  useEffect(() => {
    setFile("");
    setClientCategoryImport("");
    setProviderCategoryImport("");
  }, [openImportFile]);
  const exportCurrencies = () => {
    setIsLoading(true);
    setRefreshTableCurrencies(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_CURRENCIES}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCurrencies(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Currencies.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCurrencies(false);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (
      activeBtn === "Currencies" &&
      (refreshTableCurrencies || paginationModalCurrencies)
    ) {
      if (!paginationModalCurrencies) {
        setPaginationModalCurrencies({
          page: 0,
          pageSize: paginationModalCurrencies?.pageSize
            ? paginationModalCurrencies?.pageSize
            : 10,
        });
      }
      tableQueryExecuterCurrencies();
      // tableQueryExecuterMNC();
    }
  }, [refreshTableCurrencies, paginationModalCurrencies, searchCurrencies]);
  //
  // Costs
  const [isModalOpenSwitchCosts, setModalOpenSwitchCosts] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [isCheckedSwitchCosts, setCheckedSwitchCosts] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [switchStatesCosts, setSwitchStatesCosts] = useState({});
  const handleSwitchChangeSwitchCosts = (rowId) => {
    setModalOpenSwitchCosts(true);
    setCheckedSwitchCosts(rowId);
  };

  const handleModalCloseSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
  };
  const handleModalConfirmSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
    // setCheckedSwitchCosts(!isCheckedSwitchCosts);
    setSwitchStatesCosts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCosts]: !prevStates[isCheckedSwitchCosts],
    }));
  };
  //
  // Taxes
  const [isModalOpenSwitchTaxes, setModalOpenSwitchTaxes] = useState(false);
  const [isCheckedSwitchTaxes, setCheckedSwitchTaxes] = useState(false);
  const [switchStatesTaxes, setSwitchStatesTaxes] = useState({});
  const handleSwitchChangeSwitchTaxes = (rowId) => {
    setModalOpenSwitchTaxes(true);
    setCheckedSwitchTaxes(rowId);
  };

  const handleModalCloseSwitchTaxes = () => {
    setModalOpenSwitchTaxes(false);
  };
  const handleModalConfirmSwitchTaxes = () => {
    setModalOpenSwitchTaxes(false);
    // setCheckedSwitchTaxes(!isCheckedSwitchTaxes);
    setSwitchStatesTaxes((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchTaxes]: !prevStates[isCheckedSwitchTaxes],
    }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
    setOpenAlert(false);
    setOpenImportAlert(false);
    setOpenImportSuccessAlert(false);
    setOpenAlertExportCost(false);
    setOpenAlertExportRates(false);
    setOpenAlertExportRatesError(false);
    setOpenAlertExportCostError(false);
  };
  //
  // Wallet
  const [isModalOpenSwitchWallet, setModalOpenSwitchWallet] = useState(false);
  const [isCheckedSwitchWallet, setCheckedSwitchWallet] = useState(false);
  const [switchStatesWallet, setSwitchStatesWallet] = useState({});
  const [resetPagination, setResetPagination] = useState(false);
  const [tableRates, setTableRates] = useState({ pageIndex: 1, data: [] });
  const [tableExchangeRates, setTableExchangeRates] = useState({
    pageIndex: 1,
    data: [],
  });
  const [ConfirmOpenRates, setConfirmOpenRates] = useState(false);
  const [refreshTableRates, setRefreshTableRates] = useState(false);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(
    windowWidth > 1000 ? true : false
  );
  const handleOpenAdvancedSearch = () => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  };
  const handleResetAll = () => {
    setStartDate("");
    setEndDate("");
    setCountry("");
    // tableQueryExecuterTaxes();
    setRefreshTableTaxes(true);
  };
  const handleResetAllExchangeRates = () => {
    setSearchClick(false);
    setClientAccounts("");
    setCurrencies("");
    setRefreshTableExchangeRates(true);
    // tableQueryExecuterExchangeRates();
  };
  const [openAdvancedSearchExchangeRates, setOpenAdvancedSearchExchangeRates] =
    useState(windowWidth > 1000 ? true : false);
  const handleOpenAdvancedSearchExchangeRates = () => {
    setOpenAdvancedSearchExchangeRates(!openAdvancedSearchExchangeRates);
  };

  const [tableExchangeRatesVsRates, setTableExchangeRatesVsRates] = useState({
    pageIndex: 1,
    data: [],
  });
  const [tableDataProfitMargin, setTableDataProfitMargin] = useState({
    pageIndex: 1,
    data: [],
  });
  const handleSwitchChangeSwitchWallet = (rowId) => {
    setModalOpenSwitchWallet(true);
    setCheckedSwitchWallet(rowId);
  };

  const submitFile = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers.Tenant =
      localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers = {
      ...MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers,
      "Content-Type": "multipart/form-data",
    };
    if (activeBtn == "Rates") {
      const formData = new FormData();
      formData.append("ratesFile", file);
      formData.append("channelGuid", "acaaa147-6836-4d60-97c3-3fb1db02fb63");
      formData.append("serviceGuid", process.env.REACT_APP_SERVICE_GUID);
      formData.append("ClientCategoryGuid", clientCategoryImport);
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${IMPORT_RATE}`,
          formData,
          MULTI_TENANCY_CONFIG_SUBMIT_FILE
        )
        .then(({ data }) => {
          if (data?.status != "failed" && data?.status != "error") {
            setOpenImportSuccessAlert(true);
            setImportSuccessMessage(data?.message);
            setopenImportFile(false);
            setClientCategoryImport("");
            setFile("");
            if (activeBtn === "Rates") {
              setClientCategoryRates("");
              tableQueryExecuterRates();
            }
            if (activeBtn === "Costs") {
              setRefreshCosts(true);
              setRefreshCosts(false);

              setProviderCategoryCosts("");
              tableQueryExecuterCosts();
            }
          } else {
          }
        })
        .catch((err) => {
          setImportMessage(err?.response?.data?.message);
          setOpenImportAlert(true);
        });
    } else if (activeBtn == "Costs") {
      const formData = new FormData();
      formData.append("costsFile", file);
      formData.append("channelGuid", "acaaa147-6836-4d60-97c3-3fb1db02fb63");
      formData.append("serviceGuid", process.env.REACT_APP_SERVICE_GUID);
      formData.append("providerCategoryGuid", providerCategoryImport);
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${IMPORT_COSTS}`,
          formData,
          MULTI_TENANCY_CONFIG_SUBMIT_FILE
        )
        .then(({ data }) => {
          if (data?.status != "failed" && data?.status != "error") {
            setOpenImportSuccessAlert(true);
            setImportSuccessMessage(data?.message);
            setopenImportFile(false);
            setProviderCategoryImport("");
            setFile("");
          } else {
          }
        })
        .catch((err) => {
          setImportMessage(err?.response?.data?.message);
          setOpenImportAlert(true);
        });
    }
  };

  const handleModalCloseSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
  };
  const handleModalConfirmSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
    // setCheckedSwitchWallet(!isCheckedSwitchTaxes);
    setSwitchStatesWallet((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchWallet]: !prevStates[isCheckedSwitchWallet],
    }));
  };
  //
  const [Zone, setZone] = React.useState("");

  const handleChangeZone = (event) => {
    setZone(event.target.value);
  };
  const [Rates, setRates] = React.useState("");

  const handleChangeRates = (event) => {
    setRates(event.target.value);
  };
  const tableDataAccounts = [
    {
      id: 1,
      name: "Test Account Type 1",
      description: "Test Account Type Description 1",
    },
    {
      id: 2,
      name: "Test Account Type 2",
      description: "Test Account Type Description 2",
    },
  ];
  let columnsAccountsType = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 400,
    },
  ];
  const tableDataAccountsCategory = [
    {
      id: 1,
      name: "Test category Type 1",
      description: "Test category Type Description 1",
    },
    {
      id: 2,
      name: "Test category Type 2",
      description: "Test category Type Description 2",
    },
  ];
  let columnsAccountsCategory = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];

  let columnsAccountsStatus = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 400,
    },
  ];
  // const tableDataRates = [
  //   {
  //     id: 1,
  //     clientCategory: "Default",
  //     countryCode: "961",
  //     country: "Lebanon",
  //     operator: "Alfa",
  //     rate: "0.2 USD",
  //   },
  // ];
  const handleStartDateChange = (value) => {
    if (endDate && endDate >= value) {
      setStartDate(value);
    } else {
      if (endDate === "") {
        setStartDate(value);
      } else {
        // setStartDate("");
      }
      // setStartDate(value);

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  const handleEndDateChange = (value) => {
    if (startDate <= value) {
      setEndDate(value);
    } else {
      setEndDate("");

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  function transformDateFormat(dateTimeString) {
    // Split the datetime string into dateTimeStringdate and time parts
    var dateTimeParts = dateTimeString?.split("T");
    var datePart = dateTimeParts && dateTimeParts[0];
    var timePart = dateTimeParts && dateTimeParts[1];

    // Split the date into components
    var dateComponents = datePart?.split("-");

    // Rearrange the date components in the desired format
    var transformedDate =
      dateComponents[2] + "-" + dateComponents[1] + "-" + dateComponents[0];

    // Extract time components
    var timeComponents = timePart?.split(":");
    var hours = timeComponents[0];
    var minutes = timeComponents[1];

    // Construct the transformed datetime string
    var transformedDateTime = transformedDate + " " + hours + ":" + minutes;

    return transformedDateTime;
  }
  function transformDateFormatWithoutTime(dateString) {
    // Split the date string into components
    var parts = dateString.split("-");

    // Rearrange the components in the desired format
    var transformedDate = parts[2] + "-" + parts[1] + "-" + parts[0];

    return transformedDate;
  }
  function transformDateFormatWithoutTimePost(dateString) {
    // Split the date string into components
    var parts = dateString.split("-");

    // Rearrange the components in the desired format
    var transformedDate = parts[0] + "-" + parts[1] + "-" + parts[2];

    return transformedDate;
  }
  let columnsRates = [
    {
      field: "clientCategoryName",
      headerName: "Client Category",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country ISO",
      field: "countryCode",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      minWidth: 150,
      flex: 1,
    },
  ];
  let columnsExchangeRates = [
    {
      field: "currentRate",
      headerName: "Current Rate",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Current Rate Inverse",
      field: "currentRateInverse",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Next Rate",
      field: "newRate",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Effective Date",
      field: "effectiveDate",
      minWidth: 100,
      renderCell: (params) => {
        let data = params?.row;
        if(data?.effectiveDate !=null){
        return (
          <>          
            {transformDateFormatWithoutTime(data?.effectiveDate?.split("T")[0])}
          </>
        );}
        return "";
      },
      flex: 1,
    },
    {
      headerName: "Currency Code",
      field: "currencyCode",
      minWidth: 100,
      flex: 1,
    },
    // {
    //   headerName: "System Currency Code",
    //   field: "systemCurrencyCode",
    //   minWidth: 100,
    //   flex: 1,
    // },
  ];
  const tableDataCosts = [
    {
      id: 1,
      providerCategory: "providerCategory",
      countryCode: "961",
      country: "Lebanon",
      operator: "operator",
      cost: "U.S",
    },
  ];
  let columnsCosts = [
    {
      field: "providerCategoryName",
      headerName: "Provider Category",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "countryCode",
      headerName: "Country ISO",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Cost",
      field: "cost",
      minWidth: 150,
      flex: 1,
    },
  ];

  const tableDataWallet = [
    {
      id: 1,
      clientCategory: "clientCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];

  const tableProfitMargin = [
    {
      clientCategory: "clientCategory 1",
      percentage: "80%",
    },
  ];
  let columnsWallet = [
    {
      field: "clientCategory",
      headerName: "Client Category",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];
  //Accounts Type
  const [refreshTableAccountsType, setRefreshTableAccountsType] = useState("");
  const [paginationModalAccountsType, setPaginationModalAccountsType] =
    useState({
      page: 0,
      pageSize: 10,
    });
  const [searchAccountsType, setSearchAccountsType] = useState("");
  const [tableDataAccountsType, setTableDataAccountsType] = useState([]);

  const tableQueryExecuterAccountsType = () => {
    setIsLoading(true);
    setRefreshTableAccountsType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_ACCOUNTS_TYPE}?PageIndex=${
          resetPagination ? 1 : paginationModalAccountsType?.page + 1
        }&PageSize=${paginationModalAccountsType?.pageSize}${
          searchAccountsType ? `&Keyword=${searchAccountsType}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataAccountsType({
          data: res?.data?.data?.types ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterAccountsType();
        }
        setTableDataAccountsType({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalCloseSwitchCurrencies = () => {
    setModalOpenSwitchCurrencies(false);
  };
  //Exchange Rate
  const [refreshTableExchangeRates, setRefreshTableExchangeRates] =
    useState("");
  const [paginationModalExchangeRates, setPaginationModalExchangeRates] =
    useState({
      page: 0,
      pageSize: 10,
    });
  const [searchExchangeRates, setSearchExchangeRates] = useState("");
  const [tableDataExchangeRates, setTableDataExchangeRates] = useState([]);

  const tableQueryExecuterExchangeRates = () => {
    setIsLoading(true);
    setRefreshTableExchangeRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_EXCHANGE_RATES}?PageIndex=${
          resetPagination ? 1 : paginationModalExchangeRates?.page + 1
        }&PageSize=${paginationModalExchangeRates?.pageSize}${
          searchExchangeRates ? `&Keyword=${searchExchangeRates}` : ""
        }${clientAccounts ? `&ClientAccountGuid=${clientAccounts}` : ""}${
          currencies ? `&CurrencyGuid=${currencies}` : ""
        }
        `,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setSearchClick(currencies);
        setTableDataExchangeRates({
          data: res?.data?.data?.exchangeRates ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterExchangeRates();
        }
        setTableDataExchangeRates({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Accounts Status
  const [refreshTableAccountsStatus, setRefreshTableAccountsStatus] =
    useState("");
  const [paginationModalAccountsStatus, setPaginationModalAccountsStatus] =
    useState({
      page: 0,
      pageSize: 10,
    });
  const [searchAccountsStatus, setSearchAccountsStatus] = useState("");
  const [tableDataAccountsStatus, setTableDataAccountsStatus] = useState([]);

  const tableQueryExecuterAccountsStatus = () => {
    setIsLoading(true);
    setRefreshTableAccountsStatus(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_ACCOUNTS_STATUS}?PageIndex=${
          resetPagination ? 1 : paginationModalAccountsStatus?.page + 1
        }&PageSize=${paginationModalAccountsStatus?.pageSize}${
          searchAccountsStatus ? `&Keyword=${searchAccountsStatus}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataAccountsStatus({
          data: res?.data?.data?.statuses ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterAccountsStatus();
        }
        setTableDataAccountsStatus({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Accounts Status
  const [refreshTableTaxes, setRefreshTableTaxes] = useState("");
  const [paginationModalTaxes, setPaginationModalTaxes] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchTaxes, setSearchTaxes] = useState("");
  const [tableDataTaxes, setTableDataTaxes] = useState([]);
  const [language, setLanguage] = useState("");

  const [refreshTableCategory, setRefreshTableCategory] = useState("");
  const [paginationModalCategory, setPaginationModalCategory] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchCategory, setSearchCategory] = useState("");
  const [tableDataCategory, setTableDataCategory] = useState([]);

  const tableQueryExecuterTaxes = (countryFirst) => {
    setLanguage();

    setIsLoading(true);
    setRefreshTableTaxes(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG_LANG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG_LANG.headers.Tenant = localStorage.getItem("tenant");

    MULTI_TENANCY_CONFIG_LANG.headers["LanguageCode"] = "en";

    setIsLoading(true);

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_TAXES}?PageIndex=${
          resetPagination ? 1 : paginationModalTaxes?.page + 1
        }&CountryGuid=${country ? country : ""}&FromDate=${
          startDate ? transformDateFormatWithoutTimePost(startDate) : ""
        }&ToDate=${
          endDate ? transformDateFormatWithoutTimePost(endDate) : ""
        }&PageSize=${paginationModalTaxes?.pageSize}${
          searchTaxes ? `&Keyword=${searchTaxes}` : ""
        }`,
        MULTI_TENANCY_CONFIG_LANG
      )
      .then((res) => {
        setSearchTaxesClick(country);
        setTableDataTaxes({
          data: res?.data?.data?.taxes ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterTaxes();
        }
        setTableDataTaxes({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterCategory = (lang) => {
    setLanguage(lang);
    setIsLoading(true);
    setRefreshTableCategory(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG_LANG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG_LANG.headers.Tenant = localStorage.getItem("tenant");

    MULTI_TENANCY_CONFIG_LANG.headers["LanguageCode"] = lang ? lang : "ar";

    setIsLoading(true);

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_TAXES}?PageIndex=${
          resetPagination ? 1 : paginationModalCategory?.page + 1
        }&PageSize=${paginationModalCategory?.pageSize}${
          searchCategory ? `&Keyword=${searchCategory}` : ""
        }`,
        MULTI_TENANCY_CONFIG_LANG
      )
      .then((res) => {
        setTableDataCategory({
          data: res?.data?.data?.taxes ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCategory(lang);
        }
        setTableDataCategory({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (
      activeBtn === "Type" &&
      (refreshTableAccountsType || paginationModalAccountsType)
    ) {
      if (!paginationModalAccountsType) {
        setPaginationModalAccountsType({
          page: 0,
          pageSize: paginationModalAccountsType?.pageSize
            ? paginationModalAccountsType?.pageSize
            : 10,
        });
      }
      tableQueryExecuterAccountsType();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableAccountsType,
    paginationModalAccountsType,
    searchAccountsType,
  ]);
  useEffect(() => {
    if (activeBtn === "Taxes" && (refreshTableTaxes || paginationModalTaxes)) {
      if (!paginationModalTaxes) {
        setPaginationModalTaxes({
          page: 0,
          pageSize: paginationModalTaxes?.pageSize
            ? paginationModalTaxes?.pageSize
            : 10,
        });
      }
      tableQueryExecuterTaxes();
      // if (langRes.length !== 0) {
      //   let lang = langRes?.find((x) => x.label === activeBtnTaxesTab)?.value;
      //   tableQueryExecuterTaxes(lang);
      // }
    }
  }, [refreshTableTaxes, paginationModalTaxes, searchTaxes, taxesTabs]);
  useEffect(() => {
    if (
      activeBtn === "Category" &&
      taxesTabs?.length !== 0 &&
      (refreshTableCategory || paginationModalCategory)
    ) {
      if (!paginationModalCategory) {
        setPaginationModalCategory({
          page: 0,
          pageSize: paginationModalCategory?.pageSize
            ? paginationModalCategory?.pageSize
            : 10,
        });
      }
      if (langRes.length !== 0) {
        let lang = langRes?.find((x) => x.label === activeBtnTaxesTab)?.value;
        tableQueryExecuterCategory(lang);
      }
    }
  }, [
    refreshTableCategory,
    paginationModalCategory,
    searchCategory,
    taxesTabs,
  ]);

  useEffect(() => {
    // if (langRes.length !== 0) {
    //   let lang = langRes?.find((x) => x.label === activeBtnTaxesTab)?.value;
    //   if (activeBtn === "Taxes") {
    //     tableQueryExecuterTaxes(lang);
    //   } else if (activeBtn === "Category") {
    //     tableQueryExecuterCategory(lang);
    //   }
    // }

    if (activeBtn === "Taxes") {
      tableQueryExecuterTaxes();
    } else if (activeBtn === "Category") {
      tableQueryExecuterCategory();
    }
  }, [activeBtnTaxesTab, activeBtn]);

  useEffect(() => {
    if (
      activeBtn === "Status" &&
      (refreshTableAccountsStatus || paginationModalAccountsStatus)
    ) {
      if (!paginationModalAccountsStatus) {
        setPaginationModalAccountsStatus({
          page: 0,
          pageSize: paginationModalAccountsStatus?.pageSize
            ? paginationModalAccountsStatus?.pageSize
            : 10,
        });
      }
      tableQueryExecuterAccountsStatus();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableAccountsStatus,
    paginationModalAccountsStatus,
    searchAccountsStatus,
  ]);

  useEffect(() => {
    if (
      activeBtn === "Exchange Rates" &&
      (refreshTableExchangeRates || paginationModalExchangeRates)
    ) {
      if (!paginationModalExchangeRates) {
        setPaginationModalExchangeRates({
          page: 0,
          pageSize: paginationModalExchangeRates?.pageSize
            ? paginationModalExchangeRates?.pageSize
            : 10,
        });
      }
      tableQueryExecuterExchangeRates();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableExchangeRates,
    paginationModalExchangeRates,
    searchExchangeRates,
  ]);
  useEffect(() => {
    if (activeBtn === "Currencies") {
      setPaginationModalCurrencies({
        page: 0,
        pageSize: 10,
      });
      setSearchCurrencies("");
    }
    setStartDate("");
    setEndDate("");
    setCountry("")
  }, [activeBtn]);

  const getAllLanguages = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_LANGUAGES}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTaxesTabs(
          res?.data?.data?.languages?.map((x, i) => ({
            label: x.name,
          }))
        );
        setLangRes(
          res?.data?.data?.languages?.map((x, i) => ({
            label: x?.name,
            value: x?.code,
          }))
        );
        setActiveBtnTaxesTab(res?.data?.data?.languages[0]?.code);
        if (activeBtn === "Taxes") {
          tableQueryExecuterTaxes();
        }
        if (activeBtn === "Category") {
          tableQueryExecuterCategory();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllLanguages();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getSysctemCurrency = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_SYSYTEM_INFO}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setSystemCurrency(res?.data?.data?.items?.currency?.recordGuid);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getSysctemCurrency();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (searchAccountsType !== "") {
      setPaginationModalAccountsType({
        page: 0,
        pageSize: 10,
      });
    }
  }, [searchAccountsType]);

  useEffect(() => {
    if (searchAccountsStatus !== "") {
      setPaginationModalAccountsStatus({
        page: 0,
        pageSize: paginationModalAccountsStatus?.pageSize
          ? paginationModalAccountsStatus?.pageSize
          : 10,
      });
    }
  }, [searchAccountsStatus]);

  useEffect(() => {
    if (activeBtn === "Type") {
      setPaginationModalAccountsType({
        page: 0,
        pageSize: 10,
      });
      setSearchAccountsType("");
    }
    if (activeBtn === "Status") {
      setPaginationModalAccountsStatus({
        page: 0,
        pageSize: 10,
      });
      setSearchAccountsStatus("");
    }

    if (activeBtn === "Taxes") {
      getAllCountries();

      setPaginationModalTaxes({
        page: 0,
        pageSize: 10,
      });
      setSearchTaxes("");
      // getAllLanguages();
    }
    if (activeBtn === "Category") {
      // getAllLanguages();
      setPaginationModalCategory({
        page: 0,
        pageSize: 10,
      });
      setSearchCategory("");
      // tableQueryExecuterTaxes()
      // getAllLanguages();
    }
    if (activeBtn === "Exchange Rates") {
      setPaginationModalExchangeRates({
        page: 0,
        pageSize: 10,
      });
      setSearchExchangeRates("");
      getAllCurrencies();
      // getAllClientAccounts();
      getSysctemCurrency();
    }
  }, [activeBtn]);

  const handlePopulateBtn = (ClientCategoryGuid) => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${POPULATE_RATE_PLAN_PROFIT_MARGIN}`,
        {
          ClientCategoryGuid,
          ProviderCategoryGuid:
            activeBtn === "Profit Margin"
              ? providerCategoryCostsGenerateReportProfitMargin
              : providerCategoryCostsGenerateReport,
          ChannelGuid: "acaaa147-6836-4d60-97c3-3fb1db02fb63",
        },
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenAlert(true);
        setAlertMessage(res?.data?.message);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handlePopulateBtn();
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  let columnsCostVsRate = [
    {
      field: "rate",
      headerName: "Rate",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Delta",
      field: "delta",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Currency Code",
      field: "currencyCode",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country Name",
      field: "countryName",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Client Category",
      field: "clientCategoryName",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "costOperatorName",
      headerName: "Operator",
      minWidth: 150,
      flex: 1,
    },
  ];
  let columnsProfitMargin = [
    {
      field: "percentageRate",
      headerName: "Estimated Profit",
      // width: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "realProfitPercentage",
      headerName: "Real Profit ",
      // width: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "clientCategoryName",
      headerName: "Client Category",
      // width: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "populate",
      headerName: "",
      // width: "auto",
      minWidth: 150,
      flex: 1,

      // width: 100,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => handlePopulateBtn(params?.row?.clientCategoryGuid)}
            className="generate-key"
          >
            Populate
          </Button>
        );
      },
    },
  ];
  // const tableDataTaxes = [
  //   {
  //     id: 1,
  //     clientCategory: "clientCategory",
  //     countrycode: "961",
  //     country: "leabanon",
  //     operator: "operator",
  //     rate: "12312",
  //   },
  // ];
  let columnsTaxes = [
    {
      field: "rate",
      headerName: "Tax Rate %",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "countryName",
      headerName: "Country",
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "From Date",
      field: "fromDate",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        let data = params?.row;
        return <>{transformDateFormat(data?.fromDate)}</>;
      },
    },
    {
      headerName: "To Date",
      field: "toDate",
      renderCell: (params) => {
        let data = params?.row;
        return <>{transformDateFormat(data?.toDate)}</>;
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: "IsForDisplayOnly",
      headerName: "For Display Only",
      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params.row?.isForDisplayOnly}
                  disabled={true}
                  // onChange={() => {
                  //   handleSwitchChangeSwitchPrefixes(rowId);
                  //   setSelectedPrefix(params.row);
                  // }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    // {
    //   headerName: "System Currency Code",
    //   field: "systemCurrencyCode",
    //   minWidth: 100,
    //   flex: 1,
    // },
  ];

  let columnsCategory = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 100,
      flex: 1,
    },
  ];

  const tabs = [
    {
      label: "Accounts",
      // subTabs: ["Type", "Category", "Status"],
      subTabs: [
        { label: "Type" },
        // { label: "Category" },
        { label: "Status" },
        // { label: "Category" },
      ],
    },
    // {
    //   label: "Rates",
    // },
    // {
    //   label: "Costs",
    // },
    // {
    //   label: "Cost Vs. Rate",
    // },
    // {
    //   label: "Profit Margin",
    // },
    {
      label: "Taxes",
    },

    {
      label: "Exchange Rates",
    },
    {
      label: "Currencies",
    },

    // {
    //   label: "Shortcode",
    // },
    // {
    //   label: "Longcode",
    // },
    // {
    //   label: "Wallet",
    // },
  ];
  const tabsSupport = [
    {
      label: "Rates",
    },
    {
      label: "Costs",
    },
    {
      label: "Cost Vs. Rate",
    },
    {
      label: "Profit Margin",
    },
  ];
  const tabsHorizantal = [
    {
      label: "Type",
    },
    {
      // label: "Category",
    },
    {
      label: "Status",
    },
  ];
  useEffect(() => {
    if (activeBtn === "Rates") {
      tableQueryExecuterRates();
    }
  }, [activeBtn, clientCategoryRates, paginationModelRates]);
  useEffect(() => {
    if (activeBtn === "Rates" && searchRates !== "") {
      setPaginationModelRates({
        page: 0,
        pageSize: paginationModelRates?.pageSize
          ? paginationModelRates?.pageSize
          : 10,
      });
    } else {
      tableQueryExecuterRates();
    }
  }, [searchRates]);

  useEffect(() => {
    // setServices(JSON.parse(services));
    // setConfig(JSON.parse(config));
    if (activeBtn === "Rates" && openAlert) {
      tableQueryExecuterRates();
    }
    if (activeBtn === "Profit Margin" && openAlert) {
      tableQueryExecuterProfitMargin();
    }
  }, [openAlert]);
  useEffect(() => {
    if (activeBtn === "Rates" && importSuccessMessage !== "") {
      tableQueryExecuterRates();
    }
    if (activeBtn === "Costs" && importSuccessMessage) {
      tableQueryExecuterCosts();
    }
    setClientCategoryImport("");
    setFile("");
    setProviderCategoryCosts("");

    //delete
  }, [importSuccessMessage, refreshCosts]);
  useEffect(() => {
    //delete
    if (activeBtn === "Rates" && refreshTableRates) {
      tableQueryExecuterRates();
    }
  }, [refreshTableRates]);
  useEffect(() => {
    if (activeBtn === "Cost Vs. Rate") {
      tableQueryExecuterCostsVsRates();
    }
    if (activeBtn === "Profit Margin") {
      tableQueryExecuterProfitMargin();
    }
    setPaginationModelCostsVsRates({
      page: 0,
      pageSize: paginationModelCostVsRate?.pageSize
        ? paginationModelCostVsRate?.pageSize
        : 10,
    });
    setPaginationModelProfitMargin({
      page: 0,
      pageSize: paginationModelProfitMargin?.pageSize
        ? paginationModelProfitMargin?.pageSize
        : 10,
    });
  }, [generateRport, openImportFile]);

  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptions(
          res?.data?.data?.countries.map((country) => ({
            label: country?.name,
            value: country?.recordGuid,
          }))
        );
        setCountry("");
        tableQueryExecuterTaxes();
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllCurrencies = () => {
    let newServices = JSON.parse(services);
    setCurrenciesOptions(false);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CURRENCIES}?SearchKeyword=`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCurrenciesOptions(
          res?.data?.data?.currencies.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCurrencies();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };
  // const getAllClientAccounts = () => {
  //   let newServices = JSON.parse(services);

  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   let data = {
  //     pageIndex: 1,
  //     pageSize: 100,
  //   };
  //   axiosInstance
  //     .post(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["billing"]
  //       }${GET_ALL_CLIENT_ACCOUNTS}?SearchKeyword=`,
  //       data,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setClientAccountsOptions(
  //         res?.data?.data?.accounts.map((x) => ({
  //           label: x?.description,
  //           value: x?.recordGuid,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       if (err === "error: 401") {
  //         getAllClientAccounts();
  //       }
  //       // toast.error(
  //       //   err?.response?.data?.message ??
  //       //     "An Error Ocurred Retrieving Countries"
  //       // );
  //     })
  //     .finally(() => {
  //       // this.setState({ loading: false, resetPagination: false });
  //     });
  // };

  const getAllClientsCategory = () => {
    setClientCategoryRatesOptions(false);
    setClientCategoryImportOptions(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_CLIENTS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setClientCategoryRatesOptions([
          { label: "All", value: "" },
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setClientCategoryImportOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);

        setClientCategoryOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setIsLoading(false);

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllClientsCategory();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllServiceGuid = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${GET_ALL_CLIENTS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setClientCategoryRatesOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.serviceCategoryGuid,
          })),
        ]);
        setClientCategoryImportOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);

        setClientCategoryOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllServiceGuid();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const exportRates = () => {
    let newServices = JSON.parse(services);
    const columns = [
      { title: "Client Category", field: "clientCategoryName" },
      {
        title: "Country ISO",
        field: "countryCode",
      },
      { title: "Country", field: "country" },
      {
        title: "Operator",
        field: "operator",
      },
      {
        title: "Rate",
        field: "rate",
      },
    ];
    setIsLoading(true);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    new axiosInstance.get(
      `${localStorage.getItem("baseUrl")}${
        newServices["billing"]
      }${GET_ALL_RATES}?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&export=true`,
      MULTI_TENANCY_CONFIG
    )
      .then((res) => {
        setIsLoading(false);
        const data = res?.data?.data?.ratePlans;
        downloadCSV(
          columns,
          data?.map((item) => ({
            ...item,
          })),
          "Rates"
        );
        setOpenAlertExportRates(true);
      })
      .catch((err) => {
        setOpenAlertExportRatesError(true);
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };

  const getAllProvidersCategory = () => {
    setProviderCategoryImportOptions(false);
    setProviderCategoryCostsOptionsCosts(false);
    setProviderCategoryCostsOptions(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_PROVIDERS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setProviderCategoryCostsOptions([
          { label: "All", value: "" },

          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label:
              x.providerCategoryDetails && x.providerCategoryDetails.length > 0
                ? x.providerCategoryDetails[0].name
                : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryCostsOptionsCosts([
          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label:
              x.providerCategoryDetails && x.providerCategoryDetails.length > 0
                ? x.providerCategoryDetails[0].name
                : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryCostsGenerateReport(
          res?.data?.data?.providerCategories[0].recordGuid
        );
        setProviderCategoryCostsGenerateReportProfitMargin(
          res?.data?.data?.providerCategories[0].recordGuid
        );
        tableQueryExecuterCostsVsRates(
          res?.data?.data?.providerCategories[0].recordGuid
        );
        // tableQueryExecuterCostsVsRates(
        //   res?.data?.data?.providerCategories[0].recordGuid
        // );
        // tableQueryExecuterProfitMargin(
        //   res?.data?.data?.providerCategories[0].recordGuid
        // );
        setProviderCategoryImportOptions([
          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label: x.providerCategoryDetails
              ? x.providerCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setIsLoading(false);

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProvidersCategory();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterRates = (promiseResolve, promiseReject) => {
    setIsLoading(true);
    setRefreshTableRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_RATES}?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&ClientCategoryGuid=${clientCategoryRates}&pageindex=${
          resetPagination ? 1 : paginationModelRates?.page + 1
        }&pagesize=${paginationModelRates?.pageSize}${
          searchRates ? `&keyword=${searchRates}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        // this.setState({ tableDataRes: res?.data?.data?.apis ?? [] });
        setTableRates({
          data: res?.data?.data?.ratePlans ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelRates({
        //   ...paginationModelRates,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterRates();
        }
        setTableRates({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const DeleteRate = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${DELETE_RATE}?RecordGuid=${recordGuidRates}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // toast.success("Rate Deleted");
        setConfirmOpenRates(false);
        setRefreshTableRates(true);
        setOpenAlert(true);
        setAlertMessage(data?.message);
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteRate();
        }
        // toast.error(err?.response?.data?.Message || "Somthing Went Wrong!");
      });
  };

  const DeleteExchangeRates = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${MANAGE_EXCHANGE_RATES_DELETE}?ExchangeRateGuid=${recordGuidExchangeRates}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ res }) => {
        tableQueryExecuterExchangeRates();
        setConfirmOpenExchangeRates(false);
        setOpenAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalExchangeRates({
          page: 0,
          pageSize: paginationModalExchangeRates?.pageSize
            ? paginationModalExchangeRates?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteExchangeRates();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      });
  };

  const DeleteTaxes = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${DELETE_TAXES}?RecordGuid=${selectedTaxes?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ res }) => {
        tableQueryExecuterTaxes();
        setConfirmOpenTaxes(false);
        setOpenAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalTaxes({
          page: 0,
          pageSize: paginationModalTaxes?.pageSize
            ? paginationModalTaxes?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteTaxes();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      });
  };

  const DeleteCategory = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${DELETE_TAXES}?RecordGuid=${selectedCategory?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ res }) => {
        tableQueryExecuterCategory();
        setConfirmOpenCategory(false);
        setOpenAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteCategory();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      });
  };
  const tableQueryExecuterCosts = (promiseResolve, promiseReject) => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["billing"]}${
          providerCategoryCosts
            ? GET_ALL_COSTS_PLANS_PROVIDER
            : GET_ALL_COSTS_PLANS
        }?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&pageindex=${
          resetPagination ? 1 : paginationModelCosts?.page + 1
        }&pagesize=${paginationModelCosts?.pageSize}${
          searchCosts ? `&keyword=${searchCosts}` : ""
        }${
          providerCategoryCosts
            ? `&ProviderCategoryGuid=${providerCategoryCosts}`
            : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableExchangeRates({
          data: res?.data?.data?.costPlans ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(false);
        }
        setTableExchangeRates({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterCostsVsRates = (providerGuid) => {
    {
      providerCategoryCostsGenerateReport !== "" && setIsLoading(true);
      setGenerateReport(false);
      let newServices = JSON.parse(services);
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
      setIsLoading(true);
      axiosInstance
        .get(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${GET_ALL_COSTS_RATES}?pageindex=${
            resetPagination ? 1 : paginationModelCostsVsRates?.page + 1
          }&pagesize=${paginationModelCostsVsRates?.pageSize}${
            providerCategoryCostsGenerateReport
              ? `&ProviderCategoryGuid=${providerCategoryCostsGenerateReport}`
              : `&ProviderCategoryGuid=${providerGuid}`
          }`,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setTableExchangeRatesVsRates({
            data: res?.data?.data?.rateCostPlans ?? [],
            totalCount: res?.data?.data?.totalRows,
            pageIndex: res?.data?.data?.pageIndex,
          });
        })
        .catch((err) => {
          if (err === "error: 401") {
            setIsLoading(false);
          }
          setTableExchangeRatesVsRates({
            data: [],
            totalCount: 0,
            isLoading: false,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const tableQueryExecuterProfitMargin = (providerGuid) => {
    setIsLoading(true);
    setGenerateReport(false);

    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_PROFIT_MARGIN}?pageindex=${
          resetPagination ? 1 : paginationModelProfitMargin?.page + 1
        }&pagesize=${paginationModelProfitMargin?.pageSize}${
          providerCategoryCostsGenerateReportProfitMargin
            ? `&ProviderCategoryGuid=${providerCategoryCostsGenerateReportProfitMargin}`
            : `&ProviderCategoryGuid=${providerGuid}`
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        console.log(
          "res?.data?.data?.rateCostPlans",
          res?.data?.data?.rateCostPlans
        );
        setTableDataProfitMargin({
          data: res?.data?.data?.profitMargins ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(false);
        }
        setTableDataProfitMargin({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const exportCosts = () => {
    let newServices = JSON.parse(services);
    const columns = [
      { title: "Provider Category", field: "providerCategoryName" },
      {
        title: "Country ISO",
        field: "countryCode",
      },
      { title: "Country", field: "country" },
      {
        title: "Operator",
        field: "operator",
      },
      {
        title: "Cost",
        field: "cost",
      },
    ];
    setIsLoading(true);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    new axiosInstance.get(
      `${localStorage.getItem("baseUrl")}${newServices["billing"]}${
        providerCategoryCosts
          ? GET_ALL_COSTS_PLANS_PROVIDER
          : GET_ALL_COSTS_PLANS
      }?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&pageindex=${"1"}&pagesize=${"100000"}${
        providerCategoryCosts
          ? `&ProviderCategoryGuid=${providerCategoryCosts}`
          : ""
      }`,
      MULTI_TENANCY_CONFIG
    )
      .then((res) => {
        setIsLoading(false);

        const data = res?.data?.data?.costPlans;
        downloadCSV(
          columns,
          data?.map((item) => ({
            ...item,
          })),
          "Costs"
        );
        setOpenAlertExportCost(true);
      })
      .catch((err) => {
        setOpenAlertExportCostError(true);

        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (activeBtn === "Cost Vs. Rate") {
      tableQueryExecuterCostsVsRates();
    }
  }, [paginationModelCostsVsRates]);

  useEffect(() => {
    if (activeBtn === "Profit Margin") {
      tableQueryExecuterProfitMargin();
    }
  }, [paginationModelProfitMargin]);

  useEffect(() => {
    if (activeBtn === "Costs") {
      tableQueryExecuterCosts();
    }
  }, [activeBtn, paginationModelCosts, providerCategoryCosts]);

  useEffect(() => {
    if (activeBtn === "Costs" && searchCosts !== "") {
      setPaginationModelCosts({
        page: 0,
        pageSize: paginationModelCosts?.pageSize
          ? paginationModelCosts?.pageSize
          : 10,
      });
    } else {
      tableQueryExecuterCosts();
    }
  }, [searchCosts]);

  useEffect(() => {
    if (openAlert) {
      tableQueryExecuterCosts();
    }
  }, [openAlert]);

  // Delete Modal Accounts functions
  const [ConfirmOpenAccounts, setConfirmOpenAccounts] = useState(false);
  const [countryOptions, setCountryOptions] = useState(false);
  const [country, setCountry] = useState("");

  const [modalContentAccounts, setModalContentAccounts] = useState({
    onButtonClick: () => {},
  });
  const OpenConfirmModalAccounts = (title, text, onButtonClick) => {
    setModalContentAccounts({ title, text, onButtonClick });
    setConfirmOpenAccounts(true);
  };

  const handleConfirmModalAccounts = () => {
    setConfirmOpenAccounts(false);
  };
  const handleGenerateReport = () => {
    setGenerateReport(true);
  };

  //

  // Delete Modal Rates functions
  const [modalContentRates, setModalContentRates] = useState({
    onButtonClick: () => {},
  });
  const OpenConfirmModalRates = (data) => {
    setRecordGuidRates(data.recordGuid);
    setModalContentRates();
    setConfirmOpenRates(true);
  };

  const handleConfirmModalRates = () => {
    setConfirmOpenRates(false);
  };

  //
  // Delete Modal Costs functions
  const [ConfirmOpenExchangeRates, setConfirmOpenExchangeRates] =
    useState(false);
  const [modalContentCosts, setModalContentCosts] = useState({
    onButtonClick: () => {},
  });
  const OpenConfirmModalExchangeRates = (data) => {
    setRecordGuidExchangeRates(data.recordGuid);
    setConfirmOpenExchangeRates(true);
  };

  const handleConfirmModalExchangeRates = () => {
    setConfirmOpenExchangeRates(false);
  };

  //
  // Delete Modal Wallet functions
  const [ConfirmOpenWallet, setConfirmOpenWallet] = useState(false);
  const [modalContentWallet, setModalContentWallet] = useState({});
  const OpenConfirmModalWallet = (title, text, onButtonClick) => {
    setModalContentWallet({ title, text, onButtonClick });
    setConfirmOpenWallet(true);
  };

  const handleConfirmModalWallet = () => {
    setConfirmOpenWallet(false);
  };

  //
  // Delete Modal Taxes functions
  const [ConfirmOpenTaxes, setConfirmOpenTaxes] = useState(false);

  const [ConfirmOpenCategory, setConfirmOpenCategory] = useState(false);

  const [modalContentTaxes, setModalContentTaxes] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });

  const OpenConfirmModalTaxes = (data, title, text, onButtonClick) => {
    setSelectedTaxes(data);
    setModalContentTaxes({ title, text, onButtonClick });
    setConfirmOpenTaxes(true);
  };

  const handleConfirmModalTaxes = () => {
    setConfirmOpenTaxes(false);
  };

  const OpenConfirmModalCategory = (data, title, text, onButtonClick) => {
    setSelectedCategory(data);
    setConfirmOpenCategory(true);
  };

  const handleConfirmModalCategory = () => {
    setConfirmOpenCategory(false);
  };
  //

  // Manage Accounts
  const [MangeAccountsAddopen, setMangeAccountsAddopen] = useState(false);
  const [MangeAccountsEditopen, setMangeAccountsEditopen] = useState(false);

  const handleAddMangeAccountsopen = () => {
    setMangeAccountsAddopen(true);
  };

  const handleAddMangeAccountsclose = () => {
    setMangeAccountsAddopen(false);
  };
  const handleEditMangeAccountsopen = () => {
    setMangeAccountsEditopen(true);
  };

  const handleEditMangeAccountsclose = () => {
    setMangeAccountsEditopen(false);
  };

  // Manage Rates
  const [MangeRatesAddopen, setMangeRatesAddopen] = useState(false);
  const [MangeRatesEditopen, setMangeRatesEditopen] = useState(false);
  const [MangeExchangeRatesEditopen, setMangeExchangeRatesEditopen] =
    useState(false);

  const handleAddMangeRatesopen = () => {
    setMangeRatesAddopen(true);
  };
  const handleAddMangeExchangeRatesopen = () => {
    setMangeExchangeRatesAddopen(true);
  };

  const handleAddMangeRatesclose = () => {
    setMangeRatesAddopen(false);
  };
  const handleEditMangeRatesopen = (data) => {
    setSectedRates(data);
    setMangeRatesEditopen(true);
  };

  const handleEditManageExchangeRatesopen = (data) => {
    setSelectedExchangeRates(data);
    setMangeExchangeRatesEditopen(true);
  };

  const handleEditMangeRatesclose = () => {
    setMangeRatesEditopen(false);
  };

  // Manage Costs
  const [MangeExchangeRatesAddopen, setMangeExchangeRatesAddopen] =
    useState(false);
  const [langRes, setLangRes] = useState([]);

  const handleAddMangeExchangeRatesclose = () => {
    setMangeExchangeRatesAddopen(false);
  };
  const handleEditMangeExchangeRatesopen = (data) => {
    setSelectedExchangeRates(data);
    setMangeExchangeRatesEditopen(true);
  };

  const handleEditMangeExchangeRatesclose = () => {
    setMangeExchangeRatesEditopen(false);
  };

  // Manage Wallet
  const [MangeWalletAddopen, setMangeWalletAddopen] = useState(false);
  const [MangeWalletEditopen, setMangeWalletEditopen] = useState(false);

  const handleAddMangeWalletopen = () => {
    setMangeWalletAddopen(true);
  };

  const handleAddMangeWalletclose = () => {
    setMangeWalletAddopen(false);
  };
  const handleEditMangeWalletopen = () => {
    setMangeWalletEditopen(true);
  };

  const handleEditMangeWalletclose = () => {
    setMangeWalletEditopen(false);
  };

  // Manage Taxes
  const [MangeTaxesAddopen, setMangeTaxesAddopen] = useState(false);
  const [MangeTaxesEditopen, setMangeTaxesEditopen] = useState(false);

  const handleAddMangeTaxesopen = () => {
    setMangeTaxesAddopen(true);
  };
  const handleEditMangeTaxesopen = (data) => {
    setMangeTaxesEditopen(true);
    setSelectedTaxes(data);
  };
  const handleTabChangeTaxesTabs = (newTabLabel) => {
    setActiveBtnTaxesTab(newTabLabel);
  };

  const [MangeCategoryAddopen, setMangeCategoryAddopen] = useState(false);
  const [MangeCategoryEditopen, setMangeCategoryEditopen] = useState(false);

  const handleAddMangeCategoryopen = () => {
    setMangeCategoryAddopen(true);
  };
  const handleEditMangeCategoryopen = (data) => {
    setMangeCategoryEditopen(true);
    setSelectedCategory(data);
  };

  useEffect(() => {
    if (activeBtn == "Rates") {
      window.scrollTo(0, 0);
      getAllClientsCategory();
    }
    setClientCategoryRates("");
    setProviderCategoryCosts("");
    setPaginationModelCostsVsRates({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelProfitMargin({
      page: 0,
      pageSize: 10,
    });
  }, [activeBtn]);

  useEffect(() => {
    if (
      activeBtn == "Costs" ||
      activeBtn === "Cost Vs. Rate" ||
      activeBtn === "Profit Margin"
    ) {
      window.scrollTo(0, 0);
      getAllProvidersCategory();
    }
  }, [activeBtn]);

  useEffect(() => {
    setSearchRates("");
    setClientCategoryRates("");
    setClientAccounts("");
    setCurrencies("");
  }, [activeBtn]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="billing">
      <Grid container>
        <Grid item xs={6} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Billing</Typography>
            <Typography className="BreadcrumbsPage">
              {"Configuration"}
            </Typography>

            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid
          // sx={{ marginTop: windowWidth < 800 && "30px" }}
          item
          xs={6}
          md={6}
          className="end"
        >
          {activeBtn === "Currencies" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeCurrenciesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Currency</Typography>
            </Button>
          )}
          {activeBtn === "Type" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeAccountsopen}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Type</Typography>
            </Button>
          )}
          {activeBtn === "Exchange Rates" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeExchangeRatesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                Add Exchange Rate
              </Typography>
            </Button>
          )}

          {activeBtn === "Shortcode" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeAccountsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Shortcode</Typography>
            </Button>
          )}

          {activeBtn === "Longcode" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeAccountsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Longcode</Typography>
            </Button>
          )}
          {activeBtn === "Status" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeAccountsopen}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Status</Typography>
            </Button>
          )}

          {activeBtn === "Rates" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeRatesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Rate</Typography>
            </Button>
          )}
          {activeBtn === "Costs" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeExchangeRatesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Cost</Typography>
            </Button>
          )}

          {/* {activeBtn !== "Costs" && activeBtn !== "Rates" && (
            <Button
              variant="contained"
              className="contained hidden-btn"
              onClick={handleAddMangeExchangeRatesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Cost</Typography>
            </Button>
          )} */}

          {/* {activeBtn === "Profit Margin" && (
            <Button
              variant="contained"
              className="contained"
              onClick={() => handlePopulateBtn("")}
            >
              <Typography sx={{ fontSize: "14px" }}>Populate All</Typography>
            </Button>
          )} */}

          {activeBtn === "Wallet" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeWalletopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add rate</Typography>
            </Button>
          )}
          {activeBtn === "Taxes" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeTaxesopen}
              // disabled={langRes?.length === 0}
              // style={{ cursor: langRes.length === 0 && "not-allowed" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Tax</Typography>
            </Button>
          )}

          {activeBtn === "Category" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeCategoryopen}
              disabled={langRes?.length === 0}
              style={{ cursor: langRes.length === 0 && "not-allowed" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Category</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      {windowWidth < 1000 && (
        <Grid
          style={{ marginLeft: "0px", marginBottom: "-25px" }}
          container
          marginLeft={2}
          marginTop={5}
        >
          <HorizontalTabsSubtabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />
        </Grid>
      )}

      {
        windowWidth > 1000 ? (
          <Grid container className="Top-spacing div-table">
            <Grid
              item
              xs={windowWidth < 1540 ? 2 : 1.5}
              className="vertical-grid"
              sx={{ paddingLeft: "2px" }}
            >
              <VerticalTabs
                tabs={tabs}
                setActiveBtn={setActiveBtn}
                activeBtn={activeBtn}
              />
            </Grid>

            <Grid xs={windowWidth < 1800 ? 10 : 10.5}>
              {/* {activeBtn === "Accounts" && (
                <>
                  <Grid container>
                    <Grid xs={12} item>
                      {localStorage.getItem("roleName") !== "support" ? (
                        // <HorizantalTabs
                        //   tabs={tabsHorizantal}
                        //   setActiveBtn={setActiveBtnHorizantal}
                        // />
                        <></>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid> */}

              <Grid container>
                <Grid xs={12}>
                  {activeBtn === "Type" && (
                    <Paper variant="CardTable" className="tt">
                      <Table
                        disableActions={true}
                        exportFunction={exportAccountsType}
                        rowCountState={tableDataAccountsType?.totalCount}
                        data={tableDataAccountsType?.data}
                        isLoading={isLoading}
                        columns={columnsAccountsType}
                        pageIndex={
                          tableDataAccountsType?.pageIndex
                            ? tableDataAccountsType?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModalAccountsType}
                        paginationModel={paginationModalAccountsType}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalTaxes}
                        editFunction={handleEditMangeTaxesopen}
                        enableSearch={true}
                        search={searchAccountsType}
                        setSearch={setSearchAccountsType}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}

                  {activeBtn === "Exchange Rates" && (
                    <Paper variant="CardTable">
                      <Table
                        // disableActions={true}
                        exportFunction={() => setOpenExportModal(true)}
                        enableExportBtn={true}
                        rowCountState={tableDataExchangeRates?.totalCount}
                        data={tableDataExchangeRates?.data}
                        isLoading={isLoading}
                        columns={columnsExchangeRates}
                        pageIndex={
                          tableDataExchangeRates?.pageIndex
                            ? tableDataExchangeRates?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModalExchangeRates}
                        paginationModel={paginationModalExchangeRates}
                        // enableDelete={true}
                        // enableEdit={true}
                        // deleteFunction={OpenConfirmModalLocation}
                        // editFunction={handleEditMangeLocationopen}
                        // enableSearch={true}
                        search={searchExchangeRates}
                        setSearch={setSearchExchangeRates}
                        advancedSearchFunction={
                          handleOpenAdvancedSearchExchangeRates
                        }
                        handleResetAll={handleResetAllExchangeRates}
                        advancedSearch={openAdvancedSearchExchangeRates}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalExchangeRates}
                        editFunction={handleEditMangeExchangeRatesopen}
                        handleSearchBtn={() => {
                          tableQueryExecuterExchangeRates();
                          setPaginationModalExchangeRates({
                            page: 0,
                            pageSize: paginationModalExchangeRates?.pageSize
                              ? paginationModalExchangeRates?.pageSize
                              : 10,
                          });
                          setSearchClick(true);
                        }}
                        contentAdvancedSearch={
                          <Grid spacing={3} container>
                            {/* <Grid lg={3} xs={12} item>
                              <SelectFormNew
                                value={clientAccounts}
                                setValue={setClientAccounts}
                                options={clientAccountsOptions}
                                placeholder={"Client Account"}
                              />
                            </Grid> */}
                            <Grid lg={3} xs={12} item>
                              <SelectFormNew
                                value={currencies}
                                setValue={setCurrencies}
                                options={currenciesOptions}
                                placeholder={"Currency"}
                              />
                            </Grid>
                          </Grid>
                        }
                      />
                    </Paper>
                  )}

                  {activeBtn === "Shortcode" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataAccountsCategory?.totalCount}
                        data={tableDataAccountsCategory}
                        // isLoading={isLoading}
                        columns={columnsAccountsCategory}
                        pageIndex={
                          tableDataAccountsCategory?.pageIndex
                            ? tableDataAccountsCategory?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalAccounts}
                        editFunction={handleEditMangeAccountsopen}
                        enableSearch={true}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}
                  {activeBtn === "Longcode" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataAccountsCategory?.totalCount}
                        data={tableDataAccountsCategory}
                        // isLoading={isLoading}
                        columns={columnsAccountsCategory}
                        pageIndex={
                          tableDataAccountsCategory?.pageIndex
                            ? tableDataAccountsCategory?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalAccounts}
                        editFunction={handleEditMangeAccountsopen}
                        enableSearch={true}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}
                  {activeBtn === "Status" && (
                    <Paper variant="CardTable">
                      <Table
                        disableActions={true}
                        exportFunction={exportAccountsStatus}
                        rowCountState={tableDataAccountsStatus?.totalCount}
                        data={tableDataAccountsStatus?.data}
                        isLoading={isLoading}
                        columns={columnsAccountsStatus}
                        pageIndex={
                          tableDataAccountsStatus?.pageIndex
                            ? tableDataAccountsStatus?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModalAccountsStatus}
                        paginationModel={paginationModalAccountsStatus}
                        // enableDelete={true}
                        // enableEdit={true}
                        // deleteFunction={OpenConfirmModalLocation}
                        // editFunction={handleEditMangeLocationopen}
                        enableSearch={true}
                        search={searchAccountsStatus}
                        setSearch={setSearchAccountsStatus}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}
                </Grid>
              </Grid>
              {/* </>
              )} */}
              {activeBtn === "Rates" && (
                <Grid container>
                  <Grid item xs={12}>
                    <Paper
                      variant="CardTable"
                      sx={{ borderTopLeftRadius: "20px" }}
                    >
                      <Table
                        rowCountState={tableRates?.totalCount}
                        data={tableRates?.data}
                        isLoading={isLoading}
                        columns={columnsRates}
                        pageIndex={
                          tableRates?.pageIndex ? tableRates?.pageIndex : 0
                        }
                        tableRates={tableRates}
                        setPaginationModel={setPaginationModelRates}
                        paginationModel={paginationModelRates}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalRates}
                        editFunction={handleEditMangeRatesopen}
                        enableSearch={true}
                        search={searchRates}
                        setSearch={setSearchRates}
                        // enableFilter={true}
                        enableImportBtn={true}
                        ImportFunction={() => setopenImportFile(true)}
                        valueFilter={clientCategoryRates}
                        setValueFilter={setClientCategoryRates}
                        optionsFilter={clientCategoryRatesOptions}
                        enableFilter2={true}
                        placeholderFilter="Client Category"
                        enableExportBtn={true}
                        exportFunction={exportRates}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              )}

              {activeBtn === "Costs" && (
                // <Costs activeBtn={activeBtn} />
                <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
                  <Table
                    rowCountState={tableExchangeRates?.totalCount}
                    data={tableExchangeRates?.data}
                    isLoading={isLoading}
                    columns={columnsCosts}
                    pageIndex={
                      tableExchangeRates?.pageIndex
                        ? tableExchangeRates?.pageIndex
                        : 0
                    }
                    tableExchangeRates={tableExchangeRates}
                    setPaginationModel={setPaginationModelCosts}
                    paginationModel={paginationModelCosts}
                    enableDelete={true}
                    enableEdit={true}
                    enableExportBtn={true}
                    exportFunction={exportCosts}
                    deleteFunction={OpenConfirmModalExchangeRates}
                    editFunction={handleEditManageExchangeRatesopen}
                    enableSearch={true}
                    enableFilter2={true}
                    enableImportBtn={true}
                    search={searchCosts}
                    setSearch={setSearchCosts}
                    ImportFunction={() => setopenImportFile(true)}
                    valueFilter={providerCategoryCosts}
                    setValueFilter={setProviderCategoryCosts}
                    optionsFilter={providerCategoryCostsOptions}
                    placeholderFilter="Provider Category"
                  />
                </Paper>
              )}
              {activeBtn === "Wallet" && (
                <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
                  <Table
                    rowCountState={tableDataWallet?.totalCount}
                    data={tableDataWallet}
                    // isLoading={isLoading}
                    columns={columnsWallet}
                    pageIndex={
                      tableDataWallet?.pageIndex
                        ? tableDataWallet?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModel}
                    paginationModel={paginationModel}
                    enableDelete={true}
                    enableEdit={true}
                    enableExportBtn={true}
                    enableImportBtn={true}
                    deleteFunction={OpenConfirmModalWallet}
                    editFunction={handleEditMangeWalletopen}
                    enableSearch={true}
                    valueFilter={Rates}
                    setValueFilter={setRates}
                    optionsFilter={["All", "Default", "vip"]}
                    placeholderFilter="Client Category"
                  />
                </Paper>
              )}
              {activeBtn === "Cost Vs. Rate" && (
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: "10px",
                          marginBottom: "-30px",
                        }}
                      >
                        <Typography
                          style={{
                            margin: "auto 20px auto 0px",
                            width: "125px",
                          }}
                        >
                          Choose Provider
                        </Typography>
                        <div style={{ width: "180px" }}>
                          <SelectFormNew
                            value={providerCategoryCostsGenerateReport}
                            setValue={setProviderCategoryCostsGenerateReport}
                            options={providerCategoryCostsOptionsCosts}
                          />
                        </div>
                        <div
                          style={{
                            margin: "auto 20px auto 20px",
                            width: "200px",
                          }}
                        >
                          <Button
                            variant="contained"
                            className="contained"
                            onClick={handleGenerateReport}
                          >
                            <Typography sx={{ fontSize: "14px" }}>
                              Generate Report
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid item xs={4}> 
                     
                    </Grid> */}
                  </Grid>
                  <Table
                    rowCountState={tableExchangeRatesVsRates?.totalCount}
                    data={tableExchangeRatesVsRates?.data}
                    isLoading={isLoading}
                    columns={columnsCostVsRate}
                    pageIndex={
                      tableExchangeRatesVsRates?.pageIndex
                        ? tableExchangeRatesVsRates?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModelCostsVsRates}
                    paginationModel={paginationModelCostsVsRates}
                    disableActions={true}
                    // valueFilter={providerCategoryCosts}
                    // setValueFilter={setProviderCategoryCosts}
                    // optionsFilter={providerCategoryCostsOptions}
                    // placeholderFilter="Provider Category"
                    // enableFilter2={true}
                  />
                </Paper>
              )}
              {activeBtn === "Profit Margin" && (
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: "10px",
                          marginBottom: "-30px",
                        }}
                      >
                        <Typography
                          style={{
                            margin: "auto 20px auto 0px",
                            width: "125px",
                          }}
                        >
                          Choose Provider
                        </Typography>
                        <div style={{ width: "180px" }}>
                          <SelectFormNew
                            value={
                              providerCategoryCostsGenerateReportProfitMargin
                            }
                            setValue={
                              setProviderCategoryCostsGenerateReportProfitMargin
                            }
                            options={providerCategoryCostsOptionsCosts}
                          />
                        </div>
                        <div
                          style={{
                            margin: "auto 20px auto 20px",
                            width: "200px",
                          }}
                        >
                          <Button
                            variant="contained"
                            className="contained"
                            onClick={handleGenerateReport}
                          >
                            <Typography sx={{ fontSize: "14px" }}>
                              Generate Report
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Table
                    rowCountState={tableDataProfitMargin?.totalCount}
                    data={tableDataProfitMargin?.data}
                    isLoading={isLoading}
                    columns={columnsProfitMargin}
                    pageIndex={
                      tableDataProfitMargin?.pageIndex
                        ? tableDataProfitMargin?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModel}
                    paginationModel={paginationModel}
                    // enableDelete={true}
                    enableEdit={true}
                    // enableExportBtn={true}
                    // enableImportBtn={true}
                    // deleteFunction={OpenConfirmModalWallet}
                    disableActions={true}
                    editFunction={handleOpenEditProfitMarginModal}
                    // valueFilter={providerCategoryCosts}
                    // setValueFilter={setProviderCategoryCosts}
                    // optionsFilter={providerCategoryCostsOptions}
                    // placeholderFilter="Provider Category"
                    // enableFilter2={true}
                  />
                </Paper>
              )}

              {activeBtn === "Category" && (
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                >
                  <Table
                    exportFunction={exportCategory}
                    enableExportBtn={true}
                    enableDelete={true}
                    enableEdit={true}
                    deleteFunction={OpenConfirmModalCategory}
                    editFunction={handleEditMangeCategoryopen}
                    rowCountState={tableDataCategory?.totalCount}
                    data={tableDataCategory?.data}
                    isLoading={isLoading}
                    columns={columnsCategory}
                    pageIndex={
                      tableDataCategory?.pageIndex
                        ? tableDataCategory?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModalCategory}
                    paginationModel={paginationModalCategory}
                    search={searchCategory}
                    setSearch={setSearchCategory}
                    advancedSearchFunction={handleOpenAdvancedSearch}
                    advancedSearch={openAdvancedSearch}
                    // horizontalTabsOptions={taxesTabs}
                    // onTabChange={handleTabChangeTaxesTabs}
                    handleSearchBtn={() => {
                      setRefreshTableCategory(true);
                      tableQueryExecuterCategory(activeBtnTaxesTab);
                      setPaginationModalCategory({
                        page: 0,
                        pageSize: paginationModalCategory?.pageSize
                          ? paginationModalCategory?.pageSize
                          : 10,
                      });
                    }}
                    // enableExportBtn={true}
                  />
                </Paper>
              )}
              {activeBtn === "Taxes" && (
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                >
                  <Table
                    handleResetAll={handleResetAll}
                    exportFunction={() => setOpenExportModalTaxes(true)}
                    enableExportBtn={true}
                    enableDelete={true}
                    enableEdit={true}
                    deleteFunction={OpenConfirmModalTaxes}
                    editFunction={handleEditMangeTaxesopen}
                    rowCountState={tableDataTaxes?.totalCount}
                    data={tableDataTaxes?.data}
                    isLoading={isLoading}
                    columns={columnsTaxes}
                    pageIndex={
                      tableDataTaxes?.pageIndex ? tableDataTaxes?.pageIndex : 0
                    }
                    setPaginationModel={setPaginationModalTaxes}
                    paginationModel={paginationModalTaxes}
                    search={searchTaxes}
                    setSearch={setSearchTaxes}
                    advancedSearchFunction={handleOpenAdvancedSearch}
                    advancedSearch={openAdvancedSearch}
                    // horizontalTabsOptions={taxesTabs}
                    // onTabChange={handleTabChangeTaxesTabs}
                    handleSearchBtn={() => {
                      setRefreshTableTaxes(true);
                      tableQueryExecuterTaxes();
                      setPaginationModalTaxes({
                        page: 0,
                        pageSize: paginationModalTaxes?.pageSize
                          ? paginationModalTaxes?.pageSize
                          : 10,
                      });
                    }}
                    contentAdvancedSearch={
                      <Grid spacing={3} container>
                        <Grid lg={3} xs={12} item>
                          <SelectFormNew
                            value={country}
                            setValue={setCountry}
                            options={countryOptions}
                            placeholder={"Country"}
                            // selected={true}
                            disabled={countryOptions.length === 0}
                          />
                        </Grid>

                        <Grid lg={3} xs={12} item>
                          <TextFieldForm
                            placeholder={"From Date"}
                            value={startDate}
                            // setValue={setStartDate}
                            onChangeFct={handleStartDateChange}
                            type="date"
                            handleClearDateInput={() => setStartDate("")}
                          />
                        </Grid>
                        <Grid lg={3} xs={12} item>
                          <TextFieldForm
                            placeholder={"To Date"}
                            value={endDate}
                            handleClearDateInput={() => setEndDate("")}
                            // onChangeFct={setEndDate}
                            onChangeFct={handleEndDateChange}
                            type="date"
                          />
                        </Grid>
                      </Grid>
                    }
                    // enableExportBtn={true}
                  />
                </Paper>
              )}
              {activeBtn === "Currencies" && (
                <Paper variant="CardTable">
                  <Table
                    rowCountState={tableDataCurrencies?.totalCount}
                    data={tableDataCurrencies?.data}
                    isLoading={isLoading}
                    columns={columnsCurrencies}
                    pageIndex={
                      tableDataCurrencies?.pageIndex
                        ? tableDataCurrencies?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModalCurrencies}
                    paginationModel={paginationModalCurrencies}
                    enableDelete={true}
                    enableEdit={true}
                    enableExportBtn={true}
                    deleteFunction={OpenConfirmModalCurrencies}
                    editFunction={handleEditMangeCurrenciesopen}
                    enableSearch={true}
                    search={searchCurrencies}
                    setSearch={setSearchCurrencies}
                    exportFunction={exportCurrencies}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        ) : (
          // <Grid container className="Top-spacing div-table">

          <Grid xs={12} md={10} lg={10}>
            {/* <Grid container>
              <Grid xs={12} item>
                <HorizantalTabs
                  tabs={tabsHorizantal}
                  setActiveBtn={setActiveBtnHorizantal}
                />
              </Grid>
            </Grid> */}

            <Grid container>
              <Grid xs={12}>
                {activeBtn === "Type" && (
                  <Paper variant="CardTable" className="tt">
                    <Table
                      disableActions={true}
                      // exportFunction={exportAccountsType}
                      rowCountState={tableDataAccountsType?.totalCount}
                      data={tableDataAccountsType?.data}
                      isLoading={isLoading}
                      columns={columnsAccountsType}
                      pageIndex={
                        tableDataAccountsType?.pageIndex
                          ? tableDataAccountsType?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModalAccountsType}
                      paginationModel={paginationModalAccountsType}
                      // enableDelete={true}
                      // enableEdit={true}
                      // deleteFunction={OpenConfirmModalLocation}
                      // editFunction={handleEditMangeLocationopen}
                      enableSearch={true}
                      search={searchAccountsType}
                      setSearch={setSearchAccountsType}
                      // enableExportBtn={true}
                    />
                  </Paper>
                )}
                {activeBtn === "Shortcode" && (
                  <Paper variant="CardTable">
                    <Table
                      rowCountState={tableDataAccountsCategory?.totalCount}
                      data={tableDataAccountsCategory}
                      // isLoading={isLoading}
                      columns={columnsAccountsCategory}
                      pageIndex={
                        tableDataAccountsCategory?.pageIndex
                          ? tableDataAccountsCategory?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModel}
                      paginationModel={paginationModel}
                      enableDelete={true}
                      enableEdit={true}
                      deleteFunction={OpenConfirmModalAccounts}
                      editFunction={handleEditMangeAccountsopen}
                      enableSearch={true}
                      enableExportBtn={true}
                    />
                  </Paper>
                )}
                {activeBtn === "Longcode" && (
                  <Paper variant="CardTable">
                    <Table
                      rowCountState={tableDataAccountsCategory?.totalCount}
                      data={tableDataAccountsCategory}
                      // isLoading={isLoading}
                      columns={columnsAccountsCategory}
                      pageIndex={
                        tableDataAccountsCategory?.pageIndex
                          ? tableDataAccountsCategory?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModel}
                      paginationModel={paginationModel}
                      enableDelete={true}
                      enableEdit={true}
                      deleteFunction={OpenConfirmModalAccounts}
                      editFunction={handleEditMangeAccountsopen}
                      enableSearch={true}
                      enableExportBtn={true}
                    />
                  </Paper>
                )}
                {activeBtn === "Exchange Rates" && (
                  <Paper variant="CardTable">
                    <Table
                      // disableActions={true}
                      // exportFunction={exportZone}
                      exportFunction={() => setOpenExportModal(true)}
                      enableExportBtn={true}
                      handleResetAll={handleResetAllExchangeRates}
                      rowCountState={tableDataExchangeRates?.totalCount}
                      data={tableDataExchangeRates?.data}
                      isLoading={isLoading}
                      columns={columnsExchangeRates}
                      pageIndex={
                        tableDataExchangeRates?.pageIndex
                          ? tableDataExchangeRates?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModalExchangeRates}
                      paginationModel={paginationModalExchangeRates}
                      enableDelete={true}
                      enableEdit={true}
                      deleteFunction={OpenConfirmModalExchangeRates}
                      // deleteFunction={OpenConfirmModalLocation}
                      editFunction={handleEditMangeExchangeRatesopen}
                      enableHiddenSearch={true}
                      search={searchExchangeRates}
                      setSearch={setSearchExchangeRates}
                      // enableExportBtn={true}

                      advancedSearchFunction={
                        handleOpenAdvancedSearchExchangeRates
                      }
                      advancedSearch={openAdvancedSearchExchangeRates}
                      handleSearchBtn={() => {
                        tableQueryExecuterExchangeRates();
                        setPaginationModalExchangeRates({
                          page: 0,
                          pageSize: paginationModalExchangeRates?.pageSize
                            ? paginationModalExchangeRates?.pageSize
                            : 10,
                        });
                      }}
                      contentAdvancedSearch={
                        <Grid spacing={3} container>
                          {/* <Grid lg={3} xs={12} item>
                            <SelectFormNew
                              value={clientAccounts}
                              setValue={setClientAccounts}
                              options={clientAccountsOptions}
                              placeholder={"Client Account"}
                            />
                          </Grid> */}
                          <Grid lg={3} xs={12} item>
                            <SelectFormNew
                              value={currencies}
                              setValue={setCurrencies}
                              options={currenciesOptions}
                              placeholder={"Currency"}
                              extrafct={() =>
                                console.log("sadsadasdasdasdasdasd")
                              }
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                  </Paper>
                )}
                {activeBtn === "Status" && (
                  <Paper variant="CardTable">
                    <Table
                      disableActions={true}
                      // exportFunction={exportZone}
                      rowCountState={tableDataAccountsStatus?.totalCount}
                      data={tableDataAccountsStatus?.data}
                      isLoading={isLoading}
                      columns={columnsAccountsStatus}
                      pageIndex={
                        tableDataAccountsStatus?.pageIndex
                          ? tableDataAccountsStatus?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModalAccountsStatus}
                      paginationModel={paginationModalAccountsStatus}
                      // enableDelete={true}
                      // enableEdit={true}
                      // deleteFunction={OpenConfirmModalLocation}
                      // editFunction={handleEditMangeLocationopen}
                      enableSearch={true}
                      search={searchAccountsStatus}
                      setSearch={setSearchAccountsStatus}
                      enableExportBtn={true}
                    />
                  </Paper>
                )}
              </Grid>
            </Grid>

            {activeBtn === "Rates" && (
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    variant="CardTable"
                    sx={{ borderTopLeftRadius: "20px" }}
                  >
                    <Table
                      rowCountState={tableRates?.totalCount}
                      data={tableRates?.data}
                      isLoading={isLoading}
                      columns={columnsRates}
                      pageIndex={
                        tableRates?.pageIndex ? tableRates?.pageIndex : 0
                      }
                      tableRates={tableRates}
                      setPaginationModel={setPaginationModelRates}
                      paginationModel={paginationModelRates}
                      enableDelete={true}
                      enableEdit={true}
                      deleteFunction={OpenConfirmModalRates}
                      editFunction={handleEditMangeRatesopen}
                      enableSearch={true}
                      search={searchRates}
                      setSearch={setSearchRates}
                      // enableFilter={true}
                      enableImportBtn={true}
                      ImportFunction={() => setopenImportFile(true)}
                      valueFilter={clientCategoryRates}
                      setValueFilter={setClientCategoryRates}
                      optionsFilter={clientCategoryRatesOptions}
                      enableFilter2={true}
                      placeholderFilter="Client Category"
                      enableExportBtn={true}
                      exportFunction={exportRates}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
            {activeBtn === "Costs" && (
              // <Costs activeBtn={activeBtn} />
              <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
                <Table
                  rowCountState={tableExchangeRates?.totalCount}
                  data={tableExchangeRates?.data}
                  isLoading={isLoading}
                  columns={columnsCosts}
                  pageIndex={
                    tableExchangeRates?.pageIndex
                      ? tableExchangeRates?.pageIndex
                      : 0
                  }
                  tableExchangeRates={tableExchangeRates}
                  setPaginationModel={setPaginationModelCosts}
                  paginationModel={paginationModelCosts}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  exportFunction={exportCosts}
                  deleteFunction={OpenConfirmModalExchangeRates}
                  editFunction={handleEditManageExchangeRatesopen}
                  enableSearch={true}
                  enableFilter2={true}
                  enableImportBtn={true}
                  search={searchCosts}
                  setSearch={setSearchCosts}
                  ImportFunction={() => setopenImportFile(true)}
                  valueFilter={providerCategoryCosts}
                  setValueFilter={setProviderCategoryCosts}
                  optionsFilter={providerCategoryCostsOptions}
                  placeholderFilter="Provider Category"
                />
              </Paper>
            )}
            {activeBtn === "Wallet" && (
              <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
                <Table
                  rowCountState={tableDataWallet?.totalCount}
                  data={tableDataWallet}
                  // isLoading={isLoading}
                  columns={columnsWallet}
                  pageIndex={
                    tableDataWallet?.pageIndex ? tableDataWallet?.pageIndex : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  enableImportBtn={true}
                  deleteFunction={OpenConfirmModalWallet}
                  editFunction={handleEditMangeWalletopen}
                  enableSearch={true}
                  valueFilter={Rates}
                  setValueFilter={setRates}
                  optionsFilter={["All", "Default", "vip"]}
                  placeholderFilter="Client Category"
                />
              </Paper>
            )}
            {activeBtn === "Cost Vs. Rate" && (
              <Paper
                variant="CardTable"
                sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "10px",
                        marginBottom: "-30px",
                      }}
                    >
                      <Typography
                        style={{
                          margin: "auto 20px auto 0px",
                          width: "125px",
                        }}
                      >
                        Choose Provider
                      </Typography>
                      <div style={{ width: "180px" }}>
                        <SelectFormNew
                          value={providerCategoryCostsGenerateReport}
                          setValue={setProviderCategoryCostsGenerateReport}
                          options={providerCategoryCostsOptionsCosts}
                        />
                      </div>
                      <div
                        style={{
                          margin: "auto 20px auto 20px",
                          width: "200px",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="contained"
                          onClick={handleGenerateReport}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            Generate Report
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Table
                  rowCountState={tableExchangeRatesVsRates?.totalCount}
                  data={tableExchangeRatesVsRates?.data}
                  isLoading={isLoading}
                  columns={columnsCostVsRate}
                  pageIndex={
                    tableExchangeRatesVsRates?.pageIndex
                      ? tableExchangeRatesVsRates?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModelCostsVsRates}
                  paginationModel={paginationModelCostsVsRates}
                  disableActions={true}
                  // valueFilter={providerCategoryCosts}
                  // setValueFilter={setProviderCategoryCosts}
                  // optionsFilter={providerCategoryCostsOptions}
                  // placeholderFilter="Provider Category"
                  // enableFilter2={true}
                />
              </Paper>
            )}
            {activeBtn === "Profit Margin" && (
              <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "10px",
                        marginBottom: "-30px",
                      }}
                    >
                      <Typography
                        style={{
                          margin: "auto 20px auto 0px",
                          width: "125px",
                        }}
                      >
                        Choose Provider
                      </Typography>
                      <div style={{ width: "180px" }}>
                        <SelectFormNew
                          value={providerCategoryCostsGenerateReport}
                          setValue={setProviderCategoryCostsGenerateReport}
                          options={providerCategoryCostsOptionsCosts}
                        />
                      </div>
                      <div
                        style={{
                          margin: "auto 20px auto 20px",
                          width: "200px",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="contained"
                          onClick={handleGenerateReport}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            Generate Report
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Table
                  rowCountState={tableDataProfitMargin?.totalCount}
                  data={tableDataProfitMargin?.data}
                  isLoading={isLoading}
                  columns={columnsProfitMargin}
                  pageIndex={
                    tableDataProfitMargin?.pageIndex
                      ? tableDataProfitMargin?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  // enableDelete={true}
                  enableEdit={true}
                  // enableExportBtn={true}
                  // enableImportBtn={true}
                  // deleteFunction={OpenConfirmModalWallet}
                  disableActions={true}
                  editFunction={handleOpenEditProfitMarginModal}
                  // valueFilter={providerCategoryCosts}
                  // setValueFilter={setProviderCategoryCosts}
                  // optionsFilter={providerCategoryCostsOptions}
                  // placeholderFilter="Provider Category"
                  // enableFilter2={true}
                />
              </Paper>
            )}
            {activeBtn === "Taxes" && (
              <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
                <Table
                  // disableActions={true}
                  rowCountState={tableDataTaxes?.totalCount}
                  data={tableDataTaxes?.data}
                  isLoading={isLoading}
                  columns={columnsTaxes}
                  pageIndex={
                    tableDataTaxes?.pageIndex ? tableDataTaxes?.pageIndex : 0
                  }
                  setPaginationModel={setPaginationModalTaxes}
                  paginationModel={paginationModalTaxes}
                  enableDelete={true}
                  enableEdit={true}
                  deleteFunction={OpenConfirmModalTaxes}
                  editFunction={handleEditMangeTaxesopen}
                  // enableSearch={true}
                  search={searchTaxes}
                  setSearch={setSearchTaxes}
                  // horizontalTabsOptions={taxesTabs}
                  // onTabChange={handleTabChangeTaxesTabs}
                  exportFunction={() => setOpenExportModalTaxes(true)}
                  enableExportBtn={true}
                />
              </Paper>
            )}
            {activeBtn === "Currencies" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataCurrencies?.totalCount}
                  data={tableDataCurrencies?.data}
                  isLoading={isLoading}
                  columns={columnsCurrencies}
                  pageIndex={
                    tableDataCurrencies?.pageIndex
                      ? tableDataCurrencies?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalCurrencies}
                  paginationModel={paginationModalCurrencies}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  deleteFunction={OpenConfirmModalCurrencies}
                  editFunction={handleEditMangeCurrenciesopen}
                  enableSearch={true}
                  search={searchCurrencies}
                  setSearch={setSearchCurrencies}
                  exportFunction={exportCurrencies}
                />
              </Paper>
            )}
            {activeBtn === "Category" && (
              <Paper
                variant="CardTable"
                sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
              >
                <Table
                  exportFunction={exportCategory}
                  enableExportBtn={true}
                  enableDelete={true}
                  enableEdit={true}
                  deleteFunction={OpenConfirmModalCategory}
                  editFunction={handleEditMangeCategoryopen}
                  rowCountState={tableDataCategory?.totalCount}
                  data={tableDataCategory?.data}
                  isLoading={isLoading}
                  columns={columnsCategory}
                  pageIndex={
                    tableDataCategory?.pageIndex
                      ? tableDataCategory?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalCategory}
                  paginationModel={paginationModalCategory}
                  search={searchCategory}
                  setSearch={setSearchCategory}
                  advancedSearchFunction={handleOpenAdvancedSearch}
                  advancedSearch={openAdvancedSearch}
                  // horizontalTabsOptions={taxesTabs}
                  // onTabChange={handleTabChangeTaxesTabs}
                  handleSearchBtn={() => {
                    setRefreshTableCategory(true);
                    tableQueryExecuterCategory(activeBtnTaxesTab);
                    setPaginationModalCategory({
                      page: 0,
                      pageSize: paginationModalCategory?.pageSize
                        ? paginationModalCategory?.pageSize
                        : 10,
                    });
                  }}
                  // enableExportBtn={true}
                />
              </Paper>
            )}
          </Grid>
        )
        // </Grid>
      }

      {/* Delete Accounts Modal */}
      {handleConfirmModalAccounts && (
        <ConfirmationModal
          open={ConfirmOpenAccounts}
          onClose={handleConfirmModalAccounts}
          title="Delete"
          text="Are you sure you want to delete this cost?"
          onButtonClick={DeleteExchangeRates}
        />
      )}
      {/* Delete Rates Modal */}
      {ConfirmOpenRates && (
        <ConfirmationModal
          open={ConfirmOpenRates}
          onClose={handleModalCloseSwitchRates}
          title="Delete"
          text="Are you sure you want to delete this rate?"
          onButtonClick={DeleteRate}
        />
      )}
      {/* Delete Costs Modal */}
      {handleConfirmModalExchangeRates && (
        <ConfirmationModal
          open={ConfirmOpenExchangeRates}
          onClose={handleConfirmModalExchangeRates}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={DeleteExchangeRates}
        />
      )}
      {/* Delete Wallet Modal */}
      {handleConfirmModalWallet && (
        <ConfirmationModal
          open={ConfirmOpenWallet}
          onClose={handleConfirmModalWallet}
          title="Delete"
          text="Are you sure you want to delete this Wallet ?"
          onButtonClick={handleConfirmModalWallet}
        />
      )}
      {/* Delete Taxes Modal */}
      {handleConfirmModalTaxes && (
        <ConfirmationModal
          open={ConfirmOpenTaxes}
          onClose={handleConfirmModalTaxes}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={DeleteTaxes}
        />
      )}
      {handleConfirmModalCategory && (
        <ConfirmationModal
          open={ConfirmOpenCategory}
          onClose={handleConfirmModalCategory}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={DeleteCategory}
        />
      )}
      {/* Manage Accounts Modal */}
      {handleAddMangeAccountsopen && (
        <ManageAccounts
          open={MangeAccountsAddopen}
          setOpen={setMangeAccountsAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeAccountsopen && (
        <ManageAccounts
          open={MangeAccountsEditopen}
          setOpen={setMangeAccountsEditopen}
          title={"Edit"}
          CostData={tableExchangeRates?.data}
        />
      )}

      {/* Manage Rates Modal */}
      {MangeRatesAddopen && (
        <ManageRates
          open={MangeRatesAddopen}
          setOpen={setMangeRatesAddopen}
          title={"Add"}
          setClientCategoryOptions={setClientCategoryOptions}
          ClientCategoryOptions={ClientCategoryOptions}
          refreshTableRates={refreshTableRates}
          setRefreshTableRates={setRefreshTableRates}
          setAlertMessage={setAlertMessage}
          setOpenAlert={setOpenAlert}
        />
      )}
      {MangeRatesEditopen && (
        <ManageRates
          open={MangeRatesEditopen}
          setOpen={setMangeRatesEditopen}
          title={"Edit"}
          setOpenAlert={setOpenAlert}
          selectedRates={selectedRates}
          ClientCategoryOptions={ClientCategoryOptions}
          setClientCategoryOptions={setClientCategoryOptions}
          refreshTableRates={refreshTableRates}
          setRefreshTableRates={setRefreshTableRates}
          setAlertMessage={setAlertMessage}
          isEdit={true}
        />
      )}
      {/* Manage Costs Modal */}
      {handleAddMangeExchangeRatesopen && (
        <ManageExchangeRates
          open={MangeExchangeRatesAddopen}
          setOpen={setMangeExchangeRatesAddopen}
          setOpenAlert={setOpenAlert}
          title={"Add"}
          clientAccountsOptions={clientAccountsOptions}
          currenciesOptions={currenciesOptions}
          setPaginationModalExchangeRates={setPaginationModalExchangeRates}
          // selectedExchangeRates={selectedExchangeRates}
          paginationModalExchangeRates={paginationModalExchangeRates}
          isEdit={false}
          systemCurrency={systemCurrency}
        />
      )}
      {handleEditMangeExchangeRatesopen && (
        <ManageExchangeRates
          open={MangeExchangeRatesEditopen}
          setOpen={setMangeExchangeRatesEditopen}
          title={"Edit"}
          isEdit={true}
          clientAccountsOptions={clientAccountsOptions}
          currenciesOptions={currenciesOptions}
          setPaginationModalExchangeRates={setPaginationModalExchangeRates}
          selectedExchangeRates={selectedExchangeRates}
          paginationModalExchangeRates={paginationModalExchangeRates}
          systemCurrency={systemCurrency}
        />
      )}

      {/* Manage Wallet Modal */}
      {handleAddMangeWalletopen && (
        <ManageWallet
          open={MangeWalletAddopen}
          setOpen={setMangeWalletAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeWalletopen && (
        <ManageWallet
          open={MangeWalletEditopen}
          setOpen={setMangeWalletEditopen}
          title={"Edit"}
        />
      )}

      {/* Manage Taxes Modal */}
      {handleAddMangeTaxesopen && (
        <ManageTaxes
          language={language}
          open={MangeTaxesAddopen}
          setOpen={setMangeTaxesAddopen}
          title={"Add"}
          setPaginationModalTaxes={setPaginationModalTaxes}
          paginationModalTaxes={paginationModalTaxes}
          setRefreshTableTaxes={setRefreshTableTaxes}
          taxesTabs={taxesTabs}
          langRes={langRes}
          countryOptions={countryOptions}
        />
      )}
      {handleEditMangeTaxesopen && (
        <ManageTaxes
          language={language}
          open={MangeTaxesEditopen}
          setOpen={setMangeTaxesEditopen}
          title={"Edit"}
          isEdit={true}
          selectedTaxes={selectedTaxes}
          setPaginationModalTaxes={setPaginationModalTaxes}
          paginationModalTaxes={paginationModalTaxes}
          setRefreshTableTaxes={setRefreshTableTaxes}
          taxesTabs={taxesTabs}
          langRes={langRes}
          countryOptions={countryOptions}
        />
      )}

      {handleAddMangeCategoryopen && (
        <ManageCategory
          language={language}
          open={MangeCategoryAddopen}
          setOpen={setMangeCategoryAddopen}
          title={"Add"}
          setPaginationModalCategory={setPaginationModalCategory}
          paginationModalCategory={paginationModalCategory}
          setRefreshTableCategory={setRefreshTableCategory}
          taxesTabs={taxesTabs}
          langRes={langRes}
          countryOptions={countryOptions}
        />
      )}
      {handleEditMangeCategoryopen && (
        <ManageCategory
          language={language}
          open={MangeCategoryEditopen}
          setOpen={setMangeCategoryEditopen}
          title={"Edit"}
          isEdit={true}
          selectedCategory={selectedCategory}
          setPaginationModalCategory={setPaginationModalCategory}
          paginationModalCategory={paginationModalCategory}
          setRefreshTableCategory={setRefreshTableCategory}
          taxesTabs={taxesTabs}
          langRes={langRes}
          countryOptions={countryOptions}
        />
      )}

      {/* Swtich Accounts Modal */}
      {isModalOpenSwitchAccounts && (
        <ConfirmationModal
          open={isModalOpenSwitchAccounts}
          onClose={handleModalCloseSwitchAccounts}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchAccounts}
        />
      )}
      {/* Swtich Rates Modal */}
      {isModalOpenSwitchRates && (
        <ConfirmationModal
          open={isModalOpenSwitchRates}
          onClose={handleModalCloseSwitchRates}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={DeleteRate}
        />
      )}
      {/* Swtich Costs Modal */}
      {isModalOpenSwitchCosts && (
        <ConfirmationModal
          open={isModalOpenSwitchCosts}
          onClose={handleModalCloseSwitchCosts}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchCosts}
        />
      )}
      {/* Swtich Wallet Modal */}
      {isModalOpenSwitchWallet && (
        <ConfirmationModal
          open={isModalOpenSwitchWallet}
          onClose={handleModalCloseSwitchWallet}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchWallet}
        />
      )}

      {/* Swtich Taxes Modal */}
      {isModalOpenSwitchTaxes && (
        <ConfirmationModal
          open={isModalOpenSwitchTaxes}
          onClose={handleModalCloseSwitchTaxes}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchTaxes}
        />
      )}
      {openEditProfitMargin && (
        <ManageProfitMargin
          open={openEditProfitMargin}
          setOpen={handleOpenEditProfitMarginModal}
          onButtonClick={handleOpenEditProfitMarginModal}
          title={"Edit"}
        />
      )}
      {isModalOpenSwitchCurrencies && (
        <ConfirmationModal
          open={isModalOpenSwitchCurrencies}
          onClose={handleModalCloseSwitchCurrencies}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCurrencies}
        />
      )}
      {handleConfirmModalCurrencies && (
        <ConfirmationModal
          open={ConfirmOpenCurrencies}
          onClose={handleConfirmModalCurrencies}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={handleConfirmModalCurrenciesDelete}
        />
      )}
      {handleAddMangeCurrenciesopen && (
        <ManageCurrencies
          open={MangeCurrenciesAddopen}
          setOpen={setMangeCurrenciesAddopen}
          title={"Add Currency"}
          // selectedCurrencies={selectedCurrencies}
          setPaginationModalCurrencies={setPaginationModalCurrencies}
          setRefreshTableCurrencies={setRefreshTableCurrencies}
          CountryOptions={countryOptions}
          paginationModalCurrencies={paginationModalCurrencies}
        />
      )}
      {handleEditMangeCurrenciesopen && (
        <ManageCurrencies
          open={MangeCurrenciesEditopen}
          setOpen={setMangeCurrenciesEditopen}
          title={"Edit Currency"}
          selectedCurrencies={selectedCurrencies}
          setPaginationModalCurrencies={setPaginationModalCurrencies}
          setRefreshTableCurrencies={setRefreshTableCurrencies}
          isEdit={true}
          CountryOptions={countryOptions}
          paginationModalCurrencies={paginationModalCurrencies}
        />
      )}
      <CustomizedDialogs
        open={openImportFile}
        setOpen={setopenImportFile}
        title="Import File"
        // firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid style={{ marginBottom: "15px" }} item xs={12}>
                <SelectFormNew
                  value={
                    activeBtn == "Rates"
                      ? clientCategoryImport
                      : providerCategoryImport
                  }
                  setValue={
                    activeBtn == "Rates"
                      ? setClientCategoryImport
                      : activeBtn == "Costs"
                      ? setProviderCategoryImport
                      : ""
                  }
                  options={
                    activeBtn == "Rates"
                      ? clientCategoryImportOptions
                      : activeBtn == "Costs"
                      ? providerCategoryImportOptions
                      : ""
                  }
                  placeholder={
                    activeBtn == "Rates"
                      ? "Client Category"
                      : activeBtn == "Costs"
                      ? "Provider Category"
                      : ""
                  }
                />
              </Grid>
              <MyDropzone setFile={setFile} file={file} />
              <Grid className="end" marginBottom={0} marginTop={3} container>
                <Button
                  onClick={submitFile}
                  variant="contained"
                  className="contained"
                  disabled={
                    activeBtn === "Rates"
                      ? clientCategoryImport === "" || file === ""
                      : providerCategoryImport === "" || file === ""
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMesssage}
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportRates}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          your file was exported Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportRatesError}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          your file was not exported
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportCostError}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          your file was not exported
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportCost}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          your file was exported Successfully
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openImportAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {importMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openImportSuccessAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {importSuccessMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
      {openExportModal && (
        <CustomizedDialogs
          open={openExportModal}
          setOpen={setOpenExportModal}
          title={"Export Data "}
          firstActionBtnName={"All Data"}
          secondActionBtnName={"Filtered Data"}
          firstActionBtnFct={() => {
            exportExchangeRates(false); // Added parentheses to invoke the function
          }}
          secondActionBtn={() => {
            // setSearchClick(true);
            exportExchangeRates(true); // Added parentheses to invoke the function
          }}
          content={
            <Typography style={{ textAlign: "center" }}>
              Do you want to export all data ?
            </Typography>
          }
        />
      )}

      {openExportModalTaxes && (
        <CustomizedDialogs
          open={openExportModalTaxes}
          setOpen={setOpenExportModalTaxes}
          title={"Export Data "}
          firstActionBtnName={"All Data"}
          secondActionBtnName={"Filtered Data"}
          firstActionBtnFct={() => {
            exportTaxes(false); // Added parentheses to invoke the function
          }}
          secondActionBtn={() => {
            // setSearchClick(true);
            exportTaxes(true); // Added parentheses to invoke the function
          }}
          content={
            <Typography style={{ textAlign: "center" }}>
              Do you want to export all data ?
            </Typography>
          }
        />
      )}
    </div>
  );
};

export default BillingConfig;
