import Add from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../../Components/Dialog/ConfirmationModal";
import Table from "../../../../Components/DynamicTable/Table";
import TextFieldForm from "../../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../../axiosInstance";

import CustomizedDialogs from "../../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../../Components/Selects/SelectFormNew";
import {
  EXPORT_SMS_TWO_WAY_LONG,
  EXPORT_SMS_TWO_WAY_SHORT,
  GET_ALL_CLIENTS_CATEGORY,
  GET_ALL_CLIENTS_ID,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_OPERATORS_OPTIONS,
  GET_ALL_PROVIDER,
  GET_ALL_REASONS,
  GET_ALL_SENDER_IDS_OPTIONS,
  MULTI_TENANCY_CONFIG,
  TWO_WAY,
  TWO_WAY_SHORT_CODE,
} from "../../../APIs";
import ManageShortCode from "./ManageShortCode";
import Test from "./test";
const ShortCode = ({ serviceGuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [rowsSelectedData, setRowsSelectedData] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  // Resellers
  const [searchClick, setSearchClick] = useState(false);

  const [isModalOpenSwitchSmsOneWay, setModalOpenSmsOneWay] = useState(false);
  const [isCheckedSwitchSmsOneWay, setCheckedSwitchSmsOneWay] = useState(false);
  const [switchStatesSmsOneWay, setswitchStatesSmsOneWay] = useState({});
  const [MangeSmsOneWayAddopen, setMangeSmsOneWayAddopen] = useState(false);
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState(false);

  const [MangeSmsOneWayEditopen, setMangeSmsOneWayEditopen] = useState(false);
  const [clientCategorySearch, setClientCategorySearch] = useState("");
  const [ClientCategoryOptions, setClientCategoryOptions] = useState([]);
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [resetPagination, setResetPagination] = useState(false);

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  const [clientCategoryOptionsSearch, setClientCategoryOptionsSearch] =
    useState([]);

  const [countryOptionsSearch, setCountryOptionsSearch] = useState(false);
  const [senderIdsOptionsSearch, setSenderIdsOptionsSearch] = useState([]);
  const [senderIdsSearch, setSenderIdsSearch] = useState("");
  const [reason, setReason] = useState("");
  const [reasonOptions, setReasonOptions] = useState(false);
  const [description, setDescription] = useState("");

  const [countryOptionsRes, setCountryOptionsRes] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [providerOptionsSearch, seProviderOptionsSearch] = useState(false);
  const [operatorOptionsSearch, setOperatorOptionsSearch] = useState([]);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);
  const [isLoadingSenderIds, setIsLoadingSenderIds] = useState(false);

  const [operatorSearch, setOperatorSearch] = useState("");
  const [countrySearch, setCountrySearch] = useState("");
  const [isoCode, setIsoCode] = useState("");

  const [providerSearch, setProviderSearch] = useState("");
  const [block, setBlock] = useState("");
  const [lock, setLock] = useState("");

  const [blockOptions, setblockOptions] = useState([
    { label: "All", value: "" },
    { label: "Blocked", value: "Block" },
    { label: "Unblocked", value: "Unblock" },
  ]);
  const [lockOptions, setLockOptions] = useState([
    { label: "All", value: "" },
    { label: "Locked", value: "Lock" },
    { label: "Unlocked", value: "Unlock" },
  ]);
  const [mncSearch, setMncSearch] = useState("");
  const [mccSearch, setMccSearch] = useState("");
  const [filteredData, setFilteredData] = useState({});

  const handleSwitchChangeSwitchSmsOneWay = (rowId) => {
    setModalOpenSmsOneWay(true);
    setCheckedSwitchSmsOneWay(rowId);
  };

  const handleModalCloseSwitchSmsOneWay = () => {
    setModalOpenSmsOneWay(false);
  };
  const handleModalConfirmSwitchSmsOneWay = () => {
    setModalOpenSmsOneWay(false);
    // setCheckedSwitchSmsOneWay(!isCheckedSwitchSmsOneWay);
    setswitchStatesSmsOneWay((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchSmsOneWay]: !prevStates[isCheckedSwitchSmsOneWay],
    }));
  };

  //
  const [Client, setClient] = React.useState("9mobile");
  const [isCheckedSwitchLocationZone, setCheckedSwitchLocationZone] =
    useState(false);
  const [isModalOpenSwitchLocationZone, setModalOpenSwitchLocationZone] =
    useState(false);

  const [isCheckedSwitchLock, setCheckedSwitchLock] = useState(false);
  const [isModalOpenSwitchLock, setModalOpenSwitchLock] = useState(false);
  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [SmsOneWay, setSmsOneWay] = React.useState("9mobile");
  const handleSwitchChangeSwitchLocationZone = (data) => {
    setReasonOptions(false);
    getAllReasons(
      data?.isBlocked ? "ROUTING_UNBLOCK_REASON" : "ROUTING_BLOCK_REASON"
    );
    setModalOpenSwitchLocationZone(!isModalOpenSwitchLocationZone);
    setCheckedSwitchLocationZone(data?.recordGuid);
    setSelectedSms(data);
  };

  const handleSwitchChangeSwitchLock = (data) => {
    setReasonOptions(false);

    getAllReasons(
      data?.isLocked ? "ROUTING_UNLOCK_REASON" : "ROUTING_LOCK_REASON"
    );
    setModalOpenSwitchLock(!isModalOpenSwitchLock);
    setCheckedSwitchLock(data?.recordGuid);
    setSelectedSms(data);
  };
  const handleChangeSmsOneWay = (event) => {
    setSmsOneWay(event.target.value);
  };
  const [switchStatesLocationZone, setSwitchStatesLocationZone] = useState({});
  const [switchStatesLock, setSwitchStatesLock] = useState({});
  const [clientCategoryRatesOptions, setClientCategoryRatesOptions] = useState(
    []
  );
  const [openExportModal, setOpenExportModal] = useState(false);

  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);

  const [tableDataSmsOneWay, setTableDataSmsOneWay] = useState({
    pageIndex: 1,
    data: [],
  });

  let columnsSmsOneWay = [
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "countryName",
      headerName: "Country",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "providerName",
      headerName: "Provider",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Block",
      field: "isBlocked",
      minWidth: 100,

      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.row?.isBlocked}
                  onChange={() =>
                    handleSwitchChangeSwitchLocationZone(params.row)
                  }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      headerName: "Lock",
      field: "isLocked",
      minWidth: 100,

      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.row?.isLocked}
                  onChange={() => handleSwitchChangeSwitchLock(params.row)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Delete Modal Resellers functions
  const [ConfirmOpenSmsOneWay, setConfirmOpenSmsOneWay] = useState(false);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(
    windowWidth > 1000 ? true : false
  );
  const [smsOneWaySearch, setSmsOneWaySearch] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [modalContentSmsOneWay, setmodalContentSmsOneWay] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${TWO_WAY_SHORT_CODE}/${selectedSms?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTable(true);
        setConfirmOpenSmsOneWay(false);
        setIsLoading(false);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllReasons = (type) => {
    setReason("");
    setDescription("");
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let body = {
      categoryTags: [type],
    };

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_REASONS}?categoryTags=${[type]}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setReasonOptions([
          ...res?.data?.data?.criteria?.map((x, i) => ({
            label: x.name,
            value: x.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllReasons(type);
        }
      })
      .finally(() => {});
  };
  const handleMultiDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let data = rowsSelectedData?.map((x) => x.recordGuid);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${TWO_WAY_SHORT_CODE}`,
        {
          data: { recordGuids: data }, // or simply: data
          ...MULTI_TENANCY_CONFIG,
        }
      )
      .then((res) => {
        setRefreshTable(true);
        setDeleteAllModalOpen(false);
        setIsLoading(false);
        setSelectedRows([]);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleMultiDelete()
        }
        else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalSmsOneWay = (data, title, text, onButtonClick) => {
    setmodalContentSmsOneWay({ title, text, onButtonClick });
    setConfirmOpenSmsOneWay(true);
    setSelectedSms(data);
  };

  const handleConfirmModalSmsOneWay = () => {
    setConfirmOpenSmsOneWay(false);
  };
  const handleOpenAdvancedSearch = () => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  };
  const handleResetAll = () => {
    setSearchClick(false);
    setMccSearch("");
    setMncSearch("");
    setProviderSearch("");
    setCountrySearch("");
    setOperatorSearch("");
    setBlock("");
    setLock("");
    // setClientCategorySearch(clientCategoryOptionsSearch[0].value);
    setRefreshTable(true);
  };
  //
  // Manage Resellers
  const [ManageSmsOneWayAddopen, setManageSmsOneWayAddopen] = useState(false);
  const [ManageSmsOneWayEditopen, setManageSmsOneWayEditopen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [CountryOptionsRecordGuid, setCountryOptionsRecordGuid] = useState([]);
  const [selectedSms, setSelectedSms] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [providerCategoryOptions, setProviderCategoryOptions] = useState([]);
  const handleAddManageSmsOneWayopen = () => {
    setManageSmsOneWayAddopen(true);
  };
  const handleModalConfirmSwitchBlock = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      recordGuid: selectedSms?.recordGuid,
      ReasonGuid: reason,
      Description: description ? description : "",
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${TWO_WAY_SHORT_CODE}/${
          selectedSms?.isBlocked === true ? "Unblock" : "Block"
        }`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Updated",
          type: "success",
        });
        setRefreshTable(true);
        setModalOpenSwitchLocationZone(false);
        // setRefreshTable(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchBlock(isModalOpenSwitchLock);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleModalConfirmSwitchLock = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      recordGuid: selectedSms?.recordGuid,
      ReasonGuid: reason,
      Description: description ? description : "",
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${TWO_WAY_SHORT_CODE}/${
          selectedSms?.isLocked === true ? "Unlock" : "Lock"
        }`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Updated",
          type: "success",
        });

        setRefreshTable(true);
        setModalOpenSwitchLock(false);
        // setRefreshTable(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLock(isModalOpenSwitchLock);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterSmsOneWay = () => {
    setIsLoading(true);
    setSelectedRows([]);

    setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${TWO_WAY_SHORT_CODE}?PageIndex=${
          resetPagination ? 1 : paginationModel?.page + 1
        }&PageSize=${paginationModel?.pageSize}&ClientGuid=${
          clientCategorySearch?.recordGuid
        }&CountryGuid=${countrySearch ? countrySearch : ""}&SenderGuid=${
          senderIdsSearch ? senderIdsSearch : ""
        }&ProviderGuid=${providerSearch ? providerSearch : ""}&Blocked=${
          block === "Block" ? true : block === "Unblock" ? false : ""
        }&Locked=${
          lock === "Lock" ? true : lock === "Unlock" ? false : ""
        }&Mcc=${mccSearch ? mccSearch : ""}&Mnc=${
          mncSearch ? mncSearch : ""
        }&Keyword=${smsOneWaySearch ? smsOneWaySearch : ""}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataSmsOneWay({
          data: res?.data?.data?.data ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
        setFilteredData({
          client: clientCategorySearch?.recordGuid,
          countrySearch: countrySearch,
          senderId: senderIdsSearch,
          providerSearch: providerSearch,
          block: block === "Block" ? true : block === "Unblock" ? false : "",
          lock: lock === "Lock" ? true : lock === "Unlock" ? false : "",
          mcc: mccSearch,
          mnc: mncSearch,
          smsOneWaySearch: smsOneWaySearch,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterSmsOneWay();
        }
        setTableDataSmsOneWay({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackToTable = () => {
    setManageSmsOneWayAddopen(false);
    setManageSmsOneWayEditopen(false);
  };
  const handleAddMangeRequestclose = () => {
    setManageSmsOneWayAddopen(false);
  };
  const handleEditManageSmsOneWayopen = () => {
    setManageSmsOneWayEditopen(true);
  };

  const handleEditMangeRequestclose = () => {
    setManageSmsOneWayEditopen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const handleModalCloseSwitchLocationZone = () => {
    setModalOpenSwitchLocationZone(false);
  };
  const handleModalCloseSwitchLock = () => {
    setModalOpenSwitchLock(false);
  };
  const handleModalCloseDeleteAll = () => {
    setDeleteAllModalOpen(false);
  };
  const handleAddMangeSmsOneWayopen = () => {
    setMangeSmsOneWayAddopen(true);
  };
  const handleDeleteAllopen = () => {
    setDeleteAllModalOpen(true);
  };
  const handleEditMangeSmsOneWayopen = (data) => {
    setSelectedSms(data);
    setMangeSmsOneWayEditopen(true);
  };

  const getAllProvidersCategory = () => {
    seProviderOptionsSearch(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .get(
        `${localStorage.getItem(
          "baseUrl"
        )}${GET_ALL_PROVIDER}?RecordGuid=${serviceGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        seProviderOptionsSearch([
          ...res?.data?.data?.items.map((x, i) => ({
            label: x.name ? x.name : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryOptions([
          ...res?.data?.data?.items.map((x, i) => ({
            label: x.name ? x.name : "",
            value: x.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProvidersCategory();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const getAllClientsCategory = () => {
  //   let newServices = JSON.parse(services);
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   setIsLoading(true);
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["members"]
  //       }${GET_ALL_CLIENTS_ID}?PageIndex=1&PageSize=50`,
  //       // body,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setClientCategoryOptionsSearch([
  //         ...res?.data?.data?.clients?.map((x, i) => ({
  //           label: x.name,
  //           value: x.recordGuid,
  //         })),
  //       ]);
  //       // setClientCategorySearch(
  //       //   res?.data?.data?.clients
  //       //     ? res?.data?.data?.clients[0]?.recordGuid
  //       //     : ""
  //       // );
  //       setIsLoading(true);
  //       tableQueryExecuterSmsOneWay(res?.data?.data?.clients[0]?.recordGuid);

  //       // this.setState({
  //       //   clientIdOptionsRes: res?.data?.data?.clients.map((x, i) => ({
  //       //     text: x.name
  //       //       ? x.name[0]?.name
  //       //       : "",
  //       //     value: x.recordGuid,
  //       //     checked: i === 0 ? true : false,
  //       //   })),
  //       //   clientIdOptions: [
  //       //     ...this.state.clientIdOptions,
  //       //     ...res?.data?.data?.clients.map((x, i) => ({
  //       //       text: x.name
  //       //         ? x.name[0]?.name
  //       //         : "",
  //       //       value: x.recordGuid,
  //       //       checked: false,
  //       //     })),
  //       //   ],
  //       //   client: res?.data?.data?.clients[0].recordGuid,
  //       // });
  //     })
  //     .catch((err) => {
  //       if (err === "error: 401") {
  //         getAllClientsCategory();
  //       }
  //     })
  //     .finally(() => {});
  // };
  const getAllCountries = () => {
    setCountryOptionsSearch(false);
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptionsSearch(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryOptionsRes(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.recordGuid,
            value: x?.isoCode,
          }))
        );
        setOperatorOptions([]);
        setOperatorSearch("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  // const getAllOperatorName = () => {
  //   setOperatorOptionsSearch([]);
  //   setIsLoadingOperator(true);
  //   let newServices = JSON.parse(services);
  //   {
  //     setOperatorSearch("");
  //   }

  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["configuration"]
  //       }${GET_ALL_OPERATORS_OPTIONS}?CountryIso=${isoCode}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setIsLoadingOperator(false);

  //       setOperatorOptionsSearch(
  //         res?.data?.data?.items.map((x) => ({
  //           label: x?.name,
  //           value: x?.recordGuid,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       if (err === "error: 401") {
  //         this.getAllOperatorName();
  //       }
  //       // toast.error(
  //       //   err?.response?.data?.message ??
  //       //     "An Error Ocurred Retrieving Countries"
  //       // );
  //       setIsLoadingOperator(false);
  //     })
  //     .finally(() => {
  //       setIsLoadingOperator(false);

  //       // this.setState({ loading: false, resetPagination: false });
  //     });
  // };
  const getAllSenerIds = () => {
    setSenderIdsOptionsSearch([]);
    setIsLoadingSenderIds(true);
    setSenderIdsSearch("");
    const headers = {
      Tenant: localStorage.getItem("tenant"),
      CountryCode: isoCode,
      ClientGuid: clientCategorySearch?.recordGuid,
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem(
          "baseUrl"
        )}${GET_ALL_SENDER_IDS_OPTIONS}?CountryCode=${isoCode}&ClientGuid=${
          clientCategorySearch?.recordGuid
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingSenderIds(false);

        setSenderIdsOptionsSearch(
          res?.data?.data?.senders?.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllSenerIds();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
        setIsLoadingSenderIds(false);
      })
      .finally(() => {
        setIsLoadingSenderIds(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };
  useEffect(() => {
    if (isoCode !== "") {
      setOperatorOptions([]);
      setSenderIdsOptionsSearch([]);
      // getAllOperatorName();
      // getAllSenerIds();
    }
  }, [isoCode]);

  useEffect(() => {
    if (isoCode !== "" && clientCategorySearch?.recordGuid !== "") {
      setSenderIdsOptionsSearch([]);
      // getAllOperatorName();
      getAllSenerIds();
    }
  }, [isoCode, clientCategorySearch]);

  useEffect(() => {
    setOperatorOptionsSearch([]);
    setSenderIdsOptionsSearch([]);
    setSenderIdsSearch("");
    if (countrySearch !== "") {
      setOperatorOptions([]);
      setSenderIdsOptionsSearch([]);
      let isoCodefinded = countryOptionsRes?.find(
        (x) => x.label === countrySearch
      )?.value;
      setIsoCode(isoCodefinded);
    } else {
      setIsoCode("");
    }
  }, [countrySearch]);
  useEffect(() => {
    // getAllClientsCategory();
    // getAllProvidersCategory();
    getAllCountries();
    // getAllOperatorName();
  }, []);
  useEffect(() => {
    if (serviceGuid) {
      getAllProvidersCategory();
    }
  }, [serviceGuid]);
  useEffect(() => {
    if (smsOneWaySearch !== "") {
      setResetPagination(true);
    } else {
      setResetPagination(false);
    }
  }, [smsOneWaySearch]);

  useEffect(() => {
    if (smsOneWaySearch !== "") {
      setPaginationModel({
        page: 0,
        pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
      });
    }
  }, [smsOneWaySearch]);

  useEffect(() => {
    if (clientCategorySearch !== "") {
      tableQueryExecuterSmsOneWay();
      setSelectedRows([]);
    }
    if (smsOneWaySearch === "" && clientCategorySearch !== "") {
      tableQueryExecuterSmsOneWay();
    }
  }, [refreshTable, paginationModel, smsOneWaySearch]);
  const exportSms = (searchClick) => {
    setIsLoading(true);
    setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        !searchClick
          ? `${localStorage.getItem("baseUrl")}${
              newServices["routing"]
            }${EXPORT_SMS_TWO_WAY_SHORT}?ClientGuid=${
              filteredData?.client ? filteredData?.client : ""
            }`
          : `${localStorage.getItem("baseUrl")}${
              newServices["routing"]
            }${EXPORT_SMS_TWO_WAY_SHORT}?ClientGuid=${
              filteredData?.client ? filteredData?.client : ""
            }&CountryGuid=${
              filteredData?.countrySearch ? filteredData?.countrySearch : ""
            }&SenderGuid=${
              filteredData?.senderId ? filteredData?.senderId : ""
            }&ProviderGuid=${
              filteredData?.providerSearch ? filteredData?.providerSearch : ""
            }&Blocked=${filteredData?.block}&Locked=${filteredData?.lock}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenExportModal(false);
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Shortcode.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setOpenExportModal(false);
        setIsLoading(false);
      });
  };
  return (
    <div>
      <Grid style={{ marginTop: "-50px" }} container>
        <Grid item xs={12} md={6} className="centerresponsive"></Grid>
        <Grid item xs={12} md={6} className="end">
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} className="end"></Grid>

            <Grid item xs={12} md={8} className="end">
              <Button
                variant="contained"
                className="contained"
                onClick={handleDeleteAllopen}
                style={{
                  transition: "all .2s",
                  marginRight: "8px",
                  opacity: selectedRows.length === 0 ? "0" : "1",
                  visibility: selectedRows.length === 0 && "hidden",
                }}
              >
                <DeleteIcon sx={{ fontSize: "17px" }} /> &nbsp;
                <Typography
                  sx={{ fontSize: "14px", marginRight: "3px", padding: "2px" }}
                >
                  Delete All
                </Typography>
              </Button>
              <Button
                variant="contained"
                className="contained add-code-btn"
                onClick={handleAddMangeSmsOneWayopen}
              >
                <Add sx={{ fontSize: "17px" }} /> &nbsp;
                <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
                  Add ShortCode
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {console.log("rowsSelectedDataaa", rowsSelectedData)}
      <Grid container className="Top-spacing div-table">
        <Grid style={{ marginTop: "10px" }} item xs={12}>
          <Paper variant="CardTable" className="page-paper-twoways">
            <Table
              // isLoading={isLoading}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              rowCountState={tableDataSmsOneWay?.totalCount}
              data={tableDataSmsOneWay?.data}
              isLoading={isLoading}
              columns={columnsSmsOneWay}
              pageIndex={
                tableDataSmsOneWay?.pageIndex
                  ? tableDataSmsOneWay?.pageIndex
                  : 0
              }
              setPaginationModel={setPaginationModel}
              paginationModel={paginationModel}
              enableDelete={true}
              enableEdit={true}
              deleteFunction={OpenConfirmModalSmsOneWay}
              editFunction={handleEditMangeSmsOneWayopen}
              enableSearch={true}
              enableExportBtn={true}
              // exportFunction={exportSms}
              exportFunction={() => setOpenExportModal(true)}
              valueFilter={Client}
              setValueFilter={setClient}
              advancedSearchFunction={handleOpenAdvancedSearch}
              advancedSearch={openAdvancedSearch}
              handleSearchBtn={() => {
                setSearchClick(true);
                setSelectedRows([]);
                setRefreshTable(true);
                setPaginationModel({
                  page: 0,
                  pageSize: paginationModel?.pageSize
                    ? paginationModel?.pageSize
                    : 10,
                });
              }}
              search={smsOneWaySearch}
              setSearch={setSmsOneWaySearch}
              setRowsSelectedData={setRowsSelectedData}
              rowsSelectedData={rowsSelectedData}
              enableCheckboxSelection={true}
              handleResetAll={handleResetAll}
              contentAdvancedSearch={
                <Grid spacing={3} container>
                  <Grid lg={3} sm={4} xs={12} item>
                    <Test
                      setRefreshTable={setRefreshTable}
                      placeholder="Client"
                      value={clientCategorySearch}
                      setValue={setClientCategorySearch}
                    />
                  </Grid>
                  <Grid lg={3} sm={4} xs={12} item>
                    <SelectFormNew
                      value={countrySearch}
                      setValue={setCountrySearch}
                      options={countryOptionsSearch}
                      placeholder={"Country"}
                      disabled={countryOptionsSearch?.length === 0}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>

                  <Grid lg={3} sm={4} xs={12} item>
                    <SelectFormNew
                      value={senderIdsSearch}
                      setValue={setSenderIdsSearch}
                      options={senderIdsOptionsSearch}
                      placeholder={"Sender"}
                      disabled={
                        senderIdsOptionsSearch?.length === 0 || isoCode === ""
                      }
                      isLoading={isLoadingSenderIds}
                      wait={"select a sender"}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} sm={4} xs={12} item>
                    <SelectFormNew
                      value={providerSearch}
                      setValue={setProviderSearch}
                      options={providerOptionsSearch}
                      placeholder={"Provider"}
                      disabled={providerOptionsSearch?.length === 0}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  {/* <Grid lg={3} xs={12} item>
                    <TextFieldForm
                      placeholder={"MNC"}
                      value={mncSearch}
                      setValue={setMncSearch}
                      positiveNumberFloat
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <TextFieldForm
                      placeholder={"MCC"}
                      value={mccSearch}
                      setValue={setMccSearch}
                      positiveNumberFloat
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid> */}
                  <Grid lg={3} sm={4} xs={12} item>
                    <SelectFormNew
                      value={block}
                      setValue={setBlock}
                      options={blockOptions}
                      placeholder={"Block"}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} sm={4} xs={12} item>
                    <SelectFormNew
                      value={lock}
                      setValue={setLock}
                      options={lockOptions}
                      placeholder={"Lock"}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Paper>
        </Grid>
      </Grid>
      {handleAddMangeSmsOneWayopen && (
        <ManageShortCode
          open={MangeSmsOneWayAddopen}
          setOpen={setMangeSmsOneWayAddopen}
          title={"Add Route"}
          clientCategoryOptionsSearch={clientCategoryOptionsSearch}
          countryOptionsSearch={countryOptionsSearch}
          operatorOptionsSearch={operatorOptionsSearch}
          providerOptionsSearch={providerOptionsSearch}
          isoCode={isoCode}
          countryOptionsRes={countryOptionsRes}
          setPaginationModel={setPaginationModel}
          setRefreshTable={setRefreshTable}
          setSmsOneWaySearch={setSmsOneWaySearch}
          paginationModel={paginationModel}
        />
      )}
      {handleEditMangeSmsOneWayopen && (
        <ManageShortCode
          open={MangeSmsOneWayEditopen}
          setOpen={setMangeSmsOneWayEditopen}
          title={"Edit Route"}
          isEdit={true}
          clientCategoryOptionsSearch={clientCategoryOptionsSearch}
          countryOptionsSearch={countryOptionsSearch}
          operatorOptionsSearch={operatorOptionsSearch}
          providerOptionsSearch={providerOptionsSearch}
          isoCode={isoCode}
          countryOptionsRes={countryOptionsRes}
          setPaginationModel={setPaginationModel}
          setRefreshTable={setRefreshTable}
          setSmsOneWaySearch={setSmsOneWaySearch}
          selectedSms={selectedSms}
          paginationModel={paginationModel}
        />
      )}

      {/* Delete Resellers Modal */}
      {handleConfirmModalSmsOneWay && (
        <ConfirmationModal
          open={ConfirmOpenSmsOneWay}
          onClose={handleConfirmModalSmsOneWay}
          title="Delete"
          text="Are you sure you want to delete this record?"
          onButtonClick={handleDelete}
        />
      )}
      {/* {handleEditManageSmsOneWayopen && (
        <AddReseller
          open={ManageSmsOneWayEditopen}
          setOpen={setManageSmsOneWayEditopen}
          title={"Edit Resellers"}
        />
      )} */}

      {/* Swtich Resellers Modal */}
      {isModalOpenSwitchSmsOneWay && (
        <ConfirmationModal
          open={isModalOpenSwitchSmsOneWay}
          onClose={handleModalCloseSwitchSmsOneWay}
          title="Change Status"
          text="Are you sure you want to change this Resellers Status?"
          onButtonClick={handleModalConfirmSwitchSmsOneWay}
        />
      )}
      {isModalOpenSwitchLocationZone && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationZone}
          onClose={handleModalCloseSwitchLocationZone}
          title={selectedSms?.isBlocked ? "Unblock " : "Block "}
          disableBtn={
            reasonOptions !== false &&
            reasonOptions?.find((x) => x.value === reason)?.label === "Other"
              ? reason === "" || description === ""
              : reason === ""
          }
          content={
            <>
              <SelectFormNew
                value={reason}
                setValue={setReason}
                options={reasonOptions}
                placementTop={true}
                placeholder={`Please select reason of
              ${selectedSms?.isBlocked ? "Unblock " : "Block "}
              `}
                disabled={reasonOptions !== false && reasonOptions.length === 0}
              />
              {reasonOptions !== false &&
                reasonOptions?.find((x) => x.value === reason)?.label ===
                  "Other" && (
                  <TextFieldForm
                    placeholder={"Description"}
                    value={description}
                    setValue={setDescription}
                  />
                )}
            </>
          }
          onButtonClick={handleModalConfirmSwitchBlock}
        />
      )}
      {isModalOpenSwitchLock && (
        <ConfirmationModal
          open={isModalOpenSwitchLock}
          onClose={handleModalCloseSwitchLock}
          title={selectedSms?.isLocked ? "Unlock " : "Lock "}
          disableBtn={
            reasonOptions !== false &&
            reasonOptions?.find((x) => x.value === reason)?.label === "Other"
              ? reason === "" || description === ""
              : reason === ""
          }
          content={
            <>
              <SelectFormNew
                value={reason}
                setValue={setReason}
                placementTop={true}
                options={reasonOptions}
                placeholder={`Please select reason of
              ${selectedSms?.isLocked ? "unlock " : "lock "}
              `}
                disabled={reasonOptions !== false && reasonOptions.length === 0}
              />
              {reasonOptions !== false &&
                reasonOptions?.find((x) => x.value === reason)?.label ===
                  "Other" && (
                  <TextFieldForm
                    placeholder={"Description"}
                    value={description}
                    setValue={setDescription}
                  />
                )}
            </>
          }
          onButtonClick={handleModalConfirmSwitchLock}
        />
      )}
      {deleteAllModalOpen && (
        <ConfirmationModal
          open={deleteAllModalOpen}
          onClose={handleModalCloseDeleteAll}
          title="Delete All"
          text="Are you sure you want to delete all selected records?"
          onButtonClick={handleMultiDelete}
        />
      )}
      {openExportModal && (
        <CustomizedDialogs
          open={openExportModal}
          setOpen={setOpenExportModal}
          title={"Export Data "}
          firstActionBtnName={"All Data"}
          secondActionBtnName={"Filter Data"}
          firstActionBtnFct={() => {
            setSearchClick(false);
            exportSms(false); // Added parentheses to invoke the function
          }}
          secondActionBtn={() => {
            setSearchClick(true);
            exportSms(true); // Added parentheses to invoke the function
          }}
          content={
            <Typography style={{ textAlign: "center" }}>
              Do you want to export all data or filtered data ?
            </Typography>
          }
        />
      )}
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ShortCode;
