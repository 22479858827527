import BuildIcon from "@mui/icons-material/Build";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TranslateIcon from "@mui/icons-material/Translate";
import { Box, Grid, Stack, Switch, Tooltip, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MobileLogo from "../../Assets/monty-mobile-icon.svg";
import Logo from "../../Assets/monty-mobile-logo.svg";
import LandingPage from "../../Service-Manager/Containers/LandingPage/LandingPage";
import TransparentModal from "../Dialog/TranparentModal";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const TopNav = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate();
  const [showTopNav, setshowTopNav] = useState(
    localStorage.getItem("showAppBar")
  );
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const handleClick = () => {
    // localStorage.setItem("typeOfLogin", "null");
    setButtonClicked(true);

    // window.location.reload();
    // setButtonClicked(false);
  };
  useEffect(() => {
    const handleResize = () => {
      // Set the tablet width threshold (adjust as needed)
      const tabletWidth = 768; // Example threshold for tablets

      // Check if the screen width is less than the tablet threshold
      setIsTablet(window.innerWidth < tabletWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  // useEffect(() => {
  //   console.log("ASdasdasdasd", buttonClicked);
  //   if (buttonClicked) {
  //     navigate("/data-seed");
  //   }
  // }, [buttonClicked]);
  const handleOpenSwitchModal = () => {
    setOpenSwitch(!openSwitch);
  };

  React.useEffect(() => {
    setshowTopNav(localStorage.getItem("showAppBar"));
  }, [localStorage.getItem("showAppBar")]);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <>
      {showTopNav !== "hidden" && (
        <>
          <AppBar
            style={{
              width:
                localStorage.getItem("typeOfLogin") !== "null" &&
                localStorage.getItem("typeOfLogin") !== null &&
                "100%",
            }}
            variant="AppBar"
            position="fixed"
            open={open}
          >
            <Toolbar>
              {(localStorage.getItem("typeOfLogin") === "Channels" ||
                localStorage.getItem("typeOfLogin") === "hidden" ||
                localStorage.getItem("typeOfLogin") === "Providers" ||
                localStorage.getItem("typeOfLogin") === "Clients") && (
                <img src={Logo} className="Logo-sidebar" />
              )}

              {!open && <img src={MobileLogo} className="MobileLogo-sidebar" />}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon sx={{ position: "absolute", left: "50px" }} />
              </IconButton>
              {/* {localStorage.getItem("typeOfLogin") === "null" ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                width: "-webkit-fill-available",
              }}
            >
              <ChevronLeftIcon
                style={{ color: "#4BAFFE", fontSize: "20px", margin: "2px" }}
              />
              <Typography
                onClick={() => {
                  handleClick();
                }}
                style={{ color: "#4BAFFE" }}
              >
                Switch to regular View
              </Typography>
            </div>
          )} */}

              <Grid container>
                <Grid
                  item
                  xs={12}
                  className="end"
                  style={{ alignItems: "center" }}
                >
                  {localStorage.getItem("roleName") !== "support" &&
                    localStorage.getItem("roleName") !== "userQA" &&
                    localStorage.getItem("projectName") !== "KeyStore" && (
                      <Tooltip title={"Switch View"} placement="bottom">
                        <IconButton
                          style={{ marginRight: "10px", color: "#c41035" }}
                          // color="inherit"
                          onClick={() => handleOpenSwitchModal()}
                        >
                          <CameraswitchIcon style={{ color: "#c41035" }} />
                        </IconButton>
                      </Tooltip>
                    )}

                  {/* <FormControl>
                    <LanguageIcon className="language-icon" />
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Language"
                      className="custom-select"
                      onChange={(event) => {
                        window.location.reload();
                        const selectedLanguage = event.target.value;
                        let toLang = "en";
                        if (i18n.language !== selectedLanguage) {
                          toLang = selectedLanguage;
                        }
                        localStorage.setItem("app_lang", toLang);
                      }}
                    >
                      <MenuItem value={"en"}>EN</MenuItem>
                      <MenuItem value={"ar"}>AR</MenuItem>
                    </Select>
                  </FormControl> */}
                  <Typography
                    style={{
                      fontSize: "14px",
                      borderRight: "1px solid black",
                      paddingRight: "15px",
                      marginRight: "15px",
                      color: "black",
                      marginTop: "2px",
                    }}
                  >
                    Currency : USD
                  </Typography>

                  <PersonOutlineIcon style={{ color: "black" }} />
                  <FormControl className="form-control-nav">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={isTablet ? "a" : ""}
                      variant="outlined"
                      className="custom-select"
                      displayEmpty
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&.MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value="" disabled>
                        {localStorage.getItem("roleName") !== "support" &&
                        localStorage.getItem("roleName") !== "userQA"
                          ? "admin"
                          : localStorage.getItem("roleName") === "support"
                          ? "userSupport"
                          : localStorage.getItem("roleName") === "userQA"
                          ? "userQA"
                          : ""}
                      </MenuItem>
                      {localStorage.getItem("roleName") !== "support" &&
                      localStorage.getItem("roleName") !== "userQA" ? (
                        <>
                          {localStorage.getItem("projectName") !==
                            "KeyStore" && (         
                            <MenuItem
                              onClick={() => navigate("/manage-tenant")}
                              value="ManageTenant"
                              className="DropdownNav-text"
                            >
                              <BuildIcon style={{ fontSize: "1.2em" }} />
                              &nbsp; Manage Tenant
                            </MenuItem>
                          )}

                          <hr />
                          <MenuItem value="Logout" className="DropdownNav-text">
                            <KeyIcon style={{ fontSize: "1.2em" }} />
                            &nbsp; Change Password
                          </MenuItem>
                          <MenuItem value="Logout" className="DropdownNav-text">
                            <KeyIcon style={{ fontSize: "1.2em" }} />
                            &nbsp; Two Factor Authentication
                          </MenuItem>
                          <MenuItem
                            value="Languages"
                            className="DropdownNav-text"
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            // }}
                          >
                            <TranslateIcon style={{ fontSize: "1.2em" }} />
                            &nbsp; Languages
                            {/* <Switch
                    onClick={(e) => {
                      e.stopPropagation(); // This line prevents event propagation
                    }}
                  ></Switch> */}
                            &nbsp; &nbsp;
                            <Stack
                              onClick={(e) => {
                                e.stopPropagation(); // This line prevents event propagation
                              }}
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography style={{ fontSize: "12px" }}>
                                EN
                              </Typography>
                              <AntSwitch
                                defaultChecked
                                inputProps={{ "aria-label": "ant design" }}
                              />
                              <Typography style={{ fontSize: "12px" }}>
                                AR
                              </Typography>
                            </Stack>
                          </MenuItem>
                          <MenuItem
                            value="Logout"
                            className="DropdownNav-text"
                            onClick={() => {
                              navigate("/", { replace: true });
                              localStorage.clear();
                            }}
                          >
                            {" "}
                            <LogoutIcon style={{ fontSize: "1.2em" }} />
                            &nbsp; Log out
                          </MenuItem>
                          <hr />
                          <MenuItem
                            value="Logout"
                            className="DropdownNav-text-gray"
                          >
                            Role:System Admin
                          </MenuItem>
                          <MenuItem
                            value="Logout"
                            className="DropdownNav-text-gray"
                          >
                            Last Login : 23/11/2023 08:49:20
                          </MenuItem>
                        </>
                      ) : (
                        <>
                          <MenuItem
                            value="Logout"
                            className="DropdownNav-text"
                            onClick={() => {
                              navigate("/", { replace: true });
                              localStorage.clear();
                            }}
                          >
                            {" "}
                            <LogoutIcon style={{ fontSize: "1.2em" }} />
                            &nbsp; Log out
                          </MenuItem>
                        </>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Box sx={{ position: "absolute", top: "50px", width: "100%" }}>
            <Typography
              style={{
                // paddingLeft: open ? "260px" : "",
                // paddingLeft:
                //   localStorage.getItem("typeOfLogin") === "null" && open
                //     ? "260px"
                //     : "",
                paddingLeft: open ? "260px" : "80px",
                position: "fixed",
                width: "100%",
                zIndex: "55",
                background: "#545454",
              }}
              className="user-login"
              variant={
                localStorage.getItem("typeOfLogin") === "null"
                  ? "second-nav-text"
                  : "second-nav-text-2"
              }
            >
              {localStorage.getItem("projectName")} -
              {localStorage.getItem("envName")}
            </Typography>
          </Box>
        </>
      )}
      {openSwitch && (
        <TransparentModal
          setOpen={setOpenSwitch}
          open={openSwitch}
          size="xl"
          content={<LandingPage hideBack={true} />}
        />
      )}
    </>
  );
};

export default withTranslation("translations")(TopNav);
