import { Delete } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Grid,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { iconArray } from "../../../Utils/Constants";
// import { HandleApiError } from "../../../Utils/Functions";
import { DELETE_MENU_ACTION, UPDATE_MENU } from "../../../Authentication/Roles";
// import Notification from "../../../Components/Notification/Notification";
// import { useSelector } from "react-redux";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import CloseIcon from "@mui/icons-material/Close";

const EditMenu = ({ Menu, ToggleEdit, SetToggleEdit }) => {
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  // const { token } = useSelector((state) => state?.authentication);
  let role = localStorage.getItem("roleName");
  let token = localStorage.getItem("token");
  const [Actions, SetActions] = useState(
    Menu?.menuAction.map((item, index) => ({
      id: index,
      name: item.menuActionDetail[0].name,
      description: item.menuActionDetail[0].description,
      hasAccess: item.hasAccess ? "Yes" : "No",
    }))
  );
  let formik = useFormik({
    initialValues: {
      Name: Menu?.menuDetail[0]?.name,
      Path: Menu?.uri,
      Icon: Menu?.iconUri,
    },
    validationSchema: Yup.object().shape({
      Name: Yup.string().required("Name is required"),
      Path: Yup.string().required("Path is required"),
      Icon: Yup.string().required("Icon is required"),
    }),
    onSubmit: async (values) => {
      try {
        let editResponse = await UPDATE_MENU({
          token,
          formData: {
            RecordGuid: Menu?.recordGuid,
            Uri: values["Path"],
            IconUri: values["Icon"],
            Position: 100,
            Group: 2,
            MenuDetail: [
              {
                Name: values["Name"],
                Description: values["Name"],
                LanguageCode: "en",
              },
            ],
          },
        });
        if (editResponse?.data?.success) {
          // Notification?.success("Menu Updated");
          setOpenMessageAlert(true);
          setMessageData({ message: "Updated Successfully ", type: "success" });

          SetToggleEdit(false);
        }
      } catch (e) {
        // HandleApiError(e);
      }
    },
  });

  // const handleDeleteMenuAction = async ({ id }) => {
  //   SetToggleEdit(false);

  //   const result = await Swal.fire({
  //     title: "Confirm Deletion",
  //     text: "Are you sure you want to delete this menu?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#dd3333",
  //     cancelButtonColor: "#3085d6",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel",
  //   });

  //   if (result.isConfirmed) {
  //     try {
  //       // Perform the deletion operation
  //       let deleteResponse = await DELETE_MENU_ACTION({
  //         token,
  //         menu: id,
  //       });

  //       if (deleteResponse?.data?.success) {
  //         // Notification?.success("Menu deleted successfully");
  //         // Here you might not reopen the Edit modal, as the item is deleted
  //       }
  //     } catch (e) {
  //       // HandleApiError(e);
  //       // Optionally, reopen the Edit modal here if there was an error
  //       // SetToggleEdit(true);
  //     }
  //   } else {
  //     SetToggleEdit(true);
  //   }
  // }

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [confirmOpenDeleteMenu, setConfirmOpenDeleteMenu] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState(null);

  const handleDeleteMenuAction = ({ id }) => {
    setIsEditModalOpen(false); // Close the edit modal
    setSelectedMenuId(id);
    setConfirmOpenDeleteMenu(true); // Open the confirmation modal
  };

  const handleConfirmDeletion = async () => {
    try {
      // Perform the deletion operation
      let deleteResponse = await DELETE_MENU_ACTION({
        token,
        menu: selectedMenuId,
      });

      if (deleteResponse?.data?.success) {
        // Notification?.success("Menu deleted successfully");
        setConfirmOpenDeleteMenu(false); // Close the confirmation modal
        SetActions((prevActions) =>
          prevActions.filter((action) => action.id !== selectedMenuId)
        );
      }
    } catch (e) {
      // HandleApiError(e);
    }
  };

  const handleCloseConfirmationModal = () => {
    setConfirmOpenDeleteMenu(false); // Close the confirmation modal
    setIsEditModalOpen(true); // Reopen the edit modal
  };

  const handleCloseModalDeleteMenu = () => {
    setConfirmOpenDeleteMenu(false);
    SetToggleEdit(true);
  };
  const [paginationModel, SetPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  useEffect(() => {
    formik?.setValues({
      Name: Menu?.menuDetail[0]?.name,
      Path: Menu?.uri,
      Icon: Menu?.iconUri,
    });
    SetActions(
      Menu?.menuAction.map((item, index) => ({
        id: item?.recordGuid,
        name: item.menuActionDetail[0].name,
        description: item.menuActionDetail[0].description,
        hasAccess: item.hasAccess ? "Yes" : "No",
      }))
    );
  }, [Menu]);
  return (
    <>
      <CustomizedDialogs
        // open={open}
        setOpen={SetToggleEdit}
        open={ToggleEdit}
        title={"Edit Menu"}
        firstActionBtnName={"Save"}
        firstActionBtnFct={formik?.handleSubmit}
        // secondActionBtnName={"Cancel"}
        content={
          <form onSubmit={formik?.handleSubmit}>
            {confirmOpenDeleteMenu && (
              <ConfirmationModal
                open={confirmOpenDeleteMenu}
                onClose={handleCloseConfirmationModal}
                title="Delete Menu"
                text="Are you sure you want to delete this menu?"
                onButtonClick={handleConfirmDeletion}
              />
            )}
            <Grid container>
              <Grid item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  formikValue={formik?.values?.Name}
                  formik={true}
                  id={"Name"}
                  key={"Name"}
                  name={"Name"}
                  onBlur={formik?.handleBlur}
                  type={"text"}
                  error={
                    formik.touched["Name"] && Boolean(formik.errors["Name"])
                  }
                  helperText={formik.touched["Name"] && formik.errors["Name"]}
                  onChange={formik?.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {/* <TextFieldForm
                formik
                fullWidth
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                // label="Path"
                // name="Path"
                // variant="standard"
                error={formik.touched["Path"] && Boolean(formik.errors["Path"])}
                helperText={formik.touched["Path"] && formik.errors["Path"]}
                value={formik?.values?.Path}
              /> */}

                <TextFieldForm
                  placeholder={"Path"}
                  formikValue={formik?.values?.Path}
                  formik={true}
                  id={"Path"}
                  key={"Path"}
                  name={"Path"}
                  onBlur={formik?.handleBlur}
                  type={"text"}
                  error={
                    formik.touched["Path"] && Boolean(formik.errors["Path"])
                  }
                  helperText={formik.touched["Path"] && formik.errors["Path"]}
                  onChange={formik?.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container>
              <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Icons</span>
              <Grid style={{ marginBottom: "10px" }} container spacing={2}>
                <Grid item xs={11}>
                <Autocomplete
                  fullWidth
                  value={formik?.values?.Icon}

                  // id="combo-box-demo"
                  options={iconArray?.map((item, index) => {
                    return { id: index, label: item };
                  })}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <i className={`fa ${option.label} mr-1`} /> {option.label}
                    </li>
                  )}
                  disableClearable
                  onChange={(e, n) => formik?.setFieldValue("Icon", n?.label)}
                  renderInput={(params) => (
                    <TextField
                    sx={{
                      padding: "3.5px 4px 7.5px 5px",
                      "& .MuiInputBase-input": {
                        padding: "0.5px 4px 7.5px 5px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "0.5px 4px 7.5px 5px",
                      },
                      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                        padding: "0.5px 4px 7.5px 5px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                      maxWidth: "96%",
                    }}
                    {...params}
                  />
                  )}
                />
                </Grid>
                <Grid
                  item
                  xs={1}
                  // marginRight={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {formik?.values?.Icon && (
                    <i className={`fa ${formik?.values?.Icon} icon-member`}></i>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {Actions?.length > 0 && (
                  <DataGrid
                    sx={{
                      minHeight: "200px",
                      // "&.MuiDataGrid-root": {
                      //   height: "auto!important",
                      // },
                      "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
                        background: "#e3e3e3a6",
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                        {
                          outline: "none",
                        },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        color: "white",
                      },
                      "& .MuiDataGrid-row:hover": {
                        // backgroundColor: "transparent"
                      },

                      "& .MuiDataGrid-columnHeadersInner": {
                        backgroundColor: "#c41035",
                        borderRadius: "20px 20px 0px 0px",
                        // Add any other styling properties as needed
                      },
                      "& .MuiCheckbox-root.Mui-checked": {
                        color: "#ff91a8d4",
                      },

                      "DataGrid-overlayHeight": "600px",
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
                        borderColor: "rgb(255 243 243)",
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-withBorderColor": {
                        borderColor: "rgb(255 243 243)",
                      },
                      "&.MuiDataGrid-root": {
                        borderColor: "rgb(255 243 243)",
                      },
                      // minHeight: !stopMinHeight && "390px",
                      "& .MuiDataGrid-columnSeparator--sideRight": {
                        display: "none",
                      },
                      "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                        {
                          outline: "none !important",
                        },
                      "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                        {
                          outline: "none !important",
                        },
                      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                        width: "0.4em",
                      },
                      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                        {
                          background: "#f1f1f1",
                        },
                      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                        {
                          backgroundColor: "#888",
                        },
                      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
                        {
                          background: "#555",
                        },
                      "& .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate ":
                        {
                          color: "red",
                        },
                    }}
                    paginationModel={paginationModel}
                    onPaginationModelChange={SetPaginationModel}
                    rows={Actions}
                    columns={[
                      {
                        field: "name",
                        headerName: "Name",
                        flex: 1,
                        editable: true,
                      },
                      {
                        headerName: "Actions",
                        renderCell: (params) => (
                          <IconButton
                            onClick={() =>
                              handleDeleteMenuAction({ id: params?.row?.id })
                            }
                            size="small"
                          >
                            <Delete />
                          </IconButton>
                        ),
                      },
                    ]}
                    density="compact"
                    disableDensitySelector={false}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditMenu;
