import { Navigate, Outlet } from "react-router-dom";

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import DrawerHeader from "../../Components/Navigation/DrawerHeader";
import Footer from "../../Components/Navigation/Footer";
import SideNav from "../../Components/Navigation/SideNav";
import TopNav from "../../Components/Navigation/TopNav";

export default function NavigationRouter() {
  const [openSideBar, setOpenSideBar] = useState(true);
  const [isTablet, setIsTablet] = useState(false);

  const handleOpen = () => {
    setOpenSideBar(!openSideBar);
  };

  useEffect(() => {
    const handleResize = () => {
      const tabletWidth = 768; // Example threshold for tablets
      setIsTablet(window.innerWidth < tabletWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (isTablet) {
      setOpenSideBar(false);
    } else {
      setOpenSideBar(true);

    }
  }, [isTablet]);

  return (
    <>
      {localStorage.getItem("token") ? (
        <>
          <TopNav open={openSideBar} handleDrawerOpen={handleOpen} />
          {localStorage.getItem("typeOfLogin") !== "Channels" &&
            localStorage.getItem("typeOfLogin") !== "hidden" &&
            localStorage.getItem("typeOfLogin") !== "Providers" &&
            localStorage.getItem("typeOfLogin") !== "Clients" && (
              <SideNav handleDrawerClose={handleOpen} open={openSideBar} />
            )}

          <Box
            component="main"
            className="main-box"
            sx={{ width: "100%", margin: "3%", marginTop: "60px" }}
          >
            <DrawerHeader />
            <Outlet />
          </Box>
          {/* <Filter/> */}
          <Footer />
        </>
      ) : (
        <Navigate to={"/"} replace />
      )}
    </>
  );
}
