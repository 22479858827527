import Add from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { MyDropzone } from "../../../Components/Buttons/ImportButton";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import Table from "../../../Components/DynamicTable/Table";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import HorizantalTabs from "../../../Components/Tabs/ChannelTabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import { downloadCSV } from "../../../Utils/functions";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

import {
  ADD_COST_PLAN,
  CHANGE_STATUS_LOCK_RATES,
  DELETE_RATE,
  EXPORT_ACCOUNTS_TYPE,
  EXPORT_ALL_COSTS,
  EXPORT_ALL_RATES,
  GET_ALL_ACCOUNTS_STATUS,
  GET_ALL_ACCOUNTS_TYPE,
  GET_ALL_CLIENTS_CATEGORY,
  GET_ALL_COSTS_PLANS,
  GET_ALL_COSTS_PLANS_PROVIDER,
  GET_ALL_COSTS_RATES,
  GET_ALL_LANGUAGES,
  GET_ALL_PROFIT_MARGIN,
  GET_ALL_PROVIDERS_CATEGORY,
  GET_ALL_RATES,
  GET_ALL_REASONS,
  GET_ALL_MISSING_RATES,
  IMPORT_COSTS,
  IMPORT_RATE,
  MULTI_TENANCY_CONFIG,
  MULTI_TENANCY_CONFIG_SUBMIT_FILE,
  POPULATE_RATE_PLAN_PROFIT_MARGIN,
  EXPORT_MISSING_RATES,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_OPERATORS_OPTIONS,
  POPULATE_WHATSAPP,
  IMPORT_COSTS_WHATSAPP,
  DOWNLOAD_COST_WHATSAPP_SAMPLE,
  DOWNLOAD_COST_SAMPLE,
  DOWNLOAD_RATE_SAMPLE,
} from "../../APIs";
import ManageAccounts from "./ManageAccounts";
import ManageCosts from "./ManageCosts";
import ManageProfitMargin from "./ManageProfitMargin";
import ManageRates from "./ManageRates";
import ManageTaxes from "./ManageTaxes";
import ManageWallet from "./ManageWallet";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import ManageMissingRates from "./ManageMissingRates";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";
const BillingPricing = ({
  serviceGuid,
  channelGuid,
  selectedBtn,
  isProfit,
  isWhatsapp,
  downloadSampleTag,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCost, setIsLoadingCost] = useState(true);

  const [recordGuidRates, setRecordGuidRates] = useState("");
  // const [serviceGuid, setServiceGuid] = useState("");
  const [recordGuidCosts, setRecordGuidCosts] = useState("");
  const [countryOptionsRes, setCountryOptionsRes] = useState([]);
  const [operatorOptionsSearch, setOperatorOptionsSearch] = useState([]);

  const [ClientCategoryOptions, setClientCategoryOptions] = useState([]);
  const [openImportFile, setopenImportFile] = useState(false);
  const [openImportFileMissingRates, setopenImportFileMissingRates] =
    useState(false);
  const [isModalOpenSwitchLock, setModalOpenSwitchLock] = useState(false);

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  // const [activeBtn, setActiveBtn] = useState(
  //   localStorage.getItem("roleName") !== "support" &&
  //     localStorage.getItem("roleName") !== "userQA"
  //     ? "Type"
  //     : "Type"
  // );
  const [activeBtn, setActiveBtn] = useState(
    selectedBtn && !isProfit ? selectedBtn : "Cost Vs. Rate"
  );
  const [activeBtnTaxesTab, setActiveBtnTaxesTab] = useState("ar");

  const [services, setServices] = useState(localStorage.getItem("services"));
  const [config, setConfig] = useState(localStorage.getItem("config"));
  const [file, setFile] = useState("");
  const [clientCategoryImport, setClientCategoryImport] = useState("");
  const [selectedRates, setSectedRates] = useState("");
  const [selectedCost, setSectedCost] = useState("");
  const [alertMesssage, setAlertMessage] = useState("");
  const [openAlertExportRates, setOpenAlertExportRates] = useState(false);
  const [openAlertExportCost, setOpenAlertExportCost] = useState(false);
  const [openImportAlert, setOpenImportAlert] = useState(false);
  const [openImportSuccessAlert, setOpenImportSuccessAlert] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");

  const [importMessage, setImportMessage] = useState("");
  const [searchCountryIso, setSearchCountryIso] = useState("");
  const [searchClientCategory, setSearchClientCategory] = useState("");

  const [searchCountry, setSearchCountry] = useState("");
  const [searchOperator, setSearchOperator] = useState("");
  const [searchCosts2, setSearchCosts2] = useState("");

  const [searchCosts, setSearchCosts] = useState("");

  const [generateRport, setGenerateReport] = useState(false);
  const [isoCode, setIsoCode] = useState("");

  const [openAlertExportRatesError, setOpenAlertExportRatesError] =
    useState(false);
  const [openAlertExportCostError, setOpenAlertExportCostError] =
    useState(false);
  const [providerCategoryImport, setProviderCategoryImport] = useState("");
  const [clientCategoryImportOptions, setClientCategoryImportOptions] =
    useState(false);
  const [providerCategoryImportOptions, setProviderCategoryImportOptions] =
    useState(false);
  const [searchRates, setSearchRates] = useState("");
  const [searchMissingRates, setSearchMissingRates] = useState("");

  const [clientCategoryRatesOptions, setClientCategoryRatesOptions] =
    useState(false);
  const [providerCategoryCostsOptions, setProviderCategoryCostsOptions] =
    useState(false);
  const [reason, setReason] = useState("");
  const [reasonOptions, setReasonOptions] = useState(false);
  const [description, setDescription] = useState("");

  const [
    providerCategoryCostsOptionsCosts,
    setProviderCategoryCostsOptionsCosts,
  ] = useState(false);
  const [providerCategoryCosts, setProviderCategoryCosts] = useState("");
  const [
    providerCategoryCostsGenerateReport,
    setProviderCategoryCostsGenerateReport,
  ] = useState("");
  const [
    providerCategoryCostsGenerateReportProfitMargin,
    setProviderCategoryCostsGenerateReportProfitMargin,
  ] = useState("");

  const [providerCategoryCostsVsRates, setProviderCategoryCostsVsRates] =
    useState("");
  const [clientCategoryOptionsSearch, setClientCategoryOptionsSearch] =
    useState(false);

  const [clientCategoryRates, setClientCategoryRates] = useState("");
  const [ActiveBtnHorizantal, setActiveBtnHorizantal] = useState("Rates");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setPaginationModelRates({
      page: 0,
      pageSize: paginationModelRates?.pageSize
        ? paginationModelRates?.pageSize
        : 10,
    });
    setPaginationModelCosts({
      page: 0,
      pageSize: paginationModelCosts?.pageSize
        ? paginationModelCosts?.pageSize
        : 10,
    });
    setPaginationModelCostsVsRates({
      page: 0,
      pageSize: paginationModelCostVsRate?.pageSize
        ? paginationModelCostVsRate?.pageSize
        : 10,
    });
    setPaginationModelProfitMargin({
      pageSize: paginationModelProfitMargin?.pageSize
        ? paginationModelProfitMargin?.pageSize
        : 10,
    });
    // setSearchCosts("");
    // setSearchRates("");
  }, [clientCategoryRates]);

  useEffect(() => {
    setPaginationModelRates({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelCosts({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelCostsVsRates({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelProfitMargin({
      page: 0,
      pageSize: 10,
    });
    // setSearchCosts("");
    // setSearchRates("");
    setSearchRates("");
    setSearchCountryIso("");
    setSearchCountry("");
    setSearchOperator("");
    setSearchCosts2("");
    setSearchClientCategory("");
  }, [activeBtn]);

  const [paginationModelCostVsRate, setPaginationModelCostVsRate] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [paginationModelProfitMargin, setPaginationModelProfitMargin] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [paginationModelRates, setPaginationModelRates] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [paginationModelMissingRates, setPaginationModelMissingRates] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [paginationModelCosts, setPaginationModelCosts] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModelCostsVsRates, setPaginationModelCostsVsRates] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [refreshCosts, setRefreshCosts] = React.useState(false);
  // Accounts
  const [isModalOpenSwitchAccounts, setModalOpenSwitchAccounts] =
    useState(false);
  const [isCheckedSwitchAccounts, setCheckedSwitchAccounts] = useState(false);
  const [openEditProfitMargin, setOpenEditProfitMargin] = useState(false);

  const [switchStatesAccounts, setswitchStatesAccounts] = useState({});
  const handleSwitchChangeSwitchAccounts = (rowId) => {
    setModalOpenSwitchAccounts(true);
    setCheckedSwitchAccounts(rowId);
  };
  const handleModalCloseSwitchLock = () => {
    setModalOpenSwitchLock(false);
  };
  const handleModalCloseSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
  };
  const handleModalConfirmSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
    // setCheckedSwitchAccounts(!isCheckedSwitchAccounts);
    setswitchStatesAccounts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchAccounts]: !prevStates[isCheckedSwitchAccounts],
    }));
  };
  const handleModalConfirmSwitchLock = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let body = {
      RatePlantGuid: selectedProvider?.recordGuid,
      LockRatePlan: !selectedProvider?.isLocked,
      LockReasonGuid: reason ? reason : description,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${CHANGE_STATUS_LOCK_RATES}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Updated",
          type: "success",
        });

        setRefreshTableRates(true);
        setModalOpenSwitchLock(false);
        // setRefreshTable(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleModalConfirmSwitchLock(isModalOpenSwitchLock);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllReasons = (type) => {
    setReason("");
    setDescription("");
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let body = {
      categoryTags: [type],
    };
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_REASONS}?categoryTags=${[type]}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setReasonOptions([
          ...res?.data?.data?.criteria?.map((x, i) => ({
            label: x.name,
            value: x.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllReasons(type);
        }
      })
      .finally(() => {});
  };
  //

  // Rates
  const [isModalOpenSwitchRates, setModalOpenSwitchRates] = useState(false);
  const [isCheckedSwitchRates, setCheckedSwitchRates] = useState(false);
  const [switchStatesRates, setSwitchStatesRates] = useState({});
  const handleSwitchChangeSwitchRates = (rowId) => {
    setModalOpenSwitchRates(true);
    setCheckedSwitchRates(rowId);
  };
  const exportAccountsType = () => {
    setIsLoading(true);
    setRefreshTableAccountsType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_ACCOUNTS_TYPE}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `AccountsType.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleModalCloseSwitchRates = () => {
    setConfirmOpenRates(false);
  };
  const handleOpenEditProfitMarginModal = () => {
    setOpenEditProfitMargin(!openEditProfitMargin);
  };
  const handleModalConfirmSwitchRates = () => {
    setModalOpenSwitchRates(false);
    // setCheckedSwitchRates(!isCheckedSwitchRates);
    setSwitchStatesRates((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchRates]: !prevStates[isCheckedSwitchRates],
    }));
  };
  useEffect(() => {
    setFile("");
    setClientCategoryImport("");
    setProviderCategoryImport("");
  }, [openImportFile]);

  //
  // Costs
  const [isModalOpenSwitchCosts, setModalOpenSwitchCosts] = useState(false);
  const [isCheckedSwitchCosts, setCheckedSwitchCosts] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [switchStatesCosts, setSwitchStatesCosts] = useState({});
  const handleSwitchChangeSwitchCosts = (rowId) => {
    setModalOpenSwitchCosts(true);
    setCheckedSwitchCosts(rowId);
  };

  const handleModalCloseSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
  };
  const handleModalConfirmSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
    // setCheckedSwitchCosts(!isCheckedSwitchCosts);
    setSwitchStatesCosts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCosts]: !prevStates[isCheckedSwitchCosts],
    }));
  };
  //
  // Taxes
  const [isModalOpenSwitchTaxes, setModalOpenSwitchTaxes] = useState(false);
  const [isCheckedSwitchTaxes, setCheckedSwitchTaxes] = useState(false);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  const [switchStatesTaxes, setSwitchStatesTaxes] = useState({});
  const handleSwitchChangeSwitchTaxes = (rowId) => {
    setModalOpenSwitchTaxes(true);
    setCheckedSwitchTaxes(rowId);
  };

  const handleModalCloseSwitchTaxes = () => {
    setModalOpenSwitchTaxes(false);
  };
  const handleModalConfirmSwitchTaxes = () => {
    setModalOpenSwitchTaxes(false);
    // setCheckedSwitchTaxes(!isCheckedSwitchTaxes);
    setSwitchStatesTaxes((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchTaxes]: !prevStates[isCheckedSwitchTaxes],
    }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
    setOpenAlert(false);
    setOpenImportAlert(false);
    setOpenImportSuccessAlert(false);
    setOpenAlertExportCost(false);
    setOpenAlertExportRates(false);
    setOpenAlertExportRatesError(false);
    setOpenAlertExportCostError(false);
  };
  //
  const [selectedProvider, setSelectedProvider] = useState();

  // Wallet
  const [isModalOpenSwitchWallet, setModalOpenSwitchWallet] = useState(false);
  const [isOpenPopulateModal, setIsOpenPopulateModal] = useState(false);
  const [selectedProfitMarginData, setSelectedProfitMarginData] = useState({});

  const [isCheckedSwitchWallet, setCheckedSwitchWallet] = useState(false);
  const [switchStatesWallet, setSwitchStatesWallet] = useState({});
  const [resetPagination, setResetPagination] = useState(false);
  const [tableRates, setTableRates] = useState({ pageIndex: 1, data: [] });
  const [tableMissingRates, setTableMissingRates] = useState({
    pageIndex: 1,
    data: [],
  });

  const [tableCosts, setTableCosts] = useState({ pageIndex: 1, data: [] });
  const [ConfirmOpenRates, setConfirmOpenRates] = useState(false);
  const [ConfirmOpenMissingRates, setConfirmOpenMissingRates] = useState(false);

  const [refreshTableRates, setRefreshTableRates] = useState(false);
  const [refreshTableMissingRates, setRefreshTableMissingRates] =
    useState(false);

  const [tableCostsVsRates, setTableCostsVsRates] = useState({
    pageIndex: 1,
    data: [],
  });
  const [tableDataProfitMargin, setTableDataProfitMargin] = useState({
    pageIndex: 1,
    data: [],
  });
  const handleSwitchChangeSwitchWallet = (rowId) => {
    setModalOpenSwitchWallet(true);
    setCheckedSwitchWallet(rowId);
  };

  const submitFile = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers.Tenant =
      localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers = {
      ...MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers,
      "Content-Type": "multipart/form-data",
    };
    if (activeBtn == "Configured rates") {
      const formData = new FormData();
      formData.append("ratesFile", file);
      formData.append("channelGuid", channelGuid);
      formData.append("serviceGuid", serviceGuid);
      formData.append("ClientCategoryGuid", clientCategoryImport);
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${IMPORT_RATE}`,
          formData,
          MULTI_TENANCY_CONFIG_SUBMIT_FILE
        )
        .then(({ data }) => {
          if (data?.status != "failed" && data?.status != "error") {
            setOpenImportSuccessAlert(true);
            setImportSuccessMessage(data?.message);
            setopenImportFile(false);
            setClientCategoryImport("");
            setFile("");
            if (activeBtn === "Configured rates") {
              setClientCategoryRates("");
              tableQueryExecuterRates();
            }
            if (activeBtn === "Costs") {
              setRefreshCosts(true);
              setRefreshCosts(false);

              setProviderCategoryCosts("");
              tableQueryExecuterCosts();
            }
          } else {
          }
        })
        .catch((err) => {
          setImportMessage(err?.response?.data?.message);
          setOpenImportAlert(true);
        });
    } else if (activeBtn == "Costs") {
      const formData = new FormData();
      formData.append("costsFile", file);
      formData.append("channelGuid", channelGuid);
      formData.append("serviceGuid", serviceGuid);
      formData.append("providerCategoryGuid", providerCategoryImport);
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${newServices["billing"]}${
           isWhatsapp? IMPORT_COSTS_WHATSAPP : IMPORT_COSTS
          }`,
          formData,
          MULTI_TENANCY_CONFIG_SUBMIT_FILE
        )
        .then(({ data }) => {
          if (data?.status != "failed" && data?.status != "error") {
            setOpenImportSuccessAlert(true);
            setImportSuccessMessage(data?.message);
            setopenImportFile(false);
            setProviderCategoryImport("");
            setFile("");
          } else {
          }
        })
        .catch((err) => {
          setImportMessage(err?.response?.data?.message);
          setOpenImportAlert(true);
        });
    }
  };

  const handleModalCloseSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
  };
  const handleModalConfirmSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
    // setCheckedSwitchWallet(!isCheckedSwitchTaxes);
    setSwitchStatesWallet((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchWallet]: !prevStates[isCheckedSwitchWallet],
    }));
  };

  useEffect(() => {
    setOperatorOptionsSearch([]);
    setSearchOperator("");
    if (searchCountry !== "") {
      let isoCodefinded = countryOptionsRes?.find(
        (x) => x.label === searchCountry
      )?.value;
      setIsoCode(isoCodefinded);
    } else {
      setIsoCode("");
    }
  }, [searchCountry]);

  const [Zone, setZone] = React.useState("");

  const handleChangeZone = (event) => {
    setZone(event.target.value);
  };
  const [Rates, setRates] = React.useState("");

  const handleChangeRates = (event) => {
    setRates(event.target.value);
  };
  const tableDataAccounts = [
    {
      id: 1,
      name: "Test Account Type 1",
      description: "Test Account Type Description 1",
    },
    {
      id: 2,
      name: "Test Account Type 2",
      description: "Test Account Type Description 2",
    },
  ];
  let columnsAccountsType = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 400,
    },
  ];
  const tableDataAccountsCategory = [
    {
      id: 1,
      name: "Test category Type 1",
      description: "Test category Type Description 1",
    },
    {
      id: 2,
      name: "Test category Type 2",
      description: "Test category Type Description 2",
    },
  ];
  let columnsAccountsCategory = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const handleSwitchChangeSwitchLock = (data) => {
    setDescription("");
    setReason("");
    setReasonOptions(false);
    getAllReasons(
      data?.isLocked
        ? "BILLING_RATE_PLAN_UNLOCK_REASONS"
        : "BILLING_RATE_PLAN_LOCK_REASONS"
    );
    setModalOpenSwitchLock(!isModalOpenSwitchLock);
    setSelectedProvider(data);
  };
  let columnsAccountsStatus = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 400,
    },
  ];
  // const tableDataRates = [
  //   {
  //     id: 1,
  //     clientCategory: "Default",
  //     countryCode: "961",
  //     country: "Lebanon",
  //     operator: "Alfa",
  //     rate: "0.2 USD",
  //   },
  // ];
  let columnsRates = [
    {
      field: "clientCategoryName",
      headerName: "Client Category",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country ISO",
      field: "countryCode",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;

        return `${row?.rate} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      headerName: "Lock",
      field: "isLocked",
      minWidth: 100,

      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.row?.isLocked}
                  onChange={() => handleSwitchChangeSwitchLock(params.row)}
                />
              }
            />
          </Box>
        );
      },
    },
  ];
  let columnsMissingRates = [
    {
      field: "clientCategoryName",
      headerName: "Client Category",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country ISO",
      field: "countryCode",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Cost",
      field: "cost",
      minWidth: 80,

      flex: 1,
      valueGetter: (params) => {
        const row = params.row;

        return `${row?.cost} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    // addFunction={handleAddMissingRateopen}
    {
      headerName: "",
      field: "addRate",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => handleAddMissingRateopen(params?.row)}
            className="generate-key"
          >
            Add missing rate
          </Button>
        );
      },
    },
  ];
  const tableDataCosts = [
    {
      id: 1,
      providerCategory: "providerCategory",
      countryCode: "961",
      country: "Lebanon",
      operator: "operator",
      cost: "U.S",
    },
  ];
  let columnsCosts = [
    {
      field: "providerCategoryName",
      headerName: "Provider Category",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "countryCode",
      headerName: "Country ISO",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Cost",
      field: "cost",
      minWidth: 150,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => {
        const row = params.row;

        return `${row?.cost} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    // {
    //   headerName: "Currency",
    //   field: "currencyCode",
    //   minWidth: 80,
    //   flex: 1,
    // },
  ];

  const tableDataWallet = [
    {
      id: 1,
      clientCategory: "clientCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];

  const tableProfitMargin = [
    {
      clientCategory: "clientCategory 1",
      percentage: "80%",
    },
  ];
  let columnsWallet = [
    {
      field: "clientCategory",
      headerName: "Client Category",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];
  //Accounts Type
  const [refreshTableAccountsType, setRefreshTableAccountsType] = useState("");
  const [countryOptionsSearch, setCountryOptionsSearch] = useState(false);

  const [paginationModalAccountsType, setPaginationModalAccountsType] =
    useState({
      page: 0,
      pageSize: 10,
    });
  const [searchAccountsType, setSearchAccountsType] = useState("");
  const [tableDataAccountsType, setTableDataAccountsType] = useState([]);

  const tableQueryExecuterAccountsType = () => {
    setIsLoading(true);
    setRefreshTableAccountsType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_ACCOUNTS_TYPE}?PageIndex=${
          resetPagination ? 1 : paginationModalAccountsType?.page + 1
        }&PageSize=${paginationModalAccountsType?.pageSize}${
          searchAccountsType ? `&Keyword=${searchAccountsType}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataAccountsType({
          data: res?.data?.data?.types ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterAccountsType();
        }
        setTableDataAccountsType({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Accounts Status
  const [refreshTableAccountsStatus, setRefreshTableAccountsStatus] =
    useState("");
  const [paginationModalAccountsStatus, setPaginationModalAccountsStatus] =
    useState({
      page: 0,
      pageSize: 10,
    });
  const [searchAccountsStatus, setSearchAccountsStatus] = useState("");
  const [tableDataAccountsStatus, setTableDataAccountsStatus] = useState([]);

  const tableQueryExecuterAccountsStatus = () => {
    setIsLoading(true);
    setRefreshTableAccountsStatus(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_ACCOUNTS_STATUS}?PageIndex=${
          resetPagination ? 1 : paginationModalAccountsStatus?.page + 1
        }&PageSize=${paginationModalAccountsStatus?.pageSize}${
          searchAccountsStatus ? `&Keyword=${searchAccountsStatus}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataAccountsStatus({
          data: res?.data?.data?.statuses ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterAccountsStatus();
        }
        setTableDataAccountsStatus({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Accounts Status
  const [refreshTableTaxes, setRefreshTableTaxes] = useState("");
  const [openAdvancedSearchMissingRates, setOpenAdvancedSearchMissingRates] =
    useState(false);

  const [paginationModalTaxes, setPaginationModalTaxes] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchTaxes, setSearchTaxes] = useState("");
  const [tableDataTaxes, setTableDataTaxes] = useState([]);

  const tableQueryExecuterTaxes = () => {
    setIsLoading(true);
    setRefreshTableTaxes(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_ACCOUNTS_STATUS}?PageIndex=${
          resetPagination ? 1 : paginationModalTaxes?.page + 1
        }&PageSize=${paginationModalTaxes?.pageSize}${
          searchTaxes ? `&Keyword=${searchTaxes}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataTaxes({
          data: res?.data?.data?.statuses ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterTaxes();
        }
        setTableDataTaxes({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      activeBtn === "Type" &&
      (refreshTableAccountsType || paginationModalAccountsType)
    ) {
      if (!paginationModalAccountsType) {
        setPaginationModalAccountsType({
          page: 0,
          pageSize: 10,
        });
      }
      tableQueryExecuterAccountsType();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableAccountsType,
    paginationModalAccountsType,
    searchAccountsType,
  ]);
  useEffect(() => {
    if (activeBtn === "Taxes" && (refreshTableTaxes || paginationModalTaxes)) {
      if (!paginationModalTaxes) {
        setPaginationModalTaxes({
          page: 0,
          pageSize: 10,
        });
      }
      tableQueryExecuterTaxes();
      // tableQueryExecuterMNC();
    }
  }, [refreshTableTaxes, paginationModalTaxes, searchTaxes]);

  useEffect(() => {
    if (
      activeBtn === "Status" &&
      (refreshTableAccountsStatus || paginationModalAccountsStatus)
    ) {
      if (!paginationModalAccountsStatus) {
        setPaginationModalAccountsStatus({
          page: 0,
          pageSize: 10,
        });
      }
      tableQueryExecuterAccountsStatus();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableAccountsStatus,
    paginationModalAccountsStatus,
    searchAccountsStatus,
  ]);

  const getAllLanguages = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_LANGUAGES}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTaxesTabs(
          res?.data?.data?.languages?.map((x, i) => ({
            label: x.name,
          }))
        );
        setLangRes(
          res?.data?.data?.languages?.map((x, i) => ({
            label: x?.name,
            value: x?.code,
          }))
        );

        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllLanguages();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (searchAccountsType !== "") {
      setPaginationModalAccountsType({
        page: 0,
        pageSize: 10,
      });
    }
  }, [searchAccountsType]);

  useEffect(() => {
    if (searchAccountsStatus !== "") {
      setPaginationModalAccountsStatus({
        page: 0,
        pageSize: 10,
      });
    }
  }, [searchAccountsStatus]);

  useEffect(() => {
    if (activeBtn === "Type") {
      setPaginationModalAccountsType({
        page: 0,
        pageSize: 10,
      });
      setSearchAccountsType("");
    }
    if (activeBtn === "Status") {
      setPaginationModalAccountsStatus({
        page: 0,
        pageSize: 10,
      });
      setSearchAccountsStatus("");
    }

    if (activeBtn === "Taxes") {
      setPaginationModalTaxes({
        page: 0,
        pageSize: 10,
      });
      setSearchTaxes("");
      getAllLanguages();
    }
  }, [activeBtn]);
  const handleOpenAdvancedSearchMissingRates = () => {
    setOpenAdvancedSearchMissingRates(!openAdvancedSearchMissingRates);
  };
  const handlePopulateBtn = (ClientCategoryGuid) => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["billing"]}${
          isWhatsapp
            ? POPULATE_WHATSAPP
            : POPULATE_RATE_PLAN_PROFIT_MARGIN
        }`,
        {
          ClientCategoryGuid,
          ProviderCategoryGuid:
            activeBtn === "Profit Margin"
              ? providerCategoryCostsGenerateReportProfitMargin
              : providerCategoryCostsGenerateReport,
          ChannelGuid: channelGuid,
        },
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsOpenPopulateModal(false);
        setIsLoading(false);
        setOpenAlert(true);
        setAlertMessage(res?.data?.message);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handlePopulateBtn();
          setIsLoading(false);
        }
        else {
          setOpenMessageAlert(true);
          setIsOpenPopulateModal(false);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  let columnsCostVsRate = [

    {
      headerName: "Client Category",
      field: "clientCategoryName",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Country Name",
      field: "countryName",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "rate",
      headerName: "Rate",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.rate} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.cost} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      headerName: "Delta",
      field: "delta",
      minWidth: 150,
      flex: 1,
    },
    // {
    //   headerName: "Currency Code",
    //   field: "currencyCode",
    //   minWidth: 150,
    //   flex: 1,
    // },
    
    {
      field: "costOperatorName",
      headerName: "Operator",
      minWidth: 150,
      flex: 1,
    },
  ];
  let columnsProfitMargin = [
    {
      field: "clientCategoryName",
      headerName: "Client Category",
      // width: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "countryName",
      headerName: "Country",
      // width: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "percentageRate",
      headerName: "Estimated Profit",
      // width: "auto",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "realProfitPercentage",
      headerName: "Real Profit ",
      // width: "auto",
      minWidth: 150,
      flex: 1,
    },
   
    {
      field: "populate",
      headerName: "",
      // width: "auto",
      minWidth: 150,
      flex: 1,

      // width: 100,
      renderCell: (params) => {
        return (
          <Button
            // onClick={() => handlePopulateBtn(params?.row?.clientCategoryGuid)}
            onClick={() => {
              setIsOpenPopulateModal(true);
              setSelectedProfitMarginData(params?.row?.clientCategoryGuid);
            }}
            className="generate-key"
          >
            Populate
          </Button>
        );
      },
    },
  ];
  // const tableDataTaxes = [
  //   {
  //     id: 1,
  //     clientCategory: "clientCategory",
  //     countrycode: "961",
  //     country: "leabanon",
  //     operator: "operator",
  //     rate: "12312",
  //   },
  // ];
  let columnsTaxes = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];

  const tabsRates = [
    {
      label: "Configured rates",
    },
    {
      label: "Missing rates",
    },
  ];

  const tabs = [
    {
      label: "Cost Vs. Rate",
    },
    {
      label: "Profit Margin",
    },
  ];
  const tabsSupport = [
    {
      label: "Rates",
    },
    {
      label: "Costs",
    },
    {
      label: "Cost Vs. Rate",
    },
    {
      label: "Profit Margin",
    },
  ];

  const getAllCountries = () => {
    setCountryOptionsSearch(false);
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptionsSearch(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryOptionsRes(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.recordGuid,
            value: x?.recordGuid,
          }))
        );
        setOperatorOptionsSearch([]);
        setSearchOperator("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const getAllOperatorName = () => {
    setOperatorOptionsSearch([]);
    setIsLoadingOperator(true);
    let newServices = JSON.parse(services);
    {
      setSearchOperator("");
    }

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${isoCode}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptionsSearch(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
        setIsLoadingOperator(false);
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };
  useEffect(() => {
    if (
      activeBtn === "Missing rates" &&
      serviceGuid &&
      channelGuid &&
      typeof serviceGuid !== undefined &&
      typeof channelGuid !== undefined
    ) {
      tableQueryExecuterMissingRates();
    }
  }, [
    activeBtn,
    clientCategoryRates,
    paginationModelMissingRates,
    serviceGuid,
    channelGuid,
  ]);
  useEffect(() => {
    if (
      activeBtn === "Configured rates" &&
      serviceGuid &&
      channelGuid &&
      typeof serviceGuid !== undefined &&
      typeof channelGuid !== undefined
    ) {
      tableQueryExecuterRates();
    }
  }, [
    activeBtn,
    clientCategoryRates,
    paginationModelRates,
    serviceGuid,
    channelGuid,
  ]);

  useEffect(() => {
    if (
      activeBtn === "Missing rates" &&
      searchMissingRates !== "" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      setPaginationModelMissingRates({
        page: 0,
        pageSize: paginationModelMissingRates?.pageSize
          ? paginationModelMissingRates?.pageSize
          : 10,
      });
    } else {
      if (
        typeof serviceGuid !== "undefined" &&
        typeof channelGuid !== "undefined"
      ) {
        tableQueryExecuterMissingRates();
      }
    }
  }, [searchMissingRates, serviceGuid, channelGuid]);

  useEffect(() => {
    if (
      activeBtn === "Configured rates" &&
      searchRates !== "" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      setPaginationModelRates({
        page: 0,
        pageSize: paginationModelRates?.pageSize
          ? paginationModelRates?.pageSize
          : 10,
      });
    } else {
      if (
        typeof serviceGuid !== "undefined" &&
        typeof channelGuid !== "undefined"
      ) {
        tableQueryExecuterRates();
      }
    }
  }, [searchRates, serviceGuid, channelGuid]);

  useEffect(() => {
    // setServices(JSON.parse(services));
    // setConfig(JSON.parse(config));
    if (
      activeBtn === "Configured rates" &&
      openAlert &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterRates();
    }
    if (
      activeBtn === "Profit Margin" &&
      openAlert &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterProfitMargin();
    }
  }, [openAlert, serviceGuid, channelGuid]);
  useEffect(() => {
    if (
      activeBtn === "Configured rates" &&
      importSuccessMessage !== "" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterRates();
    }
    if (
      activeBtn === "Costs" &&
      importSuccessMessage &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterCosts();
    }
    setClientCategoryImport("");
    setFile("");
    setProviderCategoryCosts("");

    //delete
  }, [importSuccessMessage, refreshCosts, serviceGuid, channelGuid]);
  useEffect(() => {
    if (
      activeBtn === "Configured rates" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterRates();
    }
  }, [refreshTableRates, serviceGuid, channelGuid]);
  useEffect(() => {
    getAllClientsCategory();
    // getAllProvidersCategory();
    getAllCountries();
    // getAllOperatorName();
  }, []);

  useEffect(() => {
    if (searchCountry !== "") {
      getAllOperatorName();
    }
  }, [searchCountry]);
  useEffect(() => {
    //delete
    console.log("serviceGuidserviceGuid", serviceGuid);
    if (
      activeBtn === "Missing rates" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined" &&
      refreshTableMissingRates
    ) {
      tableQueryExecuterMissingRates();
    }
  }, [serviceGuid, channelGuid, refreshTableMissingRates]);
  useEffect(() => {
    if (
      activeBtn === "Cost Vs. Rate" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined" &&
      providerCategoryCostsGenerateReport
    ) {
      tableQueryExecuterCostsVsRates();
    }
    if (
      activeBtn === "Profit Margin" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterProfitMargin();
    }
    setPaginationModelCostsVsRates({
      page: 0,
      pageSize: paginationModelCostVsRate?.pageSize
        ? paginationModelCostVsRate?.pageSize
        : 10,
    });
    setPaginationModelProfitMargin({
      page: 0,
      pageSize: 10,
    });
  }, [generateRport, openImportFile, serviceGuid, channelGuid]);

  const getAllClientsCategory = () => {
    setClientCategoryRatesOptions(false);
    setClientCategoryImportOptions(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_CLIENTS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setClientCategoryRatesOptions([
          { label: "All", value: "" },
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setClientCategoryImportOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);

        setClientCategoryOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0]?.recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllClientsCategory();
        }
      })
      .finally(() => {});
  };
  const getAllServiceGuid = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${GET_ALL_CLIENTS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setClientCategoryRatesOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.serviceCategoryGuid,
          })),
        ]);
        setClientCategoryImportOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);

        setClientCategoryOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllServiceGuid();
        }
      })
      .finally(() => {});
  };
  const exportRates = () => {
    setIsLoading(true);
    setRefreshTableRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_ALL_RATES}?exportToCsv=true&ChannelGuid=${channelGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Configured rates.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportRates();
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };

  const exportMissingRates = () => {
    setIsLoading(true);
    setRefreshTableMissingRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_MISSING_RATES}?exportToCsv=true&ChannelGuid=${channelGuid}&ServiceGuid=${serviceGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({ message: "Download Successfully ", type: "success" });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Missing rates.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportMissingRates();
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };

  const getAllProvidersCategory = () => {
    setProviderCategoryImportOptions(false);
    setProviderCategoryCostsOptionsCosts(false);
    setProviderCategoryCostsOptions(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_PROVIDERS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setProviderCategoryCostsOptions([
          { label: "All", value: "" },

          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label:
              x.providerCategoryDetails && x.providerCategoryDetails.length > 0
                ? x.providerCategoryDetails[0]?.name
                : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryCostsOptionsCosts([
          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label:
              x.providerCategoryDetails && x.providerCategoryDetails.length > 0
                ? x.providerCategoryDetails[0]?.name
                : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryCostsGenerateReport(
          res?.data?.data?.providerCategories[0]?.recordGuid
        );
        setProviderCategoryCostsGenerateReportProfitMargin(
          res?.data?.data?.providerCategories[0]?.recordGuid
        );
        tableQueryExecuterCostsVsRates(
          res?.data?.data?.providerCategories[0]?.recordGuid
        );
        // tableQueryExecuterCostsVsRates(
        //   res?.data?.data?.providerCategories[0]?.recordGuid
        // );
        // tableQueryExecuterProfitMargin(
        //   res?.data?.data?.providerCategories[0]?.recordGuid
        // );
        setProviderCategoryImportOptions([
          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label: x.providerCategoryDetails
              ? x.providerCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0]?.recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProvidersCategory();
        }
      })
      .finally(() => {});
  };
  const tableQueryExecuterRates = (promiseResolve, promiseReject) => {
    setIsLoading(true);
    setRefreshTableRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_RATES}?ChannelGuid=${channelGuid}&ClientCategoryGuid=${clientCategoryRates}&ServiceGuid=${serviceGuid}&pageindex=${
          resetPagination ? 1 : paginationModelRates?.page + 1
        }&pagesize=${paginationModelRates?.pageSize}${
          searchRates ? `&keyword=${searchRates}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        console.log("asfasfasfasf", res);
        // this.setState({ tableDataRes: res?.data?.data?.apis ?? [] });
        setTableRates({
          data: res?.data?.data?.ratePlans ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelRates({
        //   ...paginationModelRates,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterRates();
        }
        setTableRates({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleResetAllMissingRates = () => {
    setSearchRates("");
    setSearchCountryIso("");
    setSearchCountry("");
    setSearchOperator("");
    setSearchCosts2("");
    setSearchClientCategory("");
    setPaginationModelMissingRates({
      page: paginationModelMissingRates?.page
        ? paginationModelMissingRates?.page
        : 0,
      pageSize: paginationModelMissingRates?.pageSize
        ? paginationModelMissingRates?.pageSize
        : 10,
    });
  };
  const tableQueryExecuterMissingRates = (promiseResolve, promiseReject) => {
    setIsLoading(true);
    setRefreshTableRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_MISSING_RATES}?ChannelGuid=${channelGuid}&ClientCategoryGuid=${searchClientCategory}&CountryRecordGuid=${searchCountry}&OperatorRecordGuid=${searchOperator}&ServiceGuid=${serviceGuid}&pageindex=${
          resetPagination ? 1 : paginationModelMissingRates?.page + 1
        }&pagesize=${paginationModelMissingRates?.pageSize}${
          searchMissingRates ? `&keyword=${searchMissingRates}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        // this.setState({ tableDataRes: res?.data?.data?.apis ?? [] });
        setTableMissingRates({
          data: res?.data?.data?.missingRatePlans ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelRates({
        //   ...paginationModelRates,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterMissingRates();
        }
        setTableMissingRates({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const DeleteRate = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${DELETE_RATE}?RecordGuid=${recordGuidRates}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // toast.success("Rate Deleted");
        setConfirmOpenRates(false);
        setRefreshTableRates(true);
        setOpenAlert(true);
        setAlertMessage(data?.message);
        setPaginationModelRates({
          page: 0,
          pageSize: paginationModelRates?.pageSize
            ? paginationModelRates?.pageSize
            : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteRate();
        }
        // toast.error(err?.response?.data?.Message || "Somthing Went Wrong!");
      });
  };

  const DeleteCost = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_COST_PLAN}?RecordGuid=${recordGuidCosts}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        tableQueryExecuterCosts();
        setPaginationModelCosts({
          page: 0,
          pageSize: paginationModelCosts?.pageSize
            ? paginationModelCosts?.pageSize
            : 10,
        });
        setConfirmOpenCosts(false);
        setOpenAlert(true);
        setAlertMessage(data?.message);
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteCost();
        }
      });
  };

  const tableQueryExecuterCosts = (promiseResolve, promiseReject) => {
    setIsLoadingCost(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["billing"]}${
          providerCategoryCosts
            ? GET_ALL_COSTS_PLANS_PROVIDER
            : GET_ALL_COSTS_PLANS
        }?ChannelGuid=${channelGuid}&ServiceGuid=${serviceGuid}&pageindex=${
          resetPagination ? 1 : paginationModelCosts?.page + 1
        }&pagesize=${paginationModelCosts?.pageSize}${
          searchCosts ? `&keyword=${searchCosts}` : ""
        }${
          providerCategoryCosts
            ? `&ProviderCategoryGuid=${providerCategoryCosts}`
            : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableCosts({
          data: res?.data?.data?.costPlans ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoadingCost(false);
        }
        setTableCosts({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoadingCost(false);
      });
  };
  const tableQueryExecuterCostsVsRates = (providerGuid) => {
    console.log(
      "providerCategoryCostsGenerateReport123",
      providerCategoryCostsGenerateReport
    );
    console.log("aaaaaasssasdstypeof", typeof providerGuid !== "undefined");

    console.log("aaaaaasssasds", providerGuid);
    {
      (typeof providerGuid !== "undefined" ||
        providerCategoryCostsGenerateReport !== "undefined") &&
        setIsLoading(true);
      setGenerateReport(false);
      let newServices = JSON.parse(services);
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
      setIsLoading(true);
      axiosInstance
        .get(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${GET_ALL_COSTS_RATES}?ChannelGuid=${channelGuid}&ServiceGuid=${serviceGuid}&pageindex=${
            resetPagination ? 1 : paginationModelCostsVsRates?.page + 1
          }&pagesize=${paginationModelCostsVsRates?.pageSize}${
            providerCategoryCostsGenerateReport
              ? `&ProviderCategoryGuid=${providerCategoryCostsGenerateReport}`
              : `&ProviderCategoryGuid=${providerGuid}`
          }`,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setTableCostsVsRates({
            data: res?.data?.data?.rateCostPlans ?? [],
            totalCount: res?.data?.data?.totalRows,
            pageIndex: res?.data?.data?.pageIndex,
          });
        })
        .catch((err) => {
          if (err === "error: 401") {
            setIsLoading(false);
          }
          setTableCostsVsRates({ data: [], totalCount: 0, isLoading: false });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const tableQueryExecuterProfitMargin = (providerGuid) => {
    setIsLoading(true);
    setGenerateReport(false);

    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    if (
      providerCategoryCostsGenerateReportProfitMargin !== "" ||
      typeof providerGuid !== "undefined"
    ) {
      axiosInstance
        .get(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${GET_ALL_PROFIT_MARGIN}?ChannelGuid=${channelGuid}&ServiceGuid=${serviceGuid}&pageindex=${
            resetPagination ? 1 : paginationModelProfitMargin?.page + 1
          }&pagesize=${paginationModelProfitMargin?.pageSize}${
            providerCategoryCostsGenerateReportProfitMargin
              ? `&ProviderCategoryGuid=${providerCategoryCostsGenerateReportProfitMargin}`
              : `&ProviderCategoryGuid=${providerGuid}`
          }`,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          console.log(
            "res?.data?.data?.rateCostPlans",
            res?.data?.data?.rateCostPlans
          );
          setTableDataProfitMargin({
            data: res?.data?.data?.profitMargins ?? [],
            totalCount: res?.data?.data?.totalRows,
            pageIndex: res?.data?.data?.pageIndex,
          });
        })
        .catch((err) => {
          if (err === "error: 401") {
            setIsLoading(false);
          }
          setTableDataProfitMargin({
            data: [],
            totalCount: 0,
            isLoading: false,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const exportCosts = () => {
    setIsLoading(true);
    setRefreshCosts(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${EXPORT_ALL_COSTS}?exportToCsv=true&ChannelGuid=${channelGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Costs.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportCosts();
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (
      activeBtn === "Cost Vs. Rate" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterCostsVsRates();
    }
  }, [paginationModelCostsVsRates, serviceGuid, channelGuid]);

  useEffect(() => {
    if (
      activeBtn === "Profit Margin" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterProfitMargin();
    }
  }, [paginationModelProfitMargin, serviceGuid, channelGuid]);

  useEffect(() => {
    if (
      activeBtn === "Costs" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      tableQueryExecuterCosts();
    }
  }, [
    activeBtn,
    paginationModelCosts,
    providerCategoryCosts,
    serviceGuid,
    channelGuid,
  ]);

  useEffect(() => {
    if (
      activeBtn === "Costs" &&
      searchCosts !== "" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      setPaginationModelCosts({
        page: 0,
        pageSize: paginationModelCosts?.pageSize
          ? paginationModelCosts?.pageSize
          : 10,
      });
    } else {
      if (
        typeof serviceGuid !== "undefined" &&
        typeof channelGuid !== "undefined"
      ) {
        tableQueryExecuterCosts();
      }
    }
  }, [searchCosts, serviceGuid, channelGuid]);

  useEffect(() => {
    if (openAlert) {
      tableQueryExecuterCosts();
    }
  }, [openAlert]);

  // Delete Modal Accounts functions
  const [ConfirmOpenAccounts, setConfirmOpenAccounts] = useState(false);
  const [modalContentAccounts, setModalContentAccounts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalAccounts = (title, text, onButtonClick) => {
    setModalContentAccounts({ title, text, onButtonClick });
    setConfirmOpenAccounts(true);
  };

  const handleConfirmModalAccounts = () => {
    setConfirmOpenAccounts(false);
  };
  const handleGenerateReport = () => {
    setGenerateReport(true);
  };

  //

  // Delete Modal Rates functions
  const [modalContentRates, setModalContentRates] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalRates = (data) => {
    setRecordGuidRates(data.recordGuid);
    setModalContentRates();
    setConfirmOpenRates(true);
  };

  const OpenConfirmModalMissingRates = (data) => {
    setRecordGuidRates(data.recordGuid);
    setConfirmOpenMissingRates(true);
  };

  const handleConfirmModalRates = () => {
    setConfirmOpenRates(false);
  };

  //
  // Delete Modal Costs functions
  const [ConfirmOpenCosts, setConfirmOpenCosts] = useState(false);
  const [modalContentCosts, setModalContentCosts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalCosts = (data) => {
    setRecordGuidCosts(data.recordGuid);
    setModalContentCosts();
    setConfirmOpenCosts(true);
  };

  const handleConfirmModalCosts = () => {
    setConfirmOpenCosts(false);
  };

  //
  // Delete Modal Wallet functions
  const [ConfirmOpenWallet, setConfirmOpenWallet] = useState(false);
  const [modalContentWallet, setModalContentWallet] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalWallet = (title, text, onButtonClick) => {
    setModalContentWallet({ title, text, onButtonClick });
    setConfirmOpenWallet(true);
  };

  const handleConfirmModalWallet = () => {
    setConfirmOpenWallet(false);
  };

  //
  // Delete Modal Taxes functions
  const [ConfirmOpenTaxes, setConfirmOpenTaxes] = useState(false);
  const [modalContentTaxes, setModalContentTaxes] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalTaxes = (title, text, onButtonClick) => {
    setModalContentTaxes({ title, text, onButtonClick });
    setConfirmOpenTaxes(true);
  };

  const handleConfirmModalTaxes = () => {
    setConfirmOpenTaxes(false);
  };
  //

  // Manage Accounts
  const [MangeAccountsAddopen, setMangeAccountsAddopen] = useState(false);
  const [MangeAccountsEditopen, setMangeAccountsEditopen] = useState(false);

  const handleAddMangeAccountsopen = () => {
    setMangeAccountsAddopen(true);
  };

  const handleAddMangeAccountsclose = () => {
    setMangeAccountsAddopen(false);
  };
  const handleEditMangeAccountsopen = () => {
    setMangeAccountsEditopen(true);
  };

  const handleEditMangeAccountsclose = () => {
    setMangeAccountsEditopen(false);
  };

  // Manage Rates
  const [MangeRatesAddopen, setMangeRatesAddopen] = useState(false);
  const [MangeMissingRatesAddopen, setMangeMissingRatesAddopen] =
    useState(false);

  const [MangeRatesEditopen, setMangeRatesEditopen] = useState(false);
  const [manageMissingRateopen, setManageMissingRateopen] = useState(false);
  const [selectedMissingRate, setSelectedMissingRate] = useState("");

  const [MangeMissingRatesEditopen, setMangeMissingRatesEditopen] =
    useState(false);

  const handleAddMangeRatesopen = () => {
    setMangeRatesAddopen(true);
  };
  const handleAddMangeMissingRatesopen = () => {
    setMangeMissingRatesAddopen(true);
  };
  const handleAddMangeRatesclose = () => {
    setMangeRatesAddopen(false);
  };
  const handleEditMangeRatesopen = (data) => {
    setSectedRates(data);
    setMangeRatesEditopen(true);
  };

  const handleAddMissingRateopen = (data) => {
    setSelectedMissingRate(data);
    setManageMissingRateopen(true);
  };
  const handleEditMangeMissingRatesopen = (data) => {
    setSectedRates(data);
    setMangeMissingRatesEditopen(true);
  };
  const handleEditManageCostopen = (data) => {
    setSectedCost(data);
    setMangeCostsEditopen(true);
  };

  const handleEditMangeRatesclose = () => {
    setMangeRatesEditopen(false);
  };

  // Manage Costs
  const [MangeCostsAddopen, setMangeCostsAddopen] = useState(false);
  const [MangeCostsEditopen, setMangeCostsEditopen] = useState(false);
  const [taxesTabs, setTaxesTabs] = useState([]);
  const [langRes, setLangRes] = useState([]);

  const handleAddMangeCostsopen = () => {
    setMangeCostsAddopen(true);
  };

  const handleAddMangeCostsclose = () => {
    setMangeCostsAddopen(false);
  };
  const handleEditMangeCostsopen = () => {
    setMangeCostsEditopen(true);
  };

  const handleEditMangeCostsclose = () => {
    setMangeCostsEditopen(false);
  };

  // Manage Wallet
  const [MangeWalletAddopen, setMangeWalletAddopen] = useState(false);
  const [MangeWalletEditopen, setMangeWalletEditopen] = useState(false);

  const handleAddMangeWalletopen = () => {
    setMangeWalletAddopen(true);
  };

  const handleAddMangeWalletclose = () => {
    setMangeWalletAddopen(false);
  };
  const handleEditMangeWalletopen = () => {
    setMangeWalletEditopen(true);
  };

  const handleEditMangeWalletclose = () => {
    setMangeWalletEditopen(false);
  };

  // Manage Taxes
  const [MangeTaxesAddopen, setMangeTaxesAddopen] = useState(false);
  const [MangeTaxesEditopen, setMangeTaxesEditopen] = useState(false);

  const handleAddMangeTaxesopen = () => {
    setMangeTaxesAddopen(true);
  };

  const handleAddMangeTaxesclose = () => {
    setMangeTaxesAddopen(false);
  };
  const handleEditMangeTaxesopen = () => {
    setMangeTaxesEditopen(true);
  };

  const handleEditMangeTaxesclose = () => {
    setMangeTaxesEditopen(false);
  };
  const handleTabChangeTaxesTabs = (newTabLabel) => {
    setActiveBtnTaxesTab(newTabLabel);
  };

  useEffect(() => {
    if (
      activeBtn == "Configured rates" &&
      typeof serviceGuid !== "undefined" &&
      typeof channelGuid !== "undefined"
    ) {
      window.scrollTo(0, 0);
      getAllClientsCategory();
    }
    setClientCategoryRates("");
    setProviderCategoryCosts("");
    setPaginationModelCostsVsRates({
      page: 0,
      pageSize: 10,
    });
    setPaginationModelProfitMargin({
      page: 0,
      pageSize: 10,
    });
  }, [activeBtn, serviceGuid, channelGuid]);

  useEffect(() => {
    if (
      activeBtn == "Costs" ||
      activeBtn === "Cost Vs. Rate" ||
      activeBtn === "Profit Margin"
    ) {
      window.scrollTo(0, 0);
      getAllProvidersCategory();
    }
  }, [activeBtn]);

  useEffect(() => {
    setSearchRates("");
    setClientCategoryRates("");
  }, [activeBtn]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  {
    console.log("Asfasfsafasfsafasfas", activeBtn);
  }
  return (
    <div className="billing">
      <Grid style={{ marginTop: "-70px" }} container>
        <Grid item xs={6} md={6} className="centerresponsive">
          {/* <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Billing</Typography>
            <Typography className="BreadcrumbsPage">Pricing</Typography>

            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs> */}
        </Grid>

        <Grid
          sx={{ marginTop: windowWidth < 1000 && "-20px" }}
          item
          xs={6}
          md={6}
          className="end"
        >
          {activeBtn === "Type" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeAccountsopen}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Type</Typography>
            </Button>
          )}
          {activeBtn === "Category" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeAccountsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Category</Typography>
            </Button>
          )}
          {activeBtn === "Status" && (
            <Button
              // variant="contained"
              className="contained"
              onClick={handleAddMangeAccountsopen}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Status</Typography>
            </Button>
          )}

          {activeBtn === "Configured rates" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeRatesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Rate</Typography>
            </Button>
          )}
          {activeBtn === "Missing rates" && (
            <Button
              sx={{ visibility: "hidden" }}
              variant="contained"
              className="contained"
              onClick={handleAddMangeMissingRatesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add rate</Typography>
            </Button>
          )}
          {activeBtn === "Costs" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeCostsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Cost</Typography>
            </Button>
          )}

          {/* {activeBtn !== "Costs" && activeBtn !== "Rates" && (
            <Button
              variant="contained"
              className="contained hidden-btn"
              onClick={handleAddMangeCostsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Cost</Typography>
            </Button>
          )} */}

          {/* {activeBtn === "Profit Margin" && (
            <Button
              variant="contained"
              className="contained"
              onClick={() => handlePopulateBtn("")}
            >
              <Typography sx={{ fontSize: "14px" }}>Populate All</Typography>
            </Button>
          )} */}

          {activeBtn === "Wallet" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeWalletopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add rate</Typography>
            </Button>
          )}
          {activeBtn === "Taxes" && (
            <Grid container className="start">
              <Grid item xs={6}></Grid>
              <Grid item xs="6" className="end">
                <Button
                  variant="contained"
                  className="contained"
                  onClick={handleAddMangeTaxesopen}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>Add rate</Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* {windowWidth < 1000 && (
        <Grid
          style={{ marginLeft: "0px" }}
          container
          marginLeft={2}
          marginTop={5}
        >
          <HorizantalTabs
            tabs={tabs}
            setActiveBtn={setActiveBtn}
            activeBtn={activeBtn}
          />
        </Grid>
      )} */}

      <Grid container className="Top-spacing div-table">
        {windowWidth > 1000 ? (
          <Grid
            item
            xs={windowWidth < 1800 ? 1.9 : 1.3}
            className="vertical-grid"
            sx={{ paddingLeft: "2px" }}
            style={{
              display: !isProfit && selectedBtn === "Costs" && "none",
            }}
          >
            <VerticalTabs
              tabs={selectedBtn === "Configured rates" ? tabsRates : tabs}
              setActiveBtn={setActiveBtn}
              activeBtn={activeBtn}
            />
          </Grid>
        ) : (
          <Grid
            style={{
              marginLeft: "0px",
              marginBottom: "-25px",
              display: !isProfit && selectedBtn === "Costs" && "none",
            }}
            container
            marginLeft={2}
            marginTop={5}
          >
            <HorizontalTabsSubtabs
              activeBtn={activeBtn}
              tabs={selectedBtn === "Configured rates" ? tabsRates : tabs}
              setActiveBtn={setActiveBtn}
            />
          </Grid>
        )}

        <Grid
          xs={
            selectedBtn === "Costs"
              ? windowWidth < 1800
                ? 12
                : 12
              : windowWidth < 1800
              ? 10
              : 10.7
          }
        >
          {/* {activeBtn === "Accounts" && (
                <>
                  <Grid container>
                    <Grid xs={12} item>
                      {localStorage.getItem("roleName") !== "support" ? (
                        // <HorizantalTabs
                        //   tabs={tabsHorizantal}
                        //   setActiveBtn={setActiveBtnHorizantal}
                        // />
                        <></>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid> */}

          <Grid container marginTop={windowWidth < 1000 && 5}>
            <Grid xs={12}>
              {activeBtn === "Type" && (
                <Paper variant="CardTable" className="tt">
                  <Table
                    disableActions={true}
                    // exportFunction={exportAccountsType}
                    rowCountState={tableDataAccountsType?.totalCount}
                    data={tableDataAccountsType?.data}
                    isLoading={isLoading}
                    columns={columnsAccountsType}
                    pageIndex={
                      tableDataAccountsType?.pageIndex
                        ? tableDataAccountsType?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModalAccountsType}
                    paginationModel={paginationModalAccountsType}
                    // enableDelete={true}
                    // enableEdit={true}
                    // deleteFunction={OpenConfirmModalLocation}
                    // editFunction={handleEditMangeLocationopen}
                    enableSearch={true}
                    search={searchAccountsType}
                    setSearch={setSearchAccountsType}
                    // enableExportBtn={true}
                  />
                </Paper>
              )}

              {activeBtn === "Category" && (
                <Paper variant="CardTable">
                  <Table
                    rowCountState={tableDataAccountsCategory?.totalCount}
                    data={tableDataAccountsCategory}
                    // isLoading={isLoading}
                    columns={columnsAccountsCategory}
                    pageIndex={
                      tableDataAccountsCategory?.pageIndex
                        ? tableDataAccountsCategory?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModel}
                    paginationModel={paginationModel}
                    enableDelete={true}
                    enableEdit={true}
                    deleteFunction={OpenConfirmModalAccounts}
                    editFunction={handleEditMangeAccountsopen}
                    enableSearch={true}
                    enableExportBtn={true}
                  />
                </Paper>
              )}

              {activeBtn === "Status" && (
                <Paper variant="CardTable">
                  <Table
                    disableActions={true}
                    // exportFunction={exportZone}
                    rowCountState={tableDataAccountsStatus?.totalCount}
                    data={tableDataAccountsStatus?.data}
                    isLoading={isLoading}
                    columns={columnsAccountsStatus}
                    pageIndex={
                      tableDataAccountsStatus?.pageIndex
                        ? tableDataAccountsStatus?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModalAccountsStatus}
                    paginationModel={paginationModalAccountsStatus}
                    // enableDelete={true}
                    // enableEdit={true}
                    // deleteFunction={OpenConfirmModalLocation}
                    // editFunction={handleEditMangeLocationopen}
                    enableSearch={true}
                    search={searchAccountsStatus}
                    setSearch={setSearchAccountsStatus}
                    // enableExportBtn={true}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
          {/* </>
              )} */}
          {activeBtn === "Configured rates" && (
            <Grid container>
              <Grid item xs={12}>
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", width: "100%" }}
                >
                  <Table
                    rowCountState={tableRates?.totalCount}
                    data={tableRates?.data}
                    isLoading={isLoading}
                    columns={columnsRates}
                    pageIndex={
                      tableRates?.pageIndex ? tableRates?.pageIndex : 0
                    }
                    tableRates={tableRates}
                    setPaginationModel={setPaginationModelRates}
                    paginationModel={paginationModelRates}
                    enableDelete={true}
                    enableEdit={true}
                    deleteFunction={OpenConfirmModalRates}
                    editFunction={handleEditMangeRatesopen}
                    enableSearch={true}
                    search={searchRates}
                    setSearch={setSearchRates}
                    // enableFilter={true}
                    enableImportBtn={true}
                    ImportFunction={() => setopenImportFile(true)}
                    valueFilter={clientCategoryRates}
                    setValueFilter={setClientCategoryRates}
                    optionsFilter={clientCategoryRatesOptions}
                    enableFilter2={true}
                    placeholderFilter="Client Category"
                    enableExportBtn={true}
                    exportFunction={exportRates}
                  />
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeBtn === "Missing rates" && (
            <Grid>
              <Grid item xs={12}>
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", width: "100%" }}
                >
                  <Table
                    rowCountState={tableMissingRates?.totalCount}
                    data={tableMissingRates?.data}
                    isLoading={isLoading}
                    disableActions={true}
                    columns={columnsMissingRates}
                    pageIndex={
                      tableMissingRates?.pageIndex
                        ? tableMissingRates?.pageIndex
                        : 0
                    }
                    tableRates={tableMissingRates}
                    setPaginationModel={setPaginationModelMissingRates}
                    paginationModel={paginationModelMissingRates}
                    enableDelete={false}
                    enableEdit={false}
                    deleteFunction={OpenConfirmModalMissingRates}
                    editFunction={handleEditMangeMissingRatesopen}
                    // enableSearch={true}
                    // search={searchMissingRates}
                    // setSearch={setSearchMissingRates}
                    // enableFilter={true}
                    enableImportBtn={false}
                    ImportFunction={() => setopenImportFileMissingRates(true)}
                    valueFilter={clientCategoryRates}
                    setValueFilter={setClientCategoryRates}
                    optionsFilter={clientCategoryRatesOptions}
                    // enableFilter2={true}
                    // placeholderFilter="Client Category"
                    enableExportBtn={true}
                    exportFunction={exportMissingRates}
                    // enableAdd={true}
                    // addFunction={handleAddMissingRateopen}
                    // addFunction={}
                    advancedSearchFunction={
                      handleOpenAdvancedSearchMissingRates
                    }
                    handleResetAll={handleResetAllMissingRates}
                    advancedSearch={openAdvancedSearchMissingRates}
                    handleSearchBtn={() => {
                      setRefreshTableMissingRates(true);
                      setPaginationModelMissingRates({
                        page: 0,
                        pageSize: paginationModelMissingRates?.pageSize
                          ? paginationModelMissingRates?.pageSize
                          : 10,
                      });
                    }}
                    contentAdvancedSearch={
                      <Grid spacing={3} container>
                        <Grid lg={3} sm={4} xs={12} item>
                          <SelectFormNew
                            value={searchClientCategory}
                            setValue={setSearchClientCategory}
                            options={ClientCategoryOptions}
                            placeholder={"Client Category"}
                            selected={true}
                            disabled={ClientCategoryOptions?.length === 0}
                          />
                        </Grid>
                        <Grid lg={3} sm={4} xs={12} item>
                          <SelectFormNew
                            value={searchCountry}
                            setValue={setSearchCountry}
                            options={countryOptionsSearch}
                            placeholder={"Country"}
                            disabled={countryOptionsSearch?.length === 0}
                          />
                        </Grid>
                        <Grid lg={3} sm={4} xs={12} item>
                          <SelectFormNew
                            value={searchOperator}
                            setValue={setSearchOperator}
                            options={operatorOptionsSearch}
                            placeholder={"Operator"}
                            disabled={operatorOptionsSearch?.length === 0}
                            isLoading={isLoadingOperator}
                            wait={"select a country"}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeBtn === "Costs" && (
            // <Costs activeBtn={activeBtn} />
            <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
              <Table
                rowCountState={tableCosts?.totalCount}
                data={tableCosts?.data}
                isLoading={isLoadingCost}
                columns={columnsCosts}
                pageIndex={tableCosts?.pageIndex ? tableCosts?.pageIndex : 0}
                tableCosts={tableCosts}
                setPaginationModel={setPaginationModelCosts}
                paginationModel={paginationModelCosts}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                exportFunction={exportCosts}
                deleteFunction={OpenConfirmModalCosts}
                editFunction={handleEditManageCostopen}
                enableSearch={true}
                enableFilter2={true}
                enableImportBtn={true}
                search={searchCosts}
                setSearch={setSearchCosts}
                ImportFunction={() => setopenImportFile(true)}
                valueFilter={providerCategoryCosts}
                setValueFilter={setProviderCategoryCosts}
                optionsFilter={providerCategoryCostsOptions}
                placeholderFilter="Provider Category"
              />
            </Paper>
          )}
          {activeBtn === "Wallet" && (
            <Paper variant="CardTable" sx={{ borderTopLeftRadius: "20px" }}>
              <Table
                rowCountState={tableDataWallet?.totalCount}
                data={tableDataWallet}
                // isLoading={isLoading}
                columns={columnsWallet}
                pageIndex={
                  tableDataWallet?.pageIndex ? tableDataWallet?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                enableImportBtn={true}
                deleteFunction={OpenConfirmModalWallet}
                editFunction={handleEditMangeWalletopen}
                enableSearch={true}
                valueFilter={Rates}
                setValueFilter={setRates}
                optionsFilter={["All", "Default", "vip"]}
                placeholderFilter="Client Category"
              />
            </Paper>
          )}
          {activeBtn === "Cost Vs. Rate" && (
            <Paper
              variant="CardTable"
              sx={{
                borderTopLeftRadius: "20px",
                marginTop: "0px",
                width: "100%",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  {windowWidth > 1000 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "10px",
                        marginBottom: "-30px",
                      }}
                    >
                      <Typography
                        style={{
                          margin: "auto 20px auto 0px",
                          width: "155px",
                        }}
                      >
                         Provider Category
                      </Typography>
                      <div style={{ width: "180px" }}>
                        <SelectFormNew
                          value={providerCategoryCostsGenerateReport}
                          setValue={setProviderCategoryCostsGenerateReport}
                          options={providerCategoryCostsOptionsCosts}
                        />
                      </div>
                      <div
                        style={{
                          margin: "auto 20px auto 20px",
                          width: "200px",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="contained"
                          onClick={handleGenerateReport}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            Generate Report
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Typography
                        style={{
                          margin: "auto 20px auto 0px",
                          width: "155px",
                        }}
                      >
                         Provider Category
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: "10px",
                          marginBottom: "-30px",
                        }}
                      >
                        <div style={{ width: "135px" }}>
                          <SelectFormNew
                            value={providerCategoryCostsGenerateReport}
                            setValue={setProviderCategoryCostsGenerateReport}
                            options={providerCategoryCostsOptionsCosts}
                          />
                        </div>
                        <div
                          style={{
                            margin: "0px 0px 0px 20px",
                            width: "100px",
                          }}
                        >
                          <Button
                            variant="contained"
                            className="contained"
                            onClick={handleGenerateReport}
                          >
                            <Typography sx={{ fontSize: "11px" }}>
                              Generate Report
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Grid>
                {/* <Grid item xs={4}> 
                     
                    </Grid> */}
              </Grid>
              <Table
                rowCountState={tableCostsVsRates?.totalCount}
                data={tableCostsVsRates?.data}
                isLoading={isLoading}
                columns={columnsCostVsRate}
                pageIndex={
                  tableCostsVsRates?.pageIndex
                    ? tableCostsVsRates?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModelCostsVsRates}
                paginationModel={paginationModelCostsVsRates}
                disableActions={true}
                // valueFilter={providerCategoryCosts}
                // setValueFilter={setProviderCategoryCosts}
                // optionsFilter={providerCategoryCostsOptions}
                // placeholderFilter="Provider Category"
                // enableFilter2={true}
              />
            </Paper>
          )}
          {activeBtn === "Profit Margin" && (
            <Paper
              variant="CardTable"
              sx={{
                borderTopLeftRadius: "20px",
                marginTop: "0px",
                width: "100%",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  {windowWidth > 1000 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "10px",
                        marginBottom: "-30px",
                      }}
                    >
                      <Typography
                        style={{
                          margin: "auto 20px auto 0px",
                          width: "155px",
                        }}
                      >
                         Provider Category
                      </Typography>
                      <div style={{ width: "180px" }}>
                        <SelectFormNew
                          value={
                            providerCategoryCostsGenerateReportProfitMargin
                          }
                          setValue={
                            setProviderCategoryCostsGenerateReportProfitMargin
                          }
                          options={providerCategoryCostsOptionsCosts}
                        />
                      </div>
                      <div
                        style={{
                          margin: "auto 20px auto 20px",
                          width: "200px",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="contained"
                          onClick={handleGenerateReport}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            Generate Report
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Typography
                        style={{
                          margin: "auto 20px auto 0px",
                          width: "155px",
                        }}
                      >
                         Provider Category
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: "10px",
                          marginBottom: "-30px",
                        }}
                      >
                        <div style={{ width: "135px" }}>
                          <SelectFormNew
                            value={
                              providerCategoryCostsGenerateReportProfitMargin
                            }
                            setValue={
                              setProviderCategoryCostsGenerateReportProfitMargin
                            }
                            options={providerCategoryCostsOptionsCosts}
                          />
                        </div>
                        <div
                          style={{
                            margin: "0px 0px 0px 20px",
                            width: "100px",
                          }}
                        >
                          <Button
                            variant="contained"
                            className="contained"
                            onClick={handleGenerateReport}
                          >
                            <Typography sx={{ fontSize: "11px" }}>
                              Generate Report
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
              <Table
                rowCountState={tableDataProfitMargin?.totalCount}
                data={tableDataProfitMargin?.data}
                isLoading={isLoading}
                columns={columnsProfitMargin}
                pageIndex={
                  tableDataProfitMargin?.pageIndex
                    ? tableDataProfitMargin?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                // enableDelete={true}
                enableEdit={true}
                // enableExportBtn={true}
                // enableImportBtn={true}
                // deleteFunction={OpenConfirmModalWallet}
                disableActions={true}
                editFunction={handleOpenEditProfitMarginModal}
                // valueFilter={providerCategoryCosts}
                // setValueFilter={setProviderCategoryCosts}
                // optionsFilter={providerCategoryCostsOptions}
                // placeholderFilter="Provider Category"
                // enableFilter2={true}
              />
            </Paper>
          )}
          {activeBtn === "Taxes" && (
            <Paper
              variant="CardTable"
              sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
            >
              {" "}
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginTop: "27px",
                      marginBottom: "-120px",
                    }}
                  >
                    <HorizantalTabs
                      tabs={taxesTabs}
                      onTabChange={handleTabChangeTaxesTabs}
                    />
                  </div>
                </Grid>
              </Grid>
              <Table
                // disableActions={true}
                // exportFunction={exportAccountsType}
                rowCountState={tableDataTaxes?.totalCount}
                data={tableDataTaxes?.data}
                isLoading={isLoading}
                columns={columnsTaxes}
                pageIndex={
                  tableDataTaxes?.pageIndex ? tableDataTaxes?.pageIndex : 0
                }
                setPaginationModel={setPaginationModalTaxes}
                paginationModel={paginationModalTaxes}
                // enableDelete={true}
                // enableEdit={true}
                // deleteFunction={OpenConfirmModalLocation}
                // editFunction={handleEditMangeLocationopen}
                enableSearch={true}
                search={searchTaxes}
                setSearch={setSearchTaxes}
                // enableExportBtn={true}
              />
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* Delete Accounts Modal */}
      {handleConfirmModalAccounts && (
        <ConfirmationModal
          open={ConfirmOpenAccounts}
          onClose={handleConfirmModalAccounts}
          title="Delete"
          text="Are you sure you want to delete this cost?"
          onButtonClick={DeleteCost}
        />
      )}

      {isOpenPopulateModal && (
        <ConfirmationModal
          open={isOpenPopulateModal}
          onClose={() => setIsOpenPopulateModal(false)}
          title="Populate"
          text="Are you sure you want to populate this record?"
          onButtonClick={() => handlePopulateBtn(selectedProfitMarginData)}
        />
      )}
      {/* Delete Rates Modal */}
      {ConfirmOpenRates && (
        <ConfirmationModal
          open={ConfirmOpenRates}
          onClose={handleModalCloseSwitchRates}
          title="Delete"
          text="Are you sure you want to delete this rate?"
          onButtonClick={DeleteRate}
        />
      )}
      {/* Delete Costs Modal */}
      {handleConfirmModalCosts && (
        <ConfirmationModal
          open={ConfirmOpenCosts}
          onClose={handleConfirmModalCosts}
          title="Delete"
          text="Are you sure you want to delete this cost ?"
          onButtonClick={DeleteCost}
        />
      )}
      {/* Delete Wallet Modal */}
      {handleConfirmModalWallet && (
        <ConfirmationModal
          open={ConfirmOpenWallet}
          onClose={handleConfirmModalWallet}
          title="Delete"
          text="Are you sure you want to delete this Wallet ?"
          onButtonClick={handleConfirmModalWallet}
        />
      )}
      {/* Delete Taxes Modal */}
      {handleConfirmModalTaxes && (
        <ConfirmationModal
          open={ConfirmOpenTaxes}
          onClose={handleConfirmModalTaxes}
          title="Delete"
          text="Are you sure you want to delete this Taxe ?"
          onButtonClick={handleConfirmModalTaxes}
        />
      )}

      {/* Manage Accounts Modal */}
      {handleAddMangeAccountsopen && (
        <ManageAccounts
          open={MangeAccountsAddopen}
          setOpen={setMangeAccountsAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeAccountsopen && (
        <ManageAccounts
          open={MangeAccountsEditopen}
          setOpen={setMangeAccountsEditopen}
          title={"Edit"}
          CostData={tableCosts?.data}
        />
      )}

      {/* Manage Rates Modal */}
      {MangeRatesAddopen && (
        <ManageRates
          serviceGuid={serviceGuid}
          channelGuid={channelGuid}
          open={MangeRatesAddopen}
          setOpen={setMangeRatesAddopen}
          title={"Add"}
          setClientCategoryOptions={setClientCategoryOptions}
          ClientCategoryOptions={ClientCategoryOptions}
          refreshTableRates={refreshTableRates}
          setRefreshTableRates={setRefreshTableRates}
          setAlertMessage={setAlertMessage}
          setOpenAlert={setOpenAlert}
        />
      )}
      {MangeRatesEditopen && (
        <ManageRates
          serviceGuid={serviceGuid}
          channelGuid={channelGuid}
          open={MangeRatesEditopen}
          setOpen={setMangeRatesEditopen}
          title={"Edit"}
          setOpenAlert={setOpenAlert}
          selectedRates={selectedRates}
          ClientCategoryOptions={ClientCategoryOptions}
          setClientCategoryOptions={setClientCategoryOptions}
          refreshTableRates={refreshTableRates}
          setRefreshTableRates={setRefreshTableRates}
          setAlertMessage={setAlertMessage}
          isEdit={true}
        />
      )}

      {manageMissingRateopen && (
        <ManageMissingRates
          serviceGuid={serviceGuid}
          channelGuid={channelGuid}
          open={manageMissingRateopen}
          setOpen={setManageMissingRateopen}
          title={"Add"}
          selectedMissingRate={selectedMissingRate}
          ClientCategoryOptions={ClientCategoryOptions}
          setClientCategoryOptions={setClientCategoryOptions}
          refreshTableMissingRates={refreshTableMissingRates}
          setRefreshTableMissingRates={setRefreshTableMissingRates}
          isEdit={true}
          setAlertMessage={setAlertMessage}
          setOpenAlert={setOpenAlert}
          setPaginationModelMissingRates={setPaginationModelMissingRates}
          paginationModelMissingRates={paginationModelMissingRates}
        />
      )}

      {/* Manage Costs Modal */}
      {handleAddMangeCostsopen && (
        <ManageCosts
          serviceGuid={serviceGuid}
          channelGuid={channelGuid}
          open={MangeCostsAddopen}
          setOpen={setMangeCostsAddopen}
          setOpenAlert={setOpenAlert}
          title={"Add"}
          providerCategoryImportOptions={providerCategoryImportOptions}
          setAlertMessage={setAlertMessage}
        />
      )}
      {handleEditMangeCostsopen && (
        <ManageCosts
          serviceGuid={serviceGuid}
          channelGuid={channelGuid}
          open={MangeCostsEditopen}
          setOpen={setMangeCostsEditopen}
          setOpenAlert={setOpenAlert}
          selectedCost={selectedCost}
          title={"Edit"}
          isEdit={true}
          providerCategoryImportOptions={providerCategoryImportOptions}
          CostData={tableCosts?.data}
          setAlertMessage={setAlertMessage}
        />
      )}

      {/* Manage Wallet Modal */}
      {handleAddMangeWalletopen && (
        <ManageWallet
          open={MangeWalletAddopen}
          setOpen={setMangeWalletAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeWalletopen && (
        <ManageWallet
          open={MangeWalletEditopen}
          setOpen={setMangeWalletEditopen}
          title={"Edit"}
        />
      )}

      {/* Manage Taxes Modal */}
      {handleAddMangeTaxesopen && (
        <ManageTaxes
          open={MangeTaxesAddopen}
          setOpen={setMangeTaxesAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeTaxesopen && (
        <ManageTaxes
          open={MangeTaxesEditopen}
          setOpen={setMangeTaxesEditopen}
          title={"Edit"}
        />
      )}

      {/* Swtich Accounts Modal */}
      {isModalOpenSwitchAccounts && (
        <ConfirmationModal
          open={isModalOpenSwitchAccounts}
          onClose={handleModalCloseSwitchAccounts}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchAccounts}
        />
      )}
      {console.log("asdasdasdas", isModalOpenSwitchRates)}
      {/* Swtich Rates Modal */}
      {isModalOpenSwitchRates && (
        <ConfirmationModal
          open={isModalOpenSwitchRates}
          onClose={handleModalCloseSwitchRates}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={DeleteRate}
        />
      )}
      {/* Swtich Costs Modal */}
      {isModalOpenSwitchCosts && (
        <ConfirmationModal
          open={isModalOpenSwitchCosts}
          onClose={handleModalCloseSwitchCosts}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchCosts}
        />
      )}
      {/* Swtich Wallet Modal */}
      {isModalOpenSwitchWallet && (
        <ConfirmationModal
          open={isModalOpenSwitchWallet}
          onClose={handleModalCloseSwitchWallet}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchWallet}
        />
      )}

      {/* Swtich Taxes Modal */}
      {isModalOpenSwitchTaxes && (
        <ConfirmationModal
          open={isModalOpenSwitchTaxes}
          onClose={handleModalCloseSwitchTaxes}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchTaxes}
        />
      )}
      {openEditProfitMargin && (
        <ManageProfitMargin
          open={openEditProfitMargin}
          setOpen={handleOpenEditProfitMarginModal}
          onButtonClick={handleOpenEditProfitMarginModal}
          title={"Edit"}
        />
      )}

      <CustomizedDialogs
        open={openImportFile}
        setOpen={setopenImportFile}
        title="Import File"
        // firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid style={{ marginBottom: "15px" }} item xs={12}>
                <SelectFormNew
                  value={
                    activeBtn == "Configured rates"
                      ? clientCategoryImport
                      : providerCategoryImport
                  }
                  setValue={
                    activeBtn == "Configured rates"
                      ? setClientCategoryImport
                      : activeBtn == "Costs"
                      ? setProviderCategoryImport
                      : ""
                  }
                  options={
                    activeBtn == "Configured rates"
                      ? clientCategoryImportOptions
                      : activeBtn == "Costs"
                      ? providerCategoryImportOptions
                      : ""
                  }
                  placeholder={
                    activeBtn == "Configured rates"
                      ? "Client Category"
                      : activeBtn == "Costs"
                      ? "Provider Category"
                      : ""
                  }
                />
              </Grid>
              <MyDropzone 
              setFile={setFile} 
              file={file} 
              downloadSample={true}
              sampleUrl= {`${localStorage.getItem("baseUrl")}/billing${
                activeBtn == "Costs" ? 
                  downloadSampleTag == "Whatsapp" ? DOWNLOAD_COST_WHATSAPP_SAMPLE : DOWNLOAD_COST_SAMPLE
                  :
                  DOWNLOAD_RATE_SAMPLE
              }`}
              />
              <Grid className="end" marginBottom={0} marginTop={3} container>
                <Button
                  onClick={submitFile}
                  variant="contained"
                  className="contained"
                  disabled={
                    activeBtn === "Configured rates"
                      ? clientCategoryImport === "" || file === ""
                      : providerCategoryImport === "" || file === ""
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMesssage}
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportRates}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          your file was exported Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportRatesError}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          your file was not exported
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportCostError}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          your file was not exported
        </Alert>
      </Snackbar>
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlertExportCost}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          your file was exported Successfully
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openImportAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {importMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openImportSuccessAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {importSuccessMessage}
        </Alert>
      </Snackbar>
      {isModalOpenSwitchLock && (
        <ConfirmationModal
          open={isModalOpenSwitchLock}
          onClose={handleModalCloseSwitchLock}
          title={selectedProvider?.isLocked ? "Unlock " : "Lock "}
          disableBtn={
            reasonOptions !== false &&
            reasonOptions?.find((x) => x.value === reason)?.label === "Other"
              ? reason === "" || description === ""
              : reason === ""
          }
          content={
            <>
              <SelectFormNew
                value={reason}
                placementTop={true}
                setValue={setReason}
                options={reasonOptions}
                placeholder={`Please select reason of
              ${selectedProvider?.isLocked ? "unlock " : "lock "}
              `}
                disabled={reasonOptions.length === 0}
              />
              {reasonOptions !== false &&
                reasonOptions?.find((x) => x.value === reason)?.label ===
                  "Other" && (
                  <TextFieldForm
                    placeholder={"Description"}
                    value={description}
                    setValue={setDescription}
                  />
                )}
            </>
          }
          onButtonClick={handleModalConfirmSwitchLock}
        />
      )}
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BillingPricing;
