import Add from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  Card,
  FormControlLabel,
  Grid,
  Grow,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
// import HorizantalTabs from "../../../Components/Tabs/HorizantalTabs";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_BANKING_DETAILS,
  CHANGE_STATUS_CLIENT,
  DELETE_CLIENT,
  EXPORT_CLIENTS_CSV,
  GET_ACCOUNTS_BY_CLIENT,
  GET_ALL_CLIENTS,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_RESELLERS,
  GET_BANKING_INFO,
  GET_PIN,
  MULTI_TENANCY_CONFIG,
  RESET_PASSWORD,
  UPDATE_BANKING_INFO,
} from "../../APIs";
import NewClient from "../Clients/ManageClient";
import ManageAccounts from "./ManageAccounts";
import ManageClient from "./ManageClient";
import ManageCosts from "./ManageCosts";
import ManageRates from "./ManageRates";
import ManageAccountHistory from "./ManageTaxes";
import ManageWallet from "./ManageWallet";
import PasswordInput from "../../../Components/TextFields/PasswordInput";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import { useLocation, useNavigate } from "react-router";

const Clients2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [prevPath, setPrevPath] = useState(null);

  useEffect(() => {
    // Update the previous path whenever the location changes
    setPrevPath(location.pathname);
  }, [location]);

  let services = localStorage.getItem("services");
  const [isOpenShowAccounts, setIsOpenShowAccounts] = useState(false);
  const [reseller, setReseller] = useState("");
  const [resellerPopUp, setResellerPopUp] = useState("");

  const [resellersOptions, setResellersOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeBtn, setActiveBtn] = useState("Clients");
  const [clientType, setClientType] = useState("");
  const [provider, setProvider] = useState("");
  const [accountType, setAccountType] = useState("");
  const [country, setCountry] = useState("");
  const [date, setDate] = useState("");
  const [CountryOptions, setCountryOptions] = useState([]);

  const [name, setName] = useState("");
  const [searchClient, setSearchClient] = useState("");
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);
  const [filteredData, setFilteredData] = useState({});
  const [openPopupResetPass, setOpenPopupResetPass] = useState(false);
  const [isLoadingSentMail, setIsLoadingSentMail] = useState(false);
  const [usernameLogin, setUsernameLogin] = useState("");
  const [pin, setPin] = useState("");
  const [newPass, setNewPass] = useState("");
  const [tag, setTag] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [iban, setIban] = useState("");
  const [codeSwiftBic, setCodeSwiftBic] = useState("");
  const [bankAccNb, setBankAccNb] = useState("");
  const [rountingNb, setRountingNb] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankStreet, setBankStreet] = useState("");
  const [bankPhoneNb, setBankPhoneNb] = useState("");
  const [bankPostCode, setBankPostCode] = useState("");
  const [bankCity, setBankCity] = useState("");
  const [bankSwiftCorrespondent, setBankSwiftCorrespondent] = useState("");
  const [bankingDetailsData, setBankingDetailsData] = useState([]);

  const [resetPagination, setResetPagination] = useState(false);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [clientTypeOptions, setClientTypeOptions] = useState([
    "All",
    "Reseller",
    "Business",
  ]);

  const [paginationModelAccounts, setPaginationModelAccounts] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [providerOptions, setProviderOptions] = useState([
    "provider 1",
    "provider 2",
    "provider 3",
  ]);
  const [CountriesOptions, setCountriesOptions] = useState([
    "Lebanon",
    "Cyprus",
    "Germany",
  ]);

  const [statusOptions, setStatusOptions] = useState(["Active", "Inactive"]);

  const [ActiveBtnHorizantal, setActiveBtnHorizantal] = useState("");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModelClients, setPaginationModelClients] = React.useState({
    page: 0,
    pageSize: 10,
  });
  useEffect(() => {
    console.log(
      "SAdsadasdasd",
      localStorage.getItem("withoutAccout") === "withoutAccout"
    );
    setActiveBtnHorizantal(
      localStorage.getItem("withoutAccout") === "withoutAccout"
        ? "Without Account"
        : "With Account"
    );
  }, []);
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);

  // Accounts
  const [isModalOpenSwitchAccounts, setModalOpenSwitchAccounts] =
    useState(false);
  const [isCheckedSwitchAccounts, setCheckedSwitchAccounts] = useState(false);
  const [switchStatesAccounts, setswitchStatesAccounts] = useState({});
  const handleSwitchChangeSwitchAccounts = (rowId) => {
    setModalOpenSwitchAccounts(true);
    setCheckedSwitchAccounts(rowId);
  };

  const handleModalCloseSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
  };
  const handleModalConfirmSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
    // setCheckedSwitchAccounts(!isCheckedSwitchAccounts);
    setswitchStatesAccounts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchAccounts]: !prevStates[isCheckedSwitchAccounts],
    }));
  };
  const [providerData, setProviderData] = useState({});
  const [tableDataShowAccounts, setTableDataShowAccounts] = useState([]);

  const handleModalShowAccounts = (data) => {
    setTableDataShowAccounts([]);
    setProviderData(data);
    tableQueryExecuterAccounts(data?.recordGuid);
    setIsOpenShowAccounts(!isOpenShowAccounts);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setCreatedDate(value);
  };

  //
  const tableQueryExecuterAccounts = (providerGuid) => {
    setIsLoadingAccounts(true);
    // setSelectedRows([]);
    // setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoadingAccounts(true);

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ACCOUNTS_BY_CLIENT}?PageIndex=${
          paginationModelAccounts?.page + 1
        }&PageSize=${
          paginationModelAccounts?.pageSize
        }&ClientGuid=${providerGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataShowAccounts({
          data: res?.data?.data?.clientAccounts ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        setIsLoadingAccounts(false);

        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          tableQueryExecuterAccounts();
        }
        setTableDataShowAccounts({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoadingAccounts(false);
      });
  };

  // Rates
  const [isModalOpenSwitchRates, setModalOpenSwitchRates] = useState(false);
  const [isCheckedSwitchRates, setCheckedSwitchRates] = useState(false);
  const [refreshTableClient, setRefreshTableClient] = useState(false);
  const [tableDataClient, setTableDataClient] = useState([]);

  const [switchStatesRates, setSwitchStatesRates] = useState({});
  const handleSwitchChangeSwitchRates = (rowId) => {
    setModalOpenSwitchRates(true);
    setCheckedSwitchRates(rowId);
  };

  const handleModalCloseSwitchRates = () => {
    setModalOpenSwitchRates(false);
  };
  const handleModalConfirmSwitchRates = () => {
    setModalOpenSwitchRates(false);
    // setCheckedSwitchRates(!isCheckedSwitchRates);
    setSwitchStatesRates((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchRates]: !prevStates[isCheckedSwitchRates],
    }));
  };

  //
  // Costs
  const [showPopupReseller, setShowPopupReseller] = useState(true);
  const [isModalOpenSwitchCosts, setModalOpenSwitchCosts] = useState(false);
  const [isCheckedSwitchCosts, setCheckedSwitchCosts] = useState(false);
  const [switchStatesCosts, setSwitchStatesCosts] = useState({});
  const handleSwitchChangeSwitchCosts = (rowId) => {
    setModalOpenSwitchCosts(true);
    setCheckedSwitchCosts(rowId);
  };

  const handleModalCloseSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
  };
  const handleModalConfirmSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
    // setCheckedSwitchCosts(!isCheckedSwitchCosts);
    setSwitchStatesCosts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCosts]: !prevStates[isCheckedSwitchCosts],
    }));
  };
  //
  // AccountHistory
  const [isModalOpenSwitchAccountHistory, setModalOpenSwitchAccountHistory] =
    useState(false);
  const [isCheckedSwitchAccountHistory, setCheckedSwitchAccountHistory] =
    useState(false);
  const [switchStatesAccountHistory, setSwitchStatesAccountHistory] = useState(
    {}
  );
  const handleSwitchChangeSwitchAccountHistory = (rowId) => {
    setModalOpenSwitchAccountHistory(true);
    setCheckedSwitchAccountHistory(rowId);
  };

  const handleModalCloseSwitchAccountHistory = () => {
    setModalOpenSwitchAccountHistory(false);
  };
  const handleModalConfirmSwitchAccountHistory = () => {
    setModalOpenSwitchAccountHistory(false);
    // setCheckedSwitchAccountHistory(!isCheckedSwitchAccountHistory);
    setSwitchStatesAccountHistory((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchAccountHistory]:
        !prevStates[isCheckedSwitchAccountHistory],
    }));
  };
  //
  // Wallet
  const [isModalOpenSwitchWallet, setModalOpenSwitchWallet] = useState(false);
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [isCheckedSwitchWallet, setCheckedSwitchWallet] = useState(false);
  const [openManageClients, setOpenManageClient] = useState(false);
  const [isModalOpenSwitchClient, setModalOpenSwitchClient] = useState(false);
  const [isCheckedSwitchClient, setCheckedSwitchClient] = useState(false);

  const [switchStatesWallet, setSwitchStatesWallet] = useState({});
  const handleSwitchChangeSwitchWallet = (rowId) => {
    setModalOpenSwitchWallet(true);
    setCheckedSwitchWallet(rowId);
  };
  const handleManageConfigClose = (data) => {
    setManageConfigOpen(false);
    setSelectedClient({});
  };
  const handleModalCloseSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
  };
  const handleModalConfirmSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
    // setCheckedSwitchWallet(!isCheckedSwitchAccountHistory);
    setSwitchStatesWallet((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchWallet]: !prevStates[isCheckedSwitchWallet],
    }));
  };
  //

  const [Zone, setZone] = React.useState("");
  const [selectedClient, setSelectedClient] = React.useState("");

  const [manageConfigOpen, setManageConfigOpen] = useState(false);

  const handleManageConfig = (data) => {
    setManageConfigOpen(true);
    setSelectedClient(data);
  };
  const handleChangeZone = (event) => {
    setZone(event.target.value);
  };
  const [Rates, setRates] = React.useState("");

  const handleChangeRates = (event) => {
    setRates(event.target.value);
  };
  const tableDataAccounts = [
    {
      id: 1,
      name: "Test Account With Account 1",
      description: "Test Account With Account Description 1",
    },
    {
      id: 2,
      name: "Test Account With Account 2",
      description: "Test Account With Account Description 2",
    },
  ];
  let columnsAccounts = [
    {
      field: "accountNumber",
      headerName: "Account Number",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "lastRecharge",
      headerName: "Last Recharge",
      flex: 1,
      minWidth: 80,
    },

    {
      field: "currentBalance",
      headerName: "Current Balance",
      flex: 1,
      minWidth: 80,
    },

    {
      field: "previousBalance",
      headerName: "Previous Balance",
      flex: 1,
      minWidth: 80,
    },

    {
      field: "lockedBalance",
      headerName: "Locked Balance",
      flex: 1,
      minWidth: 80,
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "balanceLimit",
      headerName: "Balance Limit",
      flex: 1,
      minWidth: 80,
    },
  ];
  const handleSwitchChangeSwitchClient = (data) => {
    setSelectedClient(data);
    setModalOpenSwitchClient(true);
    setCheckedSwitchClient(data?.recordGuid);
  };
  const submitResetPass = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      UserName: usernameLogin,
      Pin: pin,
      NewPassword: newPass,
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${RESET_PASSWORD}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // setGoToConfigPage(true);
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully reseted !", type: "success" });
        setOpenPopupResetPass(false);
        setUsernameLogin("");
        setPin("");
        setNewPass("");
        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitResetPass();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  const submitAddBankingDetails = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      ClientGuid: selectedClient?.recordGuid,
      CountryGuid: country,
      IBAN: iban,
      CodeSwiftBic: codeSwiftBic,
      BankAccountNumber: bankAccNb,
      RoutingNumber: rountingNb,
      BankName: bankName,
      BankStreet: bankStreet,
      BankPhoneNumber: bankPhoneNb,
      BankPostCode: bankPostCode,
      BankCity: bankCity,
      BankSwiftCorrespondent: bankSwiftCorrespondent,
    };

    let dataEdit = {
      BankingInfoGuid: bankingDetailsData?.recordGuid,
      CountryGuid: country,
      IBAN: iban,
      CodeSwiftBic: codeSwiftBic,
      BankAccountNumber: bankAccNb,
      RoutingNumber: rountingNb,
      BankName: bankName,
      BankStreet: bankStreet,
      BankPhoneNumber: bankPhoneNb,
      BankPostCode: bankPostCode,
      BankCity: bankCity,
      BankSwiftCorrespondent: bankSwiftCorrespondent,
    };
    if (bankingDetailsData?.length === 0) {
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${ADD_BANKING_DETAILS}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data?.message || "Successfully Added",
            type: "success",
          });
          setOpenPopupResetPass(false);
          setCountry("");
          setIban("");
          setCodeSwiftBic("");
          setBankAccNb("");
          setRountingNb("");
          setBankName("");
          setBankStreet("");
          setBankPhoneNb("");
          setBankPostCode("");
          setBankCity("");
          setBankSwiftCorrespondent("");
          setBankingDetailsModalOpen(false);

          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((error) => {
          setOpenMessageAlert(true);
          setMessageData({
            message: error?.response?.data?.Message
              ? error?.response?.data?.Message
              : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
            type: "error",
          });
        });
    } else {
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${UPDATE_BANKING_INFO}`,
          dataEdit,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data?.message || "Successfully Updated",
            type: "success",
          });
          setOpenPopupResetPass(false);
          setCountry("");
          setIban("");
          setCodeSwiftBic("");
          setBankAccNb("");
          setRountingNb("");
          setBankName("");
          setBankStreet("");
          setBankPhoneNb("");
          setBankPostCode("");
          setBankCity("");
          setBankSwiftCorrespondent("");
          setBankingDetailsModalOpen(false);

          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((error) => {
          setOpenMessageAlert(true);
          setMessageData({
            message: error?.response?.data?.Message
              ? error?.response?.data?.Message
              : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
            type: "error",
          });
        });
    }
  };

  let columnsClients = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "Created Date",
      headerName: "Created Date",
      flex: 1,
      minWidth: 80,

      renderCell: (params) => {
        let craetedDate = params?.row?.createdDate;
        return new Date(craetedDate * 1000).toDateString();
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 80,
      renderCell: (params) => {
        const rowId = params?.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.row?.isActive}
                  onChange={() => handleSwitchChangeSwitchClient(params?.row)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const tableDataClients = [
    {
      id: 1,
      name: "Test",
      client: "Reseller",
      billing: "Touch",
      country: "Lebanon",
      status: "Active",
      tag: "touch",
      createdDate: "22-11-2020",
    },
  ];
  const tableDataAccountsCategory = [
    {
      id: 1,
      name: "Test category With Account 1",
      description: "Test category With Account Description 1",
    },
    {
      id: 2,
      name: "Test category With Account 2",
      description: "Test category With Account Description 2",
    },
  ];
  let columnsAccountsCategory = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const tableDataAccountsStatus = [
    {
      id: 1,
      name: "Test status With Account 1",
      description: "Test status With Account Description 1",
    },
    {
      id: 2,
      name: "Test status With Account 2",
      description: "Test status With Account Description 2",
    },
  ];
  let columnsAccountsStatus = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const tableDataRates = [
    {
      id: 1,
      clientCategory: "Default",
      countryCode: "961",
      country: "Lebanon",
      operator: "Alfa",
      rate: "0.2 USD",
    },
  ];
  let columnsRates = [
    {
      field: "clientCategory",
      headerName: "Client Without Account",
      flex: 1,
    },
    {
      headerName: "Country ISO",
      field: "countryCode",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];

  const tableDataCosts = [
    {
      id: 1,
      providerCategory: "providerCategory",
      countryCode: "961",
      country: "Lebanon",
      operator: "operator",
      cost: "U.S",
    },
  ];

  const getBankingDetailsById = (recordGuid) => {
    setCountry("");
    setIban("");
    setCodeSwiftBic("");
    setBankAccNb("");
    setRountingNb("");
    setBankName("");
    setBankStreet("");
    setBankPhoneNb("");
    setBankPostCode("");
    setBankCity("");
    setBankSwiftCorrespondent("");
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["billing"]
        }${GET_BANKING_INFO}?ClientGuid=${recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        let bankingInfo =
          res?.data?.data?.bankingInfo?.length !== 0
            ? res?.data?.data?.bankingInfo[0]
            : [];
        setBankingDetailsData(
          res?.data?.data?.bankingInfo?.length !== 0
            ? res?.data?.data?.bankingInfo[0]
            : []
        );
        console.log("asfasfasfasfaafasf", res?.data?.data?.bankingInfo?.length);
        if (res?.data?.data?.bankingInfo?.length !== 0) {
          setCountry(bankingInfo.countryRecordGUID);
          setIban(bankingInfo.iban);
          setCodeSwiftBic(bankingInfo.codeSwiftBic);
          setBankAccNb(bankingInfo.bankAccountNumber);
          setRountingNb(bankingInfo?.routingNumber);
          setBankName(bankingInfo?.bankName);
          setBankStreet(bankingInfo?.bankStreet);
          setBankPhoneNb(bankingInfo?.bankPhoneNumber);
          setBankPostCode(bankingInfo?.bankPostCode);
          setBankCity(bankingInfo?.bankCity);
          setBankSwiftCorrespondent(bankingInfo?.bankSwiftCorrespondent);
        } else {
          setCountry("");
          setIban("");
          setCodeSwiftBic("");
          setBankAccNb("");
          setRountingNb("");
          setBankName("");
          setBankStreet("");
          setBankPhoneNb("");
          setBankPostCode("");
          setBankCity("");
          setBankSwiftCorrespondent("");
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          getBankingDetailsById(recordGuid);
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const handleGetPin = (data) => {
    setIsLoadingSentMail(true);
    // setUsername(data?.username);
    setUsernameLogin(data?.username);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let dataForm = {
      UserName: data?.username,
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]}${GET_PIN}`,
        dataForm,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenPopupResetPass(true);
        setIsLoadingSentMail(false);

        setOpenPopupResetPass(!openPopupResetPass);
        // setGoToConfigPage(true);
        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((error) => {
        setIsLoadingSentMail(false);

        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
            ? error?.response?.data?.Exception
            : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const validateBankAccountNumber = (input) => {
    // Example regex pattern for bank account number (adjust as per your requirements)
    const bankAccountPattern = /^\d{10}$/;
    // Check if the input matches the pattern
    console.log("asdasdsadasd", input);
    console.log("asdasdsadasdaaaaaaa", bankAccountPattern.test(input));

    if (bankAccountPattern.test(input)) {
      setBankAccNb(input);
    }
  };
  let columnsCosts = [
    {
      field: "providerCategory",
      headerName: "Provider Without Account",
      flex: 1,
    },
    {
      field: "countryCode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Cost",
      field: "cost",
      flex: 1,
    },
  ];

  const tableDataWallet = [
    {
      id: 1,
      clientCategory: "clientCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];
  let columnsWallet = [
    {
      field: "clientCategory",
      headerName: "Client Without Account",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];
  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptions(
          res?.data?.data?.countries.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllResellers = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${GET_ALL_RESELLERS}?TypeTag=RESELLER`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setResellersOptions([
          ...res?.data?.data?.clients?.map((x, i) => ({
            label: x.firstName ? x.firstName : "",
            value: x.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllResellers();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const exportClients = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${EXPORT_CLIENTS_CSV}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Clients.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err === "error: 401") {
          exportClients();
        }
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };

  const tableDataAccountHistory = [
    {
      id: 1,
      clientCategory: "clientCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];
  let columnsAccountHistory = [
    {
      field: "clientCategory",
      headerName: "Client Without Account",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];

  const tabs = [
    {
      label: "Clients",
    },
    // {
    //   label: "Accounts", disabled: true
    // },
    // {
    //   label: "History", disabled: true
    // },
    // {
    //   label: "Ledger", disabled: true
    // },
  ];
  const tabsHorizantal = [
    {
      label: "With Account",
    },
    {
      label: "Without Account",
    },
  ];

  // Delete Modal Accounts functions
  const [ConfirmOpenAccounts, setConfirmOpenAccounts] = useState(false);
  const [openViewClient, setOpenViewClient] = useState(false);

  const [modalContentClients, setModalContentClients] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenClients, setConfirmOpenClients] = useState(false);

  const [modalContentAccounts, setModalContentAccounts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalAccounts = (title, text, onButtonClick) => {
    setModalContentAccounts({ title, text, onButtonClick });
    setConfirmOpenAccounts(true);
  };
  const OpenConfirmModalClients = (data, title, text, onButtonClick) => {
    setModalContentClients({ title, text, onButtonClick });
    setConfirmOpenClients(true);
    setSelectedClient(data);
  };
  const handleConfirmModalAccounts = () => {
    setConfirmOpenAccounts(false);
  };
  const handleModalCloseSwitchClient = () => {
    setModalOpenSwitchClient(false);
  };
  const handleModalConfirmSwitchClient = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      ClientGuid: isCheckedSwitchClient,
      IsEnabled: !selectedClient?.isActive,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${CHANGE_STATUS_CLIENT}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableClient(true);
        setModalOpenSwitchClient(false);
        // setRefreshTableZone(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchClient(isCheckedSwitchClient);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleConfirmModalClients = () => {
    setConfirmOpenClients(false);
  };

  //
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    getAllResellers();
    getAllCountries();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResetAll = () => {
    setName("");
    setUsername("");
    setEmail("");
    setMobileNumber("");
    setCreatedDate("");
    setProvider("");
    setRefreshTableClient(true);
  };

  // Delete Modal Rates functions
  const [ConfirmOpenRates, setConfirmOpenRates] = useState(false);
  const [editClientModal, setOpenEditClientModal] = useState(false);
  const [bankingDetailsModalOpen, setBankingDetailsModalOpen] = useState(false);

  const [openAdvancedSearchGroup, setOpenAdvancedSearchGroup] = useState(true);

  const [modalContentRates, setModalContentRates] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalRates = (title, text, onButtonClick) => {
    setModalContentRates({ title, text, onButtonClick });
    setConfirmOpenRates(true);
  };

  const handleConfirmModalRates = () => {
    setConfirmOpenRates(false);
  };

  //
  // Delete Modal Costs functions
  const [ConfirmOpenCosts, setConfirmOpenCosts] = useState(false);
  const [modalContentCosts, setModalContentCosts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalCosts = (title, text, onButtonClick) => {
    setModalContentCosts({ title, text, onButtonClick });
    setConfirmOpenCosts(true);
  };

  const handleConfirmModalCosts = () => {
    setConfirmOpenCosts(false);
  };

  const validateIBAN = (input) => {
    // Regex pattern for IBAN format (example pattern)
    const ibanPattern = /^([A-Z]{2}\d{2})( ?\d{4}){5}$/;
    // Check if the input matches the IBAN pattern
    return ibanPattern.test(input);
  };
  // Delete Modal Wallet functions
  const [ConfirmOpenWallet, setConfirmOpenWallet] = useState(false);
  const [modalContentWallet, setModalContentWallet] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalWallet = (title, text, onButtonClick) => {
    setModalContentWallet({ title, text, onButtonClick });
    setConfirmOpenWallet(true);
  };

  const handleConfirmModalWallet = () => {
    setConfirmOpenWallet(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  //
  // Delete Modal AccountHistory functions
  const [ConfirmOpenAccountHistory, setConfirmOpenAccountHistory] =
    useState(false);
  const [modalContentAccountHistory, setModalContentAccountHistory] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalAccountHistory = (title, text, onButtonClick) => {
    setModalContentAccountHistory({ title, text, onButtonClick });
    setConfirmOpenAccountHistory(true);
  };

  const handleConfirmModalAccountHistory = () => {
    setConfirmOpenAccountHistory(false);
  };
  //

  // Manage Accounts
  const [MangeAccountsAddopen, setMangeAccountsAddopen] = useState(false);
  const [MangeAccountsEditopen, setMangeAccountsEditopen] = useState(false);
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const [MangeClientsEditopen, setMangeClientsEditopen] = useState(false);

  const handleAddMangeAccountsopen = () => {
    setMangeAccountsAddopen(true);
  };
  const handleAddClientOpen = () => {
    setOpenAddClientModal(true);
  };
  const handleEditClientOpen = (data) => {
    setSelectedClient(data);
    setOpenEditClientModal(true);
  };
  const handleAddBankingDetailsOpen = (data) => {
    getBankingDetailsById(data?.recordGuid);
    setSelectedClient(data);
    setBankingDetailsModalOpen(true);
  };
  const handleAddMangeAccountsclose = () => {
    setMangeAccountsAddopen(false);
  };
  const handleEditMangeAccountsopen = () => {
    setMangeAccountsEditopen(true);
  };
  const handleEditMangeClientsOpen = () => {
    setMangeClientsEditopen(true);
  };

  const handleEditMangeAccountsclose = () => {
    setMangeAccountsEditopen(false);
  };

  // Manage Rates
  const [MangeRatesAddopen, setMangeRatesAddopen] = useState(false);
  const [MangeRatesEditopen, setMangeRatesEditopen] = useState(false);

  const handleAddMangeRatesopen = () => {
    setMangeRatesAddopen(true);
  };

  const handleAddMangeRatesclose = () => {
    setMangeRatesAddopen(false);
  };
  const handleEditMangeRatesopen = () => {
    setMangeRatesEditopen(true);
  };

  const handleEditMangeRatesclose = () => {
    setMangeRatesEditopen(false);
  };

  // Manage Costs
  const [MangeCostsAddopen, setMangeCostsAddopen] = useState(false);
  const [MangeCostsEditopen, setMangeCostsEditopen] = useState(false);

  const handleAddMangeCostsopen = () => {
    setMangeCostsAddopen(true);
  };

  const handleAddMangeCostsclose = () => {
    setMangeCostsAddopen(false);
  };
  const handleEditMangeCostsopen = () => {
    setMangeCostsEditopen(true);
  };

  const handleEditMangeCostsclose = () => {
    setMangeCostsEditopen(false);
  };

  // Manage Wallet
  const [MangeWalletAddopen, setMangeWalletAddopen] = useState(false);
  const [MangeWalletEditopen, setMangeWalletEditopen] = useState(false);

  const handleAddMangeWalletopen = () => {
    setMangeWalletAddopen(true);
  };

  const handleAddMangeWalletclose = () => {
    setMangeWalletAddopen(false);
  };
  const handleEditMangeWalletopen = () => {
    setMangeWalletEditopen(true);
  };

  const handleEditMangeWalletclose = () => {
    setMangeWalletEditopen(false);
  };

  // Manage AccountHistory
  const [MangeAccountHistoryAddopen, setMangeAccountHistoryAddopen] =
    useState(false);
  const [MangeAccountHistoryEditopen, setMangeAccountHistoryEditopen] =
    useState(false);

  const handleAddMangeAccountHistoryopen = () => {
    setMangeAccountHistoryAddopen(true);
  };

  const handleAddMangeAccountHistoryclose = () => {
    setMangeAccountHistoryAddopen(false);
  };
  const handleEditMangeAccountHistoryopen = () => {
    setMangeAccountHistoryEditopen(true);
  };

  const handleEditMangeAccountHistoryclose = () => {
    setMangeAccountHistoryEditopen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleOpenViewClient = () => {
    setOpenViewClient(!openViewClient);
    // setOpenEditClientModal(!openEditClientModal);
  };
  const handleOpenAddlient = () => {
    setOpenAddClientModal(!openAddClientModal);
  };
  const handleOpenAdvancedSearchGroup = () => {
    setOpenAdvancedSearchGroup(!openAdvancedSearchGroup);
  };
  useEffect(() => {
    if (
      reseller !== "" &&
      activeBtn === "Clients" &&
      (refreshTableClient || paginationModelClients)
    ) {
      if (!paginationModelClients) {
        setPaginationModelClients({
          page: 0,
          pageSize: 10,
        });
      }
      tableQueryExecuterClient();
      // tableQueryExecuterMNC();
    }
  }, [
    paginationModelClients,
    refreshTableClient,
    searchClient,
    reseller,

    // countryFilter,
  ]);
  useEffect(() => {
    if (activeBtn === "Clients") {
      setPaginationModelClients({
        page: 0,
        pageSize: 10,
      });
      // setClientSearchName("");
    }
  }, [activeBtn]);

  function dateToTimestamp(dateString) {
    // Create a Date object from the input date string
    const dateObj = new Date(dateString);

    // Get the Unix timestamp (in milliseconds) using the getTime() method
    const unixTimestamp = dateObj.getTime();

    // Convert milliseconds to seconds by dividing by 1000
    return Math.floor(unixTimestamp / 1000);
  }

  const tableQueryExecuterClient = () => {
    setIsLoading(true);
    setRefreshTableClient(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    console.log("createdDatecreatedDate", createdDate);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${GET_ALL_CLIENTS}?PageIndex=${
          resetPagination ? 1 : paginationModelClients?.page + 1
        }&PageSize=${
          paginationModelClients?.pageSize
        }&ParentGuid=${localStorage.getItem("clientId")}&search=${
          searchClient ? searchClient : ""
        }&CreatedDate=${
          createdDate ? dateToTimestamp(createdDate) : ""
        }&username=${userName}&ParentId=${reseller ? reseller : ""}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataClient({
          data: res?.data?.data?.clients ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        setFilteredData({
          createdDate: createdDate,
          userName: userName,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterClient();
        }
        setTableDataClient({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDeleteClient = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${DELETE_CLIENT}?ClientGuid=${selectedClient?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message || "Successfully Deleted !",
          type: "success",
        });
        setRefreshTableClient(true);
        setConfirmOpenClients(false);

        // setRefreshTableMNC(false);
        setPaginationModelClients({
          page: 0,
          pageSize: paginationModelClients?.pageSize
            ? paginationModelClients?.pageSize
            : 10,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleDeleteClient(isCheckedSwitchClient);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setPaginationModelClients({
      page: 0,
      pageSize: 10,
    });
    if (reseller !== "") {
      tableQueryExecuterClient();
    }
  }, [openAddClientModal, editClientModal]);
  return (
    <>
      {!openViewClient &&
        !editClientModal &&
        !openAddClientModal &&
        !manageConfigOpen && (
          <div className="billing">
            <Grid container>
              <Grid item xs={12} md={6} className="centerresponsive">
                <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                  <Typography className="BreadcrumbsPage">
                    Management
                  </Typography>
                  <Typography className="breadcrumbactiveBtn">
                    {activeBtn}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12} md={6} className="end">
                {activeBtn === "Accounts" && (
                  <>
                    {ActiveBtnHorizantal === "With Account" && (
                      <Button
                        // variant="contained"
                        className="contained"
                        onClick={handleAddMangeAccountsopen}
                      >
                        <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                        <Typography sx={{ fontSize: "14px" }}>
                          Add Account
                        </Typography>
                      </Button>
                    )}
                    {ActiveBtnHorizantal === "Without Account" && (
                      <Button
                        // variant="contained"
                        className="contained"
                        onClick={handleAddMangeAccountsopen}
                      >
                        <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                        <Typography sx={{ fontSize: "14px" }}>
                          Add Account
                        </Typography>
                      </Button>
                    )}
                    {ActiveBtnHorizantal === "Status" && (
                      <Button
                        // variant="contained"
                        className="contained"
                        onClick={handleAddMangeAccountsopen}
                      >
                        <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                        <Typography sx={{ fontSize: "14px" }}>
                          Add Status
                        </Typography>
                      </Button>
                    )}
                  </>
                )}
                {activeBtn === "Clients" && (
                  <>
                    <Button
                      // variant="contained"
                      className="contained"
                      onClick={handleAddClientOpen}
                    >
                      <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                      <Typography sx={{ fontSize: "14px" }}>
                        Add Client
                      </Typography>
                    </Button>
                  </>
                )}
                {activeBtn === "Ledger" && (
                  <>
                    {ActiveBtnHorizantal === "With Account" && (
                      <Button
                        // variant="contained"
                        className="contained"
                        onClick={handleAddMangeAccountsopen}
                      >
                        <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                        <Typography sx={{ fontSize: "14px" }}>
                          Add Account Ledger
                        </Typography>
                      </Button>
                    )}
                    {ActiveBtnHorizantal === "Without Account" && (
                      <Button
                        // variant="contained"
                        className="contained"
                        onClick={handleAddMangeAccountsopen}
                      >
                        <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                        <Typography sx={{ fontSize: "14px" }}>
                          Add Account Ledger
                        </Typography>
                      </Button>
                    )}
                    {ActiveBtnHorizantal === "Status" && (
                      <Button
                        // variant="contained"
                        className="contained"
                        onClick={handleAddMangeAccountsopen}
                      >
                        <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                        <Typography sx={{ fontSize: "14px" }}>
                          Add Status
                        </Typography>
                      </Button>
                    )}
                  </>
                )}
                {activeBtn === "Costs" && (
                  <Button
                    variant="contained"
                    className="contained"
                    onClick={handleAddMangeCostsopen}
                  >
                    <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                    <Typography sx={{ fontSize: "14px" }}>Add Cost</Typography>
                  </Button>
                )}
                {activeBtn === "Wallet" && (
                  <Button
                    variant="contained"
                    className="contained"
                    onClick={handleAddMangeWalletopen}
                  >
                    <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                    <Typography sx={{ fontSize: "14px" }}>Add rate</Typography>
                  </Button>
                )}
                {/* {activeBtn === "History" && (
                <Button
                  variant="contained"
                  className="contained"
                  onClick={handleAddMangeAccountHistoryopen}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>
                    Add Account History
                  </Typography>
                </Button>
              )} */}
              </Grid>
            </Grid>
            {/* {windowWidth < 1000 && (
              <Grid
                style={{
                  marginBottom: "-38px",
                  width: windowWidth < 500 ? "280px" : "380px",
                }}
                container
                // marginLeft={2}
                marginTop={5}
              >
                <HorizontalTabsSubtabs
                  activeBtn={activeBtn}
                  tabs={tabs}
                  setActiveBtn={setActiveBtn}
                />
              </Grid>
            )} */}
            {console.log("windowWidthwindowWidth", windowWidth)}
            {windowWidth > 1000 ? (
              <Grid container className="Top-spacing div-table">
                {/* <Grid
                  item
                  xs={3.5}
                  md={1.5}
                  lg={windowWidth > 1540 ? 1.5 : 2}
                  className="vertical-grid"
                  sx={{ paddingLeft: "2px" }}
                >
                  <VerticalTabs
                    activeBtn={activeBtn}
                    tabs={tabs}
                    setActiveBtn={setActiveBtn}
                  />
                </Grid> */}
                <Grid item xs={12} md={12} lg={12}>
                  {activeBtn === "Accounts" && (
                    <>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid xs={12}>
                          {ActiveBtnHorizantal === "With Account" && (
                            <Card variant="CardTable">
                              <Table
                                rowCountState={tableDataAccounts?.totalCount}
                                data={tableDataAccounts}
                                // isLoading={isLoading}
                                columns={columnsAccounts}
                                pageIndex={
                                  tableDataAccounts?.pageIndex
                                    ? tableDataAccounts?.pageIndex
                                    : 0
                                }
                                setPaginationModel={setPaginationModel}
                                paginationModel={paginationModel}
                                // enableDelete={true}
                                // enableEdit={true}
                                // deleteFunction={OpenConfirmModalAccounts}
                                // editFunction={handleEditMangeAccountsopen}
                                enableSearch={true}
                                enableExportBtn={true}
                                // disableActions={true}
                                // enableMore={true}
                                // moreFunction={handleOpenViewClient}
                                advancedSearchFunction={
                                  handleOpenAdvancedSearchGroup
                                }
                                advancedSearch={openAdvancedSearchGroup}
                                titleAdvancedSearch={"Advanced Search"}
                                contentAdvancedSearch={
                                  <Grid spacing={3} container>
                                    <Grid lg={3} xs={12} item>
                                      <SelectForm
                                        placeholder={"Client Type"}
                                        value={clientType}
                                        setValue={setClientType}
                                        options={clientTypeOptions}
                                      />
                                    </Grid>
                                    <Grid lg={3} xs={12} item>
                                      <SelectForm
                                        placeholder={"Provider"}
                                        value={provider}
                                        setValue={setProvider}
                                        options={providerOptions}
                                      />
                                    </Grid>
                                    <Grid lg={3} xs={12} item>
                                      <SelectForm
                                        placeholder={"Account Type"}
                                        value={country}
                                        setValue={setCountry}
                                        options={CountriesOptions}
                                      />{" "}
                                    </Grid>
                                    <Grid lg={3} xs={12} item>
                                      <TextFieldForm
                                        value={date}
                                        setValue={setDate}
                                        placeholder={"Date"}
                                      />
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </Card>
                          )}

                          {ActiveBtnHorizantal === "Without Account" && (
                            <Paper variant="CardTable">
                              <Table
                                rowCountState={
                                  tableDataAccountsCategory?.totalCount
                                }
                                data={tableDataAccountsCategory}
                                // isLoading={isLoading}
                                columns={columnsAccountsCategory}
                                pageIndex={
                                  tableDataAccountsCategory?.pageIndex
                                    ? tableDataAccountsCategory?.pageIndex
                                    : 0
                                }
                                setPaginationModel={setPaginationModel}
                                paginationModel={paginationModel}
                                // enableDelete={true}
                                // enableEdit={true}
                                // deleteFunction={OpenConfirmModalAccounts}
                                // editFunction={handleEditMangeAccountsopen}
                                enableSearch={true}
                                enableExportBtn={true}
                                enableMore={true}
                                // disableActions={true}
                                moreFunction={handleOpenViewClient}
                              />
                            </Paper>
                          )}

                          {ActiveBtnHorizantal === "Status" && (
                            <Paper variant="CardTable">
                              <Table
                                rowCountState={
                                  tableDataAccountsStatus?.totalCount
                                }
                                data={tableDataAccountsStatus}
                                // isLoading={isLoading}
                                columns={columnsAccountsStatus}
                                pageIndex={
                                  tableDataAccountsStatus?.pageIndex
                                    ? tableDataAccountsStatus?.pageIndex
                                    : 0
                                }
                                setPaginationModel={setPaginationModel}
                                paginationModel={paginationModel}
                                // enableDelete={true}
                                // enableEdit={true}
                                // deleteFunction={OpenConfirmModalAccounts}
                                // editFunction={handleEditMangeAccountsopen}
                                enableSearch={true}
                                enableExportBtn={true}
                                enableMore={true}
                                moreFunction={handleOpenViewClient}
                                // disableActions={true}
                              />
                            </Paper>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {activeBtn === "Clients" && (
                    <>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid xs={12}>
                          <Card variant="CardTable">
                            <Table
                              enableAddBankingDetails={true}
                              AddBankingDetailsFunction={
                                handleAddBankingDetailsOpen
                              }
                              enableResetPassword={true}
                              resetPasswordFunction={handleGetPin}
                              exportFunction={exportClients}
                              enableShow={true}
                              showFunction={handleModalShowAccounts}
                              largeActions={true}
                              enableManageConfig={true}
                              manageConfigFunction={handleManageConfig}
                              rowCountState={tableDataClient?.totalCount}
                              data={tableDataClient?.data}
                              isLoading={isLoading}
                              columns={columnsClients}
                              handleResetAll={handleResetAll}
                              handleSearchBtn={() =>
                                setRefreshTableClient(true)
                              }
                              pageIndex={
                                tableDataClient?.pageIndex
                                  ? tableDataClient?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModelClients}
                              paginationModel={paginationModelClients}
                              enableDelete={true}
                              enableEdit={true}
                              deleteFunction={OpenConfirmModalClients}
                              editFunction={handleEditClientOpen}
                              enableSearch={true}
                              setSearch={setSearchClient}
                              enableExportBtn={true}
                              enableFilter={true}
                              placeholderFilter={"Reseller"}
                              optionsFilter={resellersOptions}
                              valueFilter={reseller}
                              setValueFilter={setReseller}
                              selected={true}
                              // enableMore={true}
                              // moreFunction={handleOpenViewClient}
                              // advancedSearchFunction={
                              //   handleOpenAdvancedSearchGroup
                              // }
                              // advancedSearch={openAdvancedSearchGroup}
                              // // disableActions={true}
                              // titleAdvancedSearch={"Advanced Search"}
                              // contentAdvancedSearch={
                              //   <Grid spacing={3} container>
                              //     <Grid lg={3} xs={12} item>
                              //       <TextFieldForm
                              //         key={"createdDate"}
                              //         id={"createdDate"}
                              //         name={"createdDate"}
                              //         placeholder={"Created Date"}
                              //         variant="outlined"
                              //         value={createdDate}
                              //         setValue={setCreatedDate}
                              //         // onChange={handleChange}
                              //         type={"date"}
                              //         InputLabelProps={{ shrink: true }}
                              //       />
                              //     </Grid>
                              //     <Grid lg={3} xs={12} item>
                              //       <TextFieldForm
                              //         key={"username"}
                              //         id={"username"}
                              //         name={"username"}
                              //         placeholder={"Username"}
                              //         variant="outlined"
                              //         value={userName}
                              //         setValue={setUsername}
                              //       />
                              //     </Grid>
                              //   </Grid>
                              // }
                            />
                          </Card>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {activeBtn === "Ledger" && (
                    <>
                      <Grid container sx={{ marginTop: "20px" }}>
                        <Grid item xs={12}>
                          <Paper variant="CardTable">
                            <Table
                              rowCountState={tableDataRates?.totalCount}
                              data={tableDataRates}
                              // isLoading={isLoading}
                              columns={columnsRates}
                              pageIndex={
                                tableDataRates?.pageIndex
                                  ? tableDataRates?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModel}
                              paginationModel={paginationModel}
                              enableDelete={true}
                              enableEdit={true}
                              deleteFunction={OpenConfirmModalRates}
                              editFunction={handleEditMangeRatesopen}
                              enableSearch={true}
                              enableImportBtn={true}
                              enableExportBtn={true}
                              // disableActions={true}
                              // enableMore={true}
                              // moreFunction={handleOpenViewClient}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {activeBtn === "Costs" && (
                    <Paper
                      variant="CardTable"
                      sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                    >
                      <Table
                        rowCountState={tableDataCosts?.totalCount}
                        data={tableDataCosts}
                        // isLoading={isLoading}
                        columns={columnsCosts}
                        pageIndex={
                          tableDataCosts?.pageIndex
                            ? tableDataCosts?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        enableExportBtn={true}
                        deleteFunction={OpenConfirmModalCosts}
                        editFunction={handleEditMangeCostsopen}
                        enableSearch={true}
                        enableFilter={true}
                        enableImportBtn={true}
                        valueFilter={Zone}
                        setValueFilter={setZone}
                        // disableActions={true}
                        optionsFilter={["All"]}
                        placeholderFilter="Provider Without Account"
                        // enableMore={true}
                        // moreFunction={handleOpenViewClient}
                      />
                    </Paper>
                  )}
                  {activeBtn === "Wallet" && (
                    <Paper
                      variant="CardTable"
                      sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                    >
                      <Table
                        rowCountState={tableDataWallet?.totalCount}
                        data={tableDataWallet}
                        // isLoading={isLoading}
                        columns={columnsWallet}
                        pageIndex={
                          tableDataWallet?.pageIndex
                            ? tableDataWallet?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        // disableActions={true}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        enableExportBtn={true}
                        enableImportBtn={true}
                        deleteFunction={OpenConfirmModalWallet}
                        editFunction={handleEditMangeWalletopen}
                        enableSearch={true}
                        valueFilter={Rates}
                        setValueFilter={setRates}
                        optionsFilter={["All", "Default", "vip"]}
                        placeholderFilter="Client Without Account"
                        // enableMore={true}
                        // moreFunction={handleOpenViewClient}
                      />
                    </Paper>
                  )}
                  {activeBtn === "History" && (
                    <>
                      {/* <Grid container>
                    <Grid xs={12} item>
                      <HorizantalTabs
                        tabs={tabsHorizantal}
                        setActiveBtn={setActiveBtnHorizantal}
                      />
                    </Grid>
                  </Grid> */}
                      <Paper
                        variant="CardTable"
                        sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                      >
                        <Table
                          rowCountState={tableDataAccountHistory?.totalCount}
                          data={tableDataAccountHistory}
                          columns={columnsAccountHistory}
                          pageIndex={
                            tableDataAccountHistory?.pageIndex
                              ? tableDataAccountHistory?.pageIndex
                              : 0
                          }
                          setPaginationModel={setPaginationModel}
                          disableActions={true}
                          paginationModel={paginationModel}
                          enableDelete={true}
                          enableEdit={true}
                          enableExportBtn={true}
                          deleteFunction={OpenConfirmModalAccountHistory}
                          editFunction={handleEditMangeAccountHistoryopen}
                          enableSearch={true}
                          valueFilter={Rates}
                          enableImportBtn={true}
                          setValueFilter={setRates}
                          optionsFilter={["All", "Default", "vip"]}
                          placeholderFilter="Client Without Account"
                          // enableMore={true}
                          // moreFunction={handleOpenViewClient}
                        />
                      </Paper>
                    </>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid xs={12} md={10} lg={10}>
                {activeBtn === "Accounts" && (
                  <>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid xs={12}>
                        {ActiveBtnHorizantal === "With Account" && (
                          <Card variant="CardTable">
                            <Table
                              rowCountState={tableDataAccounts?.totalCount}
                              data={tableDataAccounts}
                              // isLoading={isLoading}
                              columns={columnsAccounts}
                              pageIndex={
                                tableDataAccounts?.pageIndex
                                  ? tableDataAccounts?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModel}
                              paginationModel={paginationModel}
                              // enableDelete={true}
                              // enableEdit={true}
                              // deleteFunction={OpenConfirmModalAccounts}
                              // editFunction={handleEditMangeAccountsopen}
                              enableSearch={true}
                              enableExportBtn={true}
                              // disableActions={true}
                              // enableMore={true}
                              // moreFunction={handleOpenViewClient}
                              advancedSearchFunction={
                                handleOpenAdvancedSearchGroup
                              }
                              advancedSearch={openAdvancedSearchGroup}
                              titleAdvancedSearch={"Advanced Search"}
                              contentAdvancedSearch={
                                <Grid spacing={3} container>
                                  <Grid lg={3} xs={12} item>
                                    <SelectForm
                                      placeholder={"Client Type"}
                                      value={clientType}
                                      setValue={setClientType}
                                      options={clientTypeOptions}
                                    />
                                  </Grid>
                                  <Grid lg={3} xs={12} item>
                                    <SelectForm
                                      placeholder={"Provider"}
                                      value={provider}
                                      setValue={setProvider}
                                      options={providerOptions}
                                    />
                                  </Grid>
                                  <Grid lg={3} xs={12} item>
                                    <SelectForm
                                      placeholder={"Account Type"}
                                      value={country}
                                      setValue={setCountry}
                                      options={CountriesOptions}
                                    />{" "}
                                  </Grid>
                                  <Grid lg={3} xs={12} item>
                                    <TextFieldForm
                                      value={date}
                                      setValue={setDate}
                                      placeholder={"Date"}
                                    />
                                  </Grid>
                                </Grid>
                              }
                            />
                          </Card>
                        )}

                        {ActiveBtnHorizantal === "Without Account" && (
                          <Paper variant="CardTable">
                            <Table
                              rowCountState={
                                tableDataAccountsCategory?.totalCount
                              }
                              data={tableDataAccountsCategory}
                              // isLoading={isLoading}
                              columns={columnsAccountsCategory}
                              pageIndex={
                                tableDataAccountsCategory?.pageIndex
                                  ? tableDataAccountsCategory?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModel}
                              paginationModel={paginationModel}
                              // enableDelete={true}
                              // enableEdit={true}
                              // deleteFunction={OpenConfirmModalAccounts}
                              // editFunction={handleEditMangeAccountsopen}
                              enableSearch={true}
                              enableExportBtn={true}
                              enableMore={true}
                              // disableActions={true}
                              moreFunction={handleOpenViewClient}
                            />
                          </Paper>
                        )}

                        {ActiveBtnHorizantal === "Status" && (
                          <Paper variant="CardTable">
                            <Table
                              rowCountState={
                                tableDataAccountsStatus?.totalCount
                              }
                              data={tableDataAccountsStatus}
                              // isLoading={isLoading}
                              columns={columnsAccountsStatus}
                              pageIndex={
                                tableDataAccountsStatus?.pageIndex
                                  ? tableDataAccountsStatus?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModel}
                              paginationModel={paginationModel}
                              // enableDelete={true}
                              // enableEdit={true}
                              // deleteFunction={OpenConfirmModalAccounts}
                              // editFunction={handleEditMangeAccountsopen}
                              enableSearch={true}
                              enableExportBtn={true}
                              enableMore={true}
                              moreFunction={handleOpenViewClient}
                              // disableActions={true}
                            />
                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
                {activeBtn === "Clients" && (
                  <>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid xs={12}>
                        <Card variant="CardTable">
                          <Table
                            exportFunction={exportClients}
                            enableShow={true}
                            showFunction={handleModalShowAccounts}
                            largeActions={true}
                            rowCountState={tableDataClient?.totalCount}
                            data={tableDataClient?.data}
                            isLoading={isLoading}
                            columns={columnsClients}
                            handleSearchBtn={() => setRefreshTableClient(true)}
                            pageIndex={
                              tableDataClient?.pageIndex
                                ? tableDataClient?.pageIndex
                                : 0
                            }
                            enableSearch={true}
                            setSearch={setSearchClient}
                            setPaginationModel={setPaginationModelClients}
                            paginationModel={paginationModelClients}
                            enableDelete={true}
                            enableEdit={true}
                            deleteFunction={OpenConfirmModalClients}
                            editFunction={handleEditClientOpen}
                            // enableSearch={true}
                            enableExportBtn={true}
                            // enableMore={true}
                            // moreFunction={handleOpenViewClient}
                            // advancedSearchFunction={
                            //   handleOpenAdvancedSearchGroup
                            // }
                            // advancedSearch={openAdvancedSearchGroup}
                            // // disableActions={true}
                            // titleAdvancedSearch={"Advanced Search"}
                            // contentAdvancedSearch={
                            //   <Grid spacing={3} container>
                            //     <Grid lg={3} xs={12} item>
                            //       <SelectForm
                            //         placeholder={"Client Type"}
                            //         value={clientType}
                            //         setValue={setClientType}
                            //         options={clientTypeOptions}
                            //       />
                            //     </Grid>
                            //     <Grid lg={3} xs={12} item>
                            //       <SelectForm
                            //         placeholder={"Billing Type"}
                            //         value={provider}
                            //         setValue={setProvider}
                            //         options={providerOptions}
                            //       />
                            //     </Grid>
                            //     <Grid lg={3} xs={12} item>
                            //       <SelectForm
                            //         placeholder={"Country"}
                            //         value={accountType}
                            //         setValue={setAccountType}
                            //         options={CountriesOptions}
                            //       />{" "}
                            //     </Grid>
                            //     <Grid lg={3} xs={12} item>
                            //       <SelectForm
                            //         placeholder={"Status"}
                            //         value={provider}
                            //         setValue={setProvider}
                            //         options={statusOptions}
                            //       />
                            //     </Grid>
                            //     <Grid lg={3} xs={12} item>
                            //       <TextFieldForm
                            //         key={"createdDate"}
                            //         id={"createdDate"}
                            //         name={"createdDate"}
                            //         placeholder={"Created Date"}
                            //         variant="outlined"
                            //         value={createdDate}
                            //         setValue={setCreatedDate}
                            //         // onChange={handleChange}
                            //         type={"date"}
                            //         InputLabelProps={{ shrink: true }}
                            //       />
                            //     </Grid>
                            //   </Grid>
                            // }
                          />
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                )}
                {activeBtn === "Ledger" && (
                  <>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid item xs={12}>
                        <Paper variant="CardTable">
                          <Table
                            rowCountState={tableDataRates?.totalCount}
                            data={tableDataRates}
                            // isLoading={isLoading}
                            columns={columnsRates}
                            pageIndex={
                              tableDataRates?.pageIndex
                                ? tableDataRates?.pageIndex
                                : 0
                            }
                            setPaginationModel={setPaginationModel}
                            paginationModel={paginationModel}
                            enableDelete={true}
                            enableEdit={true}
                            deleteFunction={OpenConfirmModalRates}
                            editFunction={handleEditMangeRatesopen}
                            enableSearch={true}
                            enableImportBtn={true}
                            enableExportBtn={true}
                            // disableActions={true}
                            // enableMore={true}
                            // moreFunction={handleOpenViewClient}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                )}

                {activeBtn === "Costs" && (
                  <Paper
                    variant="CardTable"
                    sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                  >
                    <Table
                      rowCountState={tableDataCosts?.totalCount}
                      data={tableDataCosts}
                      // isLoading={isLoading}
                      columns={columnsCosts}
                      pageIndex={
                        tableDataCosts?.pageIndex
                          ? tableDataCosts?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModel}
                      paginationModel={paginationModel}
                      enableDelete={true}
                      enableEdit={true}
                      enableExportBtn={true}
                      deleteFunction={OpenConfirmModalCosts}
                      editFunction={handleEditMangeCostsopen}
                      enableSearch={true}
                      enableFilter={true}
                      enableImportBtn={true}
                      valueFilter={Zone}
                      setValueFilter={setZone}
                      // disableActions={true}
                      optionsFilter={["All"]}
                      placeholderFilter="Provider Without Account"
                      // enableMore={true}
                      // moreFunction={handleOpenViewClient}
                    />
                  </Paper>
                )}
                {activeBtn === "Wallet" && (
                  <Paper
                    variant="CardTable"
                    sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                  >
                    <Table
                      rowCountState={tableDataWallet?.totalCount}
                      data={tableDataWallet}
                      // isLoading={isLoading}
                      columns={columnsWallet}
                      pageIndex={
                        tableDataWallet?.pageIndex
                          ? tableDataWallet?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModel}
                      // disableActions={true}
                      paginationModel={paginationModel}
                      enableDelete={true}
                      enableEdit={true}
                      enableExportBtn={true}
                      enableImportBtn={true}
                      deleteFunction={OpenConfirmModalWallet}
                      editFunction={handleEditMangeWalletopen}
                      enableSearch={true}
                      valueFilter={Rates}
                      setValueFilter={setRates}
                      optionsFilter={["All", "Default", "vip"]}
                      placeholderFilter="Client Without Account"
                      // enableMore={true}
                      // moreFunction={handleOpenViewClient}
                    />
                  </Paper>
                )}
                {activeBtn === "History" && (
                  <>
                    {/* <Grid container>
                    <Grid xs={12} item>
                      <HorizantalTabs
                        tabs={tabsHorizantal}
                        setActiveBtn={setActiveBtnHorizantal}
                      />
                    </Grid>
                  </Grid> */}
                    <Paper
                      variant="CardTable"
                      sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                    >
                      <Table
                        rowCountState={tableDataAccountHistory?.totalCount}
                        data={tableDataAccountHistory}
                        columns={columnsAccountHistory}
                        pageIndex={
                          tableDataAccountHistory?.pageIndex
                            ? tableDataAccountHistory?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        disableActions={true}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        enableExportBtn={true}
                        deleteFunction={OpenConfirmModalAccountHistory}
                        editFunction={handleEditMangeAccountHistoryopen}
                        enableSearch={true}
                        valueFilter={Rates}
                        enableImportBtn={true}
                        setValueFilter={setRates}
                        optionsFilter={["All", "Default", "vip"]}
                        placeholderFilter="Client Without Account"
                        // enableMore={true}
                        // moreFunction={handleOpenViewClient}
                      />
                    </Paper>
                  </>
                )}
              </Grid>
            )}

            {/* Delete Accounts Modal */}
            {handleConfirmModalAccounts && (
              <ConfirmationModal
                open={ConfirmOpenAccounts}
                onClose={handleConfirmModalAccounts}
                title="Delete"
                text="Are you sure you want to delete this cost?"
                onButtonClick={handleConfirmModalAccounts}
              />
            )}
            {handleConfirmModalClients && (
              <ConfirmationModal
                open={ConfirmOpenClients}
                onClose={handleConfirmModalClients}
                title="Delete"
                text="Are you sure you want to delete this record?"
                onButtonClick={handleDeleteClient}
              />
            )}
            {/* Delete Rates Modal */}
            {handleConfirmModalRates && (
              <ConfirmationModal
                open={ConfirmOpenRates}
                onClose={handleConfirmModalRates}
                title="Delete"
                text="Are you sure you want to delete this rate?"
                onButtonClick={handleConfirmModalRates}
              />
            )}
            {/* Delete Costs Modal */}
            {handleConfirmModalCosts && (
              <ConfirmationModal
                open={ConfirmOpenCosts}
                onClose={handleConfirmModalCosts}
                title="Delete"
                text="Are you sure you want to delete this cost ?"
                onButtonClick={handleConfirmModalCosts}
              />
            )}
            {/* Delete Wallet Modal */}
            {handleConfirmModalWallet && (
              <ConfirmationModal
                open={ConfirmOpenWallet}
                onClose={handleConfirmModalWallet}
                title="Delete"
                text="Are you sure you want to delete this Wallet ?"
                onButtonClick={handleConfirmModalWallet}
              />
            )}
            {/* Delete AccountHistory Modal */}
            {handleConfirmModalAccountHistory && (
              <ConfirmationModal
                open={ConfirmOpenAccountHistory}
                onClose={handleConfirmModalAccountHistory}
                title="Delete"
                text="Are you sure you want to delete this Taxe ?"
                onButtonClick={handleConfirmModalAccountHistory}
              />
            )}

            {/* Manage Accounts Modal */}
            {handleAddMangeAccountsopen && (
              <ManageAccounts
                open={MangeAccountsAddopen}
                setOpen={setMangeAccountsAddopen}
                title={"Add"}
              />
            )}
            {handleEditMangeAccountsopen && (
              <ManageAccounts
                open={MangeAccountsEditopen}
                setOpen={setMangeAccountsEditopen}
                title={"Edit"}
              />
            )}

            {/* Manage Rates Modal */}
            {handleAddMangeRatesopen && (
              <ManageRates
                open={MangeRatesAddopen}
                setOpen={setMangeRatesAddopen}
                title={"Add"}
              />
            )}
            {handleEditMangeRatesopen && (
              <ManageRates
                open={MangeRatesEditopen}
                setOpen={setMangeRatesEditopen}
                title={"Edit"}
              />
            )}
            {/* Manage Costs Modal */}
            {handleAddMangeCostsopen && (
              <ManageCosts
                open={MangeCostsAddopen}
                setOpen={setMangeCostsAddopen}
                title={"Add"}
              />
            )}
            {handleEditMangeCostsopen && (
              <ManageCosts
                open={MangeCostsEditopen}
                setOpen={setMangeCostsEditopen}
                title={"Edit"}
              />
            )}
            {isModalOpenSwitchClient && (
              <ConfirmationModal
                open={isModalOpenSwitchClient}
                onClose={handleModalCloseSwitchClient}
                title="Change Status"
                text="Are you sure you want to change this Client Status?"
                onButtonClick={handleModalConfirmSwitchClient}
              />
            )}
            {/* Manage Wallet Modal */}
            {handleAddMangeWalletopen && (
              <ManageWallet
                open={MangeWalletAddopen}
                setOpen={setMangeWalletAddopen}
                title={"Add"}
              />
            )}
            {handleEditMangeWalletopen && (
              <ManageWallet
                open={MangeWalletEditopen}
                setOpen={setMangeWalletEditopen}
                title={"Edit"}
              />
            )}

            {/* Manage AccountHistory Modal */}
            {handleAddMangeAccountHistoryopen && (
              <ManageAccountHistory
                open={MangeAccountHistoryAddopen}
                setOpen={setMangeAccountHistoryAddopen}
                title={"Add"}
              />
            )}
            {isOpenShowAccounts && (
              <CustomizedDialogs
                open={isOpenShowAccounts}
                setOpen={setIsOpenShowAccounts}
                title={"Accounts"}
                size="lg"
                content={
                  <>
                    <Table
                      disableHeaderColor={true}
                      disableActions={true}
                      rowCountState={tableDataShowAccounts?.totalCount}
                      data={tableDataShowAccounts?.data}
                      isLoading={isLoadingAccounts}
                      columns={columnsAccounts}
                      pageIndex={
                        tableDataShowAccounts?.pageIndex
                          ? tableDataShowAccounts?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModelAccounts}
                      paginationModel={paginationModelAccounts}
                    />
                  </>
                }
              />
            )}
            {handleEditMangeAccountHistoryopen && (
              <ManageAccountHistory
                open={MangeAccountHistoryEditopen}
                setOpen={setMangeAccountHistoryEditopen}
                title={"Edit"}
              />
            )}

            {/* Swtich Accounts Modal */}
            {isModalOpenSwitchAccounts && (
              <ConfirmationModal
                open={isModalOpenSwitchAccounts}
                onClose={handleModalCloseSwitchAccounts}
                title="Switch Zone"
                text="Are you sure you want to Switch this Zone ?"
                onButtonClick={handleModalConfirmSwitchAccounts}
              />
            )}

            {/* Swtich Rates Modal */}
            {isModalOpenSwitchRates && (
              <ConfirmationModal
                open={isModalOpenSwitchRates}
                onClose={handleModalCloseSwitchRates}
                title="Switch Zone"
                text="Are you sure you want to Switch this Zone ?"
                onButtonClick={handleModalConfirmSwitchRates}
              />
            )}
            {/* Swtich Costs Modal */}
            {isModalOpenSwitchCosts && (
              <ConfirmationModal
                open={isModalOpenSwitchCosts}
                onClose={handleModalCloseSwitchCosts}
                title="Switch Zone"
                text="Are you sure you want to Switch this Zone ?"
                onButtonClick={handleModalConfirmSwitchCosts}
              />
            )}
            {/* Swtich Wallet Modal */}
            {isModalOpenSwitchWallet && (
              <ConfirmationModal
                open={isModalOpenSwitchWallet}
                onClose={handleModalCloseSwitchWallet}
                title="Switch Zone"
                text="Are you sure you want to Switch this Zone ?"
                onButtonClick={handleModalConfirmSwitchWallet}
              />
            )}

            {/* Swtich AccountHistory Modal */}
            {isModalOpenSwitchAccountHistory && (
              <ConfirmationModal
                open={isModalOpenSwitchAccountHistory}
                onClose={handleModalCloseSwitchAccountHistory}
                title="Switch Zone"
                text="Are you sure you want to Switch this Zone ?"
                onButtonClick={handleModalConfirmSwitchAccountHistory}
              />
            )}
          </div>
        )}
      {openViewClient && <ManageClient handleGoBack={handleOpenViewClient} />}
      {openAddClientModal && handleAddClientOpen && (
        <NewClient
          handleGoBack={handleOpenAddlient}
          setOpenAddClientModal={setOpenAddClientModal}
          setRefreshTableClient={setRefreshTableClient}
          isNewProvider={true}
          goToListing={() => setOpenAddClientModal(false)}
        />
      )}

      {editClientModal && handleEditClientOpen && (
        <NewClient
          open={editClientModal}
          setOpen={setOpenEditClientModal}
          title={"Edit"}
          handleGoBack={handleOpenAddlient}
          setOpenAddClientModal={setOpenEditClientModal}
          isEdit={true}
          selectedProvider={selectedClient}
          goToListing={() => setOpenEditClientModal(false)}
        />
      )}
      {bankingDetailsModalOpen && (
        <CustomizedDialogs
          open={bankingDetailsModalOpen}
          setOpen={setBankingDetailsModalOpen}
          title={"Banking Details"}
          secondActionBtnName={"Save"}
          // secondActionBtn={() => setGoToConfigPage(true)}
          secondActionBtn={() => {
            submitAddBankingDetails();

            // setGoToConfigPage(true);
            // setShowPopup(false);
          }}
          firstActionBtnName={"Cancel"}
          firstActionBtnFct={() => {
            setBankingDetailsModalOpen(false);
            setCountry("");
            setIban("");
            setCodeSwiftBic("");
            setBankAccNb("");
            setRountingNb("");
            setBankName("");
            setBankStreet("");
            setBankPhoneNb("");
            setBankPostCode("");
            setBankCity("");
            setBankSwiftCorrespondent("");
          }}
          disableFirstBtn={
            country === "" ||
            iban === "" ||
            codeSwiftBic === "" ||
            bankAccNb === "" ||
            rountingNb === "" ||
            bankName === "" ||
            bankStreet === "" ||
            bankPhoneNb === "" ||
            bankPostCode === "" ||
            bankCity === "" ||
            bankSwiftCorrespondent === ""
          }
          content={
            <div>
              <SelectFormNew
                placeholder={"Country"}
                value={country}
                setValue={setCountry}
                options={CountryOptions}
              />{" "}
              <TextFieldForm
                placeholder={"Iban"}
                value={iban}
                setValue={setIban}
                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Code Swift Bic"}
                value={codeSwiftBic}
                setValue={setCodeSwiftBic}
                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Account Number"}
                value={bankAccNb}
                setValue={setBankAccNb}
                bankAccountNb={true}
                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Routing Number"}
                value={rountingNb}
                setValue={setRountingNb}
                bankAccountNb={true}

                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Bank Name"}
                value={bankName}
                setValue={setBankName}
                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Street"}
                value={bankStreet}
                setValue={setBankStreet}
                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Phone Number"}
                value={bankPhoneNb}
                setValue={setBankPhoneNb}
                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Post Code"}
                value={bankPostCode}
                setValue={setBankPostCode}
                positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"City"}
                value={bankCity}
                setValue={setBankCity}
                // positiveNumber={true}
              />
              <TextFieldForm
                placeholder={"Swift Correspondent"}
                value={bankSwiftCorrespondent}
                setValue={setBankSwiftCorrespondent}
                // positiveNumber={true}
              />
            </div>
          }
        />
      )}

      {openPopupResetPass && (
        <CustomizedDialogs
          open={openPopupResetPass}
          setOpen={setOpenPopupResetPass}
          title={"Reset Password"}
          secondActionBtnName={"Save"}
          // secondActionBtn={() => setGoToConfigPage(true)}
          secondActionBtn={() => {
            submitResetPass();

            // setGoToConfigPage(true);
            // setShowPopup(false);
          }}
          firstActionBtnName={"Cancel"}
          firstActionBtnFct={() => {
            setOpenPopupResetPass(false);
            setUsernameLogin("");
            setPin("");
            setNewPass("");
          }}
          // disableFirstBtn={
          //   !isEdit
          //     ? Country === "" || Operator === "" || Name === ""
          //     : Name === ""
          // }
          content={
            <div>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "red",
                  textAlign: "center",
                }}
              >
                A PIN has been sent to your email
              </Typography>
              <TextFieldForm
                placeholder={"Pin"}
                value={pin}
                setValue={setPin}
                positiveNumber={true}
              />
              <PasswordInput
                placeholder={"New Password"}
                value={newPass}
                setValue={setNewPass}
              />
            </div>
          }
        />
      )}

      {showPopupReseller && (
        <CustomizedDialogs
          removeCloseBtn={true}
          open={showPopupReseller}
          setOpen={setShowPopupReseller}
          title={"Resellers"}
          secondActionBtnName={"Save"}
          TransitionComponent={Grow}
          // secondActionBtn={() => setGoToConfigPage(true)}
          secondActionBtn={() => {
            setReseller(resellerPopUp);
            // setGoToConfigPage(true);
            setShowPopupReseller(false);
          }}
          disableSecondtBtn={resellerPopUp === ""}
          firstActionBtnName={"Cancel"}
          firstActionBtnFct={() => {
            console.log("prevPath",prevPath);
            navigate(prevPath)}
          }
          content={
            <div>
              <SelectFormNew
                value={resellerPopUp}
                setValue={setResellerPopUp}
                options={resellersOptions}
                placeholder={"choose a reseller"}
                placementTop={true}
                disabled={resellersOptions === false}
              />
              {/* <Typography style={{ textAlign: "center" }}>
                Please add services configuration to activate
              </Typography> */}
            </div>
          }
        />
      )}

      {manageConfigOpen && (
        <NewClient
          selectedProvider={selectedClient}
          goToListing={handleManageConfigClose}
        />
      )}
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Clients2;
