import Add from "@mui/icons-material/Add";
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import ManageBundle from "./ManageBundle";
import ManageFeatures from "./ManageFeatures";
import ManageReason from "./ManageReason";
import ManageService from "./ManageService";
import HorizantalTabs from "../../../Components/Tabs/HorizantalTabs";
import ManageBundleContent from "./ManageBundleContent";
import ManageBundleDetail from "./ManageBundleDetail";
import ManageServiceQuota from "./ManageServiceQuota";
import ManageServiceFeature from "./ManageServiceFeature";
const Catalog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeBtn, setActiveBtn] = useState("Bundle");
  const [ActiveBtnHorizantal, setActiveBtnHorizantal] = useState("English");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  // Bundle
  const [isModalOpenSwitchBundle, setModalOpenSwitchBundle] = useState(false);
  const [isCheckedSwitchBundle, setCheckedSwitchBundle] = useState(false);
  const [switchStatesBundle, setswitchStatesBundle] = useState({});
  const handleSwitchChangeSwitchBundle = (rowId) => {
    setModalOpenSwitchBundle(true);
    setCheckedSwitchBundle(rowId);
  };
  const handleModalCloseSwitchBundle = () => {
    setModalOpenSwitchBundle(false);
  };
  const handleModalConfirmSwitchBundle = () => {
    setModalOpenSwitchBundle(false);
    // setCheckedSwitchBundle(!isCheckedSwitchBundle);
    setswitchStatesBundle((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchBundle]: !prevStates[isCheckedSwitchBundle],
    }));
  };

  // SUB
  const [isModalOpenSwitchmultipleSub, setModalOpenSwitchmultipleSub] =
    useState(false);
  const [isCheckedSwitchmultipleSub, setCheckedSwitchmultipleSub] =
    useState(false);

  const handleModalCloseSwitchmultipleSub = () => {
    setModalOpenSwitchmultipleSub(false);
  };

  const [switchStatesmultipleSub, setswitchStatesmultipleSub] = useState({});
  const handleSwitchChangeSwitchmultipleSub = (rowId) => {
    setModalOpenSwitchmultipleSub(true);
    setCheckedSwitchmultipleSub(rowId);
  };
  const handleModalConfirmSwitchmultipleSub = () => {
    setModalOpenSwitchmultipleSub(false);
    // setCheckedSwitchBundle(!isCheckedSwitchBundle);
    setswitchStatesmultipleSub((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchmultipleSub]: !prevStates[isCheckedSwitchmultipleSub],
    }));
  };

  //

  // Service
  const [isModalOpenSwitchService, setModalOpenSwitchService] = useState(false);
  const [isCheckedSwitchService, setCheckedSwitchService] = useState(false);
  const [switchStatesService, setSwitchStatesService] = useState({});
  const handleSwitchChangeSwitchService = (rowId) => {
    setModalOpenSwitchService(true);
    setCheckedSwitchService(rowId);
  };

  const handleModalCloseSwitchService = () => {
    setModalOpenSwitchService(false);
  };
  const handleModalConfirmSwitchService = () => {
    setModalOpenSwitchService(false);
    // setCheckedSwitchService(!isCheckedSwitchService);
    setSwitchStatesService((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchService]: !prevStates[isCheckedSwitchService],
    }));
  };

  //
  // Reason
  const [isModalOpenSwitchReason, setModalOpenSwitchReason] = useState(false);
  const [isCheckedSwitchReason, setCheckedSwitchReason] = useState(false);
  const [switchStatesReason, setSwitchStatesReason] = useState({});
  const handleSwitchChangeSwitchReason = (rowId) => {
    setModalOpenSwitchReason(true);
    setCheckedSwitchReason(rowId);
  };

  const handleModalCloseSwitchReason = () => {
    setModalOpenSwitchReason(false);
  };
  const handleModalConfirmSwitchReason = () => {
    setModalOpenSwitchReason(false);
    // setCheckedSwitchReason(!isCheckedSwitchReason);
    setSwitchStatesReason((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchReason]: !prevStates[isCheckedSwitchReason],
    }));
  };
  //
  // Features
  const [isModalOpenSwitchFeatures, setModalOpenSwitchFeatures] =
    useState(false);
  const [isCheckedSwitchFeatures, setCheckedSwitchFeatures] = useState(false);
  const [switchStatesFeatures, setSwitchStatesFeatures] = useState({});
  const handleSwitchChangeSwitchFeatures = (rowId) => {
    setModalOpenSwitchFeatures(true);
    setCheckedSwitchFeatures(rowId);
  };

  const handleModalCloseSwitchFeatures = () => {
    setModalOpenSwitchFeatures(false);
  };
  const handleModalConfirmSwitchFeatures = () => {
    setModalOpenSwitchFeatures(false);
    // setCheckedSwitchFeatures(!isCheckedSwitchFeatures);
    setSwitchStatesFeatures((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchFeatures]: !prevStates[isCheckedSwitchFeatures],
    }));
  };
  //

  // Quoto
  const [isModalOpenSwitchQuoto, setModalOpenSwitchQuoto] = useState(false);
  const [isCheckedSwitchQuoto, setCheckedSwitchQuoto] = useState(false);
  const [switchStatesQuoto, setSwitchStatesQuoto] = useState({});
  const handleSwitchChangeSwitchQuoto = (rowId) => {
    setModalOpenSwitchQuoto(true);
    setCheckedSwitchQuoto(rowId);
  };

  const handleModalCloseSwitchQuoto = () => {
    setModalOpenSwitchQuoto(false);
  };
  const handleModalConfirmSwitchQuoto = () => {
    setModalOpenSwitchQuoto(false);
    setSwitchStatesQuoto((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchQuoto]: !prevStates[isCheckedSwitchQuoto], // Correctly toggle the state
    }));
  };
  
  //
  const [Bundle, setBundle] = React.useState("");

  const handleChangeBundle = (event) => {
    setBundle(event.target.value);
  };
  const [Service, setService] = React.useState("");

  const handleChangeService = (event) => {
    setService(event.target.value);
  };
  const tableDataBundle = [
    {
      id: 1,
      tag: "Tag 1",
      parentId: "21212121212",
      multipleSub: "Inactive",
      status: "Inactive",
    },
    {
      id: 2,
      tag: "Tag 2",
      parentId: "43434223",
      multipleSub: "Inactive",
      status: "Inactive",
    },
    {
      id: 3,
      tag: "Tag 3",
      parentId: "1232456",
      multipleSub: "Inactive",
      status: "Inactive",
    },
  ];
  let columnsBundle = [
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
    },
    {
      field: "parentId",
      headerName: "Parent Id",
      flex: 1,
    },
    {
      field: "multipleSub",
      headerName: "Multiple Sub",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesmultipleSub[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchmultipleSub(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesBundle[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchBundle(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataBundleContent = [
    { id: 1, bundle: "bundle 1", content: "content 1" },
    { id: 2, bundle: "bundle 2", content: "content 2" },
    { id: 3, bundle: "bundle 3", content: "content 3" },
  ];
  let columnsBundleContent = [
    {
      field: "content",
      headerName: "Content",
      flex: 1,
    },
    {
      field: "bundle",
      headerName: "Bundle",
      flex: 1,
    },
  ];

  const tableDataBundleDetail = [
    {
      id: 1,
      name: "name 1",
      bundle: "bundle 1",
      description: "description  1",
      language: "arabic",
    },
    {
      id: 2,
      name: "name 2",
      bundle: "bundle 2",
      description: "description  2",
      language: "english",
    },
    {
      id: 3,
      name: "name 3",
      bundle: "bundle 3",
      description: "description  3",
      language: "french",
    },
  ];
  let columnsBundleDetail = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "bundle",
      headerName: "bundle",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "language",
      headerName: "Language",
      flex: 1,
    },
  ];

  const tableDataService = [
    {
      id: 1,
      category: "Service type 1",
      parentId: "55465431215643",
      tenantId: "45641354531345",
      quota: "serviceType ",
      price: "122",
      currency: "euro",
      provider: "provider 1",
      externalId: "75413546842",
      statusId: "878645",
    },
    {
      id: 2,
      category: "Service type 2",
      parentId: "55465431215643",
      tenantId: "45641354531345",
      quota: "serviceType ",
      price: "122",
      currency: "euro",
      provider: "provider 2",
      externalId: "75413546842",
      statusId: "878645",
    },
  ];
  let columnsService = [
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      headerName: "Parent Id",
      field: "parentId",
      flex: 1,
    },
    {
      headerName: "Tenant Id",
      field: "tenantId",
      flex: 1,
    },
    {
      headerName: "Quota",
      field: "quota",
      flex: 1,
    },
    {
      headerName: "Price",
      field: "price",
      flex: 1,
    },
    {
      headerName: "Currency",
      field: "currency",
      flex: 1,
    },
    {
      headerName: "Provider",
      field: "provider",
      flex: 1,
    },
    {
      headerName: "External Id",
      field: "externalId",
      flex: 1,
    },
    {
      headerName: "Status Id",
      field: "statusId",
      flex: 1,
    },
  ];

  const tableDataServiceFeature = [
    {
      id: 1,
      service: "Service 1",
      feature: "feature 1",
    },
    {
      id: 2,
      service: "Service 2",
      feature: "feature 2",
    },
  ];
  let columnsServiceFeature = [
    {
      field: "service",
      headerName: "Service",
      flex: 1,
    },
    {
      headerName: "Feature",
      field: "feature",
      flex: 1,
    },
  ];

  const tableDataServiceQuota = [
    {
      id: 1,
      tag: "Service type 1",
      parentId: "55465431215643",
      serviceId: "45641354531345",
      quotaType: "serviceType ",
      quotaValue: "122",
      minValue: "euro",
      maxValue: "provider 1",
      bundle: "75413546842",
      status: "",
    },
  ];
  let columnsServiceQuota = [
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
    },
    {
      headerName: "Parent Id",
      field: "parentId",
      flex: 1,
    },
    {
      headerName: "Service Id",
      field: "serviceId",
      flex: 1,
    },
    {
      headerName: "Quota Type",
      field: "quotaType",
      flex: 1,
    },
    {
      headerName: "Quota Value",
      field: "quotaValue",
      flex: 1,
    },
    {
      headerName: "Min Value",
      field: "minValue",
      flex: 1,
    },
    {
      headerName: "Max Value",
      field: "maxValue",
      flex: 1,
    },
    {
      headerName: "Bundle",
      field: "bundle",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesQuoto[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchQuoto(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataReason = [
    {
      id: 1,
      tag: "Tag 1",
      parentId: "12312",
      reasonType: "reasonType 1",
      reasonCategory: "reasonCategory 1",
      status: "Inactive",
    },
    {
      id: 2,
      tag: "Tag 2",
      parentId: "12312",
      reasonType: "reasonType 2",
      reasonCategory: "reasonCategory 2",
      status: "Inactive",
    },
  ];
  let columnsReason = [
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
    },
    {
      headerName: "Parent ID",
      field: "parentId",
      flex: 1,
    },
    {
      headerName: "Reason Type",
      field: "reasonType",
      flex: 1,
    },
    {
      headerName: "Reason Category",
      field: "reasonCategory",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesReason[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchReason(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataFeatures = [
    {
      id: 1,
      name: "operator 1",
      description: "Tag 1",
      languageCode: "12312",
      featureTypeTag: "U.S",
      valueType: "SASAS",
      status: "Inactive",
    },
  ];
  let columnsFeatures = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
    },
    {
      headerName: "Language Code",
      field: "languageCode",
      flex: 1,
    },
    {
      headerName: "Feature Type Tag",
      field: "featureTypeTag",
      flex: 1,
    },
    {
      field: "valueType",
      headerName: "Value Type",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesFeatures[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchFeatures(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataFeaturesAR = [
    {
      id: 1,
      name: "operator 1 AR ",
      description: "Tag 1 AR ",
      languageCode: "12312 AR",
      featureTypeTag: "U.S",
      valueType: "SASAS",
      status: "Inactive",
    },
  ];
  let columnsFeaturesAR = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
    },
    {
      headerName: "Language Code",
      field: "languageCode",
      flex: 1,
    },
    {
      headerName: "featureTypeTag",
      field: "Feature Type Tag",
      flex: 1,
    },
    {
      field: "valueType",
      headerName: "Value Type",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesFeatures[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchFeatures(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataFeaturesFR = [
    {
      id: 1,
      name: "operator 1 FR",
      description: "Tag 1 FR",
      languageCode: "12312 FR",
      featureTypeTag: "U.S",
      valueType: "SASAS",
      status: "Inactive",
    },
  ];
  let columnsFeaturesFR = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
    },
    {
      headerName: "Language Code",
      field: "languageCode",
      flex: 1,
    },
    {
      headerName: "featureTypeTag",
      field: "Feature Type Tag",
      flex: 1,
    },
    {
      field: "valueType",
      headerName: "Value Type",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesFeatures[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchFeatures(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const tabs = [
    {
      label: "Bundle",
    },
    {
      label: "Service",
    },
    {
      label: "Reason",
    },
    {
      label: "Features",
    },
  ];

  // Delete Modal Bundle functions
  const [ConfirmOpenBundle, setConfirmOpenBundle] = useState(false);
  const [modalContentBundle, setModalContentBundle] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalBundle = (title, text, onButtonClick) => {
    setModalContentBundle({ title, text, onButtonClick });
    setConfirmOpenBundle(true);
  };

  const handleConfirmModalBundle = () => {
    setConfirmOpenBundle(false);
  };

  //
  // Delete Modal Bundle Content functions
  const [ConfirmOpenBundleContent, setConfirmOpenBundleContent] =
    useState(false);
  const [modalContentBundleContent, setModalContentBundleContent] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalBundleContent = (title, text, onButtonClick) => {
    setModalContentBundleContent({ title, text, onButtonClick });
    setConfirmOpenBundleContent(true);
  };

  const handleConfirmModalBundleContent = () => {
    setConfirmOpenBundleContent(false);
  };

  //

  // Delete Modal Bundle Detail functions
  const [ConfirmOpenBundleDetail, setConfirmOpenBundleDetail] = useState(false);
  const [modalContentBundleDetail, setModalContentBundleDetail] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalBundleDetail = (title, text, onButtonClick) => {
    setModalContentBundleDetail({ title, text, onButtonClick });
    setConfirmOpenBundleDetail(true);
  };

  const handleConfirmModalBundleDetail = () => {
    setConfirmOpenBundleDetail(false);
  };

  //

  // Delete Modal Service functions
  const [ConfirmOpenService, setConfirmOpenService] = useState(false);
  const [modalContentService, setModalContentService] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalService = (title, text, onButtonClick) => {
    setModalContentService({ title, text, onButtonClick });
    setConfirmOpenService(true);
  };

  const handleConfirmModalService = () => {
    setConfirmOpenService(false);
  };

  //

  // Delete Modal Service Quota functions
  const [ConfirmOpenServiceQuota, setConfirmOpenServiceQuota] = useState(false);
  const [modalContentServiceQuota, setModalContentServiceQuota] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalServiceQuota = (title, text, onButtonClick) => {
    setModalContentServiceQuota({ title, text, onButtonClick });
    setConfirmOpenServiceQuota(true);
  };

  const handleConfirmModalServiceQuota = () => {
    setConfirmOpenServiceQuota(false);
  };

  //

  // Delete Modal Service Feature functions
  const [ConfirmOpenServiceFeature, setConfirmOpenServiceFeature] =
    useState(false);
  const [modalContentServiceFeature, setModalContentServiceFeature] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalServiceFeature = (title, text, onButtonClick) => {
    setModalContentServiceFeature({ title, text, onButtonClick });
    setConfirmOpenServiceFeature(true);
  };

  const handleConfirmModalServiceFeature = () => {
    setConfirmOpenServiceFeature(false);
  };

  //
  // Delete Modal Reason functions
  const [ConfirmOpenReason, setConfirmOpenReason] = useState(false);
  const [modalContentReason, setModalContentReason] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalReason = (title, text, onButtonClick) => {
    setModalContentReason({ title, text, onButtonClick });
    setConfirmOpenReason(true);
  };

  const handleConfirmModalReason = () => {
    setConfirmOpenReason(false);
  };

  //
  // Delete Modal Languages functions
  const [ConfirmOpenLanguages, setConfirmOpenLanguages] = useState(false);
  const [modalContentLanguages, setModalContentLanguages] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalLanguages = (title, text, onButtonClick) => {
    setModalContentLanguages({ title, text, onButtonClick });
    setConfirmOpenLanguages(true);
  };

  const handleConfirmModalLanguages = () => {
    setConfirmOpenLanguages(false);
  };

  //
  // Delete Modal Features functions
  const [ConfirmOpenFeatures, setConfirmOpenFeatures] = useState(false);
  const [modalContentFeatures, setModalContentFeatures] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalFeatures = (title, text, onButtonClick) => {
    setModalContentFeatures({ title, text, onButtonClick });
    setConfirmOpenFeatures(true);
  };

  const handleConfirmModalFeatures = () => {
    setConfirmOpenFeatures(false);
  };
  //

  // Manage Bundle
  const [MangeBundleAddopen, setMangeBundleAddopen] = useState(false);
  const [MangeBundleEditopen, setMangeBundleEditopen] = useState(false);

  const handleAddMangeBundleopen = () => {
    setMangeBundleAddopen(true);
  };

  const handleAddMangeBundleclose = () => {
    setMangeBundleAddopen(false);
  };
  const handleEditMangeBundleopen = () => {
    setMangeBundleEditopen(true);
  };

  const handleEditMangeBundleclose = () => {
    setMangeBundleEditopen(false);
  };

  // Manage Bundle Content
  const [MangeBundleContentAddopen, setMangeBundleContentAddopen] =
    useState(false);
  const [MangeBundleContentEditopen, setMangeBundleContentEditopen] =
    useState(false);

  const handleAddMangeBundleContentopen = () => {
    setMangeBundleContentAddopen(true);
  };

  const handleAddMangeBundleContentclose = () => {
    setMangeBundleContentAddopen(false);
  };
  const handleEditMangeBundleContentopen = () => {
    setMangeBundleContentEditopen(true);
  };

  const handleEditMangeBundleContentclose = () => {
    setMangeBundleContentEditopen(false);
  };

  // Manage Detail
  const [MangeBundleDetailAddopen, setMangeBundleDetailAddopen] =
    useState(false);
  const [MangeBundleDetailEditopen, setMangeBundleDetailEditopen] =
    useState(false);

  const handleAddMangeBundleDetailopen = () => {
    setMangeBundleDetailAddopen(true);
  };

  const handleAddMangeBundleDetailclose = () => {
    setMangeBundleDetailAddopen(false);
  };
  const handleEditMangeBundleDetailopen = () => {
    setMangeBundleDetailEditopen(true);
  };

  const handleEditMangeBundleDetailclose = () => {
    setMangeBundleDetailEditopen(false);
  };

  // Manage Service
  const [MangeServiceAddopen, setMangeServiceAddopen] = useState(false);
  const [MangeServiceEditopen, setMangeServiceEditopen] = useState(false);

  const handleAddMangeServiceopen = () => {
    setMangeServiceAddopen(true);
  };

  const handleAddMangeServiceclose = () => {
    setMangeServiceAddopen(false);
  };
  const handleEditMangeServiceopen = () => {
    setMangeServiceEditopen(true);
  };

  const handleEditMangeServiceclose = () => {
    setMangeServiceEditopen(false);
  };

  // Manage Service Feature
  const [MangeServiceFeatureAddopen, setMangeServiceFeatureAddopen] =
    useState(false);
  const [MangeServiceFeatureEditopen, setMangeServiceFeatureEditopen] =
    useState(false);

  const handleAddMangeServiceFeatureopen = () => {
    setMangeServiceFeatureAddopen(true);
  };

  const handleAddMangeServiceFeatureclose = () => {
    setMangeServiceFeatureAddopen(false);
  };
  const handleEditMangeServiceFeatureopen = () => {
    setMangeServiceFeatureEditopen(true);
  };

  const handleEditMangeServiceFeatureclose = () => {
    setMangeServiceFeatureEditopen(false);
  };
  // Manage Service Quota
  const [MangeServiceQuotaAddopen, setMangeServiceQuotaAddopen] =
    useState(false);
  const [MangeServiceQuotaEditopen, setMangeServiceQuotaEditopen] =
    useState(false);

  const handleAddMangeServiceQuotaopen = () => {
    setMangeServiceQuotaAddopen(true);
  };

  const handleAddMangeServiceQuotaclose = () => {
    setMangeServiceQuotaAddopen(false);
  };
  const handleEditMangeServiceQuotaopen = () => {
    setMangeServiceQuotaEditopen(true);
  };

  const handleEditMangeServiceQuotaclose = () => {
    setMangeServiceQuotaEditopen(false);
  };
  // Manage Reason
  const [MangeReasonAddopen, setMangeReasonAddopen] = useState(false);
  const [MangeReasonEditopen, setMangeReasonEditopen] = useState(false);

  const handleAddMangeReasonopen = () => {
    setMangeReasonAddopen(true);
  };

  const handleAddMangeReasonclose = () => {
    setMangeReasonAddopen(false);
  };
  const handleEditMangeReasonopen = () => {
    setMangeReasonEditopen(true);
  };

  const handleEditMangeReasonclose = () => {
    setMangeReasonEditopen(false);
  };

  // Manage Languages
  const [MangeLanguagesAddopen, setMangeLanguagesAddopen] = useState(false);
  const [MangeLanguagesEditopen, setMangeLanguagesEditopen] = useState(false);

  const handleAddMangeLanguagesopen = () => {
    setMangeLanguagesAddopen(true);
  };

  const handleAddMangeLanguagesclose = () => {
    setMangeLanguagesAddopen(false);
  };
  const handleEditMangeLanguagesopen = () => {
    setMangeLanguagesEditopen(true);
  };

  const handleEditMangeLanguagesclose = () => {
    setMangeLanguagesEditopen(false);
  };

  // Manage Features
  const [MangeFeaturesAddopen, setMangeFeaturesAddopen] = useState(false);
  const [MangeFeaturesEditopen, setMangeFeaturesEditopen] = useState(false);

  const handleAddMangeFeaturesopen = () => {
    setMangeFeaturesAddopen(true);
  };

  const handleAddMangeFeaturesclose = () => {
    setMangeFeaturesAddopen(false);
  };
  const handleEditMangeFeaturesopen = () => {
    setMangeFeaturesEditopen(true);
  };

  const handleEditMangeFeaturesclose = () => {
    setMangeFeaturesEditopen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabsHorizantal = [
    {
      label: "English",
    },
    {
      label: "Arabic",
    },
    {
      label: "French",
    },
  ];

  const [selectedFilterBundle, setselectedFilterBundle] = useState("Bundle");
  const [tableDataBundlePage, settableDataBundle] = useState(
    tableDataBundleContent
  );
  const [columnsBundlePage, setcolumnsBundle] = useState(columnsBundle);
  const handleFilterChange = (event) => {
    const newFilter = event.target.value;
    setselectedFilterBundle(newFilter);
    switch (newFilter) {
      case "Bundle":
        settableDataBundle(tableDataBundle);
        setcolumnsBundle(columnsBundle);
        break;
      case "Detail":
        settableDataBundle(tableDataBundleDetail);
        setcolumnsBundle(columnsBundleDetail);
        break;
      case "Content":
        settableDataBundle(tableDataBundleContent);
        setcolumnsBundle(columnsBundleContent);
        break;
      default:
    }
  };
  useEffect(() => {
    handleFilterChange({ target: { value: selectedFilterBundle } });
  }, [selectedFilterBundle]);

  const getAddBundleFunction = () => {
    switch (selectedFilterBundle) {
      case "Bundle":
        return handleAddMangeBundleopen;
      case "Content":
        return handleAddMangeBundleContentopen;
      case "Detail":
        return handleAddMangeBundleDetailopen;
      default:
        return () => {};
    }
  };
  const getEditBundleFunction = () => {
    switch (selectedFilterBundle) {
      case "Bundle":
        return handleEditMangeBundleopen;
      case "Content":
        return handleEditMangeBundleContentopen;
      case "Detail":
        return handleEditMangeBundleDetailopen;
      default:
        return () => {};
    }
  };

  const getBundleDeleteModal = () => {
    switch (selectedFilterBundle) {
      case "Bundle":
        return OpenConfirmModalBundle;
      case "Content":
        return OpenConfirmModalBundleContent;
      case "Detail":
        return OpenConfirmModalBundleDetail;
      default:
        return () => {};
    }
  };

  const [selectedFilterService, setselectedFilterService] = useState("Service");
  const [tableDataServicePage, settableDataServicePage] =
    useState(tableDataService);
  const [columnsServicePage, setcolumnsServicePage] = useState(columnsService);
  const handleFilterChangeService = (event) => {
    const newFilter = event.target.value;
    setselectedFilterService(newFilter);
    switch (newFilter) {
      case "Service":
        settableDataServicePage(tableDataService);
        setcolumnsServicePage(columnsService);
        break;
      case "Service Quota":
        settableDataServicePage(tableDataServiceQuota);
        setcolumnsServicePage(columnsServiceQuota);
        break;
      case "Service Feature":
        settableDataServicePage(tableDataServiceFeature);
        setcolumnsServicePage(columnsServiceFeature);
        break;
      default:
    }
  };
  useEffect(() => {
    handleFilterChangeService({ target: { value: selectedFilterService } });
  }, [selectedFilterService]);

  const getAddServiceFunction = () => {
    switch (selectedFilterService) {
      case "Service":
        return handleAddMangeServiceopen;
      case "Service Quota":
        return handleAddMangeServiceQuotaopen;
      case "Service Feature":
        return handleAddMangeServiceFeatureopen;
      default:
        return () => {};
    }
  };
  const getEditServiceFunction = () => {
    switch (selectedFilterService) {
      case "Service":
        return handleEditMangeServiceopen;
      case "Service Quota":
        return handleEditMangeServiceQuotaopen;
      case "Service Feature":
        return handleEditMangeServiceFeatureopen;
      default:
        return () => {};
    }
  };

  const getServiceDeleteModal = () => {
    switch (selectedFilterService) {
      case "Service":
        return OpenConfirmModalService;
      case "Service Quota":
        return OpenConfirmModalServiceQuota;
      case "Service Feature":
        return OpenConfirmModalServiceFeature;
      default:
        return () => {};
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">
              Catalog Management
            </Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
          {activeBtn === "Bundle" && (
            <Button className="contained" onClick={getAddBundleFunction()}>
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {`Add ${selectedFilterBundle}`}
              </Typography>
            </Button>
          )}
          {activeBtn === "Service" && (
            <Button className="contained" onClick={getAddServiceFunction()}>
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {`Add ${selectedFilterService}`}
              </Typography>
            </Button>
          )}
          {activeBtn === "Reason" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeReasonopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Reason</Typography>
            </Button>
          )}
          {activeBtn === "Features" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeFeaturesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Feature</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container className="Top-spacing div-table">
        <Grid xs={4} md={2} lg={2} className="vertical-grid">
          <VerticalTabs
            activeBtn={activeBtn}
 tabs={tabs} setActiveBtn={setActiveBtn} />
        </Grid>
        <Grid xs={8} md={10} lg={10}>
          {activeBtn === "Bundle" && (
            <Paper
              variant="CardTable"
              sx={{ borderTopLeftRadius: "20px"}}
            >
              <Table
                rowCountState={tableDataBundlePage?.totalCount}
                data={tableDataBundlePage}
                // isLoading={isLoading}
                columns={columnsBundlePage}
                pageIndex={
                  tableDataBundlePage?.pageIndex
                    ? tableDataBundlePage?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={() => getBundleDeleteModal()()}
                editFunction={() => getEditBundleFunction()()}
                enableSearch={true}
                enableFilter={true}
                valueFilter={selectedFilterBundle}
                setValueFilter={setselectedFilterBundle}
                onChangeFilter={handleFilterChange}
                optionsFilter={["Bundle", "Content", "Detail"]}
                enableExportBtn={true}
              />
            </Paper>
          )}
          {activeBtn === "Service" && (
            <Paper
              variant="CardTable"
              sx={{ borderTopLeftRadius: "20px"}}
            >
              <Table
                rowCountState={tableDataServicePage?.totalCount}
                data={tableDataServicePage}
                // isLoading={isLoading}
                columns={columnsServicePage}
                pageIndex={
                  tableDataServicePage?.pageIndex
                    ? tableDataServicePage?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={() => getServiceDeleteModal()()}
                editFunction={() => getEditServiceFunction()()}
                enableSearch={true}
                enableFilter={true}
                valueFilter={selectedFilterService}
                setValueFilter={setselectedFilterService}
                onChangeFilter={handleFilterChangeService}
                optionsFilter={["Service", "Service Quota", "Service Feature"]}
                enableExportBtn={true}
              />
            </Paper>
          )}

          {activeBtn === "Reason" && (
            <Paper
              variant="CardTable"
              sx={{ borderTopLeftRadius: "20px"}}
            >
              <Table
                rowCountState={tableDataReason?.totalCount}
                data={tableDataReason}
                // isLoading={isLoading}
                columns={columnsReason}
                pageIndex={
                  tableDataReason?.pageIndex ? tableDataReason?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                deleteFunction={OpenConfirmModalReason}
                editFunction={handleEditMangeReasonopen}
                enableSearch={true}
              />
            </Paper>
          )}
          {activeBtn === "Features" && (
            <>
              <Grid container>
                <Grid xs={12} item>
                  <HorizantalTabs
                    tabs={tabsHorizantal}
                    setActiveBtn={setActiveBtnHorizantal}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12}>
                  {ActiveBtnHorizantal === "English" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataFeatures?.totalCount}
                        data={tableDataFeatures}
                        columns={columnsFeatures}
                        pageIndex={
                          tableDataFeatures?.pageIndex
                            ? tableDataFeatures?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        enableExportBtn={true}
                        deleteFunction={OpenConfirmModalFeatures}
                        editFunction={handleEditMangeFeaturesopen}
                        enableSearch={true}
                      />
                    </Paper>
                  )}

                  {ActiveBtnHorizantal === "Arabic" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataFeaturesAR?.totalCount}
                        data={tableDataFeaturesAR}
                        columns={columnsFeaturesAR}
                        pageIndex={
                          tableDataFeaturesAR?.pageIndex
                            ? tableDataFeaturesAR?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        enableExportBtn={true}
                        deleteFunction={OpenConfirmModalFeatures}
                        editFunction={handleEditMangeFeaturesopen}
                        enableSearch={true}
                      />
                    </Paper>
                  )}

                  {ActiveBtnHorizantal === "French" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataFeaturesFR?.totalCount}
                        data={tableDataFeaturesFR}
                        columns={columnsFeaturesFR}
                        pageIndex={
                          tableDataFeaturesFR?.pageIndex
                            ? tableDataFeaturesFR?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        enableExportBtn={true}
                        deleteFunction={OpenConfirmModalFeatures}
                        editFunction={handleEditMangeFeaturesopen}
                        enableSearch={true}
                      />
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {/* Delete Bundle Modal */}
      {handleConfirmModalBundle && (
        <ConfirmationModal
          open={ConfirmOpenBundle}
          onClose={handleConfirmModalBundle}
          title="Delete"
          text="Are you sure you want to delete this Bundle ?"
          onButtonClick={handleConfirmModalBundle}
        />
      )}
      {/* Delete Bundle Detail Modal */}
      {handleConfirmModalBundleDetail && (
        <ConfirmationModal
          open={ConfirmOpenBundleDetail}
          onClose={handleConfirmModalBundleDetail}
          title="Delete"
          text="Are you sure you want to delete this Detail ?"
          onButtonClick={handleConfirmModalBundleDetail}
        />
      )}
      {/* Delete Bundle Content Modal */}
      {handleConfirmModalBundleContent && (
        <ConfirmationModal
          open={ConfirmOpenBundleContent}
          onClose={handleConfirmModalBundleContent}
          title="Delete"
          text="Are you sure you want to delete this Content ?"
          onButtonClick={handleConfirmModalBundleContent}
        />
      )}
      {/* Delete Service Modal */}
      {handleConfirmModalService && (
        <ConfirmationModal
          open={ConfirmOpenService}
          onClose={handleConfirmModalService}
          title="Delete"
          text="Are you sure you want to delete this Service ?"
          onButtonClick={handleConfirmModalService}
        />
      )}
      {/* Delete Service Feature Modal */}
      {handleConfirmModalServiceFeature && (
        <ConfirmationModal
          open={ConfirmOpenServiceFeature}
          onClose={handleConfirmModalServiceFeature}
          title="Delete"
          text="Are you sure you want to delete this Service Feature ?"
          onButtonClick={handleConfirmModalServiceFeature}
        />
      )}

      {/* Delete Service Modal Quota */}
      {handleConfirmModalServiceQuota && (
        <ConfirmationModal
          open={ConfirmOpenServiceQuota}
          onClose={handleConfirmModalServiceQuota}
          title="Delete"
          text="Are you sure you want to delete this Service Quota?"
          onButtonClick={handleConfirmModalServiceQuota}
        />
      )}
      {/* Delete Reason Modal */}
      {handleConfirmModalReason && (
        <ConfirmationModal
          open={ConfirmOpenReason}
          onClose={handleConfirmModalReason}
          title="Delete"
          text="Are you sure you want to delete this Reason ?"
          onButtonClick={handleConfirmModalReason}
        />
      )}
      {/* Delete Languages Modal */}
      {handleConfirmModalLanguages && (
        <ConfirmationModal
          open={ConfirmOpenLanguages}
          onClose={handleConfirmModalLanguages}
          title="Delete"
          text="Are you sure you want to delete this Language ?"
          onButtonClick={handleConfirmModalLanguages}
        />
      )}
      {/* Delete Features Modal */}
      {handleConfirmModalFeatures && (
        <ConfirmationModal
          open={ConfirmOpenFeatures}
          onClose={handleConfirmModalFeatures}
          title="Delete"
          text="Are you sure you want to delete this Feature ?"
          onButtonClick={handleConfirmModalFeatures}
        />
      )}

      {/* Manage Bundle Modal */}
      {handleAddMangeBundleopen && (
        <ManageBundle
          open={MangeBundleAddopen}
          setOpen={setMangeBundleAddopen}
          title={"Add Bundle"}
        />
      )}
      {handleEditMangeBundleopen && (
        <ManageBundle
          open={MangeBundleEditopen}
          setOpen={setMangeBundleEditopen}
          title={"Edit Bundle"}
        />
      )}
      {/* Manage Bundle Detail Modal */}
      {handleAddMangeBundleDetailopen && (
        <ManageBundleDetail
          open={MangeBundleDetailAddopen}
          setOpen={setMangeBundleDetailAddopen}
          title={"Add Detail"}
        />
      )}
      {handleEditMangeBundleDetailopen && (
        <ManageBundleDetail
          open={MangeBundleDetailEditopen}
          setOpen={setMangeBundleDetailEditopen}
          title={"Edit Detail"}
        />
      )}

      {/* Manage Bundle Content Modal */}
      {handleAddMangeBundleContentopen && (
        <ManageBundleContent
          open={MangeBundleContentAddopen}
          setOpen={setMangeBundleContentAddopen}
          title={"Add Content"}
        />
      )}
      {handleEditMangeBundleContentopen && (
        <ManageBundleContent
          open={MangeBundleContentEditopen}
          setOpen={setMangeBundleContentEditopen}
          title={"Edit Content"}
        />
      )}
      {/* Manage Service Modal */}
      {handleAddMangeServiceopen && (
        <ManageService
          open={MangeServiceAddopen}
          setOpen={setMangeServiceAddopen}
          title={"Add Service"}
        />
      )}
      {handleEditMangeServiceopen && (
        <ManageService
          open={MangeServiceEditopen}
          setOpen={setMangeServiceEditopen}
          title={"Edit Service"}
        />
      )}

      {/* Manage Service Quota Modal */}
      {handleAddMangeServiceQuotaopen && (
        <ManageServiceQuota
          open={MangeServiceQuotaAddopen}
          setOpen={setMangeServiceQuotaAddopen}
          title={"Add Service Quota"}
        />
      )}
      {handleEditMangeServiceQuotaopen && (
        <ManageServiceQuota
          open={MangeServiceQuotaEditopen}
          setOpen={setMangeServiceQuotaEditopen}
          title={"Edit Service Quota"}
        />
      )}

      {/* Manage Service Feature Modal */}
      {handleAddMangeServiceFeatureopen && (
        <ManageServiceFeature
          open={MangeServiceFeatureAddopen}
          setOpen={setMangeServiceFeatureAddopen}
          title={"Add Service Feature"}
        />
      )}
      {handleEditMangeServiceFeatureopen && (
        <ManageServiceFeature
          open={MangeServiceFeatureEditopen}
          setOpen={setMangeServiceFeatureEditopen}
          title={"Edit Service Feature"}
        />
      )}
      {/* Manage Reason Modal */}
      {handleAddMangeReasonopen && (
        <ManageReason
          open={MangeReasonAddopen}
          setOpen={setMangeReasonAddopen}
          title={"Add Reason"}
        />
      )}
      {handleEditMangeReasonopen && (
        <ManageReason
          open={MangeReasonEditopen}
          setOpen={setMangeReasonEditopen}
          title={"Edit Reason"}
        />
      )}

      {/* Manage Features Modal */}
      {handleAddMangeFeaturesopen && (
        <ManageFeatures
          open={MangeFeaturesAddopen}
          setOpen={setMangeFeaturesAddopen}
          title={"Add Feature"}
        />
      )}
      {handleEditMangeFeaturesopen && (
        <ManageFeatures
          open={MangeFeaturesEditopen}
          setOpen={setMangeFeaturesEditopen}
          title={"Edit Feature"}
        />
      )}

      {/* Swtich Bundle Modal */}
      {isModalOpenSwitchBundle && (
        <ConfirmationModal
          open={isModalOpenSwitchBundle}
          onClose={handleModalCloseSwitchBundle}
          title="Change Status"
          text="Are you sure you want to change this Bundle Status?"
          onButtonClick={handleModalConfirmSwitchBundle}
        />
      )}
      {/* Swtich multipleSub Modal */}
      {isModalOpenSwitchmultipleSub && (
        <ConfirmationModal
          open={isModalOpenSwitchmultipleSub}
          onClose={handleModalCloseSwitchmultipleSub}
          title="Change Status"
          text="Are you sure you want to change this Multiple Sub Status?"
          onButtonClick={handleModalConfirmSwitchmultipleSub}
        />
      )}

      {/* Swtich Service Modal */}
      {isModalOpenSwitchService && (
        <ConfirmationModal
          open={isModalOpenSwitchService}
          onClose={handleModalCloseSwitchService}
          title="Change Status"
          text="Are you sure you want to change this Service Status?"
          onButtonClick={handleModalConfirmSwitchService}
        />
      )}
      {/* Swtich Reason Modal */}
      {isModalOpenSwitchReason && (
        <ConfirmationModal
          open={isModalOpenSwitchReason}
          onClose={handleModalCloseSwitchReason}
          title="Change Status"
          text="Are you sure you want to change this Reason Status?"
          onButtonClick={handleModalConfirmSwitchReason}
        />
      )}
      {/* Swtich Features Modal */}
      {isModalOpenSwitchFeatures && (
        <ConfirmationModal
          open={isModalOpenSwitchFeatures}
          onClose={handleModalCloseSwitchFeatures}
          title="Change Status"
          text="Are you sure you want to change this Feature Status?"
          onButtonClick={handleModalConfirmSwitchFeatures}
        />
      )}

      {/* Swtich Quoto Modal */}
      {isModalOpenSwitchQuoto && (
        <ConfirmationModal
          open={isModalOpenSwitchQuoto}
          onClose={handleModalCloseSwitchQuoto}
          title="Change Status"
          text="Are you sure you want to change this Service Quoto Status?"
          onButtonClick={handleModalConfirmSwitchQuoto}
        />
      )}
    </div>
  );
};

export default Catalog;
