import {
    FormControlLabel,
    Grid, Switch
  } from "@mui/material";
  import React ,{ useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  
  
  const ManageCountry = ({ open, setOpen, title, onButtonClick }) => {
    const [Tag, setTag] =useState("");
    const [TagOptions, setTagOptions] = useState(["tag 1","tag 2","tag 3","tag 4"]);
    const [name, setName] = useState("");
    const [ParentID, setParentID] = useState("");
    const [Country, setCountry] =useState("");
    const [CountryOptions, setCountryOptions] = useState(["Canada","Lebanon","China","Brasil"]);
    const [IsoCode, setIsoCode] = useState("");
    const [Zone, setZone] = useState("");
    const [Mcc, setMcc] = useState("");
    const [IsoCode3, setIsoCode3] = useState("");
    const [TimeZoneID, setTimeZoneID] = useState("");
    const [switchState, setSwitchState] = useState(false);
    
  
    const handleSwitchChange = () => {
      setSwitchState(!switchState);
    };
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
  
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Tag} setValue={setTag}  options={TagOptions} placeholder={"Tag"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Parent ID"}
                  value={ParentID}
                  setValue={setParentID}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Country} setValue={setCountry}  options={CountryOptions} placeholder={"Country"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Iso Code"}
                  value={IsoCode}
                  setValue={setIsoCode}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Zone"}
                  value={Zone}
                  setValue={setZone}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Mcc"}
                  value={Mcc}
                  setValue={setMcc}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Iso Code 3"}
                  value={IsoCode3}
                  setValue={setIsoCode3}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Time Zone ID"}
                  value={TimeZoneID}
                  setValue={setTimeZoneID}
                />
              </Grid>
            </Grid>
            <span style={{color:"#B3B3B3",fontSize:"15px"}}>Status</span>
            <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Switch checked={switchState} onChange={handleSwitchChange} />}
                      label={switchState ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageCountry;
  