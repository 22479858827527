import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Breadcrumbs,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Typography from "@mui/material/Typography";
import axios from "axios";
import { useFormik } from "formik";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InfoIcon from "@mui/icons-material/Info";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PersonIcon from "@mui/icons-material/Person";

import {
  HandleApiError,
  removeEmptyVariables,
  removeNullKeys,
  updateState,
} from "../../../Utils/functions";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_CLIENT,
  ADD_PROVIDER,
  EDIT_PROVIDER,
  GET_ALL_CLIENTS,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_CRITERIA_SPECIFIC,
  GET_ALL_CTIES,
  GET_ALL_INDUSTRIES,
  GET_ALL_INDUSTRY,
  GET_ALL_PROVIDERS,
  GET_MNPHLR_BY_ID,
  GET_PROVIDER_ID,
  GET_SMTP_BY_ID,
  GET_SMPP_BY_ID,
  LOOKUP_CONFIG,
  MULTI_TENANCY_CONFIG,
  NON_ADMIN_MEMBER_URL,
  SMPP_CONFIG,
  SMTP_CONFIG,
  WHATSAPP_CONFIG,
} from "../../APIs";
import DynamicField from "./DynamicFields";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import {
  validationSchema,
  validationSchemaAdd,
  validationSchemaEdit,
  validationSchemaLookupConfig,
  validationSchemaSmppConfig,
  validationSchemaSmtpConfig,
  validationSchemaWhatsappConfig,
} from "./Validation";

const steps = [
  {
    label: "Provider Details",
  },
  // {
  //   label: "Account Config",
  // },
  // {
  //   label: "Account Type",
  // },
  {
    label: "Address Info",
  },
  {
    label: "Engagement",
  },
];

export default function NewProvider({
  selectedProvider,
  isEdit,
  goToListing,
  isNewProvider,
}) {
  let services = localStorage.getItem("services");

  const navigate = useNavigate();

  // const [activeStep, setActiveStep] = useState(0);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [Countries, SetCountries] = useState([]);
  const [goBack, setGoBack] = useState(false);
  const [serviceLookupModeGuidOptions, setServiceLookupModeGuidOptions] =
    useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [Industries, SetIndustries] = useState([]);
  const [AccountManagers, SetAccountManagers] = useState([]);
  const [titleTagOptions, setTitleTagOptions] = useState([]);
  const [typeTagOptions, setTypeTagOptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [providerDetails, setProviderDetails] = useState("");
  const [smtpDetails, setSmtpDetails] = useState("");
  const [smppDetails, setSmppDetails] = useState("");
  const [selectedNewProvider, setSelectedNewProvider] = useState("");
  const [selectedNewProviderData, setSelectedNewProviderData] = useState("");

  const [mnphlrDetails, setMnphlrDetails] = useState("");
  const [showConfigPopup, setShowConfigPopup] = useState(false);

  const [billingTagOptions, setBillingTagOptions] = useState([]);
  const [connectionModeOptions, setConnectionModeOptions] = useState([
    { label: "None", value: 0 },
    { label: "Transmitter", value: 1 },
    { label: "Receiver", value: 2 },
    { label: "Transceiver", value: 3 },
  ]);

  const [contactTypeOptions, setContactTypeOptions] = useState([]);

  const [switchState, setSwitchState] = useState(false);
  const [switchRegisteredDelivery, setSwitchRegisteredDelivery] =
    useState(false);
  const [switchCanBind, setSwitchCanBind] = useState(false);
  const [switchOperator, setSwitchOperator] = useState(false);

  const [goToConfigPage, setGoToConfigPage] = useState(
    selectedProvider ? true : false
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordSmtp, setShowPasswordSmtp] = useState(false);

  const [industryOptions, setIndustryOptions] = useState([]);
  const [initialStateProvider, setInitialStateProvider] = useState({
    //Details
    TitleTag: "",
    TypeTag: "",
    BillingTag: "",
    // Username: "",
    // Password: "",
    // Templatekey: "",
    //Contacts
    TelephoneNumber: "",
    MobileNumber: "",
    Email: "",
    CountryGuid: "",
    ContactType: "",
    FirstName: "",
    LastName: "",
    CompanyWebsite: "",
    CompanyEmail: "",
    //Address
    Street: "",
    CityId: "",
    Region: "",
    State: "",
    Zip: "",
    Building: "",
    Floor: "",
    RoomNumber: "",
    Address: "",
    //Info
    EngagementEmail: "",
    SupportEmail: "",
    BillingEmail: "",
    TechnicalEmail: "",
    AlertsEmail: "",
    IndustryGuid: "",
    BusinessWebUrl: "",
    //Configuration
    //Config
    ParameterName: "",
    ParameterValue: "",
    ParameterType: "",
    //SmtpConfiguration
    SmtpServer: "",
    SmtpUser: "",
    SmtpPassword: "",
    SmtpPort: "",
    SmtpSsl: "",
    //SmppConfiguration
    UsernameSmpp: "",
    PasswordSmpp: "",
    ShortLongCode: "",
    TextPattern: "",
    IpAddress: "",
    PortAddress: "",
    ServiceType: "",
    SubmitType: "",
    SourceTon: "",
    SourceNpi: "",
    DestinationTon: "",
    DestinationNpi: "",
    ShortCodeTonNpi: "",
    MaximumRetry: "",
    MaximumConnections: "",
    ConnectionToOpen: "",
    WindowSize: "",
    EnquireLink: "",
    SubmitPerSecond: "",
    ClientSubmitPerSecond: "",
    QueueToSend: "",
    ConnectionMode: "",
    Location: "",
    GatewayInstance: "",
    GatewayInstanceType: "",
    TranslationPrefix: "",
    Comment: "",
    //WhatsappConfiguration
    HostWhatsapp: "",
    PortWhatsapp: "",
    UserWhatsapp: "",
    PasswordWhatsapp: "",
    //ViberConfiguration
    HostViber: "",
    PortViber: "",
    UserViber: "",
    PasswordViber: "",
    //LookupConfiguration
    ConnectivityType: "",
    ServiceLookupMode: "",
    NumberOfThreads: "",
    Ip: "",
    HostLookup: "",
    PortLookup: "",
    QueryDns: "",
    Info: {
      IndustryTag: null,
      // "IndustryTag": "54c8ddb8-6bc1-4c95-a24a-f2cc2ec2b6f1"
    },
    accountType: null,

    alertemail: false,
    "Allow number lookup customization": false,
    "Allow viber customization": false,
    "Allow sms rate customization": false,
    "Allow sign up": false,
    "Alerts via email": "",

    BillingEmail: null,
    TechnicalEmail: null,
    AlertsEmail: null,
    logoUrl: null,
    BusinessWebUrl: "",
  });

  const [Cities, SetCities] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [providerOptions, setProviderOptions] = useState([
    { label: "SMS", value: "2222" },
    { label: "HLR", value: "1111" },
    { label: "MNP", value: "444" },
  ]);
  const [countryOptions, setCountryOptions] = useState([
    { label: "Country 1", value: "Country 1" },
    { label: "Country 2", value: "Country 2" },
  ]);
  const [connectivityTypeOptions, setConnectivityTypeOptions] = useState([
    { label: "HTTP", value: "HTTP" },
    { label: "List", value: "List" },
  ]);
  const [serviceLookupModeOptions, setServiceLookupModeOptions] = useState([
    { label: "Request Per SMS", value: "Request Per SMS" },
    { label: "Database Update", value: "Database Update" },
  ]);
  const [GlobalData, SetGlobalData] = useState({
    steps: [
      {
        label: "Provider Details",
      },
      // {
      //   label: "Account Config",
      // },
      // {
      //   label: "Account Type",
      // },
      {
        label: "Address Info",
      },
      {
        label: "Engagement",
      },
    ],
    activeStep: 0,
  });
  const { steps, activeStep } = GlobalData;

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep !== 0) {
      SetGlobalData(updateState(GlobalData, "activeStep", activeStep - 1));
    }
  };
  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };

  const handleSwitchChangeRegisteredDelivery = () => {
    setSwitchRegisteredDelivery(!switchRegisteredDelivery);
  };
  const handleSwitchChangeCanBind = () => {
    setSwitchCanBind(!switchCanBind);
  };
  const handleSwitchChangeOperator = () => {
    setSwitchOperator(!switchOperator);
  };

  const getAllCities = (country) => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_CTIES}?CountryGuid=${country}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        SetCities(res?.data?.data?.cities);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCities(country);
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const GET_ALL_COUNTRIES_API = async () => {
    try {
      return await axiosInstance.get(
        `/configuration/api/v1/Country/get-all?PageIndex=1&pageSize=400`
      );
    } catch (e) {
      if (e == "error: 401")
        return await axiosInstance.get(
          `/configuration/api/v1/Country/get-all?PageIndex=1&pageSize=400`
        );
      else throw e;
    }
  };
  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=100`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        SetCountries(res?.data?.data?.countries);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const GET_ALL_USERS_API = async ({
    type,
    search = null,
    pageSize = 5,
    pageNumber = 1,
  }) => {
    MULTI_TENANCY_CONFIG.headers.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    // Create a URL with or without the searchCriteria parameter
    let url = `${NON_ADMIN_MEMBER_URL}/user/get-client-users?PageIndex=${pageNumber}&PageSize=${pageSize}&Typetag=${type}`;
    if (search) {
      url += `&search=${encodeURIComponent(search)}`;
    }
    return await axios.get(url, MULTI_TENANCY_CONFIG);
  };
  const getAllAccountManager = async () => {
    try {
      const userResponse = await GET_ALL_USERS_API({
        pageSize: 10000,
        pageNumber: -1,
        type: "ACCOUNT_MANAGER",
      });

      SetAccountManagers(userResponse?.data?.data?.users);
    } catch (e) {
      HandleApiError(e);
    }
  };

  const GetAllIndustries = () => {
    let newServices = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${GET_ALL_INDUSTRY}?PageIndex=0&PageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        SetIndustries(
          res?.data?.data?.industries?.map((item) => {
            return { name: item?.details[0].name, id: item?.recordGuid };
          })
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          GetAllIndustries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getAllCountries();
    GetAllIndustries();
    getAllAccountManager();
    getAllIndustries();
    getAllCriteria("contact type");
    getAllCriteria("type tag");
    getAllCriteria("billing tag");
    getAllCriteria("title tag");
    getAllCriteria("connectivity type");
    getAllCriteria("service lookup mode");
  }, []);
  //   React.useEffect(() => {
  //     console.log("asfsafasfsafselectedProvider",selectedProvider)
  //  setInitialStateProvider(
  //   {}

  //  )

  //   }, [selectedProvider]);
  const getAllCriteria = (name) => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let data = {};

    if (name === "title tag") {
      data = {
        categoryTags: ["CLIENT_TITLE"],
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${GET_ALL_CRITERIA_SPECIFIC}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setTitleTagOptions(res?.data?.data?.criteria);
        })
        .catch((err) => {
          if (err === "error: 401") {
            getAllCriteria(name);
          }
          // toast.error(
          //   err?.response?.data?.message ??
          //     "An Error Ocurred Retrieving Countries"
          // );
        })
        .finally(() => {
          // setState({ loading: false, resetPagination: false });
        });
    }
    if (name === "billing tag") {
      data = {
        categoryTags: ["BILLING_TYPE"],
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${GET_ALL_CRITERIA_SPECIFIC}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setBillingTagOptions(res?.data?.data?.criteria);
        })
        .catch((err) => {
          if (err === "error: 401") {
            getAllCriteria(name);
          }
          // toast.error(
          //   err?.response?.data?.message ??
          //     "An Error Ocurred Retrieving Countries"
          // );
        })
        .finally(() => {
          // setState({ loading: false, resetPagination: false });
        });
    }
    if (name === "type tag") {
      data = {
        categoryTags: ["PROVIDER_TYPE"],
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${GET_ALL_CRITERIA_SPECIFIC}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setTypeTagOptions(res?.data?.data?.criteria);
        })
        .catch((err) => {
          if (err === "error: 401") {
            getAllCriteria(name);
          }
          // toast.error(
          //   err?.response?.data?.message ??
          //     "An Error Ocurred Retrieving Countries"
          // );
        })
        .finally(() => {
          // setState({ loading: false, resetPagination: false });
        });
    }
    if (name === "contact type") {
      data = {
        categoryTags: ["CONTACT_TYPE"],
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${GET_ALL_CRITERIA_SPECIFIC}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setContactTypeOptions(res?.data?.data?.criteria);
        })
        .catch((err) => {
          if (err === "error: 401") {
            getAllCriteria(name);
          }
          // toast.error(
          //   err?.response?.data?.message ??
          //     "An Error Ocurred Retrieving Countries"
          // );
        })
        .finally(() => {
          // setState({ loading: false, resetPagination: false });
        });
    }

    if (name === "service lookup mode") {
      data = {
        categoryTags: ["SERVICE_LOOKUP_MODE"],
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${GET_ALL_CRITERIA_SPECIFIC}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setServiceLookupModeGuidOptions(res?.data?.data?.criteria);
        })
        .catch((err) => {
          if (err === "error: 401") {
            getAllCriteria(name);
          }
          // toast.error(
          //   err?.response?.data?.message ??
          //     "An Error Ocurred Retrieving Countries"
          // );
        })
        .finally(() => {
          // setState({ loading: false, resetPagination: false });
        });
    }

    if (name === "connectivity type") {
      data = {
        categoryTags: ["CONNECTIVITY_TYPE"],
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${GET_ALL_CRITERIA_SPECIFIC}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setConnectivityTypeOptions(res?.data?.data?.criteria);
        })
        .catch((err) => {
          if (err === "error: 401") {
            getAllCriteria(name);
          }
          // toast.error(
          //   err?.response?.data?.message ??
          //     "An Error Ocurred Retrieving Countries"
          // );
        })
        .finally(() => {
          // setState({ loading: false, resetPagination: false });
        });
    }
  };
  React.useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);
  React.useEffect(() => {
    if (goBack) {
      // setTimeout(() => {
      //   setOpenAddClientModal(false);
      // }, 3000);
    }
  }, [goBack]);
  const circleStyle = {
    width: 35, // Set the desired width
    height: 35, // Set the desired height
    borderRadius: "50%", // Ensures a circular shape
    fontSize: "0.95rem!important",
  };

  const submitAdd = ({ formData }) => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      ...formData,
      RecordGuid:
        selectedNewProvider !== ""
          ? selectedNewProvider
          : selectedProvider?.recordGuid,
    };
    if (selectedProvider) {
      axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            newServices["member"]
          }${EDIT_PROVIDER}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          // setShowPopup(true);
          setOpenMessageAlert(true);

          setMessageData({
            message: "Successfully Updated !",
            type: "success",
          });
          setTimeout(goToListing, 2000); // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAdd({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    } else {
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["member"]
          }${ADD_PROVIDER}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setShowPopup(true);
          setOpenMessageAlert(true);
          console.log(
            "setSelectedNewProvider",
            data?.data?.provider?.recordGuid
          );
          setSelectedNewProvider(data?.data?.provider?.recordGuid);
          setSelectedNewProviderData(data?.data?.provider);

          setMessageData({
            message: data?.message || "Successfully Added",
            type: "success",
          });
          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAdd({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    }
  };

  const ADD_NEW_CLIENT = async ({ formData }) => {
    try {
      return await axiosInstance.post(`${ADD_CLIENT}`);
    } catch (e) {
      if (e == "error: 401")
        return await axiosInstance.post(`${ADD_CLIENT}`, formData);
      else throw e;
    }
  };
  const GET_ALL_CITIES_API = async ({ country }) => {
    try {
      return await axiosInstance.get(
        `/configuration/api/v1/city/get-all?countryIso=${country}`
      );
    } catch (e) {
      if (e == "error: 401")
        return await axiosInstance.get(
          `/configuration/api/v1/city/get-all?countryIso=${country}`
        );
      else throw e;
    }
  };

  const getAllIndustries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_INDUSTRIES}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIndustryOptions(res?.data?.data?.industries);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllIndustries();
        }
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const submitAddLookupConfig = ({ formData }) => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    if (mnphlrDetails) {
      let data = { ...formData, RecordGuid: mnphlrDetails?.recordGuid };
      axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            newServices["configuration"]
          }${LOOKUP_CONFIG}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setShowConfigPopup(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data.message || "Successfully Update.",
            type: "success",
          });
          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAddLookupConfig({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    } else {
      let data = {
        ...formData,
        ProviderGuid:
          selectedNewProvider !== ""
            ? selectedNewProvider
            : selectedProvider?.recordGuid,
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["configuration"]
          }${LOOKUP_CONFIG}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setShowConfigPopup(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data.message || "Successfully Added !",
            type: "success",
          });
          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAddLookupConfig({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    }
  };

  const submitAddSmtp = ({ formData }) => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };

    console.log("formData", formData);
    let data = { ...formData, smtpConfigurationGuid: smtpDetails?.recordGuid };
    console.log("dataaa", data);

    if (smtpDetails) {
      axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            newServices["configuration"]
          }${SMTP_CONFIG}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setShowConfigPopup(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data.message || "Successfully Updated.",
            type: "success",
          });
          // formikSmtpConfiguration.resetForm();

          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAddSmtp({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    } else {
      let data = {
        ...formData,
        ProviderGuid:
          selectedNewProvider !== ""
            ? selectedNewProvider
            : selectedProvider?.recordGuid,
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["configuration"]
          }${SMTP_CONFIG}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setShowConfigPopup(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data.message || "Successfully Added.",
            type: "success",
          });
          // formikSmtpConfiguration.resetForm();

          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAddSmtp({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    }
  };

  const submitAddSmpp = ({ formData }) => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };

    if (smppDetails) {
      let data = {
        ...formData,
        RecordGuid: smppDetails?.recordGuid,
        AccountMode: 1,
        AccountType: 1,
      };
      axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            newServices["configuration"]
          }${SMPP_CONFIG}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setShowConfigPopup(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data.message || "Successfully Updated.",
            type: "success",
          });
          // formikSmtpConfiguration.resetForm();

          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAddSmpp({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    } else {
      let data = {
        ...formData,
        ProviderGuid:
          selectedNewProvider !== ""
            ? selectedNewProvider
            : selectedProvider?.recordGuid,
        AccountMode: 1,
        AccountType: 1,
      };

      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["configuration"]
          }${SMPP_CONFIG}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          // setGoToConfigPage(true);
          setShowConfigPopup(true);
          setOpenMessageAlert(true);
          setMessageData({
            message: data.message || "Successfully Added.",
            type: "success",
          });
          // formikSmtpConfiguration.resetForm();

          // setGoBack(true);
          // setTimeout(setOpenAddClientModal(false), 4000);
          // setRefreshTableClient(true);
        })
        .catch((err) => {
          if (err === "error: 401") {
            submitAddSmpp({ formData });
          } else {
            setOpenMessageAlert(true);
            setMessageData({
              message: err?.response?.data?.Message
                ? err?.response?.data?.Message
                : err?.response?.data?.message,
              type: "error",
            });
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: initialStateProvider,
    validationSchema: selectedProvider
      ? validationSchemaEdit[activeStep]
      : validationSchemaAdd[activeStep],
    onSubmit: async (values) => {
      let dataAdd = {
        TitleTag: values["TitleTag"],
        TypeTag: values["TypeTag"],
        BillingTag: values["BillingTag"],

        Contacts: [
          {
            TelephoneNumber: values["TelephoneNumber"],
            MobileNumber: values["MobileNumber"],
            Email: values["Email"],
            CountryGuid: values["CountryGuid"],
            ContactType: values["ContactType"],
            FirstName: values["FirstName"],
            LastName: values["LastName"],
            CompanyWebsite: values["CompanyWebsite"],
            CompanyEmail: values["CompanyEmail"],
          },
        ],
        Addresses: [
          {
            Region: values["Region"] || null,
            Address1: values["Address"] || null,
            Street: values["Street"] || null,
            cityGuid: values["CityId"] || null,

            State: values["State"] || null,
            Zip: values["Zip"] || null,
            Building: values["Building"] || null,
            Floor: values["Floor"] || null,
            RoomNumber: values["RoomNumber"] || null,
          },
        ],
        Info: {
          // IndustryTag: values?.Info?.IndustryTag,
          EngagementEmail: values?.EngagementEmail,
          SupportEmail: values?.SupportEmail,
          BillingEmail: values?.BillingEmail,
          TechnicalEmail: values?.TechnicalEmail,
          AlertsEmail: values?.AlertsEmail,
          IndustryGuid: values?.IndustryGuid,
          BusinessWebUrl: values?.BusinessWebUrl,
          // AccountManagerGuid: values?.account_manager,
        },
      };
      let dataEdit = {
        titleTag: values["TitleTag"],
        typeTag: values["TypeTag"],
        firstName: values["FirstName"],
        lastName: values["LastName"],

        // Contacts: [
        //   {
        //     TelephoneNumber: values["TelephoneNumber"],
        //     MobileNumber: values["MobileNumber"],
        //     Email: values["Email"],
        //     CountryGuid: values["CountryGuid"],
        //     ContactType: values["ContactType"],
        //     FirstName: values["FirstName"],
        //     LastName: values["LastName"],
        //     CompanyWebsite: values["CompanyWebsite"],
        //     CompanyEmail: values["CompanyEmail"],
        //   },
        // ],
        address: {
          address1: values["Address"] || null,
          street: values["Street"] || null,
          cityGuid: values["CityId"] || null,
          region: values["Region"] || null,
          state: values["State"] || null,
          zip: values["Zip"] || null,
          building: values["Building"] || null,
          floor: values["Floor"] || null,
          roomNumber: values["RoomNumber"] || null,
        },
        info: {
          // IndustryTag: values?.Info?.IndustryTag,
          engagementEmail: values?.EngagementEmail,
          supportEmail: values?.SupportEmail,
          billingEmail: values?.BillingEmail,
          technicalEmail: values?.TechnicalEmail,
          alertsEmail: values?.AlertsEmail,
          industryTag: values?.IndustryGuid,
          businessWebUrl: values?.BusinessWebUrl,

          // AccountManagerGuid: values?.account_manager,
        },
      };
      if (activeStep === 2) {
        try {
          let recordResponse = await submitAdd({
            formData: removeEmptyVariables(
              selectedProvider ? dataEdit : dataAdd
            ),
          });
          if (recordResponse?.data?.success) {
            // showSnackbar("Client added Successfully", "success");
            // Notification?.success("Reseller added Successfully");+
            // SetGlobalData(updateState(GlobalData, "path", "main"));
            // history?.push("/client/listing");
          }
        } catch (e) {
          // showSnackbar(handleMessageError({e, type: "validation"}), "error");
        }
      } else {
        SetGlobalData(updateState(GlobalData, "activeStep", activeStep + 1));
      }
    },
  });
  const formikLookupConfiguration = useFormik({
    initialValues: {
      ConnectivityTypeGuid: "",
      ServiceLookupModeGuid: "",
      NumberOfThreads: "",
      Ip: "",
      Host: "",
      Port: "",
      QueryDns: "",
    },
    validationSchema: validationSchemaLookupConfig,
    onSubmit: async (values) => {
      let data = {
        ConnectivityTypeTag: values["ConnectivityTypeGuid"],
        ServiceLookupModeTag: values["ServiceLookupModeGuid"],
        NumberOfThreads: values["NumberOfThreads"],
        Ip: values["Ip"],
        Host: values["Host"],
        Port: values["Port"],
        QueryDns: values["QueryDns"],
      };
      console.log("avasvasfdsafasfasf", data);
      try {
        let recordResponse = await submitAddLookupConfig({
          formData: removeNullKeys(data),
        });
        if (recordResponse?.data?.success) {
          // showSnackbar("Client added Successfully", "success");
          // Notification?.success("Reseller added Successfully");+
          // SetGlobalData(updateState(GlobalData, "path", "main"));
          // history?.push("/client/listing");
        }
      } catch (e) {
        console.log("aaaaaaaaaaaaaaaaaaaaaas");

        // showSnackbar(handleMessageError({e, type: "validation"}), "error");
      }
      // } else {
      //   // handleNext()
      //   SetGlobalData(updateState(GlobalData, "activeStep", activeStep + 1));
      // }
    },
  });

  const formikSmtpConfiguration = useFormik({
    initialValues: {
      SmtpServer: "",
      SmtpUser: "",
      SmtpPassword: "",
      SmtpPort: "",
      SmtpSsl: "",
      FromAddress: "",
      ReplyAddress: "",
      ProviderGuid: "",
    },
    validationSchema: validationSchemaSmtpConfig,
    onSubmit: async (values) => {
      let data = {
        SmtpServer: values["SmtpServer"],
        SmtpUser: values["SmtpUser"],
        SmtpPassword: values["SmtpPassword"],
        SmtpPort: values["SmtpPort"],
        SmtpSsl: switchState,
        FromAddress: values["FromAddress"],
        ReplyAddress: values["ReplyAddress"],
      };
      console.log("avasvasfdsafasfasf", data);
      try {
        let recordResponse = await submitAddSmtp({
          formData: removeNullKeys(data),
        });
        if (recordResponse?.data?.success) {
          // showSnackbar("Client added Successfully", "success");
          // Notification?.success("Reseller added Successfully");+
          // SetGlobalData(updateState(GlobalData, "path", "main"));
          // history?.push("/client/listing");
        }
      } catch (e) {
        console.log("aaaaaaaaaaaaaaaaaaaaaas");

        // showSnackbar(handleMessageError({e, type: "validation"}), "error");
      }
      // } else {
      //   // handleNext()
      //   SetGlobalData(updateState(GlobalData, "activeStep", activeStep + 1));
      // }
    },
  });

  const formikSmppConfiguration = useFormik({
    initialValues: {
      Username: "",
      Password: "",
      IpAddress: "",
      SourceTon: "",
      SourceNpi: "",
      DestinationTon: "",
      DestinationNpi: "",
      ShortCodeTonNpi: "",
      MaximumRetry: "",
      ConnectionToOpen: "",
      EnquireLink: "",
      SubmitPerSecond: "",
      ConnectionMode: "",
      TranslationPrefix: "",
      IsRegisteredDelivery: "",
      CanBind: "",
      AccountType: "",
      IsOperatorEncoding: "",
      AccountMode: "",
    },
    validationSchema: validationSchemaSmppConfig,
    onSubmit: async (values) => {
      let data = {
        Username: values["Username"],
        Password: values["Password"],
        IpAddress: values["IpAddress"],
        SourceTon: values["SourceTon"],
        SourceNpi: values["SourceNpi"],
        DestinationTon: values["DestinationTon"],
        DestinationNpi: values["DestinationNpi"],
        ShortCodeTonNpi: values["ShortCodeTonNpi"],
        MaximumRetry: values["MaximumRetry"],
        ConnectionToOpen: values["ConnectionToOpen"],
        EnquireLink: values["EnquireLink"],
        SubmitPerSecond: values["SubmitPerSecond"],
        ConnectionMode: values["ConnectionMode"],
        TranslationPrefix: values["TranslationPrefix"],
        IsRegisteredDelivery: switchRegisteredDelivery,
        CanBind: switchCanBind,
        AccountType: values["AccountType"],
        IsOperatorEncoding: switchOperator,
        AccountMode: values["AccountMode"],
      };
      try {
        let recordResponse = await submitAddSmpp({
          formData: removeNullKeys(data),
        });
        if (recordResponse?.data?.success) {
          // showSnackbar("Client added Successfully", "success");
          // Notification?.success("Reseller added Successfully");+
          // SetGlobalData(updateState(GlobalData, "path", "main"));
          // history?.push("/client/listing");
        }
      } catch (e) {
        console.log("aaaaaaaaaaaaaaaaaaaaaas");

        // showSnackbar(handleMessageError({e, type: "validation"}), "error");
      }
      // } else {
      //   // handleNext()
      //   SetGlobalData(updateState(GlobalData, "activeStep", activeStep + 1));
      // }
    },
  });

  const formikWhatsapp = useFormik({
    initialValues: {
      WhatsappToken: "",
      OwnerBusinessId: "",
      OwnerBusinessName: "",
      CampaignLimit: "",
    },
    validationSchema: validationSchemaWhatsappConfig,
    onSubmit: async (values) => {
      let data = {
        WhatsappToken: values?.WhatsappToken,
        OwnerBusinessId: values?.OwnerBusinessId,
        OwnerBusinessName: values?.OwnerBusinessName,
        CampaignLimit: values?.CampaignLimit,
      };
      try {
        let recordResponse = await submitAddWhatsapp({
          formData: removeNullKeys(data),
        });
        if (recordResponse?.data?.success) {
          // showSnackbar("Client added Successfully", "success");
          // Notification?.success("Reseller added Successfully");+
          // SetGlobalData(updateState(GlobalData, "path", "main"));
          // history?.push("/client/listing");
        }
      } catch (e) {
        // showSnackbar(handleMessageError({e, type: "validation"}), "error");
      }
      // } else {
      //   // handleNext()
      //   SetGlobalData(updateState(GlobalData, "activeStep", activeStep + 1));
      // }
    },
  });

  const getProviderById = (recordGuid) => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["members"]
        }${GET_PROVIDER_ID}?providerGuid=${recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setProviderDetails(res?.data?.data?.provider);
        let provider = res?.data?.data?.provider;
        formik.setFieldValue("TitleTag", provider?.titleTag);
        formik.setFieldValue("TypeTag", provider?.TypeTag);
        formik.setFieldValue("BillingTag", provider?.BillingTag);
        formik.setFieldValue(
          "TelephoneNumber",
          provider?.contact[0]?.telephoneNumber
        );
        formik.setFieldValue(
          "MobileNumber",
          provider?.contact[0]?.mobileNumber
        );
        formik.setFieldValue("FirstName", provider?.contact[0]?.firstName);
        formik.setFieldValue("LastName", provider?.contact[0]?.lastName);
        formik.setFieldValue("Email", provider?.contact[0]?.email);
        formik.setFieldValue(
          "CompanyEmail",
          provider?.contact[0]?.companyEmail
        );
        formik.setFieldValue(
          "CompanyWebsite",
          provider?.contact[0]?.companyWebsite
        );
        formik.setFieldValue(
          "CountryGuid",
          provider?.contact[0]?.countryRecordGuid
        );
        formik.setFieldValue(
          "ContactType",
          provider?.contact[0]?.contactTypeTag
        );
        formik.setFieldValue(
          "EngagementEmail",
          provider?.providerInfo?.engagementEmail
        );
        formik.setFieldValue(
          "SupportEmail",
          provider?.providerInfo?.supportEmail
        );
        formik.setFieldValue(
          "BillingEmail",
          provider?.providerInfo?.billingEmail
        );
        formik.setFieldValue(
          "TechnicalEmail",
          provider?.providerInfo?.technicalEmail
        );
        formik.setFieldValue(
          "AlertsEmail",
          provider?.providerInfo?.alertsEmail
        );
        formik.setFieldValue(
          "IndustryGuid",
          provider?.providerInfo?.industryRecordGuid
        );
        formik.setFieldValue(
          "BusinessWebUrl",
          provider?.providerInfo?.businessWebUrl
        );
        formik.setFieldValue("Address", provider?.addresses[0]?.address1);
        formik.setFieldValue("Street", provider?.addresses[0]?.street);
        formik.setFieldValue("CityId", provider?.addresses[0]?.cityRecordGuid);
        formik.setFieldValue("Region", provider?.addresses[0]?.region);
        formik.setFieldValue("State", provider?.addresses[0]?.state);
        formik.setFieldValue("Zip", provider?.addresses[0]?.zip);
        formik.setFieldValue("Building", provider?.addresses[0]?.building);
        formik.setFieldValue("Floor", provider?.addresses[0]?.floor);
        formik.setFieldValue("RoomNumber", provider?.addresses[0]?.roomNumber);

        // setInitialStateProvider(
        //   {
        //     //Details
        //     TitleTag: res?.data?.data?.provider?.titleId,
        //     TypeTag: res?.data?.data?.provider?.typeTag,
        //     BillingTag: "",
        //     // Username: "",
        //     // Password: "",
        //     // Templatekey: "",
        //     //Contacts
        //     TelephoneNumber: res?.data?.data?.provider?.telephoneNumber,
        //     MobileNumber: res?.data?.data?.provider?.mobileNumber,
        //     Email: res?.data?.data?.provider?.email,
        //     CountryGuid: "",
        //     ContactType: "",
        //     FirstName: res?.data?.data?.provider?.firstName,
        //     LastName: res?.data?.data?.provider?.lastName,
        //     CompanyWebsite: "",
        //     CompanyEmail: "",
        //     //Address
        //     Street: "",
        //     CityId: "",
        //     Region: "",
        //     State: "",
        //     Zip: "",
        //     Building: "",
        //     Floor: "",
        //     RoomNumber: "",
        //     Address: "",
        //     //Info
        //     EngagementEmail: "",
        //     SupportEmail: "",
        //     BillingEmail: "",
        //     TechnicalEmail: "",
        //     AlertsEmail: "",
        //     IndustryGuid: "",
        //     BusinessWebUrl: "",
        //     //Configuration
        //     //Config
        //     ParameterName: "",
        //     ParameterValue: "",
        //     ParameterType: "",
        //     //SmtpConfiguration
        //     SmtpServer: "",
        //     SmtpUser: "",
        //     SmtpPassword: "",
        //     SmtpPort: "",
        //     SmtpSsl: "",
        //     //SmppConfiguration
        //     UsernameSmpp: "",
        //     PasswordSmpp: "",
        //     ShortLongCode: "",
        //     TextPattern: "",
        //     IpAddress: "",
        //     PortAddress: "",
        //     ServiceType: "",
        //     SubmitType: "",
        //     SourceTon: "",
        //     SourceNpi: "",
        //     DestinationTon: "",
        //     DestinationNpi: "",
        //     ShortCodeTonNpi: "",
        //     MaximumRetry: "",
        //     MaximumConnections: "",
        //     ConnectionToOpen: "",
        //     WindowSize: "",
        //     EnquireLink: "",
        //     SubmitPerSecond: "",
        //     ClientSubmitPerSecond: "",
        //     QueueToSend: "",
        //     ConnectionMode: "",
        //     Location: "",
        //     GatewayInstance: "",
        //     GatewayInstanceType: "",
        //     TranslationPrefix: "",
        //     Comment: "",
        //     //WhatsappConfiguration
        //     HostWhatsapp: "",
        //     PortWhatsapp: "",
        //     UserWhatsapp: "",
        //     PasswordWhatsapp: "",
        //     //ViberConfiguration
        //     HostViber: "",
        //     PortViber: "",
        //     UserViber: "",
        //     PasswordViber: "",
        //     //LookupConfiguration
        //     ConnectivityType: "",
        //     ServiceLookupMode: "",
        //     NumberOfThreads: "",
        //     Ip: "",
        //     HostLookup: "",
        //     PortLookup: "",
        //     QueryDns: "",
        //     Info: {
        //       IndustryTag: null,
        //       // "IndustryTag": "54c8ddb8-6bc1-4c95-a24a-f2cc2ec2b6f1"
        //     },
        //     accountType: null,

        //     alertemail: false,
        //     "Allow number lookup customization": false,
        //     "Allow viber customization": false,
        //     "Allow sms rate customization": false,
        //     "Allow sign up": false,
        //     "Alerts via email": "",

        //     BillingEmail: null,
        //     TechnicalEmail: null,
        //     AlertsEmail: null,
        //     logoUrl: null,
        //     BusinessWebUrl: "",
        //   }
        // )

        // SetCountries(res?.data?.data?.countries);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getProviderById(recordGuid);
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  //MNPHLR= lookup configs
  const getMnpHlrById = (recordGuid) => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_MNPHLR_BY_ID}?ProviderGuid=${recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setMnphlrDetails(res?.data?.data?.items[0]);
        let mnphlrDetails = res?.data?.data?.items[0];

        formikLookupConfiguration.setFieldValue(
          "ConnectivityTypeGuid",
          mnphlrDetails?.connectivityType?.tag
        );
        formikLookupConfiguration.setFieldValue(
          "ServiceLookupModeGuid",
          mnphlrDetails?.serviceLookupMode?.tag
        );
        formikLookupConfiguration.setFieldValue(
          "NumberOfThreads",
          mnphlrDetails?.numberOfThreads
        );
        formikLookupConfiguration.setFieldValue("Ip", mnphlrDetails?.ip);
        formikLookupConfiguration.setFieldValue("Host", mnphlrDetails?.host);
        formikLookupConfiguration.setFieldValue("Port", mnphlrDetails?.port);
        formikLookupConfiguration.setFieldValue(
          "QueryDns",
          mnphlrDetails?.queryDns
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getProviderById(recordGuid);
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getSmtpById = (recordGuid) => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_SMTP_BY_ID}?ProviderGuid=${recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setSmtpDetails(res?.data?.data?.smtpConfigurations[0]);
        let smtpDetails = res?.data?.data?.smtpConfigurations[0];
        formikSmtpConfiguration.setFieldValue(
          "SmtpServer",
          smtpDetails?.smtpServer
        );
        formikSmtpConfiguration.setFieldValue(
          "SmtpUser",
          smtpDetails?.smtpUser
        );
        formikSmtpConfiguration.setFieldValue(
          "SmtpPassword",
          smtpDetails?.smtpPassword
        );
        formikSmtpConfiguration.setFieldValue(
          "SmtpPort",
          smtpDetails?.smtpPort
        );
        formikSmtpConfiguration.setFieldValue(
          "FromAddress",
          smtpDetails?.fromAddress
        );
        formikSmtpConfiguration.setFieldValue(
          "ReplyAddress",
          smtpDetails?.replyAddress
        );
        setSwitchState(smtpDetails?.smtpSsl);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getProviderById(recordGuid);
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getSmppById = (recordGuid) => {
    console.log("res?.data?.items[0]smpppp");

    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_SMPP_BY_ID}?ProviderGuid=${recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setSmppDetails(res?.data?.data?.items[0]);
        let smpp = res?.data?.data?.items[0];
        console.log("res?.data?.items[0]", res?.data?.data.items[0]);

        formikSmppConfiguration.setFieldValue("Username", smpp?.username);
        formikSmppConfiguration.setFieldValue("Password", smpp?.password);
        formikSmppConfiguration.setFieldValue("IpAddress", smpp?.ipAddress);
        formikSmppConfiguration.setFieldValue("SourceTon", smpp?.sourceTon);
        formikSmppConfiguration.setFieldValue("SourceNpi", smpp?.sourceNpi);
        formikSmppConfiguration.setFieldValue(
          "DestinationTon",
          smpp?.destinationTon
        );
        formikSmppConfiguration.setFieldValue(
          "DestinationNpi",
          smpp?.destinationNpi
        );
        formikSmppConfiguration.setFieldValue(
          "ShortCodeTonNpi",
          smpp?.shortCodeTonNpi
        );
        formikSmppConfiguration.setFieldValue(
          "MaximumRetry",
          smpp?.maximumRetry
        );
        formikSmppConfiguration.setFieldValue(
          "ConnectionToOpen",
          smpp?.connectionToOpen
        );
        formikSmppConfiguration.setFieldValue("EnquireLink", smpp?.enquireLink);
        formikSmppConfiguration.setFieldValue(
          "SubmitPerSecond",
          smpp?.submitPerSecond
        );
        formikSmppConfiguration.setFieldValue(
          "ConnectionMode",
          smpp?.connectionMode
        );
        formikSmppConfiguration.setFieldValue(
          "TranslationPrefix",
          smpp?.translationPrefix
        );
        formikSmppConfiguration.setFieldValue(
          "IsRegisteredDelivery",
          smpp?.isRegisteredDelivery
        );
        formikSmppConfiguration.setFieldValue("CanBind", smpp?.canBind);
        formikSmppConfiguration.setFieldValue("AccountType", smpp?.accountType);
        formikSmppConfiguration.setFieldValue(
          "IsOperatorEncoding",
          smpp?.isOperatorEncoding
        );
        formikSmppConfiguration.setFieldValue("AccountMode", smpp?.accountMode);
        setSwitchCanBind(smpp?.canBind);
        setSwitchRegisteredDelivery(smpp?.isRegisteredDelivery);
        setSwitchOperator(smpp?.isOperatorEncoding);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getProviderById(recordGuid);
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllClients = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${GET_ALL_CLIENTS}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        console.log("res?.data?.data?.clients", res?.data?.data?.clients);
        setClientOptions(res?.data?.data?.clients);
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllClients();
        }
      })
      .finally(() => {});
  };
  const submitAddWhatsapp = ({ formData }) => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = { ...formData, ProviderGuid: selectedProvider?.recordGuid };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${WHATSAPP_CONFIG}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // setGoToConfigPage(true);
        setShowPopup(true);
        setOpenMessageAlert(true);

        setMessageData({
          message: data?.message || "Successfully Added",
          type: "success",
        });

        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAddWhatsapp();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const getCities = async ({ country }) => {
    try {
      let citiesResponse = await GET_ALL_CITIES_API({ country });
      SetCities(citiesResponse?.data?.data?.cities);
    } catch (e) {
      HandleApiError(e);
    }
  };
  React.useEffect(() => {
    if (selectedProvider) {
      getProviderById(selectedProvider?.recordGuid);
    } else if (selectedNewProvider !== "") {
      getProviderById(selectedNewProvider);
    }
    localStorage.setItem("recordGuid", "");
    localStorage.setItem("goToNextPage", "false");
    // localStorage.setItem("username", "");

    getAllClients();
    getAllProviders();
  }, [selectedProvider, selectedNewProvider]);

  React.useEffect(() => {
    if (selectedProvider) {
      getMnpHlrById(selectedProvider?.recordGuid);
    }
  }, []);

  React.useEffect(() => {
    if (selectedProvider) {
      getSmtpById(selectedProvider?.recordGuid);
    }
  }, []);

  React.useEffect(() => {
    if (selectedProvider) {
      getSmppById(selectedProvider?.recordGuid);
    }
  }, []);

  React.useEffect(() => {
    if (isEdit && providerDetails !== "") {
      getAllCities(providerDetails?.contact[0]?.countryRecordGuid);
    }
  }, [providerDetails]);

  const getAllProviders = () => {
    // setSelectedRows([]);
    // setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let data = {
      pageIndex: 1,
      pageSize: 500,
      search: "",
      typeTag: "",
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_PROVIDERS}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setProviderOptions(res?.data?.data?.providers);
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProviders();
        }
      })
      .finally(() => {});
  };
  return (
    <>
      {!goToConfigPage || isEdit ? (
        <>
          <form onSubmit={formik?.handleSubmit}>
            <Grid
              style={{ marginBottom: "50px" }}
              item
              xs={12}
              md={6}
              className="centerresponsive"
            >
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <Typography
                  style={{
                    cursor: (selectedProvider || isNewProvider) && "pointer",
                  }}
                  className="BreadcrumbsPage"
                  onClick={(selectedProvider || isNewProvider) && goToListing}
                >
                  Providers
                </Typography>
                <Typography className="breadcrumbactiveBtn">
                  {selectedProvider
                    ? selectedProvider?.firstName
                    : "New Provider"}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={2}>
                <Box sx={{ display: "flex" }}>
                  <Stepper
                    sx={{
                      width: "100%",
                      height: windowWidth > 1000 ? "30px" : "65px",
                      "& .MuiStepConnector-line": {
                        height: "70px",
                        margin: "0px 0px 0px 5px",
                      },
                      "& .MuiStepIcon-root.Mui-completed ": {
                        color: "#c41035",
                      },
                      "& .MuiStepIcon-root.Mui-active": {
                        color: "#c41035 !important",
                      },
                    }}
                    alternativeLabel={windowWidth < 1000 ? true : false}
                    activeStep={activeStep}
                    orientation={windowWidth < 1000 ? "horizontal" : "vertical"}
                  >
                    {steps.map((step, index) => (
                      <Step
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (activeStep > index)
                            SetGlobalData(
                              updateState(GlobalData, "activeStep", index)
                            );
                        }}
                        key={step.label}
                      >
                        <StepLabel
                          StepIconProps={{
                            style: circleStyle,
                          }}
                          // optional={
                          //   index === 2 ? (
                          //     <Typography variant="caption">Last step</Typography>
                          //   ) : null
                          // }
                        >
                          {step.label}
                        </StepLabel>
                        <StepContent></StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>

              {activeStep === 0 && (
                <Grid
                  style={{
                    background: "white",
                    padding: "20px",
                    borderRadius: "25px",
                    marginTop: windowWidth < 1000 && "40px",
                  }}
                  item
                  lg={10}
                  xs={12}
                >
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isEdit && providerDetails === ""}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Typography>
                    <InfoIcon
                      style={{
                        color: "#C41035",
                        fontSize: "25px",
                        marginRight: "5px",
                        marginBottom: "-7px",
                      }}
                    />
                    Details
                  </Typography>

                  {!selectedProvider ? (
                    <>
                      <Grid
                        style={{ marginBottom: "10px", marginTop: "8px" }}
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Title
                            </span>

                            <FormControl
                              sx={{
                                width: 300,
                                borderRadius: "32px",
                                border:
                                  "1px solid rgb(224, 224, 224)!important",
                                width: "100%",
                              }}
                            >
                              <Select
                                key="TitleTag"
                                id="TitleTag" // Add an id for accessibility
                                name="TitleTag" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getAllCities(e?.target?.value);
                                }}
                                size="small"
                                // onBlur={formik.handleBlur}
                                value={formik.values.TitleTag}
                                labelId="TitleTag"
                              >
                                {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem> */}
                                {titleTagOptions?.map((TitleTag) => (
                                  <MenuItem value={TitleTag?.tag}>
                                    {TitleTag?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Type*
                            </span>

                            <FormControl
                              sx={{
                                width: 300,
                                borderRadius: "32px",
                                border:
                                  "1px solid rgb(224, 224, 224)!important",
                                width: "100%",
                              }}
                            >
                              <Select
                                key="TypeTag"
                                id="TypeTag" // Add an id for accessibility
                                name="TypeTag" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getAllCities(e?.target?.value);
                                }}
                                size="small"
                                // onBlur={formik.handleBlur}
                                value={formik.values.TypeTag}
                                labelId="TypeTag"
                              >
                                {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem> */}
                                {typeTagOptions?.map((TypeTag) => (
                                  <MenuItem value={TypeTag?.tag}>
                                    {TypeTag?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {formik.touched.TypeTag &&
                              formik.errors.TypeTag && (
                                <FormHelperText
                                  style={{
                                    color: "#D62F6B",
                                    marginLeft: "15px",
                                    position: "absolute",
                                  }}
                                >
                                  {formik.errors.TypeTag}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Account Type*
                            </span>

                            <FormControl
                              sx={{
                                width: 300,
                                borderRadius: "32px",
                                border:
                                  "1px solid rgb(224, 224, 224)!important",
                                width: "100%",
                              }}
                            >
                              <Select
                                key="BillingTag"
                                id="BillingTag" // Add an id for accessibility
                                name="BillingTag" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getAllCities(e?.target?.value);
                                }}
                                size="small"
                                // onBlur={formik.handleBlur}
                                value={formik.values.BillingTag}
                                labelId="BillingTag"
                              >
                                {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem> */}
                                {billingTagOptions?.map((BillingTag) => (
                                  <MenuItem value={BillingTag?.tag}>
                                    {BillingTag?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {formik.touched.BillingTag &&
                              formik.errors.BillingTag && (
                                <FormHelperText
                                  style={{
                                    color: "#D62F6B",
                                    marginLeft: "15px",
                                    position: "absolute",
                                  }}
                                >
                                  {formik.errors.BillingTag}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Contact Type*
                            </span>

                            <FormControl
                              sx={{
                                width: 300,
                                borderRadius: "32px",
                                border:
                                  "1px solid rgb(224, 224, 224)!important",
                                width: "100%",
                              }}
                            >
                              <Select
                                key="ContactType"
                                id="ContactType" // Add an id for accessibility
                                name="ContactType" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getAllCities(e?.target?.value);
                                }}
                                size="small"
                                // onBlur={formik.handleBlur}
                                value={formik.values.ContactType}
                                labelId="ContactType"
                              >
                                {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem> */}
                                {contactTypeOptions?.map(
                                  (ContactType, index) => (
                                    <MenuItem
                                      key={index}
                                      value={ContactType?.tag}
                                    >
                                      {ContactType?.name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                            {formik.touched.ContactType &&
                              formik.errors.ContactType && (
                                <FormHelperText
                                  style={{
                                    color: "#D62F6B",
                                    marginLeft: "15px",
                                    position: "absolute",
                                  }}
                                >
                                  {formik.errors.ContactType}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        style={{ marginBottom: "10px", marginTop: "8px" }}
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Telephone Number{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"TelephoneNumber"}
                                fullWidth
                                id={"TelephoneNumber"}
                                name={"TelephoneNumber"}
                                type={"text"}
                                value={formik.values["TelephoneNumber"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["TelephoneNumber"] &&
                                  Boolean(formik.errors["TelephoneNumber"])
                                }
                                helperText={
                                  formik.touched["TelephoneNumber"] &&
                                  formik.errors["TelephoneNumber"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Mobile Number*{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"MobileNumber"}
                                fullWidth
                                id={"MobileNumber"}
                                name={"MobileNumber"}
                                type={"text"}
                                value={formik.values["MobileNumber"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                inputProps={{ maxLength: 15 }} // Added maxLength attribute
                                error={
                                  formik.touched["MobileNumber"] &&
                                  Boolean(formik.errors["MobileNumber"])
                                }
                                helperText={
                                  formik.touched["MobileNumber"] &&
                                  formik.errors["MobileNumber"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Email*
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"Email"}
                                fullWidth
                                id={"Email"}
                                name={"Email"}
                                type={"Email"}
                                value={formik.values["Email"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["Email"] &&
                                  Boolean(formik.errors["Email"])
                                }
                                helperText={
                                  formik.touched["Email"] &&
                                  formik.errors["Email"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              First Name*{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"FirstName"}
                                fullWidth
                                id={"FirstName"}
                                name={"FirstName"}
                                type={"text"}
                                value={formik.values["FirstName"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["FirstName"] &&
                                  Boolean(formik.errors["FirstName"])
                                }
                                helperText={
                                  formik.touched["FirstName"] &&
                                  formik.errors["FirstName"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Last Name{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"LastName"}
                                fullWidth
                                id={"LastName"}
                                name={"LastName"}
                                type={"text"}
                                value={formik.values["LastName"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["LastName"] &&
                                  Boolean(formik.errors["LastName"])
                                }
                                helperText={
                                  formik.touched["LastName"] &&
                                  formik.errors["LastName"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Company Website{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"CompanyWebsite"}
                                fullWidth
                                id={"CompanyWebsite"}
                                name={"CompanyWebsite"}
                                type={"text"}
                                value={formik.values["CompanyWebsite"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["CompanyWebsite"] &&
                                  Boolean(formik.errors["CompanyWebsite"])
                                }
                                helperText={
                                  formik.touched["CompanyWebsite"] &&
                                  formik.errors["CompanyWebsite"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          lg={3}
                          style={{ marginBottom: "15px" }}
                        >
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Company Email{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"CompanyEmail"}
                                fullWidth
                                id={"CompanyEmail"}
                                name={"CompanyEmail"}
                                type={"text"}
                                value={formik.values["CompanyEmail"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["CompanyEmail"] &&
                                  Boolean(formik.errors["CompanyEmail"])
                                }
                                helperText={
                                  formik.touched["CompanyEmail"] &&
                                  formik.errors["CompanyEmail"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        style={{ marginBottom: "10px", marginTop: "8px" }}
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Title
                            </span>

                            <FormControl
                              sx={{
                                width: 300,
                                borderRadius: "32px",
                                border:
                                  "1px solid rgb(224, 224, 224)!important",
                                width: "100%",
                              }}
                            >
                              <Select
                                key="TitleTag"
                                id="TitleTag" // Add an id for accessibility
                                name="TitleTag" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getAllCities(e?.target?.value);
                                }}
                                size="small"
                                // onBlur={formik.handleBlur}
                                value={formik.values.TitleTag}
                                labelId="TitleTag"
                              >
                                {/* <MenuItem value="">
       <em>None</em>
     </MenuItem> */}
                                {titleTagOptions?.map((TitleTag) => (
                                  <MenuItem value={TitleTag?.tag}>
                                    {TitleTag?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Type*
                            </span>

                            <FormControl
                              sx={{
                                width: 300,
                                borderRadius: "32px",
                                border:
                                  "1px solid rgb(224, 224, 224)!important",
                                width: "100%",
                              }}
                            >
                              <Select
                                key="TypeTag"
                                id="TypeTag" // Add an id for accessibility
                                name="TypeTag" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getAllCities(e?.target?.value);
                                }}
                                size="small"
                                // onBlur={formik.handleBlur}
                                value={formik.values.TypeTag}
                                labelId="TypeTag"
                              >
                                {typeTagOptions?.map((TypeTag) => (
                                  <MenuItem value={TypeTag?.tag}>
                                    {TypeTag?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Account Type*
                            </span>

                            <FormControl
                              sx={{
                                width: 300,
                                borderRadius: "32px",
                                border:
                                  "1px solid rgb(224, 224, 224)!important",
                                width: "100%",
                              }}
                            >
                              <Select
                                key="BillingTag"
                                id="BillingTag" // Add an id for accessibility
                                name="BillingTag" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getAllCities(e?.target?.value);
                                }}
                                size="small"
                                // onBlur={formik.handleBlur}
                                value={formik.values.BillingTag}
                                labelId="BillingTag"
                              >
                                {billingTagOptions?.map((BillingTag) => (
                                  <MenuItem value={BillingTag?.tag}>
                                    {BillingTag?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {formik.touched.BillingTag &&
                              formik.errors.BillingTag && (
                                <FormHelperText
                                  style={{
                                    color: "#D62F6B",
                                    marginLeft: "15px",
                                    position: "absolute",
                                  }}
                                >
                                  {formik.errors.BillingTag}
                                </FormHelperText>
                              )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              First Name*{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"FirstName"}
                                fullWidth
                                id={"FirstName"}
                                name={"FirstName"}
                                type={"text"}
                                value={formik.values["FirstName"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["FirstName"] &&
                                  Boolean(formik.errors["FirstName"])
                                }
                                helperText={
                                  formik.touched["FirstName"] &&
                                  formik.errors["FirstName"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        style={{ marginBottom: "10px", marginTop: "8px" }}
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Last Name{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"LastName"}
                                fullWidth
                                id={"LastName"}
                                name={"LastName"}
                                type={"text"}
                                value={formik.values["LastName"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["LastName"] &&
                                  Boolean(formik.errors["LastName"])
                                }
                                helperText={
                                  formik.touched["LastName"] &&
                                  formik.errors["LastName"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={3}>
                          <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                            >
                              Company Website{" "}
                            </span>

                            <FormControl
                              sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                              }}
                            >
                              {" "}
                              <TextField
                                sx={{
                                  "&.MuiTextField-root": {
                                    border: "none",
                                  },
                                  padding: "5px",
                                }}
                                key={"CompanyWebsite"}
                                fullWidth
                                id={"CompanyWebsite"}
                                name={"CompanyWebsite"}
                                type={"text"}
                                value={formik.values["CompanyWebsite"]}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                error={
                                  formik.touched["CompanyWebsite"] &&
                                  Boolean(formik.errors["CompanyWebsite"])
                                }
                                helperText={
                                  formik.touched["CompanyWebsite"] &&
                                  formik.errors["CompanyWebsite"]
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid
                    style={{
                      marginTop: "50px",
                      justifyContent: "space-between",
                    }}
                    container
                  >
                    <Grid item={6}>
                      <Button
                        disabled={steps.length === 0}
                        onClick={handleBack}
                        className="outline"
                        style={{ visibility: "hidden" }}

                        // sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item={6}>
                      <Button
                        className="contained"
                        onClick={formik?.handleSubmit}
                        disabled={isEdit && providerDetails === ""}
                      >
                        {activeStep === steps.length - 1
                          ? "Finish"
                          : "Continue"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {activeStep === 1 && (
                <>
                  <Grid
                    style={{
                      background: "white",
                      padding: "20px",
                      borderRadius: "25px",
                      marginTop: windowWidth < 1000 && "40px",
                    }}
                    item
                    lg={10}
                    xs={12}
                  >
                    <Typography>
                      <ContactMailIcon
                        style={{
                          color: "#C41035",
                          fontSize: "25px",
                          marginRight: "5px",
                          marginBottom: "-7px",
                        }}
                      />
                      Address Info
                    </Typography>
                    <Grid
                      style={{ marginBottom: "10px", marginTop: "8px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Region
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Region"}
                              fullWidth
                              id={"Region"}
                              name={"Region"}
                              type={"text"}
                              value={formik.values["Region"]}
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.touched["Region"] &&
                                Boolean(formik.errors["Region"])
                              }
                              helperText={
                                formik.touched["Region"] &&
                                formik.errors["Region"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Country
                        </span>

                        <FormControl
                          sx={{
                            width: 300,
                            borderRadius: "32px",
                            border: "1px solid rgb(224, 224, 224)!important",
                            width: "100%",
                          }}
                        >
                          <Select
                            key="CountryGuid"
                            id="CountryGuid" // Add an id for accessibility
                            name="CountryGuid" // Name should match the field name in initialValues
                            onChange={(e) => {
                              formik.handleChange(e);
                              console.log("afafafasfasfasf", e?.target?.value);
                              getAllCities(e?.target?.value);
                            }}
                            size="small"
                            // onBlur={formik.handleBlur}
                            value={formik.values.CountryGuid}
                            labelId="CountryGuid"
                          >
                            {Countries?.map((CountryGuid) => (
                              <MenuItem value={CountryGuid?.recordGuid}>
                                {CountryGuid?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formik.touched.CountryGuid &&
                          formik.errors.CountryGuid && (
                            <FormHelperText
                              style={{ color: "#D62F6B", marginLeft: "15px" }}
                            >
                              {formik.errors.CountryGuid}
                            </FormHelperText>
                          )}
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          City
                        </span>

                        <FormControl
                          sx={{
                            width: 300,
                            borderRadius: "32px",
                            border: "1px solid rgb(224, 224, 224)!important",
                            width: "100%",
                          }}
                        >
                          <Select
                            disabled={
                              formik.values.CountryGuid === "" ||
                              Cities?.length === 0
                            }
                            key="CityId"
                            id="CityId" // Add an id for accessibility
                            name="CityId" // Name should match the field name in initialValues
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.CityId}
                            labelId="CityId"
                            size="small"
                          >
                            {Cities?.map((CityId) => (
                              <MenuItem value={CityId?.recordGuid}>
                                {CityId?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formik.touched.CityId && formik.errors.CityId && (
                          <FormHelperText
                            style={{ color: "#D62F6B", marginLeft: "15px" }}
                          >
                            {formik.errors.CityId}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Address*
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Address"}
                              fullWidth
                              id={"Address"}
                              name={"Address"}
                              type={"text"}
                              value={formik.values["Address"]}
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.touched["Address"] &&
                                Boolean(formik.errors["Address"])
                              }
                              helperText={
                                formik.touched["Address"] &&
                                formik.errors["Address"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Street
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Street"}
                              fullWidth
                              id={"Street"}
                              name={"Street"}
                              type={"text"}
                              value={formik.values["Street"]}
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.touched["Street"] &&
                                Boolean(formik.errors["Street"])
                              }
                              helperText={
                                formik.touched["Street"] &&
                                formik.errors["Street"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            State{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"State"}
                              fullWidth
                              id={"State"}
                              name={"State"}
                              type={"text"}
                              value={formik.values["State"]}
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.touched["State"] &&
                                Boolean(formik.errors["State"])
                              }
                              helperText={
                                formik.touched["State"] &&
                                formik.errors["State"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Zip{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Zip"}
                              fullWidth
                              id={"Zip"}
                              name={"Zip"}
                              type={"number"}
                              value={formik.values["Zip"]}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formik.handleChange(e);
                                }
                              }}
                              inputProps={{ maxLength: 10 }} // Setting maximum length to 10
                              error={
                                formik.touched["Zip"] &&
                                Boolean(formik.errors["Zip"])
                              }
                              helperText={
                                formik.touched["Zip"] && formik.errors["Zip"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Building
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Building"}
                              fullWidth
                              id={"Building"}
                              name={"Building"}
                              type={"text"}
                              value={formik.values["Building"]}
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.touched["Building"] &&
                                Boolean(formik.errors["Building"])
                              }
                              helperText={
                                formik.touched["Building"] &&
                                formik.errors["Building"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Floor
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Floor"}
                              fullWidth
                              id={"Floor"}
                              name={"Floor"}
                              inputProps={{ maxLength: 10 }} // Setting maximum length to 10
                              type={"number"}
                              value={formik.values["Floor"]}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formik.handleChange(e);
                                }
                              }} // onBlur={formik.handleBlur}
                              error={
                                formik.touched["Floor"] &&
                                Boolean(formik.errors["Floor"])
                              }
                              helperText={
                                formik.touched["Floor"] &&
                                formik.errors["Floor"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid
                        style={{ marginBottom: "15px" }}
                        item
                        xs={12}
                        lg={3}
                      >
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Room
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"RoomNumber"}
                              fullWidth
                              id={"RoomNumber"}
                              name={"RoomNumber"}
                              type={"number"}
                              value={formik.values["RoomNumber"]}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formik.handleChange(e);
                                }
                              }}
                              // onBlur={formik.handleBlur}
                              error={
                                formik.touched["RoomNumber"] &&
                                Boolean(formik.errors["RoomNumber"])
                              }
                              helperText={
                                formik.touched["RoomNumber"] &&
                                formik.errors["RoomNumber"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      style={{
                        marginTop: "50px",
                        justifyContent: "space-between",
                      }}
                      container
                    >
                      <Grid item={6}>
                        <Button
                          disabled={steps.length === 0}
                          onClick={handleBack}
                          className="outline"
                          // style={{ visibility: "hidden" }}

                          // sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item={6}>
                        <Button
                          className="contained"
                          onClick={formik?.handleSubmit}
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : "Continue"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {activeStep === 2 && (
                <Grid
                  style={{
                    background: "white",
                    padding: "30px 50px",
                    borderRadius: "25px",
                    marginTop: windowWidth < 1000 && "40px",
                  }}
                  item
                  lg={10}
                  xs={12}
                >
                  <Typography>
                    <PersonIcon
                      style={{
                        color: "#C41035",
                        fontSize: "25px",
                        marginRight: "5px",
                        marginBottom: "-7px",
                      }}
                    />
                    Engagement Info
                  </Typography>
                  <Grid
                    style={{ marginBottom: "10px", marginTop: "8px" }}
                    container
                    spacing={3}
                  >
                    <Grid item xs={12} lg={3}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Engagement Email
                        </span>

                        <FormControl
                          sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid #E0E0E0 ",
                            display: "grid",
                          }}
                        >
                          {" "}
                          <TextField
                            sx={{
                              "&.MuiTextField-root": {
                                border: "none",
                              },
                              padding: "5px",
                            }}
                            key={"EngagementEmail"}
                            fullWidth
                            id={"EngagementEmail"}
                            name={"EngagementEmail"}
                            type={"text"}
                            value={formik.values.EngagementEmail}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.touched.EngagementEmail &&
                              Boolean(formik.errors.EngagementEmail)
                            }
                            helperText={
                              formik.touched.EngagementEmail &&
                              formik.errors.EngagementEmail
                            }
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                        Support Email{" "}
                      </span>

                      <FormControl
                        sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                        }}
                      >
                        {" "}
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              border: "none",
                            },
                            padding: "5px",
                          }}
                          key={"SupportEmail"}
                          fullWidth
                          id={"SupportEmail"}
                          name={"SupportEmail"}
                          type={"text"}
                          value={formik.values.SupportEmail}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          error={
                            formik.touched.SupportEmail &&
                            Boolean(formik.errors.SupportEmail)
                          }
                          helperText={
                            formik.touched.SupportEmail &&
                            formik.errors.SupportEmail
                          }
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Billing Email
                        </span>

                        <FormControl
                          sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid #E0E0E0 ",
                            display: "grid",
                          }}
                        >
                          {" "}
                          <TextField
                            sx={{
                              "&.MuiTextField-root": {
                                border: "none",
                              },
                              padding: "5px",
                            }}
                            key={"BillingEmail"}
                            fullWidth
                            id={"BillingEmail"}
                            name={"BillingEmail"}
                            type={"text"}
                            value={formik.values["BillingEmail"]}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.touched["BillingEmail"] &&
                              Boolean(formik.errors["BillingEmail"])
                            }
                            helperText={
                              formik.touched["BillingEmail"] &&
                              formik.errors["BillingEmail"]
                            }
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Technical Email{" "}
                        </span>

                        <FormControl
                          sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid #E0E0E0 ",
                            display: "grid",
                          }}
                        >
                          {" "}
                          <TextField
                            sx={{
                              "&.MuiTextField-root": {
                                border: "none",
                              },
                              padding: "5px",
                            }}
                            key={"TechnicalEmail"}
                            fullWidth
                            id={"TechnicalEmail"}
                            name={"TechnicalEmail"}
                            type={"text"}
                            value={formik.values["TechnicalEmail"]}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.touched["TechnicalEmail"] &&
                              Boolean(formik.errors["TechnicalEmail"])
                            }
                            helperText={
                              formik.touched["TechnicalEmail"] &&
                              formik.errors["TechnicalEmail"]
                            }
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Alerts Email{" "}
                        </span>

                        <FormControl
                          sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid #E0E0E0 ",
                            display: "grid",
                          }}
                        >
                          {" "}
                          <TextField
                            sx={{
                              "&.MuiTextField-root": {
                                border: "none",
                              },
                              padding: "5px",
                            }}
                            key={"AlertsEmail"}
                            fullWidth
                            id={"AlertsEmail"}
                            name={"AlertsEmail"}
                            value={formik.values["AlertsEmail"]}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.touched["AlertsEmail"] &&
                              Boolean(formik.errors["AlertsEmail"])
                            }
                            helperText={
                              formik.touched["AlertsEmail"] &&
                              formik.errors["AlertsEmail"]
                            }
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Industry
                        </span>

                        <FormControl
                          sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid #E0E0E0 ",
                            display: "grid",
                          }}
                        >
                          {" "}
                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="IndustryGuid"
                              id="IndustryGuid" // Add an id for accessibility
                              name="IndustryGuid" // Name should match the field name in initialValues
                              onChange={(e) => {
                                formik.handleChange(e);
                                console.log(
                                  "afafafasfasfasf",
                                  e?.target?.value
                                );
                                getAllCities(e?.target?.value);
                              }}
                              size="small"
                              // onBlur={formik.handleBlur}
                              value={formik.values.IndustryGuid}
                              labelId="IndustryGuid"
                            >
                              {industryOptions?.map((IndustryGuid) => (
                                <MenuItem value={IndustryGuid?.recordGuid}>
                                  {IndustryGuid?.details
                                    ? IndustryGuid?.details[0]?.name
                                    : ""}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Business Web Url
                        </span>

                        <FormControl
                          sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid #E0E0E0 ",
                            display: "grid",
                          }}
                        >
                          {" "}
                          <TextField
                            sx={{
                              "&.MuiTextField-root": {
                                border: "none",
                              },
                              padding: "5px",
                            }}
                            key={"BusinessWebUrl"}
                            fullWidth
                            id={"BusinessWebUrl"}
                            name={"BusinessWebUrl"}
                            value={formik.values["BusinessWebUrl"]}
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={
                              formik.touched["BusinessWebUrl"] &&
                              Boolean(formik.errors["BusinessWebUrl"])
                            }
                            helperText={
                              formik.touched["BusinessWebUrl"] &&
                              formik.errors["BusinessWebUrl"]
                            }
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    style={{
                      marginTop: "50px",
                      justifyContent: "space-between",
                    }}
                    container
                  >
                    <Grid item={6}>
                      <Button
                        disabled={steps.length === 0}
                        onClick={handleBack}
                        className="outline"
                        // style={{ visibility: "hidden" }}

                        // sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item={6}>
                      <Button
                        className="contained"
                        onClick={formik?.handleSubmit}
                      >
                        Save Changes
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* <Grid
          display={"flex"}
          flexDirection={"row"}
          style={{ width: "100%", marginTop: 20,textAlign:"end" }}
          justifyContent={"space-between"}
        >
          <Button onClick={handleBack} className="mui-btn secondary filled">
            Back
          </Button>
          <Button
            variant="contained"
            className="mui-btn secondary filled"
            onClick={formik?.handleSubmit}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Continue"}
          </Button>
        </Grid> */}
          </form>
        </>
      ) : (
        <>
          <Grid
            style={{ marginBottom: "50px" }}
            item
            xs={12}
            md={6}
            className="centerresponsive"
          >
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <Typography
                style={{ cursor: selectedProvider && "pointer" }}
                className="BreadcrumbsPage"
                onClick={selectedProvider && goToListing}
              >
                Providers
              </Typography>

              {/* <Typography className="breadcrumbactiveBtn">
                {"Manage Provider"}
              </Typography> */}
              <Typography
                // style={{ cursor: "pointer" }}
                className="breadcrumbactiveBtn" // onClick={() => {
                //   setGoToConfigPage(false);
                //   formik.resetForm();
                // }}
              >
                {selectedProvider?.username
                  ? selectedProvider?.username
                  : "New Provider"}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid container>
            {/* <Grid
                style={{
                  background: "white",
                  padding: "20px",
                  borderRadius: "25px",
                }}
                item
                lg={12}
                xs={12}
              > */}
            <div>
              <Accordion
                defaultExpanded
                style={{ borderRadius: "10px", marginBottom: "10px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <SettingsIcon
                    style={{
                      color: "#C41035",
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <Typography>MNPHLR Configuration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={formikLookupConfiguration?.handleSubmit}>
                    <Grid
                      style={{ marginBottom: "10px", marginTop: "8px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Connectivity Type*
                          </span>

                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="ConnectivityTypeGuid"
                              id="ConnectivityTypeGuid" // Add an id for accessibility
                              name="ConnectivityTypeGuid" // Name should match the field name in initialValues
                              onChange={(e) => {
                                formikLookupConfiguration.handleChange(e);
                              }}
                              size="small"
                              // onBlur={formikLookupConfiguration.handleBlur}
                              value={
                                formikLookupConfiguration.values
                                  .ConnectivityTypeGuid
                              }
                              labelId="ConnectivityTypeGuid"
                            >
                              {connectivityTypeOptions?.map(
                                (ConnectivityTypeGuid) => (
                                  <MenuItem value={ConnectivityTypeGuid?.tag}>
                                    {ConnectivityTypeGuid?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          {formikLookupConfiguration.touched
                            .ConnectivityTypeGuid &&
                            formikLookupConfiguration.errors
                              .ConnectivityTypeGuid && (
                              <FormHelperText
                                style={{
                                  color: "#D62F6B",
                                  marginLeft: "15px",
                                  position: "absolute",
                                }}
                              >
                                {
                                  formikLookupConfiguration.errors
                                    .ConnectivityTypeGuid
                                }
                              </FormHelperText>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Service Lookup Mode*
                          </span>

                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="ServiceLookupModeGuid"
                              id="ServiceLookupModeGuid" // Add an id for accessibilit
                              name="ServiceLookupModeGuid" // Name should match the field name in initialValues
                              onChange={(e) => {
                                formikLookupConfiguration.handleChange(e);
                              }}
                              size="small"
                              // onBlur={serviceLookupModeGuidOptions.handleBlur}
                              value={
                                formikLookupConfiguration.values
                                  .ServiceLookupModeGuid
                              }
                              labelId="ServiceLookupModeGuid"
                            >
                              {serviceLookupModeGuidOptions?.map(
                                (ServiceLookupModeGuid) => (
                                  <MenuItem value={ServiceLookupModeGuid?.tag}>
                                    {ServiceLookupModeGuid?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          {formikLookupConfiguration.touched
                            .ServiceLookupModeGuid &&
                            formikLookupConfiguration.errors
                              .ServiceLookupModeGuid && (
                              <FormHelperText
                                style={{
                                  color: "#D62F6B",
                                  marginLeft: "15px",
                                  position: "absolute",
                                }}
                              >
                                {
                                  formikLookupConfiguration.errors
                                    .ServiceLookupModeGuid
                                }
                              </FormHelperText>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Number of Threads*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"NumberOfThreads"}
                              fullWidth
                              id={"NumberOfThreads"}
                              name={"NumberOfThreads"}
                              type={"number"}
                              value={
                                formikLookupConfiguration.values[
                                  "NumberOfThreads"
                                ]
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formikLookupConfiguration.handleChange(e);
                                }
                              }}
                              // onBlur={formikLookupConfiguration.handleBlur}
                              error={
                                formikLookupConfiguration.touched[
                                  "NumberOfThreads"
                                ] &&
                                Boolean(
                                  formikLookupConfiguration.errors[
                                    "NumberOfThreads"
                                  ]
                                )
                              }
                              helperText={
                                formikLookupConfiguration.touched[
                                  "NumberOfThreads"
                                ] &&
                                formikLookupConfiguration.errors[
                                  "NumberOfThreads"
                                ]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            IP Address*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Ip"}
                              fullWidth
                              id={"Ip"}
                              name={"Ip"}
                              type={"text"}
                              value={formikLookupConfiguration.values["Ip"]}
                              onChange={formikLookupConfiguration.handleChange}
                              // onBlur={formikLookupConfiguration.handleBlur}
                              error={
                                formikLookupConfiguration.touched["Ip"] &&
                                Boolean(formikLookupConfiguration.errors["Ip"])
                              }
                              helperText={
                                formikLookupConfiguration.touched["Ip"] &&
                                formikLookupConfiguration.errors["Ip"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Host*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Host"}
                              fullWidth
                              id={"Host"}
                              name={"Host"}
                              type={"text"}
                              value={formikLookupConfiguration.values["Host"]}
                              onChange={formikLookupConfiguration.handleChange}
                              // onBlur={formikLookupConfiguration.handleBlur}
                              error={
                                formikLookupConfiguration.touched["Host"] &&
                                Boolean(
                                  formikLookupConfiguration.errors["Host"]
                                )
                              }
                              helperText={
                                formikLookupConfiguration.touched["Host"] &&
                                formikLookupConfiguration.errors["Host"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Port*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Port"}
                              fullWidth
                              id={"Port"}
                              name={"Port"}
                              type={"number"}
                              value={formikLookupConfiguration.values["Port"]}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formikLookupConfiguration.handleChange(e);
                                }
                              }}
                              // onBlur={formikLookupConfiguration.handleBlur}
                              error={
                                formikLookupConfiguration.touched["Port"] &&
                                Boolean(
                                  formikLookupConfiguration.errors["Port"]
                                )
                              }
                              helperText={
                                formikLookupConfiguration.touched["Port"] &&
                                formikLookupConfiguration.errors["Port"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Query DNS*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"QueryDns"}
                              fullWidth
                              id={"QueryDns"}
                              name={"QueryDns"}
                              type={"text"}
                              value={
                                formikLookupConfiguration.values["QueryDns"]
                              }
                              onChange={formikLookupConfiguration.handleChange}
                              // onBlur={formikLookupConfiguration.handleBlur}
                              error={
                                formikLookupConfiguration.touched["QueryDns"] &&
                                Boolean(
                                  formikLookupConfiguration.errors["QueryDns"]
                                )
                              }
                              helperText={
                                formikLookupConfiguration.touched["QueryDns"] &&
                                formikLookupConfiguration.errors["QueryDns"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      {/* <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Client
                          </span>

                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="ClientGuid"
                              id="ClientGuid" // Add an id for accessibility
                              name="ClientGuid" // Name should match the field name in initialValues
                              onChange={(e) => {
                                clientOptions.handleChange(e);
                                getAllCities(e?.target?.value);
                              }}
                              size="small"
                              // onBlur={clientOptions.handleBlur}
                              value={clientOptions.values.ClientGuid}
                              labelId="ClientGuid"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {clientOptions?.map((ClientGuid) => (
                                <MenuItem value={ClientGuid?.recordGuid}>
                                  {ClientGuid?.firstName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid> */}

                      {/* <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Provider
                          </span>

                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="ProviderGuid"
                              id="ProviderGuid" // Add an id for accessibility
                              name="ProviderGuid" // Name should match the field name in initialValues
                              onChange={(e) => {
                                connectivityTypeOptions.handleChange(e);
                                getAllCities(e?.target?.value);
                              }}
                              size="small"
                              // onBlur={connectivityTypeOptions.handleBlur}
                              value={
                                connectivityTypeOptions.values.ProviderGuid
                              }
                              labelId="ProviderGuid"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {providerOptions?.map((ProviderGuid) => (
                                <MenuItem value={ProviderGuid?.recordGuid}>
                                  {ProviderGuid?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid> */}
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "50px",
                        justifyContent: "space-between",
                      }}
                      container
                    >
                      <Grid item={6}>
                        <Button
                          disabled={steps.length === 0}
                          onClick={handleBack}
                          className="outline"
                          style={{ visibility: "hidden" }}

                          // sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item={12}>
                        <Button
                          className="contained"
                          onClick={formikLookupConfiguration?.handleSubmit}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ borderRadius: "10px", marginBottom: "10px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <SettingsIcon
                    style={{
                      color: "#C41035",
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <Typography>SMTP Configuration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={formikSmtpConfiguration?.handleSubmit}>
                    <Grid
                      style={{ marginBottom: "10px", marginTop: "8px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Server*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"SmtpServer"}
                              fullWidth
                              id={"SmtpServer"}
                              name={"SmtpServer"}
                              type={"text"}
                              value={
                                formikSmtpConfiguration.values["SmtpServer"]
                              }
                              onChange={formikSmtpConfiguration.handleChange}
                              // onBlur={formikSmtpConfiguration.handleBlur}
                              error={
                                formikSmtpConfiguration.touched["SmtpServer"] &&
                                Boolean(
                                  formikSmtpConfiguration.errors["SmtpServer"]
                                )
                              }
                              helperText={
                                formikSmtpConfiguration.touched["SmtpServer"] &&
                                formikSmtpConfiguration.errors["SmtpServer"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Username*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"SmtpUser"}
                              fullWidth
                              id={"SmtpUser"}
                              name={"SmtpUser"}
                              type={"text"}
                              value={formikSmtpConfiguration.values["SmtpUser"]}
                              onChange={formikSmtpConfiguration.handleChange}
                              // onBlur={formikSmtpConfiguration.handleBlur}
                              error={
                                formikSmtpConfiguration.touched["SmtpUser"] &&
                                Boolean(
                                  formikSmtpConfiguration.errors["SmtpUser"]
                                )
                              }
                              helperText={
                                formikSmtpConfiguration.touched["SmtpUser"] &&
                                formikSmtpConfiguration.errors["SmtpUser"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Password*
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                              padding: "5px 1px",
                            }}
                          >
                            <TextField
                              sx={{
                                "& .MuiInputBase-input": {
                                  padding: "6.5px 15px",
                                },

                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                              }}
                              InputProps={{
                                style: {
                                  borderRadius: "25px",
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowPasswordSmtp(!showPasswordSmtp)
                                      }
                                      edge="end"
                                    >
                                      {showPasswordSmtp ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              key={"SmtpPassword"}
                              fullWidth
                              id={"SmtpPassword"}
                              name={"SmtpPassword"}
                              type={showPasswordSmtp ? "text" : "password"}
                              value={
                                formikSmtpConfiguration.values["SmtpPassword"]
                              }
                              onChange={formikSmtpConfiguration.handleChange}
                              // onBlur={formikSmtpConfiguration.handleBlur}
                              error={
                                formikSmtpConfiguration.touched[
                                  "SmtpPassword"
                                ] &&
                                Boolean(
                                  formikSmtpConfiguration.errors["SmtpPassword"]
                                )
                              }
                              helperText={
                                formikSmtpConfiguration.touched[
                                  "SmtpPassword"
                                ] &&
                                formikSmtpConfiguration.errors["SmtpPassword"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Port*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              fullWidth
                              id="SmtpPort"
                              name="SmtpPort"
                              type="number"
                              value={formikSmtpConfiguration.values.SmtpPort}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formikSmtpConfiguration.handleChange(e);
                                }
                              }}
                              error={
                                formikSmtpConfiguration.touched.SmtpPort &&
                                Boolean(formikSmtpConfiguration.errors.SmtpPort)
                              }
                              helperText={
                                formikSmtpConfiguration.touched.SmtpPort &&
                                formikSmtpConfiguration.errors.SmtpPort
                              }
                              inputProps={{
                                min: "1", // Set the minimum value to 1 to accept only positive numbers
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            From Address*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"FromAddress"}
                              fullWidth
                              id={"FromAddress"}
                              name={"FromAddress"}
                              type={"text"}
                              value={
                                formikSmtpConfiguration.values["FromAddress"]
                              }
                              onChange={formikSmtpConfiguration.handleChange}
                              // onBlur={formikSmtpConfiguration.handleBlur}
                              error={
                                formikSmtpConfiguration.touched[
                                  "FromAddress"
                                ] &&
                                Boolean(
                                  formikSmtpConfiguration.errors["FromAddress"]
                                )
                              }
                              helperText={
                                formikSmtpConfiguration.touched[
                                  "FromAddress"
                                ] &&
                                formikSmtpConfiguration.errors["FromAddress"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Reply Address*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"ReplyAddress"}
                              fullWidth
                              id={"ReplyAddress"}
                              name={"ReplyAddress"}
                              type={"text"}
                              value={
                                formikSmtpConfiguration.values["ReplyAddress"]
                              }
                              onChange={formikSmtpConfiguration.handleChange}
                              // onBlur={formikSmtpConfiguration.handleBlur}
                              error={
                                formikSmtpConfiguration.touched[
                                  "ReplyAddress"
                                ] &&
                                Boolean(
                                  formikSmtpConfiguration.errors["ReplyAddress"]
                                )
                              }
                              helperText={
                                formikSmtpConfiguration.touched[
                                  "ReplyAddress"
                                ] &&
                                formikSmtpConfiguration.errors["ReplyAddress"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Box
                          sx={{
                            width: "100%",
                            marginBottom: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            SMTP SSL{" "}
                          </span>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={switchState}
                                onChange={() => handleSwitchChange()}
                              />
                            }
                            // label={switchState ? "Active " : "Inactive "}
                          />
                        </Box>
                      </Grid>

                      {/* <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Client
                          </span>

                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="ClientGuid"
                              id="ClientGuid" // Add an id for accessibility
                              name="ClientGuid" // Name should match the field name in initialValues
                              onChange={(e) => {
                                formikSmtpConfiguration.handleChange(e);
                              }}
                              size="small"
                              // onBlur={formikSmtpConfiguration.handleBlur}
                              value={formikSmtpConfiguration.values.ClientGuid}
                              labelId="ClientGuid"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {clientOptions?.map((ClientGuid) => (
                                <MenuItem value={ClientGuid?.recordGuid}>
                                  {ClientGuid?.firstName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Provider
                          </span>

                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="ProviderGuid"
                              id="ProviderGuid" // Add an id for accessibility
                              name="ProviderGuid" // Name should match the field name in initialValues
                              onChange={(e) => {
                                formikSmtpConfiguration.handleChange(e);
                              }}
                              size="small"
                              // onBlur={providerOptions.handleBlur}
                              value={
                                formikSmtpConfiguration.values.ProviderGuid
                              }
                              labelId="ProviderGuid"
                            >
                              <MenuItem value={null}>
                                <em>None</em>
                              </MenuItem>
                              {providerOptions?.map((ProviderGuid) => (
                                <MenuItem value={ProviderGuid?.recordGuid}>
                                  {ProviderGuid?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid> */}
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "50px",
                        justifyContent: "space-between",
                      }}
                      container
                    >
                      <Grid item={6}>
                        <Button
                          disabled={steps.length === 0}
                          onClick={handleBack}
                          className="outline"
                          style={{ visibility: "hidden" }}

                          // sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item={12}>
                        <Button
                          className="contained"
                          onClick={formikSmtpConfiguration?.handleSubmit}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{ borderRadius: "10px", marginBottom: "10px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <SettingsIcon
                    style={{
                      color: "#C41035",
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <Typography>SMPP Configuration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={formikSmppConfiguration?.handleSubmit}>
                    <Grid
                      style={{ marginBottom: "10px", marginTop: "8px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Username*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"Username"}
                              fullWidth
                              id={"Username"}
                              name={"Username"}
                              type={"text"}
                              value={formikSmppConfiguration.values["Username"]}
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched["Username"] &&
                                Boolean(
                                  formikSmppConfiguration.errors["Username"]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched["Username"] &&
                                formikSmppConfiguration.errors["Username"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Password*
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                              padding: "5px 1px",
                            }}
                          >
                            <TextField
                              sx={{
                                "& .MuiInputBase-input": {
                                  padding: "6.5px 15px",
                                },

                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                              }}
                              InputProps={{
                                style: {
                                  borderRadius: "25px",
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              key={"Password"}
                              fullWidth
                              id={"Password"}
                              name={"Password"}
                              type={showPassword ? "text" : "password"}
                              value={formikSmppConfiguration.values["Password"]}
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched["Password"] &&
                                Boolean(
                                  formikSmppConfiguration.errors["Password"]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched["Password"] &&
                                formikSmppConfiguration.errors["Password"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            IP Address*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              fullWidth
                              id="IpAddress"
                              name="IpAddress"
                              type="text"
                              value={formikSmppConfiguration.values.IpAddress}
                              onChange={formikSmppConfiguration.handleChange}
                              error={
                                formikSmppConfiguration.touched.IpAddress &&
                                Boolean(
                                  formikSmppConfiguration.errors.IpAddress
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched.IpAddress &&
                                formikSmppConfiguration.errors.IpAddress
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Source Ton*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"SourceTon"}
                              fullWidth
                              id={"SourceTon"}
                              name={"SourceTon"}
                              type={"text"}
                              value={
                                formikSmppConfiguration.values["SourceTon"]
                              }
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched["SourceTon"] &&
                                Boolean(
                                  formikSmppConfiguration.errors["SourceTon"]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched["SourceTon"] &&
                                formikSmppConfiguration.errors["SourceTon"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Source NPI*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"SourceNpi"}
                              fullWidth
                              id={"SourceNpi"}
                              name={"SourceNpi"}
                              type={"text"}
                              value={
                                formikSmppConfiguration.values["SourceNpi"]
                              }
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched["SourceNpi"] &&
                                Boolean(
                                  formikSmppConfiguration.errors["SourceNpi"]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched["SourceNpi"] &&
                                formikSmppConfiguration.errors["SourceNpi"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Destination TON*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"DestinationTon"}
                              fullWidth
                              id={"DestinationTon"}
                              name={"DestinationTon"}
                              type={"text"}
                              value={
                                formikSmppConfiguration.values["DestinationTon"]
                              }
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched[
                                  "DestinationTon"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors[
                                    "DestinationTon"
                                  ]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "DestinationTon"
                                ] &&
                                formikSmppConfiguration.errors["DestinationTon"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Destination NPI*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"DestinationNpi"}
                              fullWidth
                              id={"DestinationNpi"}
                              name={"DestinationNpi"}
                              type={"text"}
                              value={
                                formikSmppConfiguration.values["DestinationNpi"]
                              }
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched[
                                  "DestinationNpi"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors[
                                    "DestinationNpi"
                                  ]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "DestinationNpi"
                                ] &&
                                formikSmppConfiguration.errors["DestinationNpi"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            ShortCode TON NPI*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"ShortCodeTonNpi"}
                              fullWidth
                              id={"ShortCodeTonNpi"}
                              name={"ShortCodeTonNpi"}
                              type={"text"}
                              value={
                                formikSmppConfiguration.values[
                                  "ShortCodeTonNpi"
                                ]
                              }
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched[
                                  "ShortCodeTonNpi"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors[
                                    "ShortCodeTonNpi"
                                  ]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "ShortCodeTonNpi"
                                ] &&
                                formikSmppConfiguration.errors[
                                  "ShortCodeTonNpi"
                                ]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Maximum Retry*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"MaximumRetry"}
                              fullWidth
                              id={"MaximumRetry"}
                              name={"MaximumRetry"}
                              type={"number"}
                              value={
                                formikSmppConfiguration.values["MaximumRetry"]
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formikSmppConfiguration.handleChange(e);
                                }
                              }} // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched[
                                  "MaximumRetry"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors["MaximumRetry"]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "MaximumRetry"
                                ] &&
                                formikSmppConfiguration.errors["MaximumRetry"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Connection To Open*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"ConnectionToOpen"}
                              fullWidth
                              id={"ConnectionToOpen"}
                              name={"ConnectionToOpen"}
                              type={"number"}
                              value={
                                formikSmppConfiguration.values[
                                  "ConnectionToOpen"
                                ]
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formikSmppConfiguration.handleChange(e);
                                }
                              }} // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched[
                                  "ConnectionToOpen"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors[
                                    "ConnectionToOpen"
                                  ]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "ConnectionToOpen"
                                ] &&
                                formikSmppConfiguration.errors[
                                  "ConnectionToOpen"
                                ]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Enquire Link*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"EnquireLink"}
                              fullWidth
                              id={"EnquireLink"}
                              name={"EnquireLink"}
                              type={"number"}
                              value={
                                formikSmppConfiguration.values["EnquireLink"]
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formikSmppConfiguration.handleChange(e);
                                }
                              }}
                              error={
                                formikSmppConfiguration.touched[
                                  "EnquireLink"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors["EnquireLink"]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "EnquireLink"
                                ] &&
                                formikSmppConfiguration.errors["EnquireLink"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Submit Per Second*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"SubmitPerSecond"}
                              fullWidth
                              id={"SubmitPerSecond"}
                              name={"SubmitPerSecond"}
                              type={"number"}
                              value={
                                formikSmppConfiguration.values[
                                  "SubmitPerSecond"
                                ]
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  (!isNaN(value) &&
                                    parseInt(value) >= 0 &&
                                    value.length <= 10) ||
                                  e.target.value === ""
                                ) {
                                  formikSmppConfiguration.handleChange(e);
                                }
                              }} // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched[
                                  "SubmitPerSecond"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors[
                                    "SubmitPerSecond"
                                  ]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "SubmitPerSecond"
                                ] &&
                                formikSmppConfiguration.errors[
                                  "SubmitPerSecond"
                                ]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Connection Mode*
                          </span>

                          <FormControl
                            sx={{
                              width: 300,
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              width: "100%",
                            }}
                          >
                            <Select
                              key="ConnectionMode"
                              id="ConnectionMode" // Add an id for accessibility
                              name="ConnectionMode" // Name should match the field name in initialValues
                              onChange={(e) => {
                                formikSmppConfiguration.handleChange(e);
                              }}
                              size="small"
                              // onBlur={formikSmppConfiguration.handleBlur}
                              value={
                                formikSmppConfiguration.values.ConnectionMode
                              }
                              labelId="ConnectionMode"
                            >
                              {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem> */}
                              {connectionModeOptions?.map((ConnectionMode) => (
                                <MenuItem value={ConnectionMode?.value}>
                                  {ConnectionMode?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {formikSmppConfiguration.touched.ConnectionMode &&
                            formikSmppConfiguration.errors.ConnectionMode && (
                              <FormHelperText
                                style={{
                                  color: "#D62F6B",
                                  marginLeft: "15px",
                                  position: "absolute",
                                }}
                              >
                                {formikSmppConfiguration.errors.ConnectionMode}
                              </FormHelperText>
                            )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Translation Prefix*{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"TranslationPrefix"}
                              fullWidth
                              id={"TranslationPrefix"}
                              name={"TranslationPrefix"}
                              type={"text"}
                              value={
                                formikSmppConfiguration.values[
                                  "TranslationPrefix"
                                ]
                              }
                              onChange={formikSmppConfiguration.handleChange}
                              // onBlur={formikSmppConfiguration.handleBlur}
                              error={
                                formikSmppConfiguration.touched[
                                  "TranslationPrefix"
                                ] &&
                                Boolean(
                                  formikSmppConfiguration.errors[
                                    "TranslationPrefix"
                                  ]
                                )
                              }
                              helperText={
                                formikSmppConfiguration.touched[
                                  "TranslationPrefix"
                                ] &&
                                formikSmppConfiguration.errors[
                                  "TranslationPrefix"
                                ]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box
                          sx={{
                            width: "100%",
                            marginBottom: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Is Registered Delivery{" "}
                          </span>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={switchRegisteredDelivery}
                                onChange={() =>
                                  handleSwitchChangeRegisteredDelivery()
                                }
                              />
                            }
                            // label={switchState ? "Active " : "Inactive "}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box
                          sx={{
                            width: "100%",
                            marginBottom: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Can Bind{" "}
                          </span>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={switchCanBind}
                                onChange={() => handleSwitchChangeCanBind()}
                              />
                            }
                            // label={switchState ? "Active " : "Inactive "}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box
                          sx={{
                            width: "100%",
                            marginBottom: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Operator Encoding{" "}
                          </span>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={switchOperator}
                                onChange={() => handleSwitchChangeOperator()}
                              />
                            }
                            // label={switchState ? "Active " : "Inactive "}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "50px",
                        justifyContent: "space-between",
                      }}
                      container
                    >
                      <Grid item={6}>
                        <Button
                          disabled={steps.length === 0}
                          onClick={handleBack}
                          className="outline"
                          style={{ visibility: "hidden" }}

                          // sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item={12}>
                        <Button
                          className="contained"
                          onClick={formikSmppConfiguration?.handleSubmit}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>

              {/*   <Accordion style={{ borderRadius: "10px", marginBottom: "10px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <WhatsAppIcon
                    style={{
                      color: "#C41035",
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <Typography>Whatsapp Configuration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={formikWhatsapp?.handleSubmit}>
                    <Grid
                      style={{ marginBottom: "10px", marginTop: "8px" }}
                      container
                      spacing={3}
                    >
                     <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Client{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"ClientGuid"}
                              fullWidth
                              id={"ClientGuid"}
                              name={"ClientGuid"}
                              type={"text"}
                              value={formikWhatsapp.values["ClientGuid"]}
                              onChange={formikWhatsapp.handleChange}
                              // onBlur={formikWhatsapp.handleBlur}
                              error={
                                formikWhatsapp.touched["ClientGuid"] &&
                                Boolean(formikWhatsapp.errors["ClientGuid"])
                              }
                              helperText={
                                formikWhatsapp.touched["ClientGuid"] &&
                                formikWhatsapp.errors["ClientGuid"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid> 

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Whatsapp Token{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"WhatsappToken"}
                              fullWidth
                              id={"WhatsappToken"}
                              name={"WhatsappToken"}
                              type={"text"}
                              value={formikWhatsapp.values["WhatsappToken"]}
                              onChange={formikWhatsapp.handleChange}
                              // onBlur={formikWhatsapp.handleBlur}
                              error={
                                formikWhatsapp.touched["WhatsappToken"] &&
                                Boolean(formikWhatsapp.errors["WhatsappToken"])
                              }
                              helperText={
                                formikWhatsapp.touched["SmtpUser"] &&
                                formikWhatsapp.errors["SmtpUser"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Owner Business Id{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"OwnerBusinessId"}
                              fullWidth
                              id={"OwnerBusinessId"}
                              name={"OwnerBusinessId"}
                              type={"text"}
                              value={formikWhatsapp.values["OwnerBusinessId"]}
                              onChange={formikWhatsapp.handleChange}
                              // onBlur={formikWhatsapp.handleBlur}
                              error={
                                formikWhatsapp.touched["OwnerBusinessId"] &&
                                Boolean(
                                  formikWhatsapp.errors["OwnerBusinessId"]
                                )
                              }
                              helperText={
                                formikWhatsapp.touched["OwnerBusinessId"] &&
                                formikWhatsapp.errors["OwnerBusinessId"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Owner Business Name{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"OwnerBusinessName"}
                              fullWidth
                              id={"OwnerBusinessName"}
                              name={"OwnerBusinessName"}
                              type={"text"}
                              value={formikWhatsapp.values["OwnerBusinessName"]}
                              onChange={formikWhatsapp.handleChange}
                              // onBlur={formikWhatsapp.handleBlur}
                              error={
                                formikWhatsapp.touched["OwnerBusinessName"] &&
                                Boolean(
                                  formikWhatsapp.errors["OwnerBusinessName"]
                                )
                              }
                              helperText={
                                formikWhatsapp.touched["OwnerBusinessName"] &&
                                formikWhatsapp.errors["OwnerBusinessName"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                            Campaign Limit{" "}
                          </span>

                          <FormControl
                            sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid #E0E0E0 ",
                              display: "grid",
                            }}
                          >
                            {" "}
                            <TextField
                              sx={{
                                "&.MuiTextField-root": {
                                  border: "none",
                                },
                                padding: "5px",
                              }}
                              key={"CampaignLimit"}
                              fullWidth
                              id={"CampaignLimit"}
                              name={"CampaignLimit"}
                              type={"text"}
                              value={formikWhatsapp.values["CampaignLimit"]}
                              onChange={formikWhatsapp.handleChange}
                              // onBlur={formikWhatsapp.handleBlur}
                              error={
                                formikWhatsapp.touched["CampaignLimit"] &&
                                Boolean(formikWhatsapp.errors["CampaignLimit"])
                              }
                              helperText={
                                formikWhatsapp.touched["CampaignLimit"] &&
                                formikWhatsapp.errors["CampaignLimit"]
                              }
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "50px",
                        justifyContent: "space-between",
                      }}
                      container
                    >
                      <Grid item={6}>
                        <Button
                          disabled={steps.length === 0}
                          onClick={handleBack}
                          className="outline"
                          style={{ visibility: "hidden" }}

                          // sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item={12}>
                        <Button
                          className="contained"
                          onClick={formikWhatsapp?.handleSubmit}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
              */}
            </div>
            {/* </Grid> */}
          </Grid>
        </>
      )}

      {!selectedProvider && showPopup && (
        <CustomizedDialogs
          removeCloseBtn={true}
          open={showPopup}
          setOpen={setShowPopup}
          title={"Pending Provider"}
          secondActionBtnName={"Proceed now"}
          // secondActionBtn={() => setGoToConfigPage(true)}
          secondActionBtn={() => {
            setGoToConfigPage(true);
            setShowPopup(false);
          }}
          firstActionBtnName={"Later"}
          firstActionBtnFct={() => {
            // setGoToConfigPage(true);
            navigate("/providers-management");
            localStorage.setItem("selectedTab", "providers-management");
            setShowPopup(false);
          }}
          // disableFirstBtn={
          //   !isEdit
          //     ? Country === "" || Operator === "" || Name === ""
          //     : Name === ""
          // }
          content={
            <div style={{ textAlign: "center" }}>
              <Typography
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  // fontSize: "17px",
                }}
              >
                Provider is successfully created and will be pending
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                Please add services configuration to activate
              </Typography>
            </div>
          }
        />
      )}

      {showConfigPopup && (
        <CustomizedDialogs
          removeCloseBtn={true}
          open={showConfigPopup}
          setOpen={setShowConfigPopup}
          title={"Configurations"}
          secondActionBtnName={"Continue Editing"}
          // secondActionBtn={() => setGoToConfigPage(true)}
          secondActionBtn={() => {
            // setGoToConfigPage(true);
            setShowConfigPopup(false);
          }}
          firstActionBtnName={"Navigate to Providers"}
          firstActionBtnFct={() => {
            // setGoToConfigPage(false);
            goToListing() && setShowConfigPopup(false);
            // navigate("/providers-management");
            // localStorage.setItem("selectedTab", "providers-management");
          }}
          // disableFirstBtn={
          //   !isEdit
          //     ? Country === "" || Operator === "" || Name === ""
          //     : Name === ""
          // }
          content={
            <div style={{ textAlign: "center" }}>
              <Typography
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  // fontSize: "17px",
                }}
              >
                Configurations have been updated successfully
              </Typography>
              {/* <Typography style={{ textAlign: "center" }}>
                Please add services configuration to activate
              </Typography> */}
            </div>
          }
        />
      )}

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
}
