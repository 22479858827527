import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../../Components/Selects/SelectFormNew";
import {
  ADD_SMS_ONE_WAY,
  GET_ALL_OPERATORS_OPTIONS,
  MULTI_TENANCY_CONFIG,
} from "../../../APIs";
import axiosInstance from "../../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

const ManageSmsOneWayRouting = ({
  open,
  setOpen,
  title,
  onButtonClick,
  isEdit,
  clientCategoryOptionsSearch,
  countryOptionsSearch,
  providerOptionsSearch,
  countryOptionsRes,
  setPaginationModel,
  setOpenAlert,
  setRefreshTable,
  setSmsOneWaySearch,
  selectedSms,
  paginationModel,

  // isoCode,
}) => {
  const [ClientCategory, setClientCategory] = useState("");
  const [isoCode, setIsoCode] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  // const [ClientCategoryOptions, setClientCategoryOptions] = useState([
  //   "category 1",
  //   "category 2",
  //   "category 3",
  //   "category 4",
  // ]);
  const [Operator, setOperator] = useState("");
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);
  const [Provider, setProvider] = useState(
    isEdit ? selectedSms?.providerGuid : ""
  );
  // const [ProviderOptions, setProviderOptions] = useState([
  //   "Provider 1",
  //   "Provider 2",
  //   "Provider 3",
  //   "Provider 4",
  // ]);
  const [Sourceprovider, setSourceprovider] = useState("");
  // const [SourceproviderOptions, setSourceproviderOptions] = useState([
  //   "Sourceprovider 1",
  //   "Sourceprovider 2",
  //   "Sourceprovider 3",
  //   "Sourceprovider 4",
  // ]);
  const [SourceOperator, setSourceOperator] = useState("");
  // const [SourceOperatorOptions, setSourceOperatorOptions] = useState([
  //   "SourceOperator 1",
  //   "SourceOperator 2",
  //   "SourceOperator 3",
  //   "SourceOperator 4",
  // ]);
  const [switchState, setSwitchState] = useState(
    selectedSms ? selectedSms?.isBlocked : false
  );
  const [country, setCountry] = useState("");
  // const [countryOptions, setCountryOptions] = useState([
  //   "country 1",
  //   "country 2",
  //   "country 3",
  //   "country 4",
  // ]);
  const [switchStateLock, setSwitchStateLock] = useState(
    selectedSms ? selectedSms?.isLocked : false
  );
  const [services, setServices] = useState(localStorage.getItem("services"));

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const handleSwitchChangeLock = () => {
    setSwitchStateLock(!switchStateLock);
  };
  useEffect(() => {
    console.log("selectedSmsselectedSms", selectedSms);
    if (isEdit && selectedSms) {
      setProvider(selectedSms?.providerGuid);
      setSwitchStateLock(selectedSms?.isLocked);
      setSwitchState(selectedSms?.isBlocked);
    }
  }, [selectedSms, isEdit, open]);
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);
  useEffect(() => {
    {
      !isEdit && setOperator("");
      setCountry("");
      setClientCategory("");
      setProvider("");
      setSwitchStateLock(false);
      setSwitchState(false);
      setClientCategory("");
    }
    {
      isEdit && setProvider(selectedSms?.providerGuid);
      setSwitchStateLock(selectedSms?.isLocked);
      setSwitchState(selectedSms?.isBlocked);
    }
  }, [open, isEdit]);
  const getAllOperatorName = () => {
    setIsLoadingOperator(true);
    setOperatorOptions([]);
    let newServices = JSON.parse(services);
    {
      setOperator("");
    }

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${isoCode}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        setIsLoadingOperator(false);

        if (err === "error: 401") {
         getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };
  useEffect(() => {
    if (isoCode !== "") {
      setOperatorOptions([]);
      getAllOperatorName();
    }
  }, [isoCode]);

  useEffect(() => {
    if (country !== "") {
      setOperatorOptions([]);
      let isoCodefinded = countryOptionsRes?.find(
        (x) => x.label === country
      )?.value;
      setIsoCode(isoCodefinded);
    } else {
      setIsoCode("");
      setOperator("");
      setOperatorOptions([]);
    }
  }, [country]);
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      clientCategoryGuid: ClientCategory,
      countryGuid: country,
      operatorGuid: Operator,
      providerGuid: Provider,
      isBlocked: switchState,
      isLocked: switchStateLock,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${ADD_SMS_ONE_WAY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });

        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        setOperator("");
        setCountry("");
        setProvider("");
        // setSmsOneWaySearch("");
        setClientCategory("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      recordGuid: selectedSms?.recordGuid,
      providerGuid: Provider,
      isBlocked: switchState,
      isLocked: switchStateLock,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${ADD_SMS_ONE_WAY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });
        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        setOperator("");
        setCountry("");
        setProvider("");
        // setSmsOneWaySearch("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitEdit();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={
          !isEdit
            ? country === "" ||
              Operator === "" ||
              Provider === "" ||
              ClientCategory === ""
              ? true
              : false
            : Provider === ""
            ? true
            : false
        }
        content={
          <>
            {isEdit ? (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={Provider}
                      setValue={setProvider}
                      options={providerOptionsSearch}
                      placeholder={"Provider"}
                      disabled={providerOptionsSearch.length === 0}
                      placementTop={isEdit&&true}

                    />
                  </Grid>
                </Grid>
                {/* <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Block
                </span>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchState}
                          onChange={handleSwitchChange}
                        />
                      }
                      // label={switchState ? "Block" : "Unblock"}
                    />
                  </Grid>
                </Grid>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Lock</span>

                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchStateLock}
                          onChange={handleSwitchChangeLock}
                        />
                      }
                      // label={switchStateLock ? "Lock" : "UnLock"}
                    />
                  </Grid>
                </Grid> */}
              </>
            ) : (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={ClientCategory}
                      setValue={setClientCategory}
                      options={clientCategoryOptionsSearch}
                      placeholder={"Client category"}
                      disabled={clientCategoryOptionsSearch.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={country}
                      setValue={setCountry}
                      options={countryOptionsSearch}
                      placeholder={"Country"}
                      disabled={countryOptionsSearch.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={Operator}
                      setValue={setOperator}
                      options={OperatorOptions}
                      placeholder={"Operator"}
                      disabled={OperatorOptions.length === 0 || country === ""}
                      isLoading={isLoadingOperator}
                      wait={"select a country"}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={Provider}
                      setValue={setProvider}
                      options={providerOptionsSearch}
                      disabled={providerOptionsSearch?.length === 0}
                      placeholder={"Provider"}
                    />
                  </Grid>
                </Grid>
                {/* <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Block
                </span>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchState}
                          onChange={handleSwitchChange}
                        />
                      }
                      // label={switchState ? "Block" : "Unblock"}
                    />
                  </Grid>
                </Grid>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Lock</span>

                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchStateLock}
                          onChange={handleSwitchChangeLock}
                        />
                      }
                      // label={switchStateLock ? "Lock" : "UnLock"}
                    />
                  </Grid>
                </Grid> */}
              </>
            )}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageSmsOneWayRouting;
