import * as Yup from "yup";

const validationSchema = [
  Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    // username: Yup.string().required("Username is required"),
    // Info: Yup.object().shape({
    //   IndustryTag: Yup.string().required("Industry is required"),
    // }),
    // password: Yup.string()
    // .required("Password is required")
    // .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    // .matches(/[!@#$%^&?*]/, "Password must contain at least one symbol")
    // .min(12, "Password must be at least 12 characters long")
    // .matches(/[0-9]/, "Password must contain at least one number"),
    // businesswebsite: Yup.string().required("Business website is required"),
    // phone: Yup.string().required("Phone is required")
    // .matches(/^[0-9]+$/, "Phone should contain only numbers"),
    // category: Yup.string().required("Category is required"),
    // account_manager: Yup.string().required("Account manager is required"),
    country: Yup.string().required("Country is required"),
    // region: Yup.string().required("Region is required"),
    // city: Yup.string().required("City is required"),
    // street: Yup.string().required("Street is required"),
    // building: Yup.string().required("Building is required"),
    // floor: Yup.string().required("Floor is required"),
    // address1: Yup.string().required("Address 1 is required"),
  }),
  Yup.object().shape({
    Alerts: Yup.boolean(),
    fromAddress: Yup.string().when("Alerts", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("From Address is required"),
      otherwise: (schema) => schema.optional(),
    }),
    ReplyAddress: Yup.string().when("Alerts", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("Reply Address is required"),
      otherwise: (schema) => schema.optional(),
    }),
    SmtpServer: Yup.string().when("Alerts", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("SMTP Server is required"),
      otherwise: (schema) => schema.optional(),
    }),
    SmtpUser: Yup.string().when("Alerts", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("SMTP User is required"),
      otherwise: (schema) => schema.optional(),
    }),
    SmtpPassword: Yup.string().when("Alerts", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("SMTP Password is required"),
      otherwise: (schema) => schema.optional(),
    }),
    SmtpPort: Yup.string().when("Alerts", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("SMTP Port is required").matches(/^[0-9]+$/, "Port should contain only numbers"),
      otherwise: (schema) => schema.optional(),
    }),
  }), 
  Yup.object().shape({
    // BillingEmail: Yup.string().required('Billing Email is required'),
    // TechnicalEmail: Yup.string().required('Technical Email is required'),
    // AlertsEmail: Yup.string().required('Alerts Email is required'),
  }),
];

export default validationSchema;
