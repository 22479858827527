import {
  Box,
  FormControlLabel,
  Grid,
  Switch
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ConfirmationModal from "../../../../Components/Dialog/ConfirmationModal";
import VerticalTabs from "../../../../Components/Tabs/VerticcalTabs";
// import ManageSmsTwoWayLongCode from "./ManageSmsTwoWayLongCode";
import LongCode from "./LongCode";
import ShortCode from "./ShortCode";
import HorizantalTabs from "../../../../Components/Tabs/ChannelTabs";
 
const SMSTwoWayRoutes = (props) => {
  const t = props.t;
  let { serviceGuid } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [shortcodeSearch, setShortcodeSearch] = useState("");
  const [longcodeSearch, setLongcodeSearch] = useState("");
 
  const [activeBtn, setActiveBtn] = useState("Longcode");
  const [btnHorizantal, setactiveBtnHorizantal] = useState("Longcode");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [isCheckedSwitchLocationZone, setCheckedSwitchLocationZone] =
    useState(false);
  const [isModalOpenSwitchLocationZone, setModalOpenSwitchLocationZone] =
    useState(false);
 
  const [isCheckedSwitchLock, setCheckedSwitchLock] = useState(false);
  const [isModalOpenSwitchLock, setModalOpenSwitchLock] = useState(false);
  const [switchStatesLocationZone, setSwitchStatesLocationZone] = useState({});
  const [switchStatesLock, setSwitchStatesLock] = useState({});
  useEffect(() => {
    if (activeBtn === "System Accounts") {
      setactiveBtnHorizantal("Shortcode");
    }
  }, [activeBtn]);
  // SystemAccounts
  const [isModalOpenSwitchSystemAccounts, setModalOpenSwitchSystemAccounts] =
    useState(false);
  const [isCheckedSwitchSystemAccounts, setCheckedSwitchSystemAccounts] =
    useState(false);
  const [switchStatesSystemAccounts, setswitchStatesSystemAccounts] = useState(
    {}
  );
 
  const [clientIdSearch, setClientIdSearch] = useState("");
  const [senderIdSearch, setSenderIdSearch] = useState("");
 
  const [clientCategoryOptionsSearch, setClientCategoryOptionsSearch] =
    useState(["category 1", "category 2", "category 3"]);
 
  const [countryOptionsSearch, setCountryOptionsSearch] = useState([
    "country 1",
    "country 2",
    "country 3",
  ]);
  const [providerOptionsSearch, seProviderOptionsSearch] = useState([
    "provider 1",
    "provider 2",
    "provider 3",
  ]);
  const [operatorOptionsSearch, setOperatorOptionsSearch] = useState([
    "operator 1",
    "operator 2",
    "operator 3",
  ]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [operatorSearch, setOperatorSearch] = useState("");
  const [countrySearch, setCountrySearch] = useState("");
  const [providerSearch, setProviderSearch] = useState("");
  const [mncSearch, setMncSearch] = useState("");
  const [mccSearch, setMccSearch] = useState("");
  
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(windowWidth > 1000 ? true : false);
  const [openAdvancedSearchLongcode, setOpenAdvancedSearchLongcode] =
    useState(windowWidth > 1000 ? true : false);
 
  const [refreshTable, setRefreshTable] = useState(false);
  const [refreshTableLongcode, setRefreshTableLongcode] = useState(false);
 
  const handleSwitchChangeSwitchSystemAccounts = (rowId) => {
    setModalOpenSwitchSystemAccounts(true);
    setCheckedSwitchSystemAccounts(rowId);
  };
 
  const handleModalCloseSwitchSystemAccounts = () => {
    setModalOpenSwitchSystemAccounts(false);
  };
  const handleModalConfirmSwitchSystemAccounts = () => {
    setModalOpenSwitchSystemAccounts(false);
    // setCheckedSwitchSystemAccounts(!isCheckedSwitchSystemAccounts);
    setswitchStatesSystemAccounts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchSystemAccounts]:
        !prevStates[isCheckedSwitchSystemAccounts],
    }));
  };
 
  //
  const handleOpenViewModal = () => {
    setOpenViewModal(!openViewModal);
  };
  // ManageClients
  const [isModalOpenSwitchManageClients, setModalOpenSwitchManageClients] =
    useState(false);
  const [isCheckedSwitchManageClients, setCheckedSwitchManageClients] =
    useState(false);
  const [switchStatesManageClients, setSwitchStatesManageClients] = useState(
    {}
  );
  const handleSwitchChangeSwitchManageClients = (rowId) => {
    setModalOpenSwitchManageClients(true);
    setCheckedSwitchManageClients(rowId);
  };
 
  const handleModalCloseSwitchManageClients = () => {
    setModalOpenSwitchManageClients(false);
  };
  const handleModalConfirmSwitchManageClients = () => {
    setModalOpenSwitchManageClients(false);
    // setCheckedSwitchManageClients(!isCheckedSwitchManageClients);
    setSwitchStatesManageClients((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchManageClients]: !prevStates[isCheckedSwitchManageClients],
    }));
  };
 
  //
 
  const [Client, setClient] = React.useState("");
 
  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [ManageClients, setManageClients] = React.useState("");
 
  const handleChangeManageClients = (event) => {
    setManageClients(event.target.value);
  };
  const handleSwitchChangeSwitchLocationZone = (rowId) => {
    setModalOpenSwitchLocationZone(true);
    setCheckedSwitchLocationZone(rowId);
  };
 
  const handleSwitchChangeSwitchLock = (rowId) => {
    setModalOpenSwitchLock(true);
    setCheckedSwitchLock(rowId);
  };
  const handleModalCloseSwitchLocationZone = () => {
    setModalOpenSwitchLocationZone(false);
  };
  const handleModalCloseSwitchLock = () => {
    setModalOpenSwitchLock(false);
  };
  const tableDataSystemAccounts = [
    {
      id: 1,
      clientId: "5142",
      operator: "Globallcell",
      country: "Lebanon",
      provider: "provider",
      senderId: "123456",
      mnc: "12",
      mcc: "10",
    },
  ];
  let columnsSystemAccounts = [
    {
      field: "clientId",
      headerName: "Client ID",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "operator",
      headerName: "Operator",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "senderId",
      headerName: "Sender ID",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mnc",
      headerName: "MNC",
      flex: 1,
      minWidth: 70,
    },
    {
      field: "mcc",
      headerName: "MCC",
      flex: 1,
      minWidth: 70,
    },
 
    {
      headerName: "Block",
      field: "blocking",
      minWidth: 100,
 
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesLocationZone[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchLocationZone(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      headerName: "Lock",
      field: "lock",
      minWidth: 100,
 
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesLock[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchLock(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const tableDataManageClients = [
    {
      id: 1,
      clientId: "1251",
      operator: "Globallcell",
      country: "Lebanon",
      provider: "provider",
      mnc: "12",
      mcc: "10",
    },
  ];
  let columnsManageClients = [
    {
      field: "clientId",
      headerName: "Client ID",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "operator",
      headerName: "Operator",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "mnc",
      headerName: "MNC",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "mcc",
      headerName: "MCC",
      flex: 1,
      minWidth: 90,
    },
 
    {
      headerName: "Block",
      field: "blocking",
      minWidth: 90,
 
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesLocationZone[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchLocationZone(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      headerName: "Lock",
      field: "lock",
      minWidth: 90,
 
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesLock[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchLock(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
 
  const tabs = [
    {
      label: "Routing",
      subTabs: [{ label: "Longcode" }, { label: "Shortcode" }],
    },
  ];
 
  const subTabs = [
    {
      label: "Longcode",
    },
    {
      label: "Shortcode",
    },
  ];
 
  // Delete Modal SystemAccounts functions
  const [ConfirmOpenSystemAccounts, setConfirmOpenSystemAccounts] =
    useState(false);
  const [modalContentSystemAccounts, setModalContentSystemAccounts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalSystemAccounts = (title, text, onButtonClick) => {
    setModalContentSystemAccounts({ title, text, onButtonClick });
    setConfirmOpenSystemAccounts(true);
  };
 
  const handleConfirmModalSystemAccounts = () => {
    setConfirmOpenSystemAccounts(false);
  };
 
  useEffect(() => {
    console.log(tabs);
  }, [])
 
  //
 
  // Delete Modal ManageClients functions
  const [ConfirmOpenManageClients, setConfirmOpenManageClients] =
    useState(false);
  const [modalContentManageClients, setModalContentManageClients] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenManageLongcode, setConfirmOpenManageLongcode] =
    useState(false);
  const [modalContentManageLongcode, setModalContentManageLongcode] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalManageClients = (title, text, onButtonClick) => {
    setModalContentManageClients({ title, text, onButtonClick });
    setConfirmOpenManageClients(true);
  };
  const OpenConfirmModalManageLongcode = (title, text, onButtonClick) => {
    setModalContentManageLongcode({ title, text, onButtonClick });
    setConfirmOpenManageLongcode(true);
  };
  const handleConfirmModalManageClients = () => {
    setConfirmOpenManageClients(false);
  };
  const handleConfirmModalManageLongcode = () => {
    setConfirmOpenManageLongcode(false);
  };
  //
 
  // Manage SystemAccounts
  const [MangeSystemAccountsAddopen, setMangeSystemAccountsAddopen] =
    useState(false);
  const [MangeSystemAccountsEditopen, setMangeSystemAccountsEditopen] =
    useState(false);
 
  const handleAddMangeSystemAccountsopen = () => {
    setMangeSystemAccountsAddopen(true);
  };
 
  const handleAddMangeSystemAccountsclose = () => {
    setMangeSystemAccountsAddopen(false);
  };
  const handleEditMangeSystemAccountsopen = () => {
    setMangeSystemAccountsEditopen(true);
  };
 
  const handleEditMangeSystemAccountsclose = () => {
    setMangeSystemAccountsEditopen(false);
  };
 
  // Manage ManageClients
  const [ManageClientAddopen, setManageClientAddopen] = useState(false);
  const [ManageClientEditopen, setManageClientEditopen] = useState(false);
 
  const handleAddManageClientopen = () => {
    setManageClientAddopen(true);
  };
 
  const handleAddManageClientclose = () => {
    setManageClientAddopen(false);
  };
  const handleEditManageClientopen = () => {
    setManageClientEditopen(true);
  };
  const handleEditManageLongcodeopen = () => {
    setManageClientEditopen(true);
  };
  const handleEditManageClientclose = () => {
    setManageClientEditopen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setClientIdSearch("");
    setCountrySearch("");
    setOperatorSearch("");
    setProviderSearch("");
    setMncSearch("");
    setMccSearch("");
  }, [activeBtn]);
 
  const [openDrawer, setOpenDrawer] = React.useState(false);
 
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleOpenAdvancedSearch = () => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  };
  const handleOpenAdvancedSearchLongcode = () => {
    setOpenAdvancedSearchLongcode(!openAdvancedSearchLongcode);
  };
  return (
    <div style={{ marginTop: "-80px", width: "104%", marginLeft: "-54px" }}>
      <Grid style={{ marginTop: "-80px" }} container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <></>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="end"
          style={{ marginBottom: "20px" }}
        >
 
       
          {/* {activeBtn === "Shortcode" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeSystemAccountsopen}
            >
              <Add sx={{ fontSize: "17px" }} />
              <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
                {t("Add Shortcode")}
              </Typography>
            </Button>
          )}
 
          {activeBtn === "Longcode" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddManageClientopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {t("Add Longcode")}
              </Typography>
            </Button>
          )} */}
        </Grid>
      </Grid>
 
      <Grid container style={{marginLeft:'50px', marginTop:'20px'}}>
            {windowWidth < 1000 && (
              <Grid
                style={{ marginLeft: "0px" }}
                container
                marginLeft={2}
                marginTop={5}
              >
                <HorizantalTabs
                  tabs={subTabs}
                  setActiveBtn={setActiveBtn}
                  onTabChange={setActiveBtn}
                />
              </Grid>
            )}
          </Grid>
   
      <Grid container className="Top-spacing div-table">
      {windowWidth > 1000 && (
        <Grid
          item
          xs={windowWidth < 1800 ? 2 : 1.5}
        // md={windowWidth < 1800 ? 1 : 1.5}
        // lg={2}
        >
          <VerticalTabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />
        </Grid> )}
        <Grid
          item
          xs={windowWidth < 1800 ? 10 : 10.5}
        // md={windowWidth < 1800 ? 11 : 10.5}
        // lg={10}
        >
          <>
            {/* <Grid container>
                <Grid xs={12} item>
                  <HorizantalTabs
                    tabs={tabsHorizantal}
                    setActiveBtn={setactiveBtnHorizantal}
                  />
                </Grid>
              </Grid> */}
 
            <Grid container>
              <Grid style={{ marginTop: "10px" }} item xs={12}>
                {activeBtn === "Shortcode" && (
                  <ShortCode serviceGuid={serviceGuid} />
                )}
              </Grid>
            </Grid>
          </>
 
          {activeBtn === "Longcode" && <LongCode serviceGuid={serviceGuid} />}
          {/* {activeBtn === "Shortcode" && <LongCode serviceGuid={serviceGuid} />} */}
        </Grid>
      </Grid>
 
      {/* Delete SystemAccounts Modal */}
      {handleConfirmModalSystemAccounts && (
        <ConfirmationModal
          open={ConfirmOpenSystemAccounts}
          onClose={handleConfirmModalSystemAccounts}
          title={t("Delete")}
          text={t("Are you sure you want to delete record?")}
          onButtonClick={handleConfirmModalSystemAccounts}
        />
      )}
      {/* Delete ManageClients Modal */}
      {handleConfirmModalManageClients && (
        <ConfirmationModal
          open={ConfirmOpenManageClients}
          onClose={handleConfirmModalManageClients}
          title={t("Delete")}
          text={t("Are you sure you want to delete record?")}
          onButtonClick={handleConfirmModalManageClients}
        />
      )}
      {handleConfirmModalManageLongcode && (
        <ConfirmationModal
          open={ConfirmOpenManageLongcode}
          onClose={handleConfirmModalManageLongcode}
          title={t("Delete")}
          text={t("Are you sure you want to delete record?")}
          onButtonClick={handleConfirmModalManageLongcode}
        />
      )}
      {/* Manage SystemAccounts Modal */}
 
      {/* Swtich SystemAccounts Modal */}
      {isModalOpenSwitchSystemAccounts && (
        <ConfirmationModal
          open={isModalOpenSwitchSystemAccounts}
          onClose={handleModalCloseSwitchSystemAccounts}
          title={t("Change Status")}
          text={t("Are you sure you want to change this user Status?")}
          onButtonClick={handleModalConfirmSwitchSystemAccounts}
        />
      )}
 
      {/* Swtich ManageClients Modal */}
      {isModalOpenSwitchManageClients && (
        <ConfirmationModal
          open={isModalOpenSwitchManageClients}
          onClose={handleModalCloseSwitchManageClients}
          title={t("Change Status")}
          text={t("Are you sure you want to change this client Status?")}
          onButtonClick={handleModalConfirmSwitchManageClients}
        />
      )}
      {isModalOpenSwitchLocationZone && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationZone}
          onClose={handleModalCloseSwitchLocationZone}
          title="Change block status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalCloseSwitchLocationZone}
        />
      )}
      {isModalOpenSwitchLock && (
        <ConfirmationModal
          open={isModalOpenSwitchLock}
          onClose={handleModalCloseSwitchLock}
          title="Change Lock Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalCloseSwitchLock}
        />
      )}
      <div></div>
    </div>
  );
};
 
export default withTranslation("translations")(SMSTwoWayRoutes);
 