import * as Icons from "@mui/icons-material";
import axios from "axios";
import Notification from "../Components/Notification/Notification";

export const DynamicIcon =  ({ iconName, iconColor,style }) => {
  // Convert the iconName to PascalCase (e.g., 'home' to 'Home')
  const formattedIconName =
    iconName.charAt(0).toUpperCase() + iconName.slice(1);

  // Check if the icon exists in the Icons object
  if (Icons[formattedIconName]) {
    const SelectedIcon = Icons[formattedIconName];
    return <SelectedIcon sx={{ color: iconColor,...style }} />;
  } else {
    // Handle case where the icon is not found
    return <span>Icon not found</span>;
  }
}
export const convertArrayOfObjectsToCSV = (columns = [], data = []) => {
  let result = "", keys = [];
  columns.forEach(element => {
      if (element.hasOwnProperty("export")) {
          if (element.export) 
              keys.push(element.title);
      } else if (element.hasOwnProperty("hidden")) {
          if (!element.hidden) 
              keys.push(element.title);
      } else 
          keys.push(element.title);
  });
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  columns.forEach((element, index) => {
      if (element.hasOwnProperty("export")) {
          if (element.export) 
              keys[index] = element.field || "";
      } else if (element.hasOwnProperty("hidden")) {
          if (!element.hidden) 
              keys[index] = element.field || "";
      } else 
          keys[index] = element.field || "";
  });
  data.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
          if (ctr > 0) result += columnDelimiter;
              const edited = typeof item[key] == "string" ? item[key]?.replace(/,/g, ";")?.replace(/#/g, " ") : item[key];
              result += edited;
          ctr ++;
      });
      result += lineDelimiter;
  });
  return result;
}
export const downloadCSV = (columns = [], data = [], title = "Excel", t = value => value) => {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(columns, data);
  if (!csv) return;
  const filename = `${t(title)}.csv`;
  if (!csv.match(/^data:text\/csv/i)) 
      csv = `data:text/csvcharset=utf-8,${csv}`;
  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

function timestampToDate(timestamp) {
  // Create a new Date object and set it to the UTC time based on the timestamp
  const date = new Date(0);
  date.setUTCSeconds(timestamp);

  return date;
}


export const HandleApiError = (e) => {
  if (e?.response?.data?.Status === 500) {
    Notification.error(e?.response?.data?.Exception || "Something Went Wrong");
  } else {
    Notification.error(
      e?.response?.data?.message ||
        e?.response?.data?.errors?.Name[0] ||
        e?.response?.data?.result?.message ||
        "Something Went Wrong"
    );
  }
};


export const downloadFile = async (url, filename) => {
  try {
    const response = await axios({
      method: 'get',
      url: url,
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    throw error; // Propagate the error to the calling function
  }
};

export const handleMessageError = ({ e, type = null }) => {
  if (type == "validation"){
    let objKeys = Object.keys(e?.response?.data?.Data || {});
    if (objKeys.length === 0) {
      return (
        e?.response?.data?.errors?.Name[0] ||
        e?.response?.data?.Message ||
        e?.response?.data?.message ||
        e?.response?.data?.result?.message ||
        "Something Went Wrong"
      );
    }
    return e?.response?.data?.Data[objKeys[0]];
  }else{
    if (e?.response?.data?.Status === 500) {
      return e?.response?.data?.Exception || "Something Went Wrong";
    } else {
      return (
        e?.response?.data?.Message ||
        e?.response?.data?.message ||
        e?.response?.data?.errors?.Name[0] ||
        e?.response?.data?.result?.message ||
        "Something Went Wrong"
      );
    }
  }
};

export function updateState(obj, propertyPath, newValue) {
  const keys = propertyPath.split(".");
  const lastIndex = keys.length - 1;
  let currentObj = obj;

  for (let i = 0; i < lastIndex; i++) {
    const key = keys[i];
    if (!currentObj[key] || typeof currentObj[key] !== "object") {
      throw new Error(`Key "${key}" does not exist at this level.`);
    }
    currentObj = currentObj[key];
  }

  const lastKey = keys[lastIndex];
  if (Array.isArray(currentObj)) {
    const index = parseInt(lastKey);
    if (isNaN(index) || index < 0 || index >= currentObj.length) {
      throw new Error(`Invalid array index "${lastKey}".`);
    }
  }

  currentObj[lastKey] = newValue;
  return { ...obj }; // Return a new object to ensure immutability
}

export function generatePassword() {
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const specialChars = "!@#$%^&*()-_=+[]{}|;:,.<>?";
  const digitChars = "0123456789";

  // Initialize an empty password string
  let password = "";

  // Add one character from each character set
  password += uppercaseChars.charAt(
    Math.floor(Math.random() * uppercaseChars.length)
  );
  password += lowercaseChars.charAt(
    Math.floor(Math.random() * lowercaseChars.length)
  );
  password += specialChars.charAt(
    Math.floor(Math.random() * specialChars.length)
  );
  password += digitChars.charAt(Math.floor(Math.random() * digitChars.length));

  // Add additional characters to meet the minimum length
  const minLength = 8;
  const remainingLength = minLength - password.length;

  for (let i = 0; i < remainingLength; i++) {
    const allChars =
      uppercaseChars + lowercaseChars + specialChars + digitChars;
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  // Shuffle the characters in the password to make it more random
  password = password.split("");
  for (let i = password.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [password[i], password[j]] = [password[j], password[i]];
  }
  password = password.join("");

  return password;
}

export function removeNullKeys(obj) {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeNullKeys(obj[key]);
    }
  }
  return obj;
}

export function removeEmptyVariables(obj) {
  for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
          // If the value is an object (and not null), recursively call removeEmptyVariables
          obj[key] = removeEmptyVariables(obj[key]);
          // If the object is now empty or contains only null values, remove the key
          if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
              delete obj[key];
          }
      } else if (obj[key] === "" || obj[key] === undefined || obj[key] === null) {
          // If the value is empty, undefined, or null, remove the key
          delete obj[key];
      }
  }
  return obj;
}
export function convertToTimeString(number) {
  var hours = Math.floor(number); // Integer division to get hours
  var minutes = Math.round((number % 1) * 60); // Remainder converted to minutes

  // Padding single digit hours and minutes with a leading zero
  var hoursString = hours.toString().padStart(2, '0');
  var minutesString = minutes.toString().padStart(2, '0');

  return hoursString + ":" + minutesString;
}

export function getFirstPageRoute(menuData) {
  if (!menuData || menuData.length === 0) {
    return null;
  }

  const firstPage = menuData[0];
  const firstPageUri = firstPage.uri || '';

  if (firstPage.children && firstPage.children.length > 0) {
    const firstSubpageUri = firstPage.children[0].uri || '';
    return `${firstPageUri}/${firstSubpageUri}`;
  }

  return firstPageUri;
}

export function createMenuTree(menuData) {
  const menuMap = {};

  // Create a map of menu items using their recordGuid as the key
  menuData.forEach((item) => {
    const recordGuid = item.recordGuid;
    item.children = [];
    menuMap[recordGuid] = item;
  });

  // Organize the menu items into a tree structure
  const rootItems = [];
  menuData.forEach((item) => {
    const parentGuid = item.parentGuid;
    if (parentGuid !== null && menuMap[parentGuid]) {
      menuMap[parentGuid].children.push(item);
    } else {
      rootItems.push(item);
    }
  });

  return rootItems;
}

export function getSevenDaysAgo() {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)); // 7 days in milliseconds
  return sevenDaysAgo;
}

export const GET_CALLBACK = ({ provider }) => {
  if (!provider) return null
  return `${window.location.origin}/callback-${provider}`
}

export const ConvertBlob = ({ type, image, output = "image/jpeg" }) => {
  if (type === "blob") {
    return new Promise((resolve, reject) => {
      image.toBlob((blob) => {
        if (blob) {
          resolve(URL.createObjectURL(blob));
        } else {
          reject(new Error("Failed to create blob."));
        }
      }, output);
    });
  } else if (type === "file") {
    return new Promise((resolve, reject) => {
      image.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], `croppedImage.${output == "image/jpeg" ? 'jpeg' : 'png'}`, {
            type: output,
          });
          resolve(file);
        } else {
          reject(new Error("Failed to create file."));
        }
      }, output);
    });
  } else {
    // Handle other response types or raise an error for unsupported types
    return Promise.reject(new Error("Unsupported responseType"));
  }
};

export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      // The result attribute contains the Base64 data
      const base64String = event.target.result;
      resolve(base64String);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    // Read the file as a Data URL, which is a Base64 representation
    reader.readAsDataURL(file);
  });
}