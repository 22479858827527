import Add from "@mui/icons-material/Add";
import {
  Box,
  CardContent,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import ManageClient from "./ManageClient";
import ManageUser from "./ManageUser";
import { withTranslation } from "react-i18next";
import HorizantalTabs from "../../../Components/Tabs/HorizantalTabs";
import PasswordConfiguration from "./PasswordConfiguration";
const MembersManagement = (props) => {
  const t = props.t;
  const [isLoading, setIsLoading] = useState(true);
  const [activeBtn, setActiveBtn] = useState("Users");
  const [btnHorizantal, setactiveBtnHorizantal] = useState("Users");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  useEffect(() => {
    if (activeBtn === "System Accounts") {
      setactiveBtnHorizantal("Users");
    }
  }, [activeBtn]);
  // SystemAccounts
  const [isModalOpenSwitchSystemAccounts, setModalOpenSwitchSystemAccounts] =
    useState(false);
  const [isCheckedSwitchSystemAccounts, setCheckedSwitchSystemAccounts] =
    useState(false);
  const [switchStatesSystemAccounts, setswitchStatesSystemAccounts] = useState(
    {}
  );
  const handleSwitchChangeSwitchSystemAccounts = (rowId) => {
    setModalOpenSwitchSystemAccounts(true);
    setCheckedSwitchSystemAccounts(rowId);
  };

  const handleModalCloseSwitchSystemAccounts = () => {
    setModalOpenSwitchSystemAccounts(false);
  };
  const handleModalConfirmSwitchSystemAccounts = () => {
    setModalOpenSwitchSystemAccounts(false);
    // setCheckedSwitchSystemAccounts(!isCheckedSwitchSystemAccounts);
    setswitchStatesSystemAccounts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchSystemAccounts]:
        !prevStates[isCheckedSwitchSystemAccounts],
    }));
  };

  //
  const handleOpenViewModal = () => {
    setOpenViewModal(!openViewModal);
  };
  // ManageClients
  const [isModalOpenSwitchManageClients, setModalOpenSwitchManageClients] =
    useState(false);
  const [isCheckedSwitchManageClients, setCheckedSwitchManageClients] =
    useState(false);
  const [switchStatesManageClients, setSwitchStatesManageClients] = useState(
    {}
  );
  const handleSwitchChangeSwitchManageClients = (rowId) => {
    setModalOpenSwitchManageClients(true);
    setCheckedSwitchManageClients(rowId);
  };

  const handleModalCloseSwitchManageClients = () => {
    setModalOpenSwitchManageClients(false);
  };
  const handleModalConfirmSwitchManageClients = () => {
    setModalOpenSwitchManageClients(false);
    // setCheckedSwitchManageClients(!isCheckedSwitchManageClients);
    setSwitchStatesManageClients((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchManageClients]: !prevStates[isCheckedSwitchManageClients],
    }));
  };

  //

  const [Client, setClient] = React.useState("");

  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [ManageClients, setManageClients] = React.useState("");

  const handleChangeManageClients = (event) => {
    setManageClients(event.target.value);
  };
  const tableDataSystemAccounts = [
    {
      id: 1,
      firstName: "mimimimi",
      lastName: "1212121",
      userName: "naim.jaber401111",
      email: "sourceProvider",
      status: "Inactive",
    },
    {
      id: 1,
      firstName: "admin",
      lastName: "user",
      userName: "admin_user",
      email: "	fitor19756@qianhost.com",
      status: "Inactive",
    },
  ];
  let columnsSystemAccounts = [
    {
      field: "firstName",
      headerName: t("First Name"),
      flex: 1,
    },
    {
      field: "lastName",
      headerName: t("Last Name"),
      flex: 1,
    },
    {
      field: "userName",
      headerName: t("Username"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("Email"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("status"),
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesSystemAccounts[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchSystemAccounts(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const tableDataManageClients = [
    {
      id: 1,
      firstName: "georges",
      lastName: "nicolas",
      userName: "nad800",
      email: "dd@gmail.com",
      status: "Inactive",
    },
    {
      id: 1,
      firstName: "final update fn 13",
      lastName: "final update ln 1",
      userName: "ns13",
      email: "n12231@dfs33mail.com",
      status: "Inactive",
    },
  ];
  let columnsManageClients = [
    {
      field: "firstName",
      headerName: t("First Name"),
      flex: 1,
    },
    {
      field: "lastName",
      headerName: t("Last Name"),
      flex: 1,
    },
    {
      field: "userName",
      headerName: t("Username"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("Email"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("Status"),
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesManageClients[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchManageClients(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tabs = [
    {
      label: t("System Accounts"),
      subTabs: [
        { label: "Users" },
        { label: "Roles" },
        { label: "Pass Configuration" },
      ],
    },
    // {
    //   label: t("Manage Clients"),
    // },
  ];
  const tabsHorizantal = [
    {
      label: t("Users"),
    },
    {
      label: t("Roles"),
    },
    {
      label: t("Pass Configuration"),
    },
  ];

  // Delete Modal SystemAccounts functions
  const [ConfirmOpenSystemAccounts, setConfirmOpenSystemAccounts] =
    useState(false);
  const [modalContentSystemAccounts, setModalContentSystemAccounts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalSystemAccounts = (title, text, onButtonClick) => {
    setModalContentSystemAccounts({ title, text, onButtonClick });
    setConfirmOpenSystemAccounts(true);
  };

  const handleConfirmModalSystemAccounts = () => {
    setConfirmOpenSystemAccounts(false);
  };

  //

  // Delete Modal ManageClients functions
  const [ConfirmOpenManageClients, setConfirmOpenManageClients] =
    useState(false);
  const [modalContentManageClients, setModalContentManageClients] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalManageClients = (title, text, onButtonClick) => {
    setModalContentManageClients({ title, text, onButtonClick });
    setConfirmOpenManageClients(true);
  };

  const handleConfirmModalManageClients = () => {
    setConfirmOpenManageClients(false);
  };

  //

  // Manage SystemAccounts
  const [MangeSystemAccountsAddopen, setMangeSystemAccountsAddopen] =
    useState(false);
  const [MangeSystemAccountsEditopen, setMangeSystemAccountsEditopen] =
    useState(false);

  const handleAddMangeSystemAccountsopen = () => {
    setMangeSystemAccountsAddopen(true);
  };

  const handleAddMangeSystemAccountsclose = () => {
    setMangeSystemAccountsAddopen(false);
  };
  const handleEditMangeSystemAccountsopen = () => {
    setMangeSystemAccountsEditopen(true);
  };

  const handleEditMangeSystemAccountsclose = () => {
    setMangeSystemAccountsEditopen(false);
  };

  // Manage ManageClients
  const [ManageClientAddopen, setManageClientAddopen] = useState(false);
  const [ManageClientEditopen, setManageClientEditopen] = useState(false);

  const handleAddManageClientopen = () => {
    setManageClientAddopen(true);
  };

  const handleAddManageClientclose = () => {
    setManageClientAddopen(false);
  };
  const handleEditManageClientopen = () => {
    setManageClientEditopen(true);
  };

  const handleEditManageClientclose = () => {
    setManageClientEditopen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <>
            {activeBtn !== "Manage Clients" && (
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <Typography className="BreadcrumbsPage">
                  {t("System Accounts")}
                </Typography>
                <Typography className="breadcrumbactiveBtn">
                  {activeBtn}
                </Typography>
              </Breadcrumbs>
            )}
            {activeBtn === "Manage Clients" && (
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <Typography className="BreadcrumbsPage">
                  {t("Manage Clients")}
                </Typography>
                {/* <Typography className="breadcrumbactiveBtn">
   {activeBtn}
 </Typography> */}
              </Breadcrumbs>
            )}
          </>
        </Grid>
        <Grid item xs={12} md={6} className="end">
      
              {activeBtn === "Users" && (
                <Button
                  variant="contained"
                  className="contained"
                  onClick={handleAddMangeSystemAccountsopen}
                >
                  <Add sx={{ fontSize: "17px" }} />
                  <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
                    {t("Add User")}
                  </Typography>
                </Button>
              )}
       
          {activeBtn === "Manage Clients" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddManageClientopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {t("Add Client")}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container className="Top-spacing div-table">
        <Grid item xs={3.5} md={1.5} lg={2}>
          <VerticalTabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />
        </Grid>
        <Grid item xs={8.5} md={10.5} lg={10}>
            <>
              {/* <Grid container>
                <Grid xs={12} item>
                  <HorizantalTabs
                    tabs={tabsHorizantal}
                    setActiveBtn={setactiveBtnHorizantal}
                  />
                </Grid>
              </Grid> */}

              <Grid container>
                <Grid xs={12} item>
                  {activeBtn === "Users" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataSystemAccounts?.totalCount}
                        data={tableDataSystemAccounts}
                        // isLoading={isLoading}
                        columns={columnsSystemAccounts}
                        pageIndex={
                          tableDataSystemAccounts?.pageIndex
                            ? tableDataSystemAccounts?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        // enableDelete={true}
                        enableEdit={true}
                        // deleteFunction={OpenConfirmModalSystemAccounts}
                        editFunction={handleEditMangeSystemAccountsopen}
                        enableSearch={true}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}
                  {activeBtn === "Roles" && (
                    <Paper variant="CardTable"> Roles </Paper>
                  )}
                  {activeBtn === "Pass Configuration" && (
                    <Paper variant="CardTable">
                      {" "}
                      <PasswordConfiguration />{" "}
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </>
          
          {activeBtn === "Manage Clients" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataManageClients?.totalCount}
                data={tableDataManageClients}
                // isLoading={isLoading}
                columns={columnsManageClients}
                pageIndex={
                  tableDataManageClients?.pageIndex
                    ? tableDataManageClients?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalManageClients}
                editFunction={handleEditManageClientopen}
                enableSearch={true}
                enableExportBtn={true}
              />
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* Delete SystemAccounts Modal */}
      {handleConfirmModalSystemAccounts && (
        <ConfirmationModal
          open={ConfirmOpenSystemAccounts}
          onClose={handleConfirmModalSystemAccounts}
          title={t("Delete")}
          text={t("Are you sure you want to delete this user?")}
          onButtonClick={handleConfirmModalSystemAccounts}
        />
      )}
      {/* Delete ManageClients Modal */}
      {handleConfirmModalManageClients && (
        <ConfirmationModal
          open={ConfirmOpenManageClients}
          onClose={handleConfirmModalManageClients}
          title={t("Delete")}
          text={t("Are you sure you want to delete this client?")}
          onButtonClick={handleConfirmModalManageClients}
        />
      )}

      {/* Manage SystemAccounts Modal */}
      {handleAddMangeSystemAccountsopen && (
        <ManageUser
          open={MangeSystemAccountsAddopen}
          setOpen={setMangeSystemAccountsAddopen}
          title={t("Add User")}
        />
      )}
      {handleEditMangeSystemAccountsopen && (
        <ManageUser
          open={MangeSystemAccountsEditopen}
          setOpen={setMangeSystemAccountsEditopen}
          title={t("Edit User")}
        />
      )}

      {/* Manage ManageClients Modal */}
      {handleAddManageClientopen && (
        <ManageClient
          open={ManageClientAddopen}
          setOpen={setManageClientAddopen}
          title={t("Add Client")}
        />
      )}
      {handleEditManageClientopen && (
        <ManageClient
          open={ManageClientEditopen}
          setOpen={setManageClientEditopen}
          title={t("Edit Client")}
        />
      )}

      {/* Swtich SystemAccounts Modal */}
      {isModalOpenSwitchSystemAccounts && (
        <ConfirmationModal
          open={isModalOpenSwitchSystemAccounts}
          onClose={handleModalCloseSwitchSystemAccounts}
          title={t("Change Status")}
          text={t("Are you sure you want to change this user Status?")}
          onButtonClick={handleModalConfirmSwitchSystemAccounts}
        />
      )}

      {/* Swtich ManageClients Modal */}
      {isModalOpenSwitchManageClients && (
        <ConfirmationModal
          open={isModalOpenSwitchManageClients}
          onClose={handleModalCloseSwitchManageClients}
          title={t("Change Status")}
          text={t("Are you sure you want to change this client Status?")}
          onButtonClick={handleModalConfirmSwitchManageClients}
        />
      )}
      <div></div>
    </div>
  );
};

export default withTranslation("translations")(MembersManagement);
