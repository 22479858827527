import React, { createRef, useCallback, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Grid } from "@mui/material";
import { toast } from "react-toastify"
import { MULTI_TENANCY_CONFIG } from "../../Service-Manager/APIs";
import axiosInstance from "../../axiosInstance";

export function MyDropzone({ file, setFile, nameProvided=false, downloadSample = false, sampleUrl = "" }) {
  //   const [file, setFile] = useState('')
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  //   const dropzoneRef = createRef()

  const getAcceptedFile = ([file]) => {
    setFile(file);
  };

  const resetFile = () => {
    setFile('');
  };

  const getRejectedFile = ([file]) => {
    // Destructuring files array to get the first index by using [file]
    file.errors.forEach(({ message, code }) => {
      if (code === "file-too-large")
        toast.error("You can't upload more than 4MB");
    });
  };

  const download = async ()=>{
    if (sampleUrl) {
      try {
        let service = JSON.parse(localStorage.getItem("services"));
        // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
        MULTI_TENANCY_CONFIG.headers.Authorization =
          "Bearer " + localStorage.getItem("token");
        MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  
        let res = await axiosInstance.get(
          sampleUrl,
          {...MULTI_TENANCY_CONFIG,responseType: 'blob'}
        );


        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Sample.${sampleUrl?.includes("billing") ? "xlsx" : "csv"}`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
       
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles, fileRejections) => {
          if (acceptedFiles.length > 0) getAcceptedFile(acceptedFiles);
          if (fileRejections.length > 0) getRejectedFile(fileRejections);
        }}
        accept=".xlsx, .xls, .csv"
        multiple={false}
        maxSize={4000000} // 4MB
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone center">
            <input {...getInputProps()} />

            {/* <Grid container className='center'> */}
            <FileUploadIcon className="import-icon" />
            <br />
            {/* </Grid> */}
            <Grid container className="center">
              <p className="drag-text">
                {(nameProvided && file) ? file : file ? file?.name : "Drag and Drop File Here"}
              </p>
            </Grid>
          </div>
        )}
      </Dropzone>
      {downloadSample && 
      <p className="download-sample" onClick={download}>
        Download Sample
      </p>
      }
    </>
  );
}
