import React from "react";
import { ResellerProvider, useResellerContext } from "./ResellerContext";

import {
  Accordion,
  AccordionSummary,
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AccordionDetails from "@mui/material/AccordionDetails";
import InfoIcon from "@mui/icons-material/Info";

import {
  HandleApiError,
  handleMessageError,
  updateState,
} from "../../../Utils/functions";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_ALL_CATEGORIES,
  GET_ALL_CLIENTS,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_CTIES,
  GET_CLIENT_BY_ID,
  GET_SAAS_CONFIG,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import { ExpandMore } from "@mui/icons-material";
import ConfigurationSection from "./Sections/ConfigurationSection";

//   import ConfigurationSection from "./Sections/ConfigurationSection";

function EditResellerConfigComponent() {
  const navigate = useNavigate();

  const { formik, GlobalData, SetGlobalData } = useResellerContext();
  const { steps, activeStep } = GlobalData;

  const [Countries, SetCountries] = useState([]);
  const [clientData, setClientData] = useState([]);

  const [Categories, SetCategories] = useState([]);
  const [Cities, SetCities] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { id } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleBack = () => {
    if (activeStep == 0) navigate("/reseller-management");
    else SetGlobalData(updateState(GlobalData, "activeStep", activeStep - 1));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    SetGlobalData(updateState(GlobalData, "openMessageAlert", false));
  };

  const GetAllCountries = async () => {
    try {
      let service = JSON.parse(localStorage.getItem("services"));
      // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let countriesResponse = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?PageIndex=1&pageSize=400`,
        MULTI_TENANCY_CONFIG
      );

      SetCountries(countriesResponse?.data?.data?.countries);
    } catch (e) {}
  };

  const getCities = async ({ country }) => {
    try {
      let service = JSON.parse(localStorage.getItem("services"));
      // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let citiesResponse = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_CTIES}?CountryGuid=${country}`,
        MULTI_TENANCY_CONFIG
      );

      SetCities(citiesResponse?.data?.data?.cities);
    } catch (e) {

    }
  };

  const GetAllCategories = async () => {
    try {
      let service = JSON.parse(localStorage.getItem("services"));
      // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let categoriesResponse = await await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${GET_ALL_CATEGORIES}`,
        MULTI_TENANCY_CONFIG
      );

      SetCategories(
        categoriesResponse?.data?.data?.clientCategory?.map((item) => {
          return {
            name: item?.clientCategoryDetails[0]?.name,
            id: item?.recordGuid,
          };
        })
      );
    } catch (e) {

    }
  };

  const circleStyle = {
    width: 35, // Set the desired width
    height: 35, // Set the desired height
    borderRadius: "50%", // Ensures a circular shape
    fontSize: "0.95rem !important",
  };

  const getSaasData = async (businessUrl,country)=>{
    try {
        let service = JSON.parse(localStorage.getItem("services"));
        // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
        MULTI_TENANCY_CONFIG.headers.Authorization =
          "Bearer " + localStorage.getItem("token");
        MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  
        let response = await axiosInstance.get(
          `${localStorage.getItem("baseUrl")}${
            service["member"]
          }${GET_SAAS_CONFIG}?BusinessWebUrl=${businessUrl}`,
          MULTI_TENANCY_CONFIG
        );

        let saas = response?.data?.data?.clientConfigs;
        let data = {
            BusinessWebUrl: businessUrl,
            country: country,
        };

        saas.forEach((config)=>{
            if ( config?.parameterValue == "null") {
                data[config?.parameterTag] = null;
            } else if (config?.parameterValue == "false") {
                data[config?.parameterTag] = false;
            } else if (config?.parameterValue == "true"){
                data[config?.parameterTag] = true;
            } else {
                data[config?.parameterTag] = config?.parameterValue;
            }

        })


        formik?.setValues(data, false);
  
      } catch (e) {
        console.log(e)
        SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
        SetGlobalData(updateState(GlobalData, "alertData", {message: handleMessageError({ e, type: "validation" }), type: "error"}));
      }
  }

  const getResellerData = async () => {
    try {
      let service = JSON.parse(localStorage.getItem("services"));
      // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${GET_CLIENT_BY_ID}?ClientGuid=${id}`,
        MULTI_TENANCY_CONFIG
      );

      let user = response?.data?.data?.client;

setClientData(user)
      SetGlobalData(updateState(GlobalData, "resellerId", id));
      getSaasData(user?.clientInfo?.businessWebUrl,user?.countryRecordGuid)

    } catch (e) {
        console.log(e)
        SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
        SetGlobalData(updateState(GlobalData, "alertData", {message: handleMessageError({ e, type: "validation" }), type: "error"}));
    }
  };


  useEffect(() => {
    GetAllCountries();
    GetAllCategories();
    getResellerData();
  }, []);

  return (
    <>
      <Grid
        style={{ marginBottom: "50px" }}
        item
        xs={12}
        md={6}
        className="centerresponsive"
      >
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/reseller-management", { replace: true });
            }}
            className="BreadcrumbsPage"
          >
            Resellers
          </Typography>
          <Typography className="breadcrumbactiveBtn">
          {clientData?.username?clientData?.username:"Edit Reseller"}

          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={2}>
          <Box sx={{}}>
            <Stepper
              sx={{
                width: "30px",
                height: "30px",
                "& .MuiStepConnector-line": {
                  height: "30px",
                  margin: "0px 0px 0px 5px",
                },
                "& .MuiStepIcon-root.Mui-completed ": {
                  color: "#c41035",
                },
                "& .MuiStepIcon-root.Mui-active": {
                  color: "#c41035 !important",
                },
              }}
              activeStep={activeStep}
              orientation={windowWidth < 1000 ? "horizontal" : "vertical"}
            >
              {steps.map((step, index) => (
                <Step key={step.label}
                style={{cursor: "pointer"}}
                onClick={()=>{
                  if (activeStep > index) SetGlobalData(updateState(GlobalData, "activeStep", index));
                }}
                >
                  <StepLabel
                    StepIconProps={{
                      style: circleStyle,
                    }}
                    style={{ width: "500px" }}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>

        <Grid
          style={{
            background: "white",
            padding: "20px",
            borderRadius: "25px",
          }}
          item
          lg={10}
          xs={12}
        >
          <form onSubmit={formik?.handleSubmit}>
            <ConfigurationSection pageType="edit" />

            <Grid
              style={{ marginTop: "20px", justifyContent: "space-between" }}
              container
            >
              <Grid item={6}>
                <Button
                  disabled={steps.length === 0}
                  onClick={handleBack}
                  className="outline"
                  // sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item={6}>
                <Button
                  className="contained"
                  onClick={formik?.handleSubmit}
                  // sx={{ mt: 1, mr: 1 }}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Continue"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={GlobalData?.openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={GlobalData?.alertData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {GlobalData?.alertData?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const EditResellerConfig = () => {
  return (
    <ResellerProvider>
      <EditResellerConfigComponent />
    </ResellerProvider>
  );
};

export default EditResellerConfig;
