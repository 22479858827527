import Add from "@mui/icons-material/Add";
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Typography
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import ManageURLStoreConfig from './ManageURLStoreConfig'
const URLStoreConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  // URLStoreConfig
  const [isModalOpenSwitchURLStoreConfig, setModalOpenURLStoreConfig] = useState(false);
  const [isCheckedSwitchURLStoreConfig, setCheckedSwitchURLStoreConfig] = useState(false);
  const [switchStatesURLStoreConfig, setswitchStatesURLStoreConfig] = useState({});
  const handleSwitchChangeSwitchURLStoreConfig = (rowId) => {
    setModalOpenURLStoreConfig(true);
    setCheckedSwitchURLStoreConfig(rowId);
  };

  const handleModalCloseSwitchURLStoreConfig = () => {
    setModalOpenURLStoreConfig(false);
  };
  const handleModalConfirmSwitchURLStoreConfig = () => {
    setModalOpenURLStoreConfig(false);
    // setCheckedSwitchURLStoreConfig(!isCheckedSwitchURLStoreConfig);
    setswitchStatesURLStoreConfig((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchURLStoreConfig]: !prevStates[isCheckedSwitchURLStoreConfig],
    }));
  };

  //

 

  const [Client, setClient] = React.useState("9mobile");

  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [Category, setCategory] = React.useState("9mobile");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const tableDataURLStoreConfig = [
    {id:1,baseUrl:'sas'}
  ];
  let columnsURLStoreConfig = [
    {
      field: "baseUrl",
      headerName: "Base Url",
      flex: 1,
    },
    {
      field: "client",
      headerName: " Client",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
    },
  ];

  // Delete Modal URLStoreConfig functions
  const [ConfirmOpenURLStoreConfig, setConfirmOpenURLStoreConfig] = useState(false);
  const [modalContentURLStoreConfig, setmodalContentURLStoreConfig] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalURLStoreConfig = (title, text, onButtonClick) => {
    setmodalContentURLStoreConfig({ title, text, onButtonClick });
    setConfirmOpenURLStoreConfig(true);
  };

  const handleConfirmModalURLStoreConfig = () => {
    setConfirmOpenURLStoreConfig(false);
  };

  //
  // Manage URLStoreConfig
  const [ManageURLStoreConfigAddopen, setManageURLStoreConfigAddopen] = useState(false);
  const [ManageURLStoreConfigEditopen, setManageURLStoreConfigEditopen] = useState(false);

  const handleAddManageURLStoreConfigopen = () => {
    setManageURLStoreConfigAddopen(true);
  };

  const handleAddMangeRequestclose = () => {
    setManageURLStoreConfigAddopen(false);
  };
  const handleEditManageURLStoreConfigopen = () => {
    setManageURLStoreConfigEditopen(true);
  };

  const handleEditMangeRequestclose = () => {
    setManageURLStoreConfigEditopen(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <Typography className="BreadcrumbsPage">URL Store Config</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddManageURLStoreConfigopen}
            >
              <Add sx={{ fontSize: "17px"}}  /> &nbsp;
              <Typography sx={{ fontSize: "14px",marginRight: "3px"  }}>Add URL Store Config</Typography>
            </Button>
        </Grid>
      </Grid>
      <Grid container className="Top-spacing div-table">
        <Grid item xs={12}>
            <Paper variant="CardTable" sx={{borderTopLeftRadius:'20px'}}>
              <Table
                rowCountState={tableDataURLStoreConfig?.totalCount}
                data={tableDataURLStoreConfig}
                // isLoading={isLoading}
                columns={columnsURLStoreConfig}
                pageIndex={
                  tableDataURLStoreConfig?.pageIndex ? tableDataURLStoreConfig?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalURLStoreConfig}
                editFunction={handleEditManageURLStoreConfigopen}
                enableSearch={true}
                enableExportBtn={true}
                valueFilter={Client}
                setValueFilter={setClient}
                optionsFilter={["9mobile", "hassan", "test2"]}
                // placeholderFilter="Client"
                enableFilter={true}
                // enableFilterBtn={true}
              />
          </Paper>
        </Grid>
      </Grid>

      {/* Delete URLStoreConfig Modal */}
      {handleConfirmModalURLStoreConfig && (
        <ConfirmationModal
          open={ConfirmOpenURLStoreConfig}
          onClose={handleConfirmModalURLStoreConfig}
          title="Delete"
          text="Are you sure you want to delete this URL store?"
          onButtonClick={handleConfirmModalURLStoreConfig}
        />
      )}
     

      {/* Manage URLStoreConfig Modal */}
      {handleAddManageURLStoreConfigopen && (
        <ManageURLStoreConfig
          open={ManageURLStoreConfigAddopen}
          setOpen={setManageURLStoreConfigAddopen}
          title={"Add URL Store Config"}
        />
      )}
      {handleEditManageURLStoreConfigopen && (
        <ManageURLStoreConfig
          open={ManageURLStoreConfigEditopen}
          setOpen={setManageURLStoreConfigEditopen}
          title={"Edit URL Store Config"}
        />
      )}

      {/* Swtich URLStoreConfig Modal */}
      {isModalOpenSwitchURLStoreConfig && (
        <ConfirmationModal
          open={isModalOpenSwitchURLStoreConfig}
          onClose={handleModalCloseSwitchURLStoreConfig}
          title="Change Status"
          text="Are you sure you want to change this URL Store Config Status?"
          onButtonClick={handleModalConfirmSwitchURLStoreConfig}
        />
      )}
      <div>
      </div>
    </div>
  );
};

export default URLStoreConfig;
