import {
  Grid
} from "@mui/material";
import React from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";

const ManageApiKeyService = ({ open, setOpen, title, onButtonClick }) => {
  const [httpMethod, setHttpMethod] = React.useState("");
  const [httpMethodOptions, setHttpMethodOptions] = React.useState(["http1","http2","http3","http4"]);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [endPoint, setEndPoint] = React.useState("");

  const handleChange = (event) => {
    setHttpMethod(event.target.value);
  };

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Name"}
                value={name}
                setValue={setName}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Description"}
                value={description}
                setValue={setDescription}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"End Point"}
                value={endPoint}
                setValue={setEndPoint}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm value={httpMethod} setValue={setHttpMethod}  options={httpMethodOptions} placeholder={"Http Method"}/>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageApiKeyService;
