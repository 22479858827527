import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_RATES,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_CURRENCIES,
  GET_ALL_OPERATORS_OPTIONS,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";

const ManageRates = ({
  open,
  setOpen,
  title,
  selectedRates,
  ClientCategoryOptions,
  setClientCategoryOptions,
  setRefreshTableRates,
  setOpenAlert,
  setAlertMessage,
}) => {
  const [ClientCategory, setClientCategory] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [Country, setCountry] = useState("");
  const [CountryOptions, setCountryOptions] = useState(false);
  const [Operator, setOperator] = useState("");
  const [OperatorOptions, setOperatorOptions] = useState(false);
  const [Rate, setRate] = useState("");
  const [currencies, setCurrencies] = useState("");
  const [currenciesOptions, setCurrenciesOptions] = useState(false);

  useEffect(() => {
    if (title === "Edit") {
      setRate(selectedRates.rate);
    } else {
      getAllCurrencies();
    }
  }, []);
  useEffect(() => {
    setClientCategory(ClientCategoryOptions[0].value);
  }, []);
  useEffect(() => {
    if (ClientCategory !== "" && title !== "Edit") {
      getAllCountries();
    }
  }, [ClientCategory]);
  useEffect(() => {
    if (Country !== "" && title !== "Edit") {
      getAllOperatorName();
    }
  }, [Country]);
  const handleAddRates = () => {
    let newServices = JSON.parse(services);

    const newData = {
      ServiceGuid: "2232496a-2702-4499-859a-82b98a672581",
      ChannelGuid: "acaaa147-6836-4d60-97c3-3fb1db02fb63",
      OperatorGuid: Operator,
      CurrencyCode: currencies,
      CountryCode:Country,
     // CountryCode: Country,
      OperationTypeTag: Operator,
      ClientCategoryGuid: ClientCategory,
      isDefault: false,
      Rate: parseInt(Rate),
      OperationTypeTag: "GENERAL",

      // RatePlanGuid:selectedRates?.recordGuid
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_RATES}`,
        newData,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpen(false);
        setRefreshTableRates(true);
        setOpenAlert(true);
        setAlertMessage(res?.data?.message);
        // Notification.success("Api Updated Successfully");
        // this.props.toggle();
        // this.props.tableRefresh();
      })
      .catch((err) => {
        // if (err === "error: 401") {
        //   this.handleSaveChanges();
        // }
        // if (err?.response?.data?.Data) {
        //   Object.keys(err?.response?.data?.Data).forEach((item) => {
        //     Notification.error(err?.response?.data?.Data[item][0]);
        //   });
        // } else {
        //   Notification.error(
        //     err?.response?.data?.message ?? "An Error occurred updating Client"
        //   );
        // }
      })
      .finally(() => {
        // this.setState({ loading: false });
      });
  };
  const handleSaveChanges = () => {
    let newServices = JSON.parse(services);

    const newData = {
      ServiceGuid: "2232496a-2702-4499-859a-82b98a672581",
      ChannelGuid: "acaaa147-6836-4d60-97c3-3fb1db02fb63",
      OperatorGuid: selectedRates?.operatorGuid,
      CurrencyCode: selectedRates?.currencyCode,
      CountryCode: selectedRates?.countryCode,
      OperationTypeTag: selectedRates?.operationTypeTag,
      isDefault: false,
      Rate: Rate,
      RatePlanGuid: selectedRates?.recordGuid,
      ClientCategoryGuid: selectedRates?.clientCategoryGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_RATES}`,
        newData,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpen(false);
        setRefreshTableRates(true);
        setOpenAlert(true);
        setAlertMessage(res?.data?.message);

        // Notification.success("Api Updated Successfully");
        // this.props.toggle();
        // this.props.tableRefresh();
      })
      .catch((err) => {
        // if (err === "error: 401") {
        //   this.handleSaveChanges();
        // }
        // if (err?.response?.data?.Data) {
        //   Object.keys(err?.response?.data?.Data).forEach((item) => {
        //     Notification.error(err?.response?.data?.Data[item][0]);
        //   });
        // } else {
        //   Notification.error(
        //     err?.response?.data?.message ?? "An Error occurred updating Client"
        //   );
        // }
      })
      .finally(() => {
        // this.setState({ loading: false });
      });
  };
  {
    console.log("selectedRates", selectedRates);
  }
  const getAllCountries = () => {
    setCountryOptions(false)
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        console.log("getAllCountries", res?.data?.data?.countries);
        setCountryOptions(
          res?.data?.data?.countries.map((country) => ({
            label: country?.name,
            value: country?.recordGuid
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllOperatorName = () => {
    let newServices = JSON.parse(services);
    setOperatorOptions(false)
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryIso=${Country}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOperatorOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };
  const getAllCurrencies = () => {
    let newServices = JSON.parse(services);
    setCurrenciesOptions(false)
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CURRENCIES}?SearchKeyword=`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCurrenciesOptions(
          res?.data?.data?.currencies.map((x) => ({
            label: x?.name,
            value: x?.code,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      firstActionBtnFct={title === "Edit" ? handleSaveChanges : handleAddRates}
      disableFirstBtn={
        title !== "Edit" &&
        (ClientCategory === "" ||
          Country === "" ||
          Operator === "" ||
          Rate === "")
          ? true
          : title !== "Add" && Rate === ""
          ? true
          : false
      }
      // secondActionBtnName={"Cancel"}
      content={
        <>
          {title === "Edit" ? (
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Rate"}
                  value={Rate}
                  setValue={setRate}
                  type={"number"}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={ClientCategory}
                    setValue={setClientCategory}
                    options={ClientCategoryOptions}
                    placeholder={"Client Category"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={Country}
                    setValue={setCountry}
                    options={CountryOptions}
                    placeholder={"Country"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={currencies}
                    setValue={setCurrencies}
                    options={currenciesOptions}
                    placeholder={"Currency"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={Operator}
                    setValue={setOperator}
                    options={OperatorOptions}
                    placeholder={"Operator"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    placeholder={"Rate"}
                    value={Rate}
                    setValue={setRate}
                    type={"number"}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      }
    />
  );
};

export default ManageRates;
