export const API_URL_IDENTITY = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://identity-qa.montylocal.net/api/v1" : "https://identity-dev.montylocal.net/api/v1";
export const API_URL_IDENTITY_V2 = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://identity-qa.montylocal.net/api/v2" : "https://identity-dev.montylocal.net/api/v2";
export const API_URL_IDENTITY_V3 = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://identity-qa.montylocal.net/api/v3" : "https://identity-dev.montylocal.net/api/v3";

export const API_LOGIN = API_URL_IDENTITY + "/Auth/AccessToken";

export const API_ENTRY = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login/Entry" : "https://omni-api-dev.montylocal.net/registration/api/login/Entry";
export const API_LOGIN_2 = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login/AccessToken" : "https://omni-api-dev.montylocal.net/registration/api/login/AccessToken";
export const CREATE_ACCOUNT = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login" : "https://omni-api-dev.montylocal.net/registration/api/login";
export const VERIFY_OTP = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/login/Activation" : "https://omni-api-dev.montylocal.net/registration/api/login/Activation";
export const ALLOW_LOGIN = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/registration/api/Resellers/ValidLogin" : "https://omni-api-dev.montylocal.net/registration/api/Resellers/ValidLogin";



export const REFRESH_TOKEN = API_URL_IDENTITY_V2 + "/Auth/Refresh"
export const RESET_PASSWORD = API_URL_IDENTITY + "/Account/ResetPassword";
export const FORGET_PASSWORD = API_URL_IDENTITY + "/Account/ForgetPassword";
export const GET_SIGNUP_TOKEN = API_URL_IDENTITY + "/Auth/login";
export const CHANGE_PASSWORD = API_URL_IDENTITY_V2 + "/Account/ChangePassword";

export const LOGINCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json",
    "Tenant": "0ce608a6-8758-4fb8-8f3e-097a1db640d6",
  }
}

export const CONFIGNOAHTH = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json"
  }
};

export const RESETLOGINCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Tenant-Key": "9ce64459-25d0-4671-a287-8626c4661905", //tenent key doesn't exist on ENTRY login
    "Content-Type": "application/json"
  }
}

export const CONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json",
    "Tenant": "0ce608a6-8758-4fb8-8f3e-097a1db640d6",
  }

}
//KEYCLOAK Omni identity 
export const KC_API_LOGIN = "https://omni-api-dev.montylocal.net/identity/api/v1/Auth/AccessToken"
export const KC_API_PAGES = window.location.origin.includes("https://omni-qa.montylocal.net") ? "https://omni-api-qa.montylocal.net/identity/api/v1/Pages/MenuPages" : "https://omni-api-dev.montylocal.net/identity/api/v1/Pages/MenuPages"

//KEYCLOAK Notification Identity
// export const KEYCLOAK_MEMBERS_LOGIN = "https://common-api-softwaredev.montylocal.net/member/api/v1/auth/login";
export const KEYCLOAK_MEMBERS_LOGIN = `/api/v1/auth/login`;//done

export const KEYCLOAK_MEMBERS_REFRESH = "https://common-api-softwaredev.montylocal.net/member/api/v1/auth/refresh-token";
export const KEYCLOAK_MEMBERS_LOGOUT = "https://common-api-softwaredev.montylocal.net/member/api/v1/auth/logout";
export const KEYCLOAK_RESET_PASSWORD = "https://common-api-softwaredev.montylocal.net/member/api/v1/user/reset-password";

//Password-config
export const KEYCLOAK_PASSWORD_POLICY = "/api/v1/auth/password-config";

//Users' APIs
export const GET_ALL_USERS = "/api/admin/v1/user/get-all";//done
export const GET_SINGLE_USER = "https://common-api-softwaredev.montylocal.net/member/api/admin/v1/user";//done
export const CHANGE_STATUS = "https://common-api-softwaredev.montylocal.net/member/api/admin/v1/user/set-active-status";//done
export const CHANGE_STATUS_TENANT = "/api/admin/v1/user/set-active-status";//done

export const ADD_USER = "/api/admin/v1/user/contact";//done
export const EDIT_USER = "https://common-api-softwaredev.montylocal.net/member/api/admin/v1/user/contact";//done
export const GET_ALL_USERS_TENANT = "/api/admin/v1/user/get-all";//done
export const GET_ALL_LANGUAGES = "/configuration/api/v1/language/get-all";//done

//Get Policies
export const GET_POLICIES = "/api/admin/v1/policy/get-all";//done
export const GET_POLICIES_TENANT = "https://common-api-softwaredev.montylocal.net/member/api/admin/v1/policy/get-all";//done

//Roles Pages APIs
export const ADD_MEMBER_ROLE = `https://common-api-softwaredev.montylocal.net/member/api/admin/v1/policy`;
export const DELETE_MEMBER_ROLE = id => `https://common-api-softwaredev.montylocal.net/member/api/admin/v1/policy?Id=${id}`;
export const GET_POLICY = `/api/admin/v1/policy/get-all`;
export const GET_POLICY_SCOPE_MAP = `https://common-api-softwaredev.montylocal.net/member/api/admin/v1/permission/get-policy-scopes-map`;//done
export const GET_POLICY_SCOPE_MAP_TENANT = `/api/admin/v1/permission/get-policy-scopes-map`;//done

export const ADD_PAGE_TO_ROLENAME = `https://common-api-softwaredev.montylocal.net/member/api/admin/v1/resource/scopes`
export const DELETE_RESOURCE = id => `https://common-api-softwaredev.montylocal.net/member/api/admin/v1/resource?Id=${id}`;
export const ASSIGN_SCOPES_RESOURCES = `https://common-api-softwaredev.montylocal.net/member/api/admin/v1/permission/assign-permission`
export const UPDATE_PAGE_TO_ROLENAME = `https://common-api-softwaredev.montylocal.net/member/api/admin/v1/resource`

export const config = {
  "headers":
  {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Authorization": "bearer ",
    "Content-Type": "application/json",
    "Tenant": process.env.REACT_APP_BASE_TENANT
  }
}
