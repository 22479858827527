import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Typography, Zoom } from "@mui/material";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ChannelsImg from "../../../Assets/channels-red.png";
import ClientsImg from "../../../Assets/clients-red.png";
import ProvidersImg from "../../../Assets/providers-red.png";
import Card from "./Card";
import Carousel from "./Carousel";
import "./LandingPage.scss";

function LandingPage(props) {
  const { hideBack } = props;
  const navigate = useNavigate();

  const [navigateTo, setNavigateTo] = useState("");
  const [buttonClicked, setButtonClicked] = useState("");
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    // localStorage.removeItem("withoutBack");
    document.body.classList.add("hide-scroll");
    return () => {
      // 👇️ removing classes from body element
      // when the component unmounts
      document.body.classList.remove("hide-scroll");
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      // Set the tablet width threshold (adjust as needed)
      const tabletWidth = 768; // Example threshold for tablets

      // Check if the screen width is less than the tablet threshold
      setIsTablet(window.innerWidth < tabletWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const t = props.t;
  const handleClick = (value, type) => {
    localStorage.setItem("typeOfLogin", type);
    setButtonClicked(true);
    setButtonClicked(false);
    setNavigateTo(value);
    // navigate(value);
    // window.location.reload();
    window.location.href = value;
  };

  // useEffect(() => {
  //   console.log("ASfasfasfasfasfasfasf", navigateTo);
  //   if (buttonClicked) {
  //   console.log("sssssssssssssss", navigateTo);

  //   navigate(navigateTo,{replace:true});

  //   }
  // }, [buttonClicked]);
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card
          title={t("Channels 360 View")}
          content={t(
            " Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat."
          )}
          text={t("Go To Channels View")}
          imagen={ChannelsImg}
          btnFunc={() => {
            // localStorage.setItem("typeOfLogin", "Channels");
            handleClick("/channels-management", "Channels");
            // navigate("/channels-management");
          }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          title={t("Clients 360 View")}
          content={t(
            " Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat."
          )}
          text={t("Go To Clients View")}
          imagen={ClientsImg}
          btnFunc={() => {
            // localStorage.setItem("typeOfLogin", "Clients");
            // navigate("/clients-management");
            handleClick("/clients-mg-dashboard", "Clients");
          }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          title={t("Providers 360 View")}
          content={t(
            " Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat."
          )}
          text={t("Go To Providers View")}
          imagen={ProvidersImg}
          btnFunc={() => {
            // localStorage.setItem("typeOfLogin", "Providers");
            // navigate("/providers-management");
            handleClick("/providers-mg-dashboard", "Providers");
          }}
        />
      ),
    },
    // {
    //   key: uuidv4(),
    //   content: (
    //     <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2016/08/slider_revolution-1.png" />
    //   ),
    // },
    // {
    //   key: uuidv4(),
    //   content: (
    //     <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2019/01/pwa_880_660.jpg" />
    //   ),
    // },
  ];
  return (
    <div className={!hideBack ? "Landing-with-img" : "Landing"}>
      {console.log("ASdasd", isTablet)}
      {console.log("Afsafasfasf", localStorage.getItem("withoutBack"))}
      <Zoom style={{ transitionDelay: "200ms" }} in={true}>
        <div>
          <Carousel
            cards={cards}
            height="88vh"
            width="50%"
            margin="auto"
            offset={2}
            isTablet={isTablet}
            // showArrows={true}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              cursor: "pointer",
              background: "white",
              padding: "13px",
              borderRadius: "20px",
              margin: "auto",
              width: "322px",
              // marginTop: "-3%",
            }}
            onClick={() => {
              localStorage.setItem("typeOfLogin", "null");
              handleClick("/data-seed", "null");
            }}
          >
            <Typography
              style={{ color: "#c41035", fontWeight: "bold" }}
              className="user-login"
            >
              Go To Regular View
            </Typography>
            <ArrowForwardIosIcon
              style={{ color: "#c41035", fontSize: "20px", margin: "2px" }}
              className="user-login"
            />
          </div>
        </div>
      </Zoom>
    </div>
  );
}

export default withTranslation("translations")(LandingPage);
