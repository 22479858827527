import {
  FormControlLabel,
  Grid, Switch
} from "@mui/material";
import React ,{ useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";


const ManageOperators = ({ open, setOpen, title, onButtonClick }) => {
  const [Tag, setTag] =useState("");
  const [TagOptions, setTagOptions] = useState(["tag 1","tag 2","tag 3","tag 4"]);
  const [Symbole, setSymbole] =useState("");
  const [SymboleOptions, setSymboleOptions] = useState(["$","€","£"]);
  const [Country, setCountry] =useState("");
  const [CountryOptions, setCountryOptions] = useState(["Lebanon","Canada","China"]);
  const [name, setName] = useState("");
  const [ParrentId, setParrentId] = useState("");
  const [MNC, setMNC] = useState("");

  const [switchState, setSwitchState] = useState(false);

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const [switchExclusive, setSwitchExclusive] = useState(false);

  const handleSwitchChangeExclusive = () => {
    setSwitchExclusive(!switchExclusive);
  };

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Name"}
                value={name}
                setValue={setName}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm value={Tag} setValue={setTag}  options={TagOptions} placeholder={"Tag"}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Parrent Id"}
                value={ParrentId}
                setValue={setParrentId}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"MNC"}
                value={MNC}
                setValue={setMNC}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm value={Country} setValue={setCountry}  options={CountryOptions} placeholder={"Country"}/>
            </Grid>
          </Grid>
          <span style={{color:"#B3B3B3",fontSize:"15px"}}>Exclusive</span>
          <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={switchExclusive}
                        onChange={handleSwitchChangeExclusive}
                      />
                    }
                    label={switchExclusive ? "Active " : "Inactive "}
                  />
                </Grid>
              </Grid>
              <span style={{color:"#B3B3B3",fontSize:"15px"}}>Status</span>
          <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={switchState} onChange={handleSwitchChange} />}
                    label={switchState ? 'Active ' : 'Inactive '}
                  />
                </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageOperators;
