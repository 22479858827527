import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function VerticalTabs({ tabs, setActiveBtn, activeBtn }) {
  const [value, setValue] = useState(activeBtn);
  const [indexValue, setIndexValue] = useState(0);
  const [isHasSubTabs, setIsHasSubTabs] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(event);
    setActiveBtn(event);
  };
  {
  }
  useEffect(() => {
    setIsHasSubTabs(tabs.find((x) => x?.subTabs) ? true : false);
  }, []);

  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "" }}
      className="vertical-tabs-page"
    >
      <Grid style={{ maxHeight: "350px" }} item xs={12}>
        <Tabs
          orientation="vertical"
          value={value}
          // onChange={handleChange}
          sx={{ marginBottom: "20px", maxHeight: "400px" }}
          className="vertical-tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"

          // indicatorColor="red"
        >
          {tabs.map((tab, index) => (
            <div style={{ textAlign: "end" }}>
              <Tab
                onClick={(e) => {
                  handleChange(
                    tab?.subTabs ? tab?.subTabs[0].label : e.target.textContent
                  );
                  setIndexValue(!isHasSubTabs ? index : 0);
                }}
                key={index}
                label={tab.label}
                {...a11yProps(index)}
                className="tabs-name"
                sx={{
                  mt: 1,
                  mb: tab?.subTabs && 1,
                  background:
                    !tab?.subTabs && value === tab.label
                      ? "#C41035"
                      : tab?.subTabs &&
                        tab?.subTabs?.find((x) => x.label === value)
                      ? "#C41035"
                      : "white",
                  boxShadow:
                    !tab?.subTabs && value === tab.label
                      ? "5px 5px 5px 0px rgba(0, 0, 0, 0.3)"
                      : tab?.subTabs &&
                        tab?.subTabs?.find((x) => x.label === value)
                      ? "5px 5px 5px 0px rgba(0, 0, 0, 0.3)"
                      : "",
                  borderRadius: "25px 0px 0px 25px",
                  fontSize: "16px",
                  fontWeight: "400",
                  textTransform: "inherit",
                  minWidth: "116px",
                  color:
                    !tab?.subTabs && value === tab.label
                      ? "#fff"
                      : tab?.subTabs &&
                        tab?.subTabs?.find((x) => x.label === value)
                      ? "#fff"
                      : "#949494",

                  minHeight: "43px",
                  padding: "6px 16px",
                  opacity: "inherit",
                  // cursor: tab?.subTabs ? "default" : "pointer",
                  width: "190px",
                  transform:
                    !isHasSubTabs &&
                    (index !== 0 && value === tab.label
                      ? `translateY(${indexValue * -50}px)`
                      : index === 0
                      ? `translateY(${indexValue * 50}px)`
                      : ""),
                  transition: "transform 0.5s ease-in-out",
                }}
              />
              {tab?.subTabs &&
                tab?.subTabs?.map((subtab, index) => (
                  <div style={{ textAlign: "end" }}>                  
                    <Tab
                      onClick={(e) => {
                        handleChange(e.target.textContent);
                        setIndexValue(index);
                      }}
                      key={index}
                      label={subtab?.label}
                      {...a11yProps(index)}
                      className="tabs-name"
                      sx={{
                        marginBottom: "2px",
                        background:
                          value === subtab.label ? "#C41035" : "#eb486a85",
                        color: value === subtab.label ? "#fff" : "#fff",
                        borderRadius: "25px 0px 0px 25px",
                        fontSize: "16px",
                        fontWeight: "400",
                        textTransform: "inherit",
                        minWidth: "116px",
                        minHeight: "43px",
                        padding: "6px 16px",
                        opacity: "inherit",
                        width: "151px",
                        // marginLeft: "90px",
                        minHeight: "38px",
                        transform:
                          tab?.subTabs?.find((x) => x.label === value) &&
                          index !== 0 &&
                          value === subtab.label
                            ? `translateY(${indexValue * -40}px)`
                            : tab?.subTabs?.find((x) => x.label === value) &&
                              index === 0
                            ? `translateY(${indexValue * 40}px)`
                            : "",
                        transition:
                          tab?.subTabs?.find((x) => x.label === value) &&
                          "transform 0.5s ease-in-out",
                      }}
                    />
                  </div>
                ))}
            </div>
          ))}
        </Tabs>
      </Grid>

      {/* {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))} */}
    </Box>
  );
}

VerticalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default VerticalTabs;
