import {
  Grid
} from "@mui/material";
import React from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";

const ManageApiGroup = ({ open, setOpen, title, onButtonClick }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Grid  container>
            <Grid  className="dialog-grid-spacing"  item xs={12}>
            <TextFieldForm
                placeholder={"Name"}
                value={name}
                setValue={setName}
              />
            </Grid>
          </Grid>
          

          <Grid container>
            <Grid  className="dialog-grid-spacing"  item xs={12}>
            <TextFieldForm
                placeholder={"Description"}
                value={description}
                setValue={setDescription}
              />
            </Grid>
          </Grid>
          
        </>
      }
    />
  );
};

export default ManageApiGroup;
