import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  
  const ManageReason = ({ open, setOpen, title, onButtonClick }) => {
    const [Tag, setTag] =useState("");
    const [TagOptions, setTagOptions] = useState(["tag 1","tag 2","tag 3","tag 4"]);
    const [ReasonType, setReasonType] =useState("");
    const [ReasonTypeOptions, setReasonTypeOptions] = useState(["Tenant 1","Tenant 2","Tenant 3","Tenant 4"]);
    const [ReasonCategory, setReasonCategory] =useState("");
    const [ReasonCategoryOptions, setReasonCategoryOptions] = useState(["Tenant 1","Tenant 2","Tenant 3","Tenant 4"]);
    const [name, setName] = useState("");
    const [ParrentId, setParrentId] = useState("");
    const [switchState, setSwitchState] = useState(false);
  
    const handleSwitchChange = () => {
      setSwitchState(!switchState);
    };
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Tag} setValue={setTag}  options={TagOptions} placeholder={"Tag"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Parrent Id"}
                  value={ParrentId}
                  setValue={setParrentId}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={ReasonType} setValue={setReasonType}  options={ReasonTypeOptions} placeholder={"Reason Type"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={ReasonCategory} setValue={setReasonCategory}  options={ReasonCategoryOptions} placeholder={"Reason Category"}/>
              </Grid>
            </Grid>
       
            <span style={{color:"#B3B3B3",fontSize:"15px"}}>Status</span>
            <Grid container>
                  
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Switch checked={switchState} onChange={handleSwitchChange} />}
                      label={switchState ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageReason;
  