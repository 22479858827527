import { createTheme } from "@mui/material/styles";

const theme = (theme_) => {
  return createTheme({
    palette: {
      mode: theme_.mode,
      // primary: theme_.primary,
    },
    direction: "ltr",
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            color: "#595959",
          },
          columnHeaderTitle: {
            fontWeight: "bold",
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: {
              variant: "theme-customize-btn",
            },
            style: {
              position: "absolute",
              right: "0px",
              top: "400px",
            },
          },
        ],
        variants: [
          {
            props: {
              variant: "contained",
            },
            style: {
              borderRadius: "32px",
              textTransform: "inherit",
              fontSize: "14px",
              boxShadow: "none",
              backgroundColor: "#c41035",
              "&:hover": {
                backgroundColor: "#c41035",
                boxShadow: "none",
              },
            },
          },
        ],
        variants: [
          {
            props: {
              variant: "outlined",
            },
            style: {
              borderRadius: "32px",
              textTransform: "inherit",
              fontSize: "14px",
              boxShadow: "none",
              backgroundColor: "white",
              border: "1px solid #c41035",
              color: "#c41035",
              "&:hover": {
                backgroundColor: "#c41035",
                boxShadow: "none",
                color: "white",
              },
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "second-nav-text",
            },
            style: {
              fontSize: "13px",
              backgroundColor: "#grey",
              height: "42px",
              fontWeight: "bold",
              color: "white",
              paddingTop: "13px",
              paddingLeft: "73px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            props: {
              variant: "second-nav-text-2",
            },
            style: {
              fontSize: "13px",
              backgroundColor: "#grey",
              height: "42px",
              fontWeight: "bold",
              color: "white",
              paddingTop: "13px",
              paddingLeft: "45px",
              display: "flex",
              alignItems: "center",
            },
          },
        ],
      },

      MuiAppBar: {
        variants: [
          {
            props: {
              variant: "AppBar",
            },
            style: {
              backgroundColor: "white",
              boxShadow: "none",
              height: "48px",
              color: "rgba(0, 0, 0, 0.54)",
              height: "auto",
              
            },
          },
        ],
      },
      MuiPaper: {
        variants: [
          {
            props: {
              variant: "CardTable",
            },
            style: {
              backgroundColor: theme_.mode,
              padding: "20px",
              height: "auto",
              paddingBottom: "5%",
            },
          },
        ],
      },
      MuiOutlinedInput: {
        input: {
          // Your custom styles for MuiOutlinedInput-input go here
          // For example:
          color: "blue",
          backgroundColor: "red",
          // Add other styles as needed
        },
      },
      MuiInputBase: {
        input: {
          MuiOutlinedInput: {
            input: {
              color: "blue",
              backgroundColor: "red",
            },
          },
        },
      },
      MuiTabs: {
        variants: [
          {
            props: {
              variant: "tabsStyle",
            },
            style: {
              backgroundColor: "white",
              borderRadius: "32px",
            },
          },
        ],
      },
      MuiTextField: {
        variants: [
          {
            props: {
              variant: "outlined",
            },
            style: {
              border:'1px solid grey',
              padding: '0px 0px',
              height: "30px",
              borderRadius: '28px',
            },
          },
          {
            props: {
              variant: "outlined2",
            },
            style: {
              border: "1px solid #E0E0E0 ",
              padding: "0px 0px",
              // height: "35px",
              borderRadius: "28px",
              padding: "3px 1px !important",
            },
          },
        ],
      },
      // MuiFormControl: {
      //   variants: [
      //     {
      //       props: {
      //         variant: "outlined",
      //       },
      //       style: {
      //         border:'1px solid #E0E0E0',
      //         // padding: '0px 0px',
      //         // height: "30px",
      //         borderRadius: '32px',
      //       },
      //     },
      //   ],
      // },
    },
  });
};

export default theme;
