import Add from "@mui/icons-material/Add";
import { Alert, Grid, Paper, Snackbar, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { MyDropzone } from "../../../Components/Buttons/ImportButton";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import Table from "../../../Components/DynamicTable/Table";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import HorizantalTabs from "../../../Components/Tabs/HorizantalTabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import { downloadCSV } from "../../../Utils/functions";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_COST_PLAN,
  DELETE_RATE,
  GET_ALL_CLIENTS_CATEGORY,
  GET_ALL_COSTS_PLANS,
  GET_ALL_COSTS_PLANS_PROVIDER,
  GET_ALL_PROVIDERS_CATEGORY,
  GET_ALL_RATES,
  IMPORT_COSTS,
  IMPORT_RATE,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import ManageAccounts from "./ManageAccounts";
import ManageCosts from "./ManageCosts";
import ManageProfitMargin from "./ManageProfitMargin";
import ManageRates from "./ManageRates";
import ManageTaxes from "./ManageTaxes";
import ManageWallet from "./ManageWallet";
const SMSOneWay = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [recordGuidRates, setRecordGuidRates] = useState("");
  const [recordGuidCosts, setRecordGuidCosts] = useState("");
  const [ClientCategoryOptions, setClientCategoryOptions] = useState([]);
  const [openImportFile, setopenImportFile] = useState(false);
  const [activeBtn, setActiveBtn] = useState("Routes");
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [config, setConfig] = useState(localStorage.getItem("config"));
  const [file, setFile] = useState("");
  const [clientCategoryImport, setClientCategoryImport] = useState("");
  const [selectedRates, setSectedRates] = useState("");
  const [selectedCost, setSectedCost] = useState("");
  const [alertMesssage, setAlertMessage] = useState("");

  const [providerCategoryImport, setProviderCategoryImport] = useState("");
  const [clientCategoryImportOptions, setClientCategoryImportOptions] =
    useState(false);
  const [providerCategoryImportOptions, setProviderCategoryImportOptions] =
    useState(false);
  const [searchRates, setSearchRates] = useState("");
  const [searchCosts, setSearchCosts] = useState("");
  const [clientCategoryRatesOptions, setClientCategoryRatesOptions] = useState(
    false
  );
  const [providerCategoryCostsOptions, setProviderCategoryCostsOptions] =
    useState(false);
  const [providerCategoryCosts, setProviderCategoryCosts] = useState("");
  const [clientCategoryRates, setClientCategoryRates] = useState("");
  const [ActiveBtnHorizantal, setActiveBtnHorizantal] = useState("Type");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const [paginationModelCostVsRate, setPaginationModelCostVsRate] =
    React.useState({
      page: 0,
      pageSize: 5,
    });
  const [paginationModelProfitMargin, setPaginationModelProfitMargin] =
    React.useState({
      page: 0,
      pageSize: 5,
    });
  const [paginationModelRates, setPaginationModelRates] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [paginationModelCosts, setPaginationModelCosts] = React.useState({
    page: 0,
    pageSize: 10,
  });
  // Routes
  const [isModalOpenSwitchAccounts, setModalOpenSwitchAccounts] =
    useState(false);
  const [isCheckedSwitchAccounts, setCheckedSwitchAccounts] = useState(false);
  const [openEditProfitMargin, setOpenEditProfitMargin] = useState(false);

  const [switchStatesAccounts, setswitchStatesAccounts] = useState({});
  const handleSwitchChangeSwitchAccounts = (rowId) => {
    setModalOpenSwitchAccounts(true);
    setCheckedSwitchAccounts(rowId);
  };

  const handleModalCloseSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
  };
  const handleModalConfirmSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
    // setCheckedSwitchAccounts(!isCheckedSwitchAccounts);
    setswitchStatesAccounts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchAccounts]: !prevStates[isCheckedSwitchAccounts],
    }));
  };

  //

  // Rates
  const [isModalOpenSwitchRates, setModalOpenSwitchRates] = useState(false);
  const [isCheckedSwitchRates, setCheckedSwitchRates] = useState(false);
  const [switchStatesRates, setSwitchStatesRates] = useState({});
  const handleSwitchChangeSwitchRates = (rowId) => {
    setModalOpenSwitchRates(true);
    setCheckedSwitchRates(rowId);
  };

  const handleModalCloseSwitchRates = () => {
    setConfirmOpenRates(false);
  };
  const handleOpenEditProfitMarginModal = () => {
    setOpenEditProfitMargin(!openEditProfitMargin);
  };
  const handleModalConfirmSwitchRates = () => {
    setModalOpenSwitchRates(false);
    // setCheckedSwitchRates(!isCheckedSwitchRates);
    setSwitchStatesRates((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchRates]: !prevStates[isCheckedSwitchRates],
    }));
  };

  //
  // Costs
  const [isModalOpenSwitchCosts, setModalOpenSwitchCosts] = useState(false);
  const [isCheckedSwitchCosts, setCheckedSwitchCosts] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [switchStatesCosts, setSwitchStatesCosts] = useState({});
  const handleSwitchChangeSwitchCosts = (rowId) => {
    setModalOpenSwitchCosts(true);
    setCheckedSwitchCosts(rowId);
  };

  const handleModalCloseSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
  };
  const handleModalConfirmSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
    // setCheckedSwitchCosts(!isCheckedSwitchCosts);
    setSwitchStatesCosts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCosts]: !prevStates[isCheckedSwitchCosts],
    }));
  };
  //
  // Taxes
  const [isModalOpenSwitchTaxes, setModalOpenSwitchTaxes] = useState(false);
  const [isCheckedSwitchTaxes, setCheckedSwitchTaxes] = useState(false);
  const [switchStatesTaxes, setSwitchStatesTaxes] = useState({});
  const handleSwitchChangeSwitchTaxes = (rowId) => {
    setModalOpenSwitchTaxes(true);
    setCheckedSwitchTaxes(rowId);
  };

  const handleModalCloseSwitchTaxes = () => {
    setModalOpenSwitchTaxes(false);
  };
  const handleModalConfirmSwitchTaxes = () => {
    setModalOpenSwitchTaxes(false);
    // setCheckedSwitchTaxes(!isCheckedSwitchTaxes);
    setSwitchStatesTaxes((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchTaxes]: !prevStates[isCheckedSwitchTaxes],
    }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  //
  // Wallet
  const [isModalOpenSwitchWallet, setModalOpenSwitchWallet] = useState(false);
  const [isCheckedSwitchWallet, setCheckedSwitchWallet] = useState(false);
  const [switchStatesWallet, setSwitchStatesWallet] = useState({});
  const [resetPagination, setResetPagination] = useState(false);
  const [tableRates, setTableRates] = useState({ pageIndex: 1, data: [] });
  const [tableCosts, setTableCosts] = useState({ pageIndex: 1, data: [] });
  const [ConfirmOpenRates, setConfirmOpenRates] = useState(false);
  const [refreshTableRates, setRefreshTableRates] = useState(false);

  const handleSwitchChangeSwitchWallet = (rowId) => {
    setModalOpenSwitchWallet(true);
    setCheckedSwitchWallet(rowId);
  };
  const submitFile = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
      "Content-Type": "multipart/form-data",
    };
    if (activeBtn == "Rates") {
      const formData = new FormData();
      formData.append("ratesFile", file);
      formData.append("channelGuid", "acaaa147-6836-4d60-97c3-3fb1db02fb63");
      formData.append("serviceGuid", "2232496a-2702-4499-859a-82b98a672581");
      formData.append("ClientCategoryGuid", clientCategoryImport);
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${IMPORT_RATE}`,
          formData,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          if (data?.status != "failed" && data?.status != "error") {
            setOpenAlert(true);
            setopenImportFile(false);
            setClientCategoryImport("");
            setFile("");
          } else {
          }
        })
        .catch((err) => {});
    } else if (activeBtn == "Costs") {
      const formData = new FormData();
      formData.append("costsFile", file);
      formData.append("channelGuid", "acaaa147-6836-4d60-97c3-3fb1db02fb63");
      formData.append("serviceGuid", "2232496a-2702-4499-859a-82b98a672581");
      formData.append("providerCategoryGuid", providerCategoryImport);
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["billing"]
          }${IMPORT_COSTS}`,
          formData,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          if (data?.status != "failed" && data?.status != "error") {
            setOpenAlert(true);
            setopenImportFile(false);
            setProviderCategoryImport("");
            setFile("");
          } else {
          }
        })
        .catch((err) => {});
    }
  };

  const handleModalCloseSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
  };
  const handleModalConfirmSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
    // setCheckedSwitchWallet(!isCheckedSwitchTaxes);
    setSwitchStatesWallet((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchWallet]: !prevStates[isCheckedSwitchWallet],
    }));
  };
  //
  const [Zone, setZone] = React.useState("");

  const handleChangeZone = (event) => {
    setZone(event.target.value);
  };
  const [Rates, setRates] = React.useState("");

  const handleChangeRates = (event) => {
    setRates(event.target.value);
  };
  const tableDataAccounts = [
    {
      id: 1,
      name: "Test Account Type 1",
      description: "Test Account Type Description 1",
    },
    {
      id: 2,
      name: "Test Account Type 2",
      description: "Test Account Type Description 2",
    },
  ];
  let columnsAccounts = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const tableDataAccountsCategory = [
    {
      id: 1,
      name: "Test category Type 1",
      description: "Test category Type Description 1",
    },
    {
      id: 2,
      name: "Test category Type 2",
      description: "Test category Type Description 2",
    },
  ];
  let columnsAccountsCategory = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const tableDataAccountsStatus = [
    {
      id: 1,
      name: "Test status Type 1",
      description: "Test status Type Description 1",
    },
    {
      id: 2,
      name: "Test status Type 2",
      description: "Test status Type Description 2",
    },
  ];
  let columnsAccountsStatus = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  // const tableDataRates = [
  //   {
  //     id: 1,
  //     clientCategory: "Default",
  //     countryCode: "961",
  //     country: "Lebanon",
  //     operator: "Alfa",
  //     rate: "0.2 USD",
  //   },
  // ];
  let columnsRates = [
    {
      field: "clientCategoryName",
      headerName: "Client Category",
      flex: 1,
    },
    {
      headerName: "Country ISO",
      field: "countryCode",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];

  const tableDataCosts = [
    {
      id: 1,
      providerCategory: "providerCategory",
      countryCode: "961",
      country: "Lebanon",
      operator: "operator",
      cost: "U.S",
    },
  ];
  let columnsCosts = [
    {
      field: "providerCategoryName",
      headerName: "Provider Category",
      flex: 1,
    },
    {
      field: "countryCode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Cost",
      field: "cost",
      flex: 1,
    },
  ];

  const tableDataWallet = [
    {
      id: 1,
      clientCategory: "clientCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];
  const tableDataCostVsRate = [
    {
      country: "Lebanon",
      cost: "1500",
      rate: "1000",
      difference: "500",
    },
  ];
  const tableProfitMargin = [
    {
      clientCategory: "clientCategory 1",
      percentage: "80%",
    },
  ];
  let columnsWallet = [
    {
      field: "clientCategory",
      headerName: "Client Category",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];
  let columnsCostVsRate = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
    {
      headerName: "Difference",
      field: "difference",
      flex: 1,
    },
  ];
  let columnsProfitMargin = [
    {
      field: "clientCategory",
      headerName: "Client Category",
      flex: 1,
    },
    {
      field: "percentage",
      headerName: "Percentage ",
      flex: 1,
    },
    {
      field: "populate",
      headerName: "",
      // width: 330,
      flex: 1,

      // width: 100,
      renderCell: (params) => {
        return <Button className="generate-key">Populate</Button>;
      },
    },
  ];
  const tableDataTaxes = [
    {
      id: 1,
      clientCategory: "clientCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];
  let columnsTaxes = [
    {
      field: "clientCategory",
      headerName: "Client Category",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];

  const tabs = [
    {
      label: "Routes",
    },
    {
      label: "Sender ID's",
    },
    {
      label: "Templates",
    },
    {
      label: "Rates",
    },
    {
      label: "Costs",
    },
    {
      label: "Cost Vs. Rate",
    },
    {
      label: "Profit Margin",
    },
  ];
  const tabsSupport = [
    {
      label: "Rates",
    },
    {
      label: "Costs",
    },
  ];
  const tabsHorizantal = [
    {
      label: "Type",
    },
    {
      label: "Category",
    },
    {
      label: "Status",
    },
  ];
  useEffect(() => {
    // setServices(JSON.parse(services));
    // setConfig(JSON.parse(config));

    if (activeBtn === "Rates") {
      tableQueryExecuterRates();
    }
  }, [activeBtn, paginationModelRates, clientCategoryRates, searchRates]);
  useEffect(() => {
    // setServices(JSON.parse(services));
    // setConfig(JSON.parse(config));

    if (openAlert) {
      tableQueryExecuterRates();
    }
  }, [openAlert]);

  useEffect(() => {
    //delete
    if (activeBtn === "Rates" && refreshTableRates) {
      tableQueryExecuterRates();
    }
  }, [refreshTableRates]);
  const getAllClientsCategory = () => {
    setClientCategoryRatesOptions(false)
    setClientCategoryImportOptions(false)
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_CLIENTS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setClientCategoryRatesOptions([
          { label: "All", value: "" },
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setClientCategoryImportOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);

        setClientCategoryOptions([
          ...res?.data?.data?.clientCategories.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setIsLoading(false);

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllClientsCategory();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const exportRates = () => {
    let newServices = JSON.parse(services);
    const columns = [
      { title: "Client Category", field: "clientCategoryName" },
      {
        title: "Country ISO",
        field: "countryCode",
      },
      { title: "Country", field: "country" },
      {
        title: "Operator",
        field: "operator",
      },
      {
        title: "Rate",
        field: "rate",
      },
    ];
    setIsLoading(true);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    new axiosInstance.get(
      `${localStorage.getItem("baseUrl")}${
        newServices["billing"]
      }${GET_ALL_RATES}?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&export=true${
        searchRates ? `&keyword=${searchRates}` : ""
      }`,
      MULTI_TENANCY_CONFIG
    )
      .then((res) => {
        setIsLoading(false);

        const data = res?.data?.data?.ratePlans;
        downloadCSV(
          columns,
          data?.map((item) => ({
            ...item,
          })),
          "Rates"
        );
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };

  const getAllProvidersCategory = () => {
    setProviderCategoryCostsOptions(false)
    setProviderCategoryImportOptions(false)
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_PROVIDERS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setProviderCategoryCostsOptions([
          { label: "All", value: "" },
          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label:
              x.providerCategoryDetails && x.providerCategoryDetails.length > 0
                ? x.providerCategoryDetails[0].name
                : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryImportOptions([
          ...res?.data?.data?.providerCategories.map((x, i) => ({
            label: x.providerCategoryDetails
              ? x.providerCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setIsLoading(false);

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProvidersCategory();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterRates = (promiseResolve, promiseReject) => {
    setIsLoading(true);
    setRefreshTableRates(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${GET_ALL_RATES}?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&ClientCategoryGuid=${clientCategoryRates}&pageindex=${
          resetPagination ? 1 : paginationModelRates?.page + 1
        }&pagesize=${paginationModelRates?.pageSize}${
          searchRates ? `&keyword=${searchRates}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        // this.setState({ tableDataRes: res?.data?.data?.apis ?? [] });
        setTableRates({
          data: res?.data?.data?.ratePlans ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelRates({
        //   ...paginationModelRates,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterRates();
        }
        setTableRates({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const DeleteRate = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${DELETE_RATE}?RecordGuid=${recordGuidRates}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // toast.success("Rate Deleted");
        setConfirmOpenRates(false);
        setRefreshTableRates(true);
        setOpenAlert(true);
        setAlertMessage(data?.message);
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteRate();
        }
        // toast.error(err?.response?.data?.Message || "Somthing Went Wrong!");
      });
  };

  const DeleteCost = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_COST_PLAN}?RecordGuid=${recordGuidCosts}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        tableQueryExecuterCosts();
        setConfirmOpenCosts(false);
        setOpenAlert(true);
        setAlertMessage(data?.message);
      })
      .catch((err) => {
        if (err === "error: 401") {
          DeleteCost();
        }
      });
  };

  const tableQueryExecuterCosts = (promiseResolve, promiseReject) => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["billing"]}${
          providerCategoryCosts
            ? GET_ALL_COSTS_PLANS_PROVIDER
            : GET_ALL_COSTS_PLANS
        }?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&pageindex=${
          resetPagination ? 1 : paginationModelCosts?.page + 1
        }&pagesize=${paginationModelCosts?.pageSize}${
          searchCosts ? `&keyword=${searchCosts}` : ""
        }${
          providerCategoryCosts
            ? `&ProviderCategoryGuid=${providerCategoryCosts}`
            : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableCosts({
          data: res?.data?.data?.costPlans ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(false);
        }
        setTableCosts({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportCosts = () => {
    let newServices = JSON.parse(services);
    const columns = [
      { title: "Provider Category", field: "providerCategoryName" },
      {
        title: "Country ISO",
        field: "countryCode",
      },
      { title: "Country", field: "country" },
      {
        title: "Operator",
        field: "operator",
      },
      {
        title: "Cost",
        field: "cost",
      },
    ];
    setIsLoading(true);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    new axiosInstance.get(
      `${localStorage.getItem("baseUrl")}${newServices["billing"]}${
        providerCategoryCosts
          ? GET_ALL_COSTS_PLANS_PROVIDER
          : GET_ALL_COSTS_PLANS
      }?ChannelGuid=acaaa147-6836-4d60-97c3-3fb1db02fb63&pageindex=${"1"}&pagesize=${"100000"}${
        searchCosts ? `&keyword=${searchCosts}` : ""
      }${
        providerCategoryCosts
          ? `&ProviderCategoryGuid=${providerCategoryCosts}`
          : ""
      }`,
      MULTI_TENANCY_CONFIG
    )
      .then((res) => {
        setIsLoading(false);

        const data = res?.data?.data?.costPlans;
        downloadCSV(
          columns,
          data?.map((item) => ({
            ...item,
          })),
          "Costs"
        );
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (activeBtn === "Costs") {
      tableQueryExecuterCosts();
    }
  }, [activeBtn, paginationModelCosts, providerCategoryCosts, searchCosts]);

  useEffect(() => {
    if (openAlert) {
      tableQueryExecuterCosts();
    }
  }, [openAlert]);

  // Delete Modal Routes functions
  const [ConfirmOpenAccounts, setConfirmOpenAccounts] = useState(false);
  const [modalContentAccounts, setModalContentAccounts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalAccounts = (title, text, onButtonClick) => {
    setModalContentAccounts({ title, text, onButtonClick });
    setConfirmOpenAccounts(true);
  };

  const handleConfirmModalAccounts = () => {
    setConfirmOpenAccounts(false);
  };

  //

  // Delete Modal Rates functions
  const [modalContentRates, setModalContentRates] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalRates = (data) => {
    setRecordGuidRates(data.recordGuid);
    setModalContentRates();
    setConfirmOpenRates(true);
  };

  const handleConfirmModalRates = () => {
    setConfirmOpenRates(false);
  };

  //
  // Delete Modal Costs functions
  const [ConfirmOpenCosts, setConfirmOpenCosts] = useState(false);
  const [modalContentCosts, setModalContentCosts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalCosts = (data) => {
    setRecordGuidCosts(data.recordGuid);
    setModalContentCosts();
    setConfirmOpenCosts(true);
  };

  const handleConfirmModalCosts = () => {
    setConfirmOpenCosts(false);
  };

  //
  // Delete Modal Wallet functions
  const [ConfirmOpenWallet, setConfirmOpenWallet] = useState(false);
  const [modalContentWallet, setModalContentWallet] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalWallet = (title, text, onButtonClick) => {
    setModalContentWallet({ title, text, onButtonClick });
    setConfirmOpenWallet(true);
  };

  const handleConfirmModalWallet = () => {
    setConfirmOpenWallet(false);
  };

  //
  // Delete Modal Taxes functions
  const [ConfirmOpenTaxes, setConfirmOpenTaxes] = useState(false);
  const [modalContentTaxes, setModalContentTaxes] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalTaxes = (title, text, onButtonClick) => {
    setModalContentTaxes({ title, text, onButtonClick });
    setConfirmOpenTaxes(true);
  };

  const handleConfirmModalTaxes = () => {
    setConfirmOpenTaxes(false);
  };
  //

  // Manage Routes
  const [MangeAccountsAddopen, setMangeAccountsAddopen] = useState(false);
  const [MangeAccountsEditopen, setMangeAccountsEditopen] = useState(false);

  const handleAddMangeAccountsopen = () => {
    setMangeAccountsAddopen(true);
  };

  const handleAddMangeAccountsclose = () => {
    setMangeAccountsAddopen(false);
  };
  const handleEditMangeAccountsopen = () => {
    setMangeAccountsEditopen(true);
  };

  const handleEditMangeAccountsclose = () => {
    setMangeAccountsEditopen(false);
  };

  // Manage Rates
  const [MangeRatesAddopen, setMangeRatesAddopen] = useState(false);
  const [MangeRatesEditopen, setMangeRatesEditopen] = useState(false);

  const handleAddMangeRatesopen = () => {
    setMangeRatesAddopen(true);
  };

  const handleAddMangeRatesclose = () => {
    setMangeRatesAddopen(false);
  };
  const handleEditMangeRatesopen = (data) => {
    setSectedRates(data);
    setMangeRatesEditopen(true);
  };

  const handleEditManageCostopen = (data) => {
    setSectedCost(data);
    setMangeCostsEditopen(true);
  };

  const handleEditMangeRatesclose = () => {
    setMangeRatesEditopen(false);
  };

  // Manage Costs
  const [MangeCostsAddopen, setMangeCostsAddopen] = useState(false);
  const [MangeCostsEditopen, setMangeCostsEditopen] = useState(false);

  const handleAddMangeCostsopen = () => {
    setMangeCostsAddopen(true);
  };

  const handleAddMangeCostsclose = () => {
    setMangeCostsAddopen(false);
  };
  const handleEditMangeCostsopen = () => {
    setMangeCostsEditopen(true);
  };

  const handleEditMangeCostsclose = () => {
    setMangeCostsEditopen(false);
  };

  // Manage Wallet
  const [MangeWalletAddopen, setMangeWalletAddopen] = useState(false);
  const [MangeWalletEditopen, setMangeWalletEditopen] = useState(false);

  const handleAddMangeWalletopen = () => {
    setMangeWalletAddopen(true);
  };

  const handleAddMangeWalletclose = () => {
    setMangeWalletAddopen(false);
  };
  const handleEditMangeWalletopen = () => {
    setMangeWalletEditopen(true);
  };

  const handleEditMangeWalletclose = () => {
    setMangeWalletEditopen(false);
  };

  // Manage Taxes
  const [MangeTaxesAddopen, setMangeTaxesAddopen] = useState(false);
  const [MangeTaxesEditopen, setMangeTaxesEditopen] = useState(false);

  const handleAddMangeTaxesopen = () => {
    setMangeTaxesAddopen(true);
  };

  const handleAddMangeTaxesclose = () => {
    setMangeTaxesAddopen(false);
  };
  const handleEditMangeTaxesopen = () => {
    setMangeTaxesEditopen(true);
  };

  const handleEditMangeTaxesclose = () => {
    setMangeTaxesEditopen(false);
  };

  useEffect(() => {
    if (activeBtn == "Rates") {
      window.scrollTo(0, 0);
      getAllClientsCategory();
    }
  }, [activeBtn]);

  useEffect(() => {
    if (activeBtn == "Costs") {
      window.scrollTo(0, 0);
      getAllProvidersCategory();
    }
  }, [activeBtn]);

  useEffect(() => {
    setSearchRates("");
    setClientCategoryRates("");
  }, [activeBtn]);

  return (
    <div className="billing">
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">SMS</Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
          {activeBtn === "Routes" && (
            <>
              {ActiveBtnHorizantal === "Type" && (
                <Button
                  // variant="contained"
                  className="contained"
                  onClick={handleAddMangeAccountsopen}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>Add Route</Typography>
                </Button>
              )}
              {ActiveBtnHorizantal === "Category" && (
                <Button
                  // variant="contained"
                  className="contained"
                  onClick={handleAddMangeAccountsopen}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>
                    Add Category
                  </Typography>
                </Button>
              )}
              {ActiveBtnHorizantal === "Status" && (
                <Button
                  // variant="contained"
                  className="contained"
                  onClick={handleAddMangeAccountsopen}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>Add Status</Typography>
                </Button>
              )}
            </>
          )}
          {activeBtn === "Sender ID's" && (
            <Button
              variant="contained"
              className="contained"
              // onClick={handleAddMangeCostsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />

              <Typography sx={{ fontSize: "14px" }}>Add Sender ID</Typography>
            </Button>
          )}
          {activeBtn === "Rates" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeRatesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Rate</Typography>
            </Button>
          )}
          {activeBtn === "Costs" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeCostsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Cost</Typography>
            </Button>
          )}
          {activeBtn === "Profit Margin" && (
            <Button
              variant="contained"
              className="contained"
              // onClick={handleAddMangeCostsopen}
            >
              <Typography sx={{ fontSize: "14px" }}>Populate All</Typography>
            </Button>
          )}
          {activeBtn === "Templates" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeWalletopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Template</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      {windowWidth < 1000 && (
        <Grid container marginLeft={2}>
          <HorizantalTabs
             tabs={
              (localStorage.getItem("roleName") ==="support"||localStorage.getItem("roleName") ==="userQA")
                ? tabsSupport
                : tabs
            }
            setActiveBtn={setActiveBtn}
          />
        </Grid>
      )}

      {windowWidth > 1000 ? (
        <Grid container className="Top-spacing div-table">
          <Grid
            item
            xs={3.5}
            md={1.5}
            lg={2}
            className="vertical-grid"
            sx={{ paddingLeft: "2px" }}
          >
            <VerticalTabs
            activeBtn={activeBtn}

              tabs={
                (localStorage.getItem("roleName") ==="support"||localStorage.getItem("roleName") ==="userQA")
                  ? tabsSupport
                  : tabs
              }
              setActiveBtn={setActiveBtn}
            />
          </Grid>
          <Grid xs={8.5} md={10.5} lg={10}>
            {activeBtn === "Routes" && (
              <>
                <Grid container>
                  <Grid xs={12}>
                    {ActiveBtnHorizantal === "Type" && (
                      <Paper variant="CardTable">
                        <Table
                          rowCountState={tableDataAccounts?.totalCount}
                          data={tableDataAccounts}
                          // isLoading={isLoading}
                          columns={columnsAccounts}
                          pageIndex={
                            tableDataAccounts?.pageIndex
                              ? tableDataAccounts?.pageIndex
                              : 0
                          }
                          setPaginationModel={setPaginationModel}
                          paginationModel={paginationModel}
                          enableDelete={true}
                          enableEdit={true}
                          deleteFunction={OpenConfirmModalAccounts}
                          editFunction={handleEditMangeAccountsopen}
                          enableSearch={true}
                          enableExportBtn={true}
                        />
                      </Paper>
                    )}

                    {ActiveBtnHorizantal === "Category" && (
                      <Paper variant="CardTable">
                        <Table
                          rowCountState={tableDataAccountsCategory?.totalCount}
                          data={tableDataAccountsCategory}
                          // isLoading={isLoading}
                          columns={columnsAccountsCategory}
                          pageIndex={
                            tableDataAccountsCategory?.pageIndex
                              ? tableDataAccountsCategory?.pageIndex
                              : 0
                          }
                          setPaginationModel={setPaginationModel}
                          paginationModel={paginationModel}
                          enableDelete={true}
                          enableEdit={true}
                          deleteFunction={OpenConfirmModalAccounts}
                          editFunction={handleEditMangeAccountsopen}
                          enableSearch={true}
                          enableExportBtn={true}
                        />
                      </Paper>
                    )}

                    {ActiveBtnHorizantal === "Status" && (
                      <Paper variant="CardTable">
                        <Table
                          rowCountState={tableDataAccountsStatus?.totalCount}
                          data={tableDataAccountsStatus}
                          // isLoading={isLoading}
                          columns={columnsAccountsStatus}
                          pageIndex={
                            tableDataAccountsStatus?.pageIndex
                              ? tableDataAccountsStatus?.pageIndex
                              : 0
                          }
                          setPaginationModel={setPaginationModel}
                          paginationModel={paginationModel}
                          enableDelete={true}
                          enableEdit={true}
                          deleteFunction={OpenConfirmModalAccounts}
                          editFunction={handleEditMangeAccountsopen}
                          enableSearch={true}
                          enableExportBtn={true}
                        />
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {activeBtn === "Rates" && (
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    variant="CardTable"
                    
                  >
                    <Table
                      rowCountState={tableRates?.totalCount}
                      data={tableRates?.data}
                      isLoading={isLoading}
                      columns={columnsRates}
                      pageIndex={
                        tableRates?.pageIndex ? tableRates?.pageIndex : 0
                      }
                      tableRates={tableRates}
                      setPaginationModel={setPaginationModelRates}
                      paginationModel={paginationModelRates}
                      enableDelete={true}
                      enableEdit={true}
                      deleteFunction={OpenConfirmModalRates}
                      editFunction={handleEditMangeRatesopen}
                      enableSearch={true}
                      search={searchRates}
                      setSearch={setSearchRates}
                      // enableFilter={true}
                      enableImportBtn={true}
                      ImportFunction={() => setopenImportFile(true)}
                      valueFilter={clientCategoryRates}
                      setValueFilter={setClientCategoryRates}
                      optionsFilter={clientCategoryRatesOptions}
                      enableFilter2={true}
                      placeholderFilter="Client Category"
                      enableExportBtn={true}
                      exportFunction={exportRates}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}

            {activeBtn === "Costs" && (
              // <Costs activeBtn={activeBtn} />
              <Paper variant="CardTable" >
                <Table
                  rowCountState={tableCosts?.totalCount}
                  data={tableCosts?.data}
                  isLoading={isLoading}
                  columns={columnsCosts}
                  pageIndex={tableCosts?.pageIndex ? tableCosts?.pageIndex : 0}
                  tableCosts={tableCosts}
                  setPaginationModel={setPaginationModelCosts}
                  paginationModel={paginationModelCosts}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  exportFunction={exportCosts}
                  deleteFunction={OpenConfirmModalCosts}
                  editFunction={handleEditManageCostopen}
                  enableSearch={true}
                  enableFilter2={true}
                  enableImportBtn={true}
                  search={searchCosts}
                  setSearch={setSearchCosts}
                  ImportFunction={() => setopenImportFile(true)}
                  valueFilter={providerCategoryCosts}
                  setValueFilter={setProviderCategoryCosts}
                  optionsFilter={providerCategoryCostsOptions}
                  placeholderFilter="Provider Category"
                />
              </Paper>
            )}
            {activeBtn === "Templates" && (
              <Paper variant="CardTable" >
                <Table
                  rowCountState={tableDataWallet?.totalCount}
                  data={tableDataWallet}
                  // isLoading={isLoading}
                  columns={columnsWallet}
                  pageIndex={
                    tableDataWallet?.pageIndex ? tableDataWallet?.pageIndex : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  enableImportBtn={true}
                  deleteFunction={OpenConfirmModalWallet}
                  editFunction={handleEditMangeWalletopen}
                  enableSearch={true}
                  valueFilter={Rates}
                  setValueFilter={setRates}
                  optionsFilter={["All", "Default", "vip"]}
                  placeholderFilter="Client Category"
                />
              </Paper>
            )}
            {activeBtn === "Cost Vs. Rate" && (
              <Paper
                variant="CardTable"
                sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
              >
                <Table
                  rowCountState={tableDataCostVsRate?.totalCount}
                  data={tableDataCostVsRate}
                  // isLoading={isLoading}
                  columns={columnsCostVsRate}
                  pageIndex={
                    tableDataCostVsRate?.pageIndex
                      ? tableDataCostVsRate?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  disableActions={true}
                  // enableDelete={true}
                  // enableEdit={true}
                  // enableExportBtn={true}
                  // enableImportBtn={true}
                  // deleteFunction={OpenConfirmModalWallet}
                  // editFunction={handleEditMangeWalletopen}
                  // enableSearch={true}
                  // valueFilter={Rates}
                  // setValueFilter={setRates}
                  // optionsFilter={["All", "Default", "vip"]}
                  // placeholderFilter="Client Category"
                />
              </Paper>
            )}
            {activeBtn === "Profit Margin" && (
              <Paper
                variant="CardTable"
                sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
              >
                <Table
                  rowCountState={tableProfitMargin?.totalCount}
                  data={tableProfitMargin}
                  // isLoading={isLoading}
                  columns={columnsProfitMargin}
                  pageIndex={
                    tableProfitMargin?.pageIndex
                      ? tableProfitMargin?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  // enableDelete={true}
                  enableEdit={true}
                  // enableExportBtn={true}
                  // enableImportBtn={true}
                  // deleteFunction={OpenConfirmModalWallet}
                  editFunction={handleOpenEditProfitMarginModal}
                  // enableSearch={true}
                  // valueFilter={Rates}
                  // setValueFilter={setRates}
                  // optionsFilter={["All", "Default", "vip"]}
                  // placeholderFilter="Client Category"
                />
              </Paper>
            )}
            {activeBtn === "Sender ID's" && (
              <Paper variant="CardTable" >
                <Table
                  rowCountState={tableDataTaxes?.totalCount}
                  data={tableDataTaxes}
                  columns={columnsTaxes}
                  pageIndex={
                    tableDataTaxes?.pageIndex ? tableDataTaxes?.pageIndex : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  deleteFunction={OpenConfirmModalTaxes}
                  editFunction={handleEditMangeTaxesopen}
                  enableSearch={true}
                  valueFilter={Rates}
                  enableImportBtn={true}
                  setValueFilter={setRates}
                  optionsFilter={["All", "Default", "vip"]}
                  placeholderFilter="Client Category"
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid xs={12} md={10} lg={10}>
          {activeBtn === "Routes" && (
            <>
              <Grid container>
                <Grid xs={12}>
                  {ActiveBtnHorizantal === "Type" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataAccounts?.totalCount}
                        data={tableDataAccounts}
                        // isLoading={isLoading}
                        columns={columnsAccounts}
                        pageIndex={
                          tableDataAccounts?.pageIndex
                            ? tableDataAccounts?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalAccounts}
                        editFunction={handleEditMangeAccountsopen}
                        enableSearch={true}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}

                  {ActiveBtnHorizantal === "Category" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataAccountsCategory?.totalCount}
                        data={tableDataAccountsCategory}
                        // isLoading={isLoading}
                        columns={columnsAccountsCategory}
                        pageIndex={
                          tableDataAccountsCategory?.pageIndex
                            ? tableDataAccountsCategory?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalAccounts}
                        editFunction={handleEditMangeAccountsopen}
                        enableSearch={true}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}

                  {ActiveBtnHorizantal === "Status" && (
                    <Paper variant="CardTable">
                      <Table
                        rowCountState={tableDataAccountsStatus?.totalCount}
                        data={tableDataAccountsStatus}
                        // isLoading={isLoading}
                        columns={columnsAccountsStatus}
                        pageIndex={
                          tableDataAccountsStatus?.pageIndex
                            ? tableDataAccountsStatus?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        deleteFunction={OpenConfirmModalAccounts}
                        editFunction={handleEditMangeAccountsopen}
                        enableSearch={true}
                        enableExportBtn={true}
                      />
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {activeBtn === "Rates" && (
            <Grid container>
              <Grid item xs={12}>
                <Paper variant="CardTable" >
                  <Table
                    rowCountState={tableRates?.totalCount}
                    data={tableRates?.data}
                    isLoading={isLoading}
                    columns={columnsRates}
                    pageIndex={
                      tableRates?.pageIndex ? tableRates?.pageIndex : 0
                    }
                    tableRates={tableRates}
                    setPaginationModel={setPaginationModelRates}
                    paginationModel={paginationModelRates}
                    enableDelete={true}
                    enableEdit={true}
                    deleteFunction={OpenConfirmModalRates}
                    editFunction={handleEditMangeRatesopen}
                    enableSearch={true}
                    search={searchRates}
                    setSearch={setSearchRates}
                    // enableFilter={true}
                    enableImportBtn={true}
                    ImportFunction={() => setopenImportFile(true)}
                    valueFilter={clientCategoryRates}
                    setValueFilter={setClientCategoryRates}
                    optionsFilter={clientCategoryRatesOptions}
                    enableFilter2={true}
                    placeholderFilter="Client Category"
                    enableExportBtn={true}
                    exportFunction={exportRates}
                  />
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeBtn === "Costs" && (
            // <Costs activeBtn={activeBtn} />
            <Paper variant="CardTable" >
              <Table
                rowCountState={tableCosts?.totalCount}
                data={tableCosts?.data}
                isLoading={isLoading}
                columns={columnsCosts}
                pageIndex={tableCosts?.pageIndex ? tableCosts?.pageIndex : 0}
                tableCosts={tableCosts}
                setPaginationModel={setPaginationModelCosts}
                paginationModel={paginationModelCosts}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                exportFunction={exportCosts}
                deleteFunction={OpenConfirmModalCosts}
                editFunction={handleEditManageCostopen}
                enableSearch={true}
                enableFilter2={true}
                enableImportBtn={true}
                search={searchCosts}
                setSearch={setSearchCosts}
                ImportFunction={() => setopenImportFile(true)}
                valueFilter={providerCategoryCosts}
                setValueFilter={setProviderCategoryCosts}
                optionsFilter={providerCategoryCostsOptions}
                placeholderFilter="Provider Category"
              />
            </Paper>
          )}
          {activeBtn === "Templates" && (
            <Paper variant="CardTable" >
              <Table
                rowCountState={tableDataWallet?.totalCount}
                data={tableDataWallet}
                // isLoading={isLoading}
                columns={columnsWallet}
                pageIndex={
                  tableDataWallet?.pageIndex ? tableDataWallet?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                enableImportBtn={true}
                deleteFunction={OpenConfirmModalWallet}
                editFunction={handleEditMangeWalletopen}
                enableSearch={true}
                valueFilter={Rates}
                setValueFilter={setRates}
                optionsFilter={["All", "Default", "vip"]}
                placeholderFilter="Client Category"
              />
            </Paper>
          )}
          {activeBtn === "Cost Vs. Rate" && (
            <Paper
              variant="CardTable"
              sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
            >
              <Table
                rowCountState={tableDataCostVsRate?.totalCount}
                data={tableDataCostVsRate}
                // isLoading={isLoading}
                columns={columnsCostVsRate}
                pageIndex={
                  tableDataCostVsRate?.pageIndex
                    ? tableDataCostVsRate?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                disableActions={true}
                // enableDelete={true}
                // enableEdit={true}
                // enableExportBtn={true}
                // enableImportBtn={true}
                // deleteFunction={OpenConfirmModalWallet}
                // editFunction={handleEditMangeWalletopen}
                // enableSearch={true}
                // valueFilter={Rates}
                // setValueFilter={setRates}
                // optionsFilter={["All", "Default", "vip"]}
                // placeholderFilter="Client Category"
              />
            </Paper>
          )}
          {activeBtn === "Profit Margin" && (
            <Paper
              variant="CardTable"
              sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
            >
              <Table
                rowCountState={tableProfitMargin?.totalCount}
                data={tableProfitMargin}
                // isLoading={isLoading}
                columns={columnsProfitMargin}
                pageIndex={
                  tableProfitMargin?.pageIndex
                    ? tableProfitMargin?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                // enableDelete={true}
                enableEdit={true}
                // enableExportBtn={true}
                // enableImportBtn={true}
                // deleteFunction={OpenConfirmModalWallet}
                editFunction={handleOpenEditProfitMarginModal}
                // enableSearch={true}
                // valueFilter={Rates}
                // setValueFilter={setRates}
                // optionsFilter={["All", "Default", "vip"]}
                // placeholderFilter="Client Category"
              />
            </Paper>
          )}
          {activeBtn === "Sender ID's" && (
            <Paper variant="CardTable" >
              <Table
                rowCountState={tableDataTaxes?.totalCount}
                data={tableDataTaxes}
                columns={columnsTaxes}
                pageIndex={
                  tableDataTaxes?.pageIndex ? tableDataTaxes?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                deleteFunction={OpenConfirmModalTaxes}
                editFunction={handleEditMangeTaxesopen}
                enableSearch={true}
                valueFilter={Rates}
                enableImportBtn={true}
                setValueFilter={setRates}
                optionsFilter={["All", "Default", "vip"]}
                placeholderFilter="Client Category"
              />
            </Paper>
          )}
        </Grid>
      )}
      {/* Delete Routes Modal */}
      {handleConfirmModalAccounts && (
        <ConfirmationModal
          open={ConfirmOpenAccounts}
          onClose={handleConfirmModalAccounts}
          title="Delete"
          text="Are you sure you want to delete this cost?"
          onButtonClick={DeleteCost}
        />
      )}
      {/* Delete Rates Modal */}
      {ConfirmOpenRates && (
        <ConfirmationModal
          open={ConfirmOpenRates}
          onClose={handleModalCloseSwitchRates}
          title="Delete"
          text="Are you sure you want to delete this rate?"
          onButtonClick={DeleteRate}
        />
      )}
      {/* Delete Costs Modal */}
      {handleConfirmModalCosts && (
        <ConfirmationModal
          open={ConfirmOpenCosts}
          onClose={handleConfirmModalCosts}
          title="Delete"
          text="Are you sure you want to delete this cost ?"
          onButtonClick={DeleteCost}
        />
      )}
      {/* Delete Wallet Modal */}
      {handleConfirmModalWallet && (
        <ConfirmationModal
          open={ConfirmOpenWallet}
          onClose={handleConfirmModalWallet}
          title="Delete"
          text="Are you sure you want to delete this Wallet ?"
          onButtonClick={handleConfirmModalWallet}
        />
      )}
      {/* Delete Taxes Modal */}
      {handleConfirmModalTaxes && (
        <ConfirmationModal
          open={ConfirmOpenTaxes}
          onClose={handleConfirmModalTaxes}
          title="Delete"
          text="Are you sure you want to delete this Taxe ?"
          onButtonClick={handleConfirmModalTaxes}
        />
      )}

      {/* Manage Routes Modal */}
      {handleAddMangeAccountsopen && (
        <ManageAccounts
          open={MangeAccountsAddopen}
          setOpen={setMangeAccountsAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeAccountsopen && (
        <ManageAccounts
          open={MangeAccountsEditopen}
          setOpen={setMangeAccountsEditopen}
          title={"Edit"}
          CostData={tableCosts?.data}
        />
      )}

      {/* Manage Rates Modal */}
      {MangeRatesAddopen && (
        <ManageRates
          open={MangeRatesAddopen}
          setOpen={setMangeRatesAddopen}
          title={"Add"}
          setClientCategoryOptions={setClientCategoryOptions}
          ClientCategoryOptions={ClientCategoryOptions}
          refreshTableRates={refreshTableRates}
          setRefreshTableRates={setRefreshTableRates}
          setAlertMessage={setAlertMessage}
          setOpenAlert={setOpenAlert}
        />
      )}
      {MangeRatesEditopen && (
        <ManageRates
          open={MangeRatesEditopen}
          setOpen={setMangeRatesEditopen}
          title={"Edit"}
          setOpenAlert={setOpenAlert}
          selectedRates={selectedRates}
          ClientCategoryOptions={ClientCategoryOptions}
          setClientCategoryOptions={setClientCategoryOptions}
          refreshTableRates={refreshTableRates}
          setRefreshTableRates={setRefreshTableRates}
          setAlertMessage={setAlertMessage}
        />
      )}
      {/* Manage Costs Modal */}
      {handleAddMangeCostsopen && (
        <ManageCosts
          open={MangeCostsAddopen}
          setOpen={setMangeCostsAddopen}
          setOpenAlert={setOpenAlert}
          title={"Add"}
          providerCategoryImportOptions={providerCategoryImportOptions}
          setAlertMessage={setAlertMessage}
        />
      )}
      {handleEditMangeCostsopen && (
        <ManageCosts
          open={MangeCostsEditopen}
          setOpen={setMangeCostsEditopen}
          setOpenAlert={setOpenAlert}
          selectedCost={selectedCost}
          title={"Edit"}
          isEdit={true}
          providerCategoryImportOptions={providerCategoryImportOptions}
          CostData={tableCosts?.data}
          setAlertMessage={setAlertMessage}
        />
      )}

      {/* Manage Wallet Modal */}
      {handleAddMangeWalletopen && (
        <ManageWallet
          open={MangeWalletAddopen}
          setOpen={setMangeWalletAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeWalletopen && (
        <ManageWallet
          open={MangeWalletEditopen}
          setOpen={setMangeWalletEditopen}
          title={"Edit"}
        />
      )}

      {/* Manage Taxes Modal */}
      {handleAddMangeTaxesopen && (
        <ManageTaxes
          open={MangeTaxesAddopen}
          setOpen={setMangeTaxesAddopen}
          title={"Add"}
        />
      )}
      {handleEditMangeTaxesopen && (
        <ManageTaxes
          open={MangeTaxesEditopen}
          setOpen={setMangeTaxesEditopen}
          title={"Edit"}
        />
      )}

      {/* Swtich Routes Modal */}
      {isModalOpenSwitchAccounts && (
        <ConfirmationModal
          open={isModalOpenSwitchAccounts}
          onClose={handleModalCloseSwitchAccounts}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchAccounts}
        />
      )}
      {/* Swtich Rates Modal */}
      {isModalOpenSwitchRates && (
        <ConfirmationModal
          open={isModalOpenSwitchRates}
          onClose={handleModalCloseSwitchRates}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={DeleteRate}
        />
      )}
      {/* Swtich Costs Modal */}
      {isModalOpenSwitchCosts && (
        <ConfirmationModal
          open={isModalOpenSwitchCosts}
          onClose={handleModalCloseSwitchCosts}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchCosts}
        />
      )}
      {/* Swtich Wallet Modal */}
      {isModalOpenSwitchWallet && (
        <ConfirmationModal
          open={isModalOpenSwitchWallet}
          onClose={handleModalCloseSwitchWallet}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchWallet}
        />
      )}

      {/* Swtich Taxes Modal */}
      {isModalOpenSwitchTaxes && (
        <ConfirmationModal
          open={isModalOpenSwitchTaxes}
          onClose={handleModalCloseSwitchTaxes}
          title="Switch Zone"
          text="Are you sure you want to Switch this Zone ?"
          onButtonClick={handleModalConfirmSwitchTaxes}
        />
      )}
      {openEditProfitMargin && (
        <ManageProfitMargin
          open={openEditProfitMargin}
          setOpen={handleOpenEditProfitMarginModal}
          onButtonClick={handleOpenEditProfitMarginModal}
          title={"Edit"}
        />
      )}
      <CustomizedDialogs
        open={openImportFile}
        setOpen={setopenImportFile}
        title="Import File"
        // firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid style={{ marginBottom: "15px" }} item xs={12}>
                <SelectFormNew
                  value={
                    activeBtn == "Rates"
                      ? clientCategoryImport
                      : providerCategoryImport
                  }
                  setValue={
                    activeBtn == "Rates"
                      ? setClientCategoryImport
                      : activeBtn == "Costs"
                      ? setProviderCategoryImport
                      : ""
                  }
                  options={
                    activeBtn == "Rates"
                      ? clientCategoryImportOptions
                      : activeBtn == "Costs"
                      ? providerCategoryImportOptions
                      : ""
                  }
                  placeholder={
                    activeBtn == "Rates"
                      ? "Client Category"
                      : activeBtn == "Costs"
                      ? "Provider Category"
                      : ""
                  }
                />
              </Grid>
              <MyDropzone setFile={setFile} file={file} />
              <Grid className="end" marginBottom={0} container>
                <Button
                  onClick={submitFile}
                  variant="contained"
                  className="save-import"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMesssage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SMSOneWay;
