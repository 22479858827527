import { Grid } from "@mui/material";
import React, { useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";

const ManageProfitMargin = ({ open, setOpen, title }) => {
  const [percentage, setPercentage] = useState("");

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      //   firstActionBtnFct={title === "Edit" ? handleSaveChanges : handleAddRates}
      //   disableFirstBtn={
      //     title !== "Edit" &&
      //     (ClientCategory === "" ||
      //       Country === "" ||
      //       Operator === "" ||
      //       Rate === "")
      //       ? true
      //       : title !== "Add" && Rate === ""
      //       ? true
      //       : false
      //   }
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Percentage"}
                value={percentage}
                setValue={setPercentage}
                type={"number"}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageProfitMargin;
