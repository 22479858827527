import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import { MANAGE_LANGUAGES, MULTI_TENANCY_CONFIG } from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

const ManageLanguages = ({
  open,
  setOpen,
  title,
  isEdit,
  selectedLanguages,
  setPaginationModalLanguages,
  paginationModalLanguages,
}) => {
  const [languageMl, setLanguageMl] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [name, setName] = useState("");

  const [ParrentId, setParrentId] = useState("");
  const [Code, setCode] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [switchState, setSwitchState] = useState(false);

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const [switchExclusive, setSwitchExclusive] = useState(false);

  const handleSwitchChangeExclusive = () => {
    setSwitchExclusive(!switchExclusive);
  };

  const [switchStateTranslation, setSwitchStateTranslation] = useState(false);

  const handleSwitchChangeTranslation = () => {
    setSwitchStateTranslation(!switchStateTranslation);
  };
  const [switchStatertl, setSwitchStatertl] = useState(false);

  const handleSwitchChangertl = () => {
    setSwitchStatertl(!switchStatertl);
  };

  useEffect(() => {
    {
      !selectedLanguages && setName("");
      setCode("");
      setLanguageMl("");
      setSwitchStatertl(false);
      setSwitchStateTranslation(false);
      setSwitchState(false);
    }
  }, [open, selectedLanguages]);
  useEffect(() => {
    {
      isEdit && setName(selectedLanguages?.name);
      setCode(selectedLanguages?.code);
      setLanguageMl(selectedLanguages?.languageMl);
      // setSwitchStatertl(selectedLanguages?.isRtl);
      // setSwitchStateTranslation(selectedLanguages?.usedForTranslation);
      // setSwitchState(selectedLanguages?.isActive);
    }
  }, [open, isEdit]);
  useEffect(() => {
    if (isEdit && selectedLanguages) {
      setName(selectedLanguages?.name);
      setCode(selectedLanguages?.code);
      setLanguageMl(selectedLanguages?.languageMl);
      // setSwitchStatertl(selectedLanguages?.isRtl);
      // setSwitchStateTranslation(selectedLanguages?.usedForTranslation);
      // setSwitchState(selectedLanguages?.isActive);
    }
  }, [selectedLanguages, isEdit, open]);
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      code: Code,
      name: name,
      languageMl: languageMl,
      usedForTranslation: switchStateTranslation,
      IsActive: switchState,
      isRtl: switchStatertl,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_LANGUAGES}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });
        setPaginationModalLanguages({
          page: 0,
          pageSize: paginationModalLanguages?.pageSize
            ? paginationModalLanguages?.pageSize
            : 10,
        });
        setOpen(false);
        setCode("");
        setLanguageMl("");
        setSwitchStatertl(false);
        setSwitchStateTranslation(false);
        setSwitchState(false);
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      code: Code,
      name: name,
      RecordGuid: selectedLanguages?.recordGuid,
      languageMl: languageMl,

    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_LANGUAGES}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });
        setPaginationModalLanguages({
          page: 0,
          pageSize: paginationModalLanguages?.pageSize
            ? paginationModalLanguages?.pageSize
            : 10,
        });
        setOpen(false);
        setCode("");
        setLanguageMl("");
        setSwitchStatertl(false);
        setSwitchStateTranslation(false);
        setSwitchState(false);
        // setSmsOneWaySearch("");
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitEdit();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={
          !isEdit
            ? name === "" || Code === "" || languageMl === ""
            : name === "" || Code === ""
        }
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Code"}
                  value={Code}
                  setValue={setCode}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Native Language"}
                  value={languageMl}
                  setValue={setLanguageMl}
                />
              </Grid>
            </Grid>
            {!isEdit && (
              <>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Used For Translation
                </span>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchStateTranslation}
                          onChange={handleSwitchChangeTranslation}
                        />
                      }
                      // label={switchStateTranslation ? "Active " : "Inactive "}
                    />
                  </Grid>
                </Grid>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>rtl</span>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchStatertl}
                          onChange={handleSwitchChangertl}
                        />
                      }
                      // label={switchStatertl ? "Active " : "Inactive "}
                    />
                  </Grid>
                </Grid>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Status
                </span>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchState}
                          onChange={handleSwitchChange}
                        />
                      }
                      // label={switchState ? "Active" : "Inactive"}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        }
      />

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageLanguages;
