import Add from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import axiosInstance from "../../../axiosInstance";
import {
  CHANGE_STATUS_CATEGORY,
  CHANGE_STATUS_CITY,
  CHANGE_STATUS_COUNTRY,
  CHANGE_STATUS_CURRENCIES,
  CHANGE_STATUS_LANG_RL,
  CHANGE_STATUS_LANG_STATUS,
  CHANGE_STATUS_LANG_TR,
  CHANGE_STATUS_ZONE,
  DELETE_SMS,
  DELETE_ZONE,
  EXPORT_CITY,
  EXPORT_COUNTRY,
  EXPORT_COUNTRY_TO_EXCEL,
  EXPORT_CURRENCIES,
  EXPORT_LANGUAGES,
  EXPORT_LANGUAGES_TO_EXCEL,
  EXPORT_ZONE,
  GET_ALL_CITY,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_COUNTRY,
  GET_ALL_CRITERIA,
  GET_ALL_CRITERIA_CATEGORY,
  GET_ALL_CRITERIA_TYPE,
  GET_ALL_CURRENCIES_DATA_SEED,
  GET_ALL_LANGUAGES_DATA_SEED,
  GET_ALL_ZONE,
  MANAGE_CITY,
  MANAGE_CRITERIA_CATEGORY,
  MANAGE_CURRENCIES,
  MANAGE_LANGUAGES,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import ManageCity from "./ManageCity";
import ManageCountry from "./ManageCountry";
import ManageCriteria from "./ManageCriteria";
import ManageCriteriaCategory from "./ManageCriteriaCategory";
import ManageCriteriaType from "./ManageCriteriaType";
import ManageCurrencies from "./ManageCurrencies";
import ManageLanguages from "./ManageLanguages";
import ManageLocation from "./ManageLocation";
import ManageOperators from "./ManageOperators";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
const DataSeed = () => {
  let services = localStorage.getItem("services");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let columnsCriteriaType = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.isActive}
                  disabled
                  // onChange={() => {
                  //   handleSwitchChangeSwitchCriteriaType(data);
                  // }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [searchCountry, setSearchCountry] = useState("");
  const [searchType, setSearchType] = useState("");

  const [searchCategory, setSearchCategory] = useState("");
  const [paginationModalType, setPaginationModalType] = useState({
    page: 0,
    pageSize: 10,
  });

  const [searchZone, setSearchZone] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchDefault, setSearchDefault] = useState("");
  const [searchCurrencies, setSearchCurrencies] = useState("");
  const [searchLanguages, setSearchLanguages] = useState("");

  const [activeBtn, setActiveBtn] = useState("Zone");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const [paginationModalLanguages, setPaginationModalLanguages] =
    React.useState({
      page: 0,
      pageSize: 5,
    });
  const [paginationModalCurrencies, setPaginationModalCurrencies] =
    React.useState({
      page: 0,
      pageSize: 5,
    });

  const [isModalOpenSwitchLocationZone, setModalOpenSwitchLocationZone] =
    useState(false);
  const [isCheckedSwitchLocationZone, setCheckedSwitchLocationZone] =
    useState(false);
  const [switchStatesLocationZone, setSwitchStatesLocationZone] = useState({});
  const [isModalOpenSwitchLocationCountry, setModalOpenSwitchLocationCountry] =
    useState(false);
  const [isCheckedSwitchLocationCountry, setCheckedSwitchLocationCountry] =
    useState(false);
  const [switchStatesLocationCountry, setswitchStatesLocationCountry] =
    useState({});
  const [isModalOpenSwitchLocationCity, setModalOpenSwitchLocationCity] =
    useState(false);
  const [isModalOpenSwitchCategory, setModalOpenSwitchCategory] =
    useState(false);
  const [isCheckedSwitchLocationCity, setCheckedSwitchLocationCity] =
    useState(false);
  const [checkedSwitchCategory, setCheckedSwitchCategory] = useState(false);
  const [switchStatesLocationCity, setswitchStatesLocationCity] = useState({});
  const [isModalOpenSwitchCriteria, setModalOpenSwitchCriteria] =
    useState(false);
  const [isCheckedSwitchCriteria, setCheckedSwitchCriteria] = useState(false);
  const [switchStatesCriteria, setSwitchStatesCriteria] = useState({});
  const [isModalOpenSwitchCriteriaType, setModalOpenSwitchCriteriaType] =
    useState(false);
  const [isCheckedSwitchCriteriaType, setCheckedSwitchCriteriaType] =
    useState(false);
  const [switchStatesCriteriaType, setSwitchStatesCriteriaType] = useState({});
  const [
    isModalOpenSwitchCriteriaCategory,
    setModalOpenSwitchCriteriaCategory,
  ] = useState(false);
  const [isCheckedSwitchCriteriaCategory, setCheckedSwitchCriteriaCategory] =
    useState(false);
  const [switchStatesCriteriaCategory, setSwitchStatesCriteriaCategory] =
    useState({});
  const [isModalOpenSwitchCurrencies, setModalOpenSwitchCurrencies] =
    useState(false);
  const [isCheckedSwitchCurrencies, setCheckedSwitchCurrencies] =
    useState(false);
  const [switchStatesCurrencies, setSwitchStatesCurrencies] = useState({});
  const [isModalOpenSwitchOperators, setModalOpenSwitchOperators] =
    useState(false);
  const [isCheckedSwitchOperators, setCheckedSwitchOperators] = useState(false);
  const [switchStatesOperators, setSwitchStatesOperators] = useState({});
  const [
    isModalOpenSwitchOperatorsExlusive,
    setModalOpenSwitchOperatorsExlusive,
  ] = useState(false);
  const [isCheckedSwitchOperatorsExlusive, setCheckedSwitchOperatorsExlusive] =
    useState(false);
  const [switchStatesOperatorsExlusive, setSwitchStatesOperatorsExlusive] =
    useState({});

  const [isModalOpenSwitchLanguages, setModalOpenSwitchLanguages] =
    useState(false);
  const [isCheckedSwitchLanguages, setCheckedSwitchLanguages] = useState(false);
  const [switchStatesLanguages, setSwitchStatesLanguages] = useState({});
  const [isModalOpenSwitchLanguagesTR, setModalOpenSwitchLanguagesTR] =
    useState(false);
  const [isCheckedSwitchLanguagesTR, setCheckedSwitchLanguagesTR] =
    useState(false);
  const [switchStatesLanguagesTR, setSwitchStatesLanguagesTR] = useState({});
  const [isModalOpenSwitchLanguagesRL, setModalOpenSwitchLanguagesRL] =
    useState(false);
  const [isCheckedSwitchLanguagesRL, setCheckedSwitchLanguagesRL] =
    useState(false);
  const [switchStatesLanguagesRL, setSwitchStatesLanguagesRL] = useState({});
  const [ConfirmOpenLocation, setConfirmOpenLocation] = useState(false);
  const [confirmOpenDefault, setConfirmOpenDefault] = useState(false);

  const [modalContentLocation, setModalContentLocation] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenLocationCountry, setConfirmOpenLocationCountry] =
    useState(false);
  const [modalContentLocationCountry, setModalContentLocationCountry] =
    useState({
      onButtonClick: () => {
        console.log("Button Clicked");
      },
    });
  const [ConfirmOpenLocationCity, setConfirmOpenLocationCity] = useState(false);
  const [modalContentLocationCity, setModalContentLocationCity] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenCriteria, setConfirmOpenCriteria] = useState(false);
  const [modalContentCriteria, setModalContentCriteria] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenCriteriaType, setConfirmOpenCriteriaType] = useState(false);
  const [modalContentCriteriaType, setModalContentCriteriaType] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });

  const [ConfirmOpenCriteriaCategory, setConfirmOpenCriteriaCategory] =
    useState(false);
  const [modalContentCriteriaCategory, setModalContentCriteriaCategory] =
    useState({
      onButtonClick: () => {
        console.log("Button Clicked");
      },
    });
  const [ConfirmOpenCurrencies, setConfirmOpenCurrencies] = useState(false);
  const [modalContentCurrencies, setModalContentCurrencies] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenLanguages, setConfirmOpenLanguages] = useState(false);
  const [modalContentLanguages, setModalContentLanguages] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenOperators, setConfirmOpenOperators] = useState(false);
  const [modalContentOperators, setModalContentOperators] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [MangeLocationAddopen, setMangeLocationAddopen] = useState(false);
  const [MangeLocationEditopen, setMangeLocationEditopen] = useState(false);
  const [MangeLocationCountryAddopen, setMangeLocationCountryAddopen] =
    useState(false);
  const [
    mangeLocationCriteriaDefaultAddopen,
    setMangeLocationCriteriaDefaultAddopen,
  ] = useState(false);
  const [MangeLocationCountryEditopen, setMangeLocationCountryEditopen] =
    useState(false);
  const [MangeLocationCityAddopen, setMangeLocationCityAddopen] =
    useState(false);
  const [MangeLocationCityEditopen, setMangeLocationCityEditopen] =
    useState(false);
  const [mangeDefaultEditopen, setMangeDefaultEditopen] = useState(false);
  const [MangeCriteriaAddopen, setMangeCriteriaAddopen] = useState(false);
  const [MangeCriteriaEditopen, setMangeCriteriaEditopen] = useState(false);
  const [MangeCriteriaTypeAddopen, setMangeCriteriaTypeAddopen] =
    useState(false);
  const [MangeCriteriaTypeEditopen, setMangeCriteriaTypeEditopen] =
    useState(false);
  const [MangeCriteriaCategoryAddopen, setMangeCriteriaCategoryAddopen] =
    useState(false);
  const [MangeCriteriaCategoryEditopen, setMangeCriteriaCategoryEditopen] =
    useState(false);
  const [MangeCurrenciesAddopen, setMangeCurrenciesAddopen] = useState(false);
  const [MangeCurrenciesEditopen, setMangeCurrenciesEditopen] = useState(false);
  const [MangeLanguagesAddopen, setMangeLanguagesAddopen] = useState(false);
  const [MangeLanguagesEditopen, setMangeLanguagesEditopen] = useState(false);
  const [MangeOperatorsAddopen, setMangeOperatorsAddopen] = useState(false);
  const [MangeOperatorsEditopen, setMangeOperatorsEditopen] = useState(false);
  const [selectedFilterLocation, setSelectedFilterLocation] = useState("Zone");
  const [tableDataLocationZone, setTableDataLocationZone] = useState([]);
  const [tableDataCriteriaType, setTableDataCriteriaType] = useState([]);

  const [tableDataLocationCountry, setTableDataLocationCountry] = useState([]);
  const [tableDataLocationCity, setTableDataLocationCity] = useState([]);
  const [tableDataCriteriaDefault, setTableDataCriteriaDefault] = useState([]);
  const [tableDataCurrencies, setTableDataCurrencies] = useState([]);
  const [tableDataLanguages, setTableDataLanguages] = useState([]);

  const [tableDataCriteriaCategory, setTableDataCriteriaCategory] = useState(
    []
  );

  const [tableDataLocation, setTableDataLocation] = useState(
    tableDataLocationZone
  );
  const [columnsLocation, setColumnsLocation] = useState([]);
  const [selectedFilterCriteria, setSelectedFilterCriteria] =
    useState("Criteria Type");
  const [tableDataCriteriaPage, setTableDataCriteriaPage] = useState(
    tableDataCriteriaType
  );
  const [columnsCriteriaPage, setColumnsCriteriaPage] =
    useState(columnsCriteriaType);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  const [refreshTableZone, setRefreshTableZone] = useState(false);
  const [refreshTableCurrencies, setRefreshTableCurrencies] = useState(false);
  const [refreshTableLanguages, setRefreshTableLanguages] = useState(false);

  const [refreshTableCategory, setRefreshTableCategory] = useState(false);
  const [refreshTableType, setRefreshTableType] = useState(false);

  const [zoneSearchName, setZoneSearchName] = useState("");
  const [paginationModalZone, setPaginationModalZone] = useState({
    page: 0,
    pageSize: 10,
  });

  const [refreshTableCountry, setRefreshTableCountry] = useState(false);
  const [countrySearchName, setCountrySearchName] = useState("");
  const [paginationModalCountry, setPaginationModalCountry] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModalDefault, setPaginationModalDefault] = useState({
    page: 0,
    pageSize: 10,
  });

  const [refreshTableCity, setRefreshTableCity] = useState(false);
  const [refreshTableDefault, setRefreshTableDefault] = useState(false);

  const [citySearchName, setCitySearchName] = useState("");
  const [paginationModalCity, setPaginationModalCity] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModalCategory, setPaginationModalCategory] = useState({
    page: 0,
    pageSize: 10,
  });
  const [resetPagination, setResetPagination] = useState(false);
  const [countryFilter, setCountryFilter] = useState("");
  const [CountryOptions, setCountryOptions] = useState([]);
  const [CountryOptionsRecordGuid, setCountryOptionsRecordGuid] = useState([]);
  const [openAdvancedSearchZone, setOpenAdvancedSearchZone] = useState(true);

  const [openAdvancedSearchCountry, setOpenAdvancedSearchCountry] =
    useState(true);
  const [openAdvancedSearchCity, setOpenAdvancedSearchCity] = useState(true);
  const [isCheckedSwitchZone, setCheckedSwitchZone] = useState(false);
  const [isCheckedSwitchCountry, setCheckedSwitchCountry] = useState(false);
  const [isCheckedSwitchCity, setCheckedSwitchCity] = useState(false);
  const [isModalOpenSwitchZone, setModalOpenSwitchZone] = useState(false);
  const [isModalOpenSwitchCountry, setModalOpenSwitchCountry] = useState(false);
  const [isModalOpenSwitchCity, setModalOpenSwitchCity] = useState(false);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedDefault, setSelectedDefault] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState("");
  const [selectedCurrencies, setSelectedCurrencies] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [openExportModalZone, setOpenExportModalZone] = useState(false);

  const handleModalCloseSwitchLocationZone = () => {
    setModalOpenSwitchLocationZone(false);
  };
  const handleModalConfirmSwitchLocationZone = () => {
    setModalOpenSwitchLocationZone(false);
    // setCheckedSwitchCriteria(!isCheckedSwitchCriteria);
    setSwitchStatesLocationZone((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchLocationZone]: !prevStates[isCheckedSwitchLocationZone],
    }));
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);
  const exportZone = () => {
    setIsLoading(true);
    setRefreshTableZone(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_ZONE}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenExportModalZone(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Zone.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setOpenExportModalZone(false);
        setIsLoading(false);
      });
  };

  const exportCurrencies = () => {
    setIsLoading(true);
    setRefreshTableCurrencies(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_CURRENCIES}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCurrencies(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Currencies.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCurrencies(false);
        setIsLoading(false);
      });
  };
  // const exportLanguages = () => {
  //   setIsLoading(true);
  //   setRefreshTableLanguages(false);
  //   let newServices = JSON.parse(services);
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   setIsLoading(true);
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["configuration"]
  //       }${EXPORT_LANGUAGES}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setIsLoading(false);
  //       // setOpenExportModalLanguages(false);
  //       setOpenMessageAlert(true);

  //       setMessageData({
  //         message: "Downloaded Successfully ",
  //         type: "success",
  //       });
  //       const url = window.URL.createObjectURL(new Blob([res?.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `Languages.csv`); // Set the desired file name
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((err) => {
  //       // toast.error("Something Went Wrong");
  //       setIsLoading(false);
  //     })
  //     .finally(() => {
  //       // this.setState({ loading: false });
  //       // setOpenExportModalLanguages(false);
  //       setIsLoading(false);
  //     });
  // };

  const exportLanguages = async () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    try {
      const response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_LANGUAGES_TO_EXCEL}`,
        {
          ...MULTI_TENANCY_CONFIG,
          responseType: "blob",
        }
      );

      // Set the response content type to Excel
      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const headers = response.headers;
      headers["Content-Type"] = contentType;

      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "languages.xlsx";
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  // const exportCountry = () => {
  //   setIsLoading(true);
  //   setRefreshTableCountry(false);
  //   let newServices = JSON.parse(services);
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   setIsLoading(true);
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${newServices["configuration"]
  //       }${EXPORT_COUNTRY}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setIsLoading(false);
  //       // setOpenExportModalCountry(false);
  //       setOpenMessageAlert(true);

  //       setMessageData({ message: "Downloaded Successfully ", type: "success" });
  //       const url = window.URL.createObjectURL(new Blob([res?.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `Country.csv`); // Set the desired file name
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((err) => {
  //       // toast.error("Something Went Wrong");
  //       if (err === "error: 401") {
  //         setIsLoading(false);
  //         exportCountry();
  //       }
  //       setOpenMessageAlert(true);
  //       setMessageData({
  //         message: err?.response?.data?.Message
  //           ? err?.response?.data?.Message
  //           : err?.response?.data?.Exception
  //             ? err?.response?.data?.Exception
  //             : err?.response?.data?.message,
  //         type: "error",
  //       });
  //     })
  //     .finally(() => {
  //       // this.setState({ loading: false });
  //       // setOpenExportModalCountry(false);
  //       setIsLoading(false);
  //     });
  // };

  const aaaa = async (apiUrl, fileName) => {
    setIsLoading(true);
    setRefreshTableCountry(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    try {
      // Fetch data from the API
      const response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_COUNTRY_TO_EXCEL}`,
        MULTI_TENANCY_CONFIG
      );
      const data = response.data;

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, `countries.xlsx`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error downloading Excel file:", error);
    }
  };

  const exportCountry = async () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    try {
      const response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_COUNTRY_TO_EXCEL}`,
        {
          ...MULTI_TENANCY_CONFIG,
          responseType: "blob",
        }
      );

      // Set the response content type to Excel
      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const headers = response.headers;
      headers["Content-Type"] = contentType;

      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "countries.xlsx";
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  const exportCity = () => {
    setIsLoading(true);
    setRefreshTableCity(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_CITY}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `City.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportCity();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };
  const exportDefault = () => {
    setIsLoading(true);
    setRefreshTableCity(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_CITY}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `City.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportCity();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };

  const tableQueryExecuterZone = () => {
    setIsLoading(true);
    setRefreshTableZone(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_ZONE}?PageIndex=${
          resetPagination ? 1 : paginationModalZone?.page + 1
        }&PageSize=${paginationModalZone?.pageSize}${
          searchZone ? `&Name=${searchZone}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationZone({
          data: res?.data?.data?.zones ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterZone();
        }
        setTableDataLocationZone({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tableQueryExecuterCurrencies = () => {
    setIsLoading(true);
    setRefreshTableCurrencies(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CURRENCIES_DATA_SEED}?PageIndex=${
          resetPagination ? 1 : paginationModalCurrencies?.page + 1
        }&PageSize=${paginationModalCurrencies?.pageSize}${
          searchCurrencies ? `&SearchKeyword=${searchCurrencies}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCurrencies({
          data: res?.data?.data?.currencies ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCurrencies();
        }
        setTableDataCurrencies({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterLanguages = () => {
    setIsLoading(true);
    setRefreshTableLanguages(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_LANGUAGES_DATA_SEED}?PageIndex=${
          resetPagination ? 1 : paginationModalLanguages?.page + 1
        }&PageSize=${paginationModalLanguages?.pageSize}${
          searchLanguages ? `&SearchKeyword=${searchLanguages}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLanguages({
          data: res?.data?.data?.languages ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterLanguages();
        }
        setTableDataLanguages({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterDefault = () => {
    setIsLoading(true);
    setRefreshTableDefault(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CRITERIA}?PageIndex=${
          resetPagination ? 1 : paginationModalDefault?.page + 1
        }&PageSize=${paginationModalDefault?.pageSize}${
          searchDefault ? `&SearchKeyword=${searchDefault}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCriteriaDefault({
          data: res?.data?.data?.criteria ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterDefault();
        }
        setTableDataCriteriaDefault({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterType = () => {
    setIsLoading(true);
    setRefreshTableType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CRITERIA_TYPE}?PageIndex=${
          resetPagination ? 1 : paginationModalType?.page + 1
        }&PageSize=${paginationModalType?.pageSize}${
          searchType ? `&SearchKeyword=${searchType}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCriteriaType({
          data: res?.data?.data?.criteriaTypes ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterType();
        }
        setTableDataCriteriaType({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterCategory = () => {
    setIsLoading(true);
    setRefreshTableCategory(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CRITERIA_CATEGORY}?PageIndex=${
          resetPagination ? 1 : paginationModalCategory?.page + 1
        }&PageSize=${paginationModalCategory?.pageSize}${
          searchCategory ? `&SearchKeyword=${searchCategory}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCriteriaCategory({
          data: res?.data?.data?.criteriaCategories ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCategory();
        }
        setTableDataCriteriaCategory({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterCountry = () => {
    setIsLoading(true);
    setRefreshTableCountry(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_COUNTRY}?PageIndex=${
          resetPagination ? 1 : paginationModalCountry?.page + 1
        }&PageSize=${paginationModalCountry?.pageSize}${
          searchCountry ? `&SearchKeyword=${searchCountry}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationCountry({
          data: res?.data?.data?.countries ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCountry();
        }
        setTableDataLocationCountry({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tableQueryExecuterCity = () => {
    setIsLoading(true);
    setRefreshTableCity(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CITY}?PageIndex=${
          resetPagination ? 1 : paginationModalCity?.page + 1
        }&PageSize=${paginationModalCity?.pageSize}${
          searchCity ? `&SearchKeyword=${searchCity}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationCity({
          data: res?.data?.data?.cities ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCity();
        }
        setTableDataLocationCity({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  useEffect(() => {
    if (activeBtn === "Zone" && (refreshTableZone || paginationModalZone)) {
      if (!paginationModalZone) {
        setPaginationModalZone({
          page: 0,
          pageSize: paginationModalZone?.pageSize
            ? paginationModalZone?.pageSize
            : 10,
        });
      }
      tableQueryExecuterZone();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableZone,
    paginationModalZone,
    searchZone,
    // countryFilter,
  ]);

  useEffect(() => {
    if (
      activeBtn === "Languages" &&
      (refreshTableLanguages || paginationModalLanguages)
    ) {
      if (!paginationModalLanguages) {
        setPaginationModalLanguages({
          page: 0,
          pageSize: paginationModalLanguages?.pageSize
            ? paginationModalLanguages?.pageSize
            : 10,
        });
      }
      tableQueryExecuterLanguages();
      // tableQueryExecuterMNC();
    }
  }, [refreshTableLanguages, paginationModalLanguages, searchLanguages]);
  useEffect(() => {
    if (
      activeBtn === "Currencies" &&
      (refreshTableCurrencies || paginationModalCurrencies)
    ) {
      if (!paginationModalCurrencies) {
        setPaginationModalCurrencies({
          page: 0,
          pageSize: paginationModalCurrencies?.pageSize
            ? paginationModalCurrencies?.pageSize
            : 10,
        });
      }
      tableQueryExecuterCurrencies();
      // tableQueryExecuterMNC();
    }
  }, [refreshTableCurrencies, paginationModalCurrencies, searchCurrencies]);
  useEffect(() => {
    if (
      activeBtn === "List" &&
      (refreshTableDefault || paginationModalDefault)
    ) {
      if (!paginationModalDefault) {
        setPaginationModalDefault({
          page: 0,
          pageSize: paginationModalDefault?.pageSize
            ? paginationModalDefault?.pageSize
            : 10,
        });
      }
      tableQueryExecuterDefault();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableDefault,
    paginationModalDefault,
    searchDefault,
    // countryFilter,
  ]);
  useEffect(() => {
    if (activeBtn === "Type" && (refreshTableType || paginationModalType)) {
      if (!paginationModalType) {
        setPaginationModalType({
          page: 0,
          pageSize: paginationModalType?.pageSize
            ? paginationModalType?.pageSize
            : 10,
        });
      }
      tableQueryExecuterType();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableType,
    paginationModalType,
    searchType,
    // countryFilter,
  ]);
  useEffect(() => {
    if (
      activeBtn === "Category" &&
      (refreshTableCategory || paginationModalCategory)
    ) {
      if (!paginationModalCategory) {
        setPaginationModalCategory({
          page: 0,
          pageSize: paginationModalCategory?.pageSize
            ? paginationModalCategory?.pageSize
            : 10,
        });
      }
      tableQueryExecuterCategory();
    }
  }, [
    refreshTableCategory,
    paginationModalCategory,
    searchCategory,
    // countryFilter,
  ]);
  useEffect(() => {
    if (searchCategory !== "") {
      setPaginationModalCategory({
        page: 0,
        pageSize: paginationModalCategory?.pageSize
          ? paginationModalCategory?.pageSize
          : 10,
      });
    }
  }, [searchCategory]);

  useEffect(() => {
    if (searchDefault !== "") {
      setPaginationModalDefault({
        page: 0,
        pageSize: paginationModalDefault?.pageSize
          ? paginationModalDefault?.pageSize
          : 10,
      });
    }
  }, [searchDefault]);

  useEffect(() => {
    if (searchType !== "") {
      setPaginationModalType({
        page: 0,
        pageSize: paginationModalType?.pageSize
          ? paginationModalType?.pageSize
          : 10,
      });
    }
  }, [searchType]);
  useEffect(() => {
    if (searchZone !== "") {
      setPaginationModalZone({
        page: 0,
        pageSize: paginationModalZone?.pageSize
          ? paginationModalZone?.pageSize
          : 10,
      });
    }
  }, [searchZone]);
  useEffect(() => {
    if (searchCountry !== "") {
      setPaginationModalCountry({
        page: 0,
        pageSize: paginationModalCountry?.pageSize
          ? paginationModalCountry?.pageSize
          : 10,
      });
    }
  }, [searchCountry]);
  useEffect(() => {
    if (searchCity !== "") {
      setPaginationModalCity({
        page: 0,
        pageSize: paginationModalCity?.pageSize
          ? paginationModalCity?.pageSize
          : 10,
      });
    }
  }, [searchCity]);

  useEffect(() => {
    if (searchCurrencies !== "") {
      setPaginationModalCurrencies({
        page: 0,
        pageSize: paginationModalCurrencies?.pageSize
          ? paginationModalCurrencies?.pageSize
          : 10,
      });
    }
  }, [searchCurrencies]);

  useEffect(() => {
    if (searchLanguages !== "") {
      setPaginationModalLanguages({
        page: 0,
        pageSize: paginationModalLanguages?.pageSize
          ? paginationModalLanguages?.pageSize
          : 10,
      });
    }
  }, [searchLanguages]);

  useEffect(() => {
    if (
      activeBtn === "Country" &&
      (refreshTableCountry || paginationModalCountry)
    ) {
      if (!paginationModalCountry) {
        setPaginationModalCountry({
          page: 0,
          pageSize: paginationModalCountry?.pageSize
            ? paginationModalCountry?.pageSize
            : 10,
        });
      }
      tableQueryExecuterCountry();
      // tableQueryExecuterPrefixes();
    }
  }, [refreshTableCountry, paginationModalCountry, searchCountry]);

  useEffect(() => {
    if (activeBtn === "City" && (refreshTableCity || paginationModalCity)) {
      if (!paginationModalCity) {
        setPaginationModalCity({
          page: 0,
          pageSize: paginationModalCity?.pageSize
            ? paginationModalCity?.pageSize
            : 10,
        });
      }
      tableQueryExecuterCity();
    }
  }, [
    refreshTableCity,
    paginationModalCity,
    searchCity,
    // countryFilter,
  ]);
  useEffect(() => {
    if (activeBtn === "Zone") {
      setPaginationModalZone({
        page: 0,
        pageSize: 10,
      });
      setSearchZone("");
      setZoneSearchName("");
    }
    if (activeBtn === "Country") {
      setPaginationModalCountry({
        page: 0,
        pageSize: 10,
      });
      setCountrySearchName("");
      setSearchCountry("");
    }
    if (activeBtn === "City") {
      setPaginationModalCity({
        page: 0,
        pageSize: 10,
      });
      setCitySearchName("");
      setSearchCity("");
    }
    if (activeBtn === "List") {
      setPaginationModalDefault({
        page: 0,
        pageSize: 10,
      });
      setSearchDefault("");
    }
    if (activeBtn === "Category") {
      setPaginationModalCategory({
        page: 0,
        pageSize: 10,
      });
      setSearchCategory("");
    }
    if (activeBtn === "Type") {
      setPaginationModalType({
        page: 0,
        pageSize: 10,
      });
      setSearchType("");
    }

    if (activeBtn === "Languages") {
      setPaginationModalLanguages({
        page: 0,
        pageSize: 10,
      });
      setSearchLanguages("");
    }

    if (activeBtn === "Currencies") {
      setPaginationModalCurrencies({
        page: 0,
        pageSize: 10,
      });
      setSearchCurrencies("");
    }
  }, [activeBtn]);
  const handleSwitchChangeSwitchLocationCountry = (rowData) => {
    setModalOpenSwitchLocationCountry(true);
    setCheckedSwitchLocationCountry(rowData?.recordGuid);
    setSelectedCountry(rowData);
  };

  const handleModalCloseSwitchLocationCountry = () => {
    setModalOpenSwitchLocationCountry(false);
  };

  const handleModalConfirmSwitchLocationCountry = () => {
    setModalOpenSwitchLocationCountry(false);
    // setCheckedSwitchLocation(!isCheckedSwitchLocation);
    setswitchStatesLocationCountry((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchLocationCountry]:
        !prevStates[isCheckedSwitchLocationCountry],
    }));
  };

  //
  const handleSwitchChangeSwitchLocationZone = (rowId) => {
    setModalOpenSwitchLocationZone(true);
    setCheckedSwitchLocationZone(rowId);
  };
  let columnsLocationZone = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },

    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params.row?.isActive}
                  onChange={() => {
                    handleSwitchChangeSwitchLocationZone(rowId);
                    setSelectedZone(params.row);
                  }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  const handleSwitchChangeSwitchLocationCity = (rowId) => {
    setModalOpenSwitchLocationCity(true);
    setCheckedSwitchLocationCity(rowId?.recordGuid);
    setSelectedCategory(rowId);
  };
  const handleSwitchChangeSwitchCategory = (rowId) => {
    setModalOpenSwitchCategory(true);
    setCheckedSwitchCategory(rowId);
  };
  const handleModalCloseSwitchLocationCity = () => {
    setModalOpenSwitchLocationCity(false);
  };

  const handleModalConfirmSwitchLocationCity = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLocationCity,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_CITY}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCity(true);
        setModalOpenSwitchLocationCity(false);
        // setRefreshTableCity(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLocationCity(isCheckedSwitchCity);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //

  const handleSwitchChangeSwitchCriteria = (rowId) => {
    setModalOpenSwitchCriteria(true);
    setCheckedSwitchCriteria(rowId);
  };

  const handleModalCloseSwitchCriteria = () => {
    setModalOpenSwitchCriteria(false);
  };
  const handleModalConfirmSwitchCriteria = () => {
    setModalOpenSwitchCriteria(false);
    // setCheckedSwitchCriteria(!isCheckedSwitchCriteria);
    setSwitchStatesCriteria((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCriteria]: !prevStates[isCheckedSwitchCriteria],
    }));
  };

  const handleSwitchChangeSwitchCriteriaType = (data) => {
    setModalOpenSwitchCriteriaType(true);
    setCheckedSwitchCriteriaType(data?.recordGuid);
    setSelectedType(data);
  };

  const handleModalCloseSwitchCriteriaType = () => {
    setModalOpenSwitchCriteriaType(false);
  };
  const handleModalConfirmSwitchCriteriaType = () => {
    setModalOpenSwitchCriteriaType(false);
    // setCheckedSwitchCriteria(!isCheckedSwitchCriteria);
    setSwitchStatesCriteriaType((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCriteriaType]: !prevStates[isCheckedSwitchCriteriaType],
    }));
  };

  const handleSwitchChangeSwitchCriteriaCategory = (rowId) => {
    setModalOpenSwitchCriteriaCategory(true);
    setCheckedSwitchCriteriaCategory(rowId);
  };

  const handleModalCloseSwitchCriteriaCategory = () => {
    setModalOpenSwitchCategory(false);
  };
  const handleModalConfirmSwitchCriteriaCategory = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchCriteriaCategory,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_CATEGORY}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCategory(true);
        setModalOpenSwitchCategory(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchCriteriaCategory(
            isCheckedSwitchCriteriaCategory
          );
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //

  const handleSwitchChangeSwitchCurrencies = (rowId) => {
    setModalOpenSwitchCurrencies(true);
    setCheckedSwitchCurrencies(rowId?.recordGuid);
    setSelectedCurrencies(rowId);
  };

  const handleModalCloseSwitchCurrencies = () => {
    setModalOpenSwitchCurrencies(false);
  };
  const handleModalConfirmSwitchCurrencies = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchCurrencies,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_CURRENCIES}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCurrencies(true);
        setModalOpenSwitchCurrencies(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchCurrencies(isCheckedSwitchCurrencies);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //

  const handleSwitchChangeSwitchOperators = (rowId) => {
    setModalOpenSwitchOperators(true);
    setCheckedSwitchOperators(rowId);
  };

  const handleModalCloseSwitchOperators = () => {
    setModalOpenSwitchOperators(false);
  };
  const handleModalConfirmSwitchOperators = () => {
    setModalOpenSwitchOperators(false);
    // setCheckedSwitchOperators(!isCheckedSwitchOperators);
    setSwitchStatesOperators((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchOperators]: !prevStates[isCheckedSwitchOperators],
    }));
  };
  const handleSwitchChangeSwitchOperatorsExlusive = (rowId) => {
    setModalOpenSwitchOperatorsExlusive(true);
    setCheckedSwitchOperatorsExlusive(rowId);
  };

  const handleModalCloseSwitchOperatorsExlusive = () => {
    setModalOpenSwitchOperatorsExlusive(false);
  };
  const handleModalConfirmSwitchOperatorsExlusive = () => {
    setModalOpenSwitchOperatorsExlusive(false);
    // setCheckedSwitchOperators(!isCheckedSwitchOperators);
    setSwitchStatesOperatorsExlusive((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchOperatorsExlusive]:
        !prevStates[isCheckedSwitchOperatorsExlusive],
    }));
  };

  const handleSwitchChangeSwitchLanguages = (rowId) => {
    setModalOpenSwitchLanguages(true);
    setCheckedSwitchLanguages(rowId?.recordGuid);
  };

  const handleModalCloseSwitchLanguages = () => {
    setModalOpenSwitchLanguages(false);
  };
  const handleModalConfirmSwitchLanguages = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLanguages,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_LANG_STATUS}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableLanguages(true);
        setModalOpenSwitchLanguages(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLanguages(isCheckedSwitchLanguages);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //
  const handleSwitchChangeSwitchZone = (rowId) => {
    setModalOpenSwitchZone(true);
    setCheckedSwitchZone(rowId);
  };
  const handleSwitchChangeSwitchCountry = (rowId) => {
    setModalOpenSwitchCountry(true);
    setCheckedSwitchCountry(rowId);
  };
  const handleSwitchChangeSwitchCity = (rowId) => {
    setModalOpenSwitchCity(true);
    setCheckedSwitchCity(rowId);
  };
  const handleModalCloseSwitchZone = () => {
    setModalOpenSwitchZone(false);
  };
  const handleModalCloseSwitchCountry = () => {
    setModalOpenSwitchCountry(false);
  };
  const handleModalCloseSwitchXity = () => {
    setModalOpenSwitchCity(false);
  };

  const handleModalConfirmSwitchZone = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLocationZone,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_ZONE}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableZone(true);
        setModalOpenSwitchLocationZone(false);
        // setRefreshTableZone(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchZone(isCheckedSwitchZone);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })

      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleModalConfirmSwitchCountry = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLocationCountry,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_COUNTRY}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCountry(true);
        setModalOpenSwitchLocationCountry(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchCountry(isCheckedSwitchCountry);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalConfirmSwitchLanguagesRL = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLanguagesRL,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_LANG_RL}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableLanguages(true);
        setModalOpenSwitchLanguagesRL(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLanguagesRL(isCheckedSwitchLanguagesRL);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalCloseSwitchLanguagesTR = () => {
    setModalOpenSwitchLanguagesTR(false);
  };
  const handleModalConfirmSwitchLanguagesTR = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLanguagesTR,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_LANG_TR}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableLanguages(true);
        setModalOpenSwitchLanguagesTR(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLanguagesTR(isCheckedSwitchLanguagesTR);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSwitchChangeSwitchLanguagesRL = (rowId) => {
    setModalOpenSwitchLanguagesRL(true);
    setCheckedSwitchLanguagesRL(rowId?.recordGuid);
  };
  const handleSwitchChangeSwitchLanguagesTR = (rowId) => {
    setModalOpenSwitchLanguagesTR(true);
    setCheckedSwitchLanguagesTR(rowId?.recordGuid);
  };
  const handleModalCloseSwitchLanguagesRL = () => {
    setModalOpenSwitchLanguagesRL(false);
  };

  // const tableDataLocationZone = [
  //   { id: 1, name: "Zone 1", tag: "Tag 1", status: "Inactive" },
  //   { id: 2, name: "Zone 2", tag: "Tag 2", status: "Inactive" },
  //   { id: 3, name: "Zone 3", tag: "Tag 3", status: "Inactive" },
  // ];

  let columnsLocationCountry = [
    { headerName: "Name", field: "name", flex: 1, minWidth: 200 },
    {
      headerName: "Country ISO",
      field: "countryCode",
      flex: 1,
      minWidth: 100,
    },
    // { headerName: "ISO Code 3", field: "isoCode3",flex: 1, },
    { headerName: "Native Name", field: "nativeName", flex: 1, minWidth: 100 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params?.row?.isActive}
                  // onChange={() =>
                  //   handleSwitchChangeSwitchLocationCountry(rowId)
                  // }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  let columnsType = [
    { headerName: "Name", field: "name", flex: 1, minWidth: 200 },
    { headerName: "Description", field: "description", flex: 1, minWidth: 150 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch disabled={true} checked={params?.row?.isActive} />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  let columnsLocationCity = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Country",
      field: "country",
      renderCell: (params) => {
        const rowData = params?.row;
        return <>{rowData?.country?.name}</>;
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },

    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowData = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params?.row?.isActive}
                  onChange={() => handleSwitchChangeSwitchLocationCity(rowData)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  let columnsCategory = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },

    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: windowWidth < 1000 && 100,
      renderCell: (params) => {
        const data = params?.row;
        return <>{data?.type?.name}</>;
      },
      flex: 1,
    },
    // {
    //   headerName: "Country",
    //   field: "country",
    //   renderCell: (params) => {
    //     const rowData = params?.row;
    //     return <>{rowData?.country?.name}</>;
    //   },
    //   flex: 1,
    // },

    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowData = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params?.row?.isActive}
                  onChange={() => handleSwitchChangeSwitchCategory(rowData)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  const tableDataCriteria = [
    {
      id: 1,
      name: "criteria 1",
      criteriaCategoryId: "12322",
      tenantId: "12323342",
      parentId: "22234234",
      status: false,
      tag: "tag 1",
    },
    {
      id: 2,
      name: "criteria 2",
      criteriaCategoryId: "1322",
      status: false,
      tag: "tag 2",
      tenantId: "12323002",
      parentId: "424342",
    },
    {
      id: 3,
      name: "criteria 3",
      criteriaCategoryId: "112233",
      status: false,
      tag: "tag 3",
      tenantId: "12323377",
      parentId: "4234234",
    },
  ];
  let columnsCriteria = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    // {
    //   headerName: "Type",
    //   field: "type",

    //   renderCell: (params) => {
    //     const data = params?.row;
    //     return <>{data?.type?.name}</>;
    //   },

    //   flex: 1,
    // },

    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled
                  checked={data?.isActive}
                  // onChange={() => handleSwitchChangeSwitchCriteria(data)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  let columnsCriteriaCategory = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Criteria Type",
      field: "criteriaType",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Parent ID",
      field: "parentId",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },

    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesCriteriaCategory[rowId] || false}
                  onChange={() =>
                    handleSwitchChangeSwitchCriteriaCategory(rowId)
                  }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  let columnsCurrencies = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    // {
    //   headerName: "Country",
    //   field: "country",
    //   flex: 1,
    // },
    {
      headerName: "Symbol",
      field: "symbol",
      flex: 1,
    },
    {
      headerName: "Currency Enum",
      field: "currencyEnum",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={data?.isActive}
                  onChange={() => handleSwitchChangeSwitchCurrencies(data)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataLang = [
    {
      id: 1,
      name: "Arabic",
      code: "",
      languageMl: "",
      tag: "Tag 1",
      parentId: "12312",
      usedForTranslation: "",
      rlt: "",
      status: "Inactive",
    },
    {
      id: 2,
      name: "English",
      code: "",
      languageMl: "",
      tag: "Tag 2",
      parentId: "43322",
      usedForTranslation: "",
      rlt: "",
      status: "Inactive",
    },
  ];
  let columnsLang = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Native Language",
      field: "languageMl",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Used For Translation",
      field: "usedForTranslation",
      renderCell: (params) => {
        const rowId = params.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={rowId?.usedForTranslation}
                  onChange={() => handleSwitchChangeSwitchLanguagesTR(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "RTL",
      field: "rlt",
      renderCell: (params) => {
        const rowId = params.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={rowId?.isRtl}
                  onChange={() => handleSwitchChangeSwitchLanguagesRL(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={rowId?.isActive}
                  onChange={() => handleSwitchChangeSwitchLanguages(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  const tableDataOper = [
    {
      id: 1,
      name: "operator 1",
      tag: "Tag 1",
      parentId: "12312",
      country: "U.S",
      exlusive: "",
      status: "Inactive",
    },
    {
      id: 2,
      name: "operator 2",
      tag: "Tag 2",
      parentId: "43322",
      country: "Germany",
      exlusive: "",
      status: "Inactive",
    },
    {
      id: 2,
      name: "operator 3",
      tag: "Tag 3",
      parentId: "23232",
      country: "Lebanon",
      exlusive: "",
      status: "Inactive",
    },
  ];
  let columnsOper = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
    },
    {
      headerName: "Parent ID",
      field: "parentId",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Exlusive",
      field: "exlusive",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesOperatorsExlusive[rowId] || false}
                  onChange={() =>
                    handleSwitchChangeSwitchOperatorsExlusive(rowId)
                  }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesOperators[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchOperators(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tabs = [
    {
      label: "Location",
      subTabs: [{ label: "Zone" }, { label: "Country" }, { label: "City" }],
    },
    {
      label: "Criteria",
      subTabs: [{ label: "List" }, { label: "Type" }, { label: "Category" }],
    },
    // {
    //   label: "Currencies",
    // },
    {
      label: "Languages",
    },
    // {
    //   label: "Operators",
    // },
  ];

  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptions(
          res?.data?.data?.countries.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryOptionsRecordGuid(
          res?.data?.data?.countries.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryFilter(res.data.data.countries[0].recordGuid);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const OpenConfirmModalLocation = (data, title, text, onButtonClick) => {
    setModalContentLocation({ title, text, onButtonClick });
    setSelectedZone(data);
    setConfirmOpenLocation(true);
  };
  const OpenConfirmModalDefault = (data, title, text, onButtonClick) => {
    setSelectedDefault(data);
    setConfirmOpenDefault(true);
  };
  const handleCloseModalLocation = () => {
    setConfirmOpenLocation(false);
  };

  const handleConfirmModalLocation = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${DELETE_ZONE}?RecordGuid=${selectedZone?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenLocation(false);
        setIsLoading(false);
        setRefreshTableZone(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalZone({
          page: 0,
          pageSize: paginationModalZone?.pageSize
            ? paginationModalZone?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalLocation();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConfirmModalLanguagesDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_LANGUAGES}?RecordGuid=${selectedLanguages?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenLanguages(false);
        setIsLoading(false);
        setRefreshTableLanguages(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });

        setPaginationModalLanguages({
          page: 0,
          pageSize: paginationModalLanguages?.pageSize
            ? paginationModalLanguages?.pageSize
            : 10,
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalLanguagesDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleConfirmModalCurrenciesDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CURRENCIES}?RecordGuid=${selectedCurrencies?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenCurrencies(false);
        setIsLoading(false);
        setRefreshTableCurrencies(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalCurrencies({
          page: 0,
          pageSize: paginationModalCurrencies?.pageSize
            ? paginationModalCurrencies?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalCurrenciesDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleConfirmModalLocationCityDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CITY}?RecordGuid=${selectedCity?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenLocationCity(false);
        setIsLoading(false);
        setRefreshTableCity(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalCity({
          page: 0,
          pageSize: paginationModalCity?.pageSize
            ? paginationModalCity?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalLocationCityDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalLocationCountry = (title, text, onButtonClick) => {
    setModalContentLocationCountry({ title, text, onButtonClick });
    setConfirmOpenLocationCountry(true);
  };

  const handleConfirmModalLocationCountry = () => {
    setConfirmOpenLocationCountry(false);
  };

  //

  const OpenConfirmModalLocationCity = (data, title, text, onButtonClick) => {
    setModalContentLocationCity({ title, text, onButtonClick });
    setConfirmOpenLocationCity(true);
    setSelectedCity(data);
  };

  const handleConfirmModalLocationCity = (data) => {
    setConfirmOpenLocationCity(false);
    setSelectedCity(data);
  };

  const OpenConfirmModalCriteria = (title, text, onButtonClick) => {
    setModalContentCriteria({ title, text, onButtonClick });
    setConfirmOpenCriteria(true);
  };

  const handleConfirmModalCriteria = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CRITERIA_CATEGORY}?RecordGuid=${
          selectedCategory?.recordGuid
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenCriteriaCategory(false);
        setIsLoading(false);
        setRefreshTableCategory(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalCriteria();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalCriteriaType = (title, text, onButtonClick) => {
    setModalContentCriteriaType({ title, text, onButtonClick });
    setConfirmOpenCriteriaType(true);
  };

  const handleConfirmModalCriteriaType = () => {
    setConfirmOpenCriteriaType(false);
  };

  const OpenConfirmModalCriteriaCategory = (
    data,
    title,
    text,
    onButtonClick
  ) => {
    setModalContentCriteriaCategory({ title, text, onButtonClick });
    setConfirmOpenCriteriaCategory(!ConfirmOpenCriteriaCategory);
    setSelectedCategory(data);
  };

  const handleConfirmModalCriteriaCategory = (data) => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CRITERIA_CATEGORY}?RecordGuid=${
          selectedCategory?.recordGuid
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenCriteriaCategory(false);
        setIsLoading(false);
        setRefreshTableCategory(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalCriteriaCategory();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalCurrencies = (data, title, text, onButtonClick) => {
    setModalContentCurrencies({ title, text, onButtonClick });
    setConfirmOpenCurrencies(true);

    setSelectedCurrencies(data);
  };

  const handleConfirmModalCurrencies = () => {
    setConfirmOpenCurrencies(false);
  };

  //

  const OpenConfirmModalLanguages = (data, title, text, onButtonClick) => {
    setModalContentLanguages({ title, text, onButtonClick });
    setConfirmOpenLanguages(true);
    setSelectedLanguages(data);
  };

  const handleConfirmModalLanguages = () => {
    setConfirmOpenLanguages(false);
  };
  const OpenConfirmModalOperators = (title, text, onButtonClick) => {
    setModalContentOperators({ title, text, onButtonClick });
    setConfirmOpenOperators(true);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleConfirmModalOperators = () => {
    setConfirmOpenOperators(false);
  };

  const handleAddMangeLocationopen = () => {
    setMangeLocationAddopen(true);
  };

  const handleAddMangeLocationclose = () => {
    setMangeLocationAddopen(false);
  };
  const handleEditMangeLocationopen = (data) => {
    setMangeLocationEditopen(true);
    setSelectedZone(data);
  };

  const handleEditMangeLocationclose = () => {
    setMangeLocationEditopen(false);
  };
  const handleAddMangeLocationCountryopen = () => {
    setMangeLocationCountryAddopen(true);
  };
  const handleAddMangeLocationCriteriaDefaultopen = () => {
    setMangeLocationCriteriaDefaultAddopen(true);
  };

  const handleAddMangeLocationCountryclose = () => {
    setMangeLocationCountryAddopen(false);
  };
  const handleEditMangeLocationCountryopen = () => {
    setMangeLocationCountryEditopen(true);
  };

  const handleEditMangeLocationCountryclose = () => {
    setMangeLocationCountryEditopen(false);
  };
  const handleAddMangeLocationCityopen = (data) => {
    setSearchCity(data);
    setMangeLocationCityAddopen(true);
  };

  const handleAddMangeLocationCityclose = () => {
    setMangeLocationCityAddopen(false);
  };
  const handleEditMangeLocationCityopen = (data) => {
    setMangeLocationCityEditopen(true);
    setSelectedCity(data);
  };
  const handleEditMangeDefaultopen = (data) => {
    setMangeDefaultEditopen(true);
    setSelectedDefault(data);
  };
  const handleEditMangeLocationCityclose = () => {
    setMangeLocationCityEditopen(false);
  };

  const handleAddMangeCriteriaopen = () => {
    setMangeCriteriaAddopen(true);
  };
  const handleAddMangeCriteriaclose = () => {
    setMangeCriteriaAddopen(false);
  };
  const handleEditMangeCriteriaopen = () => {
    setMangeCriteriaEditopen(true);
  };

  const handleEditMangeCriteriaclose = () => {
    setMangeCriteriaEditopen(false);
  };

  const handleAddMangeCriteriaTypeopen = () => {
    setMangeCriteriaTypeAddopen(true);
  };

  const handleAddMangeCriteriaTypeclose = () => {
    setMangeCriteriaTypeAddopen(false);
  };
  const handleEditMangeCriteriaTypeopen = () => {
    setMangeCriteriaTypeEditopen(true);
  };

  const handleEditMangeCriteriaTypeclose = () => {
    setMangeCriteriaTypeEditopen(false);
  };

  const handleAddMangeCriteriaCategoryopen = () => {
    setMangeCriteriaCategoryAddopen(true);
  };

  const handleAddMangeCriteriaCategoryclose = () => {
    setMangeCriteriaCategoryAddopen(false);
  };
  const handleEditMangeCriteriaCategoryopen = (data) => {
    setMangeCriteriaCategoryEditopen(true);
    setSelectedCategory(data);
  };

  const handleEditMangeCriteriaCategoryclose = () => {
    setMangeCriteriaCategoryEditopen(false);
  };

  const handleAddMangeCurrenciesopen = () => {
    setMangeCurrenciesAddopen(true);
  };

  const handleAddMangeCurrenciesclose = () => {
    setMangeCurrenciesAddopen(false);
  };
  const handleEditMangeCurrenciesopen = (data) => {
    setMangeCurrenciesEditopen(true);
    setSelectedCurrencies(data);
  };

  const handleEditMangeCurrenciesclose = () => {
    setMangeCurrenciesEditopen(false);
  };

  const handleAddMangeLanguagesopen = () => {
    setMangeLanguagesAddopen(true);
  };

  const handleAddMangeLanguagesclose = () => {
    setMangeLanguagesAddopen(false);
  };
  const handleEditMangeLanguagesopen = (data) => {
    setMangeLanguagesEditopen(true);
    setSelectedLanguages(data);
  };

  const handleEditMangeLanguagesclose = () => {
    setMangeLanguagesEditopen(false);
  };

  const handleAddMangeOperatorsopen = () => {
    setMangeOperatorsAddopen(true);
  };

  const handleAddMangeOperatorsclose = () => {
    setMangeOperatorsAddopen(false);
  };
  const handleEditMangeOperatorsopen = () => {
    setMangeOperatorsEditopen(true);
  };

  const handleEditMangeOperatorsclose = () => {
    setMangeOperatorsEditopen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCountries();
  }, []);

  const handleFilterChange = (event) => {
    const newFilter = event.target.value;
    setSelectedFilterLocation(newFilter);
    switch (newFilter) {
      case "Zone":
        setTableDataLocation(tableDataLocationZone);
        setColumnsLocation(columnsLocationZone);
        break;
      case "Country":
        setTableDataLocation(tableDataLocationCountry);
        setColumnsLocation(columnsLocationCountry);
        break;
      case "City":
        setTableDataLocation(tableDataLocationCity);
        setColumnsLocation(columnsLocationCity);
        break;
      default:
    }
  };
  useEffect(() => {
    handleFilterChange({ target: { value: selectedFilterLocation } });
  }, [selectedFilterLocation]);

  const getAddLocationFunction = () => {
    switch (selectedFilterLocation) {
      case "Zone":
        return handleAddMangeLocationopen;
      case "Country":
        return handleAddMangeLocationCountryopen;
      case "City":
        return handleAddMangeLocationCityopen;
      default:
        return () => {};
    }
  };
  const getEditLocationFunction = () => {
    switch (selectedFilterLocation) {
      case "Zone":
        return handleEditMangeLocationopen;
      case "Country":
        return handleEditMangeLocationCountryopen;
      case "City":
        return handleEditMangeLocationCityopen;
      case "List":
        return handleEditMangeDefaultopen;
      default:
        return () => {};
    }
  };

  const getLocationDeleteModal = () => {
    switch (selectedFilterLocation) {
      case "Zone":
        return OpenConfirmModalLocation;
      case "Country":
        return OpenConfirmModalLocationCountry;
      case "City":
        return OpenConfirmModalLocationCity;

      case "List":
        return OpenConfirmModalDefault;
      default:
        return () => {};
    }
  };
  const handleFilterChangeCriteriaPage = (event) => {
    const newFilter = event.target.value;
    setSelectedFilterCriteria(newFilter);
    switch (newFilter) {
      case "Criteria":
        setTableDataCriteriaPage(tableDataCriteria);
        setColumnsCriteriaPage(columnsCriteria);
        break;
      case "Criteria Type":
        setTableDataCriteriaPage(tableDataCriteriaType);
        setColumnsCriteriaPage(columnsCriteriaType);
        break;
      case "Criteria Category":
        setTableDataCriteriaPage(tableDataCriteriaCategory);
        setColumnsCriteriaPage(columnsCriteriaCategory);
        break;
      default:
    }
  };
  useEffect(() => {
    handleFilterChangeCriteriaPage({
      target: { value: selectedFilterCriteria },
    });
  }, [selectedFilterCriteria]);

  const getAddCriteriaFunction = () => {
    switch (selectedFilterCriteria) {
      case "Criteria":
        return handleAddMangeCriteriaopen;
      case "Criteria Type":
        return handleAddMangeCriteriaTypeopen;
      case "Criteria Category":
        return handleAddMangeCriteriaCategoryopen;
      default:
        return () => {};
    }
  };
  const getEditCriteriaFunction = () => {
    switch (selectedFilterCriteria) {
      case "Criteria":
        return handleEditMangeCriteriaopen;
      case "Criteria Type":
        return handleEditMangeCriteriaTypeopen;
      case "Criteria Category":
        return handleEditMangeCriteriaCategoryopen;
      default:
        return () => {};
    }
  };

  const getCriteriaDeleteModal = () => {
    switch (selectedFilterCriteria) {
      case "Criteria":
        return OpenConfirmModalCriteria;
      case "Criteria Type":
        return OpenConfirmModalCriteriaType;
      case "Criteria Category":
        return OpenConfirmModalCriteriaCategory;
      default:
        return () => {};
    }
  };
  const handleOpenAdvancedSearchZone = () => {
    setOpenAdvancedSearchZone(!openAdvancedSearchZone);
  };
  const handleOpenAdvancedSearchCountry = () => {
    setOpenAdvancedSearchCountry(!openAdvancedSearchCountry);
  };
  const handleOpenAdvancedSearchCity = () => {
    setOpenAdvancedSearchCity(!openAdvancedSearchCity);
  };
  return (
    <div className="billing">
      <Grid container>
        <Grid item xs={6} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Data Seed</Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          // sx={{ marginTop: windowWidth < 1000 && "-25px" }}
          item
          xs={6}
          md={6}
          className="end"
        >
          {/* {activeBtn === "Location" && (
            <Button className="contained" onClick={getAddLocationFunction()}>
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {`Add ${selectedFilterLocation}`}
              </Typography>
            </Button>
          )} */}
          {activeBtn === "Zone" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeLocationopen()}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>{`Add Zone`}</Typography>
            </Button>
          )}
          {activeBtn === "Country" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeLocationCountryopen()}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>{`Add Country`}</Typography>
            </Button>
          )}
          {activeBtn === "Category" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeCriteriaCategoryopen()}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography
                sx={{ fontSize: "14px" }}
              >{`Add Category`}</Typography>
            </Button>
          )}
          {activeBtn === "List" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeLocationCriteriaDefaultopen()}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography
                sx={{ fontSize: "14px" }}
              >{`Add Criteria`}</Typography>
            </Button>
          )}
          {activeBtn === "Type" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeLocationCriteriaDefaultopen()}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography
                sx={{ fontSize: "14px" }}
              >{`Add Criteria`}</Typography>
            </Button>
          )}
          {activeBtn === "City" && (
            <Button
              style={{ visibility: "hidden" }}
              className="contained"
              onClick={() => handleAddMangeLocationCityopen()}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>{`Add City`}</Typography>
            </Button>
          )}
          {activeBtn === "Criteria" && (
            <Button className="contained" onClick={getAddCriteriaFunction()}>
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {`Add ${selectedFilterCriteria}`}
              </Typography>
            </Button>
          )}
          {activeBtn === "Currencies" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeCurrenciesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Currency</Typography>
            </Button>
          )}
          {activeBtn === "Languages" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeLanguagesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Language</Typography>
            </Button>
          )}
          {activeBtn === "Operators" && (
            // <div style={{ width: "100%" }}>
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeOperatorsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Operator</Typography>
            </Button>
            // </div>
          )}
        </Grid>
      </Grid>
      {windowWidth < 1000 && (
        <Grid
          style={{ marginLeft: "0px" }}
          container
          marginLeft={2}
          marginTop={5}
        >
          <HorizontalTabsSubtabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />
        </Grid>
      )}
      {windowWidth > 300 ? (
        <Grid container className="Top-spacing-data div-table">
          {windowWidth > 1000 && (
            <Grid
              item
              xs={windowWidth < 1800 ? 1.7 : 1.3}
              className="vertical-grid"
            >
              <VerticalTabs
                activeBtn={activeBtn}
                tabs={tabs}
                setActiveBtn={setActiveBtn}
              />
            </Grid>
          )}
          <Grid
            item
            xs={
              windowWidth < 1800 && windowWidth > 1000
                ? 10.3
                : windowWidth < 1000 ?
                12
                : 10.7
            }
          >
            {activeBtn === "Zone" && (
              <Paper variant="CardTable">
                <Table
                  exportFunction={exportZone}
                  rowCountState={tableDataLocationZone?.totalCount}
                  data={tableDataLocationZone?.data}
                  isLoading={isLoading}
                  columns={columnsLocationZone}
                  pageIndex={
                    tableDataLocationZone?.pageIndex
                      ? tableDataLocationZone?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalZone}
                  paginationModel={paginationModalZone}
                  enableDelete={true}
                  enableEdit={true}
                  deleteFunction={OpenConfirmModalLocation}
                  editFunction={handleEditMangeLocationopen}
                  enableSearch={true}
                  search={searchZone}
                  setSearch={setSearchZone}
                  enableExportBtn={true}
                />
              </Paper>
            )}
            {activeBtn === "Country" && (
              <Paper variant="CardTable">
                <Table
                  exportFunction={exportCountry}
                  rowCountState={tableDataLocationCountry?.totalCount}
                  data={tableDataLocationCountry?.data}
                  isLoading={isLoading}
                  columns={columnsLocationCountry}
                  pageIndex={
                    tableDataLocationCountry?.pageIndex
                      ? tableDataLocationCountry?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalCountry}
                  paginationModel={paginationModalCountry}
                  disableActions={true}
                  // enableDelete={true}
                  // enableEdit={true}
                  // deleteFunction={() => OpenConfirmModalLocationCountry()}
                  // editFunction={() => handleEditMangeLocationCountryopen()}
                  enableSearch={true}
                  search={searchCountry}
                  setSearch={setSearchCountry}
                  enableExportBtn={true}
                />
              </Paper>
            )}
            {activeBtn === "Type" && (
              <Paper variant="CardTable">
                <Table
                  // exportFunction={exportType}
                  rowCountState={tableDataCriteriaType?.totalCount}
                  data={tableDataCriteriaType?.data}
                  isLoading={isLoading}
                  columns={columnsType}
                  pageIndex={
                    tableDataCriteriaType?.pageIndex
                      ? tableDataCriteriaType?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalType}
                  paginationModel={paginationModalType}
                  disableActions={true}
                  // enableDelete={true}
                  // enableEdit={true}
                  // deleteFunction={() => OpenConfirmModalLocationType()}
                  // editFunction={() => handleEditMangeLocationTypeopen()}
                  enableSearch={true}
                  search={searchType}
                  setSearch={setSearchType}
                  // enableExportBtn={true}
                />
              </Paper>
            )}
            {activeBtn === "List" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataCriteriaDefault?.totalCount}
                  data={tableDataCriteriaDefault?.data}
                  isLoading={isLoading}
                  columns={columnsCriteria}
                  pageIndex={
                    tableDataCriteriaDefault?.pageIndex
                      ? tableDataCriteriaDefault?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalDefault}
                  paginationModel={paginationModalDefault}
                  // enableDelete={true}
                  // enableEdit={true}
                  // deleteFunction={OpenConfirmModalDefault}
                  // editFunction={handleEditMangeDefaultopen}
                  enableSearch={true}
                  search={searchDefault}
                  setSearch={setSearchDefault}
                  // enableExportBtn={true}
                  exportFunction={exportDefault}
                  disableActions={true}
                />
              </Paper>
            )}
            {activeBtn === "Category" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataCriteriaCategory?.totalCount}
                  data={tableDataCriteriaCategory?.data}
                  isLoading={isLoading}
                  columns={columnsCategory}
                  pageIndex={
                    tableDataCriteriaCategory?.pageIndex
                      ? tableDataCriteriaCategory?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalCategory}
                  paginationModel={paginationModalCategory}
                  // enableDelete={true}
                  // enableEdit={true}
                  // deleteFunction={OpenConfirmModalCriteriaCategory}
                  // editFunction={handleEditMangeCriteriaCategoryopen}
                  enableSearch={true}
                  search={searchCategory}
                  setSearch={setSearchCategory}
                  // enableExportBtn={true}
                  exportFunction={exportDefault}
                  disableActions={true}
                />
              </Paper>
            )}
            {activeBtn === "City" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataLocationCity?.totalCount}
                  data={tableDataLocationCity?.data}
                  isLoading={isLoading}
                  columns={columnsLocationCity}
                  pageIndex={
                    tableDataLocationCity?.pageIndex
                      ? tableDataLocationCity?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalCity}
                  paginationModel={paginationModalCity}
                  // enableDelete={false}
                  // enableEdit={false}
                  // deleteFunction={OpenConfirmModalLocationCity}
                  // editFunction={handleEditMangeLocationCityopen}
                  enableSearch={true}
                  search={searchCity}
                  setSearch={setSearchCity}
                  // enableFilter={true}
                  // valueFilter={selectedFilterLocation}
                  // setValueFilter={setSelectedFilterLocation}
                  // onChangeFilter={handleFilterChange}
                  // optionsFilter={["Zone", "Country", "City"]}
                  enableExportBtn={true}
                  exportFunction={exportCity}
                  disableActions={true}
                />
              </Paper>
            )}
            {activeBtn === "Criteria" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataCriteriaPage?.totalCount}
                  data={tableDataCriteriaPage}
                  isLoading={isLoading}
                  columns={columnsCriteriaPage}
                  pageIndex={
                    tableDataCriteriaPage?.pageIndex
                      ? tableDataCriteriaPage?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  disableActions={true}
                  // enableDelete={true}
                  // enableEdit={true}
                  // deleteFunction={() => getCriteriaDeleteModal()}
                  // editFunction={() => getEditCriteriaFunction()}
                  // enableSearch={true}
                  enableFilter={true}
                  valueFilter={selectedFilterCriteria}
                  setValueFilter={setSelectedFilterCriteria}
                  onChangeFilter={handleFilterChangeCriteriaPage}
                  optionsFilter={[
                    "Criteria",
                    "Criteria Type",
                    "Criteria Category",
                  ]}
                  // enableExportBtn={true}
                />
              </Paper>
            )}

            {activeBtn === "Currencies" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataCurrencies?.totalCount}
                  data={tableDataCurrencies?.data}
                  isLoading={isLoading}
                  columns={columnsCurrencies}
                  pageIndex={
                    tableDataCurrencies?.pageIndex
                      ? tableDataCurrencies?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalCurrencies}
                  paginationModel={paginationModalCurrencies}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  deleteFunction={OpenConfirmModalCurrencies}
                  editFunction={handleEditMangeCurrenciesopen}
                  enableSearch={true}
                  search={searchCurrencies}
                  setSearch={setSearchCurrencies}
                  exportFunction={exportCurrencies}
                />
              </Paper>
            )}
            {activeBtn === "Languages" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataLanguages?.totalCount}
                  data={tableDataLanguages?.data}
                  isLoading={isLoading}
                  columns={columnsLang}
                  pageIndex={
                    tableDataLanguages?.pageIndex
                      ? tableDataLanguages?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalLanguages}
                  paginationModel={paginationModalLanguages}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  deleteFunction={OpenConfirmModalLanguages}
                  editFunction={handleEditMangeLanguagesopen}
                  enableSearch={true}
                  search={searchLanguages}
                  setSearch={setSearchLanguages}
                  exportFunction={exportLanguages}
                />
              </Paper>
            )}
            {activeBtn === "Operators" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataOper?.totalCount}
                  data={tableDataOper}
                  columns={columnsOper}
                  pageIndex={
                    tableDataOper?.pageIndex ? tableDataOper?.pageIndex : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  enableDelete={true}
                  enableEdit={true}
                  enableExportBtn={true}
                  deleteFunction={OpenConfirmModalOperators}
                  editFunction={handleEditMangeOperatorsopen}
                  // enableSearch={true}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          md={10}
          lg={10}
          xs={
            windowWidth < 1800 && windowWidth > 1000
              ? 10.3
              : windowWidth < 1000
              ? 15
              : 10.7
          }
        >
          {activeBtn === "Location" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataLocation?.totalCount}
                data={tableDataLocation}
                isLoading={isLoading}
                columns={columnsLocation}
                pageIndex={
                  tableDataLocation?.pageIndex
                    ? tableDataLocation?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={() => getLocationDeleteModal()()}
                editFunction={() => getEditLocationFunction()()}
                // enableSearch={true}
                enableFilter={true}
                valueFilter={selectedFilterLocation}
                setValueFilter={setSelectedFilterLocation}
                onChangeFilter={handleFilterChange}
                optionsFilter={["Zone", "Country", "City"]}
                enableExportBtn={true}
              />
            </Paper>
          )}
          {activeBtn === "Criteria" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataCriteriaPage?.totalCount}
                data={tableDataCriteriaPage}
                isLoading={isLoading}
                columns={columnsCriteriaPage}
                pageIndex={
                  tableDataCriteriaPage?.pageIndex
                    ? tableDataCriteriaPage?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModalCurrencies}
                paginationModel={paginationModalCurrencies}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={() => getCriteriaDeleteModal()()}
                editFunction={() => getEditCriteriaFunction()()}
                // enableSearch={true}
                enableFilter={true}
                valueFilter={selectedFilterCriteria}
                setValueFilter={setSelectedFilterCriteria}
                onChangeFilter={handleFilterChangeCriteriaPage}
                optionsFilter={[
                  "Criteria",
                  "Criteria Type",
                  "Criteria Category",
                ]}
                enableExportBtn={true}
              />
            </Paper>
          )}

          {activeBtn === "Currencies" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataCurrencies?.totalCount}
                data={tableDataCurrencies}
                isLoading={isLoading}
                columns={columnsCurrencies}
                pageIndex={
                  tableDataCurrencies?.pageIndex
                    ? tableDataCurrencies?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModalCurrencies}
                paginationModel={paginationModalCurrencies}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                deleteFunction={OpenConfirmModalCurrencies}
                editFunction={handleEditMangeCurrenciesopen}
                // enableSearch={true}
              />
            </Paper>
          )}
          {activeBtn === "Languages" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataLang?.totalCount}
                data={tableDataLang}
                isLoading={isLoading}
                columns={columnsLang}
                pageIndex={
                  tableDataLang?.pageIndex ? tableDataLang?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                deleteFunction={OpenConfirmModalLanguages}
                editFunction={handleEditMangeLanguagesopen}
                // enableSearch={true}
              />
            </Paper>
          )}
          {activeBtn === "Operators" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataOper?.totalCount}
                data={tableDataOper}
                columns={columnsOper}
                pageIndex={
                  tableDataOper?.pageIndex ? tableDataOper?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                enableExportBtn={true}
                deleteFunction={OpenConfirmModalOperators}
                editFunction={handleEditMangeOperatorsopen}
                // enableSearch={true}
              />
            </Paper>
          )}
        </Grid>
      )}
      {/*  
      function of delete button
      onClick={() => OpenConfirmModal("Modal Title", "Modal Text", () => console.log("Button Clicked"))} 
      */}

      {/* Delete location Modal */}
      {handleConfirmModalLocation && (
        <ConfirmationModal
          open={ConfirmOpenLocation}
          onClose={handleCloseModalLocation}
          title="Delete"
          text="Are you sure you want to delete this zone ?"
          onButtonClick={handleConfirmModalLocation}
        />
      )}
      {/* Delete location Country Modal */}
      {handleConfirmModalLocationCountry && (
        <ConfirmationModal
          open={ConfirmOpenLocationCountry}
          onClose={handleConfirmModalLocationCountry}
          title="Delete"
          text="Are you sure you want to delete this country ?"
          onButtonClick={handleConfirmModalLocationCountry}
        />
      )}
      {/* Delete location City Modal */}
      {handleConfirmModalLocationCity && (
        <ConfirmationModal
          open={ConfirmOpenLocationCity}
          onClose={handleConfirmModalLocationCity}
          title="Delete"
          text="Are you sure you want to delete this city ?"
          onButtonClick={handleConfirmModalLocationCityDelete}
        />
      )}
      {/* Delete Criteria Modal */}
      {handleConfirmModalCriteria && (
        <ConfirmationModal
          open={ConfirmOpenCriteria}
          onClose={handleConfirmModalCriteria}
          title="Delete"
          text="Are you sure you want to delete this criteria ?"
          onButtonClick={handleConfirmModalCriteria}
        />
      )}
      {/* Delete Criteria Type Modal */}
      {handleConfirmModalCriteriaType && (
        <ConfirmationModal
          open={ConfirmOpenCriteriaType}
          onClose={handleConfirmModalCriteriaType}
          title="Delete"
          text="Are you sure you want to delete this criteria type?"
          onButtonClick={handleConfirmModalCriteria}
        />
      )}

      {/* Delete Criteria Category Modal */}
      {OpenConfirmModalCriteriaCategory && (
        <ConfirmationModal
          open={ConfirmOpenCriteriaCategory}
          onClose={OpenConfirmModalCriteriaCategory}
          title="Delete"
          text="Are you sure you want to delete this criteria category?"
          onButtonClick={handleConfirmModalCriteriaCategory}
        />
      )}
      {/* Delete Currencies Modal */}
      {handleConfirmModalCurrencies && (
        <ConfirmationModal
          open={ConfirmOpenCurrencies}
          onClose={handleConfirmModalCurrencies}
          title="Delete"
          text="Are you sure you want to delete this currency ?"
          onButtonClick={handleConfirmModalCurrenciesDelete}
        />
      )}
      {/* Delete Languages Modal */}
      {handleConfirmModalLanguages && (
        <ConfirmationModal
          open={ConfirmOpenLanguages}
          onClose={handleConfirmModalLanguages}
          title="Delete"
          text="Are you sure you want to delete this language ?"
          onButtonClick={handleConfirmModalLanguagesDelete}
        />
      )}
      {/* Delete Operators Modal */}
      {handleConfirmModalOperators && (
        <ConfirmationModal
          open={ConfirmOpenOperators}
          onClose={handleConfirmModalOperators}
          title="Delete "
          text="Are you sure you want to delete this operator ?"
          onButtonClick={handleConfirmModalOperators}
        />
      )}

      {/* Manage location Modal */}
      {handleAddMangeLocationopen && (
        <ManageLocation
          open={MangeLocationAddopen}
          setOpen={setMangeLocationAddopen}
          title={"Add Zone"}
          setRefreshTableZone={setRefreshTableZone}
          setPaginationModalZone={setPaginationModalZone}
          setZoneSearchName={setZoneSearchName}
          selectedZone={selectedZone}
          paginationModalZone={paginationModalZone}
        />
      )}
      {handleEditMangeLocationopen && (
        <ManageLocation
          open={MangeLocationEditopen}
          setOpen={setMangeLocationEditopen}
          title={"Edit Zone"}
          isEdit={true}
          setRefreshTableZone={setRefreshTableZone}
          setPaginationModalZone={setPaginationModalZone}
          setZoneSearchName={setZoneSearchName}
          selectedZone={selectedZone}
          paginationModalZone={paginationModalZone}
        />
      )}

      {/* Manage location Country Modal */}
      {handleAddMangeLocationCountryopen && (
        <ManageCountry
          open={MangeLocationCountryAddopen}
          setOpen={setMangeLocationCountryAddopen}
          title={"Add Country"}
        />
      )}
      {handleEditMangeLocationCountryopen && (
        <ManageCountry
          open={MangeLocationCountryEditopen}
          setOpen={setMangeLocationCountryEditopen}
          title={"Edit Country"}
        />
      )}

      {/* Manage location Modal  City*/}
      {handleAddMangeLocationCityopen && (
        <ManageCity
          open={MangeLocationCityAddopen}
          setOpen={setMangeLocationCityAddopen}
          title={"Add City"}
          CountryOptions={CountryOptions}
          // selectedCity={selectedCity}
          setPaginationModalCity={setPaginationModalCity}
          setRefreshTableCity={setRefreshTableCity}
          paginationModalCity={paginationModalCity}
          isEdit={false}
        />
      )}
      {handleEditMangeLocationCityopen && (
        <ManageCity
          open={MangeLocationCityEditopen}
          setOpen={setMangeLocationCityEditopen}
          title={"Edit City"}
          isEdit={true}
          selectedCity={selectedCity}
          CountryOptions={CountryOptions}
          setPaginationModalCity={setPaginationModalCity}
          setRefreshTableCity={setRefreshTableCity}
          paginationModalCity={paginationModalCity}
        />
      )}
      {/* Manage Criteria Modal */}
      {handleAddMangeCriteriaopen && (
        <ManageCriteria
          open={MangeCriteriaAddopen}
          setOpen={setMangeCriteriaAddopen}
          title={"Add Criteria"}
        />
      )}
      {handleEditMangeCriteriaopen && (
        <ManageCriteria
          open={MangeCriteriaEditopen}
          setOpen={setMangeCriteriaEditopen}
          title={"Edit Criteria"}
        />
      )}
      {/* Manage Criteria Type Modal */}
      {handleAddMangeCriteriaTypeopen && (
        <ManageCriteriaType
          open={MangeCriteriaTypeAddopen}
          setOpen={setMangeCriteriaTypeAddopen}
          title={"Add Criteria Type"}
        />
      )}
      {handleEditMangeCriteriaTypeopen && (
        <ManageCriteriaType
          open={MangeCriteriaTypeEditopen}
          setOpen={setMangeCriteriaTypeEditopen}
          title={"Edit Criteria Type"}
        />
      )}
      {/* Manage Criteria Category Modal */}
      {handleAddMangeCriteriaopen && (
        <ManageCriteriaCategory
          open={MangeCriteriaCategoryAddopen}
          setOpen={setMangeCriteriaCategoryAddopen}
          title={"Add Criteria Category"}
          selectedCategory={selectedCategory}
          setPaginationModalCategory={setPaginationModalCategory}
          setRefreshTableCategory={setRefreshTableCategory}
        />
      )}
      {handleEditMangeCriteriaCategoryopen && (
        <ManageCriteriaCategory
          open={MangeCriteriaCategoryEditopen}
          setOpen={setMangeCriteriaCategoryEditopen}
          title={"Edit Criteria Category"}
          selectedCategory={selectedCategory}
          isEdit={true}
          setPaginationModalCategory={setPaginationModalCategory}
          setRefreshTableCategory={setRefreshTableCategory}
        />
      )}
      {/* Manage Currencies Modal */}
      {handleAddMangeCurrenciesopen && (
        <ManageCurrencies
          open={MangeCurrenciesAddopen}
          setOpen={setMangeCurrenciesAddopen}
          title={"Add Currency"}
          // selectedCurrencies={selectedCurrencies}
          setPaginationModalCurrencies={setPaginationModalCurrencies}
          setRefreshTableCurrencies={setRefreshTableCurrencies}
          CountryOptions={CountryOptions}
          paginationModalCurrencies={paginationModalCurrencies}
        />
      )}
      {handleEditMangeCurrenciesopen && (
        <ManageCurrencies
          open={MangeCurrenciesEditopen}
          setOpen={setMangeCurrenciesEditopen}
          title={"Edit Currency"}
          selectedCurrencies={selectedCurrencies}
          setPaginationModalCurrencies={setPaginationModalCurrencies}
          setRefreshTableCurrencies={setRefreshTableCurrencies}
          isEdit={true}
          CountryOptions={CountryOptions}
          paginationModalCurrencies={paginationModalCurrencies}
        />
      )}

      {/* Manage Languages Modal */}
      {handleAddMangeLanguagesopen && (
        <ManageLanguages
          open={MangeLanguagesAddopen}
          setOpen={setMangeLanguagesAddopen}
          title={"Add Language"}
          // selectedLanguages={selectedLanguages}
          setPaginationModalLanguages={setPaginationModalLanguages}
          setRefreshTableLanguages={setRefreshTableLanguages}
          paginationModalLanguages={paginationModalLanguages}
        />
      )}
      {handleEditMangeLanguagesopen && (
        <ManageLanguages
          open={MangeLanguagesEditopen}
          setOpen={setMangeLanguagesEditopen}
          title={"Edit Language"}
          isEdit={true}
          selectedLanguages={selectedLanguages}
          setPaginationModalLanguages={setPaginationModalLanguages}
          setRefreshTableLanguages={setRefreshTableLanguages}
          paginationModalLanguages={paginationModalLanguages}
        />
      )}

      {/* Manage Operators Modal */}
      {handleAddMangeOperatorsopen && (
        <ManageOperators
          open={MangeOperatorsAddopen}
          setOpen={setMangeOperatorsAddopen}
          title={"Add Operator"}
        />
      )}
      {handleEditMangeOperatorsopen && (
        <ManageOperators
          open={MangeOperatorsEditopen}
          setOpen={setMangeOperatorsEditopen}
          title={"Edit Operator"}
        />
      )}
      {/* Swtich LocationZone Modal */}
      {isModalOpenSwitchLocationZone && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationZone}
          onClose={handleModalCloseSwitchLocationZone}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchZone}
        />
      )}
      {/* Swtich location Country Modal */}
      {isModalOpenSwitchLocationCountry && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationCountry}
          onClose={handleModalCloseSwitchLocationCountry}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCountry}
        />
      )}

      {/* Swtich location City Modal */}
      {isModalOpenSwitchLocationCity && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationCity}
          onClose={handleModalCloseSwitchLocationCity}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchLocationCity}
        />
      )}
      {/* {isModalOpenSwitchCriteriaCategory && (
        <ConfirmationModal
          open={isModalOpenSwitchCriteriaCategory}
          onClose={handleModalCloseSwitchLocationCity}
          title="Change Status"
          text="Are you sure you want to change this City Status?"
          onButtonClick={handleModalConfirmSwitchLocationCity}
        />
      )} */}
      {/* Swtich Criteria Modal */}
      {isModalOpenSwitchCriteria && (
        <ConfirmationModal
          open={isModalOpenSwitchCriteria}
          onClose={handleModalCloseSwitchCriteria}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCriteria}
        />
      )}
      {/* Swtich Criteria Type Modal */}
      {isModalOpenSwitchCriteriaType && (
        <ConfirmationModal
          open={isModalOpenSwitchCriteriaType}
          onClose={handleModalCloseSwitchCriteriaType}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCriteriaType}
        />
      )}
      {/* Swtich Criteria Category Modal */}
      {isModalOpenSwitchCategory && (
        <ConfirmationModal
          open={isModalOpenSwitchCategory}
          onClose={handleModalCloseSwitchCriteriaCategory}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCriteriaCategory}
        />
      )}
      {/* Swtich Currencies Modal */}
      {isModalOpenSwitchCurrencies && (
        <ConfirmationModal
          open={isModalOpenSwitchCurrencies}
          onClose={handleModalCloseSwitchCurrencies}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCurrencies}
        />
      )}
      {/* Swtich Languages Modal */}
      {isModalOpenSwitchLanguages && (
        <ConfirmationModal
          open={isModalOpenSwitchLanguages}
          onClose={handleModalCloseSwitchLanguages}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchLanguages}
        />
      )}
      {isModalOpenSwitchLanguagesTR && (
        <ConfirmationModal
          open={isModalOpenSwitchLanguagesTR}
          onClose={handleModalCloseSwitchLanguagesTR}
          title="Change Status"
          text="Are you sure you want to change this translation status?"
          onButtonClick={handleModalConfirmSwitchLanguagesTR}
        />
      )}
      {isModalOpenSwitchLanguagesRL && (
        <ConfirmationModal
          open={isModalOpenSwitchLanguagesRL}
          onClose={handleModalCloseSwitchLanguagesRL}
          title="Change Status"
          text="Are you sure you want to change this RTL status?"
          onButtonClick={handleModalConfirmSwitchLanguagesRL}
        />
      )}
      {/* Swtich Operators Modal */}
      {isModalOpenSwitchOperators && (
        <ConfirmationModal
          open={isModalOpenSwitchOperators}
          onClose={handleModalCloseSwitchOperators}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchOperators}
        />
      )}
      {/* Swtich Operators Exlusive Modal */}
      {isModalOpenSwitchOperatorsExlusive && (
        <ConfirmationModal
          open={isModalOpenSwitchOperatorsExlusive}
          onClose={handleModalCloseSwitchOperatorsExlusive}
          title="Change Status"
          text="Are you sure you want to change this Exlusive Status?"
          onButtonClick={handleModalConfirmSwitchOperatorsExlusive}
        />
      )}

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DataSeed;
