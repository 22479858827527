import { Alert, FormControlLabel, Grid, Snackbar, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import { ADD_COST_PLAN, GET_ALL_COUNTRIES_OPTIONS, GET_ALL_CURRENCIES, GET_ALL_OPERATORS_OPTIONS, MULTI_TENANCY_CONFIG } from "../../APIs";
import axiosInstance from "../../../axiosInstance";
const ManageCosts = ({ open, setOpen, title, onButtonClick, providerCategoryImportOptions, setOpenAlert, setAlertMessage, isEdit, CostData, selectedCost }) => {
  const [ProviderCategory, setProviderCategory] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [Country, setCountry] = useState("");
  const [currencies, setCurrencies] = useState("");
  const [currenciesOptions, setCurrenciesOptions] = useState(false);
  const [CountryOptions, setCountryOptions] = useState(false);
  const [OperatorOptions, setOperatorOptions] = useState(false);
  const [Operator, setOperator] = useState("");
  // const [alertMesssage, setAlertMessage] = useState("");
  // const [openAlert, setOpenAlert] = useState(false);


  useEffect(() => {
    getAllCountries();
  }, [ProviderCategory]);

  useEffect(() => {
    getAllOperatorName();
  }, [Country]);

  useEffect(() => {
    getAllCurrencies();
  }, []);

  const getAllCountries = () => {
    let service = JSON.parse(services);
    setCountryOptions(false)
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        console.log("getAllCountries", res?.data?.data?.countries);
        setCountryOptions(
          res?.data?.data?.countries.map((country) => ({
            label: country?.name,
            value: country?.recordGuid
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllOperatorName = () => {
    let newServices = JSON.parse(services);
    setOperatorOptions(false)
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryIso=${Country}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOperatorOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };

  const getAllCurrencies = () => {
    let newServices = JSON.parse(services);
    setCurrenciesOptions(false)
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["configuration"]
        }${GET_ALL_CURRENCIES}?SearchKeyword=`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCurrenciesOptions(
          res?.data?.data?.currencies.map((x) => ({
            label: x?.name,
            value: x?.code,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };

  useEffect(() => {
    console.log(selectedCost);
  }, [])

  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      "ServiceGuid": "2232496a-2702-4499-859a-82b98a672581",
      "ChannelGuid": "acaaa147-6836-4d60-97c3-3fb1db02fb63",
      "OperatorGuid": Operator,
      "CurrencyCode": currencies,
      "CountryCode": Country,
      "OperationTypeTag": "GENERAL",
      "ProviderCategoryGuid": ProviderCategory,
      "IsDefault": true,
      "Cost": Cost
    }
    axiosInstance.post(`${localStorage.getItem("baseUrl")}${newServices["billing"]}${ADD_COST_PLAN}`, data, MULTI_TENANCY_CONFIG)
      .then(({ data }) => {
        setOpen(false)
        setOpenAlert(true)
        setOperator('')
        setCurrencies('')
        setCountry('')
        setProviderCategory('')
        setCost('')
        setAlertMessage(data?.message)

      }).catch((error) => {
      })
  }

  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      "ServiceGuid": "2232496a-2702-4499-859a-82b98a672581",
      "ChannelGuid": "acaaa147-6836-4d60-97c3-3fb1db02fb63",
      "OperatorGuid": selectedCost?.operatorGuid,
      "CurrencyCode": selectedCost?.currencyCode,
      "CountryCode": selectedCost?.countryCode,
      "OperationTypeTag": "GENERAL",
      "ProviderCategoryGuid": selectedCost?.providerCategoryGuid,
      "IsDefault": true,
      "Cost": Cost,
      "CostPlanGuid": selectedCost?.recordGuid,
    }
    axiosInstance.put(`${localStorage.getItem("baseUrl")}${newServices["billing"]}${ADD_COST_PLAN}`, data, MULTI_TENANCY_CONFIG)
      .then(({ data }) => {
        setOpen(false)
        setOpenAlert(true)
        setAlertMessage(data?.message)
      }).catch((error) => {
      })
  }

  useEffect(() => {
    if(isEdit){
      setCost(selectedCost?.cost)
    }
  }, [selectedCost])

  const [Cost, setCost] = useState(isEdit ? selectedCost?.cost : "");
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      firstActionBtnFct={isEdit ? submitEdit : submitAdd}
      // secondActionBtnName={"Cancel"}
      content={
        <>
        {!isEdit && <>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectFormNew
                value={ProviderCategory}
                setValue={setProviderCategory}
                options={providerCategoryImportOptions}
                placeholder={"ProviderCategory"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectFormNew
                value={Country}
                setValue={setCountry}
                options={CountryOptions}
                placeholder={"Country"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectFormNew
                value={currencies}
                setValue={setCurrencies}
                options={currenciesOptions}
                placeholder={"Currency"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectFormNew
                value={Operator}
                setValue={setOperator}
                options={OperatorOptions}
                placeholder={"Operator"}
              />
            </Grid>
          </Grid>
          </>
      }
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Cost"}
                value={Cost}
                setValue={setCost}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageCosts;
