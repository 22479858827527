import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DnsIcon from "@mui/icons-material/Dns";
import KeyIcon from "@mui/icons-material/Key";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import montymobileLogo from "../Assets/monty-mobile-logo.svg";
import { MULTI_TENANCY_CONFIG } from "../Service-Manager/APIs";
import axiosInstance from "../axiosInstance";
import { KEYCLOAK_MEMBERS_LOGIN } from "./Api";
import { loginConfig } from "./LoginConfig";
import "./SignIn.scss";
import axios from "axios";
const SignInOmni = (props) => {
  const t = props.t;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [environment, setEnvironment] = useState("");
  const [projectNamesOptions, setProjectNamesOptions] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenant, setTenant] = useState("");
  const [dbOptions, setDbOptions] = useState([]);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [loginOmni, setloginOmni] = useState(true);
  const [flip, setflip] = useState(false);

  useEffect(() => {
    localStorage.clear();
    document.body.classList.add("hide-scroll");
    localStorage.setItem("token", "");
    const { projects } = loginConfig || {};
    const mappedProjects =
      projects?.map((x) => ({
        value: x.name,
        text: x.name,
      })) || [];
    setProjectNamesOptions(mappedProjects);
    return () => {
      // 👇️ removing classes from body element
      // when the component unmounts
      document.body.classList.remove("hide-scroll");
    };
  }, []);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        !loginOmni &&
        !flip &&
        username &&
        password &&
        tenant &&
        projectName &&
        environment
      ) {
        keyCloakLogin();
      } else if ((loginOmni || flip) && username && password) {
        keyCloakLogin();
      }
      // You can perform any action you want when the Enter key is pressed
    }
  };
  useEffect(() => {
    // Check if the app has been opened before
    const hasBeenOpened = sessionStorage.getItem("appOpened");

    if (!hasBeenOpened) {
      // If not opened before, set the flag and force a reload
      sessionStorage.setItem("appOpened", "true");
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    username,
    password,
    environment,
    projectName,
    tenant,
    password,
    username,
    loginOmni,
    flip,
  ]); // The empty dependency array ensures that this effect runs only once on mount

  useEffect(() => {
    localStorage.setItem("projectName", "Omni-Channel");
    setProjectName("Omni-Channel");
    localStorage.setItem("tenant", process.env.REACT_APP_BASE_TENANT);

    localStorage.setItem(
      "services",
      JSON.stringify({
        members: "/member",
        configuration: "/configuration",
        "data-seed": "/data-seed",
        "routing": "/routing",
        billing: "/billing",
        apiKey: "/apikey",
        catalog: "/catalog",
        member: "/member",
      })
    );
    localStorage.setItem(
      "baseService",
      JSON.stringify([
        {
          id: "3",
          name: "channels-management",
          endpointName: "channels-management",
          endpoint: "/channels-management",
          pages: [
            {
              pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
              name: "Channels",
              icon: "SettingsApplications",
              path: "channels-management",
              parent_page_name: [null],
              endpointName: "routing",
              endpoint: "/routing",
              hasChildren: true,
              children: [
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f26f5",
                  name: "Dashboard",
                  icon: "key",
                  path: "channels-dashboard",
                  parentPageName: "Channels",
                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9k"],
                },
                // {
                //   pageId: "c8ab6209-5464-4f95-b2e9-c111405f2614",
                //   name: "Routing",
                //   icon: "settingsSuggest",
                //   path: "routes-services",
                //   parentPageName: "Channels",
                //   parent_page_name: [
                //     "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                //   ],
                // },

                // {
                //   pageId: "c8ab6209-5464-4f95-b2e9-c111405f26f3",
                //   name: "SMS",
                //   icon: "Sms",
                //   path: "sms-one-way-management",
                //   parentPageName: "Channels",

                //   parent_page_name: [
                //     "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                //   ],
                // },
                // {
                //   pageId: "c8ab6209-5464-4f95-b2e9-c111405f2612",
                //   name: "SMS Two Way",
                //   icon: "Chat",
                //   path: "sms-two-way-management",
                //   parentPageName: "Channels",

                //   parent_page_name: [
                //     "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                //   ],
                // },
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f2677",
                  name: "Push",
                  icon: "PushPin",
                  path: "push-management",
                  parentPageName: "Channels",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9k"],
                },
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f2687",
                  name: "Whatsapp",
                  icon: "WhatsApp",
                  path: "whatsapp-management",
                  parentPageName: "Channels",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9k"],
                },
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f2655",
                  name: "Viber",
                  icon: "Vibration",
                  path: "viber-management",
                  parentPageName: "Channels",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9k"],
                },
              ],
            },
          ],
        },

        // {
        //   pageId: "50b076bc-4e5f-4df4-b480-10aadbebe425",
        //   name: "Channels",
        //   endpoint: "/channels-management",
        //   endpointName: "channels-management",
        //   icon: "key",
        //   path: "channels-management",
        //   parent_page_name: [null],
        // },

        {
          id: "3",
          name: "providers-management",
          endpointName: "providers-management",
          endpoint: "/providers-management",
          pages: [
            {
              pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
              name: "Providers",
              icon: "SettingsApplications",
              path: "providers-mg-dashboard",
              parent_page_name: [null],
              endpointName: "routing",
              endpoint: "/routing",
              hasChildren: true,
              children: [
                {
                  pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                  name: "New Provider",
                  icon: "PersonAdd",
                  path: "new-provider",
                  parentPageName: "Providers",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9e"],
                },
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                  name: "Dashboard",
                  icon: "insights",
                  path: "providers-mg-dashboard",
                  parentPageName: "Providers",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9e"],
                },
                {
                  pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                  name: "Providers",
                  icon: "groups",
                  path: "providers-management",
                  parentPageName: "Providers",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9e"],
                },
              ],
            },
          ],
        },

        {
          id: "3",
          name: "resellers-management",
          endpointName: "resellers-management",
          endpoint: "/resellers-management",
          pages: [
            {
              pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
              name: "Resellers",
              icon: "ManageAccounts",
              path: "resellers-mg-dashboard",
              parent_page_name: [null],
              endpointName: "resellers-management",
              endpoint: "/resellers-management",
              hasChildren: true,
              children: [
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                  name: "Dashboard",
                  icon: "insights",
                  path: "resellers-mg-dashboard",
                  parentPageName: "Resellers",

                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9q"],
                },
                {
                  pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                  name: "Resellers",
                  icon: "store",
                  path: "resellers-management",
                  parentPageName: "Resellers",
                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9q"],
                },
              ],
            },
          ],
        },

        {
          id: "3",
          name: "clients-management",
          endpointName: "catalog",
          endpoint: "/catalog",
          pages: [
            {
              pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
              name: "Clients",
              icon: "ManageAccounts",
              path: "clients-mg-dashboard",
              parent_page_name: [null],
              endpointName: "clients-management",
              endpoint: "/clients-management",
              hasChildren: true,
              children: [
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                  name: "Dashboard",
                  icon: "insights",
                  path: "clients-mg-dashboard",
                  parentPageName: "Clients",
                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9q"],
                },
                {
                  pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                  name: "Clients",
                  icon: "groups",
                  path: "clients-management",
                  parentPageName: "Clients",
                  parent_page_name: ["9b56914b-1de3-43dc-b9b1-dc65e2aecd9q"],
                },
              ],
            },
          ],
        },

        {
          id: "2",
          endpointName: "resellers-config",
          endpoint: "/resellers-config",

          // pages: [
          //   {
          //     pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
          //     name: "Resellers Config",
          //     icon: "engineering",
          //     path: "resellers-config",
          //     parent_page_name: [null],
          //     hasChildren: false,
          //   },
          // ],
        },
        {
          id: "2",
          endpointName: "clients-config",
          endpoint: "/clients-config",

          pages: [
            {
              pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
              name: "Clients Config",
              icon: "engineering",
              path: "clients-config",
              parent_page_name: [null],
              hasChildren: false,
            },
          ],
        },
        {
          id: "2",
          endpointName: "routing",
          endpoint: "/routing",

          pages: [
            {
              pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
              name: "Routing",
              icon: "engineering",
              path: "routing",
              parent_page_name: [null],
              hasChildren: false,
            },
          ],
        },

        // {
        //   id: "2",
        //   endpointName: "routing",
        //   endpoint: "/routing",

        //   pages: [
        //     {
        //       pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
        //       name: "Routing",
        //       icon: "engineering",
        //       path: "routing",
        //       parent_page_name: [null],
        //       hasChildren: false,
        //     },
        //   ],
        // },
        {
          id: "3",
          name: "billing",
          endpointName: "billing",
          endpoint: "/billing",
          pages: [
            {
              pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
              name: "Billing",
              icon: "MonetizationOn",
              path: "billing-configuration",
              parent_page_name: [null],
              endpointName: "billing",
              endpoint: "/billing",
              hasChildren: true,
              children: [
                {
                  pageId: "c8ab6209-5464-4f95-b2e9-c111405f76ff",
                  name: "Configuration",
                  icon: "currencyExchange",
                  path: "billing-configuration",
                  parentPageName: "Billing",
                  parent_page_name: [
                    "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                  ],
                },
                {
                  pageId: "9a752c2f-2b00-4e16-9c84-4980c403e618",
                  name: "Pricing",
                  icon: "priceChange",
                  path: "billing-pricing",
                  parentPageName: "Billing",
                  parent_page_name: [
                    "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                  ],
                },
              ],
            },
          ],
        },

        // {
        //   id: "1",
        //   endpointName: "members",
        //   endpoint: "/member",
        //   pages: [
        //     {
        //       pageId: "50b076bc-4e5f-4df4-b480-10aadbebe420",
        //       name: "Api Key Service",
        //       endpoint: "/apikey",
        //       endpointName: "apiKey",
        //       icon: "key",
        //       path: "api-key-service",
        //       parent_page_name: [null],
        //     },
        //     {
        //       pageId: "b023cb43-9663-4dfc-960e-e3b4b3a2de8d",
        //       name: "Catalog",
        //       icon: "book",
        //       endpoint: "/catalog",
        //       endpointName: "catalog",
        //       path: "catalog-management",
        //       parent_page_name: [null],
        //     },
        //   ],
        // },

        {
          id: "1",
          endpointName: "members",
          endpoint: "/member",
          pages: [
            {
              pageId: "0ba00fc2-3f8f-4e95-a1e3-e81eab5fd071",
              name: "Users",
              endpoint: "/member",
              endpointName: "member",
              icon: "groups",
              path: "users-management",
              parent_page_name: [null],
            },
          ],
        },
        {
          id: "2",
          endpointName: "configuration",
          endpoint: "/configuration",

          pages: [
            {
              pageId: "71210e8f-f226-4ef8-bc08-d7e793915eb8",
              name: "Configuration",
              icon: "settings",
              path: "configuration-management",
              parent_page_name: [null],
            },
          ],
        },

        {
          id: "6",
          name: "dataSeed",
          endpointName: "data-seed",
          endpoint: "/data-seed",

          pages: [
            {
              pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c4",
              name: "Data Seed",
              icon: "emojiObjects",
              path: "data-seed",
              parent_page_name: [null],
              hasChildren: false,
            },
          ],
        },
      ])
    );
    localStorage.setItem("baseUrl", process.env.REACT_APP_BASE_API_IDENTITY);
    localStorage.setItem(
      "config",
      JSON.stringify({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          Authorization: "bearer ",
          "Content-Type": "application/json",
          Tenant: process.env.REACT_APP_BASE_TENANT,
        },
      })
    );
    localStorage.setItem("envName", " Live");
  }, []);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getEnvironment = (projectName) => {
    let objectOfProject = loginConfig?.projects?.find((x) => {
      return x.name === projectName;
    });

    if (objectOfProject) {
      setDbOptions(
        objectOfProject?.enviroments?.map((x) => ({
          text: x.name,
          value: x.name,
        }))
      );

      // Set tenant options based on the selected environment
      setTenantOptions([]);
      if (
        objectOfProject?.enviroments &&
        objectOfProject?.enviroments.length > 0
      ) {
        const selectedEnvironment = objectOfProject.enviroments.find(
          (env) => env.name === environment
        );

        if (selectedEnvironment) {
          setTenantOptions(
            selectedEnvironment.tenants.map((tenant) => ({
              text: tenant,
              value: tenant,
            }))
          );
        }
      }
    }

    if (!projectName || projectName.length === 0) {
      setDbOptions([]);
      setTenantOptions([]);
    }
  };

  const getServices = (enviroment) => {
    let objectOfProject = loginConfig?.projects?.find(
      (x) => x.name === projectName
    );
    let objectOfEnv = {};
    objectOfEnv = objectOfProject?.enviroments?.find(
      (x) => x.name === enviroment
    );
    let transformedObject = {};
    objectOfEnv?.services?.forEach((item) => {
      transformedObject[item.endpointName] = item.endpoint;
    });
    objectOfEnv?.services?.forEach((endpoint) => {
      const endpointName = endpoint.endpointName;
      const pages = endpoint.pages;

      pages.forEach((page) => {
        if (page.endpoint && page.endpointName) {
          transformedObject[page.endpointName] = page.endpoint;
        }
      });
    });
    objectOfEnv?.services?.forEach((item) => {
      transformedObject[item.endpointName] = item.endpoint;
    });

    localStorage.setItem("services", JSON.stringify(transformedObject));
    localStorage.setItem("baseService", JSON.stringify(objectOfEnv.services));
    localStorage.setItem("baseUrl", objectOfEnv.baseUrl);
    localStorage.setItem(
      "config",
      JSON.stringify({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          Authorization: "bearer ",
          "Content-Type": "application/json",
          Tenant: tenant,
        },
      })
    );

    // dispatch(setServices(transformedObject));
    // dispatch(setBaseService(objectOfEnv.services));
    // dispatch(setBaseUrl(objectOfEnv.baseUrl));
    // dispatch(
    //   setConfig({
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //       "Access-Control-Allow-Headers":
    //         "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //       Authorization: "bearer ",
    //       "Content-Type": "application/json",
    //       Tenant: tenant,
    //     },
    //   })
    // );
    // this.setState({
    //   tenantOptions: objectOfEnv?.tenants.map((x) => ({ text: x, value: x })),
    // });

    // objectOfEnv?.services?.forEach((endpoint) => {
    //   const endpointName = endpoint.endpointName;
    //   const pages = endpoint.pages;

    //   pages.forEach((page) => {
    //     if (page.endpoint && page.endpointName) {
    //       transformedObject[page.endpointName] = page.endpoint;
    //     }
    //   });
    // });
    // objectOfEnv?.services?.forEach((item) => {
    //   transformedObject[item.endpointName] = item.endpoint;
    // });
    // localStorage.setItem("services", JSON.stringify(transformedObject));
    // localStorage.setItem("baseService", JSON.stringify(objectOfEnv.services));
    // // localStorage.setItem("loginConfig", objectOfEnv.tenant);
    // localStorage.setItem("baseUrl", objectOfEnv.baseUrl);

    // localStorage.setItem(
    //   "config",
    //   JSON.stringify({
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //       "Access-Control-Allow-Headers":
    //         "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //       Authorization: "bearer ",
    //       "Content-Type": "application/json",
    //       Tenant: this.state.tenant[0],
    //     },
    //   })
    // );
  };
  const keyCloakLogin = () => {
    let newServices = JSON.parse(localStorage.getItem("services"));
    console.timeLog("newServices", newServices);
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setLoading(true);

    const body = {
      Username: username,
      Password: password,
    };

    axios
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${KEYCLOAK_MEMBERS_LOGIN}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        // navigate(
        //   JSON.parse(localStorage.getItem("baseService")) &&
        //     JSON.parse(localStorage.getItem("baseService"))[0]?.pages[0]?.path,
        //   { replace: true }
        // );
        // navigate("/api-key-service",{ replace: true });

        localStorage.setItem("token", res?.data?.data?.accessToken);
        localStorage.setItem("withoutBack", "null");
        let roleNameStatic = "support";
        localStorage.setItem("refreshToken", res?.data?.data?.refreshToken);
        localStorage.setItem("roleName", roleNameStatic);
        if (roleNameStatic === "support") {
          localStorage.setItem(
            "baseService",
            JSON.stringify([
              {
                id: "3",
                name: "billing",
                endpointName: "billing",
                endpoint: "/billing",
                pages: [
                  {
                    pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                    name: "Billing",
                    icon: "MonetizationOn",
                    path: "billing-configuration",
                    parent_page_name: [null],
                    endpointName: "billing",
                    endpoint: "/billing",
                    hasChildren: true,
                    children: [
                      {
                        pageId: "c8ab6209-5464-4f95-b2e9-c111405f76ff",
                        name: "Configuration",
                        icon: "currencyExchange",
                        path: "billing-configuration",
                        parentPageName: "Billing",
                        parent_page_name: [
                          "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                        ],
                      },
                      {
                        pageId: "9a752c2f-2b00-4e16-9c84-4980c403e618",
                        name: "Pricing",
                        icon: "priceChange",
                        path: "billing-pricing",
                        parentPageName: "Billing",
                        parent_page_name: [
                          "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                        ],
                      },
                    ],
                  },
                ],
              },
            ])
          );
          navigate("/billing-service", { replace: true });
        } else {
          navigate("/landing-page", { replace: true });
        }

        // dispatch(setPages(loginConfig));
        // dispatch(setEnvirement(environment));
        // dispatch(setNameOfProject(projectName));
        // dispatch(setTenantOfProject(tenant));

        // signIn(res?.data?.data);
        // redirectBasedOnPath();
      })
      .catch((err) => {
        if (err?.response?.data?.Exception) {
          setErrMessage(err?.response?.data?.Exception);
        } else if (err?.response?.data?.message) {
          setErrMessage(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className="sign-in">
      <Box className="center">
        <img src={montymobileLogo} alt={"logo"} className="logo-style" />
      </Box>
      <Container>
        <Card className="original-card center">
          <Grid container>
            {/* <Grid item xs={6}>
              <Typography variant="h5" align="center">
                {loginOmni ? "Sign In to Service Manager Portal?" : ""}
              </Typography>
              <Grid container className="center">
                {loginOmni && (
                  <Button
                    variant="contained"
                    className="sign-card-btn"
                    onClick={() => {
                      setloginOmni(false);
                      setusername("");
                      setpassword("");
                      setErrMessage("");
                    }}
                  >
                    {t("SIGN IN")}
                  </Button>
                )}
              </Grid>
            </Grid> */}
            <Grid item xs={11}>
              <Typography variant="h5" align="center">
                {!loginOmni ? "Sign In to Service Manager Portal" : ""}
              </Typography>
              <Grid container className="center">
                {!loginOmni && (
                  <Button
                    variant="contained"
                    className="contained"
                    onClick={() => {
                      setloginOmni(true);
                      setusername("");
                      setpassword("");
                      setErrMessage("");
                    }}
                  >
                    {t("SIGN IN")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Container>

      <Box marginTop={10}>
        <Paper
          elevation={1}
          className={
            !loginOmni && !flip
              ? "paper flip-paper-main"
              : loginOmni
              ? "transition-paper-omni"
              : !loginOmni && flip
              ? "flip-paper paper"
              : ""
          }
        >
          <Box className={!flip ? "content-box" : "content-box-flip"}>
            <Box style={{ display: "grid" }} className="center" paddingTop={10}>
              <Typography variant="h5" align="left" paddingBottom={1}>
                {"Sign In to Service Manager Portal"}
              </Typography>
              <Typography
                variant="h6"
                align="left"
                style={{ textAlign: "center" }}
                paddingBottom={loginOmni ? 5 : 2}
              >
                {"Omni-Channel"}
              </Typography>
            </Box>
            <Box
              className="center"
              paddingTop={0}
              paddingBottom={0}
              color={"red"}
            >
              <Typography variant="h7" align="center">
                {errMessage ? errMessage : ""}
              </Typography>
            </Box>
            <Box className="center">
              <TextField
                className="login-inputs"
                id="input-with-icon-textfield"
                placeholder={t("Username")}
                value={username}
                onChange={(e) => setusername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Box>

            <Box className="center" marginTop={3}>
              <TextField
                className="login-inputs"
                id="input-with-icon-textfield"
                placeholder={t("Password")}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpenIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        style={{ marginRight: "10px" }}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Box>
            {!loginOmni && !flip && (
              <>
                <Box className="center">
                  <Grid container>
                    <Select
                      value={projectName}
                      onChange={(e) => {
                        setProjectName(e.target.value);
                        localStorage.setItem("projectName", e.target.value);
                      }}
                      displayEmpty
                      variant="standard"
                      className="login-select custom-select"
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountTreeIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="" disabled>
                        {t("Select Project Name")}
                      </MenuItem>
                      {projectNamesOptions.map((project, index) => (
                        <MenuItem key={index} value={project.value}>
                          {project.text}
                        </MenuItem>
                      ))}
                    </Select>

                    <Select
                      value={environment}
                      onChange={(e) => {
                        setEnvironment(e.target.value);
                        getServices(e.target.value);
                        localStorage.setItem("envName", e.target.value);
                      }}
                      displayEmpty
                      disabled={!projectName}
                      variant="standard"
                      className="login-select-small custom-select"
                      onOpen={() => getEnvironment(projectName)}
                      startAdornment={
                        <InputAdornment position="start">
                          <DnsIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="" disabled>
                        {t("Select Environment")}
                      </MenuItem>
                      {dbOptions.map((project, index) => (
                        <MenuItem key={index} value={project?.value}>
                          {project?.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Box>

                <Box className="center">
                  <Select
                    value={tenant}
                    onChange={(e) => {
                      setTenant(`${e.target.value}`);
                      localStorage.setItem("tenant", e.target.value);
                      localStorage.setItem(
                        "config",
                        JSON.stringify({
                          headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                              "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                            "Access-Control-Allow-Headers":
                              "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                            Authorization: "bearer ",
                            "Content-Type": "application/json",
                            Tenant: e.target.value,
                          },
                        })
                      );
                    }}
                    displayEmpty
                    variant="standard"
                    className="login-select-big custom-select"
                    onOpen={() => getEnvironment(projectName)}
                    disabled={!projectName}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return "Select Tenant";
                      } else {
                        return `${selected?.substring(0, 25)}...`;
                      }
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="" disabled>
                      {t("Select Tenant")}
                    </MenuItem>
                    {tenantOptions.map((project, index) => (
                      <MenuItem key={index} value={project?.value}>
                        {project?.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
            <Box paddingBottom={5}>
              <Button
                onClick={keyCloakLogin}
                variant="contained"
                className="signin-btn"
                disabled={
                  !loginOmni && !flip
                    ? !username ||
                      !password ||
                      !tenant ||
                      !projectName ||
                      !environment
                    : !username || !password
                }
              >
                {t("SIGN IN")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default withTranslation("translations")(SignInOmni);
