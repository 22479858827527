import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  import DescriptionInput from "../../../Components/TextFields/DescriptionInput";
  
  const ManageBundleDetails = ({ open, setOpen, title, onButtonClick }) => {
    const [Bundle, setBundle] =useState("");
    const [BundleOptions, setBundleOptions] = useState(["Bundle 1","Bundle 2","Bundle 3","Bundle 4"]);

    const [Language, setLanguage] =useState("");
    const [LanguageOptions, setLanguageOptions] = useState(["Arabic","Englsih","French"]);
    const [Name, setName] = useState("");
    const [Description, setDescription] = useState("");
    const [switchState, setSwitchState] = useState(false);
    const [switchSub, setSwitchSub] = useState(false);
  
    const handleSwitchChange = () => {
      setSwitchState(!switchState);
    };
    const handleSwitchSubChange = () => {
      setSwitchSub(!switchSub);
    };
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
             <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={Name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Bundle} setValue={setBundle}  options={BundleOptions} placeholder={"Bundle"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Language} setValue={setLanguage}  options={LanguageOptions} placeholder={"Language"}/>
              </Grid>
            </Grid>


            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <DescriptionInput
                  placeholder={"Description"}
                  value={Description}
                  setValue={setDescription}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageBundleDetails;
  