import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import { ADD_RATES, MULTI_TENANCY_CONFIG } from "../../APIs";

const ManageMissingRates = ({
  serviceGuid,
  channelGuid,
  open,
  setOpen,
  title,
  selectedMissingRate,
  ClientCategoryOptions,
  setClientCategoryOptions,
  refreshTableMissingRates,
  setRefreshTableMissingRates,
  isEdit,
  setAlertMessage,
  setOpenAlert,
  setPaginationModelMissingRates,
  paginationModelMissingRates,
}) => {
  const [ClientCategory, setClientCategory] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [Country, setCountry] = useState("");
  const [CountryOptions, setCountryOptions] = useState(false);
  const [Operator, setOperator] = useState("");
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [Rate, setRate] = useState("");
  const [currencies, setCurrencies] = useState("");
  const [currenciesOptions, setCurrenciesOptions] = useState(false);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setRate(selectedMissingRate?.rate);
      setClientCategory(selectedMissingRate?.clientCategoryName);
      setCountry(selectedMissingRate?.country);
      setCurrencies(selectedMissingRate?.currency);
      setOperator(selectedMissingRate?.operator);
    }
  }, [selectedMissingRate]);

  //   useEffect(() => {
  //     setClientCategory(ClientCategoryOptions[0]?.value);
  //   }, []);
  //   useEffect(() => {
  //     getAllCountries();
  //   }, []);
  //   useEffect(() => {
  //     if (Country !== "" ) {
  //       getAllOperatorName();
  //     }
  //   }, [Country]);
  const handleSaveChanges = () => {
    let newServices = JSON.parse(services);

    const newData = {
      ServiceGuid: serviceGuid,
      ChannelGuid: channelGuid,
      OperatorGuid: selectedMissingRate?.operatorGuid,
      CurrencyCode: selectedMissingRate?.currencyCode,
      CountryGuid: selectedMissingRate?.countryGuid,
      // CountryCode: selectedMissingRate?.countryCode,
      OperationTypeTag: selectedMissingRate?.operationTypeTag,
      isDefault: false,
      Rate: Rate,
      //   RatePlanGuid: selectedMissingRate?.recordGuid,
      ClientCategoryGuid: selectedMissingRate?.clientCategoryGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_RATES}`,
        newData,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpen(false);
        setOpenAlert(true);
        setAlertMessage(res?.data?.message);
        setOperator("");
        setCurrencies("");
        setCountry("");
        setClientCategory("");
        setRate("");
        setRefreshTableMissingRates(true);
        setPaginationModelMissingRates({
          pageSize: paginationModelMissingRates?.pageSize,
          page: 0,
        });

        // Notification.success("Api Updated Successfully");
        // this.props.toggle();
        // this.props.tableRefresh();
      })
      .catch((err) => {
        // if (err === "error: 401") {
        //   this.handleSaveChanges();
        // }
        // if (err?.response?.data?.Data) {
        //   Object.keys(err?.response?.data?.Data).forEach((item) => {
        //     Notification.error(err?.response?.data?.Data[item][0]);
        //   });
        // } else {
        //   Notification.error(
        //     err?.response?.data?.message ?? "An Error occurred updating Client"
        //   );
        // }
        setOperator("");
        setCurrencies("");
        setCountry("");
        setClientCategory("");
        setRate("");
        setRefreshTableMissingRates(true);
      })
      .finally(() => {
        // this.setState({ loading: false });
      });
  };

  useEffect(() => {
    if (!isEdit) {
      setOperator("");
      setCurrencies("");
      setCountry("");
      setClientCategory("");
      setRate("");
    }
  }, [open]);

  //   const getAllCountries = () => {
  //     let service = JSON.parse(services);
  //     // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
  //     MULTI_TENANCY_CONFIG.headers.Authorization =
  //       "Bearer " + localStorage.getItem("token");
  //     MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //     axiosInstance
  //       .get(
  //         `${localStorage.getItem("baseUrl")}${
  //           service["configuration"]
  //         }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
  //         MULTI_TENANCY_CONFIG
  //       )
  //       .then((res) => {
  //         console.log("getAllCountries", res?.data?.data?.countries);
  //         setCountryOptions(
  //           res?.data?.data?.countries.map((country) => ({
  //             label: country?.name,
  //             value: country?.recordGuid,
  //           }))
  //         );
  //       })
  //       .catch((err) => {
  //         if (err === "error: 401") {
  //           getAllCountries();
  //         }
  //         // toast.error(
  //         //   err?.response?.data?.message ??
  //         //     "An Error Ocurred Retrieving Countries"
  //         // );
  //       })
  //       .finally(() => {
  //         // setState({ loading: false, resetPagination: false });
  //       });
  //   };

  //   const getAllOperatorName = () => {
  //     MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //     setIsLoadingOperator(true);
  //     setOperatorOptions([]);
  //     let newServices = JSON.parse(services);
  //     setOperator("");
  //     axiosInstance
  //       .get(
  //         `${localStorage.getItem("baseUrl")}${
  //           newServices["configuration"]
  //         }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${Country}`,
  //         MULTI_TENANCY_CONFIG
  //       )
  //       .then((res) => {
  //         setIsLoadingOperator(false);

  //         setOperatorOptions(
  //           res?.data?.data?.items.map((x) => ({
  //             label: x?.name,
  //             value: x?.recordGuid,
  //           }))
  //         );
  //       })
  //       .catch((err) => {
  //         setIsLoadingOperator(false);

  //         if (err === "error: 401") {
  //           getAllOperatorName();
  //         }
  //         // toast.error(
  //         //   err?.response?.data?.message ??
  //         //     "An Error Ocurred Retrieving Countries"
  //         // );
  //       })
  //       .finally(() => {
  //         setIsLoadingOperator(false);

  //         // this.setState({ loading: false, resetPagination: false });
  //       });
  //   };
  //   const getAllCurrencies = () => {
  //     let newServices = JSON.parse(services);

  //     MULTI_TENANCY_CONFIG.headers.Authorization =
  //       "Bearer " + localStorage.getItem("token");
  //     MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //     axiosInstance
  //       .get(
  //         `${localStorage.getItem("baseUrl")}${
  //           newServices["configuration"]
  //         }${GET_ALL_CURRENCIES}?SearchKeyword=`,
  //         MULTI_TENANCY_CONFIG
  //       )
  //       .then((res) => {
  //         setCurrenciesOptions(
  //           res?.data?.data?.currencies.map((x) => ({
  //             label: x?.name,
  //             value: x?.code,
  //           }))
  //         );
  //       })
  //       .catch((err) => {
  //         if (err === "error: 401") {
  //           getAllOperatorName();
  //         }
  //         // toast.error(
  //         //   err?.response?.data?.message ??
  //         //     "An Error Ocurred Retrieving Countries"
  //         // );
  //       })
  //       .finally(() => {
  //         // this.setState({ loading: false, resetPagination: false });
  //       });
  //   };

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      firstActionBtnFct={handleSaveChanges}
      disableFirstBtn={
        title !== "Edit" &&
        (ClientCategory === "" ||
          Country === "" ||
          Operator === "" ||
          Rate === "" ||
          currencies === "")
          ? true
          : title !== "Add" && Rate === ""
          ? true
          : false
      }
      // secondActionBtnName={"Cancel"}
      content={
        <>
          {title === "Edit" ? (
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Rate"}
                  value={Rate}
                  setValue={setRate}
                  positiveNumberFloat
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    value={ClientCategory}
                    setValue={setClientCategory}
                    // options={ClientCategoryOptions}
                    placeholder={"Client Category"}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    value={Country}
                    setValue={setCountry}
                    // options={CountryOptions}
                    placeholder={"Country"}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    value={currencies}
                    setValue={setCurrencies}
                    // options={currenciesOptions}
                    placeholder={"Currency"}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    value={Operator}
                    setValue={setOperator}
                    // options={OperatorOptions}
                    placeholder={"Operator"}
                    // isLoading={isLoadingOperator}
                    // wait={"select a country"}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    placeholder={"Rate"}
                    value={Rate}
                    setValue={setRate}
                    // type={"number"}
                    positiveNumberFloat
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      }
    />
  );
};

export default ManageMissingRates;
