import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Table from "../../../Components/DynamicTable/Table";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import { DynamicIcon } from "../../../Utils/functions";
import ManageSmtp from "./ManageSmtp";
const tableDataLocation = [
  {
    name: "mimimimi",
    lastName: "1212121",
    userName: "naim.jaber401111",
    email: "sourceProvider@gmail.com",
  },
  {
    name: "admin",
    lastName: "user",
    userName: "admin_user",
    email: "fitor19756@qianhost.com",
  },
];
let columnsLocation = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
  },
  {
    field: "userName",
    headerName: "Username",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
];

const tableDataCurrencies = [
  {
    name: "Dollars",
  },
  {
    name: "LBP",
  },
];
let columnsCurrencies = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
];

const tableDataMenuPages = [
  {
    name: "Channels",
  },
  {
    name: "Providers",
  },
  {
    name: "Resellers",
  },
];
let columnsMenuPages = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
];
const tableDataCountries = [
  {
    name: "Canada",
  },
  {
    name: "Lebanon",
  },
  {
    name: "Brazil",
  },
];
let columnsLanuages = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
];

const tableDataLanuages = [
  {
    name: "English",
  },
  {
    name: "Spanish",
  },
  {
    name: "Arabic",
  },
];
let columnsCountries = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
];
export default function ManageTenant() {
  const [openSection, setOpenSection] = useState("Details");
  const [status, setStatus] = useState(false);
  const [openSmtp, setOpenSmtp] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 3,
  });
  const [baseUrl, setBaseUrl] = useState(
    "https://mm-omni-api-software-dev.montylocal.net"
  );
  const [authority, setAuthority] = useState(
    "https://keycloak-softwaredev.montyloacal.net/realms/selfcare"
  );
  const [tokenEndPoint, setTokenEndPoint] = useState(
    "https://keycloak-softwaredev.montyloacal.net/realms/selfcare/protocol/openid-connect/token"
  );

  const handleClickStatus = () => {
    setStatus(!status);
  };
  const handleOpenSmtp = () => {
    setOpenSmtp(!openSmtp);
  };
  const dataset = [
    {
      name: "Details",
      icon: "Info",
      content: (
        <div style={{ width: "80%" }}>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <TextFieldForm
                value={baseUrl}
                setValue={setBaseUrl}
                placeholder="Base Url: "
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldForm
                value={authority}
                setValue={setAuthority}
                placeholder="Authority: "
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldForm
                value={tokenEndPoint}
                setValue={setTokenEndPoint}
                placeholder="Token End Point: "
              />
            </Grid>

            <Grid item xs={12}>
              <Grid spacing={1} container>
                <Grid item>
                  <Button className="contained">View Keys</Button>
                </Grid>

                <Grid item>
                  <Button className="contained">View Clients</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ),
    },
    {
      name: "Menu Pages",
      icon: "Menu",
      content: (
        <div style={{ width: "95%", marginTop: "-20px" }}>
          <Table
            rowCountState={tableDataLocation?.totalCount}
            data={tableDataLocation}
            // isLoading={isLoading}
            columns={columnsLocation}
            pageIndex={
              tableDataLocation?.pageIndex ? tableDataLocation?.pageIndex : 0
            }
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            stopMinHeight
            disableHeaderColor
            disableActions
          />
        </div>
        // <Table
        //   rowCountState={tableDataLocation?.totalCount}
        //   data={tableDataLocation}
        //   // isLoading={isLoading}
        //   columns={columnsLocation}
        //   pageIndex={
        //     tableDataLocation?.pageIndex ? tableDataLocation?.pageIndex : 0
        //   }
        // />
      ),
    },
    {
      name: "Users",
      icon: "Groups",
      content: (
        <div style={{ width: "95%", marginTop: "-20px" }}>
          <Table
            rowCountState={tableDataMenuPages?.totalCount}
            data={tableDataMenuPages}
            // isLoading={isLoading}
            columns={columnsMenuPages}
            pageIndex={
              tableDataMenuPages?.pageIndex ? tableDataMenuPages?.pageIndex : 0
            }
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            stopMinHeight
            disableHeaderColor
            disableActions
          />
        </div>
      ),
    },
    {
      name: "Countries",
      icon: "Public",
      content: (
        <div style={{ width: "95%", marginTop: "-20px" }}>
          <Table
            rowCountState={tableDataCountries?.totalCount}
            data={tableDataCountries}
            // isLoading={isLoading}
            columns={columnsCountries}
            pageIndex={
              tableDataCountries?.pageIndex ? tableDataCountries?.pageIndex : 0
            }
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            stopMinHeight
            disableHeaderColor
            disableActions
          />
        </div>
      ),
    },
    {
      name: "Languages",
      icon: "Language",
      content: (
        <div style={{ width: "95%", marginTop: "-20px" }}>
          <Table
            rowCountState={tableDataLanuages?.totalCount}
            data={tableDataLanuages}
            // isLoading={isLoading}
            columns={columnsCountries}
            pageIndex={
              tableDataLanuages?.pageIndex ? tableDataLanuages?.pageIndex : 0
            }
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            stopMinHeight
            disableHeaderColor
            disableActions
          />
        </div>
      ),
    },
    {
      name: "Currencies",
      icon: "AttachMoney",

      content: (
        <div style={{ width: "95%", marginTop: "-20px" }}>
          <Table
            rowCountState={tableDataCurrencies?.totalCount}
            data={tableDataCurrencies}
            // isLoading={isLoading}
            columns={columnsCurrencies}
            pageIndex={
              tableDataCurrencies?.pageIndex
                ? tableDataCurrencies?.pageIndex
                : 0
            }
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            stopMinHeight
            disableHeaderColor
            disableActions
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Manage Tenant</Typography>
            <Typography className="breadcrumbactiveBtn">
              {localStorage.getItem("projectName")}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid xs={12} item>
          <Card
            style={{
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            <Grid container>
              <Grid lg={6} md={12} item>
                <Typography className="label-card">
                  {localStorage.getItem("projectName")}
                </Typography>
                <div style={{ margin: "5px 0px", display: "flex" }}>
                  <Typography className="sub-label-card">
                    Description :
                  </Typography>
                  <Typography className="sub-label-card-2">
                    Description
                  </Typography>
                </div>
                <div style={{ margin: "5px 0px", display: "flex" }}>
                  <Typography className="sub-label-card">
                    Tenant Key :
                  </Typography>{" "}
                  <Typography className="sub-label-card-2">
                    {localStorage.getItem("tenant")}
                  </Typography>
                </div>
                <div style={{ margin: "5px 0px", display: "flex" }}>
                  <Typography className="sub-label-card">Status : </Typography>
                  <div style={{ margin: "-8px 0px 0px 0px" }}>
                    <Switch
                      // style={{ margin: "-8px 0px 0px 0px" }}
                      checked={status}
                      onChange={handleClickStatus}
                    />
                  </div>
                </div>
              </Grid>
              <Grid lg={6} md={12} item className="end">
                <div style={{ display: "flex" }}>
                  <SettingsIcon
                    style={{ fontSize: "50px", color: "#C61035" }}
                  />
                  <div style={{ margin: "auto", display: "flex" }}>
                    <Typography className="sub-label-card-2">
                      SMTP Configuration
                    </Typography>

                    <AddIcon
                      onClick={handleOpenSmtp}
                      style={{
                        fontSize: "25px",
                        color: "#C61035",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          marginTop: "20px",
        }}
      >
        {dataset.map((datum, i) => {
          const { name, icon, content } = datum;
          const isOpen = name === openSection;
          return (
            <div
              key={name}
              style={{
                height: "100%",
                //   backgroundColor: isOpen ? "white" : "lightgray",
                backgroundColor: "white",
                marginRight: "10px",
                borderRadius: "10px",

                //   border: `1px solid ${isOpen ? "white" : "black"}`,
                flex: 1,
                flexGrow: isOpen ? 1 : 0,
                // transition: "all 0.5s ease",

                //my changes
                padding: 18,
                flexBasis: "1.2rem",
                cursor: !isOpen ? "pointer" : "auto",
                position: "relative",
              }}
              onClick={!isOpen ? () => setOpenSection(datum.name) : null}
            >
              <div
                style={{
                  // transition: "all 0.5s ease",
                  marginBottom: "5px",
                  //my changes
                  transform: `rotate(${isOpen ? "0" : "90"}deg) 
                translateX(${isOpen ? "0" : "50"}%)`,
                  whiteSpace: "nowrap",
                  width: isOpen ? "100%" : "1rem",
                  position: "absolute",
                  top: isOpen ? "1rem" : "178px",
                  left: isOpen ? "1rem" : "",
                  fontWeight: "bold",
                }}
              >
                {console.log("isOpenisOpen", openSection)}
                <Grid container>
                  <Grid xs={12} item>
                    <div
                      // style={{
                      //   color: localStorage.getItem('primary') || '#c41035',
                      //   fontWeight: "bold",
                      //   margin: "5px",
                      //   transform: isOpen ? "scale(1)" : "scale(-1)",
                      //   display: "flex",
                      // }}
                      className={
                        isOpen
                          ? "manage-client-transform-open"
                          : "manage-client-transform"
                      }
                    >
                      <div
                        style={{
                          margin: isOpen
                            ? "0px"
                            : name === "Details"
                            ? "0px -25px 0px 25px"
                            : name === "Menu Pages"
                            ? "0px 10px 0px -10px"
                            : name === "Users"
                            ? "0px -41px 0px 41px"
                            : name === "Contries"
                            ? "0px -22px 0px 22px"
                            : name === "Languages"
                            ? "0px -5px 0px 5px"
                            : name === "Currencies"
                            ? "0px -9px 0px 9px"
                            : "",
                        }}
                      >
                        <DynamicIcon
                          iconColor={
                            localStorage.getItem("primary") || "#c41035"
                          }
                          iconName={icon}
                          style={{
                            fontSize: "20px",
                            margin: "0px 6px -4px 6px",
                          }}
                        />
                        <span>{name}</span>
                      </div>
                      <div style={{ textAlign: "end", width: "100%" }}>
                        {isOpen ? (
                          <DynamicIcon
                            iconColor={"#00B900"}
                            iconName={"lockOpen"}
                            style={{
                              fontSize: "20px",
                              margin: "0px 30px -4px 6px",
                            }}
                          />
                        ) : (
                          <DynamicIcon
                            iconColor={"gray"}
                            iconName={"lock"}
                            style={{
                              fontSize: "20px",
                              margin: "0px 6px -4px 0px",
                              width:
                                name == "Details"
                                  ? "9em"
                                  : name === "Menu Pages"
                                  ? "5em"
                                  : name === "Users"
                                  ? "10em"
                                  : name === "Contries"
                                  ? "8em"
                                  : name === "Languages"
                                  ? "6em"
                                  : "6.3em",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {isOpen && content}
                  </Grid>
                </Grid>
              </div>
            </div>
          );
        })}
        {openSmtp && (
          <ManageSmtp
            open={openSmtp}
            setOpen={setOpenSmtp}
            title={"Add SMTP"}
          />
        )}
      </div>
    </>
  );
}
