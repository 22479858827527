import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  
  const ManageOtp = ({ open, setOpen, title, onButtonClick }) => {
    const [Clients, setClients] =useState("");
    const [ClientsOptions, setClientsOptions] = useState(["Seconds","Minuts","Hours"]);
    const [MinimumLength, setMinimumLength] = useState("");
    const [MaximumLength, setMaximumLength] = useState("");
    const [KeyLength, setKeyLength] = useState("");
    const [TimeToLive, setTimeToLive] = useState("");
    return ( 
      
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                type={'number'}
                  placeholder={"Minimum Length"}
                  value={MinimumLength}
                  setValue={setMinimumLength}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                   type={'number'}
                  placeholder={"Maximum Length"}
                  value={MaximumLength}
                  setValue={setMaximumLength}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                   type={'number'}
                  placeholder={"Key Length"}
                  value={KeyLength}
                  setValue={setKeyLength}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid className="dialog-grid-spacing" item xs={12} sm={6}>
            <TextFieldForm
                  placeholder={"Time To Live"}
                  value={TimeToLive}
                  setValue={setTimeToLive}
                />
              </Grid>
              <Grid className="dialog-grid-spacing" item xs={12} sm={6}>
                <SelectForm value={Clients} setValue={setClients}  options={ClientsOptions} placeholder={"Time"}/>
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageOtp;
  