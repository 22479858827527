import Add from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  CardContent,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import ManageMNC from "./ManageMNC";
import ManageOperators from "./ManageOperators";
import ManagePrefixes from "./ManagePrefixes";
import HorizantalTabs from "../../../Components/Tabs/HorizantalTabs";
import {
  CHANGE_STATUS_MAIN,
  CHANGE_STATUS_MNC,
  CHANGE_STATUS_OPERATORS,
  CHANGE_STATUS_PREFIXES,
  DELETE_MNC,
  DELETE_OPERATORS,
  DELETE_PREFIXES,
  DOWNLOAD_PREFIX_SAMPLE,
  EXPORT_MNC,
  EXPORT_OPERATORS,
  EXPORT_PREFIXES,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_MNC,
  GET_ALL_OPERATORS,
  GET_ALL_OPERATORS_OPTIONS,
  GET_ALL_PREFIXES,
  IMPORT_PREFIX_FILE,
  MULTI_TENANCY_CONFIG,
  MULTI_TENANCY_CONFIG_SUBMIT_FILE,
} from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import { downloadCSV } from "../../../Utils/functions";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import { MyDropzone } from "../../../Components/Buttons/ImportButton";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";
const Configuration = ({ activeTab }) => {
  let services = localStorage.getItem("services");
  const [isLoading, setIsLoading] = useState(true);
  const [refreshTablehMNC, setRefreshTableMNC] = useState(false);
  const [refreshTablePrefixes, setRefreshTablePrefixes] = useState(false);
  const [refreshTableOperators, setRefreshTableOperators] = useState(false);
  const [tableDataMNC, setTableDataMNC] = useState({ pageIndex: 1, data: [] });
  const [searchPrefixes, setSearchPrefixes] = useState("");
  const [searchOperators, setSearchOperators] = useState("");
  const [resetPagination, setResetPagination] = useState(false);
  const [CountryOptions, setCountryOptions] = useState(false);
  const [CountryOptionsRecordGuid, setCountryOptionsRecordGuid] = useState([]);
  const [prefixNumber, setPrefixNumber] = useState("");
  const [openImportFile, setopenImportFile] = useState(false);
  const [file, setFile] = useState("");

  const [selectedMNC, setSelectedMNC] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [mncOperators, setMncOperators] = useState("");

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [countryFilter, setCountryFilter] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [openAdvancedSearchGroup, setOpenAdvancedSearchGroup] = useState(
    windowWidth > 1000 ? true : false
  );
  const [openAdvancedSearchPrefixes, setOpenAdvancedSearchPrefixes] = useState(
    windowWidth > 1000 ? true : false
  );
  const [openAdvancedSearchOperators, setOpenAdvancedSearchOperators] =
    useState(windowWidth > 1000 ? true : false);

  const [OperatorSearch, setOperatorSearch] = useState("");
  const [CountrySearch, setCountrySearch] = useState("");
  const [mncSearch, setMncSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");

  const [OperatorOptions, setOperatorOptions] = useState([]);

  const [tableDataPrefixes, setTableDataPrefixes] = useState({
    pageIndex: 1,
    data: [],
  });
  const [tableDataOperators, setTableDataOperators] = useState({
    pageIndex: 1,
    data: [],
  });

  const [activeBtn, setActiveBtn] = useState("MNC");
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  // MNC
  const [isModalOpenSwitchMNC, setModalOpenSwitchMNC] = useState(false);
  const [isCheckedSwitchMNC, setCheckedSwitchMNC] = useState(false);
  const [isModalOpenSwitchPrefix, setModalOpenSwitchPrefix] = useState(false);
  const [isCheckedSwitchPrefix, setCheckedSwitchPrefix] = useState(false);
  const [switchStatesMNC, setswitchStatesMNC] = useState({});

  const [paginationModelMNC, setPaginationModelMNC] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModelPrefixes, setPaginationModelPrefixes] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModelOperators, setPaginationModelOperators] = useState({
    page: 0,
    pageSize: 10,
  });
  const handleModalCloseSwitchMNC = () => {
    setModalOpenSwitchMNC(false);
  };

  const handleResetAllMnc = () => {
    setCountrySearch("");
    setMncSearch("");
    setOperatorSearch("");
    setRefreshTableMNC(true);
  };
  const handleResetAllPrefixes = () => {
    setSearchPrefixes("");
    setCountrySearch("");
    setOperatorSearch("");
    setRefreshTablePrefixes(true);
  };
  const handleResetAllOperators = () => {
    setMncOperators("");
    setSearchOperators("");
    setCountrySearch("");
    setRefreshTableOperators(true);
  };
  useEffect(() => {
    setFile("");
  }, [openImportFile]);
  useEffect(() => {
    if (CountrySearch === "") {
      setOperatorSearch("");
    }
  }, [CountrySearch]);

  const exportMNC = () => {
    setIsLoading(true);
    setRefreshTableMNC(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_MNC}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `MNC.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportMNC();
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };

  // const exportMNC = () => {
  //   const columns = [
  //     { title: "MNC", field: "name" },
  //     {
  //       title: "Tag",
  //       field: "tag",
  //     },
  //   ];
  //   setIsLoading(true);
  //   setRefreshTableMNC(false);
  //   let newServices = JSON.parse(services);
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   setIsLoading(true);
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["configuration"]
  //       }${GET_ALL_MNC}?PageIndex=0&PageSize=1000`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setIsLoading(false);
  //       setMessageData({ message: "Downloaded Successfully !", type: "success" });
  //       setOpenMessageAlert(true);
  //       const data = res?.data?.data?.items;
  //       downloadCSV(
  //         columns,
  //         data?.map((item) => ({
  //           ...item,
  //         })),
  //         "MNC"
  //       );
  //     })
  //     .catch((err) => {
  //       // toast.error("Something Went Wrong");
  //       setIsLoading(false);
  //     })
  //     .finally(() => {
  //       // this.setState({ loading: false });
  //       setIsLoading(false);
  //     });
  // };

  const exportPrefixes = () => {
    setIsLoading(true);
    setRefreshTablePrefixes(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_PREFIXES}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Prefixes.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };
  const exportOperators = () => {
    setIsLoading(true);
    setRefreshTableOperators(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_OPERATORS}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Operators.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportOperators();
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };
  const handleModalConfirmSwitchMNC = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchMNC,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_MNC}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableMNC(true);
        setModalOpenSwitchMNC(false);
        // setRefreshTableMNC(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleSwitchChangeSwitchMNC(isCheckedSwitchMNC);
        }
        setMessageData({
          message: err?.response?.data?.message,
          type: "error",
        });
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //
  // MNC main
  const [isModalOpenSwitchMNCmain, setModalOpenSwitchMNCmain] = useState(false);
  const [isCheckedSwitchMNCmain, setCheckedSwitchMNCmain] = useState(false);
  const [switchStatesMNCmain, setswitchStatesMNCmain] = useState({});
  const handleSwitchChangeSwitchMNCmain = (rowId) => {
    setModalOpenSwitchMNCmain(true);
    setCheckedSwitchMNCmain(rowId);
  };
  const submitFile = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers.Tenant =
      localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers = {
      ...MULTI_TENANCY_CONFIG_SUBMIT_FILE.headers,
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("File", file);
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${IMPORT_PREFIX_FILE}`,
        formData,
        MULTI_TENANCY_CONFIG_SUBMIT_FILE
      )
      .then(({ data }) => {
        if (data?.status != "failed" && data?.status != "error") {
          // setOpenImportSuccessAlert(true);
          // setImportSuccessMessage(data?.message);
          // setopenImportFile(false);
          // setClientCategoryImport("");
          setopenImportFile(false);
          setMessageData({
            message: data?.message||"Successfully Imported File !",
            type: "success",
          });
          setOpenMessageAlert(true);
          setRefreshTablePrefixes(true);
        }
      })
      .catch((err) => {
        setMessageData({
          message: err?.response?.data?.message, ///rmi17
          type: "error",
        });
        setOpenMessageAlert(true);
        setopenImportFile(false);
      });
  };

  const handleModalCloseSwitchMNCmain = () => {
    setModalOpenSwitchMNCmain(false);
  };
  const handleModalConfirmSwitchMNCmain = () => {
    {
      setIsLoading(true);
      let newServices = JSON.parse(services);
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
      setIsLoading(true);
      let body = {
        RecordGuid: isCheckedSwitchMNCmain,
      };
      axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            newServices["configuration"]
          }${CHANGE_STATUS_MAIN}`,
          body,
          MULTI_TENANCY_CONFIG
        )
        .then((res) => {
          setRefreshTableMNC(true);
          setModalOpenSwitchMNCmain(false);
          // setRefreshTableMNC(false);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("SAfasfasfaswss", err);
          if (err === "error: 401") {
            setIsLoading(true);
            handleSwitchChangeSwitchMNCmain(isCheckedSwitchMNCmain);
          }
          setMessageData({
            message: err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  //

  // Prefixes
  const [isModalOpenSwitchPrefixes, setModalOpenSwitchPrefixes] =
    useState(false);
  const [isCheckedSwitchPrefixes, setCheckedSwitchPrefixes] = useState(false);
  const [switchStatesPrefixes, setSwitchStatesPrefixes] = useState({});
  const handleSwitchChangeSwitchPrefixes = (rowId) => {
    setModalOpenSwitchPrefixes(true);
    setCheckedSwitchPrefixes(rowId);
  };
  const handleSwitchChangeSwitchMNC = (rowId) => {
    setModalOpenSwitchMNC(true);
    setCheckedSwitchMNC(rowId);
  };

  const handleModalCloseSwitchPrefixes = () => {
    setModalOpenSwitchPrefixes(false);
  };
  const handleModalConfirmSwitchPrefixes = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchPrefixes,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_PREFIXES}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTablePrefixes(true);
        setModalOpenSwitchPrefixes(false);
        // setRefreshTablePrefixes(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleSwitchChangeSwitchPrefixes(isCheckedSwitchPrefixes);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //
  // Operators
  const [isModalOpenSwitchOperators, setModalOpenSwitchOperators] =
    useState(false);
  const [isCheckedSwitchOperators, setCheckedSwitchOperators] = useState(false);
  const [switchStatesOperators, setSwitchStatesOperators] = useState({});
  const handleSwitchChangeSwitchOperators = (rowId) => {
    setModalOpenSwitchOperators(true);
    setCheckedSwitchOperators(rowId);
  };

  const handleModalCloseSwitchOperators = () => {
    setModalOpenSwitchOperators(false);
  };
  const handleModalConfirmSwitchOperators = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchOperators,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_OPERATORS}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableOperators(true);
        setModalOpenSwitchOperators(false);
        // setRefreshTableOperators(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleSwitchChangeSwitchOperators(isCheckedSwitchOperators);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //
  // Operators exlusive
  const [
    isModalOpenSwitchOperatorsexlusive,
    setModalOpenSwitchOperatorsexlusive,
  ] = useState(false);
  const [isCheckedSwitchOperatorsexlusive, setCheckedSwitchOperatorsexlusive] =
    useState(false);
  const [switchStatesOperatorsexlusive, setSwitchStatesOperatorsexlusive] =
    useState({});
  const handleSwitchChangeSwitchOperatorsexlusive = (rowId) => {
    setModalOpenSwitchOperatorsexlusive(true);
    setCheckedSwitchOperatorsexlusive(rowId);
  };

  const handleModalCloseSwitchOperatorsexlusive = () => {
    setModalOpenSwitchOperatorsexlusive(false);
  };
  const handleModalConfirmSwitchOperatorsexlusive = () => {
    setModalOpenSwitchOperatorsexlusive(false);
    // setCheckedSwitchOperators(!isCheckedSwitchOperators);
    setSwitchStatesOperatorsexlusive((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchOperatorsexlusive]:
        !prevStates[isCheckedSwitchOperatorsexlusive],
    }));
  };
  //
  const [Tag, setTag] = useState("");

  const handleChangeTag = (event) => {
    setTag(event.target.value);
  };

  let columnsMNC = [
    {
      field: "mcc",
      headerName: "MCC",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mnc",
      headerName: "MNC",
      flex: 1,
      minWidth: 100,
    },

    {
      headerName: "Country",
      field: "country",
      valueGetter: (params) => {
        return params?.row?.operator?.country?.name || ""; // Get the nested country name
      },
      flex: 1,
      minWidth: 100,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString()); // Sorting strings
      },
    },
    {
      headerName: "Operator",
      field: "operator",
      minWidth: 100,
      valueGetter: (params) => {
        return params?.row?.operator?.name || ""; // Get the operator name
      },
      renderCell: (params) => {
        return <>{params?.row?.operator?.name}</>;
      },
      flex: 1,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString()); // Sorting strings
      },
    },
    {
      field: "main",
      headerName: "Main",
      minWidth: 100,

      renderCell: (params) => {
        const rowId = params.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params.row?.isMain}
                  onChange={() => {
                    handleSwitchChangeSwitchMNCmain(rowId);
                    setSelectedMNC(params.row);
                  }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",

      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params.row?.isActive}
                  onChange={() => {
                    handleSwitchChangeSwitchMNC(rowId);
                    setSelectedMNC(params.row);
                  }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: 100,
    },
  ];

  let columnsPrefixes = [
    {
      field: "prefixNumber",
      headerName: "Prefix Number",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Country",
      field: "country",
      valueGetter: (params) => {
        return params?.row?.operator?.country?.name || ""; // Get the country name
      },
      flex: 1,
      minWidth: 150,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString()); // Sorting strings
      },
    },
    {
      headerName: "Operator",
      field: "operator",
      minWidth: 150,
      valueGetter: (params) => {
        return params?.row?.operator?.name || ""; // Get the operator name
      },
      renderCell: (params) => {
        return <>{params?.row?.operator?.name}</>;
      },
      flex: 1,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString()); // Sorting strings
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params.row?.isActive}
                  onChange={() => {
                    handleSwitchChangeSwitchPrefixes(rowId);
                    setSelectedPrefix(params.row);
                  }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: 150,
    },
  ];

  let columnsOper = [
    {
      field: "mcCsString",
      headerName: "MCC",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mnCsString",
      headerName: "MNC",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params?.row?.country?.name || ""; // Extract the country name for sorting
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString()); // Sorting strings
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   renderCell: (params) => {
    //     const rowId = params.row?.recordGuid;
    //     return (
    //       <Box direction="row" spacing={2}>
    //         <FormControlLabel
    //           control={
    //             <Switch
    //               checked={params.row?.isActive}
    //               onChange={() => {
    //                 handleSwitchChangeSwitchOperators(rowId);
    //                 setSelectedOperator(params.row);
    //               }}
    //             />
    //           }
    //         />
    //       </Box>
    //     );
    //   },
    //   flex: 1,
    // },
  ];

  const tabs = [
    {
      label: "MNC",
    },
    {
      label: "Prefixes",
    },
    {
      label: "Operators",
    },
  ];

  // Delete Modal MNC functions
  const [ConfirmOpenMNC, setConfirmOpenMNC] = useState(false);
  const [modalContentMNC, setModalContentMNC] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalMNC = (data, title, text, onButtonClick) => {
    setModalContentMNC({ title, text, onButtonClick });
    setConfirmOpenMNC(true);
    setSelectedMNC(data);

    console.log("data", data);
  };

  const handleConfirmModalMNC = () => {
    setConfirmOpenMNC(false);
  };
  const handleDeleteMNC = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${DELETE_MNC}?RecordGuid=${selectedMNC?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableMNC(true);
        setConfirmOpenMNC(false);
        // setRefreshTableMNC(false);
        setIsLoading(false);
        setMessageData({ message: "Successfully Deleted !", type: "success" });
        setOpenMessageAlert(true);
        setPaginationModelMNC({
          page: 0,
          pageSize: paginationModelMNC?.pageSize
            ? paginationModelMNC?.pageSize
            : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleDeleteMNC(isCheckedSwitchMNC);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDeletePrefixes = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${DELETE_PREFIXES}?RecordGuid=${selectedPrefix?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTablePrefixes(true);
        setConfirmOpenPrefixes(false);
        // setRefreshTablePrefixes(false);
        setIsLoading(false);
        setMessageData({ message: "Successfully Deleted !", type: "success" });
        setOpenMessageAlert(true);
        setPaginationModelPrefixes({
          page: 0,
          pageSize: paginationModelPrefixes?.pageSize
            ? paginationModelPrefixes?.pageSize
            : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleDeletePrefixes(isCheckedSwitchPrefixes);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDeleteOperators = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${DELETE_OPERATORS}?RecordGuid=${selectedOperator?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableOperators(true);
        setConfirmOpenOperators(false);
        // setRefreshTableOperators(false);
        setIsLoading(false);
        setMessageData({ message: "Successfully Deleted !", type: "success" });
        setOpenMessageAlert(true);
        setPaginationModelOperators({
          page: 0,
          pageSize: paginationModelOperators?.pageSize
            ? paginationModelOperators?.pageSize
            : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleDeleteOperators(isCheckedSwitchOperators);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //

  // Delete Modal Prefixes functions
  const [ConfirmOpenPrefixes, setConfirmOpenPrefixes] = useState(false);
  const [modalContentPrefixes, setModalContentPrefixes] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalPrefixes = (data, title, text, onButtonClick) => {
    setModalContentPrefixes({ title, text, onButtonClick });
    setConfirmOpenPrefixes(true);
    setSelectedPrefix(data);
  };

  const handleConfirmModalPrefixes = () => {
    setConfirmOpenPrefixes(false);
  };

  //
  // Delete Modal Operators functions
  const [ConfirmOpenOperators, setConfirmOpenOperators] = useState(false);
  const [modalContentOperators, setModalContentOperators] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalOperators = (data, title, text, onButtonClick) => {
    setModalContentOperators({ title, text, onButtonClick });
    setConfirmOpenOperators(true);
    setSelectedOperator(data);
  };

  const handleConfirmModalOperators = () => {
    setConfirmOpenOperators(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const [MangeMNCAddopen, setMangeMNCAddopen] = useState(false);
  const [MangeMNCEditopen, setMangeMNCEditopen] = useState(false);

  const handleAddMangeMNCopen = () => {
    setMangeMNCAddopen(true);
  };

  const handleAddMangeMNCclose = () => {
    setMangeMNCAddopen(false);
  };
  const handleEditMangeMNCopen = (data) => {
    setSelectedMNC(data);
    setMangeMNCEditopen(true);
  };

  const handleEditMangeMNCclose = () => {
    setMangeMNCEditopen(false);
  };

  // Manage Prefixes
  const [MangePrefixesAddopen, setMangePrefixesAddopen] = useState(false);
  const [MangePrefixesEditopen, setMangePrefixesEditopen] = useState(false);

  const handleAddMangePrefixesopen = () => {
    setMangePrefixesAddopen(true);
  };

  const handleAddMangePrefixesclose = () => {
    setMangePrefixesAddopen(false);
  };
  const handleEditMangePrefixesopen = (data) => {
    setMangePrefixesEditopen(true);
    setSelectedPrefix(data);
  };

  const handleEditMangePrefixesclose = () => {
    setMangePrefixesEditopen(false);
  };
  const getAllCountries = () => {
    setCountryOptions(false);
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptions(
          res?.data?.data?.countries.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryOptionsRecordGuid(
          res?.data?.data?.countries.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryFilter(res.data.data.countries[0].recordGuid);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  // Manage Operators
  const [MangeOperatorsAddopen, setMangeOperatorsAddopen] = useState(false);
  const [MangeOperatorsEditopen, setMangeOperatorsEditopen] = useState(false);

  const handleAddMangeOperatorsopen = () => {
    setMangeOperatorsAddopen(true);
  };

  const handleAddMangeOperatorsclose = () => {
    setMangeOperatorsAddopen(false);
  };
  const handleEditMangeOperatorsopen = (data) => {
    setMangeOperatorsEditopen(true);
    setSelectedOperator(data);
  };
  const handleEditMangeOperatorsclose = () => {
    setMangeOperatorsEditopen(false);
  };
  const tableQueryExecuterMNC = () => {
    setIsLoading(true);
    setRefreshTableMNC(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_MNC}?PageIndex=${
          resetPagination ? 1 : paginationModelMNC?.page + 1
        }&PageSize=${paginationModelMNC?.pageSize}${
          mncSearch ? `&mnc=${mncSearch}` : ""
        }${CountrySearch ? `&CountryGuid=${CountrySearch}` : ""}${
          OperatorSearch ? `&OperatorGuid=${OperatorSearch}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataMNC({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterMNC();
        }
        setTableDataMNC({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterPrefixes = () => {
    setIsLoading(true);
    setRefreshTablePrefixes(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_PREFIXES}?PageIndex=${
          resetPagination ? 1 : paginationModelPrefixes?.page + 1
        }&PageSize=${paginationModelPrefixes?.pageSize}${
          searchPrefixes ? `&PrefixNumber=${searchPrefixes}` : ""
        }${CountrySearch ? `&CountryGuid=${CountrySearch}` : ""}${
          OperatorSearch ? `&OperatorGuid=${OperatorSearch}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataPrefixes({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelPrefixes({
        //   ...paginationModelPrefixes,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterPrefixes();
        }
        setTableDataPrefixes({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterOperators = () => {
    setIsLoading(true);
    setRefreshTableOperators(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS}?PageIndex=${
          resetPagination ? 1 : paginationModelOperators?.page + 1
        }&PageSize=${paginationModelOperators?.pageSize}${
          searchOperators ? `&Name=${searchOperators}` : ""
        }${CountrySearch ? `&CountryGuid=${CountrySearch}` : ""}${
          mncOperators ? `&MNC=${mncOperators}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataOperators({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelOperators({
        //   ...paginationModelOperators,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterOperators();
        }
        setTableDataOperators({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCountries();
  }, []);
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);
  useEffect(() => {
    if (
      activeBtn === "MNC" &&
      (refreshTablehMNC || mncSearch !== "" || paginationModelMNC)
    ) {
      if (!paginationModelMNC) {
        setPaginationModelMNC({
          page: 0,
          pageSize: paginationModelMNC?.pageSize
            ? paginationModelMNC?.pageSize
            : 10,
        });
      }

      tableQueryExecuterMNC();
    }
    if (
      activeBtn === "Prefixes" &&
      (refreshTablePrefixes || searchPrefixes !== "" || paginationModelPrefixes)
    ) {
      if (!paginationModelPrefixes) {
        setPaginationModelPrefixes({
          page: 0,
          pageSize: paginationModelPrefixes?.pageSize
            ? paginationModelPrefixes?.pageSize
            : 10,
        });
      }

      tableQueryExecuterPrefixes();
    }
    // ) {
    //   setPaginationModelPrefixes({
    //     page: 0,
    //     pageSize: paginationModelPrefixes?.pageSize
    //       ? paginationModelPrefixes?.pageSize
    //       : 10,
    //   });
    //   tableQueryExecuterPrefixes();
    // }
    if (
      activeBtn === "Operators" &&
      (refreshTableOperators ||
        searchOperators !== "" ||
        paginationModelOperators)
    ) {
      if (!paginationModelOperators) {
        setPaginationModelOperators({
          page: 0,
          pageSize: paginationModelOperators?.pageSize
            ? paginationModelOperators?.pageSize
            : 10,
        });
      }

      tableQueryExecuterOperators();
    }
    //  {
    //   setPaginationModelPrefixes({
    //     page: 0,
    //     pageSize: paginationModelOperators?.pageSize
    //       ? paginationModelOperators?.pageSize
    //       : 10,
    //   });
    //   tableQueryExecuterOperators();
    // }
  }, [
    refreshTablehMNC,
    refreshTableOperators,
    refreshTablePrefixes,

    paginationModelMNC,
    paginationModelPrefixes,
    paginationModelOperators,
    // countryFilter,
  ]);
  useEffect(() => {
    if (activeBtn === "MNC") {
      setPaginationModelMNC({
        page: 0,
        pageSize: 10,
      });
      setMncSearch("");
    }
    if (activeBtn === "Prefixes") {
      setPaginationModelPrefixes({
        page: 0,
        pageSize: 10,
      });
      setSearchPrefixes("");
    }
    if (activeBtn === "Operators") {
      setPaginationModelOperators({
        page: 0,
        pageSize: 10,
      });
      setSearchOperators("");
    }
    setMncSearch("");
    setOperatorSearch("");
    setCountrySearch("");
    setPrefixNumber("");
    setMncOperators("");
  }, [activeBtn]);
  const handleOpenAdvancedSearchGroup = () => {
    setOpenAdvancedSearchGroup(!openAdvancedSearchGroup);
  };
  const handleOpenAdvancedSearchPrefixes = () => {
    setOpenAdvancedSearchPrefixes(!openAdvancedSearchPrefixes);
  };
  const handleOpenAdvancedSearchOperators = () => {
    setOpenAdvancedSearchOperators(!openAdvancedSearchOperators);
  };
  useEffect(() => {
    if (CountrySearch !== "") {
      setOperatorOptions([]);
      getAllOperatorName();
    } else {
      setOperatorSearch("");
    }
  }, [CountrySearch]);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  const getAllOperatorName = () => {
    setIsLoadingOperator(true);

    let newServices = JSON.parse(services);
    {
      setOperatorSearch("");
    }
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${CountrySearch}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoadingOperator(false);

          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };
  return (
    <div className="billing">
      {console.log("countryFilter", countryFilter)}
      <Grid container>
        <Grid item xs={6} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Configuration</Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          // sx={{ marginTop: windowWidth < 800 && "30px" }}
          item
          xs={6}
          md={6}
          className="end"
        >
          {activeBtn === "MNC" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeMNCopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add MNC</Typography>
            </Button>
          )}
          {activeBtn === "Prefixes" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangePrefixesopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Prefix</Typography>
            </Button>
          )}
          {activeBtn === "Operators" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeOperatorsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Operator</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      {windowWidth < 1000 && (
        <Grid
          style={{ marginLeft: "0px", marginBottom: "-25px" }}
          container
          marginLeft={2}
          marginTop={5}
        >
          <HorizontalTabsSubtabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />
        </Grid>
      )}
      {console.log("windowWidthwindowWidth", windowWidth)}
      {windowWidth > 1000 ? (
        <Grid container className="Top-spacing div-table">
          <Grid
            item
            xs={windowWidth < 1800 ? 1.7 : 1.3}
            className="vertical-grid"
          >
            <VerticalTabs
              activeBtn={activeBtn}
              tabs={tabs}
              setActiveBtn={setActiveBtn}
            />
          </Grid>
          <Grid xs={windowWidth < 1800 ? 10.3 : 10.7}>
            {activeBtn === "MNC" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataMNC?.totalCount}
                  data={tableDataMNC?.data}
                  isLoading={isLoading}
                  columns={columnsMNC}
                  pageIndex={
                    tableDataMNC?.pageIndex ? tableDataMNC?.pageIndex : 0
                  }
                  setPaginationModel={setPaginationModelMNC}
                  paginationModel={paginationModelMNC}
                  enableDelete={true}
                  enableEdit={true}
                  handleResetAll={handleResetAllMnc}
                  // enableAddBtn={true}
                  // addBtnName={"Zone"}
                  // addFunction={handleAddMangeLocationopen}
                  deleteFunction={OpenConfirmModalMNC}
                  editFunction={handleEditMangeMNCopen}
                  // enableSearch={true}
                  enableExportBtn={true}
                  search={mncSearch}
                  setSearch={setMncSearch}
                  exportFunction={exportMNC}
                  advancedSearchFunction={handleOpenAdvancedSearchGroup}
                  advancedSearch={openAdvancedSearchGroup}
                  titleAdvancedSearch={"Advanced Search"}
                  handleSearchBtn={() => setRefreshTableMNC(true)}
                  contentAdvancedSearch={
                    <Grid spacing={3} container>
                      <Grid lg={3} xs={12} item>
                        <TextFieldForm
                          placeholder={"MNC"}
                          value={mncSearch}
                          setValue={setMncSearch}
                          positiveNumber
                        />
                      </Grid>
                      <Grid lg={3} xs={12} item>
                        <SelectFormNew
                          value={CountrySearch}
                          setValue={setCountrySearch}
                          options={CountryOptions}
                          placeholder={"Country"}
                        />
                      </Grid>
                      <Grid lg={3} xs={12} item>
                        <SelectFormNew
                          value={OperatorSearch}
                          setValue={setOperatorSearch}
                          options={OperatorOptions}
                          placeholder={"Operator"}
                          disabled={
                            CountrySearch === "" ||
                            OperatorOptions?.length === 0
                          }
                          isLoading={isLoadingOperator}
                          wait={"select a country"}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Paper>
            )}
            {activeBtn === "Prefixes" && (
              <Paper variant="CardTable">
                <Table
                  handleResetAll={handleResetAllPrefixes}
                  enableImportBtn={true}
                  ImportFunction={() => setopenImportFile(true)}
                  rowCountState={tableDataPrefixes?.totalCount}
                  data={tableDataPrefixes?.data}
                  isLoading={isLoading}
                  columns={columnsPrefixes}
                  pageIndex={
                    tableDataPrefixes?.pageIndex
                      ? tableDataPrefixes?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModelPrefixes}
                  paginationModel={paginationModelPrefixes}
                  enableDelete={true}
                  // enableEdit={true}
                  // enableAddBtn={true}
                  // addBtnName={"Criteria"}
                  // addFunction={handleAddMangeCriteriaopen}
                  deleteFunction={OpenConfirmModalPrefixes}
                  editFunction={handleEditMangePrefixesopen}
                  // enableSearch={true}
                  enableExportBtn={true}
                  search={searchPrefixes}
                  setSearch={setSearchPrefixes}
                  exportFunction={exportPrefixes}
                  advancedSearchFunction={handleOpenAdvancedSearchPrefixes}
                  advancedSearch={openAdvancedSearchPrefixes}
                  titleAdvancedSearch={"Advanced Search"}
                  handleSearchBtn={() => setRefreshTablePrefixes(true)}
                  contentAdvancedSearch={
                    <Grid spacing={3} container>
                      <Grid lg={3} xs={12} item>
                        <TextFieldForm
                          placeholder={"Prefix Number"}
                          value={searchPrefixes}
                          setValue={setSearchPrefixes}
                          positiveNumber
                        />
                      </Grid>
                      <Grid lg={3} xs={12} item>
                        <SelectFormNew
                          value={CountrySearch}
                          setValue={setCountrySearch}
                          options={CountryOptions}
                          placeholder={"Country"}
                        />
                      </Grid>
                      <Grid lg={3} xs={12} item>
                        <SelectFormNew
                          value={OperatorSearch}
                          setValue={setOperatorSearch}
                          options={OperatorOptions}
                          placeholder={"Operator"}
                          disabled={
                            CountrySearch === "" ||
                            OperatorOptions?.length === 0
                          }
                          isLoading={isLoadingOperator}
                          wait={"select a country"}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Paper>
            )}
            {activeBtn === "Operators" && (
              <Paper variant="CardTable">
                <Table
                  handleResetAll={handleResetAllOperators}
                  rowCountState={tableDataOperators?.totalCount}
                  data={tableDataOperators?.data}
                  isLoading={isLoading}
                  columns={columnsOper}
                  pageIndex={
                    tableDataOperators?.pageIndex
                      ? tableDataOperators?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModelOperators}
                  paginationModel={paginationModelOperators}
                  enableDelete={true}
                  enableEdit={true}
                  // enableAddBtn={true}
                  // addBtnName={"Operators"}
                  // addFunction={handleAddMangeOperatorsopen}
                  deleteFunction={OpenConfirmModalOperators}
                  editFunction={handleEditMangeOperatorsopen}
                  // enableSearch={true}
                  enableExportBtn={true}
                  search={searchOperators}
                  setSearch={setSearchOperators}
                  // enableFilter2
                  valueFilter={countryFilter}
                  setValueFilter={setCountryFilter}
                  optionsFilter={CountryOptions}
                  placeholderFilter={"Country"}
                  exportFunction={exportOperators}
                  advancedSearchFunction={handleOpenAdvancedSearchOperators}
                  advancedSearch={openAdvancedSearchOperators}
                  titleAdvancedSearch={"Advanced Search"}
                  handleSearchBtn={() => setRefreshTableOperators(true)}
                  contentAdvancedSearch={
                    <Grid spacing={3} container>
                      <Grid lg={3} xs={12} item>
                        <TextFieldForm
                          placeholder={"Name"}
                          value={searchOperators}
                          setValue={setSearchOperators}
                          // positiveNumber
                        />
                      </Grid>
                      <Grid lg={3} xs={12} item>
                        <SelectFormNew
                          value={CountrySearch}
                          setValue={setCountrySearch}
                          options={CountryOptions}
                          placeholder={"Country"}
                          // selected
                        />
                      </Grid>
                      {/* <Grid lg={3} xs={12} item>
                        <TextFieldForm
                          placeholder={"MNC"}
                          value={mncOperators}
                          setValue={setMncOperators}
                          positiveNumber
                        />
                      </Grid> */}
                    </Grid>
                  }
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid xs={12} md={10} lg={10}>
          {activeBtn === "MNC" && (
            <Paper variant="CardTable">
              <Table
                handleResetAll={handleResetAllMnc}
                rowCountState={tableDataMNC?.totalCount}
                data={tableDataMNC?.data}
                isLoading={isLoading}
                columns={columnsMNC}
                pageIndex={
                  tableDataMNC?.pageIndex ? tableDataMNC?.pageIndex : 0
                }
                setPaginationModel={setPaginationModelMNC}
                paginationModel={paginationModelMNC}
                enableDelete={true}
                enableEdit={true}
                // enableAddBtn={true}
                // addBtnName={"Zone"}
                // addFunction={handleAddMangeLocationopen}
                deleteFunction={OpenConfirmModalMNC}
                editFunction={handleEditMangeMNCopen}
                // enableSearch={true}
                enableExportBtn={true}
                search={mncSearch}
                setSearch={setMncSearch}
                exportFunction={exportMNC}
                advancedSearchFunction={handleOpenAdvancedSearchGroup}
                advancedSearch={openAdvancedSearchGroup}
                titleAdvancedSearch={"Advanced Search"}
                handleSearchBtn={() => setRefreshTableMNC(true)}
                enableImportBtn={true}
                ImportFunction={() => setopenImportFile(true)}
                contentAdvancedSearch={
                  <Grid spacing={3} container>
                    <Grid lg={3} xs={12} item>
                      <TextFieldForm
                        placeholder={"MNC"}
                        value={mncSearch}
                        setValue={setMncSearch}
                        positiveNumber
                      />
                    </Grid>
                    <Grid lg={3} xs={12} item>
                      <SelectFormNew
                        value={CountrySearch}
                        setValue={setCountrySearch}
                        options={CountryOptions}
                        placeholder={"Country"}
                      />
                    </Grid>
                    <Grid lg={3} xs={12} item>
                      <SelectFormNew
                        value={OperatorSearch}
                        setValue={setOperatorSearch}
                        options={OperatorOptions}
                        placeholder={"Operator"}
                        disabled={
                          CountrySearch === "" || OperatorOptions?.length === 0
                        }
                        isLoading={isLoadingOperator}
                        wait={"select a country"}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Paper>
          )}
          {activeBtn === "Prefixes" && (
            <Paper variant="CardTable">
              <Table
                handleResetAll={handleResetAllPrefixes}
                enableImportBtn={true}
                ImportFunction={() => setopenImportFile(true)}
                rowCountState={tableDataPrefixes?.totalCount}
                data={tableDataPrefixes?.data}
                isLoading={isLoading}
                columns={columnsPrefixes}
                pageIndex={
                  tableDataPrefixes?.pageIndex
                    ? tableDataPrefixes?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModelPrefixes}
                paginationModel={paginationModelPrefixes}
                enableDelete={true}
                // enableEdit={true}
                // enableAddBtn={true}
                // addBtnName={"Criteria"}
                // addFunction={handleAddMangeCriteriaopen}
                deleteFunction={OpenConfirmModalPrefixes}
                editFunction={handleEditMangePrefixesopen}
                // enableSearch={true}
                // enableExportBtn={true}
                search={searchPrefixes}
                setSearch={setSearchPrefixes}
                // exportFunction={exportPrefixes}
                advancedSearchFunction={handleOpenAdvancedSearchPrefixes}
                advancedSearch={openAdvancedSearchPrefixes}
                titleAdvancedSearch={"Advanced Search"}
                handleSearchBtn={() => setRefreshTablePrefixes(true)}
                contentAdvancedSearch={
                  <Grid spacing={3} container>
                    <Grid lg={3} xs={12} item>
                      <TextFieldForm
                        placeholder={"Prefix Number"}
                        value={searchPrefixes}
                        setValue={setSearchPrefixes}
                        positiveNumber
                      />
                    </Grid>
                    <Grid lg={3} xs={12} item>
                      <SelectFormNew
                        value={CountrySearch}
                        setValue={setCountrySearch}
                        options={CountryOptions}
                        placeholder={"Country"}
                      />
                    </Grid>
                    <Grid lg={3} xs={12} item>
                      <SelectFormNew
                        value={OperatorSearch}
                        setValue={setOperatorSearch}
                        options={OperatorOptions}
                        placeholder={"Operator"}
                        disabled={
                          CountrySearch === "" || OperatorOptions?.length === 0
                        }
                        isLoading={isLoadingOperator}
                        wait={"select a country"}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Paper>
          )}
          {activeBtn === "Operators" && (
            <Paper variant="CardTable">
              <Table
                handleResetAll={handleResetAllOperators}
                rowCountState={tableDataOperators?.totalCount}
                data={tableDataOperators?.data}
                isLoading={isLoading}
                columns={columnsOper}
                pageIndex={
                  tableDataOperators?.pageIndex
                    ? tableDataOperators?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModelOperators}
                paginationModel={paginationModelOperators}
                enableDelete={true}
                enableEdit={true}
                // enableAddBtn={true}
                // addBtnName={"Operators"}
                // addFunction={handleAddMangeOperatorsopen}
                deleteFunction={OpenConfirmModalOperators}
                editFunction={handleEditMangeOperatorsopen}
                // enableSearch={true}
                enableExportBtn={true}
                search={searchOperators}
                setSearch={setSearchOperators}
                // enableFilter2
                valueFilter={countryFilter}
                setValueFilter={setCountryFilter}
                optionsFilter={CountryOptions}
                placeholderFilter={"Country"}
                exportFunction={exportOperators}
                advancedSearchFunction={handleOpenAdvancedSearchOperators}
                advancedSearch={openAdvancedSearchOperators}
                titleAdvancedSearch={"Advanced Search"}
                handleSearchBtn={() => setRefreshTableOperators(true)}
                contentAdvancedSearch={
                  <Grid spacing={3} container>
                    <Grid lg={3} xs={12} item>
                      <TextFieldForm
                        placeholder={"Name"}
                        value={searchOperators}
                        setValue={setSearchOperators}
                        // positiveNumber
                      />
                    </Grid>
                    <Grid lg={3} xs={12} item>
                      <SelectFormNew
                        value={CountrySearch}
                        setValue={setCountrySearch}
                        options={CountryOptions}
                        placeholder={"Country"}
                        // selected
                      />
                    </Grid>
                    {/* <Grid lg={3} xs={12} item>
                      <TextFieldForm
                        placeholder={"MNC"}
                        value={mncOperators}
                        setValue={setMncOperators}
                        positiveNumber
                      />
                    </Grid> */}
                  </Grid>
                }
              />
            </Paper>
          )}
        </Grid>
      )}

      {/* Delete MNC Modal */}
      {handleConfirmModalMNC && (
        <ConfirmationModal
          open={ConfirmOpenMNC}
          onClose={handleConfirmModalMNC}
          title="Delete"
          text="Are you sure you want to delete this MNC ?"
          onButtonClick={handleDeleteMNC}
        />
      )}
      {/* Delete Prefixes Modal */}
      {handleConfirmModalPrefixes && (
        <ConfirmationModal
          open={ConfirmOpenPrefixes}
          onClose={handleConfirmModalPrefixes}
          title="Delete"
          text="Are you sure you want to delete this prefix ?"
          onButtonClick={handleDeletePrefixes}
        />
      )}
      {/* Delete Operators Modal */}
      {handleConfirmModalOperators && (
        <ConfirmationModal
          open={ConfirmOpenOperators}
          onClose={handleConfirmModalOperators}
          title="Delete"
          text="Are you sure you want to delete this Operator ?"
          onButtonClick={handleDeleteOperators}
        />
      )}

      {/* Manage MNC Modal */}
      {handleAddMangeMNCopen && (
        <ManageMNC
          paginationModel={paginationModelMNC}
          open={MangeMNCAddopen}
          setOpen={setMangeMNCAddopen}
          title={"Add MNC"}
          countryOptions={CountryOptions}
          setRefreshTableMNC={setRefreshTableMNC}
          setPaginationModelMNC={setPaginationModelMNC}
          setMncSearch={setMncSearch}
        />
      )}
      {handleEditMangeMNCopen && (
        <ManageMNC
          paginationModel={paginationModelMNC}
          open={MangeMNCEditopen}
          setOpen={setMangeMNCEditopen}
          title={"Edit MNC"}
          countryOptions={CountryOptions}
          setRefreshTableMNC={setRefreshTableMNC}
          isEdit={true}
          selectedMNC={selectedMNC}
          setPaginationModelMNC={setPaginationModelMNC}
          setMncSearch={setMncSearch}
        />
      )}

      {/* Manage Prefixes Modal */}
      {handleAddMangePrefixesopen && (
        <ManagePrefixes
          paginationModel={paginationModelPrefixes}
          open={MangePrefixesAddopen}
          setOpen={setMangePrefixesAddopen}
          title={"Add Prefix"}
          countryOptions={CountryOptions}
          setRefreshTablePrefixes={setRefreshTablePrefixes}
          setPaginationModelPrefixes={setPaginationModelPrefixes}
          setSearchPrefixes={setSearchPrefixes}
        />
      )}
      {handleEditMangePrefixesopen && (
        <ManagePrefixes
          paginationModel={paginationModelPrefixes}
          open={MangePrefixesEditopen}
          setOpen={setMangePrefixesEditopen}
          title={"Edit Prefix"}
          isEdit={true}
          countryOptions={CountryOptions}
          setRefreshTablePrefixes={setRefreshTablePrefixes}
          setPaginationModelPrefixes={setPaginationModelPrefixes}
          setSearchPrefixes={setSearchPrefixes}
          selectedPrefix={selectedPrefix}
        />
      )}
      {/* Manage Operators Modal */}
      {handleAddMangeOperatorsopen && (
        <ManageOperators
          paginationModel={paginationModelOperators}
          open={MangeOperatorsAddopen}
          setOpen={setMangeOperatorsAddopen}
          title={"Add Operator"}
          countryOptions={CountryOptionsRecordGuid}
          setRefreshTableOperators={setRefreshTableOperators}
          setPaginationModelOperators={setPaginationModelOperators}
          setSearchOperators={setSearchOperators}
        />
      )}
      {handleEditMangeOperatorsopen && (
        <ManageOperators
          paginationModel={paginationModelOperators}
          open={MangeOperatorsEditopen}
          setOpen={setMangeOperatorsEditopen}
          title={"Edit Operator"}
          isEdit={true}
          countryOptions={CountryOptionsRecordGuid}
          setRefreshTableOperators={setRefreshTableOperators}
          setPaginationModelOperators={setPaginationModelOperators}
          setSearchOperators={setSearchOperators}
          selectedOperator={selectedOperator}
        />
      )}

      {/* Swtich MNC Modal */}
      {isModalOpenSwitchMNC && (
        <ConfirmationModal
          open={isModalOpenSwitchMNC}
          onClose={handleModalCloseSwitchMNC}
          title={selectedMNC?.isActive ? "Deactivate " : "Activate"}
          text={`Are you sure you want to ${
            selectedMNC?.isActive ? "deactivate " : "activate"
          } this mnc?`}
          onButtonClick={handleModalConfirmSwitchMNC}
        />
      )}

      {isModalOpenSwitchPrefix && (
        <ConfirmationModal
          open={isModalOpenSwitchPrefix}
          onClose={handleModalCloseSwitchPrefixes}
          title={selectedPrefix?.isActive ? "Deactivate " : "Activate"}
          text={`Are you sure you want to ${
            selectedPrefix?.isActive ? "deactivate " : "activate"
          } this mnc?`}
          onButtonClick={handleModalConfirmSwitchPrefixes}
        />
      )}

      {/* Swtich MNC main Modal */}
      {isModalOpenSwitchMNCmain && (
        <ConfirmationModal
          open={isModalOpenSwitchMNCmain}
          onClose={handleModalCloseSwitchMNCmain}
          title={selectedMNC?.isMain ? "Deactivate " : "Activate"}
          text={`Are you sure you want to ${
            selectedMNC?.isMain ? "unset " : "set"
          } this mnc as main?`}
          onButtonClick={handleModalConfirmSwitchMNCmain}
        />
      )}

      {/* Swtich Prefixes Modal */}
      {isModalOpenSwitchPrefixes && (
        <ConfirmationModal
          open={isModalOpenSwitchPrefixes}
          onClose={handleModalCloseSwitchPrefixes}
          title={selectedPrefix?.isActive ? "Deactivate " : "Activate"}
          text={`Are you sure you want to ${
            selectedPrefix?.isActive ? "deactivate " : "activate"
          } this prefix?`}
          onButtonClick={handleModalConfirmSwitchPrefixes}
        />
      )}
      {/* Swtich Operators Modal */}
      {isModalOpenSwitchOperators && (
        <ConfirmationModal
          open={isModalOpenSwitchOperators}
          onClose={handleModalCloseSwitchOperators}
          title={selectedOperator?.isActive ? "Deactivate " : "Activate"}
          text={`Are you sure you want to ${
            selectedOperator?.isActive ? "deactivate " : "activate"
          } this operator?`}
          onButtonClick={handleModalConfirmSwitchOperators}
        />
      )}
      {/* Swtich Operators exlusive Modal */}
      {isModalOpenSwitchOperatorsexlusive && (
        <ConfirmationModal
          open={isModalOpenSwitchOperatorsexlusive}
          onClose={handleModalCloseSwitchOperatorsexlusive}
          title="Change Status"
          text="Are you sure you want to change this Exlusive Status?"
          onButtonClick={handleModalConfirmSwitchOperatorsexlusive}
        />
      )}
      {console.log("HELLL", openMessageAlert)}
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
      <CustomizedDialogs
        open={openImportFile}
        setOpen={setopenImportFile}
        title="Import File"
        // firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid style={{ marginBottom: "15px" }} item xs={12}>
                {/* <SelectFormNew
                  value={
                    activeBtn == "Rates"
                      ? clientCategoryImport
                      : providerCategoryImport
                  }
                  setValue={
                    activeBtn == "Rates"
                      ? setClientCategoryImport
                      : activeBtn == "Costs"
                      ? setProviderCategoryImport
                      : ""
                  }
                  options={
                    activeBtn == "Rates"
                      ? clientCategoryImportOptions
                      : activeBtn == "Costs"
                      ? providerCategoryImportOptions
                      : ""
                  }
                  placeholder={
                    activeBtn == "Rates"
                      ? "Client Category"
                      : activeBtn == "Costs"
                      ? "Provider Category"
                      : ""
                  }
                /> */}
              </Grid>
              <MyDropzone 
              setFile={setFile} 
              file={file} 
              downloadSample={true}
              sampleUrl= {`${localStorage.getItem("baseUrl")}/configuration${DOWNLOAD_PREFIX_SAMPLE}`}
              />
              <Grid className="end" marginBottom={0} marginTop={3} container>
                <Button
                  onClick={submitFile}
                  variant="contained"
                  className="contained"
                  // disabled={
                  //   activeBtn === "Rates"
                  //     ? clientCategoryImport === "" || file === ""
                  //     : providerCategoryImport === "" || file === ""
                  // }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
    </div>
  );
};

export default Configuration;
