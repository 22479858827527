import {
  FormControlLabel,
  Grid,
  Switch
} from "@mui/material";
import React, { useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import PasswordInput from "../../../Components/TextFields/PasswordInput";
import { withTranslation } from "react-i18next";
const ManageUser = ({ open, setOpen, title, onButtonClick ,t }) => {
  const [Role, setRole] =useState("");
  const [RoleOptions, setRoleOptions] = useState(["admin","client","ali"]);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Username, setUsername] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");


  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={t("Save")}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
      
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={t("First Name")}
                value={FirstName}
                setValue={setFirstName}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={t("Last Name")}
                value={LastName}
                setValue={setLastName}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={t("Username")}
                value={Username}
                setValue={setUsername}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={t("Email")}
                value={Email}
                setValue={setEmail}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <PasswordInput
                placeholder={t("Password")}
                value={Password}
                setValue={setPassword}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <PasswordInput
                placeholder={t("Confirm Password")}
                value={ConfirmPassword}
                setValue={setConfirmPassword}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm value={Role} setValue={setRole}  options={RoleOptions} placeholder={t("Role")}/>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default withTranslation("translations")(ManageUser);
