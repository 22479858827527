import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    refreshToken: null,
    role: null,
};

const authSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        SignIn: (state, action) => {
            return{
                ...state,
                token: action?.payload?.token,
                refreshToken: action?.payload?.refreshToken,
                role: action?.payload?.role,
                clientId: action?.payload?.clientId,
                BillingAccount: null,
            }
        },
        SetClientId: (state, action) => {
            return{
                ...state,
                clientId: action?.payload?.clientId
            }
        },
        SetBillingId: (state, action) => {
            return{
                ...state,
                BillingAccount: action.payload
            }
        }
    },
});

export const { SignIn, SetClientId, SetBillingId } = authSlice.actions;
export default authSlice.reducer;