import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import HorizantalTabs from "../../../Components/Tabs/ChannelTabs";
import VerticalTabs from "../../../Components/Tabs/ChannelTabVertical";
import SmsOneCharts from "./SmsOneCharts";
import ViberCharts from "./ViberCharts";
import All from "./All";
import TowWay from "./TowWay";
import Whatsapp from "./Whatsapp";
import Push from "./Push";
import SmsIcon from "@mui/icons-material/Sms";
import ChatIcon from "@mui/icons-material/Chat";
import PushPinIcon from "@mui/icons-material/PushPin";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import VibrationIcon from "@mui/icons-material/Vibration";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import BorderAllIcon from "@mui/icons-material/BorderAll";
function ChannelsDashboard() {
  const [activeBtn, setActiveBtn] = useState("All");
  const tabsVertical = [
    {
      label: "",
      icon: "",
    },
    {
      label: "All",
      icon: <BorderAllIcon />,
    },
    {
      label: "Sms",
      icon: <SmsIcon />,
    },
    {
      label: "Sms Two Way",
      icon: <ChatIcon />,
    },
    {
      label: "Push",
      icon: <PushPinIcon />,
    },
    {
      label: "Whatsapp",
      icon: <WhatsAppIcon />,
    },
    {
      label: "Viber",
      icon: <VibrationIcon />,
    },
  ];
  const [startDate, setStartDate] = useState("2022-12-19");
  const [endDate, setEndDate] = useState("2023-12-19");
  const [newDate, setnewDate] = useState(false);
  const tabs = [
    {
      label: "All",
      icon: <BorderAllIcon className="chanel-icons"/>,
    },
    {
      label: "Sms",
      icon: <SmsIcon className="chanel-icons"/>,
    },
    {
      label: "Sms Two Way",
      icon: <ChatIcon className="chanel-icons"/>,
    },
    {
      label: "Push",
      icon: <PushPinIcon className="chanel-icons"/>,
    },
    {
      label: "Whatsapp",
      icon: <WhatsAppIcon className="chanel-icons"/>,
    },
    {
      label: "Viber",
      icon: <VibrationIcon className="chanel-icons"/>,
    },
  ];
  const isLargeScreen = window.innerWidth > 729;
  const handleTabChange = (newTabLabel) => {
    setActiveBtn(newTabLabel);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "startdate") {
      setStartDate(value);
    } else if (name === "enddate") {
      setnewDate(true);
      setEndDate(value);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const renderLargeScreenLayout = () => (
    <Box>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">
              Channel Management
            </Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={8} xl={8}>
          <HorizantalTabs tabs={tabs} onTabChange={handleTabChange} />
        </Grid>
        <Grid item sx={12} md={12} lg={4} xl={4} className="end">
          <TextField
            key={"startdate"}
            id={"startdate"}
            name={"startdate"}
            variant="outlined"
            value={startDate}
            onChange={handleChange}
            // className="custom-select"
            // label="Start Date"
            // variant="date-picker"
            // sx={{
            //   border:'1px solid black',
            //   padding: '3px',
            //   borderRadius: '28px',
            // }}

            sx={{ marginRight: "10px", marginBottom: "13px" }}
            type={"date"}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            key={"enddate"}
            id={"enddate"}
            name={"enddate"}
            variant="outlined"
            value={endDate}
            onChange={handleChange}
            // label="End Date"
            // sx={{
            //   border:'1px solid black',
            //   padding: '3px',
            //   borderRadius: '28px',
            // }}
            sx={{ marginBottom: "13px" }}
            type={"date"}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={12}>
          {activeBtn === "All" && <All />}
          {activeBtn === "Sms" && <SmsOneCharts />}
          {activeBtn === "Sms Two Way" && <TowWay />}
          {activeBtn === "Push" && <Push />}
          {activeBtn === "Whatsapp" && <Whatsapp />}
          {activeBtn === "Viber" && <ViberCharts />}
        </Grid>
      </Grid>
    </Box>
  );

  const renderSmallScreenLayout = () => (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">
              Channel Management
            </Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid container>
      <Grid
          item
          sm={8}
          xs={12}
          className={isXsScreen ? "" : "end"}
          sx={{
            mt: isXsScreen ? 2 : 0,order: isXsScreen ? 1 : 2 
          }}
        >
          <TextField
            key={"startdate"}
            id={"startdate"}
            name={"startdate"}
            variant="outlined"
            value={startDate}
            onChange={handleChange}
            // className="custom-select"
            // label="Start Date"
            // variant="date-picker"
            // sx={{
            //   border:'1px solid black',
            //   padding: '3px',
            //   borderRadius: '28px',
            // }}

            sx={{ marginRight: "10px" }}
            type={"date"}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            key={"enddate"}
            id={"enddate"}
            name={"enddate"}
            variant="outlined"
            value={endDate}
            onChange={handleChange}
            // label="End Date"
            type={"date"}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      <Grid
          item
          sm={4}
          xs={12}
          className={isXsScreen ? "" : ""}
          sx={{
            mt: isXsScreen ? 2 : 0, order: isXsScreen ? 2 : 1
          }}
        >
          <FormControl className="select-hover">
            <Select
              labelId="demo-simple-select-standard"
              id="demo-simple-select"
              value=""
              className="custom-select"
              variant="standard"
              displayEmpty
            >
              <MenuItem value="" disabled>
                {activeBtn}
              </MenuItem>
              <MenuItem>
                <VerticalTabs
            activeBtn={activeBtn}

                  tabs={tabsVertical}
                  windowWidth={windowWidth}
                  onTabChange={handleTabChange}
                />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={12}>
          {activeBtn === "All" && <All />}
          {activeBtn === "Sms" && <SmsOneCharts />}
          {activeBtn === "Sms Two Way" && <TowWay />}
          {activeBtn === "Push" && <Push />}
          {activeBtn === "Whatsapp" && <Whatsapp />}
          {activeBtn === "Viber" && <ViberCharts />}
        </Grid>
      </Grid>
      
    </Box>
  );

  useEffect(() => {
    console.log(activeBtn);
  }, [activeBtn]);

  return (
    <Box>
      {windowWidth > 1499
        ? renderLargeScreenLayout()
        : renderSmallScreenLayout()}
    </Box>
  );
}
export default ChannelsDashboard;
