import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";

export default function TextFieldForm({
  value,
  setValue,
  placeholder,
  adornment,
  type,
  placeholderInside,
  positiveNumber,
  positiveNumberFloat,
  onChangeFct,
  isMobileNumber,
  selected,
  // error
  id,
  key,
  name,
  error,
  helperText,
  onBlur,
  formikValue,
  onChange,
  formik,
  handleChange,
  disabled,
  minLength,
  maxLength,
  dateTime,
  handleClearDateInput,
  defaultValue,
  bankAccountNb
}) {
  function isPositiveNumber(value) {
    if (/^\d*\.?\d+$/.test(value)) {
      setValue(value);
    }
    if (value === "") {
      setValue(value);
    }
  }
  function isPositiveNumberFloat(value) {
    if (/^\d*\.?\d{0,5}$/.test(value)) {
      setValue(value);
    }
  }
  function isPositieNuberWithOneCharacter (value) {
    if (/^[0-9-]*$/.test(value)) {
      setValue(value);
    }
    
}
  const handleClearInput = () => {
    setValue("");
  };
  function validateMobileNumber(number) {
    if (/^(?:\+\d{1,3}\s?)?(?:\d{10,12})$/.test(number)) {
      setValue(number);
    }
  }
  const handleChangeInput = (event) => {
    const inputValue = event.target.value;
    // Check if the input value contains only spaces
    if (inputValue.trim() === "") {
      // If only spaces are present, set the value to an empty string
      setValue("");
    } else {
      // Otherwise, update the value normally
      setValue(inputValue);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      {placeholder && (
        <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
          {placeholder}
        </span>
      )}

      <FormControl
        sx={{
          width: "100%",
          borderRadius: "32px",
          border: "1px solid #E0E0E0 ",
          display: "grid",
        }}
      >
        {!formik && !dateTime && (
          <OutlinedInput
            autoComplete="off"
            type={type}
            InputLabelProps={{
              shrink: true,
            }}
            endAdornment={
              handleClearDateInput && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear input"
                    onClick={handleClearDateInput}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
            InputProps={{
              placeholder: "",
            }}
            // id={id}
            helperText={helperText}
            key={key}
            name={name}
            error={error}
            disabled={disabled}
            size="small"
            id="header-search"
            placeholder={placeholderInside ? placeholderInside : ""}
            value={value}
            onChange={(e) => {
              positiveNumber
                ? isPositiveNumber(e.target.value)
                : positiveNumberFloat
                ? isPositiveNumberFloat(e.target.value)
                : onChangeFct
                ? onChangeFct(e.target.value)
                : isMobileNumber
                ? validateMobileNumber(e.target.value):
                bankAccountNb?
                isPositieNuberWithOneCharacter(e.target.value)
                : handleChangeInput(e);
            }}
            aria-describedby="header-search-text"
            inputProps={{
              minLength: minLength,
              maxLength: maxLength,
              "aria-label": "weight",
            }}
            startAdornment={
              adornment && (
                <InputAdornment position="start">
                  <PersonOutlineIcon />
                </InputAdornment>
              )
            }
            onBlur={onBlur}
            // endAdornment={adornment&&
            //   <InputAdornment position="end">
            //     <PersonOutlineIcon />


            
            //   </InputAdornment>
            // }
            //   placeholder={placeholder}
          />
        )}
        {formik && !dateTime && (
          <TextField
            value={formikValue}
            id={id}
            key={key}
            name={name}
            onBlur={onBlur}
            type={type}
            error={error}
            helperText={helperText}
            onChange={onChange}
            disabled={disabled}
            sx={{
              "&.MuiTextField-root": {
                border: "none",
              },
              padding: "5px",
            }}
          />
        )}
      </FormControl>
    </Box>
  );
}
