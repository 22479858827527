import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const SelectFormNew = ({
  options,
  multiple,
  placeholder,
  value,
  setValue,
  disabled,
  formik,
  name,
  onChange,
  key,
  onBlur,
  formikValue,
  labelId,
  id,
  selected,
  isLoading,
  wait,
  ListboxProps,
  placementTop,
  extrafct,
}) => {
  const handleChange = (event) => {
    if (event !== null) {
      setValue(event.value);
    }
    if (!selected && event === null) {
      setValue("");
    }
  };

  return (
    <div>
      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>{placeholder}</span>
      <FormControl
        sx={{
          borderRadius: "32px",

          border: "1px solid #E0E0E0 ",
          width: "100%",
          border: "none",
        }}
        variant="outlined"
      >
        {formik ? (
          <Select
            key={key}
            id={id} // Add an id for accessibility
            name={name} // Name should match the field name in initialValues
            onChange={onChange}
            onBlur={onBlur}
            value={formikValue}
            labelId={labelId}
            size="small"
            //   MenuProps={{
            //     PaperProps: {
            //         style: {
            //             maxHeight: 200, // Adjust the maxHeight according to your preference
            //             width: 250, // Adjust the width if necessary
            //             background:"red"
            //         },
            //     },
            // }}
            style={{
              border: "1px solid rgb(224, 224, 224)",
              borderRadius: "32px",
              padding: "0px",
            }}
            sx={{
              padding: "0px 8px 8px 8px",
              "&.MuiTextField-root": {
                border: "1px solid #E0E0E0",
              },
              // maxHeight: "200px",
              // "&.MuiOutlinedInput-notchedOutline": {
              //   border: "none",
              // },
              // "& fieldset": { border: "none" },

              maxWidth: "96%",
            }}
          >
            {options?.map((item) => (
              <MenuItem value={item?.value}>{item?.label}</MenuItem>
            ))}
          </Select>
        ) : (
          <Autocomplete
            PopperComponent={(props) => (
              <Popper
                {...props}
                style={{ zIndex: 1500, width: placementTop ? "370px" : "100%" }}
                // placement={placementTop && "top"}
              />
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust the maxHeight according to your preference
                  width: 250, // Adjust the width if necessary
                  zIndex: 1500,
                },
              },
            }}
            slotProps={{
              popper: {
                sx: {
                  zIndex: 1000,
                },
              },
            }}
            popupIcon={<KeyboardArrowDownIcon />}
            ListboxProps={{ style: { maxHeight: "7rem" } }}
            disableClearable={selected}
            disabled={disabled}
            disablePortal={!placementTop}
            options={options || []}
            value={{
              label:
                options !== false &&
                options?.find((x) => x.value === value)?.label
                  ? options?.find((x) => x.value === value)?.label
                  : "",
              value: value,
            }}
            sx={{
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none",
              },
            }}
            // onChange={handleChange}
            onChange={(e, newValue) => {
              handleChange(newValue);
              extrafct && extrafct();
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                sx={{
                  padding: "0px 8px 8px 8px",
                  "&.MuiTextField-root": {
                    border: "1px solid #E0E0E0",
                  },
                  "&.MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& fieldset": { border: "none" },

                  maxWidth: "96%",
                }}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {" "}
                      {wait && isLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {!wait && !isLoading && options === false && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  startAdornment: (
                    <>
                      {!wait &&
                        !isLoading &&
                        options &&
                        options?.length === 0 &&
                        "No options found"}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option.label}
            PaperComponent={({ children }) => (
              <Paper style={{ overflowY: "auto" }}>{children}</Paper>
            )}
            noOptionsMessage={() => "No Categories Found"}
            loading={!options}
            // getOptionValue={(option) => option.value}
          />
        )}
      </FormControl>
    </div>
  );
};

export default SelectFormNew;
