import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_SERVICE,
  GET_ALL_SERVICE_CATEGORY,
  GET_ALL_SERVICE_TYPE,
  MULTI_TENANCY_CONFIG,
  UPDATE_SERVICE,
} from "../../APIs";

const ManageLocation = ({
  open,
  setOpen,
  title,
  isEdit,
  setRefreshTableService,
  setOpenAlert,
  setSearchService,
  selectedService,
  paginationModalService,
  setPaginationModalService,
  currenciesOptions,
}) => {
  let services = localStorage.getItem("services");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [serviceCategoryOptions, setServiceCategoryOptions] = useState(false);
  const [serviceTypeOptions, setServiceTypeOptions] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [currency, setCurrency] = useState("");
  const [nbOfSubscription, setNbOfSubscription] = useState(0);
  const [nbOfUnSubscription, setNbOfUnSubscription] = useState(0);

  const [isBroadcast, setIsBroadcast] = useState(false);
  const [isIntegration, setIsIntegration] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isApi, setIsApi] = useState(false);

  const [serviceCategory, setServiceCategory] = useState(false);
  const [price, setPrice] = useState("");

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const handleSwitchChangeBroadcast = () => {
    setIsBroadcast(!isBroadcast);
  };

  const handleSwitchChangeIntegration = () => {
    setIsIntegration(!isIntegration);
  };

  const handleSwitchChangeTemplate = () => {
    setIsTemplate(!isTemplate);
  };
  const handleSwitchChangeApi = () => {
    setIsApi(!isApi);
  };

  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      serviceCategoryGuid: serviceCategory,
      ServiceTypeGuid: serviceType,
      currencyCode: currency,
      price: price,
      ServiceInfo: {
        NumberOfSubscription: 0,
        NumberOfUnSubscription: 0,
        // IsActive: isBroadcast,
      },
      serviceDetails: [
        {
          languageCode: "en",
          name: name,
          description: description,
        },
      ],
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${ADD_SERVICE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setName("");
        setOpenMessageAlert(true);
        setMessageData({
          message: data?.message || "Successfully Added",
          type: "success",
        });
        setRefreshTableService(true);
        setOpen(false);
        setPaginationModalService({
          page: 0,
          pageSize: paginationModalService?.pageSize
            ? paginationModalService?.pageSize
            : 10,
        });
        // setOpenAlert(true);
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  useEffect(() => {
    getAllServiceCategory();
    getAllServiceType();
    {
      !isEdit && setName("");
      setSwitchState(true);
    }
  }, [open]);
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      recordGuid: selectedService?.recordGuid,
      price: parseFloat(price),
      currencyCode: currency,

      SERVICEDETAILS: [
        {
          LANGUAGECODE: "EN",
          NAME: name,
          DESCRIPTION: description,
        },
      ],
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${UPDATE_SERVICE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setName("");
        setOpenMessageAlert(true);
        setMessageData({
          message: data?.message || "Successfully Added",
          type: "success",
        });
        setRefreshTableService(true);
        setOpen(false);
        setPaginationModalService({
          page: 0,
          pageSize: paginationModalService?.pageSize
            ? paginationModalService?.pageSize
            : 10,
        });
        // setOpenAlert(true);
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    console.log("selectedService", selectedService);
    if (isEdit) {
      setPrice(selectedService?.price);
      setName(
        selectedService?.serviceDetails &&
          selectedService?.serviceDetails?.length !== 0
          ? selectedService?.serviceDetails[0]?.name
          : ""
      );
      setDescription(
        selectedService?.serviceDetails &&
          selectedService?.serviceDetails?.length !== 0
          ? selectedService?.serviceDetails[0]?.description
          : ""
      );
      setCurrency(selectedService?.currency?.code);
    } else {
      setServiceCategory("");
      setServiceType("");
      setCurrency("");
      setPrice("");
      setIsBroadcast(false);
      setIsIntegration(false);
      setIsTemplate(false);
      setIsApi(false);
      setName("");
      setDescription("");
    }
  }, [open, isEdit]);

  const getAllServiceCategory = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["catalog"]
        }${GET_ALL_SERVICE_CATEGORY}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setServiceCategoryOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.tag,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllServiceCategory();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const getAllServiceType = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["catalog"]
        }${GET_ALL_SERVICE_TYPE}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setServiceTypeOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.tag,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllServiceType();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={isEdit ? submitEdit : submitAdd}
        disableFirstBtn={
          isEdit
            ? name === "" ||
              description === "" ||
              price === "" ||
              currency === ""
            : name === "" ||
              description === "" ||
              price === "" ||
              currency === "" ||
              serviceCategory === "" ||
              serviceType === ""
        }
        // secondActionBtnName={"Cancel"}
        content={
          <>
            {!isEdit ? (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Name"}
                      value={name}
                      setValue={setName}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Description"}
                      value={description}
                      setValue={setDescription}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Price"}
                      value={price}
                      setValue={setPrice}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={serviceCategory}
                      setValue={setServiceCategory}
                      options={serviceCategoryOptions}
                      placeholder={"Service Category"}
                      disabled={serviceCategoryOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={serviceType}
                      setValue={setServiceType}
                      options={serviceTypeOptions}
                      placeholder={"Service Type"}
                      disabled={serviceTypeOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={currency}
                      setValue={setCurrency}
                      options={currenciesOptions}
                      placeholder={"Currency"}
                      disabled={currenciesOptions?.length === 0}
                    />
                  </Grid>
                </Grid>

                {/* <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Number Of Subscription"}
                      value={nbOfSubscription}
                      setValue={setNbOfSubscription}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Number Of UnSubscription"}
                      value={nbOfUnSubscription}
                      setValue={setNbOfUnSubscription}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid> */}

                {/* <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                          }}
                        >
                          Status
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isBroadcast}
                              onChange={handleSwitchChangeBroadcast}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                            marginRight: "20px",
                          }}
                        >
                          Integration
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isIntegration}
                              onChange={handleSwitchChangeIntegration}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                          }}
                        >
                          Template
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isTemplate}
                              onChange={handleSwitchChangeTemplate}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                            marginRight: "20px",
                          }}
                        >
                          Api
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isApi}
                              onChange={handleSwitchChangeApi}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                {/* </Grid> */}
              </>
            ) : (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Name"}
                      value={name}
                      setValue={setName}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Description"}
                      value={description}
                      setValue={setDescription}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Price"}
                      value={price}
                      setValue={setPrice}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={currency}
                      setValue={setCurrency}
                      options={currenciesOptions}
                      placeholder={"Currency"}
                      disabled={currenciesOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageLocation;
