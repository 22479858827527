import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  import DescriptionInput from "../../../Components/TextFields/DescriptionInput";

  
  const ManageServiceQuota = ({ open, setOpen, title, onButtonClick }) => {
    const [Tag, setTag] =useState("");
    const [TagOptions, setTagOptions] = useState(["tag 1","tag 2","tag 3","tag 4"]);
    const [ServiceID, setServiceID] =useState("");
    const [ServiceIDOptions, setServiceIDOptions] = useState(["Tenant 1","Tenant 2","Tenant 3","Tenant 4"]);
    const [minValue, setminValue] =useState("");
    const [minValueOptions, setminValueOptions] = useState(["Dollar","Euro"]);
    const [ServiceCategory, setServiceCategory] = useState("");
    const [quotaType, setquotaType] = useState("");
    const [quotaValue, setquotaValue] = useState("")
    const [MinValue, setMinValue] = useState("");
    const [MaxValue, setMaxValue] = useState("");
    const [ParrentId, setParrentId] = useState("");
    const [Bundle, setBundle] = useState("");
    const [StatusId, setStatusId] = useState("");
    const [switchState, setSwitchState] = useState(false);
    const [switchIsOneTime, setSwitchIsOneTime] = useState(false);
    const [switchAccessNewSubscription, setSwitchAccessNewSubscription] = useState(false);
    const [isPublished, setisPublished] = useState(false);
    const [AllowMultipleSubscription, setAllowMultipleSubscription] = useState(false);
    const [HasMultipleBillingCycle, setHasMultipleBillingCycle] = useState(false);
  
    const handleSwitchChange = () => {
      setSwitchState(!switchState);
    };
    const handleisPublished = () => {
      setisPublished(!isPublished);
    };
    const handleSwitchIsOneTimeChange = () => {
      setSwitchIsOneTime(!switchIsOneTime);
    };
    const handleSwitchAccessNewSubscriptionChange = () => {
      setSwitchAccessNewSubscription(!switchAccessNewSubscription);
    };
    const handleAllowMultipleSubscription = () => {
      setAllowMultipleSubscription(!AllowMultipleSubscription);
    };
    const handleHasMultipleBillingCycle = () => {
      setHasMultipleBillingCycle(!HasMultipleBillingCycle);
    };
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Tag} setValue={setTag}  options={TagOptions} placeholder={"Tag"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Parrent Id"}
                  value={ParrentId}
                  setValue={setParrentId}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={ServiceID} setValue={setServiceID}  options={ServiceIDOptions} placeholder={"Service ID"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Quota Type"}
                  value={quotaType}
                  setValue={setquotaType}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Quota Value"}
                  value={quotaValue}
                  setValue={setquotaValue}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Min Value"}
                  value={MinValue}
                  setValue={setMinValue}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Max Value"}
                  value={MaxValue}
                  setValue={setMaxValue}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Bundle"}
                  value={Bundle}
                  setValue={setBundle}
                />
              </Grid>
            </Grid>

            {/* <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <DescriptionInput
                  placeholder={"Description"}
                  value={Description}
                  setValue={setDescription}
                />
              </Grid>
            </Grid> */}
            
            <Grid container>
                  <Grid item xs={12}>
                  <span style={{color:"#B3B3B3",fontSize:"15px"}}>Status</span>
                  <br/>
                    <FormControlLabel
                      control={<Switch checked={switchState} onChange={handleSwitchChange} />}
                      label={switchState ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                </Grid>

          </>
        }
      />
    );
  };
  
  export default ManageServiceQuota;
  