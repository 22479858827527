import Add from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Breadcrumbs,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import axiosInstance from "../../../axiosInstance";

import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import {
  CHANGE_STATUS_BLOCK,
  CHANGE_STATUS_LOCK,
  DELETE_USER,
  Delete_REASON,
  EXPORT_SMS_ONE_WAY,
  GET_ALL_CLIENTS_CATEGORY,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_OPERATORS_OPTIONS,
  GET_ALL_PROVIDER,
  GET_ALL_REASONS,
  GET_ALL_USERS,
  MULTI_DELETE,
  MULTI_TENANCY_CONFIG,
  SELECTED_USER,
} from "../../APIs";
import ManageReasons from "./ManageReasons";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
const Reasons = ({ serviceGuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [filteredData, setFilteredData] = useState({});

  const [rowsSelectedData, setRowsSelectedData] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  // Resellers
  const [isModalOpenSwitchSmsOneWay, setModalOpenSmsOneWay] = useState(false);
  const [isCheckedSwitchSmsOneWay, setCheckedSwitchSmsOneWay] = useState(false);
  const [switchStatesSmsOneWay, setswitchStatesSmsOneWay] = useState({});
  const [MangeSmsOneWayAddopen, setMangeSmsOneWayAddopen] = useState(false);
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState(false);

  const [MangeSmsOneWayEditopen, setMangeSmsOneWayEditopen] = useState(false);
  const [clientCategorySearch, setClientCategorySearch] = useState("");
  const [ClientCategoryOptions, setClientCategoryOptions] = useState([]);
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [resetPagination, setResetPagination] = useState(false);

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  const [clientCategoryOptionsSearch, setClientCategoryOptionsSearch] =
    useState([]);

  const [countryOptionsSearch, setCountryOptionsSearch] = useState([]);
  const [countryOptionsRes, setCountryOptionsRes] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [providerOptionsSearch, seProviderOptionsSearch] = useState([]);
  const [operatorOptionsSearch, setOperatorOptionsSearch] = useState([]);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  const [operatorSearch, setOperatorSearch] = useState("");
  const [countrySearch, setCountrySearch] = useState("");
  const [isoCode, setIsoCode] = useState("");

  const [providerSearch, setProviderSearch] = useState("");
  const [block, setBlock] = useState("");
  const [lock, setLock] = useState("");

  const [blockOptions, setblockOptions] = useState([
    { label: "All", value: "" },
    { label: "Blocked", value: "Block" },
    { label: "Unblocked", value: "Unblock" },
  ]);
  const [lockOptions, setLockOptions] = useState([
    { label: "All", value: "" },
    { label: "Locked", value: "Lock" },
    { label: "Unlocked", value: "Unlock" },
  ]);
  const [mncSearch, setMncSearch] = useState("");
  const [mccSearch, setMccSearch] = useState("");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeBtn, setActiveBtn] = useState("Routing");

  const tabs = [
    {
      label: "Routing",
    },
    {
      label: "Rate",
    },
  ];

  useEffect(()=>{
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  },[activeBtn])
  


  const handleSwitchChangeSwitchSmsOneWay = (rowId) => {
    setModalOpenSmsOneWay(true);
    setCheckedSwitchSmsOneWay(rowId);
  };

  const handleModalCloseSwitchSmsOneWay = () => {
    setModalOpenSmsOneWay(false);
  };
  const handleModalConfirmSwitchSmsOneWay = () => {
    setModalOpenSmsOneWay(false);
    // setCheckedSwitchSmsOneWay(!isCheckedSwitchSmsOneWay);
    setswitchStatesSmsOneWay((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchSmsOneWay]: !prevStates[isCheckedSwitchSmsOneWay],
    }));
  };

  //
  const [Client, setClient] = React.useState("9mobile");
  const [isCheckedSwitchLocationZone, setCheckedSwitchLocationZone] =
    useState(false);
  const [isModalOpenSwitchLocationZone, setModalOpenSwitchLocationZone] =
    useState(false);

  const [isCheckedSwitchLock, setCheckedSwitchLock] = useState(false);
  const [isModalOpenSwitchLock, setModalOpenSwitchLock] = useState(false);
  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [SmsOneWay, setSmsOneWay] = React.useState("9mobile");
  const handleSwitchChangeSwitchLocationZone = (data) => {
    setModalOpenSwitchLocationZone(!isModalOpenSwitchLocationZone);
    setCheckedSwitchLocationZone(data?.recordGuid);
    setSelectedSms(data);
  };

  const handleSwitchChangeSwitchLock = (data) => {
    setModalOpenSwitchLock(!isModalOpenSwitchLock);
    setCheckedSwitchLock(data?.recordGuid);
    setSelectedSms(data);
  };
  const handleChangeSmsOneWay = (event) => {
    setSmsOneWay(event.target.value);
  };
  const [switchStatesLocationZone, setSwitchStatesLocationZone] = useState({});
  const [switchStatesLock, setSwitchStatesLock] = useState({});
  const [clientCategoryRatesOptions, setClientCategoryRatesOptions] = useState(
    []
  );
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);

  const [tableDataSmsOneWay, setTableDataSmsOneWay] = useState({
    pageIndex: 1,
    data: [],
  });

  let columnsSmsOneWay = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "categoryName",
      headerName: "Category",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        const row = params.row;

        return `${row?.category?.name}`;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        const row = params.row;

        return `${row?.category?.type?.name}`;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
  ];

  // Delete Modal Resellers functions
  const [ConfirmOpenSmsOneWay, setConfirmOpenSmsOneWay] = useState(false);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(true);
  const [smsOneWaySearch, setSmsOneWaySearch] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [searchClick, setSearchClick] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);

  const [modalContentSmsOneWay, setmodalContentSmsOneWay] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  {
  }
  const handleDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${Delete_REASON}?RecordGuid=${selectedSms?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTable(true);
        setConfirmOpenSmsOneWay(false);
        setIsLoading(false);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize
            ? paginationModel?.pageSize
            : 10,
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleDelete()
        }
        else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMultiDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let data = rowsSelectedData?.map((x) => x.recordGuid);
    console.log("Dataaa", data);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${MULTI_DELETE}`,
        {
          data: { recordGuids: data }, // or simply: data
          ...MULTI_TENANCY_CONFIG,
        }
      )
      .then((res) => {
        setRefreshTable(true);
        setDeleteAllModalOpen(false);
        setIsLoading(false);
        setSelectedRows([]);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleMultiDelete()
        }
        else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalSmsOneWay = (data, title, text, onButtonClick) => {
    setmodalContentSmsOneWay({ title, text, onButtonClick });
    setConfirmOpenSmsOneWay(true);
    setSelectedSms(data);
  };

  const handleConfirmModalSmsOneWay = () => {
    setConfirmOpenSmsOneWay(false);
  };
  const handleOpenAdvancedSearch = () => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  };
  const handleResetAll = () => {
    setSearchClick(false);
    setMccSearch("");
    setMncSearch("");
    setProviderSearch("");
    setCountrySearch("");
    setOperatorSearch("");
    setBlock("");
    setLock("");
    setClientCategorySearch(clientCategoryOptionsSearch[0].value);
    setRefreshTable(true);
  };
  //
  // Manage Resellers
  const [ManageSmsOneWayAddopen, setManageSmsOneWayAddopen] = useState(false);
  const [ManageSmsOneWayEditopen, setManageSmsOneWayEditopen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [CountryOptionsRecordGuid, setCountryOptionsRecordGuid] = useState([]);
  const [selectedSms, setSelectedSms] = useState({});
  const [modalContent, setModalContent] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [providerCategoryOptions, setProviderCategoryOptions] = useState([]);
  const handleAddManageSmsOneWayopen = () => {
    setManageSmsOneWayAddopen(true);
  };
  const handleModalConfirmSwitchBlock = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      // RecordGuid: isModalOpenSwitchLock,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${CHANGE_STATUS_BLOCK}/${
          selectedSms?.isBlocked === true ? "Unblock" : "Block"
        }/${isCheckedSwitchLocationZone}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Updated",
          type: "success",
        });
        setRefreshTable(true);
        setModalOpenSwitchLocationZone(false);
        // setRefreshTable(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchBlock(isModalOpenSwitchLock);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleModalConfirmSwitchLock = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {};
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${CHANGE_STATUS_LOCK}/${
          selectedSms?.isLocked === true ? "Unlock" : "Lock"
        }/${isCheckedSwitchLock}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Updated",
          type: "success",
        });

        setRefreshTable(true);
        setModalOpenSwitchLock(false);
        // setRefreshTable(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLock(isModalOpenSwitchLock);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterReasons = () => {
    setIsLoading(true);
    setSelectedRows([]);
    setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_REASONS}?PageIndex=${
          resetPagination ? 1 : paginationModel?.page + 1
        }&PageSize=${paginationModel?.pageSize}&search=${
          smsOneWaySearch ? smsOneWaySearch : ""
        }
        ${
          activeBtn == "Routing" ? 
          "&categoryTags=ROUTING_BLOCK_REASON&categoryTags=ROUTING_UNBLOCK_REASON&categoryTags=ROUTING_LOCK_REASON&categoryTags=ROUTING_UNLOCK_REASON"          
          :
          "&categoryTags=BILLING_RATE_PLAN_LOCK_REASONS&categoryTags=BILLING_RATE_PLAN_UNLOCK_REASONS"   
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataSmsOneWay({
          data: res?.data?.data?.criteria ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterReasons();
        }
        setTableDataSmsOneWay({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackToTable = () => {
    setManageSmsOneWayAddopen(false);
    setManageSmsOneWayEditopen(false);
  };
  const handleAddMangeRequestclose = () => {
    setManageSmsOneWayAddopen(false);
  };
  const handleEditManageSmsOneWayopen = () => {
    setManageSmsOneWayEditopen(true);
  };

  const handleEditMangeRequestclose = () => {
    setManageSmsOneWayEditopen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const handleModalCloseSwitchLocationZone = () => {
    setModalOpenSwitchLocationZone(false);
  };
  const handleModalCloseSwitchLock = () => {
    setModalOpenSwitchLock(false);
  };
  const handleModalCloseDeleteAll = () => {
    setDeleteAllModalOpen(false);
  };
  const handleAddMangeSmsOneWayopen = () => {
    setMangeSmsOneWayAddopen(true);
  };
  const handleDeleteAllopen = () => {
    setDeleteAllModalOpen(true);
  };
  const handleEditMangeSmsOneWayopen = (data) => {
    setSelectedSms(data);
    getSelectedUser(data?.recordGuid);
    setMangeSmsOneWayEditopen(true);
  };
  const getSelectedUser = (data) => {
    // setSelectedSms({});

    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${SELECTED_USER}?UserGuid=${data}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        // setSelectedSms(res?.data?.data?.user);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getSelectedUser(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllProvidersCategory = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .get(
        `${localStorage.getItem(
          "baseUrl"
        )}${GET_ALL_PROVIDER}?RecordGuid=${serviceGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        seProviderOptionsSearch([
          ...res?.data?.data?.items.map((x, i) => ({
            label: x.name ? x.name : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryOptions([
          ...res?.data?.data?.items.map((x, i) => ({
            label: x.name ? x.name : "",
            value: x.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProvidersCategory();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllClientsCategory = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_CLIENTS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setClientCategoryOptionsSearch([
          ...res?.data?.data?.clientCategories?.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setClientCategorySearch(
          res?.data?.data?.clientCategories
            ? res?.data?.data?.clientCategories[0]?.recordGuid
            : ""
        );
        setIsLoading(true);
        tableQueryExecuterReasons(
          res?.data?.data?.clientCategories[0]?.recordGuid
        );

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllClientsCategory();
        }
      })
      .finally(() => {});
  };
  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptionsSearch(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryOptionsRes(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.recordGuid,
            value: x?.recordGuid,
          }))
        );
        setOperatorOptions([]);
        setOperatorSearch("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const getAllOperatorName = () => {
    setOperatorOptionsSearch([]);
    setIsLoadingOperator(true);
    let newServices = JSON.parse(services);
    {
      setOperatorSearch("");
    }

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${isoCode}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptionsSearch(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
        setIsLoadingOperator(false);
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };
  useEffect(() => {
    if (isoCode !== "") {
      setOperatorOptions([]);
      getAllOperatorName();
    }
  }, [isoCode]);

  useEffect(() => {
    setOperatorOptionsSearch([]);
    setOperatorSearch("");
    if (countrySearch !== "") {
      setOperatorOptions([]);
      let isoCodefinded = countryOptionsRes?.find(
        (x) => x.label === countrySearch
      )?.value;
      setIsoCode(isoCodefinded);
    } else {
      setIsoCode("");
    }
  }, [countrySearch]);
  useEffect(() => {
    getAllClientsCategory();
    // getAllProvidersCategory();
    getAllCountries();
    // getAllOperatorName();
  }, []);
  useEffect(() => {
    if (serviceGuid) {
      getAllProvidersCategory();
    }
  }, [serviceGuid]);
  useEffect(() => {
    if (smsOneWaySearch !== "") {
      setResetPagination(true);
    } else {
      setResetPagination(false);
    }
  }, [smsOneWaySearch]);

  useEffect(() => {
    if (smsOneWaySearch !== "") {
      setPaginationModel({
        page: 0,
        pageSize: paginationModel?.pageSize,
      });
    }
  }, [smsOneWaySearch]);

  useEffect(() => {
    if (clientCategorySearch !== "") {
      tableQueryExecuterReasons();
      setSelectedRows([]);
    }
    if (smsOneWaySearch === "") {
      tableQueryExecuterReasons();
    }
  }, [refreshTable, paginationModel, smsOneWaySearch]);

  const exportSms = (searchClick) => {
    setIsLoading(true);
    setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    {
      console.log(
        "clientCategoryOptionsSearchclientCategoryOptionsSearch",
        clientCategoryOptionsSearch
      );
    }
    axiosInstance
      .get(
        !searchClick
          ? `${localStorage.getItem("baseUrl")}${
              newServices["routing"]
            }${EXPORT_SMS_ONE_WAY}?ClientCategoryGuid=${
              filteredData?.client ? filteredData?.client : ""
            }`
          : `${localStorage.getItem("baseUrl")}${
              newServices["routing"]
            }${EXPORT_SMS_ONE_WAY}?ClientCategoryGuid=${
              filteredData?.client ? filteredData?.client : ""
            }&CountryGuid=${
              filteredData?.countrySearch ? filteredData?.countrySearch : ""
            }&OperatorGuid=${
              filteredData?.operatorSearch ? filteredData?.operatorSearch : ""
            }&ProviderGuid=${
              filteredData?.providerSearch ? filteredData?.providerSearch : ""
            }&Blocked=${filteredData?.block}&Locked=${filteredData?.lock}&Mcc=${
              filteredData?.mcc
            }&Mnc=${filteredData?.mnc}&Keyword=${
              filteredData?.smsOneWaySearch
            }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenExportModal(false);
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `SMS.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setOpenExportModal(false);
        setIsLoading(false);
      });
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Reasons</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
          <Button
            variant="contained"
            className="contained"
            onClick={handleAddMangeSmsOneWayopen}
          >
            <Add sx={{ fontSize: "17px" }} /> &nbsp;
            <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
              Add Reason
            </Typography>
          </Button>
        </Grid>
      </Grid>
      {windowWidth < 1000 && (
        <Grid
          style={{ marginBottom: "-38px", width: windowWidth < 500 ? '280px' : '380px' }}
          container
          // marginLeft={2}
          marginTop={5}
        >
          <HorizontalTabsSubtabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />            
        </Grid>
      )}
      <Grid container className="Top-spacing div-table">
        {windowWidth > 1000 && <Grid
          item
          xs={3.5}
          md={1.5}
          lg={2}
          className="vertical-grid"
          sx={{ paddingLeft: "2px" }}
        >
          <VerticalTabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />
        </Grid>}
        <Grid style={{ marginTop: "10px" }} item  xs={12} md={windowWidth > 1000 ? 10.5 : 12} lg={10}>
          <Paper variant="CardTable">
            <Table
              // isLoading={isLoading}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              rowCountState={tableDataSmsOneWay?.totalCount}
              data={tableDataSmsOneWay?.data}
              isLoading={isLoading}
              columns={columnsSmsOneWay}
              pageIndex={
                tableDataSmsOneWay?.pageIndex
                  ? tableDataSmsOneWay?.pageIndex
                  : 0
              }
              setPaginationModel={setPaginationModel}
              paginationModel={paginationModel}
              enableDelete={true}
              enableEdit={true}
              deleteFunction={OpenConfirmModalSmsOneWay}
              editFunction={handleEditMangeSmsOneWayopen}
              // enableSearch={true}
              // enableExportBtn={true}
              // exportFunction={exportSms}
              exportFunction={() => setOpenExportModal(true)}
              valueFilter={Client}
              setValueFilter={setClient}
              // advancedSearchFunction={handleOpenAdvancedSearch}
              // advancedSearch={openAdvancedSearch}
              // handleSearchBtn={() => {
              //   setSearchClick(true);
              //   setSelectedRows([]);
              //   setRefreshTable(true);
              //   setPaginationModel({
              //     page: 0,
              //     pageSize: paginationModel?.pageSize
              //       ? paginationModel?.pageSize
              //       : 10,
              //   });
              // }}
              search={smsOneWaySearch}
              setSearch={setSmsOneWaySearch}
              // setRowsSelectedData={setRowsSelectedData}
              // rowsSelectedData={rowsSelectedData}
              // enableCheckboxSelection={true}
              // handleResetAll={handleResetAll}
            />
          </Paper>
        </Grid>
      </Grid>
      {MangeSmsOneWayAddopen && (
        <ManageReasons
          open={MangeSmsOneWayAddopen}
          setOpen={setMangeSmsOneWayAddopen}
          title={"Add Reason"}
          clientCategoryOptionsSearch={clientCategoryOptionsSearch}
          countryOptionsSearch={countryOptionsSearch}
          operatorOptionsSearch={operatorOptionsSearch}
          providerOptionsSearch={providerOptionsSearch}
          isoCode={isoCode}
          countryOptionsRes={countryOptionsRes}
          setPaginationModel={setPaginationModel}
          setRefreshTable={setRefreshTable}
          setSmsOneWaySearch={setSmsOneWaySearch}
          paginationModel={paginationModel}
          activeBtn={activeBtn}
        />
      )}
      {MangeSmsOneWayEditopen && (
        <ManageReasons
          open={MangeSmsOneWayEditopen}
          setOpen={setMangeSmsOneWayEditopen}
          title={"Edit Reason"}
          isEdit={true}
          clientCategoryOptionsSearch={clientCategoryOptionsSearch}
          countryOptionsSearch={countryOptionsSearch}
          operatorOptionsSearch={operatorOptionsSearch}
          providerOptionsSearch={providerOptionsSearch}
          isoCode={isoCode}
          countryOptionsRes={countryOptionsRes}
          setPaginationModel={setPaginationModel}
          setRefreshTable={setRefreshTable}
          setSmsOneWaySearch={setSmsOneWaySearch}
          selectedSms={selectedSms}
          paginationModel={paginationModel}
          activeBtn={activeBtn}
        />
      )}

      {/* Delete Resellers Modal */}
      {handleConfirmModalSmsOneWay && (
        <ConfirmationModal
          open={ConfirmOpenSmsOneWay}
          onClose={handleConfirmModalSmsOneWay}
          title="Delete"
          text="Are you sure you want to delete this record?"
          onButtonClick={handleDelete}
        />
      )}
      {/* {handleEditManageSmsOneWayopen && (
        <AddReseller
          open={ManageSmsOneWayEditopen}
          setOpen={setManageSmsOneWayEditopen}
          title={"Edit Resellers"}
        />
      )} */}

      {/* Swtich Resellers Modal */}
      {isModalOpenSwitchSmsOneWay && (
        <ConfirmationModal
          open={isModalOpenSwitchSmsOneWay}
          onClose={handleModalCloseSwitchSmsOneWay}
          title="Change Status"
          text="Are you sure you want to change this Resellers Status?"
          onButtonClick={handleModalConfirmSwitchSmsOneWay}
        />
      )}
      {openExportModal && (
        <CustomizedDialogs
          open={openExportModal}
          setOpen={setOpenExportModal}
          title={"Export Data "}
          firstActionBtnName={"All Data"}
          secondActionBtnName={"Filtered Data"}
          firstActionBtnFct={() => {
            exportSms(false); // Added parentheses to invoke the function
          }}
          secondActionBtn={() => {
            // setSearchClick(true);
            exportSms(true); // Added parentheses to invoke the function
          }}
          content={
            <Typography style={{ textAlign: "center" }}>
              Do you want to export all data for this client category ?
            </Typography>
          }
        />
      )}
      {isModalOpenSwitchLocationZone && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationZone}
          onClose={handleModalCloseSwitchLocationZone}
          title="Change block status"
          text={`Are you sure you want to ${
            selectedSms?.isBlocked ? "unblock this record" : "block this record"
          }?`}
          onButtonClick={handleModalConfirmSwitchBlock}
        />
      )}
      {isModalOpenSwitchLock && (
        <ConfirmationModal
          open={isModalOpenSwitchLock}
          onClose={handleModalCloseSwitchLock}
          title="Change Lock Status"
          text={`Are you sure you want to ${
            selectedSms?.isLocked ? "unlock this record" : "lock this record"
          }?`}
          onButtonClick={handleModalConfirmSwitchLock}
        />
      )}
      {deleteAllModalOpen && (
        <ConfirmationModal
          open={deleteAllModalOpen}
          onClose={handleModalCloseDeleteAll}
          title="Delete All"
          text="Are you sure you want to delete all selected records?"
          onButtonClick={handleMultiDelete}
        />
      )}
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
      {/* <Test /> */}
    </div>
  );
};

export default Reasons;
