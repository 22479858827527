import { Grid } from "@mui/material";
import axiosInstance from "../../../axiosInstance";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import {
  ADD_RATES,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_CURRENCIES,
  GET_ALL_OPERATORS_OPTIONS,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";

const ManageRates = ({
  open,
  setOpen,
  title,
  selectedRates,
  ClientCategoryOptions,
  setClientCategoryOptions,
  setRefreshTableRates,
  setOpenAlert,
  setAlertMessage,
  isEdit,
  serviceGuid,
  channelGuid,
}) => {
  const [ClientCategory, setClientCategory] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [Country, setCountry] = useState("");
  const [CountryOptions, setCountryOptions] = useState(false);
  const [Operator, setOperator] = useState("");
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [Rate, setRate] = useState("");
  const [currencies, setCurrencies] = useState("");
  const [currenciesOptions, setCurrenciesOptions] = useState(false);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  useEffect(() => {
    if (title === "Edit") {
      setRate(selectedRates?.rate);
    } else {
      getAllCurrencies();
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      setRate(selectedRates?.rate);
    }
  }, [selectedRates]);

  useEffect(() => {
    setClientCategory(ClientCategoryOptions[0]?.value);
  }, []);
  useEffect(() => {
    getAllCountries();
  }, []);
  useEffect(() => {
    if (Country !== "" && title !== "Edit") {
      getAllOperatorName();
    }
  }, [Country]);
  const handleAddRates = () => {
    let newServices = JSON.parse(services);

    const newData = {
      ServiceGuid: serviceGuid,
      ChannelGuid: channelGuid,
      OperatorGuid: Operator,
      CurrencyCode: currencies,
      CountryGuid: Country,
      OperationTypeTag: Operator,
      ClientCategoryGuid: ClientCategory,
      isDefault: false,
      Rate: Rate,
      OperationTypeTag: "GENERAL",

      // RatePlanGuid:selectedRates?.recordGuid
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_RATES}`,
        newData,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpen(false);
        setRefreshTableRates(true);
        setOpenAlert(true);
        setAlertMessage(res?.data?.message);
        // Notification.success("Api Updated Successfully");
        // this.props.toggle();
        // this.props.tableRefresh();
      })
      .catch((err) => {
        // if (err === "error: 401") {
        //   this.handleSaveChanges();
        // }
        // if (err?.response?.data?.Data) {
        //   Object.keys(err?.response?.data?.Data).forEach((item) => {
        //     Notification.error(err?.response?.data?.Data[item][0]);
        //   });
        // } else {
        //   Notification.error(
        //     err?.response?.data?.message ?? "An Error occurred updating Client"
        //   );
        // }
      })
      .finally(() => {
        // this.setState({ loading: false });
      });
  };
  const handleSaveChanges = () => {
    let newServices = JSON.parse(services);

    const newData = {
      ServiceGuid: serviceGuid,
      ChannelGuid: channelGuid,
      OperatorGuid: selectedRates?.operatorGuid,
      CurrencyCode: selectedRates?.currencyCode,
      CountryGuid: selectedRates?.countryGuid,
      // CountryCode: selectedRates?.countryCode,
      OperationTypeTag: selectedRates?.operationTypeTag,
      isDefault: false,
      Rate: Rate,
      RatePlanGuid: selectedRates?.recordGuid,
      ClientCategoryGuid: selectedRates?.clientCategoryGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_RATES}`,
        newData,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpen(false);
        setRefreshTableRates(true);
        setOpenAlert(true);
        setAlertMessage(res?.data?.message);
        setOperator("");
        setCurrencies("");
        setCountry("");
        setClientCategory("");
        setRate("");
        // Notification.success("Api Updated Successfully");
        // this.props.toggle();
        // this.props.tableRefresh();
      })
      .catch((err) => {
        // if (err === "error: 401") {
        //   this.handleSaveChanges();
        // }
        // if (err?.response?.data?.Data) {
        //   Object.keys(err?.response?.data?.Data).forEach((item) => {
        //     Notification.error(err?.response?.data?.Data[item][0]);
        //   });
        // } else {
        //   Notification.error(
        //     err?.response?.data?.message ?? "An Error occurred updating Client"
        //   );
        // }
        setOperator("");
        setCurrencies("");
        setCountry("");
        setClientCategory("");
        setRate("");
      })
      .finally(() => {
        // this.setState({ loading: false });
      });
  };

  useEffect(() => {
    if (!isEdit) {
      setOperator("");
      setCurrencies("");
      setCountry("");
      setClientCategory("");
      setRate("");
    }
  }, [open]);

  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        console.log("getAllCountries", res?.data?.data?.countries);
        setCountryOptions(
          res?.data?.data?.countries.map((country) => ({
            label: country?.name,
            value: country?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllOperatorName = () => {
    setIsLoadingOperator(true);
    setOperatorOptions([]);
    let newServices = JSON.parse(services);
    {
      setOperator("");
    }

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${Country}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        setIsLoadingOperator(false);

        if (err === "error: 401") {
          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };
  const getAllCurrencies = () => {
    let newServices = JSON.parse(services);

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CURRENCIES}?SearchKeyword=`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCurrenciesOptions(
          res?.data?.data?.currencies.map((x) => ({
            label: x?.name,
            value: x?.code,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };
  function formatNumberWithComma(inputNumber) {
    // Convert the number to a string
    let numberString = inputNumber.toString();

    // Split the string into integer and decimal parts
    let parts = numberString.split(".");

    // Add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // If there is a decimal part, take the first 5 characters
    if (parts[1]) {
      parts[1] = parts[1].substring(0, 5);
      setRate(inputNumber);
    }

    // Join the parts back together
    let formattedNumber = parts.join(".");

    return formattedNumber;
  }

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      firstActionBtnFct={title === "Edit" ? handleSaveChanges : handleAddRates}
      disableFirstBtn={
        title !== "Edit" &&
        (ClientCategory === "" ||
          Country === "" ||
          Rate === "" ||
          currencies === "")
          ? true
          : title !== "Add" && Rate === ""
          ? true
          : false
      }
      // secondActionBtnName={"Cancel"}
      content={
        <>
          {title === "Edit" ? (
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Rate"}
                  value={Rate}
                  setValue={setRate}
                  positiveNumberFloat
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={ClientCategory}
                    setValue={setClientCategory}
                    options={ClientCategoryOptions}
                    placeholder={"Client Category"}
                    disabled={ClientCategoryOptions?.length === 0}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={Country}
                    setValue={setCountry}
                    options={CountryOptions}
                    placeholder={"Country"}
                    disabled={CountryOptions?.length === 0}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={currencies}
                    setValue={setCurrencies}
                    options={currenciesOptions}
                    placeholder={"Currency"}
                    disabled={currenciesOptions?.length === 0}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={Operator}
                    setValue={setOperator}
                    options={OperatorOptions}
                    placeholder={"Operator"}
                    disabled={OperatorOptions?.length === 0 || Country === ""}
                    isLoading={isLoadingOperator}
                    wait={"select a country"}
                  />
                  <span style={{color:"red",fontSize:"14px",marginLeft:"3px"}}>
                  *rate will be added to all operators if not any is selected
                  </span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    placeholder={"Rate"}
                    value={Rate}
                    setValue={setRate}
                    // type={"number"}
                    positiveNumberFloat
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      }
    />
  );
};

export default ManageRates;
