import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// Custom Scroll Button Component
function ScrollButton(props) {
  return (
    <IconButton
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.direction === "left" ? (
        <KeyboardArrowLeft />
      ) : (
        <KeyboardArrowRight />
      )}
    </IconButton>
  );
}

export default function HorizontalTabsSubtabs({
  tabs,
  setActiveBtn,
  activeBtn,
}) {
  const [value, setValue] = useState(activeBtn);
  const [indexValue, setIndexValue] = useState(0);
  const [isHasSubTabs, setIsHasSubTabs] = useState(false);

  const handleChange = (event, newValue, subTabs) => {
    setValue(newValue);
    setActiveBtn(newValue);

    // If the clicked tab has subTabs, set the value to the first subTab
    if (subTabs && subTabs.length > 0) {
      setValue(subTabs[0].label);
      setActiveBtn(subTabs[0].label);
    }
  };

  useEffect(() => {
    setIsHasSubTabs(tabs.find((x) => x?.subTabs) ? true : false);
  }, []);

  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      className="horizontal-tabs-page"
    >
      <Grid style={{ maxHeight: "350px" }} item xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          className="horizontal-tabs"
          sx={{ marginBottom: "20px", maxHeight: "400px", display: "flex" }}
          ScrollButtonComponent={ScrollButton}
        >
          {tabs.map((tab, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "8px",
              }}
            >
              <Tab
                onClick={() => {
                  handleChange(null, tab.label, tab.subTabs);
                  setIndexValue(!isHasSubTabs ? index : 0);
                }}
                label={tab.label}
                className="tabs-name"
                sx={{
                  background: value === tab.label ? "#C41035" : "white",
                  color: value === tab.label ? "#fff" : "#949494",
                  borderRadius: "25px 0px 0px 25px",
                  fontSize: "16px",
                  fontWeight: "400",
                  textTransform: "inherit",
                  minWidth: "130px",
                  minHeight: "43px",
                  padding: "6px 16px",
                  opacity: "inherit",
                  cursor: "pointer",
                  // marginLeft: '5px'
                }}
              />
              {tab?.subTabs &&
                tab.subTabs.map((subtab, subIndex) => (
                  <Tab
                    key={subIndex}
                    onClick={() => {
                      handleChange(null, subtab.label);
                      setIndexValue(subIndex);
                    }}
                    label={subtab.label}
                    className="tabs-name"
                    sx={{
                      background: value === subtab.label ? "#C41035" : "#eb486a85",
                      color: value === subtab.label ? "#fff" : "#fff",
                      borderRadius: "0px",
                      fontSize: "16px",
                      fontWeight: "400",
                      textTransform: "inherit",
                      minWidth: "116px",
                      minHeight: "23px",
                      padding: "6px 16px",
                      opacity: "inherit",
                      cursor: "pointer",
                    }}
                  />
                ))}
            </Box>
          ))}
        </Tabs>
      </Grid>
    </Box>
  );
}
