import { Alert, FormControlLabel, Grid, Snackbar, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm"
import axiosInstance from "../../../axiosInstance";
import { ADD_COST_PLAN, ADD_OPERATOR, MULTI_TENANCY_CONFIG } from "../../APIs";
const ManageOperators = ({
  open,
  setOpen,
  title,
  onButtonClick,
  providerCategoryImportOptions,
  setOpenAlert,
  setAlertMessage,
  isEdit,
  CostData,
  selectedMNC,
  countryOptions,
  setRefreshTableOperators,
  selectedOperator,
  setPaginationModelOperators,
  paginationModel
}) => {
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [Country, setCountry] = useState("");
  const [mnc, setMnc] = useState("");
  const [mcc, setMcc] = useState("");

  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [Operator, setOperator] = useState("");
  const [switchState, setSwitchState] = useState(true);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  // useEffect(() => {
  //   if (Country !== "") {
  //     setOperatorOptions([]);
  //     getAllOperatorName();
  //   }
  // }, [Country]);

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  // const getAllOperatorName = () => {
  //   let newServices = JSON.parse(services);
  //   setOperator("");
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["configuration"]
  //       }${GET_ALL_OPERATORS_OPTIONS}?CountryIso=${Country}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setOperatorOptions(
  //         res?.data?.data?.items.map((x) => ({
  //           label: x?.name,
  //           value: x?.recordGuid,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       if (err === "error: 401") {
  //         this.getAllOperatorName();
  //       }
  //       // toast.error(
  //       //   err?.response?.data?.message ??
  //       //     "An Error Ocurred Retrieving Countries"
  //       // );
  //     })
  //     .finally(() => {
  //       // this.setState({ loading: false, resetPagination: false });
  //     });
  // };
  function removeEmptyKeys(obj) {
    let newObj = {};
    for (let key in obj) {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Name: Name,
      countryGuid: Country,
      IsActive: switchState,
      Mnc: mnc,
      Mcc: mcc
      // Mnc: mnc,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["configuration"]
        }${ADD_OPERATOR}`,
        removeEmptyKeys(data),
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });
        setRefreshTableOperators(true);
        setOpen(false);
        // setOpenAlert(true);
        setOperator("");
        setCountry("");
        setName("");
        setMnc("");
        setMcc("");

        setPaginationModelOperators({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    {
      !isEdit && setOperator("");
      setCountry("");
      setName("");
      setMnc("");
      setSwitchState(true);
      setMcc("");

    }
  }, [open]);

  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Name: Name,
      // countryGuid: Country,
      IsActive: switchState,
      // Mnc: mnc,
      Mnc: mnc,
      Mcc: mcc,
      recordGuid: selectedOperator?.recordGuid,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${newServices["configuration"]
        }${ADD_OPERATOR}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated", type: "success" });
        setRefreshTableOperators(true);
        setOpen(false);
        // setOpenAlert(true);
        setOperator("");
        setCountry("");
        setMcc("");

        setName("");
        setMnc("");
        setPaginationModelOperators({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitEdit();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (isEdit && selectedOperator) {
      setName(selectedOperator?.name);
      setCountry(selectedOperator?.country?.recordGuid);
      setMcc(selectedOperator?.mcCsString);
      setMnc(selectedOperator?.mnCsString);

      // setOperator(selectedOperator?.operator?.recordGuid);
      setSwitchState(selectedOperator?.isActive);
    }
  }, [selectedOperator, isEdit]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
  };
  const [Name, setName] = useState(isEdit ? selectedMNC?.cost : "");
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={isEdit ? submitEdit : submitAdd}
        disableFirstBtn={!isEdit ? Country === "" || Name === "" : Name === ""}
        content={
          <>
            {!isEdit && (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"MCC"}
                      value={mcc}
                      setValue={setMcc}
                      positiveNumber

                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"MNC"}
                      value={mnc}
                      setValue={setMnc}
                      positiveNumber

                    />
                  </Grid>
                </Grid>


                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={Country}
                      setValue={setCountry}
                      options={countryOptions}
                      placeholder={"Country"}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={Name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
            {/* <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={currencies}
                    setValue={setCurrencies}
                    options={currenciesOptions}
                    placeholder={"Currency"}
                  />
                </Grid>
              </Grid> */}
            {/* <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectFormNew
                  value={Operator}
                  setValue={setOperator}
                  options={OperatorOptions}
                  placeholder={"Operator"}
                  disabled={OperatorOptions?.length === 0}
                />
              </Grid>
            </Grid> */}

            {/* <Grid container>
              <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Status</span>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={switchState ? "Active " : "Inactive "}
                />
              </Grid>
            </Grid> */}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => handleClose()}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageOperators;
