import { Alert, FormControlLabel, Grid, Snackbar, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_COST_PLAN,
  ADD_MNC,
  GET_ALL_CRITERIA_CATEGORY,
  GET_ALL_OPERATORS_OPTIONS,
  MANAGE_REASONS,
  MANAGE_REASONS_UPDATE,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
const ManageReasons = ({
  open,
  setOpen,
  title,
  clientCategoryOptionsSearch,
  countryOptionsSearch,
  operatorOptionsSearch,
  providerOptionsSearch,
  isoCode,
  countryOptionsRes,
  setPaginationModel,
  setRefreshTable,
  setSmsOneWaySearch,
  selectedSms,
  paginationModel,
  isEdit,
  activeBtn,
}) => {
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [Name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [criteriaCategory, setCriteriaCategory] = useState("");
  const [criteriaCategoryOptions, setCriteriaCategoryOptions] = useState(
    
    activeBtn == "Routing" ? 
    [
      { label: "ROUTING_BLOCK_REASON", value: "ROUTING_BLOCK_REASON" },
      { label: "ROUTING_UNBLOCK_REASON", value: "ROUTING_UNBLOCK_REASON" },

      { label: "ROUTING_LOCK_REASON", value: "ROUTING_LOCK_REASON" },

      { label: "ROUTING_UNLOCK_REASON", value: "ROUTING_UNLOCK_REASON" },
    ] 
    :
    [
      { label: "BILLING_RATE_PLAN_LOCK_REASONS", value: "BILLING_RATE_PLAN_LOCK_REASONS" },
      { label: "BILLING_RATE_PLAN_UNLOCK_REASONS", value: "BILLING_RATE_PLAN_UNLOCK_REASONS" },
    ]
    
  );

  const [nameDetails, setNameDetails] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  // const getAllCriteriaCategory = () => {
  //   setCriteriaCategoryOptions(false);
  //   let service = JSON.parse(services);
  //   // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         service["configuration"]
  //       }${GET_ALL_CRITERIA_CATEGORY}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setCriteriaCategoryOptions(
  //         res?.data?.data?.criteriaCategories.map((country) => ({
  //           label: country?.name,
  //           value: country?.tag,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       if (err === "error: 401") {
  //         getAllCriteriaCategory();
  //       }
  //       // toast.error(
  //       //   err?.response?.data?.message ??
  //       //     "An Error Ocurred Retrieving Countries"
  //       // );
  //     })
  //     .finally(() => {
  //       // setState({ loading: false, resetPagination: false });
  //     });
  // };
  // useEffect(() => {
  //   getAllCriteriaCategory();
  // }, [open]);

  // const handleSwitchChange = () => {
  //   setSwitchState(!switchState);
  // };
  // const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  // const getAllOperatorName = () => {
  //   setIsLoadingOperator(true);

  //   let newServices = JSON.parse(services);
  //   {
  //     setOperator("");
  //   }
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["configuration"]
  //       }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${Country}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setIsLoadingOperator(false);

  //       setOperatorOptions(
  //         res?.data?.data?.items.map((x) => ({
  //           label: x?.name,
  //           value: x?.recordGuid,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       if (err === "error: 401") {
  //         setIsLoadingOperator(false);

  //         this.getAllOperatorName();
  //       }
  //       // toast.error(
  //       //   err?.response?.data?.message ??
  //       //     "An Error Ocurred Retrieving Countries"
  //       // );
  //     })
  //     .finally(() => {
  //       setIsLoadingOperator(false);

  //       // this.setState({ loading: false, resetPagination: false });
  //     });
  // };
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      name: Name,
      criteriaCategoryTag: criteriaCategory,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_REASONS}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Added !", type: "success" });
        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        // setOpenAlert(true);
        setName("");
        setNameDetails("");
        // setCountryName("");
        setDescription("");
        setCriteriaCategory("");

        // setSearchMNC("");
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      name: Name,
      criteriaCategoryTag: criteriaCategory,

      criteriaGuid: selectedSms?.recordGuid,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_REASONS_UPDATE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: data?.message, type: "success" });
        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        // setOpenAlert(true);
        setName("");
        setNameDetails("");
        // setCountryName("");
        setDescription("");
        setCriteriaCategory("");

        // setSearchMNC("");
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitEdit();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  useEffect(() => {
    console.log("assafasfafasaa", selectedSms);
    if (isEdit) {
      setName(selectedSms?.name);
      setNameDetails(selectedSms?.category?.name);
      setDescription(selectedSms?.category?.type?.description);
      setCriteriaCategory(selectedSms?.category?.tag);
    }
  }, [open]);

  // useEffect(() => {
  //   {
  //     !isEdit && setOperator("");
  //     setCurrencies("");
  //     setCountry("");
  //     setCountryName("");
  //     setName("");
  //     setSwitchState(true);
  //   }
  // }, [open]);

  // const submitEdit = () => {
  //   let newServices = JSON.parse(services);
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   MULTI_TENANCY_CONFIG.headers = {
  //     ...MULTI_TENANCY_CONFIG.headers,
  //   };
  //   let data = {
  //     recordGuid: selectedMNC?.recordGuid,
  //     OperatorGuid: Operator,
  //     mnc: Name,
  //     IsActive: switchState,
  //   };
  //   axiosInstance
  //     .put(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["configuration"]
  //       }${ADD_MNC}`,
  //       data,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then(({ data }) => {
  //       setOpenMessageAlert(true);
  //       setMessageData({ message: "Successfully Updated", type: "success" });
  //       setPaginationModelMNC({
  //         page: 0,
  //         pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
  //       });
  //       setOpen(false);
  //       // setOpenAlert(true);
  //       setOperator("");
  //       setCountry("");
  //       // setCountryName("");
  //       setName("");
  //     })
  //     .catch((error) => {
  //       setOpenMessageAlert(true);
  //      setMessageData({
  //         message: error?.response?.data?.Message
  //           ? error?.response?.data?.Message
  //           : error?.response?.data?.Exception
  //           ? error?.response?.data?.Exception
  //           : error?.response?.data?.message,
  //         type: "error",
  //       });;
  //     });
  // };

  // useEffect(() => {
  //   if (isEdit && selectedMNC) {
  //     console.log(
  //       "SAFsafasfasfasf",
  //       selectedMNC?.operator?.country?.recordGuid
  //     );
  //     console.log("SAFsafasfasfasfOptions", countryOptions);

  //     setCountry(selectedMNC?.operator?.country?.recordGuid);
  //     setCountryName(selectedMNC?.operator?.country?.name);
  //     setOperator(selectedMNC?.operator?.recordGuid);
  //     setSwitchState(selectedMNC?.isActive);
  //     setName(selectedMNC?.mnc);
  //   }
  // }, [selectedMNC]);

  // const [Name, setName] = useState(isEdit ? selectedMNC?.name : "");
  // console.log("selectedMNCselectedMNC", selectedMNC);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
  };
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={isEdit ? submitEdit : submitAdd}
        disableFirstBtn={
          Name === "" ||
          // nameDetails === "" ||
          criteriaCategory === "" 
          // description === ""
        }
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={Name}
                  setValue={setName}
                />
              </Grid>
            </Grid>

            {/* <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Category Name"}
                  value={nameDetails}
                  setValue={setNameDetails}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Description"}
                  value={description}
                  setValue={setDescription}
                />
              </Grid>
            </Grid> */}

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectFormNew
                  value={criteriaCategory}
                  setValue={setCriteriaCategory}
                  options={criteriaCategoryOptions}
                  placeholder={"Criteria Category"}
                  placementTop={true}
                  // disabled={isEdit}
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => handleClose()}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageReasons;
