import { useFormik } from "formik";
import { useState } from "react";
import {
  handleMessageError,
  removeNullKeys,
  updateState,
} from "../../../Utils/functions"; 
import {config} from "./config";

// AppContext.js
import { createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ADD_NEW_RESELLER, MULTI_TENANCY_CONFIG, SMTP_CONFIG, UPDATE_RESELLER } from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import validationSchema from "./validation";
import editConfigValidationSchema from "./editConfigValidation";
import editDetailsValidationSchema from "./editDetailsValidation";

const ResellerContext = createContext();

export const useResellerContext = () => {
  return useContext(ResellerContext);
};

export const ResellerProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [GlobalData, SetGlobalData] = useState({
    steps: location?.pathname?.includes("add-reseller") ? 
    [
      {
        label: "Account Information",
      },
      {
        label: "SMTP Config",
      },
      {
        label: "Client Config",
      },
      {
        label: "Login Config",
      },
      {
        label: "General Config",
      },
      {
        label: "Engagement",
      },
    ] 
    : location?.pathname?.includes("reseller-config") ? 
    [ 
      {
      label: "Client Config",
      },
      {
        label: "Login Config",
      },
      {
        label: "General Config",
      },
    ]
    :
    [
      {
        label: "Account Information",
      },
      {
        label: "SMTP Config",
      },
      {
        label: "Engagement",
      },
    ],
    activeStep: 0,
    alertData:{ message: "", type: "" },
    openMessageAlert: false,
    resellerId:null,
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      password: "",
      username: "",
      businesswebsite: "",
      phone: "",
      category: "",
      account_manager: "",
      country: "",
      street: "",
      city: "",
      region: "",
      state: "",
      zip: "",
      building: null,
      floor: "",
      room: "",
      address1: null,
      address2: null,
      Info: {
        IndustryTag: null,
      },


      Alerts: true,
      smtpConfigGuid:null,
      fromAddress: "",
      ReplyAddress: "",
      SmtpServer: "",
      SmtpUser: "",
      SmtpPassword: "",
      SmtpPort: "",
      Smtpssl: false,


      ALERTS_TOGGLE: false,
      ALERTS: "",
      SENDER_TOGGLE: false,
      SENDER: "",
      API_TOGGLE: false,
      API: "",


      SIGNUP: false,
      COPYRIGHTS_TOGGLE: false,
      COPYRIGHTS: "",
      DEFAULT_SIGNIN: false,
      KYC_ENABLED: false,
      TERMS_CONDITIONS_ENABLED: false,
      GOOGLE: false,
      GITHUB: false,
      BANNER: false,
      BANNER_IMAGE: null, // Placeholder for uploaded banner image file
      PRIMARY_TEXT: "",
      SECONDARY_TEXT: "",
      BACKGROUND_COLOR: "", // Set your initial background color if needed
      GOOGLE_ANALYTICS: "",
      GOOGLE_TAG: "",
      NOTIFICATION_ALERT: false,
      BACKGROUND_THEME: "LIGHT",


      BusinessWebUrl: "",
      PORTAL_NAME: "",
      MAIN_LOGO: null, // Placeholder for uploaded main logo image file
      FAVICON_LOGO: null, // Placeholder for uploaded favicon logo image file
      DIRECTION: "left", // Default login alignment
      PRIMARY_COLOR: "#ed204c", // Default primary color
      SECONDARY_COLOR: "#000000", // Default secondary color

      

      BillingEmail: null,
      TechnicalEmail: null,
      AlertsEmail: null,
      logoUrl: null,
    },
    validationSchema: location?.pathname?.includes("add-reseller") 
                      ? validationSchema[GlobalData?.activeStep] 
                      : location?.pathname?.includes("reseller-config") 
                        ? editConfigValidationSchema[GlobalData?.activeStep]
                        : editDetailsValidationSchema[GlobalData?.activeStep] ,
    onSubmit: async (values) => {
      if (GlobalData?.activeStep === GlobalData?.steps?.length - 1) {
        if (location?.pathname?.includes("add-reseller")){
          let data = {
            TitleTag: "MR",
            TypeTag: "RESELLER",
            Username: values["username"],
            Password: values["password"],
            CategoryId: values["category"],
            Name: values["firstName"],
            logoUrl: values["logoUrl"],
            PolicyId: process.env.REACT_APP_RESELLER_ID,
            Contacts: [
              {
                TelephoneNumber: values["phone"],
                MobileNumber: values["phone"],
                Email: values["email"],
                CountryGuid: values["country"],
                ContactType: "HOME",
                FirstName: values["firstName"],
                LastName: values["lastName"],
                CompanyWebsite: values["businesswebsite"],
                CompanyName: values["businesswebsite"],
              },
            ],
            Addresses: [
              {
                Street: values["street"] || null,
                CityGuid: values["city"] || null,
                Region: values["region"] || null,
                State: values["state"] || null,
                Zip: values["zip"] || null,
                Building: values["building"] || null,
                Floor: values["floor"] || null,
                RoomNumber: values["room"] || null,
                Address1: values["address1"] || null,
                Address2: values["address2"] || null,
              },
            ],
            Config: [
              ...config?.config?.map((item) => {
                return {
                  ...item,
                  ParameterTag: item?.ParameterName,
                  ParameterValue:
                    `${formik?.values[item?.ParameterName] || item?.ParameterValue}`
                };
              }),
            ],
            Info: {
              BillingEmail: values?.BillingEmail,
              TechnicalEmail: values?.TechnicalEmail,
              AlertsEmail: values?.AlertsEmail,
              BusinessWebUrl: values?.BusinessWebUrl,
            },
          };
  
          if (formik?.values?.Alerts) {
            data["SmtpConfiguration"] = {
              fromAddress: values?.fromAddress,
              ReplyAddress: values?.ReplyAddress,
              SmtpServer: values?.SmtpServer,
              SmtpUser: values?.SmtpUser,
              SmtpPassword: values?.SmtpPassword,
              SmtpPort: values?.SmtpPort,
              SmtpSsl: values?.Smtpssl,
            };
          }
  
          try {
  
            let services = localStorage.getItem("services");
            let service = JSON.parse(services);
  
            MULTI_TENANCY_CONFIG.headers.Authorization =
            "Bearer " + localStorage.getItem("token");
            MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  
            let recordResponse = await axiosInstance
              .post(
                `${localStorage.getItem("baseUrl")}${
                  service["member"]
                }${ADD_NEW_RESELLER}`,
                removeNullKeys(data),
                MULTI_TENANCY_CONFIG
              )
                
            if (recordResponse?.data?.success) {
              SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
              SetGlobalData(updateState(GlobalData, "alertData", {message: "Reseller added successfully", type: "success"}));
             
              SetGlobalData(updateState(GlobalData, "path", "main"));
              navigate("/reseller-management",{replace: true})
            }
          } catch (e) {
            SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
            SetGlobalData(updateState(GlobalData, "alertData", {message: handleMessageError({ e, type: "validation" }), type: "error"}));
          }
        } else if (location?.pathname?.includes("reseller-config")) {
          await updateResellerConfig(values);
        } else {
          await updateResellerDetails(values);
        }
        
      } 
      else
        SetGlobalData(updateState(GlobalData, "activeStep", GlobalData?.activeStep + 1));
    },
  });

  const updateResellerConfig = async (values)=>{
    let data = {
      RecordGuid: GlobalData?.resellerId,
      CountryGuid: values["country"],
      Config: [
        ...config?.config?.map((item) => {
          return {
            ...item,
            ParameterTag: item?.ParameterName,
            ParameterValue:
              `${formik?.values[item?.ParameterName] || item?.ParameterValue}`
          };
        }),
      ],
      Info: {
        BusinessWebUrl: values?.BusinessWebUrl,
      },
    };

    try {

      let services = localStorage.getItem("services");
      let service = JSON.parse(services);

      MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let recordResponse = await axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            service["member"]
          }${UPDATE_RESELLER}`,
          removeNullKeys(data),
          MULTI_TENANCY_CONFIG
        )
          
      if (recordResponse?.data?.success) {
        SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
        SetGlobalData(updateState(GlobalData, "alertData", {message: "Reseller updated successfully", type: "success"}));
        SetGlobalData(updateState(GlobalData, "resellerId", null));
       
        SetGlobalData(updateState(GlobalData, "path", "main"));
        navigate("/reseller-management",{replace: true})
      }
    } catch (e) {
      SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
      SetGlobalData(updateState(GlobalData, "alertData", {message: handleMessageError({ e, type: "validation" }), type: "error"}));
    }
  }

  const updateResellerDetails = async (values)=>{
    let data = {
      RecordGuid: GlobalData?.resellerId,
      
      Name: values["firstName"],
      FirstName: values["firstName"],
     
      CountryGuid: values["country"],
      CompanyWebsite: values["businesswebsite"],

      Address: 
        {
          Street: values["street"] || null,
          CityGuid: values["city"] || null,
          Region: values["region"] || null,
          State: values["state"] || null,
          Zip: values["zip"] || null,
          Building: values["building"] || null,
          Floor: values["floor"] || null,
          RoomNumber: values["room"] || null,
          Address1: values["address1"] || null,
          Address2: values["address2"] || null,
        },
      Info: {
        BillingEmail: values?.BillingEmail,
        TechnicalEmail: values?.TechnicalEmail,
        AlertsEmail: values?.AlertsEmail,
        BusinessWebUrl: values?.BusinessWebUrl,
      },
    };

    let smtpData = {
      FromAddress: values?.fromAddress,
      ReplyAddress: values?.ReplyAddress,
      SmtpServer: values?.SmtpServer,
      SmtpUser: values?.SmtpUser,
      SmtpPassword: values?.SmtpPassword,
      SmtpPort: values?.SmtpPort,
      SmtpSsl: values?.Smtpssl,
      smtpConfigurationGuid: values?.smtpConfigGuid
    }


    try {

      let services = localStorage.getItem("services");
      let service = JSON.parse(services);

      MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let recordResponse = await axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            service["member"]
          }${UPDATE_RESELLER}`,
          removeNullKeys(data),
          MULTI_TENANCY_CONFIG
        )

      if (formik?.values?.Alerts && values?.smtpConfigGuid) {
        let configResponse = await axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${SMTP_CONFIG}`,
          removeNullKeys(smtpData),
          MULTI_TENANCY_CONFIG
        )  
      } else if (!formik?.values?.Alerts && values?.smtpConfigGuid){
        let deleteResponse = await axiosInstance
        .delete(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${SMTP_CONFIG}`,
          {
            headers :{
              Authorization: "Bearer " + localStorage.getItem("token"),
              Tenant : localStorage.getItem("tenant")
            }, 
            data : {RecordGuid: values?.smtpConfigGuid}
          }
        )  
      } else if (formik?.values?.Alerts && !values?.smtpConfigGuid) {
        delete smtpData.smtpConfigurationGuid;
        smtpData["ClientGuid"] = GlobalData?.resellerId;
        let addResponse = await axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            service["configuration"]
          }${SMTP_CONFIG}`,
          removeNullKeys(smtpData),
          MULTI_TENANCY_CONFIG
        )  
      }
          
      if (recordResponse?.data?.success) {
        SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
        SetGlobalData(updateState(GlobalData, "alertData", {message: "Reseller updated successfully", type: "success"}));
        SetGlobalData(updateState(GlobalData, "resellerId", null));
       
        SetGlobalData(updateState(GlobalData, "path", "main"));
        navigate("/reseller-management",{replace: true})
      }
    } catch (e) {
      SetGlobalData(updateState(GlobalData, "openMessageAlert", true));
      SetGlobalData(updateState(GlobalData, "alertData", {message: handleMessageError({ e, type: "validation" }), type: "error"}));
    }
  }

  return (
    <ResellerContext.Provider value={{ formik, GlobalData, SetGlobalData }}>
      {children}
    </ResellerContext.Provider>
  );
};
