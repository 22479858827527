import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import {
  GET_ALL_CRITERIA_TYPE,
  MANAGE_CRITERIA_CATEGORY,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

const ManageCriteriaCategory = ({
  open,
  setOpen,
  title,
  isEdit,
  selectedCategory,
  CountryOptions,
  setRefreshTableCity,
  setPaginationModalCategory,
  setRefreshTableCategory,
}) => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const [Country, setCountry] = useState("");
  const [switchState, setSwitchState] = useState(false);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [criteriaType, setCriteriaType] = useState("");
  const [criteriaTypeOptions, setCriteriaTypeOptions] = useState(false);

  const getAllCriteriaType = () => {
    setCriteriaTypeOptions(false)
    let newServices = JSON.parse(services);
    {
      !isEdit && setCriteriaType("");
    }
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CRITERIA_TYPE}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCriteriaTypeOptions(
          res?.data?.data?.criteriaTypes.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCriteriaType();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  useEffect(() => {
    getAllCriteriaType();
    {
      !isEdit && setName("");
      setCriteriaType("");
      setId("");
      setSwitchState(false);
    }
    {
      isEdit && setName(selectedCategory?.name);
      setSwitchState(selectedCategory?.isActive);
      setId(selectedCategory?.id);
    }
  }, [open, isEdit]);
  useEffect(() => {
    if (isEdit && selectedCategory) {
      setName(selectedCategory?.name);
      setSwitchState(selectedCategory?.isActive);
      setId(selectedCategory?.id);
    }
  }, [selectedCategory, isEdit, open]);
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      id: id,
      name: name,
      isEditable: switchState,
      criteriaTypeGuid: criteriaType,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CRITERIA_CATEGORY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });
        setPaginationModalCategory({
          page: 0,
          pageSize: 10,
        });
        setOpen(false);
        setName("");
        setCriteriaType("");
        setId("");
        setSwitchState(false);
      })
      .catch((error) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
  ? error?.response?.data?.Message:
  error?.response?.data?.Exception?error?.response?.data?.Exception
  : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      id: id,
      name: name,
      // isEditable: switchState,
      criteriaCategoryGuid: selectedCategory?.recordGuid,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CRITERIA_CATEGORY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });
        setPaginationModalCategory({
          page: 0,
          pageSize: 10,
        });
        setOpen(false);
        setName("");
        setCriteriaType("");
        setId("");
        setSwitchState(false);
        // setSmsOneWaySearch("");
      })
      .catch((error) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
  ? error?.response?.data?.Message:
  error?.response?.data?.Exception?error?.response?.data?.Exception
  : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={
          !isEdit
            ? name === "" || criteriaType === "" || id === ""
            : name === "" || id === ""
        }
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"ID"}
                  value={id}
                  setValue={setId}
                  positiveNumber={true}
                />
              </Grid>
            </Grid>
            {!isEdit && (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={criteriaType}
                      setValue={setCriteriaType}
                      options={criteriaTypeOptions}
                      placeholder={"Criteria Type"}
                      disabled={criteriaTypeOptions?.length === 0}
                    />
                  </Grid>
                </Grid>

                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Editable
                </span>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchState}
                          onChange={handleSwitchChange}
                        />
                      }
                      // label={switchState ? "Active " : "Inactive "}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageCriteriaCategory;
