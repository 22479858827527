import { Alert, Grid, IconButton, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import CloseIcon from "@mui/icons-material/Close";
import { MANAGE_PROVIDER_CATEGORY, MULTI_TENANCY_CONFIG } from "../../APIs";
import axiosInstance from "../../../axiosInstance";

const ManageProviderCategory = ({
  open,
  setOpen,
  title,
  isEdit,
  selectedProviderCategory,
  setPaginationModel,
  paginationModel
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  let services = localStorage.getItem("services");

  useEffect(() => {
    if (open&&!isEdit ) {
      setName("");
      setDescription("");
    }
  }, [ isEdit, open]);


  useEffect(() => {
    if (open&&isEdit ) {
      setName(selectedProviderCategory?.providerCategoryDetails[0]?.name);
      setDescription(selectedProviderCategory?.providerCategoryDetails[0]?.description);
    }
  }, [selectedProviderCategory, isEdit, open]);


  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      "ProviderCategoryDetails": [
        {
          "LanguageCode": "en",
          "Name": name,
          "Description": description
        }
      ]
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${MANAGE_PROVIDER_CATEGORY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });

        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        setName("");
        setDescription("");
   
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      "RecordGuid": selectedProviderCategory?.recordGuid,
      "ProviderCategoryDetails": [
          {
              "LanguageCode": "en",
              "Name": name,
              "Description": description
          }
      ]
  }
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${MANAGE_PROVIDER_CATEGORY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });
        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        setName("");
        setDescription("");
        // setSmsOneWaySearch("");
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        // secondActionBtnName={"Cancel"}
        disableFirstBtn={name === "" || description === ""}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Description"}
                  value={description}
                  setValue={setDescription}
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageProviderCategory;
