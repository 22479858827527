import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_SMS_ONE_WAY,
  MANAGE_EXCHANGE_RATES_ADD,
  MANAGE_EXCHANGE_RATES_UPDATE,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";

const ManageExchangeRates = ({
  open,
  setOpen,
  title,
  isEdit,
  clientAccountsOptions,
  currenciesOptions,
  setPaginationModalExchangeRates,
  selectedExchangeRates,
  paginationModalExchangeRates,
  systemCurrency,

  // isoCode,
}) => {
  const [ClientCategory, setClientCategory] = useState("");
  const [isoCode, setIsoCode] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  // const [ClientCategoryOptions, setClientCategoryOptions] = useState([
  //   "category 1",
  //   "category 2",
  //   "category 3",
  //   "category 4",
  // ]);
  const [Operator, setOperator] = useState("");
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);
  const [Provider, setProvider] = useState(
    isEdit ? selectedExchangeRates?.providerGuid : ""
  );
  // const [ProviderOptions, setProviderOptions] = useState([
  //   "Provider 1",
  //   "Provider 2",
  //   "Provider 3",
  //   "Provider 4",
  // ]);
  const [currencies, setCurrencies] = useState("");
  const [clientAccount, setClientAccount] = useState("");
  const [rate, setRate] = useState(1);
  const [nextRate, setNextRate] = useState("");
  const [rateInverse, setRateInverse] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // const [SourceproviderOptions, setSourceproviderOptions] = useState([
  //   "Sourceprovider 1",
  //   "Sourceprovider 2",
  //   "Sourceprovider 3",
  //   "Sourceprovider 4",
  // ]);
  const [SourceOperator, setSourceOperator] = useState("");
  // const [SourceOperatorOptions, setSourceOperatorOptions] = useState([
  //   "SourceOperator 1",
  //   "SourceOperator 2",
  //   "SourceOperator 3",
  //   "SourceOperator 4",
  // ]);
  const [switchState, setSwitchState] = useState(
    selectedExchangeRates ? selectedExchangeRates?.isBlocked : false
  );
  const [switchStateLock, setSwitchStateLock] = useState(
    selectedExchangeRates ? selectedExchangeRates?.isLocked : false
  );
  const [services, setServices] = useState(localStorage.getItem("services"));

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const handleSwitchChangeLock = () => {
    setSwitchStateLock(!switchStateLock);
  };
  function isPositiveNumber(value) {
    if (/^\d*\.?\d+$/.test(value) && parseFloat(value) > 0) {
      setRate(value);
      setRateInverse(1 / value)
    }
    if (value === "") {
      setRate("");
      setRateInverse("")

    }
  }
  const ConvertToTimesStmp = (date) => {
    const specificDate = new Date(date); // Specific date
    const timestamp = specificDate.getTime(); // Timestamp in milliseconds
    return timestamp;
    // console.log(timestamp);
  };
  //   useEffect(() => {
  //     if (isEdit && selectedExchangeRates) {
  //       setProvider(selectedExchangeRates?.providerGuid);
  //       setSwitchStateLock(selectedExchangeRates?.isLocked);
  //       setSwitchState(selectedExchangeRates?.isBlocked);
  //     }
  //   }, [selectedExchangeRates, isEdit, open]);
  //   useEffect(() => {
  //     // setOpenMessageAlert(true);
  //   }, [messageData]);

  useEffect(() => {
    setCurrencies("");
    // setClientAccount("");
    setRate("");
    setRateInverse("");
    setNextRate("");
    setEffectiveDate("");
    // setStartDate("");
    // setEndDate("");
  }, [open]);

  useEffect(() => {
    {
      !isEdit && setCurrencies("");
      // setClientAccount("");
      setRate("");
      setRateInverse("");
      setNextRate("");
      setEffectiveDate("");
      // setStartDate("");
      // setEndDate("");
    }
    {
      isEdit && selectedExchangeRates && setCurrencies(selectedExchangeRates?.currencyRecordGuid);
      // setClientAccount(selectedExchangeRates?.clientAccountRecordGuid);
      selectedExchangeRates?.currentRate && setRate(selectedExchangeRates?.currentRate);
      setRateInverse(selectedExchangeRates?.currentRateInverse);
      setNextRate(selectedExchangeRates?.newRate);
      setEffectiveDate(selectedExchangeRates?.effectiveDate?.split("T")[0]);
      // setStartDate(selectedExchangeRates?.startDate?.split("T")[0]);
      // setEndDate(selectedExchangeRates?.endDate?.split("T")[0]);
    }
  }, [open, isEdit, selectedExchangeRates]);

  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };

    let data = {
      CurrencyGuid: currencies,
      // SystemCurrencyGuid: systemCurrency,
      // ClientAccountGuid: clientAccount,      
      Rate: rate,
      CurrentRateInverse: rateInverse,
      // NextRate: nextRate,
      // EffectiveDate: ConvertToTimesStmp(effectiveDate),
      // StartDate: ConvertToTimesStmp(startDate),
      // EndDate: ConvertToTimesStmp(endDate),
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["billing"]
        }${MANAGE_EXCHANGE_RATES_ADD}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: data?.message || "Successfully Added", type: "success" });

        setPaginationModalExchangeRates({
          page: 0,
          pageSize: paginationModalExchangeRates?.pageSize
            ? paginationModalExchangeRates?.pageSize
            : 10,
        });
        setOpen(false);
        setCurrencies("");
        // setClientAccount("");
        setRate("");
        setRateInverse("");
        setNextRate("");
        setEffectiveDate("");
        // setStartDate("");
        // setEndDate("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };

    let data = {
      ExchangeRateGuid: selectedExchangeRates?.recordGuid,
      // CurrencyGuid: currencies,
      // SystemCurrencyGuid: "c9144f02-2885-437d-97be-b450d158a556",
      // ClientAccountGuid: clientAccount,
      CurrentRate: rate,
      CurrentRateInverse: rateInverse,
      NewRate: nextRate,
      EffectiveDate: effectiveDate == null ? "" : ConvertToTimesStmp(effectiveDate),
      // StartDate: ConvertToTimesStmp(startDate),
      // EndDate: ConvertToTimesStmp(endDate),
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${newServices["billing"]
        }${MANAGE_EXCHANGE_RATES_UPDATE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({message: data?.message || "Successfully Updated !", type: "success" });
        setPaginationModalExchangeRates({
          page: 0,
          pageSize: paginationModalExchangeRates?.pageSize
            ? paginationModalExchangeRates?.pageSize
            : 10,
        });
        setOpen(false);
        setCurrencies("");
        // setClientAccount("");
        setRate("");
        setRateInverse("");
        setNextRate("");
        setEffectiveDate("");
        // setStartDate("");
        // setEndDate("");
        // setSmsOneWaySearch("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleStartDateChange = (value) => {
    if (endDate && endDate < value) {
      setStartDate(value);

    } else {
      setStartDate(value);

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  const handleEndDateChange = (value) => {
    if (startDate < value) {
      setEndDate(value);

    } else {
      setEndDate("");

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  return (
    <>
      {console.log("start date", startDate)}
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={!isEdit ?
          currencies === "" ||
          // clientAccount === "" ||
          rate === "" ||
          rateInverse === "" :
          currencies === "" ||
          // clientAccount === "" ||
          rate === "" ||
          rateInverse === "" ||
          nextRate === "" && effectiveDate === ""
          // // startDate === "" ||
          // // endDate === "" ||
          // effectiveDate === ""
        }
        content={
          <>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectFormNew
                  value={currencies}
                  setValue={setCurrencies}
                  options={currenciesOptions}
                  placeholder={"Currency"}
                  disabled={currenciesOptions.length === 0}
                />
              </Grid>
            </Grid>
            <Grid container>
              {/* <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={clientAccount}
                    setValue={setClientAccount}
                    options={clientAccountsOptions}
                    placeholder={"Client Account"}
                    disabled={clientAccountsOptions.length === 0}
                  />
                </Grid> */}
            </Grid>

            <Grid container spacing={2}>
              <Grid className="dialog-grid-spacing" item xs={6}>
                <TextFieldForm
                  placeholder={"Current Rate"}
                  value={rate}
                  onChangeFct={(e) => {
                    isPositiveNumber(e);
                    // setRateInverse(e!==""&&1 / e);
                  }}
                  // setValue={setRate}
                  type="number"
                // positiveNumberFloat={true}
                />
              </Grid>

              <Grid className="dialog-grid-spacing" item xs={6}>
                <TextFieldForm
                  placeholder={"Rate Inverse"}
                  value={rateInverse}
                  setValue={setRateInverse}
                  // positiveNumberFloat={true}
                  disabled={true}
                />
              </Grid>
            </Grid>

            {isEdit &&
              <Grid container spacing={2}>
                <Grid className="dialog-grid-spacing" item xs={6}>
                  <TextFieldForm
                    placeholder={"Next Rate"}
                    value={nextRate}
                    // setValue={setNextRate}
                    onChangeFct={(e) => {
                      if (/^\d*\.?\d+$/.test(e) && parseFloat(e) > 0) {
                        setNextRate(e)
                      }
                      else {
                        setNextRate("")
                      }
                      // setRateInverse(e!==""&&1 / e);
                    }}
                    // setValue={setRate}
                    type="number"
                  />
                </Grid>

                <Grid className="dialog-grid-spacing" item xs={6}>
                  <TextFieldForm
                    placeholder={"Effective Date"}
                    value={effectiveDate}
                    setValue={setEffectiveDate}
                    type="date"
                  />
                </Grid>
              </Grid>
            }
            {/*   <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    placeholder={"Start Date"}
                    value={startDate}
                    // setValue={setStartDate}
                    onChangeFct={handleStartDateChange}
                    type="date"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    placeholder={"End Date"}
                    value={endDate}
                    // setValue={setEndDate}
                    onChangeFct={handleEndDateChange}
                    type="date"
                  />
                </Grid>
              </Grid> */}

          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageExchangeRates;
