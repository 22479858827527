import Add from "@mui/icons-material/Add";
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Typography
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import ManageRequest from './ManageRequest'
const UrlStore = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  // UrlStore
  const [isModalOpenSwitchUrlStore, setModalOpenUrlStore] = useState(false);
  const [isCheckedSwitchUrlStore, setCheckedSwitchUrlStore] = useState(false);
  const [switchStatesUrlStore, setswitchStatesUrlStore] = useState({});
  const handleSwitchChangeSwitchUrlStore = (rowId) => {
    setModalOpenUrlStore(true);
    setCheckedSwitchUrlStore(rowId);
  };

  const handleModalCloseSwitchUrlStore = () => {
    setModalOpenUrlStore(false);
  };
  const handleModalConfirmSwitchUrlStore = () => {
    setModalOpenUrlStore(false);
    // setCheckedSwitchUrlStore(!isCheckedSwitchUrlStore);
    setswitchStatesUrlStore((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchUrlStore]: !prevStates[isCheckedSwitchUrlStore],
    }));
  };

  //

 

  const [Client, setClient] = React.useState("9mobile");

  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [Category, setCategory] = React.useState("9mobile");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const tableDataUrlStore = [
  ];
  let columnsUrlStore = [
    {
      field: "longUrl",
      headerName: "Long URL",
      flex: 1,
    },
    {
      field: "shortUrl",
      headerName: "Short URL",
      flex: 1,
    },
    {
      field: "client",
      headerName: " Client",
      flex: 1,
    },
    {
      field: "numberOfClicks",
      headerName: "Number of clicks",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesUrlStore[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchUrlStore(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  // Delete Modal UrlStore functions
  const [ConfirmOpenUrlStore, setConfirmOpenUrlStore] = useState(false);
  const [modalContentUrlStore, setmodalContentUrlStore] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalUrlStore = (title, text, onButtonClick) => {
    setmodalContentUrlStore({ title, text, onButtonClick });
    setConfirmOpenUrlStore(true);
  };

  const handleConfirmModalUrlStore = () => {
    setConfirmOpenUrlStore(false);
  };

  //
  // Manage Routing
  const [ManageRequestAddopen, setManageRequestAddopen] = useState(false);
  const [ManageRequestEditopen, setManageRequestEditopen] = useState(false);

  const handleAddManageRequestopen = () => {
    setManageRequestAddopen(true);
  };

  const handleAddMangeRequestclose = () => {
    setManageRequestAddopen(false);
  };
  const handleEditManageRequestopen = () => {
    setManageRequestEditopen(true);
  };

  const handleEditMangeRequestclose = () => {
    setManageRequestEditopen(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <Typography className="BreadcrumbsPage">URL Store</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddManageRequestopen}
            >
              <Add sx={{ fontSize: "17px"}}  /> &nbsp;
              <Typography sx={{ fontSize: "14px",marginRight: "3px"  }}>Request URL</Typography>
            </Button>
        </Grid>
      </Grid>
      <Grid container className="Top-spacing div-table">
        <Grid item xs={12}>
            <Paper variant="CardTable" sx={{borderTopLeftRadius:'20px'}}>
              <Table
                rowCountState={tableDataUrlStore?.totalCount}
                data={tableDataUrlStore}
                // isLoading={isLoading}
                columns={columnsUrlStore}
                pageIndex={
                  tableDataUrlStore?.pageIndex ? tableDataUrlStore?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalUrlStore}
                editFunction={handleEditManageRequestopen}
                enableSearch={true}
                enableExportBtn={true}
                valueFilter={Client}
                setValueFilter={setClient}
                optionsFilter={["9mobile", "hassan", "test2"]}
                // placeholderFilter="Client"
                enableFilter={true}
                // enableFilterBtn={true}
              />
          </Paper>
        </Grid>
      </Grid>

      {/* Delete UrlStore Modal */}
      {handleConfirmModalUrlStore && (
        <ConfirmationModal
          open={ConfirmOpenUrlStore}
          onClose={handleConfirmModalUrlStore}
          title="Delete"
          text="Are you sure you want to delete this URL store?"
          onButtonClick={handleConfirmModalUrlStore}
        />
      )}
     

      {/* Manage UrlStore Modal */}
      {handleAddManageRequestopen && (
        <ManageRequest
          open={ManageRequestAddopen}
          setOpen={setManageRequestAddopen}
          title={"Request URL"}
        />
      )}
      {handleEditManageRequestopen && (
        <ManageRequest
          open={ManageRequestEditopen}
          setOpen={setManageRequestEditopen}
          title={"Request URL"}
        />
      )}

      {/* Swtich UrlStore Modal */}
      {isModalOpenSwitchUrlStore && (
        <ConfirmationModal
          open={isModalOpenSwitchUrlStore}
          onClose={handleModalCloseSwitchUrlStore}
          title="Change Status"
          text="Are you sure you want to change this URL Store Status?"
          onButtonClick={handleModalConfirmSwitchUrlStore}
        />
      )}
      <div>
      </div>
    </div>
  );
};

export default UrlStore;
