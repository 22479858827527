import { Add, SearchOutlined, SupervisorAccount } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GetAppIcon from "@mui/icons-material/GetApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LockIcon from "@mui/icons-material/Lock";
import LoginIcon from "@mui/icons-material/Login";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import SwipeDownIcon from "@mui/icons-material/SwipeDown";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Slide,
  Snackbar,
  SnackbarContent,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import MuiPagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import * as React from "react";
import AdvancedSearch from "../AdvancedSearch/AdvancedSearch";
import CustomizedDialogs from "../Dialog/Dialog";
import SelectFormNew from "../Selects/SelectFormNew";
import SelectForm from "../Selects/SelectsForm";
import HorizantalTabs from "../Tabs/ChannelTabs";
import TableTabs from "../Tabs/TableTabs";
export default function CustomPaginationGrid({
  rowCountState,
  data,
  isLoading,
  columns,
  setPaginationModel,
  paginationModel,
  addBtnName,
  setSearch,
  search,
  enableSearch,
  enableExportBtn,
  enableImportBtn,
  enableView,
  enableDelete,
  enableEdit,
  viewFunction,
  deleteFunction,
  editFunction,
  exportFunction,
  ImportFunction,
  enableFilter,
  selected,
  valueFilter,
  setValueFilter,
  onChangeFilter,
  optionsFilter,
  placeholderFilter,
  disableActions,
  enableFilterBtn,
  filterFunction,
  advancedSearch,
  advancedSearchFunction,
  contentAdvancedSearch,
  titleAdvancedSearch,
  moreFunction,
  enableMore,
  enableFilter2,
  disableHeaderColor,
  stopMinHeight,
  handleSearchBtn,
  enableCheckboxSelection,
  rowsSelectedData,
  setRowsSelectedData,
  handleResetAll,
  setSelectedRows,
  selectedRows,
  horizontalTabsOptions,
  onTabChange,
  enableSetLogin,
  loginFunction,
  enableResetPassword,
  resetPasswordFunction,
  enableManageConfig,
  manageConfigFunction,
  showFunction,
  largeActions,
  enableShow,
  enableAssign,
  assignFunction,
  unAssignFunction,
  enableAdd,
  addFunction,
  enableViewClients,
  viewClientsFunction,
  AddBankingDetailsFunction,
  enableAddBankingDetails
}) {
  const [openAdvancedSearchModal, setOpenAdvancedSearchModal] =
    React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  let taxesTabs = [{ label: "label 1" }, { label: "label 2" }];
  const handleChange = (event) => {
    setChecked(event.target.checked);
    localStorage.setItem("dontShow", event.target.checked);
  };

  const advancedSearchAll = () => {
    advancedSearchFunction();
    {
      !checked && setOpenAdvancedSearchModal(true);
      localStorage.setItem("statusFilter", advancedSearch ? "true" : "false");
    }
    // localStorage.setItem("filterExpanded", "true");
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    margin: "85px auto",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  function SlideTransition(props) {
    const transitionDuration = 800;

    return (
      <Slide
        {...props}
        direction="left"
        timeout={{ enter: transitionDuration, exit: transitionDuration }}
      />
    );
  }
  function getDataByIndices(dataArray, indexArray) {
    // Convert the index values to numbers for array access
    const indices = indexArray.map(Number);
    // Filter the array based on the specified indices
    const filteredData = indices.map((index) => dataArray[index]);
    setRowsSelectedData(filteredData);
  }
  const handlePageSizeChange = (newPageSize) => {
    if (paginationModel?.pageSize !== newPageSize?.pageSize) {
      setPaginationModel({
        ...paginationModel,
        page: 0,
        pageSize: newPageSize?.pageSize,
      });
    } else {
      setPaginationModel({
        ...paginationModel,
        page: newPageSize?.page,
        pageSize: newPageSize?.pageSize,
      });
    }

    // Update pagination state and set page to 0 to go to the first page
  };
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          <Typography style={{ color: "#595959", fontWeight: "bold" }}>
            No Rows
          </Typography>
        </Box>
      </StyledGridOverlay>
    );
  }
  const RowsPerPage = paginationModel?.pageSize
    ? paginationModel?.pageSize
    : 10;
  const totalPages = Math.ceil(rowCountState / RowsPerPage);

  function Pagination({ page, onPageChange, className }) {
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={totalPages}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }
  function CustomPagination(props) {
    return (
      <GridPagination
        showFirstButton
        showLastButton
        ActionsComponent={Pagination}
        {...props}
      />
    );
  }
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAdvancedSearchModal(false);
    localStorage.setItem("filterExpanded", "false");
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Grid container>
        <Grid xl={3} lg={3} xs={12} item>
          {enableFilter && (
            <Box sx={{ width: "80%!important" }}>
              <SelectFormNew
                value={valueFilter}
                setValue={setValueFilter}
                onChange={(e) => setValueFilter(e)}
                options={optionsFilter}
                placeholder={placeholderFilter}
                disabled={optionsFilter && optionsFilter?.length === 0}
                selected={selected}
              />
            </Box>
          )}

          {enableFilter2 && (
            <Box sx={{ width: "80%!important" }}>
              <SelectFormNew
                value={valueFilter}
                setValue={setValueFilter}
                onChange={onChangeFilter}
                options={optionsFilter}
                placeholder={placeholderFilter}
                searchIcon={true}
              />
            </Box>
          )}
        </Grid>
        <Grid lg={2} xl={2} xs={12} item></Grid>
        <Grid
          item
          style={{
            margin: "23px 0px 20px 0px",
            textAlign: "right",
            // display: windowWidth < 800 && "grid",
            // gap: windowWidth < 800 && "10px",
          }}
          xl={7}
          lg={7}
          xs={12}
          className="end"
        >
          {advancedSearchFunction && (
            <Button
              // variant="outline"
              // className="outline"
              onClick={advancedSearchAll}
              sx={{
                width: "40px!important",
                marginRight: "10px",
                border: `1px solid ${
                  localStorage.getItem("primary") || "#c41035"
                }`,
                borderRadius: "25px",
              }}
              variant="outline"
              className="outline"
            >
              {" "}
              <FilterAltIcon sx={{ fontSize: "17px" }} /> &nbsp;
              {/* <Typography style={{ fontSize: "14px" }}>Export</Typography> */}
            </Button>
          )}

          {enableFilterBtn && (
            <Grid container>
              <Box sx={{ width: "auto" }}>
                <Button
                  variant="outline"
                  className="outline"
                  // onClick={advancedSearchAll}
                  sx={{
                    width: windowWidth > 800 && "130px!important",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                  <FilterAltIcon sx={{ fontSize: "17px" }} /> &nbsp;
                  <Typography style={{ fontSize: "14px" }}>
                    {windowWidth > 800 && "Filter"}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          )}
          {enableImportBtn && (
            <Box sx={{ width: "auto" }}>
              <Button
                variant="outline"
                className="outline"
                onClick={ImportFunction}
                sx={{
                  width: windowWidth > 1000 ? "130px!important" : "30px !important",
                  marginRight: "10px",
                }}
              >
                {" "}
                <GetAppIcon sx={{ fontSize: "17px" }} /> &nbsp;
                <Typography style={{ fontSize: "14px" }}>
                  {windowWidth > 800 && "Import"}
                </Typography>
              </Button>
            </Box>
          )}
          {enableExportBtn && (
            <Box sx={{ width: "auto" }}>
              <Button
                variant="outline"
                className="outline"
                onClick={exportFunction}
                sx={{
                  width:
                    windowWidth > 800 ? "130px !important" : "50px !important",
                  marginRight: "10px",
                }}
              >
                {" "}
                <FileUploadIcon sx={{ fontSize: "17px", paddingLeft: windowWidth < 1000 && '5px' }} /> &nbsp;
                <Typography style={{ fontSize: "14px" }}>
                  {windowWidth > 800 && "Export"}
                </Typography>
              </Button>
            </Box>
          )}
          {enableSearch && (
            <Box
              sx={{
                width: "auto",
                border: "1px solid #E0E0E0",
                borderRadius: "32px",
              }}
            >
              <FormControl
                sx={{
                  width: { xs: "100%", md: 300 },
                  borderRadius: "32px",
                  border: "1px solid #E0E0E0 ",
                }}
                // variant="outlined"
              >
                <OutlinedInput
                  size="small"
                  id="header-search"
                  onChange={(e) => setSearch && setSearch(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  }
                  aria-describedby="header-search-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  placeholder="Search"
                />
              </FormControl>
            </Box>
          )}
        </Grid>
      </Grid>
      {contentAdvancedSearch && (
        <AdvancedSearch
          expanded={advancedSearch}
          title={titleAdvancedSearch}
          content={contentAdvancedSearch}
          handleSearchBtn={handleSearchBtn}
          handleResetAll={handleResetAll ? handleResetAll : false}
        />
      )}

      <Box
        style={{
          width: "100%",
          borderRadius: "25px",
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
          // height: 650,
        }}
      >
        {openAdvancedSearchModal && (
          <></>
          // <Snackbar
          //   sx={{
          //     "&.MuiPaper-root-MuiSnackbarContent-root": {
          //       background: "white!important",
          //     },
          //   }}
          //   anchorOrigin={{
          //     vertical: "top",
          //     horizontal: "right",
          //   }}
          //   open={openAdvancedSearchModal}
          //   onClose={handleClose}
          //   // key={"top right"}
          //   // TransitionComponent={SlideTransition}
          // >
          //   <SnackbarContent
          //     // action={action}
          //     style={{
          //       background: "white",
          //       color: "black",
          //       borderRadius: "20px",
          //       width: "550px",
          //     }}
          //     message={
          //       <>
          //         <Grid container>
          //           <Grid
          //             style={{ borderBottom: "1px solid #B3B3BA" }}
          //             container
          //           >
          //             <Grid item xs={9}>
          //               <div style={{ display: "flex" }}>
          //                 <SettingsSuggestIcon
          //                   style={{
          //                     color: "#C41035",
          //                     fontSize: "35px",
          //                     marginTop: "4px",
          //                   }}
          //                 />{" "}
          //                 <Typography
          //                   style={{
          //                     fontSize: "20px",
          //                     padding: "10px",
          //                     marginTop: "-1px",
          //                     color: "#000000",
          //                     // fontWeight: 600,
          //                   }}
          //                 >
          //                   Update User Preferences{" "}
          //                 </Typography>
          //               </div>
          //             </Grid>
          //             <Grid style={{ textAlign: "right" }} item xs={3}>
          //               {action}
          //             </Grid>
          //           </Grid>

          //           <Grid style={{ marginTop: "8px" }} item xs={12}>
          //             <Typography
          //               style={{
          //                 fontSize: "18px",
          //                 textAlign: "center",
          //                 padding: "15px 15px 5px 15px",
          //               }}
          //             >
          //               {/* Do you want to save this preferences to all pages ? */}
          //               {localStorage.getItem("statusFilter") === "false" ? (
          //                 <div
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "center",
          //                   }}
          //                 >
          //                   <Typography
          //                     style={{ fontSize: "18px", fontWeight: "600" }}
          //                   >
          //                     Advanced search:
          //                   </Typography>
          //                   <Typography style={{ fontSize: "18px" }}>
          //                     opened
          //                   </Typography>
          //                   {/* <CloseIcon
          //                     style={{ color: "red", marginTop: "2px" }}
          //                   /> */}
          //                 </div>
          //               ) : (
          //                 <div
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "center",
          //                   }}
          //                 >
          //                   <Typography
          //                     style={{ fontSize: "18px", fontWeight: "600" }}
          //                   >
          //                     Advanced search:
          //                   </Typography>
          //                   <Typography style={{ fontSize: "18px" }}>
          //                     collapsed
          //                   </Typography>
          //                   {/* <CheckIcon
          //                     style={{ color: "green", marginTop: "2px" }}
          //                   /> */}
          //                 </div>
          //               )}
          //             </Typography>
          //           </Grid>
          //           <Grid style={{ marginBottom: "15px" }} item xs={12}></Grid>
          //           <Grid xs={12} item style={{ textAlign: "center" }}>
          //             <div
          //               style={{
          //                 textAlign: "center",
          //                 display: "flex",
          //                 justifyContent: "center",
          //                 marginTop: "0px",
          //               }}
          //             >
          //               <Button
          //                 className="confirmation-btn-snack"
          //                 onClick={() => {
          //                   localStorage.setItem("filterExpanded", "true");
          //                   localStorage.setItem(
          //                     "statusFilter",
          //                     advancedSearch ? "true" : "false"
          //                   );
          //                   setOpenAdvancedSearchModal(false);
          //                 }}
          //               >
          //                 Save
          //               </Button>
          //             </div>
          //           </Grid>
          //           <Grid container>
          //             <Grid item xs={12}>
          //               <Typography
          //                 style={{
          //                   fontSize: "18px",
          //                   textAlign: "center",
          //                   marginTop: "8px",
          //                   color: "grey",
          //                   padding: "10px",
          //                 }}
          //               >
          //                 Please note that this preference will be applied on
          //                 all advanced search of all pages.
          //               </Typography>
          //             </Grid>
          //           </Grid>
          //           <Grid container>
          //             <Grid item xs={12}>
          //               <Typography
          //                 style={{
          //                   fontSize: "14px",
          //                   textAlign: "center",
          //                   // marginTop: "4px",
          //                   color: "grey",
          //                   padding: "7px",
          //                 }}
          //               >
          //                 Don't show again{" "}
          //                 <Checkbox
          //                   size="small"
          //                   sx={{
          //                     marginTop: "-3px",
          //                     color: "#C41035",
          //                     "&.Mui-checked": {
          //                       color: "#C41035",
          //                     },
          //                   }}
          //                   checked={checked}
          //                   onChange={handleChange}
          //                   inputProps={{ "aria-label": "controlled" }}
          //                 />
          //               </Typography>
          //             </Grid>
          //           </Grid>
          //         </Grid>
          //       </>
          //     }
          //   />
          // </Snackbar>
        )}
        {horizontalTabsOptions && (
          <TableTabs tabs={horizontalTabsOptions} onTabChange={onTabChange} />
        )}
        <DataGrid
          autoHeight
          pagination
          checkboxSelection={enableCheckboxSelection}
          isRowSelectable={(params) => !params?.row.isLocked}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            pagination: CustomPagination,
          }}
          sx={{
            // "&.MuiDataGrid-root": {
            //   height: "auto!important",
            // },
            "&.MuiDataGrid-root.MuiDataGrid-root": {
              border: "transparent!important",
            },

            "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
              background: "#e3e3e3a6",
            },
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
              {
                outline: "none",
              },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: disableHeaderColor ? "black" : "white",
            },
            "& .MuiDataGrid-row:hover": {
              // backgroundColor: "transparent"
            },

            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: disableHeaderColor ? "white" : "#c41035",
              borderRadius: "20px 20px 0px 0px",
              // Add any other styling properties as needed
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: disableHeaderColor ? "white" : "#c41035",
              borderRadius: "20px 20px 0px 0px",
              // Add any other styling properties as needed
            },
            "& .MuiCheckbox-root.Mui-checked": {
              color: "#ff91a8d4",
            },

            "DataGrid-overlayHeight": "600px",
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
              borderColor: "rgb(255 243 243)",
            },
            "&.MuiDataGrid-root .MuiDataGrid-withBorderColor": {
              borderColor: "rgb(255 243 243)",
            },
            "&.MuiDataGrid-root": {
              borderColor: "rgb(255 243 243)",
            },
            minHeight: !stopMinHeight && "390px",
            "& .MuiDataGrid-columnSeparator--sideRight": {
              display: "none",
            },
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
              {
                outline: "none !important",
              },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "0.4em",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            "& .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate ":
              {
                color: "red",
              },
            "& .MuiDataGrid-sortIcon": {
              opacity: 1,
              color: "white",
            },
            "& .MuiDataGrid-menuIconButton": {
              opacity: 1,
              color: "white",
            },
          }}
          disableRowSelectionOnClick
          disableVirtualization
          disableColumnSelector
          disableColumnMenu={true}
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            getDataByIndices(data, ids);
            setSelectedRows(ids);
          }}
          // onSelectionModelChange={(ids) => {
          //   const selectedIDs = new Set(ids);
          //   const selectedRowData = data.filter((row) =>
          //     selectedIDs.has(row.id.toString())
          //   );
          //   console.log("selectedRowData", selectedRowData);
          // }}
          rowSelectionModel={selectedRows}
          {...data}
          rows={
            data
              ? data?.map((item, index) => {
                  return { ...item, id: String(index) };
                })
              : {}
          }
          // {...data}
          columns={
            disableActions
              ? columns?.map((x) => ({
                  ...x,
                  sortable:
                    x.headerName !== "Block" &&
                    x.headerName !== "Main" &&
                    x.headerName !== "Lock" &&
                    x.headerName !== "Status"&&
                    x.headerName !== ""

                      ? true
                      : false,
                }))
              : [
                  ...columns?.map((x) => ({
                    ...x,
                    sortable:
                      x.headerName !== "Block" &&
                      x.headerName !== "Main" &&
                      x.headerName !== "Lock" &&
                      x.headerName !== "Status"
                        ? true
                        : false,
                  })),
                  {
                    field: "actions",
                    headerName: "Actions",
                    minWidth: largeActions ? 230 : 150,
                    align: "left",
                    sortable: false,

                    // flex: 1,

                    renderCell: (params) => {
                      return (
                        <Box direction="row" spacing={2}>
                          {enableEdit && (
                            <IconButton
                              onClick={() => {
                                !params.row?.isLocked &&
                                  editFunction(params.row);
                              }}
                              aria-label="Example"
                            >
                              {params.row?.isLocked ? (
                                <EditIcon
                                  style={{ visibility: "hidden" }}
                                  fontSize="small"
                                />
                              ) : (
                                <Tooltip title={"Edit"} placement="top">
                                  <EditIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}

                          {enableSetLogin && (
                            <IconButton
                              onClick={() => {
                                !params.row?.isLocked &&
                                  loginFunction(params.row);
                              }}
                              aria-label="Example"
                              style={{
                                display:
                                  params.row?.username !== null && "none",
                              }}
                            >
                              {params.row?.isLocked ? (
                                <LockIcon
                                  style={{ cursor: "not-allowed" }}
                                  fontSize="small"
                                />
                              ) : (
                                <Tooltip title={"set Login"} placement="top">
                                  <LoginIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}
                          {enableResetPassword && (
                            <IconButton
                              style={{
                                display:
                                  params.row?.username === null && "none",
                              }}
                              onClick={() => {
                                !params.row?.isLocked &&
                                  resetPasswordFunction(params.row);
                              }}
                              aria-label="Example"
                            >
                              {params.row?.isLocked ? (
                                <LockIcon
                                  style={{ cursor: "not-allowed" }}
                                  fontSize="small"
                                />
                              ) : (
                                <Tooltip
                                  title={"Reset Password"}
                                  placement="top"
                                >
                                  <RotateLeftIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}

                          {enableManageConfig && (
                            <IconButton
                              onClick={() => {
                                !params.row?.isLocked &&
                                  manageConfigFunction(params.row);
                              }}
                              aria-label="Example"
                            >
                              {params.row?.isLocked ? (
                                <LockIcon
                                  style={{ cursor: "not-allowed" }}
                                  fontSize="small"
                                />
                              ) : (
                                <Tooltip
                                  title={"Manage Config"}
                                  placement="top"
                                >
                                  <SettingsIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}
                          {enableAssign && (
                            <IconButton
                              onClick={() => {
                                !params.row?.providerCategoryRecordGuid
                                  ? assignFunction(params.row)
                                  : unAssignFunction(params.row);
                              }}
                              aria-label="Example"
                            >
                              {params.row?.providerCategoryRecordGuid ? (
                                <Tooltip
                                  title={"Unassign Category"}
                                  placement="top"
                                >
                                  <SwipeDownIcon
                                    // style={{ cursor: "not-allowed" }}
                                    fontSize="small"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={"Assign Category"}
                                  placement="top"
                                >
                                  <TouchAppIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}
                          {enableAdd && (
                            <IconButton
                              onClick={() => {
                                addFunction(params?.row)
                              }}
                              aria-label="Example"
                            >
                              {params.row?.providerCategoryRecordGuid ? (
                                <Tooltip
                                  title={"Unassign Category"}
                                  placement="top"
                                >
                                  <SwipeDownIcon
                                    // style={{ cursor: "not-allowed" }}
                                    fontSize="small"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={"Add missing rate"}
                                  placement="top"
                                >
                                  <Add fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}
                          {enableViewClients && (
                            <IconButton
                              onClick={() => {
                                viewClientsFunction(params?.row)
                              }}
                              aria-label="Example"
                            >
                                <Tooltip
                                  title={"View Clients"}
                                  placement="top"
                                >
                                  <SupervisorAccount fontSize="small" />
                                </Tooltip>
                              
                            </IconButton>
                          )}
                          {enableAddBankingDetails && (
                            <IconButton
                              onClick={() => {
                                AddBankingDetailsFunction(params?.row)
                              }}
                              aria-label="Example"
                            >
                                <Tooltip
                                  title={"Banking Details"}
                                  placement="top"
                                >
                                  <AccountBalanceIcon fontSize="small" />
                                </Tooltip>
                              
                            </IconButton>
                          )}
                          {enableShow && (
                            <IconButton
                              onClick={() => {
                                !params.row?.isLocked &&
                                  showFunction(params.row);
                              }}
                              aria-label="Example"
                            >
                              {params.row?.isLocked ? (
                                <LockIcon
                                  style={{ cursor: "not-allowed" }}
                                  fontSize="small"
                                />
                              ) : (
                                <Tooltip
                                  title={"Show Accounts"}
                                  placement="top"
                                >
                                  <VisibilityIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}

                          {enableDelete && (
                            <IconButton
                              onClick={() => {
                                !params.row?.isLocked &&
                                  deleteFunction(params.row);
                              }}
                              aria-label="Example"
                            >
                              {params.row?.isLocked ? (
                                <LockIcon
                                  style={{ cursor: "not-allowed" }}
                                  fontSize="small"
                                />
                              ) : (
                                <Tooltip title={"Delete"} placement="top">
                                  <DeleteIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          )}

                          {enableView && (
                            <IconButton
                              onClick={viewFunction}
                              aria-label="Example"
                            >
                              <Tooltip title={"View"} placement="top">
                                <VisibilityIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                          )}
                          {enableMore && (
                            <IconButton
                              onClick={moreFunction}
                              aria-label="Example"
                            >
                              <Tooltip title={"More"} placement="top">
                                <MoreVertIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                          )}
                        </Box>
                      );
                    },
                  },
                ]
          }
          rowCount={rowCountState}
          loading={isLoading}
          pageSizeOptions={[10, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePageSizeChange}
          // getRowClassName={(params) =>
          //   params.indexRelativeToCurrentPage % 2 === 0 ? "white" : "grey"
          // }
          disableColumnFilter
          disableDensitySelector
          initialState={{
            ...data?.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
        />
      </Box>
    </Box>
  );
}
