import { IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useState, useEffect, useRef } from "react";
// Replace these imports with your actual icons
import BarChartIcon from "@mui/icons-material/BarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IosShareIcon from "@mui/icons-material/IosShare";
import SsidChartIcon from "@mui/icons-material/SsidChart";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  borderRadius: "32px",
  backgroundColor: "white",
  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.1)",
  color: theme.palette.text.secondary,
}));
function All() {
  const statsRevenue = [
    {
      label: "One Way",
      value: "578M",
      share: "10.18%",
      change: -1.35,
    },
    {
      label: "Two Way",
      value: "1.375B",
      share: "31.02%",
      change: -1.64,
    },
    {
      label: "Push",
      value: "1.301B",
      share: "30.39%",
      change: 2.18,
    },
    {
      label: "Whatsapp",
      value: "1.221B",
      share: "28.45%",
      change: 2.35,
    },
    {
      label: "Viber",
      value: "1.221B",
      share: "28.45%",
      change: 2.35,
    },
  ];
  const statsTraffic = [
    {
      label: "One Way",
      total: "Total SMS",
      value: "578",
      share: "10.18%",
      change: -1.35,
    },
    {
      label: "Two Way",
      total: "Total SMS",
      value: "1.375",
      share: "31.02%",
      change: 1.64,
    },
    {
      label: "Push",
      total: "Total Push",
      value: "1.301",
      share: "30.39%",
      change: 2.18,
    },
    {
      label: "Whatsapp",
      total: "Total Whatsapp",
      value: "1.221",
      share: "28.45%",
      change: -2.35,
    },
    {
      label: "Viber",
      total: "Total Viber",
      value: "1.221",
      share: "28.45%",
      change: 2.35,
    },
  ];
  const Revenue = {
    chart: {
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: "Revenues",
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Sms",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Sms Two Way",
        data: [2, 123, 555, 32, 76],
      },
      {
        name: "Push",
        data: [21, 553, 32, 111, 222],
      },
      {
        name: "Whatsapp",
        data: [10, 2332, 3232, 232, 333],
      },
      {
        name: "Viber",
        data: [100, 12, 44, 500, 123],
      },
    ],
  };
  const Traffic = {
    chart: {
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: "Number of SMS",
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Sms",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Sms Two Way",
        data: [2, 123, 555, 32, 76],
      },
      {
        name: "Push",
        data: [21, 553, 32, 111, 222],
      },
      {
        name: "Whatsapp",
        data: [10, 2332, 3232, 232, 333],
      },
      {
        name: "Viber",
        data: [100, 12, 44, 500, 123],
      },
    ],
  };
  const [maxHeight, setMaxHeight] = useState(0);
  const cardRefs = useRef([]);

  useEffect(() => {
    let allEqual = true;
    let firstHeight = cardRefs.current[0]?.offsetHeight || 0;

    const maxHeight = cardRefs.current.reduce((max, card) => {
      if (card.offsetHeight !== firstHeight) {
        allEqual = false;
      }
      return card.offsetHeight > max ? card.offsetHeight : max;
    }, 0);

    if (allEqual) {
      setMaxHeight(null); // or setMaxHeight(0);
    } else {
      setMaxHeight(maxHeight);
    }
  }, []);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "white", 
          padding: "20px",
          // border: "1px solid #c41035",
          borderRadius: "32px",
        }}
      >
        <Typography
          className="title-chart"
          sx={{
            fontSize: "27px",
            // margin:'10px',
            marginBottom:'12px',
            fontWeight: "bold",
            color:'#c41035',  
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span>Traffic</span>
        </Typography>
        <Grid container spacing={2}>
          {statsTraffic.map((stat, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} xl={true} key={index}>
              <Item
                ref={(el) => (cardRefs.current[index] = el)}
                style={{ height: maxHeight ? `${maxHeight}px` : "auto" }}
              >
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography
                      className="title-chart"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {stat.label}
                    </Typography>
                    <Typography>Total SMS</Typography>
                    <Typography
                      className="title-chart"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {stat.value}
                    </Typography>
                    <Typography>Traffic share</Typography>
                    <Typography color="textSecondary">{stat.share}</Typography>
                  </Box>
                  <Box>
                    {stat.change > 0 ? (
                      <ArrowUpwardIcon color="success" />
                    ) : (
                      <ArrowDownwardIcon color="error" />
                    )}
                    <Typography
                      className="title-chart"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {Math.abs(stat.change)}%
                    </Typography>
                    <Typography>From last month</Typography>
                    {/* <Typography>
                    <BarChartIcon sx={{fontSize:'40px'}}/>
                  </Typography> */}
                    <Typography>
                      {stat.change > 0 ? (
                        <StackedLineChartIcon
                          sx={{ fontSize: "40px" }}
                          color="success"
                        />
                      ) : (
                        <SsidChartIcon
                          sx={{ fontSize: "40px" }}
                          color="error"
                        />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>
        <br />  <br/>   <br/>   
        <Grid item xs={12} lg={12}>
          {/* <Paper className="paper-channel-box"> */}
          
         
              {/* <Box sx={{position:'absolute', right:'90px'}}>
<Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip></Box> */}
        
           
            <HighchartsReact highcharts={Highcharts} options={Traffic} />
            <Grid item xs={12} className="center">
              <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "17px",
                    // marginRight:'-90px',
                    // marginBottom:'12px',
                    fontWeight: "bold",
                    color:'black',  
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span>Traffic by channels</span>
                </Typography>
              </Grid>
          {/* </Paper> */}
        </Grid>
      </Box>
      <br />
      <Box
       sx={{
        backgroundColor: "white", 
        padding: "20px",
        // border: "1px solid #c41035",
        borderRadius: "32px",
      }}
      >
            <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "27px",
                    // margin:'10px',
                    marginBottom:'12px',
                    fontWeight: "bold",
                    color:'#c41035',  
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span>Revenues</span>
                </Typography>
        <Grid container spacing={2}>
          {statsRevenue.map((stat, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} xl={true} key={index}>
              <Item ref={(el) => (cardRefs.current[index] = el)}
                style={{ height: maxHeight ? `${maxHeight}px` : "auto" }}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography
                      className="title-chart"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {stat.label}
                    </Typography>
                    <Typography>Total Balance</Typography>
                    <Typography
                      className="title-chart"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {stat.value}
                    </Typography>
                    <Typography>Traffic share</Typography>
                    <Typography color="textSecondary">{stat.share}</Typography>
                  </Box>
                  <Box>
                    {stat.change > 0 ? (
                      <ArrowUpwardIcon color="success" />
                    ) : (
                      <ArrowDownwardIcon color="error" />
                    )}
                    <Typography
                      className="title-chart"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {Math.abs(stat.change)}%
                    </Typography>
                    <Typography>From last month</Typography>
                    {/* <Typography>
                    <BarChartIcon sx={{fontSize:'40px'}}/>
                  </Typography> */}
                    <Typography>
                      {stat.change > 0 ? (
                        <StackedLineChartIcon
                          sx={{ fontSize: "40px" }}
                          color="success"
                        />
                      ) : (
                        <SsidChartIcon
                          sx={{ fontSize: "40px" }}
                          color="error"
                        />
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>

        <br /> <br /> <br />
        <Grid item xs={12} lg={12}>
          {/* <Paper className="paper-channel-box"> */}
            <Grid container>
            
      
              {/* <Grid item xs={6} className="end">
               
              </Grid> */}
{/* <Box sx={{position:'absolute', right:'90px'}}>
<Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip></Box> */}
            </Grid>
            <br/>
            <HighchartsReact highcharts={Highcharts} options={Revenue} />
            <Grid item xs={12} className="center">
              <Typography
                  className="title-chart"
                  sx={{
                    textAlign:'center',
                    fontSize: "17px",
                    // marginRight:'-120px',
                    // marginBottom:'12px',
                    fontWeight: "bold",
                    color:'black',  
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span>Revenues by channels</span>
                </Typography>
              </Grid>
          {/* </Paper> */}
        </Grid>
      </Box>
    </Box>
  );
}

export default All;
