import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import Table from "../../../Components/DynamicTable/Table";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import { DynamicIcon } from "../../../Utils/functions";
import HistoryModal from "./HistoryModal";
import ManageAccounts from "./ManageAccounts";
import ManageSmpp from "./ManageSmpp";
import ManageSmtp from "./ManageSmtp";
import ManageWebhooks from "./ManageWebhooks";
import MultipleSelect from "../../../Components/Selects/MultipleSelect";

// export default function ManageClient() {
//   return <Page />;
// }

export default function ManageClient({ handleGoBack }) {
  const [openSection, setOpenSection] = useState("Accounts");
  const [status, setStatus] = useState(false);
  const [openSmtp, setOpenSmtp] = useState(false);
  const [openSmpp, setOpenSmpp] = useState(false);
  const [openWebhooks, setOpenWebhooks] = useState(false);
  const [openAddAccount, setOpenAddAccount] = useState(false);
  const [openAddWallet, setOpenAddWallet] = useState(false);

  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [openUnsubscribeModal, setOpenUnsubscribeModal] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [searchContacts, setSearchContacts] = useState("");
  const [accountNb, setAccountNb] = useState(["123456"]);
  const [walletNb, setWalletNb] = useState(["84512"]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  let listHistory = [
    {
      startDate: "10/10/2023",
      endDate: "11/11/2023",
    },
    {
      startDate: "7/10/2023",
      endDate: "11/10/2023",
    },
  ];
  let listContacts = [
    {
      name: "Hassan",
      number: "+96170545787",
    },
    {
      name: "Ibrahim",
      number: "+96170545787",
    },
    {
      name: "Ali",
      number: "+96170545787",
    },
    {
      name: "Izzat",
      number: "+96170545787",
    },
    {
      name: "Jana",
      number: "+96170545787",
    },
    {
      name: "Roula",
      number: "+96170545787",
    },
    {
      name: "Layla",
      number: "+96170545787",
    },
    {
      name: "Rami",
      number: "+96170545787",
    },
    {
      name: "Fadi",
      number: "+96170545787",
    },
    {
      name: "Dani",
      number: "+96170545787",
    },

    {
      name: "Hadi",
      number: "+96170545787",
    },
    {
      name: "Samar",
      number: "+96170545787",
    },
    {
      name: "Reem",
      number: "+96170545787",
    },
    {
      name: "Tamy",
      number: "+96170545787",
    },
    {
      name: "Rayan",
      number: "+96170545787",
    },
  ];
  let listSubscription = [
    {
      name: "SMS",
      type: "Expire On 12/12/2024",
      icon: "CheckCircle",
      icon1: "Sms",
    },
    {
      name: "SMS Two Way",
      type: "Not Subscribed",
      icon: "Cancel",
      icon1: "chat",
    },
    {
      name: "Push",
      type: "Expires On 12/12/2024",
      icon: "Cancel",
      icon1: "PushPin",
    },
    {
      name: "Whatsapp",
      type: "Not Subscribed",
      icon: "Cancel",
      icon1: "WhatsApp",
    },
    {
      name: "Viber",
      type: "Not Subscribed",
      icon: "Cancel",
      icon1: "Vibration",
    },
  ];

  const uppercaseAlphabetArray = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index)
  );

  const [baseUrl, setBaseUrl] = useState(
    "https://mm-omni-api-software-dev.montylocal.net"
  );
  const [authority, setAuthority] = useState(
    "https://keycloak-softwaredev.montyloacal.net/realms/selfcare"
  );
  const [tokenEndPoint, setTokenEndPoint] = useState(
    "https://keycloak-softwaredev.montyloacal.net/realms/selfcare/protocol/openid-connect/token"
  );

  const handleClickStatus = () => {
    setStatus(!status);
  };
  const handleOpenSmtp = () => {
    setOpenSmtp(!openSmtp);
  };
  const handleOpenSmpp = () => {
    setOpenSmpp(!openSmpp);
  };
  const handleOpenWebhooks = () => {
    setOpenWebhooks(!openWebhooks);
  };
  const handleOpenHistory = () => {
    setOpenHistory(!openHistory);
  };
  const tableDataSenderIds = [
    {
      countryName: "Turkey",
      image: "tr",
      status: "Approved",
      name: "SMS",
      type: "normal",
    },
    {
      countryName: "Egypt",
      image: "eg",
      status: "Pending",
      name: "Push",
      type: "shortcode",
    },

    // Add more cards as needed
  ];
  let columnsSenderIds = [
    {
      field: "countryName",
      headerName: "Country Name",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },

    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
  ];
  const cards = [
    {
      image: "tr",
      alt: "Slide 1",
      title: "Approved",
      description: "SMS",
      type: "normal",
    },
    {
      image: "eg",
      alt: "Slide 2",
      title: "Pending",
      description: "Push",
      type: "shortcode",
    },
    {
      image: "de",
      alt: "Slide 2",
      title: "Pending",
      description: "Whatsapp",
      type: "longcode",
    },
    {
      image: "eg",
      alt: "Slide 2",
      title: "Approved",
      description: "Push",
      type: "normal",
    },
    // Add more cards as needed
  ];
  let allAccountList = [
    {
      accountNumber: "123456",
      balance: "1000$",
      previousBalance: "1500$",
      accountType: "Prepaid",
      lockedBalance: "1000$",
      previousLocked: "1200$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
  ];
  let allWalletList = [
    {
      walletNumber: "123456",
      points: "1000$",
      previousPoints: "1500$",
      walletType: "Prepaid",
      lockedPoints: "1000$",
      previousLocked: "1200$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
  ];
  const [accountsList, setAccountsList] = useState([
    {
      accountNumber: "123456",
      balance: "1000$",
      previousBalance: "1500$",
      accountType: "Prepaid",
      lockedBalance: "1000$",
      previousLocked: "1200$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
    {
      accountNumber: "84512",
      balance: "1012$",
      previousBalance: "1320$",
      accountType: "Postpaid",
      lockedBalance: "1312$",
      previousLocked: "124$",
    },
  ]);
  const [walletList, setWalletList] = useState([
    {
      walletNumber: "123456",
      points: "1000$",
      previousPoints: "1500$",
      walletType: "Prepaid",
      lockedPoints: "1000$",
      previousLocked: "1200$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
    {
      walletNumber: "84512",
      points: "1012$",
      previousPoints: "1320$",
      walletType: "Postpaid",
      lockedPoints: "1312$",
      previousLocked: "124$",
    },
  ]);
  useEffect(() => {
    let newAccountNumbersList = allAccountList.filter((item) =>
      accountNb.includes(item["accountNumber"])
    );
    console.log("Asdasdasdasdasdas", newAccountNumbersList);

    setAccountsList(newAccountNumbersList);
  }, [accountNb]);
  useEffect(() => {
    let newWalletNumbersList = allWalletList.filter((item) =>
    walletNb.includes(item["walletNumber"])
    );
    console.log("Asdasdasdasdasdas", newWalletNumbersList);

    setWalletList(newWalletNumbersList);
  }, [walletNb]);
  const dataset = [
    {
      name: "Accounts",
      icon: "accountBox",
      content: (
        <div
          style={{ margin: "0px 20px", maxHeight: "35vh", overflow: "auto" }}
        >
          {accountsList?.map((x) => (
            <>
              <div style={{ display: "flex" }}>
                <Typography className="label-card-2 ">
                  {x.accountNumber}
                </Typography>
              </div>
              <div style={{ margin: "0px 0px" }}>
                <Grid style={{ marginBottom: "20px" }} spacing={4} container>
                  <Grid lg={4} md={12} item>
                    <Grid container>
                      <Grid item lg={5} md={5}>
                        <Typography className="sub-label-card">
                          Balance :
                        </Typography>
                      </Grid>
                      <Grid item lg={3} md={3}>
                        <Typography className="sub-label-card-2">
                          {x.balance}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={5} md={5}>
                        <Typography className="sub-label-card">
                          Locked Balance :
                        </Typography>
                      </Grid>
                      <Grid item lg={3} md={3}>
                        <Typography className="sub-label-card-2">
                          {x.lockedBalance}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid lg={4} md={12} item>
                    <Grid container>
                      <Grid item lg={6} md={6}>
                        <Typography className="sub-label-card">
                          Previous Balance :
                        </Typography>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <Typography className="sub-label-card-2">
                          {x.previousBalance}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={6} md={6}>
                        <Typography className="sub-label-card">
                          Previous Locked :
                        </Typography>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <Typography className="sub-label-card-2">
                          {x.previousLocked}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid lg={4} md={12} item>
                    <Grid container>
                      <Grid item lg={6} md={6}>
                        <Typography className="sub-label-card">
                          Account Type :
                        </Typography>
                      </Grid>
                      <Grid item lg={2} md={2}>
                        <Typography className="sub-label-card-2">
                          {x.accountType}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </>
          ))}
        </div>
      ),
    },
    {
      name: "Contacts",
      icon: "Contacts",
      content: (
        <div
          style={{
            width: "96%",
            padding: "0px 10px",
            maxHeight: "300px",
            overflow: "hidden",
            paddingBottom: "20px",
            // display: "flex",
            marginTop: "20px",
          }}
        >
          <Grid container>
            <Grid item lg={4} md={10}>
              <TextFieldForm
                value={searchContacts}
                setValue={setSearchContacts}
                placeholderInside={"Search"}
              />

              <div style={{ maxHeight: "400px", overflow: "auto" }}>
                {listContacts.map((x) => {
                  return (
                    <div style={{ maxHeight: "400px", overflow: "auto" }}>
                      <Grid style={{ margin: "5px 0px" }} container>
                        <Grid xs={2} item>
                          <Avatar>{x.name[0]}</Avatar>
                        </Grid>
                        <Grid xs={4} item>
                          <div
                            style={{
                              display: "grid",
                              margin: "-2px 0px 0px 0px",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                margin: "0px 0px -6px 0px",
                              }}
                            >
                              {x.name}
                            </Typography>
                            <Typography style={{ color: "#757575" }}>
                              {x.number}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={8}></Grid>
                      </Grid>
                    </div>
                    // <div style={{display:"grid",margin:"20px"}}>
                    //   <div style={{display:"flex"}}>
                    //   <Avatar>{x.name[0]}</Avatar>

                    //   {x.name}
                    //   </div>
                    // </div>
                  );
                })}
              </div>
            </Grid>
            <Grid item lg={5} md={2}>
              <div
                style={{
                  maxHeight: "400px",
                  overflow: "hidden",
                  margin: "0px 10px",
                  marginTop: "62px",
                }}
              >
                {uppercaseAlphabetArray.map((letter, index) => (
                  <Typography
                    style={{ fontWeight: "bold", color: "#757575" }}
                    key={index}
                  >
                    {letter}
                  </Typography>
                ))}
              </div>{" "}
            </Grid>
          </Grid>
        </div>
      ),
    },
    // {
    //   name: "Accounts",
    //   icon: "accountBox",
    //   content: (
    //     <div style={{ width: "96%", padding: "0px 10px" }}>
    //       <div style={{ width: "20%" }}>
    //         <TextFieldForm
    //           value={searchAccounts}
    //           setValue={setSearchAccounts}
    //           placeholder={"Search"}
    //         />
    //       </div>
    //       <div style={{ marginTop: "-40px" }}>
    //         <Table
    //           rowCountState={tableDataLocation?.totalCount}
    //           data={tableDataLocation}
    //           // isLoading={isLoading}
    //           columns={columnsLocation}
    //           pageIndex={
    //             tableDataLocation?.pageIndex ? tableDataLocation?.pageIndex : 0
    //           }
    //           disableActions
    //         />
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Subscription",
      icon: "TouchApp",
      content: (
        <div
          style={{
            width: "96%",
            padding: "0px 10px",
            maxHeight: "400px",
            overflow: "hidden",
            paddingBottom: "20px",
            // display: "flex",
          }}
        >
          <Grid container>
            <Grid item lg={10} md={10}>
              <div
                style={{
                  maxHeight: "400px",
                  overflow: "auto",
                  marginTop: "20px",
                }}
              >
                {listSubscription.map((x) => {
                  return (
                    <div style={{ maxHeight: "400px", overflow: "auto" }}>
                      <Grid style={{ margin: "5px 0px" }} container>
                        <Grid xs={1} item>
                          <DynamicIcon
                            iconName={x.icon1}
                            style={{
                              fontSize: "25px",
                              margin: "6px 0px 0px 0px",
                              color: "#757575",
                            }}
                          />
                        </Grid>
                        <Grid xs={3} item>
                          <div
                            style={{
                              display: "grid",
                              margin: "-2px 0px 0px 0px",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                margin: "0px 0px -6px 0px",
                              }}
                            >
                              {x.name}
                            </Typography>
                            <Typography
                              style={{
                                color: "#757575",
                                fontSize: "12px",
                                marginTop: "2px",
                              }}
                            >
                              {x.type}
                              {x.type === "Not Subscribed" ? (
                                <span
                                  style={{
                                    color: "#59B259",
                                    fontWeight: "normal",
                                    cursor: "pointer",
                                    fontSize: "11px",
                                    marginLeft: "3px",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => handleOpenSubscribe()}
                                >
                                  subscribe
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "normal",
                                    cursor: "pointer",
                                    fontSize: "11px",
                                    marginLeft: "3px",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => handleOpenUnSubscribe()}
                                >
                                  unsubscribe
                                </span>
                              )}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <DynamicIcon
                            iconColor={x.icon === "Cancel" ? "red" : "#59B259"}
                            iconName={x.icon}
                            style={{
                              fontSize: "25px",
                              margin: "0px 6px -4px 6px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{
                              fontSize: "25px",
                              margin: "-5px 6px -4px -6px",
                            }}
                            onClick={() => handleOpenHistory()}
                          >
                            <HistoryIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                    // <div style={{display:"grid",margin:"20px"}}>
                    //   <div style={{display:"flex"}}>
                    //   <Avatar>{x.name[0]}</Avatar>

                    //   {x.name}
                    //   </div>
                    // </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      ),
    },
    {
      name: "Sender ID's",
      icon: "send",
      content: (
        <div style={{ width: "80%", marginTop: "-4%" }}>
          {/* <Carousel cards={cards} /> */}
          <Table
            rowCountState={tableDataSenderIds?.totalCount}
            data={tableDataSenderIds}
            // isLoading={isLoading}
            columns={columnsSenderIds}
            pageIndex={
              tableDataSenderIds?.pageIndex ? tableDataSenderIds?.pageIndex : 0
            }
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            disableHeaderColor
            disableActions
            stopMinHeight
          />
        </div>
      ),
    },
    {
      name: "Wallet",
      icon: "Wallet",

      content: (
        <div
        style={{ margin: "0px 20px", maxHeight: "35vh", overflow: "auto" }}
      >
        {walletList?.map((x) => (
          <>
            <div style={{ display: "flex" }}>
              <Typography className="label-card-2 ">
                {x.walletNumber}
              </Typography>
            </div>
            <div style={{ margin: "0px 0px" }}>
              <Grid style={{ marginBottom: "20px" }} spacing={4} container>
                <Grid lg={4} md={12} item>
                  <Grid container>
                    <Grid item lg={5} md={5}>
                      <Typography className="sub-label-card">
                        Points :
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3}>
                      <Typography className="sub-label-card-2">
                        {x.points}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={5} md={5}>
                      <Typography className="sub-label-card">
                        Locked Points :
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3}>
                      <Typography className="sub-label-card-2">
                        {x.lockedPoints}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid lg={4} md={12} item>
                  <Grid container>
                    <Grid item lg={6} md={6}>
                      <Typography className="sub-label-card">
                        Previous Points :
                      </Typography>
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <Typography className="sub-label-card-2">
                        {x.previousPoints}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} md={6}>
                      <Typography className="sub-label-card">
                        Previous Locked :
                      </Typography>
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <Typography className="sub-label-card-2">
                        {x.previousLocked}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
            
              </Grid>
            </div>
          </>
        ))}
      </div>
      ),
    },
  ];
  const handleOpenAddAccount = () => {
    setOpenAddAccount(!openAddAccount);
  };
  const handleOpenAddWallet = () => {
    setOpenAddWallet(!openAddWallet);
  };
  const handleOpenUnSubscribe = () => {
    setOpenUnsubscribeModal(!openUnsubscribeModal);
  };
  const handleOpenSubscribe = () => {
    setOpenSubscribeModal(!openSubscribeModal);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography
              style={{ cursor: "pointer" }}
              onClick={handleGoBack}
              className="BreadcrumbsPage"
            >
              Clients
            </Typography>
            <Typography className="breadcrumbactiveBtn">
              {/* {localStorage.getItem("projectName")} */}
              Manage Client
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid xs={12} item>
          <Card
            style={{
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            <Grid container>
              <Grid lg={4} md={12} item>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <DescriptionIcon
                    style={{ color: "#C61035", marginLeft: "-4px" }}
                  />
                  <Typography className="label-card">Description</Typography>
                </div>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography className="sub-label-card">
                      Client Name :
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <Typography className="sub-label-card-2">Client</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography className="sub-label-card">
                      Client Type :
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <Typography className="sub-label-card-2">
                      Reseller
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={4} md={4}>
                    <Typography className="sub-label-card">Email :</Typography>
                  </Grid>
                  <Grid item lg={4} md={4}>
                    <Typography className="sub-label-card-2">
                      {" "}
                      <a href="Client@gmail.com">Client@gmail.com</a>
                    </Typography>
                  </Grid>
                </Grid>
               
              </Grid>
              <Grid item xl={2}></Grid>
              <Grid justify="flex-end" lg={2} md={12} item>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <SettingsIcon
                    style={{ color: "#C61035", marginLeft: "-4px" }}
                  />
                  <Typography className="label-card">Configuration</Typography>
                </div>
                <Grid container>
                  <Grid item lg={12}>
                    <Grid container>
                      <Grid item lg={6}>
                        <Typography className="sub-label-card-2">
                          SMTP
                        </Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <AddIcon
                          style={{
                            fontSize: "25px",
                            color: "#C61035",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={handleOpenSmtp}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12}>
                      <Grid container>
                        <Grid item lg={6}>
                          <Typography className="sub-label-card-2">
                            SMPP
                          </Typography>
                        </Grid>
                        <Grid item lg={6}>
                          <AddIcon
                            style={{
                              fontSize: "25px",
                              color: "#C61035",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenSmpp}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12}>
                      <Grid container>
                        <Grid item lg={6}>
                          <Typography className="sub-label-card-2">
                            Webhooks
                          </Typography>
                        </Grid>
                        <Grid item lg={6}>
                          <EditIcon
                            style={{
                              fontSize: "18px",
                              color: "#C61035",
                              fontWeight: "bold",
                              cursor: "pointer",
                              marginTop: "4px",
                            }}
                            onClick={handleOpenWebhooks}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* 
                <div style={{ display: "flex" }}>
                  <div style={{ margin: "auto", display: "flex" }}>
                    <Typography className="sub-label-card-2">
                      SMTP Configuration
                    </Typography>

                    <AddIcon
                      style={{
                        fontSize: "25px",
                        color: "#C61035",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div> */}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          marginTop: "20px",
        }}
      >
        {dataset.map((datum, i) => {
          const { name, icon, content } = datum;
          const isOpen = name === openSection;
          return (
            <div
              key={name}
              style={{
                height: "100%",
                //   backgroundColor: isOpen ? "white" : "lightgray",
                backgroundColor: "white",
                marginRight: "10px",
                borderRadius: "10px",

                //   border: `1px solid ${isOpen ? "white" : "black"}`,
                flex: 1,
                flexGrow: isOpen ? 1 : 0,
                // transition: "all 0.5s ease",

                //my changes
                padding: 18,
                flexBasis: "1.2rem",
                cursor: !isOpen ? "pointer" : "auto",
                position: "relative",
              }}
              onClick={!isOpen ? () => setOpenSection(datum.name) : null}
            >
              <div
                style={{
                  // transition: "all 0.5s ease",
                  marginBottom: "5px",
                  //my changes
                  transform: `rotate(${isOpen ? "0" : "90"}deg) 
                translateX(${isOpen ? "0" : "50"}%)`,
                  whiteSpace: "nowrap",
                  width: isOpen ? "100%" : "1rem",
                  position: "absolute",
                  top: isOpen ? "1rem" : "178px",
                  left: isOpen ? "1rem" : "",
                  fontWeight: "bold",
                }}
              >
                {console.log("isOpenisOpen", openSection)}
                <Grid container>
                  <Grid xs={12} item>
                    <div
                      style={{
                        color: "#c41035",
                        fontWeight: "bold",
                        margin: "5px",
                        transform: isOpen ? "scale(1)" : "scale(-1)",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          margin: isOpen
                            ? "0px"
                            : name === "Configuration"
                            ? "0px -25px 0px -25px"
                            : name === "Contacts"
                            ? "0px 50px 0px -25px"
                            : name === "Accounts"
                            ? "0px 34px 0px -23px"
                            : name === "Subscription"
                            ? "0px 12px 0px -25px"
                            : name === "Sender ID's"
                            ? "0px 17px 0px -21px"
                            : name === "Wallet"
                            ? "0px 51px 0px -20px"
                            : "",
                        }}
                      >
                        <DynamicIcon
                          iconColor={"#c41035"}
                          iconName={icon}
                          style={{
                            fontSize: "20px",
                            margin: "0px 6px -4px 6px",
                          }}
                        />
                        <span>{name} </span>
                      </div>
                      <div style={{ textAlign: "end", width: "100%" }}>
                        {isOpen ? (
                          <>
                            {name === "Accounts" && isOpen && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  // height:"50px",
                                }}
                              >
                                <Button
                                  onClick={() => handleOpenAddAccount()}
                                  className="outline"
                                  variant="outline"
                                  style={{
                                    margin: "0px 10px 0px 0px",
                                    height: "35px",
                                  }}
                                >
                                  Add Account
                                </Button>
                                <div style={{ width: "150px" }}>
                                  <MultipleSelect
                                    value={accountNb}
                                    setValue={setAccountNb}
                                    options={[
                                      { label: "123456", value: "123456" },
                                      { label: "84512", value: "84512" },
                                      { label: "4442", value: "4442" },
                                    ]}
                                    multiple={true}
                                  />
                                </div>
                                <DynamicIcon
                                  iconColor={"#00B900"}
                                  iconName={"lockOpen"}
                                  style={{
                                    fontSize: "20px",
                                    margin: "10px 30px -4px 6px",
                                  }}
                                />
                              </div>
                            )}

                            {name === "Wallet" && isOpen && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  // height:"50px",
                                }}
                              >
                                {/* <Button
                                  onClick={() => handleOpenAddWallet()}
                                  className="outline"
                                  variant="outline"
                                  style={{
                                    margin: "0px 10px 0px 0px",
                                    height: "35px",
                                  }}
                                >
                                  Add Wallet
                                </Button> */}
                                <div style={{ width: "150px" }}>
                                  <MultipleSelect
                                    value={walletNb}
                                    setValue={setWalletNb}
                                    options={[
                                      { label: "123456", value: "123456" },
                                      { label: "84512", value: "84512" },
                                      { label: "4442", value: "4442" },
                                    ]}
                                    multiple={true}
                                  />
                                </div>
                                <DynamicIcon
                                  iconColor={"#00B900"}
                                  iconName={"lockOpen"}
                                  style={{
                                    fontSize: "20px",
                                    margin: "10px 30px -4px 6px",
                                  }}
                                />
                              </div>
                            )}
                            {name !== "Accounts" &&
                              name !== "Wallet" &&
                              isOpen && (
                                <DynamicIcon
                                  iconColor={"#00B900"}
                                  iconName={"lockOpen"}
                                  style={{
                                    fontSize: "20px",
                                    margin: "0px 30px -4px 6px",
                                  }}
                                />
                              )}
                          </>
                        ) : (
                          <DynamicIcon
                            iconColor={"gray"}
                            iconName={"lock"}
                            style={{
                              fontSize: "20px",
                              margin: "0px 6px -4px 0px",
                              width:
                                name == "Configuration"
                                  ? "9em"
                                  : name === "Contacts"
                                  ? "5em"
                                  : name === "Users"
                                  ? "10em"
                                  : name === "Contries"
                                  ? "8em"
                                  : name === "Languages"
                                  ? "6em"
                                  : "6.3em",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {isOpen && content}
                  </Grid>
                </Grid>
              </div>
            </div>
          );
        })}
      </div>
      {openWebhooks && (
        <ManageWebhooks
          open={openWebhooks}
          setOpen={setOpenWebhooks}
          title={"Edit Webhook"}
        />
      )}
      {openSmpp && (
        <ManageSmpp open={openSmpp} setOpen={setOpenSmpp} title={"Add SMPP"} />
      )}
      {openSmtp && (
        <ManageSmtp open={openSmtp} setOpen={setOpenSmtp} title={"Add SMTP"} />
      )}
      {openAddAccount && (
        <ManageAccounts
          open={openAddAccount}
          setOpen={setOpenAddAccount}
          title={"Add Account"}
        />
      )}
      {openSubscribeModal && (
        <CustomizedDialogs
          open={openSubscribeModal}
          setOpen={setOpenSubscribeModal}
          title={"Subscribtion"}
          firstActionBtnName={"Save"}
          content={
            <>
              <Grid
                style={{ display: "flex", justifyContent: "center" }}
                container
              >
                <Grid item>
                  <Typography
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Are you sure do you want to subscribe ?
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
        />
      )}
      {openHistory && (
        <HistoryModal
          open={openHistory}
          setOpen={setOpenHistory}
          title={"History"}
          listHistory={listHistory}
        />
      )}

      {openUnsubscribeModal && (
        <CustomizedDialogs
          open={openUnsubscribeModal}
          setOpen={setOpenUnsubscribeModal}
          title={"Unsubscribtion"}
          firstActionBtnName={"Save"}
          content={
            <>
              <Grid
                style={{ display: "flex", justifyContent: "center" }}
                container
              >
                <Grid
                  style={{ display: "flex", justifyContent: "center" }}
                  item
                >
                  <Typography
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Are you sure do you want to unsubscribe ?
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
        />
      )}
    </>
  );
}
