import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Grid } from "@mui/material";

// Custom Scroll Button Component
function ScrollButton(props) {
  return (
    <IconButton {...props}>
      {props.direction === "left" ? (
        <KeyboardArrowLeft />
      ) : (
        <KeyboardArrowRight />
      )}
    </IconButton>
  );
}

export default function HorizantalTabs({ tabs, setActiveBtn }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (setActiveBtn) setActiveBtn(tabs[newValue].label);
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: 320, sm: 500 },
        zIndex: 200,
        position: "absolute",
      }}
      className="Scorllbar-tabs-hor"
    >
      <Grid
        style={{
          zIndex: 200,
          position: "absolute",
        }}
        item
        xs={12}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          ScrollButtonComponent={ScrollButton}
          aria-label="scrollable auto tabs example"
          className="horizantal-tabs-page"
          sx={{
            position: "absolute",
            zIndex: 200,
            "& div.MuiTabs-scroller": {
              "& .MuiTabs-flexContainer": {
                zIndex: 200,
                position: "absolute",
              },
            },
          }}
        >
          {tabs &&
            tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                className="horizantal-tabs-name"
                sx={{
                  mr: 1,
                  background: "white",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                  fontSize: "16px",
                  fontWeight: "400",
                  textTransform: "inherit",
                  // minWidth: "116px",
                  color: "#949494",
                  // minHeight: "43px",
                  // padding: "6px 16px",
                }}
              />
            ))}
        </Tabs>
      </Grid>
    </Box>
  );
}
