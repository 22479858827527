import * as Yup from "yup";

const validationSchema = [
  Yup.object().shape({
    ALERTS_TOGGLE: Yup.boolean(),
    ALERTS: Yup.string().when("ALERTS_TOGGLE", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("Email Address is required"),
      otherwise: (schema) => schema.optional(),
    }),
    SENDER_TOGGLE: Yup.boolean(),
    SENDER: Yup.string().when("SENDER_TOGGLE", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("Sender Id is required"),
      otherwise: (schema) => schema.optional(),
    }),
    API_TOGGLE: Yup.boolean(),
    API: Yup.string().when("API_TOGGLE", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("API is required"),
      otherwise: (schema) => schema.optional(),
    }),
  }),
  Yup.object().shape({
    COPYRIGHTS_TOGGLE: Yup.boolean(),
    COPYRIGHTS: Yup.string().when("COPYRIGHTS_TOGGLE", {
      is: (val) => val == true, // alternatively: (val) => val == true
      then: (schema) => schema.required("Copy Rights required"),
      otherwise: (schema) => schema.optional(),
    }),

  }),
  Yup.object().shape({
    BusinessWebUrl: Yup.string().required("Domain name is required"),
    PORTAL_NAME: Yup.string().required("Portal name is required")
  }),
];

export default validationSchema;
