import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import LogoWhite from "../../Assets/monty-mobile-logo-white.svg";
import Logo from "../../Assets/monty-mobile-logo.svg";

import { Collapse, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DynamicIcon } from "../../Utils/functions";
import { useSelector } from "react-redux";
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": { ...openedMixin(theme), border: "none" },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": { ...closedMixin(theme), border: "none" },
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
// const DynamicIcon = ({ iconName, iconColor }) => {
//   // Convert the iconName to PascalCase (e.g., 'home' to 'Home')
//   const formattedIconName =
//     iconName.charAt(0).toUpperCase() + iconName.slice(1);

//   // Check if the icon exists in the Icons object
//   if (Icons[formattedIconName]) {
//     const SelectedIcon = Icons[formattedIconName];
//     return <SelectedIcon sx={{ color: iconColor }} />;
//   } else {
//     // Handle case where the icon is not found
//     return <span>Icon not found</span>;
//   }
// };
const SideNav = ({ open, handleDrawerClose }) => {
  const baseService = JSON.parse(localStorage.getItem("baseService"));
  const pagesNew = useSelector((state) => state?.menus) || [];

  const selectedTabsNew = localStorage.getItem("selectedTabs");
  const navigate = useNavigate();
  const [pages, setPages] = useState([]);
  const [allPages, setAllPages] = useState([]);

  const [selectedTab, setSelectedTab] = useState("");
  const [allSelectedTabs, setAllSelectedTabs] = useState([]);

  const handleClick = (name, lastChildClicked) => {
    if (lastChildClicked) {
      setSelectedTab(name);
    }
    // Check if allSelectedTabs is an array or can be converted to one
    if (!Array.isArray(allSelectedTabs)) {
      return;
    }
    if (allSelectedTabs?.includes(name)) {
      setAllSelectedTabs(allSelectedTabs?.filter((x) => x !== name));
      localStorage.setItem(
        "selectedTabs",
        allSelectedTabs?.filter((x) => x !== name)
      );
    } else {
      setAllSelectedTabs((prevTabs) => [...prevTabs, name]);
      localStorage.setItem(
        "selectedTabs",
        allSelectedTabs?.filter((x) => x !== name)
      );
    }
    localStorage.setItem("selectedTab", name);
  };
  function createMenuTree(menuData) {
    const menuMap = {};
    // Create a map of menu items using their recordGuid as the key
    const menuDataWithChildren = menuData?.map((item) => ({
      ...item,
      children: [],
    }));

    // Update menuMap using the new objects
    menuDataWithChildren.forEach((item) => {
      const recordGuid = item.recordGuid;
      menuMap[recordGuid] = item;
    });
    // Organize the menu items into a tree structure
    const rootItems = [];
    menuDataWithChildren?.forEach((item) => {
      const parentGuid = item?.parentGuid;
      const groupId = item?.group;

      if (groupId === 2) {
        if (parentGuid !== null && menuMap[parentGuid]) {
          menuMap[parentGuid]?.children?.push(item);
        } else {
          rootItems?.push(item);
        }
      }
    });

    return rootItems;
  }

  const theme = useTheme();
  const currentPath = new URL(window.location.href).pathname;
  const pathWithoutSlash = currentPath.substring(1);
  useEffect(() => {
    let pagesObj = [];
    baseService?.map((item) => {
      if (item.pages && Array.isArray(item.pages)) {
        pagesObj.push(...item.pages);
      }
    });
    // setSelectedTab(
    //   pagesObj?.length > 0 && pagesObj[0]?.hasChildren
    //     ? pagesObj[0]?.children[0]?.name
    //     : pagesObj[0]?.name
    // );

    setSelectedTab(
      localStorage.getItem("selectedTab")
        ? localStorage.getItem("selectedTab")
        : localStorage.getItem("projectName") === "KeyStore"
        ? "url-store"
        : "data-seed"
    );
    const transformedPages = pagesObj
      ?.map((x) =>
        x?.hasChildren
          ? x?.children?.map((y) => ({
              parentPageName: y?.parentPageName,
              path: y?.path,
              name: y?.name,
            }))
          : []
      )
      .flat();

    // setAllSelectedTabs([
    //   transformedPages?.find((x) => x?.path === pathWithoutSlash)
    //     ?.parentPageName,
    // ]);
    setPages(pagesObj);

    setAllPages(
      pagesObj?.map((x) => ({
        ...x,
        ...(x?.hasChildren && { children: x?.children }),
      }))
    );
    // let selected = pagesObj?.find((x) => x.path === pathWithoutSlash)?.name;
    // console.log("pagesObjpagesObjpagesObj", pagesObj);
    // console.log(
    //   "pagesOballPagesallPagesallPagesallPages",
    //   pagesObj?.map((x) => ({
    //     ...(x?.hasChildren
    //       ? x?.children?.map((obj) => (obj))
    //       : { ...x }),
    //   }))
    // );
  }, [pathWithoutSlash]);

  let childrenPages = [],
    independentPages = [];

  pages?.forEach?.((page) => {
    if (page?.parent_page_name?.join("") != "") childrenPages?.push(page);
    else independentPages?.push(page); // it might have children, it might not
  });

  childrenPages =
    childrenPages &&
    childrenPages?.sort(
      (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
    );
  independentPages =
    independentPages &&
    independentPages?.sort(
      (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
    );

  // const shouldSideButtonsRender = childrenPages.some(
  //   ({ parent_page_name }) =>
  //     parent_page_name === independentPages[selectedTab]?.name
  // );

  let testpages =
    pagesNew?.length !== 0 &&
    createMenuTree(
      pagesNew
        ?.filter((x) => x?.group === 2)
        ?.sort((a, b) => a?.displayOrder - b?.displayOrder)
    );
  return (
    <div>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Grid container>
            <Grid item className="center" xs={12}>
              <img
                src={
                  localStorage.getItem("themeMode")
                    ? localStorage.getItem("themeMode") == "light"
                      ? Logo
                      : LogoWhite
                    : Logo
                }
                className="Logo-sidebar"
              />
            </Grid>
          </Grid>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List style={{ overflow: "auto" }}>
          {testpages!==false&&
            testpages?.map((menu, index) => (
              <>
                {menu?.children?.length === 0 ? (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2,
                      }}
                      onClick={() => {
                        navigate(menu?.uri, { replace: true });
                        handleClick(menu?.uri, true);
                      }}
                    >
                      <Tooltip title={!open ? menu?.uri : ""} placement="right">
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            style={{
                              fontSize: "18px",
                              color: "#c41035",
                            }}
                            className={`fa ${menu?.iconUri} menu-icon`}
                          />
                        </ListItemIcon>
                      </Tooltip>

                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        {console.log("menumenu", menu)}
                        <Typography
                          style={{
                            fontWeight: menu?.uri === selectedTab && "bold",
                            fontSize: "13px",
                            marginLeft: "-15px",
                            color:
                              menu?.uri === selectedTab
                                ? localStorage.getItem("primary") || "#c41035"
                                : "#595959",
                          }}
                        >
                          {menu.menuDetail[0].name}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        // justifyContent: open ? "initial" : "center",
                        px: open ? 2 : 1,
                      }}
                      onClick={() => handleClick(menu?.uri)}
                    >
                      <Tooltip title={!open ? menu?.uri : ""} placement="right">
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {/* <DynamicIcon iconColor={"#C41035"} iconName={icon} /> */}
                          <i
                            style={{
                              fontSize: "18px",

                              color: "#c41035",
                            }}
                            className={`fa ${menu?.iconUri} menu-icon`}
                          />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <Typography
                          style={{
                            fontSize: "13px",
                            color: "#595959",
                            marginLeft: "-15px",
                          }}
                        >
                          {menu.menuDetail[0].name}
                        </Typography>
                      </ListItemText>{" "}
                      {open && allSelectedTabs?.includes(menu?.uri) ? (
                        <ExpandLess sx={{ color: "#757575" }} />
                      ) : (
                        open && <ExpandMore sx={{ color: "#757575" }} />
                      )}
                    </ListItemButton>
                    <Collapse
                      in={allSelectedTabs?.includes(menu?.uri)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {menu?.children?.map((menu, index) => (
                          <ListItemButton
                            onClick={() => {
                              navigate(menu?.uri, { replace: true });
                              handleClick(menu?.uri, true);
                            }}
                            sx={{ pl: 4 }}
                          >
                            <Tooltip
                              title={!open ? menu?.uri : ""}
                              placement="right"
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : 0,
                                  justifyContent: open ? "center" : "auto",
                                  ml: open ? 2 : 0,
                                }}
                              >
                                <i
                                  style={{
                                    fontSize: "18px",

                                    color: "#c41035",
                                  }}
                                  className={`fa ${menu?.iconUri} menu-icon`}
                                />
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <Typography
                                style={{
                                  fontSize: "13px",
                                  fontWeight:
                                    menu?.uri === selectedTab && "bold",
                                  marginLeft: "-15px",

                                  color:
                                    menu?.uri === selectedTab
                                      ? localStorage.getItem("primary") ||
                                        "#c41035"
                                      : "#595959",
                                }}
                              >
                                {menu.menuDetail[0].name}
                              </Typography>
                            </ListItemText>{" "}
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </ListItem>
                )}
              </>
            ))}
        </List>
      </Drawer>
    </div>
  );
};

export default SideNav;
