import { Button, Typography } from "@mui/material";

function ButtonCard({ text,btnFunc }) {
  return (
    <Button
      className="contained-white-blue"
      onClick={btnFunc}
    >
      {/* <Add sx={{ fontSize: "17px", marginRight: "3px" }} /> */}
      <Typography sx={{ fontSize: "15px",fontWeight:"bold" }}>{text}</Typography>
    </Button>
  );
}

export default ButtonCard;
