import { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import axiosInstance from "../../../../axiosInstance";
import { GET_ALL_CLIENTS_ID, MULTI_TENANCY_CONFIG } from "../../../APIs";
import { CircularProgress } from "@mui/material";
const PAGE_SIZE = 20;

export default function Test({
  placeholder,
  value,
  setValue,
  setRefreshTable,
}) {
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [defaultOptions, setDefaultOptions] = useState([]);

  const loadDepartureOptions = async (search, loadedOptions, { page }) => {
    let newServices = JSON.parse(services);
    try {
      const response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_CLIENTS_ID}?Name=${
          search ? search : ""
        }&PageIndex=${page}&PageSize=${10}`,
        MULTI_TENANCY_CONFIG
      );
      const responseJSON = await response;
      const hasMore = (page - 1) * 10 < responseJSON?.data?.data?.totalRows;

      return {
        options:
          (responseJSON?.data?.data?.clientName || []).map((item) => ({
            ...item,
            value: item?.recordGuid,
            label: item?.name,
          })) || [],
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error fetching data:", error);
      // Return an empty options array if there's an error
      // loadDepartureOptions("", [], { page: 1 });

      return { options: [], hasMore: false };
    }
  };
  const getFirstData = () => {
    let newServices = JSON.parse(services);
    const response = axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_CLIENTS_ID}?&PageIndex=${1}&PageSize=${1}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setDefaultOptions({
          label: res?.data?.data?.clientName[0]?.name,
          value: res?.data?.data?.clientName[0]?.recordGuid,
        });
        setValue({
          label: res?.data?.data?.clientName[0]?.name,
          value: res?.data?.data?.clientName[0]?.recordGuid,
          recordGuid: res?.data?.data?.clientName[0]?.recordGuid,
        });
        setRefreshTable(true);

      })
      .catch((error) => {
        if (error === "error: 401") {
          getFirstData();
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
      });
  };
  useEffect(() => {
    // Load data when the component mounts
    getFirstData();
  }, []); // Empty dependency array ensures this runs only once on mount
  const LoadingIndicator = () => {
    return (
      <CircularProgress style={{ color: "grey" }} color="inherit" size={20} />
    );
  };
  const indicatorSeparatorStyle = {
    alignSelf: "stretch",
    backgroundColor: "Red",
    marginBottom: 8,
    marginTop: 8,
    width: 0,
  };
  const IndicatorSeparator = (innerProps) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  return (
    <>
      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>{placeholder}</span>
      <AsyncPaginate
        value={value !== "" ? value : defaultOptions}
        loadOptions={loadDepartureOptions || []}
        onChange={(event) => {
          setValue(event);
        }}
        styles={{
          IndicatorSeparator: () => null,

          control: (provided, state) => ({
            ...provided,
            borderRadius: 25,
            borderColor: state.isFocused ? "#CCCCCC" : provided.borderColor,
            boxShadow: state.isFocused ? "none" : "none",
            cursor: "pointer",

            "&:hover": {
              borderColor: "#CCCCCC",
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "white" : "#fffff",
            backgroundColor: state.isFocused ? "white" : "#fffff",

            color: "black",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: state.isSelected ? "#F5F5F5" : "#F5F5F5",
            },

            // border: "none", // Remove border on selection
          }),
        }}
        // defaultValue={value}
        additional={{
          page: 1,
        }}
        defaultOptions
        components={{ LoadingIndicator, IndicatorSeparator }}
      />
    </>
  );
}
