import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggle: false,
  logo: null,
  background: null,
  loginDirection: "right",
  portal_name: null,
  allowSignUp: false,
  parentGuid: null,
  COLORS: {
    primary: "#4203ee",
    secondary: "#ED204C",
    grey: "#d1d1d1",
    black: "#000",
    text_white: "#ffffff",
  },
  Commons: {
    Button: {
      Border: "1px",
    },
  },
};

const SASSSlice = createSlice({
  name: "SASS",
  initialState,
  reducers: {
    SetSASS: (state, action) => {
      return action?.payload;
    },
  },
});

export const { SetSASS } = SASSSlice.actions;
export default SASSSlice.reducer;
