import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    // backgroundColor: 'white',
    color:'grey', 
    background:"white",
    height:"15px",
    padding: theme.spacing(1), 
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      © 2024 Monty Mobile
    </footer>
  );
};

export default Footer;
