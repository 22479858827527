import * as Yup from "yup";
export const validationSchema = [
  Yup.object().shape({
    TypeTag: Yup.string().required("Type is required"),
    // BillingTag: Yup.string().required("Account type is required"),
    TelephoneNumber: Yup.string().matches(
      /^[0-9]{8,}$/,
      "Telephone number must be at least 8 digits"
    ),
    MobileNumber: Yup.string()
      .matches(/^[0-9]{8,}$/, "Mobile number must be at least 8 digits")
      .required("Mobile Number is required"),
    Email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    FirstName: Yup.string().required("First Name is required"),
    ContactType: Yup.string().required("Contact type is required"),
    // CompanyWebsite: Yup.string().url("Invalid URL format"),
    // CompanyEmail: Yup.string().email("Invalid email format"),
  }),
  Yup.object().shape({
    Address: Yup.string().required("Address is required"),
  }),
];

export const validationSchemaAdd = [
  Yup.object().shape({
    BillingTag: Yup.string().required("Account type is required"),

    TypeTag: Yup.string().required("Type is required"),
    // BillingTag: Yup.string().required("Billing tag is required"),
    TelephoneNumber: Yup.string().matches(
      /^[0-9]{8,}$/,
      "Telephone number must be at least 8 digits"
    ),
    MobileNumber: Yup.string()
      .matches(/^[0-9]{8,}$/, "Mobile number must be at least 8 digits")
      .required("Mobile Number is required"),
    Email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    FirstName: Yup.string().required("First Name is required"),
    ContactType: Yup.string().required("Contact type is required"),
    // CompanyWebsite: Yup.string().url("Invalid URL format"),
    // CompanyEmail: Yup.string().email("Invalid email format"),
  }),
  Yup.object().shape({
    Address: Yup.string().required("Address is required"),
  }),
];

export const validationSchemaEdit = [

  Yup.object().shape({
  BillingTag: Yup.string().required("Account type is required"),

    TypeTag: Yup.string().required("Type is required"),
    // BillingTag: Yup.string().required("Billing tag is required"),
    FirstName: Yup.string().required("First Name is required"),
    // CompanyWebsite: Yup.string().url("Invalid URL format"),
    // CompanyEmail: Yup.string().email("Invalid email format"),
  }),
  Yup.object().shape({
    Address: Yup.string().required("Address is required"),
  }),
];

export const validationSchemaLookupConfig = Yup.object().shape({
  ConnectivityTypeGuid: Yup.string().required("Connectivity Type is required"),
  ServiceLookupModeGuid: Yup.string().required(
    "Service Lookup Mode is required"
  ),
  NumberOfThreads: Yup.string().required("Number Of Threads is required"),
  Ip: Yup.string()
    .required("IP is required")
    .matches(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/, "Invalid IP address format"),
  Host: Yup.string().required("Host is required"),
  Port: Yup.string().required("Port is required"),
  QueryDns: Yup.string().required("Query DNS is required"),
});
export const validationSchemaSmtpConfig = Yup.object().shape({
  SmtpServer: Yup.string().required("Smtp Server is required"),
  SmtpUser: Yup.string().required("Smtp User is required"),
  SmtpPassword: Yup.string().required("Smtp Password is required"),
  SmtpPort: Yup.string().required("Smtp Port is required"),
  FromAddress: Yup.string().required("From Address is required"),
  ReplyAddress: Yup.string().required("Reply Address is required"),
});

export const validationSchemaWhatsappConfig = Yup.object().shape({
  // ConnectivityTypeGuid: Yup.string().required("Connectivity Type is required"),
  // NumberOfThreads: Yup.string().required("Number Of Threads"),
  // Host: Yup.string().required("Host is required"),
  // Port: Yup.string().required("Port is required"),
  // QueryDns: Yup.string().required("Query Dns key is required"),
  // ClientGuid: Yup.string().required("Client is required"),
  // ProviderGuid: Yup.string().required("Provider is required"),
  // ServiceLookupModeGuid: Yup.string().required(
  //   "Service Lookup Mode is required"
  // ),
});
export const validationSchemaSmppConfig = Yup.object().shape({
  Username: Yup.string().required("Username is required"),
  Password: Yup.string().required("Password is required"),
  IpAddress: Yup.string().required("IP Address is required").matches(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/, "Invalid IP address format"),
  SourceTon: Yup.string().required("Source Ton is required"),
  SourceNpi: Yup.string().required("Source Npi is required"),
  DestinationTon: Yup.string().required("Destination Ton is required"),
  DestinationNpi: Yup.string().required("Destination Npi is required"),
  ShortCodeTonNpi: Yup.string().required("Short Code Ton Npi is required"),
  MaximumRetry: Yup.string().required("Maximum Retry is required"),
  ConnectionToOpen: Yup.string().required("Connection To Open is required"),
  EnquireLink: Yup.string().required("Enquire Link is required"),
  SubmitPerSecond: Yup.string().required("Submit Per Second is required"),
  ConnectionMode: Yup.string().required("Connection Mode is required"),
  TranslationPrefix: Yup.string().required("Translation Prefix is required"),

});
