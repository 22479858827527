import { Box, Button, Switch } from "@mui/material";
import Table from "../../../Components/DynamicTable/Table";
import { useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";

export default function ViewApiKeys({open,setOpen,title}) {
  const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  let columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      // width: 200,
    },
    {
      field: "generateKey",
      headerName: "Active",
      flex: 0,

      // width: 100,
      renderCell: (params) => {
        return <Switch />;
      },
    },
  ];
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      content={
        <Box sx={{marginBottom:'10%'}}>
          <Table
            rowCountState={tableData?.totalCount}
            data={[{name:"Omni"}]}
            isLoading={isLoading}
            columns={columns}
            pageIndex={tableData?.pageIndex ? tableData?.pageIndex : 0}
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            disableActions={true}
            size={"sm"}
          />
        </Box>
      }
    />
  );
}
