import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import AllOutIcon from '@mui/icons-material/AllOut';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Slider,
  Modal,
  Tooltip,
  Typography,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
} from "@mui/material";
import Table from "../../../Components/DynamicTable/Table";
import LinearProgress from "@mui/material/LinearProgress";
import FlagIcon from "@mui/icons-material/Flag";
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IosShareIcon from "@mui/icons-material/IosShare";
import { styled } from "@mui/material/styles";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import SelectForm from '../../../Components/Selects/SelectsForm'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import PublicIcon from '@mui/icons-material/Public';
import NumbersIcon from '@mui/icons-material/Numbers';
function TowWay() {
  const [isLoading, setIsLoading] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    height: "110px",
    borderRadius: "32px",
    backgroundColor: "white",
    boxShadow: "none",
    color: theme.palette.text.secondary,
  }));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const tableData = [
    {
      id: 1,
      country: "Lebanon",
      messages: "1209",
      deliveryRate: "",
      cost: "1588",
    },
  ];
  let columns = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "messages",
      headerName: "Messages",
      flex: 1,
    },
    {
      field: "deliveryRate",
      headerName: "Delivery Rate",
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" value={50} />
            </Box>
            <Box sx={{ minWidth: 35 }}>{/* <span>50%</span> */}</Box>
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
    },
  ];
  const ChannelAnalyze = {
    chart: {
      renderTo: 'container',
      type: 'line',
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Tow Ways",
        data: [100, 200, 300, 400, 500],
      },
    ],
  };

  const DeliverytimebyNetwork = {
    chart: {
      renderTo: 'container',
      type: 'line',
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Alfa",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Touch",
        data: [2, 60, 100, 120, 10],
      },
    ],
  };

  const ShortCodeByCountry = {
    chart: {
      renderTo: 'container',
      type: 'line',
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Lebanon",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Greece",
        data: [2, 60, 100, 120, 10],
      },
      {
        name: "Egypt",
        data: [22, 78, 145, 120, 110],
      },
    ],
  };

  const ShortCodes = {
    chart: {
      type: 'column',
      height: 300
  },
  title: {
      text: null,
      align: 'left'
  },
  xAxis: {
      categories: null
  },
  yAxis: {
      min: 0,
      title: {
          text: 'Short Code'
      },
      stackLabels: {
          enabled: true
      }
  },
  credits: {
    enabled: false,
  },
  legend: {
      align: 'left',
      x: 70,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
  },
  tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
  },
  plotOptions: {
      column: {
          stacking: 'normal',
          dataLabels: {
              enabled: true
          }
      }
  },
  series: [{
      name: 'Clients',
      data: [3, 5, 1, 13]
  }, {
      name: 'Revenue',
      data: [14, 8, 8, 12]
  }, {
      name: 'Consumption',
      data: [0, 2, 6, 3]
  }]
  };

  const BreakdownbyStatus = {
    chart: {
      type: "pie",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    series: [
      {
        name: "Status",
        colorByPoint: true,
        data: [
          {
            name: "Delivery Success",
            y: 60,
            sliced: true,
            selected: true,
          },
          {
            name: "Failed",
            y: 20,
          },
          {
            name: "Failed Delivered",
            y: 10,
          },
          {
            name: "Received",
            y: 10,
          },
        ],
      },
    ],
  };
  const Shortcodes = [
    {
      label: "Reserved",
      // value: "578M",
      // share: "10.18%",
      change: 543,
      number: '1231',
      content: "Reserved short codes",
      type: 'reserved short'
    },
    {
      label: "Free",
      // value: "1.301B",
      // share: "30.39%",
      change: 218,
      number: '1231',
      content: "Free short codes",
      type: 'free'
    },
  ];

  const Longcodes = [
    {
      label: "Reserved",
      // value: "1.375B",
      // share: "31.02%",
      change: 164,
      number: '03666777 01999999',
      content: "Reserved long codes",
      type: 'reserved long'
    },
    {
      label: "Free",
      // value: "1.221B",
      // share: "28.45%",
      change: 235,
      number: '71232121 71002121',
      content: "Free long codes",
      type: 'free'
    }
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalNumber, setModalNumber] = useState('');
  const [isFree, setFree] = useState(false);
  const [long, setLong] = useState(false);
  const [freelong, setfreeLong] = useState(false);

  const [showSelect, setshowSelect] = useState(false);


  const handleOpenModal = (content, number) => {
    setModalContent(content);
    setModalNumber(number);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function getTitle() {
    let codeType = long ? 'Long Code' : 'Short Code';
    let status = isFree ? 'Free' : 'Reserved';
    return `${codeType} ${status}`;
}
  return (
    <Box className="smschart-page">
      <Grid container spacing={3}>
      <Grid item lg={6} xs={12}>
      <Item>
        <Typography className="code-title">
          Short Codes
        </Typography>
        <Grid container spacing={2}>
        <Grid item lg={3} xs={4}><NumbersIcon className="code-icon"/></Grid>
          {Shortcodes.map((stat, index) => (
            <Grid item sm={4} md={2} lg={4} key={index}>
              <Box display="flex" textAlign={'right'}>
                <Box flexGrow={1}>
                  <Typography
                    className="title-chart center"
                    onClick={() => { handleOpenModal(stat.content, stat.number); stat.type == 'free' ? setFree(true) : setFree(false); 
                    (stat.type == 'reserved long') ? setLong(true) : setLong(false)}}
                    sx={{
                      fontSize: "21px",
                      fontWeight: "bold",
                      color: "black",
                      display: "flex",
                      flexDirection: "row",
                      cursor: 'pointer',
                      alignItems: "center",
                    }}
                  >
                    {stat.label}
                  </Typography>
                  {/* <Typography>{stat.total}</Typography>
                  <Typography
                    className="title-chart"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "black",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {stat.value}
                  </Typography>
                  <Typography>Traffic share</Typography>
                  <Typography color="textSecondary">{stat.share}</Typography> */}
                  <Box>
                    {/* {stat.change > 0 && stat.type !== 'free' ? (
                      <ArrowUpwardIcon color="success" />
                    ) : stat.type !== 'free' ? (
                      <ArrowDownwardIcon color="error" />
                    ): ''} */}
                    <Typography
                      className="title-chart center"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {Math.abs(stat.change)}&nbsp;  {stat.change > 0 ? (
                        <StackedLineChartIcon
                          sx={{ fontSize: "20px" }}
                          color="success"
                        />
                      ) : (
                        <SsidChartIcon sx={{ fontSize: "20px" }} color="error" />
                      )}
                    </Typography>
                    {/* <Typography>From last month</Typography> */}
                    {/* <Typography>
                    <BarChartIcon sx={{fontSize:'40px'}}/>
                  </Typography> */}
                    {/* <Typography>
                      {stat.change > 0 ? (
                        <StackedLineChartIcon
                          sx={{ fontSize: "40px" }}
                          color="success"
                        />
                      ) : (
                        <SsidChartIcon sx={{ fontSize: "40px" }} color="error" />
                      )}
                    </Typography> */}
                  </Box>
                </Box>

              </Box>
            </Grid>
          ))}
        </Grid>
      </Item>
      </Grid>

      <Grid item lg={6} xs={12}>
      <Item>
        <Typography className="code-title">
          Long Codes
        </Typography>
        <Grid container spacing={2}>
        <Grid item lg={3}  xs={4}><NumbersIcon className="code-icon"/></Grid>
          {Longcodes.map((stat, index) => (
            <Grid item sm={4} md={2} lg={4} key={index}>
              <Box display="flex" textAlign={'right'}>
                <Box flexGrow={1}>
                  <Typography
                    className="title-chart center"
                    onClick={() => { handleOpenModal(stat.content, stat.number); stat.type == 'free' ? setFree(true) : setFree(false); 
                    (stat.type == 'reserved long') ? setLong(true) : setLong(false)}}
                    sx={{
                      fontSize: "21px",
                      fontWeight: "bold",
                      color: "black",
                      display: "flex",
                      flexDirection: "row",
                      cursor: 'pointer',
                      alignItems: "center",
                    }}
                  > 
                    {stat.label}
                  </Typography>
                  {/* <Typography>{stat.total}</Typography>
                  <Typography
                    className="title-chart"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "black",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {stat.value}
                  </Typography>
                  <Typography>Traffic share</Typography>
                  <Typography color="textSecondary">{stat.share}</Typography> */}
                  <Box>
                    {/* {stat.change > 0 && stat.type !== 'free' ? (
                      <ArrowUpwardIcon color="success" />
                    ) : stat.type !== 'free' ? (
                      <ArrowDownwardIcon color="error" />
                    ): ''} */}
                    <Typography
                      className="title-chart center"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {Math.abs(stat.change)}&nbsp;  {stat.change > 0 ? (
                        <StackedLineChartIcon
                          sx={{ fontSize: "20px" }}
                          color="success"
                        />
                      ) : (
                        <SsidChartIcon sx={{ fontSize: "20px" }} color="error" />
                      )}
                    </Typography>
                    {/* <Typography>From last month</Typography> */}
                    {/* <Typography>
                    <BarChartIcon sx={{fontSize:'40px'}}/>
                  </Typography> */}
                    {/* <Typography>
                      {stat.change > 0 ? (
                        <StackedLineChartIcon
                          sx={{ fontSize: "40px" }}
                          color="success"
                        />
                      ) : (
                        <SsidChartIcon sx={{ fontSize: "40px" }} color="error" />
                      )}
                    </Typography> */}
                  </Box>
                </Box>

              </Box>
            </Grid>
          ))}
        </Grid>
      </Item>
      </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FlagIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />&nbsp;
                  <span>Short Code By Country</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={ShortCodeByCountry}
            />
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <NumbersIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />&nbsp;
                  <span>Short Codes</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={ShortCodes}
            />
          </Paper>
        </Grid>
      </Grid>


      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
      >

        <Box sx={modalStyle}>
          <Typography onClick={handleCloseModal} sx={{ position: 'absolute', right: '10px', top: '0', fontWeight: 'bold', fontSize: '17px', cursor: 'pointer' }}>x</Typography>
          <>
          <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '-33px', color:'black',fontWeight:'bold' }}>
        {getTitle()}
    </Typography>
    <br/>
          {isFree ?

            <Grid container>
              {/* <Typography> {long ? 'Free' : '2321'}</Typography> */}
              <Grid item xs={2} marginTop={1}>
               <Typography className="reserved-name">
               {long ? '124124512' : '2321'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button onClick={() => {
                  setshowSelect(true)
                }} variant="outlined">Assign</Button>
              </Grid>
              {showSelect &&
                <Grid sx={{marginTop:'-25px'}} item xs={6}>
                 <SelectForm placeholder={"Client"} />
                </Grid>
              }
            </Grid>
            :
            <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Card className="channel-card">
                    <Grid container>
                      <Grid borderRight={'1px solid black'} item xs={6}>
                        <Typography className="reserved-name center">
                         {long ? 'Long Code' : 'Short Code'}
                        </Typography>
                        <Typography className="center">
                          {long ? '124124512' : '2321'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="reserved-name center">
                          Client
                        </Typography>
                        <Typography className="center">
                          Touch
                        </Typography>
                        </Grid>
                      </Grid>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className="channel-card">
                    <Grid container>
                      <Grid borderRight={'1px solid black'} item xs={6}>
                        <Typography className="reserved-name center">
                        {long ? 'Long Code' : 'Short Code'}
                        </Typography>
                        <Typography className="center">
                        {long ? '543543345' : '5467'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="reserved-name center">
                          Client
                        </Typography>
                        <Typography className="center">
                          Touch
                        </Typography>
                        </Grid>
                      </Grid>
                  </Card>
                </Grid>
            </Grid>

          }
          
          </>
          

        </Box>

      </Modal>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />&nbsp;
                  <span>Channel Analyze</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact highcharts={Highcharts} options={ChannelAnalyze} />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />&nbsp;
                  <span>Breakdown by Status</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={BreakdownbyStatus}
            />
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AccessAlarmIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />&nbsp;
                  <span>Delivery time by Network</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={DeliverytimebyNetwork}
            />
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container sx={{ position: 'relative' }}>
        <Grid item xs={12}>
          <Paper className="paper-channel">
            <Grid container sx={{ position: 'absolute', zIndex: '200' }}>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FlagIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />&nbsp;
                  <span>  Delivery Rate by Country</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "40px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Table
              rowCountState={tableData?.totalCount}
              data={tableData}
              // isLoading={isLoading}
              columns={columns}
              // enableExportBtn={true}
              disableActions={true}
              pageIndex={tableData?.pageIndex ? tableData?.pageIndex : 0}
              setPaginationModel={setPaginationModel}
              paginationModel={paginationModel}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
export default TowWay;
