import Add from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../../Components/Dialog/ConfirmationModal";
import Table from "../../../../Components/DynamicTable/Table";
import TextFieldForm from "../../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../../axiosInstance";

import CustomizedDialogs from "../../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../../Components/Selects/SelectFormNew";
import {
  EXPORT_MNP_HLR,
  GET_ALL_CLIENTS_CATEGORY,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_MNP_HLR,
  GET_ALL_OPERATORS_OPTIONS,
  GET_ALL_PROVIDER,
  GET_ALL_REASONS,
  MANAGE_MNP_HLR,
  MULTI_TENANCY_CONFIG,
} from "../../../APIs";
import ManageMnpHlr from "./ManageMnpHlr";
const MnpHlr = ({ serviceGuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [filteredData, setFilteredData] = useState({});

  const [rowsSelectedData, setRowsSelectedData] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  // Resellers
  const [isModalOpenSwitchSmsOneWay, setModalOpenSmsOneWay] = useState(false);
  const [isCheckedSwitchSmsOneWay, setCheckedSwitchSmsOneWay] = useState(false);
  const [switchStatesSmsOneWay, setswitchStatesSmsOneWay] = useState({});
  const [MangeSmsOneWayAddopen, setMangeSmsOneWayAddopen] = useState(false);
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [MangeSmsOneWayEditopen, setMangeSmsOneWayEditopen] = useState(false);
  const [clientCategorySearch, setClientCategorySearch] = useState("");
  const [ClientCategoryOptions, setClientCategoryOptions] = useState([]);
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [resetPagination, setResetPagination] = useState(false);

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonOptions, setReasonOptions] = useState(false);
  const [description, setDescription] = useState("");

  const [clientCategoryOptionsSearch, setClientCategoryOptionsSearch] =
    useState(false);

  const [countryOptionsSearch, setCountryOptionsSearch] = useState(false);
  const [countryOptionsRes, setCountryOptionsRes] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [providerOptionsSearch, seProviderOptionsSearch] = useState(false);
  const [operatorOptionsSearch, setOperatorOptionsSearch] = useState([]);
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  const [operatorSearch, setOperatorSearch] = useState("");
  const [countrySearch, setCountrySearch] = useState("");
  const [isoCode, setIsoCode] = useState("");

  const [providerSearch, setProviderSearch] = useState("");
  const [block, setBlock] = useState("");
  const [lock, setLock] = useState("");

  const [blockOptions, setblockOptions] = useState([
    { label: "All", value: "" },
    { label: "Blocked", value: "Block" },
    { label: "Unblocked", value: "Unblock" },
  ]);
  const [lockOptions, setLockOptions] = useState([
    { label: "All", value: "" },
    { label: "Locked", value: "Lock" },
    { label: "Unlocked", value: "Unlock" },
  ]);
  const [mncSearch, setMncSearch] = useState("");
  const [mccSearch, setMccSearch] = useState("");

  const handleSwitchChangeSwitchSmsOneWay = (rowId) => {
    setModalOpenSmsOneWay(true);
    setCheckedSwitchSmsOneWay(rowId);
  };

  const handleModalCloseSwitchSmsOneWay = () => {
    setModalOpenSmsOneWay(false);
  };
  const handleModalConfirmSwitchSmsOneWay = () => {
    setModalOpenSmsOneWay(false);
    // setCheckedSwitchSmsOneWay(!isCheckedSwitchSmsOneWay);
    setswitchStatesSmsOneWay((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchSmsOneWay]: !prevStates[isCheckedSwitchSmsOneWay],
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //
  const [Client, setClient] = React.useState("9mobile");
  const [isCheckedSwitchLocationZone, setCheckedSwitchLocationZone] =
    useState(false);
  const [isModalOpenSwitchLocationZone, setModalOpenSwitchLocationZone] =
    useState(false);

  const [isCheckedSwitchLock, setCheckedSwitchLock] = useState(false);
  const [isModalOpenSwitchLock, setModalOpenSwitchLock] = useState(false);
  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [SmsOneWay, setSmsOneWay] = React.useState("9mobile");
  const handleSwitchChangeSwitchLocationZone = (data) => {
    setReasonOptions(false);
    getAllReasons(
      data?.isBlocked ? "ROUTING_UNBLOCK_REASON" : "ROUTING_BLOCK_REASON"
    );
    setModalOpenSwitchLocationZone(!isModalOpenSwitchLocationZone);
    setCheckedSwitchLocationZone(data?.recordGuid);
    setSelectedSms(data);
  };

  const handleSwitchChangeSwitchLock = (data) => {
    setDescription("");
    setReason("");
    setReasonOptions(false);

    getAllReasons(
      data?.isLocked ? "ROUTING_UNLOCK_REASON" : "ROUTING_LOCK_REASON"
    );
    setModalOpenSwitchLock(!isModalOpenSwitchLock);
    setCheckedSwitchLock(data?.recordGuid);
    setSelectedSms(data);
  };
  const handleChangeSmsOneWay = (event) => {
    setSmsOneWay(event.target.value);
  };
  const [switchStatesLocationZone, setSwitchStatesLocationZone] = useState({});
  const [switchStatesLock, setSwitchStatesLock] = useState({});
  const [clientCategoryRatesOptions, setClientCategoryRatesOptions] = useState(
    []
  );
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);

  const [tableDataSmsOneWay, setTableDataSmsOneWay] = useState({
    pageIndex: 1,
    data: [],
  });

  let columnsSmsOneWay = [
    {
      field: "clientCategoryName",
      headerName: "Client Category",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "operatorName",
      headerName: "Operator",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "countryName",
      headerName: "Country",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "providerName",
      headerName: "Provider",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mnc",
      headerName: "MNC",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mcc",
      headerName: "MCC",
      flex: 1,
      minWidth: 100,
    },

    {
      headerName: "Block",
      field: "isBlocked",
      minWidth: 100,

      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.row?.isBlocked}
                  disabled={params?.row?.isLocked}
                  onChange={() =>
                    handleSwitchChangeSwitchLocationZone(params.row)
                  }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      headerName: "Lock",
      field: "isLocked",
      minWidth: 100,

      renderCell: (params) => {
        const rowId = params.row.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params?.row?.isLocked}
                  onChange={() => handleSwitchChangeSwitchLock(params.row)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  // Delete Modal Resellers functions
  const [ConfirmOpenSmsOneWay, setConfirmOpenSmsOneWay] = useState(false);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(
    windowWidth > 1000 ? true : false
  );
  const [smsOneWaySearch, setSmsOneWaySearch] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [searchClick, setSearchClick] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);

  const [modalContentSmsOneWay, setmodalContentSmsOneWay] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  {
    console.log("searchClicksearchClick", searchClick);
  }
  const handleDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${MANAGE_MNP_HLR}/${selectedSms?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTable(true);
        setConfirmOpenSmsOneWay(false);
        setIsLoading(false);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMultiDelete = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let data = rowsSelectedData?.map((x) => x.recordGuid);
    console.log("Dataaa", data);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${MANAGE_MNP_HLR}`,
        {
          data: { recordGuids: data }, // or simply: data
          ...MULTI_TENANCY_CONFIG,
        }
      )
      .then((res) => {
        setRefreshTable(true);
        setDeleteAllModalOpen(false);
        setIsLoading(false);
        setSelectedRows([]);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleMultiDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalSmsOneWay = (data, title, text, onButtonClick) => {
    setmodalContentSmsOneWay({ title, text, onButtonClick });
    setConfirmOpenSmsOneWay(true);
    setSelectedSms(data);
  };

  const handleConfirmModalSmsOneWay = () => {
    setConfirmOpenSmsOneWay(false);
  };
  const handleOpenAdvancedSearch = () => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  };
  const handleResetAll = () => {
    setSearchClick(false);
    setMccSearch("");
    setMncSearch("");
    setProviderSearch("");
    setCountrySearch("");
    setOperatorSearch("");
    setBlock("");
    setLock("");
    setClientCategorySearch(
      clientCategoryOptionsSearch !== false &&
        clientCategoryOptionsSearch[0].value
    );
    setRefreshTable(true);
  };
  //
  // Manage Resellers
  const [ManageSmsOneWayAddopen, setManageSmsOneWayAddopen] = useState(false);
  const [ManageSmsOneWayEditopen, setManageSmsOneWayEditopen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [CountryOptionsRecordGuid, setCountryOptionsRecordGuid] = useState([]);
  const [selectedSms, setSelectedSms] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [providerCategoryOptions, setProviderCategoryOptions] = useState([]);
  const handleAddManageSmsOneWayopen = () => {
    setManageSmsOneWayAddopen(true);
  };
  const handleModalConfirmSwitchBlock = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    let body = {
      recordGuid: selectedSms?.recordGuid,
      ReasonGuid: reason,
      Description: description ? description : "",
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${MANAGE_MNP_HLR}/${
          selectedSms?.isBlocked === true ? "Unblock" : "Block"
        }`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Updated",
          type: "success",
        });
        setRefreshTable(true);
        setModalOpenSwitchLocationZone(false);
        // setRefreshTable(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleModalConfirmSwitchBlock(isModalOpenSwitchLock);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleModalConfirmSwitchLock = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let body = {
      recordGuid: selectedSms?.recordGuid,
      ReasonGuid: reason,
      Description: description ? description : "",
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${MANAGE_MNP_HLR}/${
          selectedSms?.isLocked === true ? "Unlock" : "Lock"
        }`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Updated",
          type: "success",
        });

        setRefreshTable(true);
        setModalOpenSwitchLock(false);
        // setRefreshTable(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleModalConfirmSwitchLock(isModalOpenSwitchLock);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterSmsOneWay = (client) => {
    setIsLoading(true);
    setSelectedRows([]);
    setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["routing"]
        }${GET_ALL_MNP_HLR}?PageIndex=${
          resetPagination ? 1 : paginationModel?.page + 1
        }&PageSize=${paginationModel?.pageSize}&ClientCategoryGuid=${
          client ? client : clientCategorySearch
        }&CountryGuid=${countrySearch ? countrySearch : ""}&OperatorGuid=${
          operatorSearch ? operatorSearch : ""
        }&ProviderGuid=${providerSearch ? providerSearch : ""}&Blocked=${
          block === "Block" ? true : block === "Unblock" ? false : ""
        }&Locked=${
          lock === "Lock" ? true : lock === "Unlock" ? false : ""
        }&Mcc=${mccSearch ? mccSearch : ""}&Mnc=${
          mncSearch ? mncSearch : ""
        }&Keyword=${smsOneWaySearch ? smsOneWaySearch : ""}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setFilteredData({
          client: client ? client : clientCategorySearch,
          countrySearch: countrySearch,
          operatorSearch: operatorSearch,
          providerSearch: providerSearch,
          block: block === "Block" ? true : block === "Unblock" ? false : "",
          lock: lock === "Lock" ? true : lock === "Unlock" ? false : "",
          mcc: mccSearch,
          mnc: mncSearch,
          smsOneWaySearch: smsOneWaySearch,
        });
        setTableDataSmsOneWay({
          data: res?.data?.data?.data ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          tableQueryExecuterSmsOneWay();
        }
        setTableDataSmsOneWay({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackToTable = () => {
    setManageSmsOneWayAddopen(false);
    setManageSmsOneWayEditopen(false);
  };
  const handleAddMangeRequestclose = () => {
    setManageSmsOneWayAddopen(false);
  };
  const handleEditManageSmsOneWayopen = () => {
    setManageSmsOneWayEditopen(true);
  };

  const handleEditMangeRequestclose = () => {
    setManageSmsOneWayEditopen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const handleModalCloseSwitchLocationZone = () => {
    setModalOpenSwitchLocationZone(false);
  };
  const handleModalCloseSwitchLock = () => {
    setModalOpenSwitchLock(false);
  };
  const handleModalCloseDeleteAll = () => {
    setDeleteAllModalOpen(false);
  };
  const handleAddMangeSmsOneWayopen = () => {
    setMangeSmsOneWayAddopen(true);
  };
  const handleDeleteAllopen = () => {
    setDeleteAllModalOpen(true);
  };
  const handleEditMangeSmsOneWayopen = (data) => {
    setSelectedSms(data);
    setMangeSmsOneWayEditopen(true);
  };

  const getAllProvidersCategory = () => {
    seProviderOptionsSearch(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .get(
        `${localStorage.getItem(
          "baseUrl"
        )}${GET_ALL_PROVIDER}?RecordGuid=${serviceGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        seProviderOptionsSearch([
          ...res?.data?.data?.items.map((x, i) => ({
            label: x.name ? x.name : "",
            value: x.recordGuid,
          })),
        ]);
        setProviderCategoryOptions([
          ...res?.data?.data?.items.map((x, i) => ({
            label: x.name ? x.name : "",
            value: x.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProvidersCategory();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllClientsCategory = () => {
    setClientCategoryOptionsSearch(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_CLIENTS_CATEGORY}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setClientCategoryOptionsSearch([
          ...res?.data?.data?.clientCategories?.map((x, i) => ({
            label: x.clientCategoryDetails
              ? x.clientCategoryDetails[0]?.name
              : "",
            value: x.recordGuid,
          })),
        ]);
        setClientCategorySearch(
          res?.data?.data?.clientCategories
            ? res?.data?.data?.clientCategories[0]?.recordGuid
            : ""
        );
        tableQueryExecuterSmsOneWay(
          res?.data?.data?.clientCategories[0]?.recordGuid
        );

        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllClientsCategory();
        }
      })
      .finally(() => {});
  };

  const getAllReasons = (type) => {
    setReason("");
    setDescription("");
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let body = {
      categoryTags: [type],
    };
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_REASONS}?categoryTags=${[type]}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setReasonOptions([
          ...res?.data?.data?.criteria?.map((reason, i) => ({
            label: reason.name,
            value: reason.recordGuid,
          })),
        ]);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllReasons(type);
        }
      })
      .finally(() => {});
  };
  const getAllCountries = () => {
    setCountryOptionsSearch(false);
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptionsSearch(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryOptionsRes(
          res?.data?.data?.countries?.map((x) => ({
            label: x?.recordGuid,
            value: x?.recordGuid,
          }))
        );
        setOperatorOptions([]);
        setOperatorSearch("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  const getAllOperatorName = () => {
    setOperatorOptionsSearch([]);
    setIsLoadingOperator(true);
    let newServices = JSON.parse(services);
    {
      setOperatorSearch("");
    }

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${isoCode}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptionsSearch(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
        setIsLoadingOperator(false);
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };
  useEffect(() => {
    if (isoCode !== "") {
      setOperatorOptions([]);
      getAllOperatorName();
    }
  }, [isoCode]);

  useEffect(() => {
    setOperatorOptionsSearch([]);
    setOperatorSearch("");
    if (countrySearch !== "") {
      setOperatorOptions([]);
      let isoCodefinded = countryOptionsRes?.find(
        (x) => x.label === countrySearch
      )?.value;
      setIsoCode(isoCodefinded);
    } else {
      setIsoCode("");
    }
  }, [countrySearch]);
  useEffect(() => {
    getAllClientsCategory();
    // getAllProvidersCategory();
    getAllCountries();
    // getAllOperatorName();
  }, []);
  useEffect(() => {
    if (serviceGuid) {
      getAllProvidersCategory();
    }
  }, [serviceGuid]);
  useEffect(() => {
    if (smsOneWaySearch !== "") {
      setResetPagination(true);
    } else {
      setResetPagination(false);
    }
  }, [smsOneWaySearch]);

  useEffect(() => {
    if (smsOneWaySearch !== "") {
      setPaginationModel({
        page: 0,
        pageSize: paginationModel?.pageSize,
      });
    }
  }, [smsOneWaySearch]);

  useEffect(() => {
    if (clientCategorySearch !== "") {
      tableQueryExecuterSmsOneWay();
      setSelectedRows([]);
    }
    if (smsOneWaySearch === "") {
      tableQueryExecuterSmsOneWay();
    }
  }, [refreshTable, paginationModel, smsOneWaySearch]);
  const exportSms = (searchClick) => {
    setIsLoading(true);
    setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    {
      console.log(
        "clientCategoryOptionsSearchclientCategoryOptionsSearch",
        clientCategoryOptionsSearch
      );
    }
    axiosInstance
      .get(
        !searchClick
          ? `${localStorage.getItem("baseUrl")}${
              newServices["routing"]
            }${EXPORT_MNP_HLR}?ClientCategoryGuid=${
              filteredData?.client ? filteredData?.client : ""
            }`
          : `${localStorage.getItem("baseUrl")}${
              newServices["routing"]
            }${EXPORT_MNP_HLR}?ClientCategoryGuid=${
              filteredData?.client ? filteredData?.client : ""
            }&CountryGuid=${
              filteredData?.countrySearch ? filteredData?.countrySearch : ""
            }&OperatorGuid=${
              filteredData?.operatorSearch ? filteredData?.operatorSearch : ""
            }&ProviderGuid=${
              filteredData?.providerSearch ? filteredData?.providerSearch : ""
            }&Blocked=${filteredData?.block}&Locked=${filteredData?.lock}&Mcc=${
              filteredData?.mcc
            }&Mnc=${filteredData?.mnc}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenExportModal(false);
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `SMS.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setOpenExportModal(false);
        setIsLoading(false);
      });
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <Grid style={{ marginTop: "-80px" }} container>
        <Grid item xs={12} md={6} className="centerresponsive"></Grid>
        <Grid item xs={12} md={6} className="end">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} className="end"></Grid>

            <Grid item xs={12} md={6} className="end">
              <Button
                variant="contained"
                className="contained"
                onClick={handleDeleteAllopen}
                style={{
                  transition: "all .2s",
                  marginRight: "8px",
                  opacity: selectedRows.length === 0 ? "0" : "1",
                  visibility: selectedRows.length === 0 && "hidden",
                }}
              >
                <DeleteIcon sx={{ fontSize: "17px" }} /> &nbsp;
                <Typography
                  sx={{ fontSize: "14px", marginRight: "3px", padding: "2px" }}
                >
                  Delete All
                </Typography>
              </Button>
              <Button
                variant="contained"
                className="contained"
                onClick={handleAddMangeSmsOneWayopen}
              >
                <Add sx={{ fontSize: "17px" }} /> &nbsp;
                <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
                  Add Route
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {console.log("rowsSelectedDataaa", rowsSelectedData)}
      <Grid container className="Top-spacing div-table">
        <Grid style={{ marginTop: "10px" }} item xs={12}>
          <Paper variant="CardTable" className="page-paper">
            <Table
              // isLoading={isLoading}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              rowCountState={tableDataSmsOneWay?.totalCount}
              data={tableDataSmsOneWay?.data}
              isLoading={isLoading}
              columns={columnsSmsOneWay}
              pageIndex={
                tableDataSmsOneWay?.pageIndex
                  ? tableDataSmsOneWay?.pageIndex
                  : 0
              }
              setPaginationModel={setPaginationModel}
              paginationModel={paginationModel}
              enableDelete={true}
              enableEdit={true}
              deleteFunction={OpenConfirmModalSmsOneWay}
              editFunction={handleEditMangeSmsOneWayopen}
              enableSearch={true}
              enableExportBtn={true}
              // exportFunction={exportSms}
              exportFunction={() => setOpenExportModal(true)}
              valueFilter={Client}
              setValueFilter={setClient}
              advancedSearchFunction={handleOpenAdvancedSearch}
              advancedSearch={openAdvancedSearch}
              handleSearchBtn={() => {
                setSearchClick(true);
                setSelectedRows([]);
                setRefreshTable(true);
                setPaginationModel({
                  page: 0,
                  pageSize: paginationModel?.pageSize
                    ? paginationModel?.pageSize
                    : 10,
                });
              }}
              search={smsOneWaySearch}
              setSearch={setSmsOneWaySearch}
              setRowsSelectedData={setRowsSelectedData}
              rowsSelectedData={rowsSelectedData}
              enableCheckboxSelection={true}
              handleResetAll={handleResetAll}
              contentAdvancedSearch={
                <Grid spacing={3} container>
                  <Grid lg={3} xs={12} item>
                    <SelectFormNew
                      value={clientCategorySearch}
                      setValue={setClientCategorySearch}
                      options={clientCategoryOptionsSearch}
                      placeholder={"Client Category"}
                      selected={true}
                      disabled={clientCategoryOptionsSearch?.length === 0}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <SelectFormNew
                      value={countrySearch}
                      setValue={setCountrySearch}
                      options={countryOptionsSearch}
                      placeholder={"Country"}
                      disabled={countryOptionsSearch?.length === 0}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <SelectFormNew
                      value={operatorSearch}
                      setValue={setOperatorSearch}
                      options={operatorOptionsSearch}
                      placeholder={"Operator"}
                      disabled={operatorOptionsSearch?.length === 0}
                      isLoading={isLoadingOperator}
                      wait={"select a country"}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <SelectFormNew
                      value={providerSearch}
                      setValue={setProviderSearch}
                      options={providerOptionsSearch}
                      placeholder={"Provider"}
                      disabled={providerOptionsSearch?.length === 0}
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <TextFieldForm
                      placeholder={"MNC"}
                      value={mncSearch}
                      setValue={setMncSearch}
                      positiveNumberFloat
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <TextFieldForm
                      placeholder={"MCC"}
                      value={mccSearch}
                      setValue={setMccSearch}
                      positiveNumberFloat
                      changeFct={() => setSearchClick(false)}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <SelectFormNew
                      value={block}
                      setValue={setBlock}
                      options={blockOptions}
                      placeholder={"Block"}
                      changeFct={() => setSearchClick(false)}
                      wait={"select a reason"}
                    />
                  </Grid>
                  <Grid lg={3} xs={12} item>
                    <SelectFormNew
                      value={lock}
                      setValue={setLock}
                      options={lockOptions}
                      placeholder={"Lock"}
                      changeFct={() => setSearchClick(false)}
                      wait={"select a reason"}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Paper>
        </Grid>
      </Grid>
      {handleAddMangeSmsOneWayopen && (
        <ManageMnpHlr
          open={MangeSmsOneWayAddopen}
          setOpen={setMangeSmsOneWayAddopen}
          title={"Add Route"}
          clientCategoryOptionsSearch={clientCategoryOptionsSearch}
          countryOptionsSearch={countryOptionsSearch}
          operatorOptionsSearch={operatorOptionsSearch}
          providerOptionsSearch={providerOptionsSearch}
          isoCode={isoCode}
          countryOptionsRes={countryOptionsRes}
          setPaginationModel={setPaginationModel}
          setRefreshTable={setRefreshTable}
          setSmsOneWaySearch={setSmsOneWaySearch}
          paginationModel={paginationModel}
        />
      )}
      {handleEditMangeSmsOneWayopen && (
        <ManageMnpHlr
          open={MangeSmsOneWayEditopen}
          setOpen={setMangeSmsOneWayEditopen}
          title={"Edit Route"}
          isEdit={true}
          clientCategoryOptionsSearch={clientCategoryOptionsSearch}
          countryOptionsSearch={countryOptionsSearch}
          operatorOptionsSearch={operatorOptionsSearch}
          providerOptionsSearch={providerOptionsSearch}
          isoCode={isoCode}
          countryOptionsRes={countryOptionsRes}
          setPaginationModel={setPaginationModel}
          setRefreshTable={setRefreshTable}
          setSmsOneWaySearch={setSmsOneWaySearch}
          selectedSms={selectedSms}
          paginationModel={paginationModel}
        />
      )}

      {/* Delete Resellers Modal */}
      {handleConfirmModalSmsOneWay && (
        <ConfirmationModal
          open={ConfirmOpenSmsOneWay}
          onClose={handleConfirmModalSmsOneWay}
          title="Delete"
          text="Are you sure you want to delete this record?"
          onButtonClick={handleDelete}
        />
      )}
      {/* {handleEditManageSmsOneWayopen && (
        <AddReseller
          open={ManageSmsOneWayEditopen}
          setOpen={setManageSmsOneWayEditopen}
          title={"Edit Resellers"}
        />
      )} */}

      {/* Swtich Resellers Modal */}
      {isModalOpenSwitchSmsOneWay && (
        <ConfirmationModal
          open={isModalOpenSwitchSmsOneWay}
          onClose={handleModalCloseSwitchSmsOneWay}
          title="Change Status"
          text="Are you sure you want to change this Resellers Status?"
          onButtonClick={handleModalConfirmSwitchSmsOneWay}
        />
      )}
      {openExportModal && (
        <CustomizedDialogs
          open={openExportModal}
          setOpen={setOpenExportModal}
          title={"Export Data "}
          firstActionBtnName={"All Data"}
          secondActionBtnName={"Filtered Data"}
          firstActionBtnFct={() => {
            exportSms(false); // Added parentheses to invoke the function
          }}
          secondActionBtn={() => {
            // setSearchClick(true);
            exportSms(true); // Added parentheses to invoke the function
          }}
          content={
            <Typography style={{ textAlign: "center" }}>
              Do you want to export all data for this client category ?
            </Typography>
          }
        />
      )}

      {isModalOpenSwitchLocationZone && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationZone}
          onClose={handleModalCloseSwitchLocationZone}
          title={selectedSms?.isBlocked ? "Unblock " : "Block "}
          disableBtn={
            reasonOptions !== false &&
            reasonOptions?.find((x) => x.value === reason)?.label === "Other"
              ? reason === "" || description === ""
              : reason === ""
          }
          content={
            <>
              <SelectFormNew
                value={reason}
                setValue={setReason}
                options={reasonOptions}
                placementTop={true}
                placeholder={`Please select reason of 
              ${selectedSms?.isBlocked ? "unblock " : "block "}
              `}
                disabled={reasonOptions !== false && reasonOptions.length === 0}
              />
              {reasonOptions !== false &&
                reasonOptions?.find((x) => x.value === reason)?.label ===
                  "Other" && (
                  <TextFieldForm
                    placeholder={"Description"}
                    value={description}
                    setValue={setDescription}
                  />
                )}
            </>
          }
          onButtonClick={handleModalConfirmSwitchBlock}
        />
      )}

      {isModalOpenSwitchLock && (
        <ConfirmationModal
          open={isModalOpenSwitchLock}
          onClose={handleModalCloseSwitchLock}
          title={selectedSms?.isLocked ? "Unlock " : "Lock "}
          disableBtn={
            reasonOptions !== false &&
            reasonOptions?.find((x) => x.value === reason)?.label === "Other"
              ? reason === "" || description === ""
              : reason === ""
          }
          content={
            <>
              <SelectFormNew
                value={reason}
                setValue={setReason}
                options={reasonOptions}
                placementTop={true}
                placeholder={`Please select reason of 
              ${selectedSms?.isLocked ? "unlock " : "lock "}
              `}
                disabled={reasonOptions.length === 0}
              />
              {reasonOptions !== false &&
                reasonOptions?.find((x) => x.value === reason)?.label ===
                  "Other" && (
                  <TextFieldForm
                    placeholder={"Description"}
                    value={description}
                    setValue={setDescription}
                  />
                )}
            </>
          }
          onButtonClick={handleModalConfirmSwitchLock}
        />
      )}
      {deleteAllModalOpen && (
        <ConfirmationModal
          open={deleteAllModalOpen}
          onClose={handleModalCloseDeleteAll}
          title="Delete All"
          text="Are you sure you want to delete all selected records?"
          onButtonClick={handleMultiDelete}
        />
      )}
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
      {/* <Test /> */}
    </div>
  );
};

export default MnpHlr;
