import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText, Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({
  value,
  setValue,
  options,
  placeholder,
  multiple,
}) {
  const theme = useTheme();
  //   const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>{placeholder}</span>

      <FormControl
        sx={{
          width: 300,
          height: 35,
          borderRadius: "32px",
          border: "1px solid #E0E0E0 ",
          width: "100%",
          "& .MuiOutlinedInput-input": {
            padding: "7px 0px 0px 0px",
          },
        }}
      >
        <Select
          // labelId="demo-multiple-checkbox-label"
          // id="demo-multiple-checkbox"
          multiple={true}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox
                style={{ color: "#C61035" }}
                checked={value.indexOf(item.value) > -1}
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
