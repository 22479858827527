import "./App.scss";
// import { Counter } from './features/counter/Counter';
import { ThemeProvider } from "@mui/material/styles";
import Routes from "./CombinedRoutes";
import Theme from "./Components/Theme/Theme";
import { useEffect, useState , useRef } from "react";
import GetTheme from "./Components/Theme/Theme";
import i18n from "./i18n-config/i18n";
import { useTheme } from "./Components/Theme/Context";
import { clearCache } from 'react-clear-cache';

function App() {
  const [darkMode, setdarkMode] = useState(false);
  const { theme, setTheme } = useTheme();
  const currentTheme = GetTheme(theme);
  const handleChangeMode = (value) => {
    setdarkMode(value);
  }; useEffect(() => {
    // Clear all cookies when the component mounts
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
  }, []);

  // const hasReloaded = localStorage.getItem('hasReloaded');

  // useEffect(() => {
  //   if (!hasReloaded) {
  //     // Reload the page once silently when the component mounts
  //     localStorage.setItem('hasReloaded', true);
  //     window.location.reload();
  //   }
  // }, []);

  return (
    <div className={`${i18n.language === "ar" && "right-to-left"} App`}>
      <ThemeProvider theme={currentTheme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
