import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiCheckbox from "../../../Components/MuiCheckbox/MuiCheckbox";
// import { useSelector } from "react-redux";

const SubMenuCard = ({
  SubMenu,
  SetAddToggle,
  handleCheckbox,
  handleDeleteMenu,
  SetEditToggle,
  setToggleType
}) => {
  // const { role } = useSelector(state => state?.authentication)
  let role = localStorage.getItem("roleName");
  let token = localStorage.getItem("token");
  return (
    <Card>
      <Grid container padding={2}>
        <Grid item xs={8}>
          <Typography color={"black"} variant="h6">
            {SubMenu?.menuDetail[0]?.name}
          </Typography>
        </Grid>
        {/* {ROLES?.includes(role) &&    */}
        <Grid display={"flex"} justifyContent={"flex-end"} item xs={4}>
          <Tooltip title={"Add Action"} placement="top">
            <IconButton
              onClick={() => {SetAddToggle(true);setToggleType("Action")}}
              className="mui-btn primary filled"
            >
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Edit"} placement="top">
            <IconButton
              size="small"
              onClick={() => SetEditToggle(true)}
              className="mui-btn primary filled"
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete"} placement="top">
            <IconButton
              onClick={handleDeleteMenu}
              size="small"
              className="mui-btn primary filled"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* } */}
      </Grid>

      <CardContent className="pt-0">
        <Grid container spacing={2}>
          {SubMenu?.menuAction.length > 0 &&
            SubMenu?.menuAction.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <FormControlLabel
                  control={
                    <MuiCheckbox
                      id={item?.recordGuid}
                      onChange={handleCheckbox}
                      checked={item?.hasAccess}
                    />
                  }
                  label={item.menuActionDetail[0]?.name}
                />
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SubMenuCard;
