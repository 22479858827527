import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  
  const ManageURLStoreConfig = ({ open, setOpen, title, onButtonClick }) => {
    const [Clients, setClients] =useState("");
    const [ClientsOptions, setClientsOptions] = useState(["Clients 1","Clients 2","Clients 3","Clients 4"]);
    const [BaseUrl, setBaseUrl] = useState("");
    return ( 
      
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Base Url"}
                  value={BaseUrl}
                  setValue={setBaseUrl}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Clients} setValue={setClients}  options={ClientsOptions} placeholder={"Client"}/>
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageURLStoreConfig;
  