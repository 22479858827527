import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function VerticalTabs({ tabs, onTabChange,windowWidth }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newTabLabel = tabs[newValue].label;
    onTabChange(newTabLabel);
  };

  return (
    <Box className={windowWidth < 1499 ? "ChannelTabs-page-mobile" : "ChannelTabs-page"}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className="ChannelTabs"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            {...a11yProps(index)}
            icon={tab.icon} 
            iconPosition="start" 
            className="channel-tabs-style-name"
            sx={{
              mt: 1,
              // textAlign:'start',
              // background: "white",
              border: "1px solid #c41035",
              borderRadius: "25px",
              fontSize: "16px",
              fontWeight: "400",
              textTransform: "inherit",
              minWidth: "116px",
              color:'#c41035',
              minHeight:'43px',
              padding:'6px 16px',
            }}
          />
        ))}
      </Tabs>
      {/* {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))} */}
    </Box>
  );
}

VerticalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default VerticalTabs;
