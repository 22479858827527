import { Grid, Typography } from "@mui/material";
import React , {useState} from "react";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Button } from "@mui/base";
import { withTranslation } from "react-i18next";
const PasswordConfiguration = (props) => {
  const t = props.t;
  const [MinimumLength, setMinimumLength] = useState("");
  const [MinimumNumberofUppercaseLetters, setMinimumNumberofUppercaseLetters] =
    useState("");
  const [MinimumNumberofLowercaseLetters, setMinimumNumberofLowercaseLetters] =
    useState("");
  const [MinimumNumberofDigits, setMinimumNumberofDigits] = useState("");
  const [
    MinimumNumberOfSpecialCharacters,
    setMinimumNumberOfSpecialCharacters,
  ] = useState("");
  const [PasswordExpirationPeriod, setPasswordExpirationPeriod] = useState("");
  const [PasswordHistory, setPasswordHistory] = useState("");
  const [checkedState, setCheckedState] = useState({
    checkbox1: false,
    checkbox6: false,
  });

  const handleChange = (event) => {
    setCheckedState({
      ...checkedState,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={16} md={6} lg={4}>
          <TextFieldForm
            placeholder={t("Minimum Length")}
            value={MinimumLength}
            setValue={setMinimumLength}
          />
        </Grid>
        <Grid item xs={16} md={6} lg={4} className="center">
          <TextFieldForm
            placeholder={t("Minimum Number of Uppercase Letters")}
            value={MinimumNumberofUppercaseLetters}
            setValue={setMinimumNumberofUppercaseLetters}
          />
        </Grid>
        <Grid item xs={16} md={6} lg={4}>
          <TextFieldForm
            placeholder={"Minimum Number of Lowercase Letters"}
            value={MinimumNumberofLowercaseLetters}
            setValue={setMinimumNumberofLowercaseLetters}
          />
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={3}>
        <Grid item xs={16} md={6} lg={4}>
          <TextFieldForm
            placeholder={t("Minimum Number of Digits")}
            value={MinimumNumberofDigits}
            setValue={setMinimumNumberofDigits}
          />
        </Grid>
        <Grid item xs={16} md={6} lg={4}>
          <TextFieldForm
            placeholder={t("Minimum Number Of Special Characters")}
            value={MinimumNumberOfSpecialCharacters}
            setValue={setMinimumNumberOfSpecialCharacters}
          />
        </Grid>
        <Grid item xs={16} md={6} lg={4}>
          <TextFieldForm
            placeholder={t("Password Expiration Period")}
            value={PasswordExpirationPeriod}
            setValue={setPasswordExpirationPeriod}
          />
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={3}>
        <Grid item xs={16} md={6} lg={4}>
          <TextFieldForm
            placeholder={t("Password History")}
            value={PasswordHistory}
            setValue={setPasswordHistory}
          />
        </Grid>
        <Grid item xs={16} md={6} lg={4}>
          <FormControlLabel  sx={{marginTop:'18px' , marginLeft:'10px'}}
            control={
              <Checkbox 
style={{color:"#C61035"}}
                checked={checkedState.checkbox1}
                onChange={handleChange}
                name="checkbox1"
                color="primary"
              />
            }
            label={t("Not Username")}
            id="checkbox1-label"
          />
        </Grid>
        <Grid item xs={16} md={6} lg={4}>
          <FormControlLabel sx={{marginTop:'18px' ,marginLeft:'10px'}}
            control={
              <Checkbox 
style={{color:"#C61035"}}
                checked={checkedState.checkbox6}
                onChange={handleChange}
                name="checkbox6"
                color="primary"
              />
            }
            label={t("Not Email")}
            id="checkbox6-label"
          />
        </Grid>
      </Grid>
      <br/><br/>
      <Grid container spacing={3}>
        <Grid xs={12} item className="end">
        <Button variant="outline" className="outline" style={{marginRight:'10px'}}>
            <Typography sx={{ fontSize: "14px" }}>
               {t("Reset")}
            </Typography>
          </Button>
          <Button variant="outline" className="contained-save">
            <Typography sx={{ fontSize: "14px" }}>
              {t("Save")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withTranslation("translations")(PasswordConfiguration);