import { Alert, FormControlLabel, Grid, Snackbar, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_COST_PLAN,
  ADD_Prefixes,
  ADD_PREFIX,
  GET_ALL_OPERATORS_OPTIONS,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
const ManagePrefixes = ({
  open,
  setOpen,
  title,
  onButtonClick,
  providerCategoryImportOptions,
  setOpenAlert,
  setAlertMessage,
  isEdit,
  CostData,
  selectedPrefixes,
  countryOptions,
  setRefreshTablePrefixes,
  setSearchPrefixes,
  selectedPrefix,
  paginationModel,
  setPaginationModelPrefixes,
}) => {
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [Country, setCountry] = useState(
    selectedPrefix ? selectedPrefix?.operator?.country?.recordGuid : ""
  );
  const [currencies, setCurrencies] = useState("");
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [Operator, setOperator] = useState("");
  const [switchState, setSwitchState] = useState(true);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [prefixNumber, setPrefixNumber] = useState("");

  useEffect(() => {
    if (Country !== "") {
      setOperatorOptions([]);
      getAllOperatorName();
    }
    if (Country === "") {
      setOperator("");
      setOperatorOptions([]);
    }
  }, [Country]);

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  const getAllOperatorName = () => {
    setIsLoadingOperator(true);

    let newServices = JSON.parse(services);
    {
      setOperator("");
    }
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${Country}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoadingOperator(false);

          getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };

  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      PrefixNumber: prefixNumber,
      OperatorGuid: Operator,
      IsActive: switchState,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${ADD_PREFIX}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // setSearchPrefixes("");
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Added", type: "success" });
        setRefreshTablePrefixes(true);
        setOpen(false);
        // setOpenAlert(true);
        setOperator("");
        setCountry("");
        setPrefixNumber("");
        setPaginationModelPrefixes({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    {
      !isEdit && setOperator("");
      setCurrencies("");
      setCountry("");
      setPrefixNumber("");
      setSwitchState(true);
    }
  }, [open]);

  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      PrefixNumber: prefixNumber,
      OperatorGuid: Operator,
      IsActive: switchState,
      recordGuid: selectedPrefix?.recordGuid,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${ADD_PREFIX}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // setSearchPrefixes("");
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated", type: "success" });
        setRefreshTablePrefixes(true);
        setOpen(false);
        // setOpenAlert(true);
        setOperator("");
        setCountry("");
        setPrefixNumber("");
        setPaginationModelPrefixes({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitEdit();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (isEdit && selectedPrefix) {
      setPrefixNumber(selectedPrefix?.prefixNumber);
      setCountry(selectedPrefix?.operator?.country?.recordGuid);
      setOperator(selectedPrefix?.operator?.recordGuid);
      setSwitchState(selectedPrefix?.isActive);
    }
  }, [selectedPrefix, isEdit]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
  };
  return (
    <>
      {console.log("selectedPrefixselectedPrefix", selectedPrefix)}
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={isEdit ? submitEdit : submitAdd}
        disableFirstBtn={
          !isEdit
            ? Country === "" || Operator === "" || prefixNumber === ""
            : prefixNumber === ""
        }
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Prefix Number"}
                  value={prefixNumber}
                  setValue={setPrefixNumber}
                  positiveNumber
                />
              </Grid>
            </Grid>
            {!isEdit && (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={Country}
                      setValue={setCountry}
                      options={countryOptions}
                      placeholder={"Country"}
                      disabled={isEdit}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={currencies}
                    setValue={setCurrencies}
                    options={currenciesOptions}
                    placeholder={"Currency"}
                  />
                </Grid>
              </Grid> */}
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={Operator}
                      setValue={setOperator}
                      options={OperatorOptions}
                      placeholder={"Operator"}
                      disabled={OperatorOptions?.length === 0 || Country === ""}
                      isLoading={isLoadingOperator}
                      wait={"select a country"}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container>
              <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Status</span>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={switchState ? "Active " : "Inactive "}
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => handleClose()}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManagePrefixes;
