import {
    Alert,
    FormControlLabel,
    Grid,
    IconButton,
    Snackbar,
    Switch,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  import {
    ADD_PREFIX,
    ADD_SERVICE,
    ADD_SERVICE_CATEGORY,
    ADD_ZONE,
    GET_ALL_SERVICE_CATEGORY,
    GET_ALL_SERVICE_TYPE,
    MULTI_TENANCY_CONFIG,
    UPDATE_SERVICE,
    UPDATE_SERVICE_CATEGORY,
  } from "../../APIs";
  import axiosInstance from "../../../axiosInstance";
  import CloseIcon from "@mui/icons-material/Close";
  import SelectFormNew from "../../../Components/Selects/SelectFormNew";
  
  const ManageServiceCategory = ({
    open,
    setOpen,
    title,
    isEdit,
    setRefreshTableServiceCategory,
    setOpenAlert,
    setSearchService,
    selectedServiceCategory,
    paginationModalServiceCategory,
    setPaginationModalServiceCategory,
    currenciesOptions,
  }) => {
    let services = localStorage.getItem("services");
  
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
  
    const [messageData, setMessageData] = useState({ message: "", type: "" });
    const [openMessageAlert, setOpenMessageAlert] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isMultipleSubscription, setIsMultipleSubscription] = useState(false);
  
    const handleSwitchChangeActive = () => {
      setIsActive(!isActive);
    };
  
    const handleSwitchChangeMultipleSubscription = () => {
        setIsMultipleSubscription(!isMultipleSubscription);
      };
    
    const submitAdd = () => {
      let newServices = JSON.parse(services);
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
      MULTI_TENANCY_CONFIG.headers = {
        ...MULTI_TENANCY_CONFIG.headers,
      };
      let data = {
        isActive: isActive,
        AllowMultipleSubscription: isMultipleSubscription,
        details: [
          {
            languageCode: "en",
            name: name,
            description: description,
          },
        ],
      };
      axiosInstance
        .post(
          `${localStorage.getItem("baseUrl")}${
            newServices["catalog"]
          }${ADD_SERVICE_CATEGORY}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          setName("");
          setOpenMessageAlert(true);
          setMessageData({
            message: data?.message || "Successfully Added",
            type: "success",
          });
          setRefreshTableServiceCategory(true);
          setOpen(false);
          setPaginationModalServiceCategory({
            page: 0,
            pageSize: paginationModalServiceCategory?.pageSize
              ? paginationModalServiceCategory?.pageSize
              : 10,
          });
          // setOpenAlert(true);
        })
        .catch((error) => {
          setOpenMessageAlert(true);
          setMessageData({
            message: error?.response?.data?.Message
              ? error?.response?.data?.Message
              : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
            type: "error",
          });
        });
    };
  
    const submitEdit = () => {
      let newServices = JSON.parse(services);
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
      MULTI_TENANCY_CONFIG.headers = {
        ...MULTI_TENANCY_CONFIG.headers,
      };
      let data = {
        recordGuid: selectedServiceCategory?.recordGuid,
        isActive: isActive,
        AllowMultipleSubscription: isMultipleSubscription,
        details: [
          {
            languageCode: "en",
            name: name,
            description: description,
          },
        ],
      };
      axiosInstance
        .put(
          `${localStorage.getItem("baseUrl")}${
            newServices["catalog"]
          }${UPDATE_SERVICE_CATEGORY}`,
          data,
          MULTI_TENANCY_CONFIG
        )
        .then(({ data }) => {
          setName("");
          setOpenMessageAlert(true);
          setMessageData({
            message: data?.message || "Successfully Added",
            type: "success",
          });
          setRefreshTableServiceCategory(true);
          setOpen(false);
          setPaginationModalServiceCategory({
            page: 0,
            pageSize: paginationModalServiceCategory?.pageSize
              ? paginationModalServiceCategory?.pageSize
              : 10,
          });
          // setOpenAlert(true);
        })
        .catch((error) => {
          setOpenMessageAlert(true);
          setMessageData({
            message: error?.response?.data?.Message
              ? error?.response?.data?.Message
              : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
            type: "error",
          });
        });
    };
  
    useEffect(() => {
      if (isEdit) {
        setName(
          selectedServiceCategory?.serviceCategoryDetails &&
            selectedServiceCategory?.serviceCategoryDetails?.length !== 0
            ? selectedServiceCategory?.serviceCategoryDetails[0]?.name
            : ""
        );
        setDescription(
          selectedServiceCategory?.serviceCategoryDetails &&
            selectedServiceCategory?.serviceCategoryDetails?.length !== 0
            ? selectedServiceCategory?.serviceCategoryDetails[0]?.description
            : ""
        );
        setIsActive(selectedServiceCategory?.isActive);
        setIsMultipleSubscription(selectedServiceCategory?.allowMultipleSubscription);

      } else {
        setName("");
          setDescription("" );
          setIsActive(false);
          setIsMultipleSubscription(false);
  
      }
    }, [open, isEdit]);
  
   
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenMessageAlert(false);
    };
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    return (
      <>
        <CustomizedDialogs
          open={open}
          setOpen={setOpen}
          title={title}
          firstActionBtnName={"Save"}
          firstActionBtnFct={isEdit ? submitEdit : submitAdd}
          disableFirstBtn={
            isEdit
              ? name === "" ||
                description === "" 
              : name === "" ||
                description === "" 
          }
          // secondActionBtnName={"Cancel"}
          content={
            <>
              {!isEdit ? (
                <>
                  <Grid container>
                    <Grid className="dialog-grid-spacing" item xs={12}>
                      <TextFieldForm
                        placeholder={"Name"}
                        value={name}
                        setValue={setName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="dialog-grid-spacing" item xs={12}>
                      <TextFieldForm
                        placeholder={"Description"}
                        value={description}
                        setValue={setDescription}
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid style={{marginTop:"5px"}}  container>
                    <Grid item xs={2.5}>
                      <span
                        style={{
                          color: "#B3B3B3",
                          fontSize: "15px",
                        }}
                      >
                        Active
                      </span>
                    </Grid>
                    <Grid item xs={9.5}>
                      <FormControlLabel
                        style={{ marginTop: "-5px" }}
                        control={
                          <Switch
                            checked={isActive}
                            onChange={handleSwitchChangeActive}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
  
                  <Grid style={{marginTop:"2px"}}  container>
                    <Grid item xs={6.5}>
                      <span
                        style={{
                          color: "#B3B3B3",
                          fontSize: "15px",
                          marginRight: "20px",
                        }}
                      >
                       Allow Multiple Subscription
                      </span>
                    </Grid>
  
                    <Grid item xs={4.5}>
                      <FormControlLabel
                        style={{ marginTop: "-5px" }}
                        control={
                          <Switch
                            checked={isMultipleSubscription}
                            onChange={handleSwitchChangeMultipleSubscription}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
  
                  
                </>
              ) : (
                <>
                  <Grid container>
                    <Grid className="dialog-grid-spacing" item xs={12}>
                      <TextFieldForm
                        placeholder={"Name"}
                        value={name}
                        setValue={setName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="dialog-grid-spacing" item xs={12}>
                      <TextFieldForm
                        placeholder={"Description"}
                        value={description}
                        setValue={setDescription}
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid style={{marginTop:"5px"}}  container>
                    <Grid item xs={2.5}>
                      <span
                        style={{
                          color: "#B3B3B3",
                          fontSize: "15px",
                        }}
                      >
                        Status
                      </span>
                    </Grid>
                    <Grid item xs={9.5}>
                      <FormControlLabel
                        style={{ marginTop: "-5px" }}
                        control={
                          <Switch
                            checked={isActive}
                            onChange={handleSwitchChangeActive}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
  
                  <Grid style={{marginTop:"2px"}}  container>
                    <Grid item xs={6.5}>
                      <span
                        style={{
                          color: "#B3B3B3",
                          fontSize: "15px",
                          marginRight: "20px",
                        }}
                      >
                       Allow Multiple Subscription
                      </span>
                    </Grid>
  
                    <Grid item xs={4.5}>
                      <FormControlLabel
                        style={{ marginTop: "-5px" }}
                        control={
                          <Switch
                            checked={isMultipleSubscription}
                            onChange={handleSwitchChangeMultipleSubscription}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
  
                  
                </>
              )}
            </>
          }
        />
        <Snackbar
          style={{ marginTop: "40px" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
          open={openMessageAlert}
          autoHideDuration={3000}
          action={action}
        >
          <Alert
            variant="filled"
            severity={messageData?.type}
            onClose={handleClose}
            sx={{ width: "100%" }}
          >
            {messageData?.message}
          </Alert>
        </Snackbar>
      </>
    );
  };
  
  export default ManageServiceCategory;
  