import {
  Grid
} from "@mui/material";
import React, { useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import DescriptionInput from "../../../Components/TextFields/DescriptionInput";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  
  const ManageAccounts = ({ open, setOpen, title, onButtonClick }) => {

    const [name, setName] = useState("");
    const [Descritpion, setDescritpion] = useState("");

  
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <DescriptionInput
                  placeholder={"Descritpion"}
                  value={Descritpion}
                  setValue={setDescritpion}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageAccounts;
  