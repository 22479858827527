import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  import DescriptionInput from "../../../Components/TextFields/DescriptionInput";
  
  const ManageFeatures = ({ open, setOpen, title, onButtonClick }) => {
    const [Tag, setTag] =useState("");
    const [TagOptions, setTagOptions] = useState(["tag 1","tag 2","tag 3","tag 4"]);
    const [LanguageCode, setLanguageCode] =useState("");
    const [LanguageCodeOptions, setLanguageCodeOptions] = useState(["Tenant 1","Tenant 2","Tenant 3","Tenant 4"]);
    const [ValueType, setValueType] =useState("");
    const [ValueTypeOptions, setValueTypeOptions] = useState(["Tenant 1","Tenant 2","Tenant 3","Tenant 4"]);
    const [FeatureType, setFeatureType] =useState("");
    const [FeatureTypeOptions, setFeatureTypeOptions] = useState(["Tenant 1","Tenant 2","Tenant 3","Tenant 4"]);
    const [name, setName] = useState("");
    const [Descritpion, setDescritpion] = useState("");
    const [switchState, setSwitchState] = useState(false);
  
    const handleSwitchChange = () => {
      setSwitchState(!switchState);
    };
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <DescriptionInput
                  placeholder={"Descritpion"}
                  value={Descritpion}
                  setValue={setDescritpion}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={Tag} setValue={setTag}  options={TagOptions} placeholder={"Tag"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={LanguageCode} setValue={setLanguageCode}  options={LanguageCodeOptions} placeholder={"Language Code"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={FeatureType} setValue={setFeatureType}  options={FeatureTypeOptions} placeholder={"Feature Type"}/>
              </Grid>
            </Grid>


            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectForm value={ValueType} setValue={setValueType}  options={ValueTypeOptions} placeholder={"Value Type"}/>
              </Grid>
            </Grid>
            <span style={{color:"#B3B3B3",fontSize:"15px"}}>Status</span>
            <Grid container>
                  
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Switch checked={switchState} onChange={handleSwitchChange} />}
                      label={switchState ? 'Active ' : 'Inactive '}
                    />
                  </Grid>
                </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageFeatures;
  