import { Grid } from "@mui/material";
import React, { useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import DescriptionInput from "../../../Components/TextFields/DescriptionInput";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectForm from "../../../Components/Selects/SelectsForm";

const ManageAccounts = ({ open, setOpen, title, onButtonClick }) => {
  const [balance, setBalance] = useState("");
  const [prevousBalance, setPreviousBalance] = useState("");
  const [lockedBalance, setLockedBalance] = useState("");
  const [prevousLocked, setPreviousLocked] = useState("");
  const [type, setType] = useState("");
  const [typeOptions, setTypeOptions] = useState(["Postpaid", "Prepaid"]);

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Balance"}
                value={balance}
                setValue={setBalance}
              />
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Previous Balance"}
                value={prevousBalance}
                setValue={setPreviousBalance}
              />
            </Grid>
          </Grid> */}
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Locked Balance"}
                value={lockedBalance}
                setValue={setLockedBalance}
              />
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Previous Locked"}
                value={prevousLocked}
                setValue={setPreviousLocked}
              />
            </Grid>
          </Grid> */}
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm
                placeholder={"Type"}
                value={type}
                setValue={setType}
                options={typeOptions}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageAccounts;
