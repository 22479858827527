import React from "react";
import { ResellerProvider, useResellerContext } from "./ResellerContext";

import {
    Accordion,
  AccordionSummary,
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AccordionDetails from "@mui/material/AccordionDetails";
import InfoIcon from "@mui/icons-material/Info";

import {
  HandleApiError,
  handleMessageError,
  updateState,
} from "../../../Utils/functions";
import { useNavigate } from "react-router-dom";
import {
  GET_ALL_CATEGORIES,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_CTIES,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import { ExpandMore } from "@mui/icons-material";
import ConfigurationSection from "./Sections/ConfigurationSection";

//   import ConfigurationSection from "./Sections/ConfigurationSection";

function AddResellerComponent() {
  const navigate = useNavigate();

  const { formik, GlobalData, SetGlobalData, } = useResellerContext();
  const { steps, activeStep } = GlobalData;

  const [Countries, SetCountries] = useState([]);
  const [Categories, SetCategories] = useState([]);
  const [Cities, SetCities] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const handleBack = () => {
    if (activeStep == 0) navigate("/reseller-management");
    else SetGlobalData(updateState(GlobalData, "activeStep", activeStep - 1));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    SetGlobalData(updateState(GlobalData, "openMessageAlert", false));
  };

  const GetAllCountries = async () => {
    try {
      let service = JSON.parse(localStorage.getItem("services"));
      // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let countriesResponse = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?PageIndex=1&pageSize=400`,
        MULTI_TENANCY_CONFIG
      );

      SetCountries(countriesResponse?.data?.data?.countries);
    } catch (e) {}
  };

  const getCities = async ({ country }) => {
    try {
      let service = JSON.parse(localStorage.getItem("services"));
      // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let citiesResponse = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_CTIES}?CountryGuid=${country}`,
        MULTI_TENANCY_CONFIG
      );

      SetCities(citiesResponse?.data?.data?.cities);
    } catch (e) {}
  };

  const GetAllCategories = async () => {
    try {
      let service = JSON.parse(localStorage.getItem("services"));
      // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
      MULTI_TENANCY_CONFIG.headers.Authorization =
        "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

      let categoriesResponse = await await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          service["member"]
        }${GET_ALL_CATEGORIES}`,
        MULTI_TENANCY_CONFIG
      );

      SetCategories(
        categoriesResponse?.data?.data?.clientCategory?.map((item) => {
          return {
            name: item?.clientCategoryDetails[0]?.name,
            id: item?.recordGuid,
          };
        })
      );
    } catch (e) {}
  };

  const circleStyle = {
    width: 35, // Set the desired width
    height: 35, // Set the desired height
    borderRadius: "50%", // Ensures a circular shape
    fontSize: "0.95rem !important",
  };

  useEffect(() => {
    GetAllCountries();
    GetAllCategories();
  }, []);

  return (
    <>
      <Grid
        style={{ marginBottom: "50px" }}
        item
        xs={12}
        md={6}
        className="centerresponsive"
      >
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/reseller-management", { replace: true });
            }}
            className="BreadcrumbsPage"
          >
            Resellers
          </Typography>
          <Typography className="breadcrumbactiveBtn">Add Reseller</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={2}>
          <Box sx={{}}>
            <Stepper
              sx={{
                width: "30px",
                height: "30px",
                "& .MuiStepConnector-line": {
                  height: "30px",
                  margin: "0px 0px 0px 5px",
                },
                "& .MuiStepIcon-root.Mui-completed ": {
                  color: "#c41035",
                },
                "& .MuiStepIcon-root.Mui-active": {
                  color: "#c41035 !important",
                },
              }}
              activeStep={activeStep}
              orientation={windowWidth < 1000 ? "horizontal": "vertical"}
            >
              {steps.map((step, index) => (
                <Step key={step.label}
                style={{cursor: "pointer"}}
                onClick={()=>{
                  if (activeStep > index) SetGlobalData(updateState(GlobalData, "activeStep", index));
                }}
                >
                  <StepLabel
                    StepIconProps={{
                      style: circleStyle,
                    }}
                    style={{ width: "500px" }}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>

        <Grid
          style={{
            background: "white",
            padding: "20px",
            borderRadius: "25px",
          }}
          item
          lg={10}
          xs={12}
        >
          <form onSubmit={formik?.handleSubmit}>
            {activeStep === 0 && (
              <>
                <Typography>
                  <InfoIcon
                    style={{
                      color: "#C41035",
                      fontSize: "25px",
                      marginRight: "5px",
                      marginBottom: "-7px",
                    }}
                  />
                  Account Information
                </Typography>

                <Grid
                  container
                  style={{ marginBottom: "10px", marginTop: "8px" }}
                  spacing={3}
                >
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                        Name*
                      </span>

                      <FormControl
                        sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                        }}
                      >
                        {" "}
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              border: "none",
                            },
                            padding: "5px",
                          }}
                          key={"firstName"}
                          fullWidth
                          id={"firstName"}
                          name={"firstName"}
                          type={"text"}
                          value={formik.values["firstName"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["firstName"] &&
                            Boolean(formik.errors["firstName"])
                          }
                          helperText={
                            formik.touched["firstName"] &&
                            formik.errors["firstName"]
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span
                            style={{ color: "#B3B3B3", fontSize: "15px" }}
                        >
                            Category*
                        </span>

                        <FormControl
                            sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid rgb(224, 224, 224)!important",
                            }}
                        >
                            <Select
                            id="category" // Add an id for accessibility
                            name="category" // Name should match the field name in initialValues
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            size="small"
                            error={
                                formik.touched["category"] &&
                                Boolean(formik.errors["category"])
                            }
                            value={formik.values.category}
                            labelId="category"
                            >
                            {Categories?.map((item) => (
                                <MenuItem value={item?.id}>{item?.name}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        {formik.touched.category && formik.errors.category && (
                          <FormHelperText style={{ color: "#d32f2f", marginLeft:"20px" }}>
                            {formik.errors.category}
                          </FormHelperText>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                        Email*
                      </span>

                      <FormControl
                        sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                        }}
                      >
                        {" "}
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              border: "none",
                            },
                            padding: "5px",
                          }}
                          key={"email"}
                          fullWidth
                          id={"email"}
                          name={"email"}
                          type={"email"}
                          value={formik.values["email"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["email"] &&
                            Boolean(formik.errors["email"])
                          }
                          helperText={
                            formik.touched["email"] && formik.errors["email"]
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                        Username*
                      </span>

                      <FormControl
                        sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                        }}
                      >
                        {" "}
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              border: "none",
                            },
                            padding: "5px",
                          }}
                          key={"username"}
                          fullWidth
                          id={"username"}
                          name={"username"}
                          type={"text"}
                          value={formik.values["username"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["username"] &&
                            Boolean(formik.errors["username"])
                          }
                          helperText={
                            formik.touched["username"] &&
                            formik.errors["username"]
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                        Password*
                      </span>

                      <FormControl
                        sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                        }}
                      >
                        {" "}
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              border: "none",
                            },
                            padding: "5px",
                          }}
                          key={"password"}
                          fullWidth
                          id={"password"}
                          name={"password"}
                          type={"password"}
                          value={formik.values["password"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["password"] &&
                            Boolean(formik.errors["password"])
                          }
                          helperText={
                            formik.touched["password"] &&
                            formik.errors["password"]
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                        Business Website
                      </span>

                      <FormControl
                        sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                        }}
                      >
                        {" "}
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              border: "none",
                            },
                            padding: "5px",
                          }}
                          key={"businesswebsite"}
                          fullWidth
                          id={"businesswebsite"}
                          name={"businesswebsite"}
                          type={"text"}
                          value={formik.values["businesswebsite"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["businesswebsite"] &&
                            Boolean(formik.errors["businesswebsite"])
                          }
                          helperText={
                            formik.touched["businesswebsite"] &&
                            formik.errors["businesswebsite"]
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                        Company Phone*
                      </span>

                      <FormControl
                        sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                        }}
                      >
                        {" "}
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              border: "none",
                            },
                            padding: "5px",
                          }}
                          key={"phone"}
                          fullWidth
                          id={"phone"}
                          name={"phone"}
                          type={"text"}
                          value={formik.values["phone"]}
                          onChange={(e)=>{
                            if (/^[0-9]*$/.test(e.target.value) || e.target.value == "") {
                              formik.setFieldValue("phone",e.target.value)
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["phone"] &&
                            Boolean(formik.errors["phone"])
                          }
                          helperText={
                            formik.touched["phone"] && formik.errors["phone"]
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      <InfoIcon
                        style={{
                          color: "#C41035",
                          fontSize: "25px",
                          marginRight: "5px",
                          marginBottom: "-7px",
                        }}
                      />
                      Address Information
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Region
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"region"}
                          fullWidth
                          id={"region"}
                          name={"region"}
                          type={"text"}
                          value={formik.values["region"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["region"] &&
                            Boolean(formik.errors["region"])
                          }
                          helperText={
                            formik.touched["region"] &&
                            formik.errors["region"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: "100%", marginBottom: "8px" }}>
                        <span
                            style={{ color: "#B3B3B3", fontSize: "15px" }}
                        >
                            Country*
                        </span>

                        <FormControl
                            sx={{
                            width: "100%",
                            borderRadius: "32px",
                            border: "1px solid rgb(224, 224, 224)!important",
                            }}
                        >
                            <Select
                                key="country"
                                id="country" // Add an id for accessibility
                                name="country" // Name should match the field name in initialValues
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getCities({ country: e?.target?.value });
                                }}
                                size="small"
                                onBlur={formik.handleBlur}
                                value={formik.values.country}
                                labelId="country"
                              >
                                {Countries?.map((country) => (
                                  <MenuItem value={country?.recordGuid}>
                                    {country?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                        </FormControl>
                        {formik.touched.country &&
                            formik.errors.country && (
                            <FormHelperText style={{ color: "#d32f2f", marginLeft:"20px" }}>
                                {formik.errors.country}
                            </FormHelperText>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                          <span
                              style={{ color: "#B3B3B3", fontSize: "15px" }}
                          >
                              City
                          </span>

                          <FormControl
                              sx={{
                              width: "100%",
                              borderRadius: "32px",
                              border: "1px solid rgb(224, 224, 224)!important",
                              }}
                          >
                              <Select
                                  key="city"
                                  id="city" // Add an id for accessibility
                                  name="city" // Name should match the field name in initialValues
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.city}
                                  labelId="city"
                                  size="small"
                              >
                                  {Cities?.map((city) => (
                                  <MenuItem value={city?.recordGuid}>
                                      {city?.name}
                                  </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          {formik.touched.city && formik.errors.city && (
                          <FormHelperText style={{ color: "red" }}>
                              {formik.errors.city}
                          </FormHelperText>
                          )}
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          State
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"state"}
                          fullWidth
                          id={"state"}
                          name={"state"}
                          type={"text"}
                          value={formik.values["state"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                          formik.touched["state"] &&
                          Boolean(formik.errors["state"])
                          }
                          helperText={
                          formik.touched["state"] &&
                          formik.errors["state"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Street
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"street"}
                          fullWidth
                          id={"street"}
                          name={"street"}
                          type={"text"}
                          value={formik.values["street"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                          formik.touched["street"] &&
                          Boolean(formik.errors["street"])
                          }
                          helperText={
                          formik.touched["street"] &&
                          formik.errors["street"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Zip
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"zip"}
                          fullWidth
                          id={"zip"}
                          name={"zip"}
                          type={"text"}
                          value={formik.values["zip"]}
                          onChange={(e)=>{
                            if (/^[0-9]*$/.test(e.target.value) || e.target.value == "") {
                              formik.setFieldValue("zip",e.target.value)
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={
                          formik.touched["zip"] &&
                          Boolean(formik.errors["zip"])
                          }
                          helperText={
                          formik.touched["zip"] && formik.errors["zip"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Building
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"building"}
                          fullWidth
                          id={"building"}
                          name={"building"}
                          type={"text"}
                          value={formik.values["building"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                          formik.touched["building"] &&
                          Boolean(formik.errors["building"])
                          }
                          helperText={
                          formik.touched["building"] &&
                          formik.errors["building"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Floor
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"floor"}
                          fullWidth
                          id={"floor"}
                          name={"floor"}
                          type={"text"}
                          value={formik.values["floor"]}
                          onChange={(e)=>{
                            if (/^[0-9]*$/.test(e.target.value) || e.target.value == "") {
                              formik.setFieldValue("floor",e.target.value)
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={
                          formik.touched["floor"] &&
                          Boolean(formik.errors["floor"])
                          }
                          helperText={
                          formik.touched["floor"] &&
                          formik.errors["floor"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Room
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"room"}
                          fullWidth
                          id={"room"}
                          name={"room"}
                          type={"text"}
                          value={formik.values["room"]}
                          onChange={(e)=>{
                            if (/^[0-9]*$/.test(e.target.value) || e.target.value == "") {
                              formik.setFieldValue("room",e.target.value)
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={
                          formik.touched["room"] &&
                          Boolean(formik.errors["room"])
                          }
                          helperText={
                          formik.touched["room"] &&
                          formik.errors["room"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Address Line 1
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"address1"}
                          fullWidth
                          id={"address1"}
                          name={"address1"}
                          type={"text"}
                          value={formik.values["address1"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched["address1"] &&
                            Boolean(formik.errors["address1"])
                          }
                          helperText={
                            formik.touched["address1"] &&
                            formik.errors["address1"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ width: "100%", marginBottom: "8px" }}>
                      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                          Address Line 2
                      </span>

                      <FormControl
                          sx={{
                          width: "100%",
                          borderRadius: "32px",
                          border: "1px solid #E0E0E0 ",
                          display: "grid",
                          }}
                      >
                          {" "}
                          <TextField
                          sx={{
                              "&.MuiTextField-root": {
                              border: "none",
                              },
                              padding: "5px",
                          }}
                          key={"address2"}
                          fullWidth
                          id={"address2"}
                          name={"address2"}
                          type={"text"}
                          value={formik.values["address2"]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                          formik.touched["address2"] &&
                          Boolean(formik.errors["address2"])
                          }
                          helperText={
                          formik.touched["address2"] &&
                          formik.errors["address2"]
                          }
                          />
                      </FormControl>
                      </Box>
                  </Grid>
                  
                        
                   
                  

                </Grid>
              </>
            )}

            {activeStep === 1 && (
                <>
                    <Typography>
                        <InfoIcon
                            style={{
                            color: "#C41035",
                            fontSize: "25px",
                            marginRight: "5px",
                            marginBottom: "-7px",
                            }}
                        />
                        SMTP Config
                    </Typography>

                    <FormGroup style={{ marginBottom: "10px", marginTop: "8px" }}>
                        <FormControlLabel 
                        control={<Switch 
                            checked={formik.values.Alerts}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="Alerts" // Field name must match the one in initialValues
                        />} 
                        label="SMTP Config" />
                    </FormGroup>
                    {formik.touched.Alerts && formik.errors.Alerts && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.Alerts}
                      </FormHelperText>
                    )}

                    {formik.values.Alerts && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                    From Address*
                                </span>

                                <FormControl
                                    sx={{
                                    width: "100%",
                                    borderRadius: "32px",
                                    border: "1px solid #E0E0E0 ",
                                    display: "grid",
                                    }}
                                >
                                    {" "}
                                    <TextField
                                    sx={{
                                        "&.MuiTextField-root": {
                                        border: "none",
                                        },
                                        padding: "5px",
                                    }}
                                    key={"fromAddress"}
                                    fullWidth
                                    id={"fromAddress"}
                                    name={"fromAddress"}
                                    type={"text"}
                                    value={formik.values?.fromAddress}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                      formik.touched?.fromAddress &&
                                      Boolean(formik.errors?.fromAddress)
                                    }
                                    helperText={
                                      formik.touched?.fromAddress &&
                                      formik.errors?.fromAddress
                                    }
                                    />
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                    Reply Address*
                                </span>

                                <FormControl
                                    sx={{
                                    width: "100%",
                                    borderRadius: "32px",
                                    border: "1px solid #E0E0E0 ",
                                    display: "grid",
                                    }}
                                >
                                    {" "}
                                    <TextField
                                    sx={{
                                        "&.MuiTextField-root": {
                                        border: "none",
                                        },
                                        padding: "5px",
                                    }}
                                    key={"ReplyAddress"}
                                    fullWidth
                                    id={"ReplyAddress"}
                                    name={"ReplyAddress"}
                                    type={"text"}
                                    value={formik.values?.ReplyAddress}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched?.ReplyAddress &&
                                        Boolean(formik.errors?.ReplyAddress)
                                    }
                                    helperText={
                                        formik.touched?.ReplyAddress &&
                                        formik.errors?.ReplyAddress
                                    }
                                    />
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                    SMTP Server*
                                </span>

                                <FormControl
                                    sx={{
                                    width: "100%",
                                    borderRadius: "32px",
                                    border: "1px solid #E0E0E0 ",
                                    display: "grid",
                                    }}
                                >
                                    {" "}
                                    <TextField
                                    sx={{
                                        "&.MuiTextField-root": {
                                        border: "none",
                                        },
                                        padding: "5px",
                                    }}
                                    key={"SmtpServer"}
                                    fullWidth
                                    id={"SmtpServer"}
                                    name={"SmtpServer"}
                                    type={"text"}
                                    value={formik.values?.SmtpServer}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                      formik.touched?.SmtpServer &&
                                      Boolean(formik.errors?.SmtpServer)
                                    }
                                    helperText={
                                      formik.touched?.SmtpServer &&
                                      formik.errors?.SmtpServer
                                    }
                                    />
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                    SMTP User*
                                </span>

                                <FormControl
                                    sx={{
                                    width: "100%",
                                    borderRadius: "32px",
                                    border: "1px solid #E0E0E0 ",
                                    display: "grid",
                                    }}
                                >
                                    {" "}
                                    <TextField
                                    sx={{
                                        "&.MuiTextField-root": {
                                        border: "none",
                                        },
                                        padding: "5px",
                                    }}
                                    key={"SmtpUser"}
                                    fullWidth
                                    id={"SmtpUser"}
                                    name={"SmtpUser"}
                                    type={"text"}
                                    value={formik.values?.SmtpUser}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched?.SmtpUser &&
                                        Boolean(formik.errors?.SmtpUser)
                                    }
                                    helperText={
                                        formik.touched?.SmtpUser &&
                                        formik.errors?.SmtpUser
                                    }
                                    />
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                    SMTP Password*
                                </span>

                                <FormControl
                                    sx={{
                                    width: "100%",
                                    borderRadius: "32px",
                                    border: "1px solid #E0E0E0 ",
                                    display: "grid",
                                    }}
                                >
                                    {" "}
                                    <TextField
                                    sx={{
                                        "&.MuiTextField-root": {
                                        border: "none",
                                        },
                                        padding: "5px",
                                    }}
                                    key={"SmtpPassword"}
                                    fullWidth
                                    id={"SmtpPassword"}
                                    name={"SmtpPassword"}
                                    type={"text"}
                                    value={formik.values?.SmtpPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched?.SmtpPassword &&
                                        Boolean(formik.errors?.SmtpPassword)
                                    }
                                    helperText={
                                        formik.touched?.SmtpPassword &&
                                        formik.errors?.SmtpPassword
                                    }
                                    />
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ width: "100%", marginBottom: "8px" }}>
                                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                    SMTP Port*
                                </span>

                                <FormControl
                                    sx={{
                                    width: "100%",
                                    borderRadius: "32px",
                                    border: "1px solid #E0E0E0 ",
                                    display: "grid",
                                    }}
                                >
                                    {" "}
                                    <TextField
                                    sx={{
                                        "&.MuiTextField-root": {
                                        border: "none",
                                        },
                                        padding: "5px",
                                    }}
                                    key={"SmtpPort"}
                                    fullWidth
                                    id={"SmtpPort"}
                                    name={"SmtpPort"}
                                    type={"text"}
                                    value={formik.values?.SmtpPort}
                                    onChange={(e)=>{
                                      if (/^[0-9]*$/.test(e.target.value) || e.target.value == "") {
                                        formik.setFieldValue("SmtpPort",e.target.value)
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched?.SmtpPort &&
                                        Boolean(formik.errors?.SmtpPort)
                                    }
                                    helperText={
                                        formik.touched?.SmtpPort &&
                                        formik.errors?.SmtpPort
                                    }
                                    />
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel 
                                    control={<Checkbox 
                                        value={formik.values["Smtpssl"]}
                                        onChange={formik?.handleChange}
                                        onBlur={formik?.handleBlur}
                                        name="Smtpssl"
                                    />} 
                                    label="Use SSL" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    )}


                </>
            )}

            <ConfigurationSection pageType="add" />

            {activeStep === 5 && (
                <>
                    <Typography>
                    <InfoIcon
                        style={{
                        color: "#C41035",
                        fontSize: "25px",
                        marginRight: "5px",
                        marginBottom: "-7px",
                        }}
                    />
                        Engagement
                    </Typography>

                    <Grid container spacing={3} style={{ marginBottom: "10px", marginTop: "8px" }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                Billing Email
                            </span>

                            <FormControl
                                sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                                }}
                            >
                                {" "}
                                <TextField
                                sx={{
                                    "&.MuiTextField-root": {
                                    border: "none",
                                    },
                                    padding: "5px",
                                }}
                                key={"BillingEmail"}
                                fullWidth
                                id={"BillingEmail"}
                                name={"BillingEmail"}
                                type={"text"}
                                value={formik.values.BillingEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.BillingEmail &&
                                  Boolean(formik.errors.BillingEmail)
                                }
                                helperText={
                                  formik.touched.BillingEmail &&
                                  formik.errors.BillingEmail
                                }
                                />
                            </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                Technical Email
                            </span>

                            <FormControl
                                sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                                }}
                            >
                                {" "}
                                <TextField
                                sx={{
                                    "&.MuiTextField-root": {
                                    border: "none",
                                    },
                                    padding: "5px",
                                }}
                                key={"TechnicalEmail"}
                                fullWidth
                                id={"TechnicalEmail"}
                                name={"TechnicalEmail"}
                                type={"text"}
                                value={formik.values.TechnicalEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.TechnicalEmail &&
                                  Boolean(formik.errors.TechnicalEmail)
                                }
                                helperText={
                                  formik.touched.TechnicalEmail &&
                                  formik.errors.TechnicalEmail
                                }
                                />
                            </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box sx={{ width: "100%", marginBottom: "8px" }}>
                            <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                                Alerts Email
                            </span>

                            <FormControl
                                sx={{
                                width: "100%",
                                borderRadius: "32px",
                                border: "1px solid #E0E0E0 ",
                                display: "grid",
                                }}
                            >
                                {" "}
                                <TextField
                                sx={{
                                    "&.MuiTextField-root": {
                                    border: "none",
                                    },
                                    padding: "5px",
                                }}
                                key={"AlertsEmail"}
                                fullWidth
                                id={"AlertsEmail"}
                                name={"AlertsEmail"}
                                type={"text"}
                                value={formik.values.AlertsEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.AlertsEmail &&
                                    Boolean(formik.errors.AlertsEmail)
                                }
                                helperText={
                                    formik.touched.AlertsEmail &&
                                    formik.errors.AlertsEmail
                                }
                                />
                            </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid
              style={{ marginTop: "20px", justifyContent: "space-between" }}
              container
            >
              <Grid item={6}>
                <Button
                  disabled={steps.length === 0}
                  onClick={handleBack}
                  className="outline"
                  // sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item={6}>
                <Button
                  className="contained"
                  onClick={formik?.handleSubmit}
                  // sx={{ mt: 1, mr: 1 }}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Continue"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={GlobalData?.openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={GlobalData?.alertData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {GlobalData?.alertData?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const AddReseller = () => {
  return (
    <ResellerProvider>
      <AddResellerComponent />
    </ResellerProvider>
  );
};

export default AddReseller;
