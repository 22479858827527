import Add from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import axiosInstance from "../../../axiosInstance";
import {
  CHANGE_STATUS_CATEGORY,
  CHANGE_STATUS_CITY,
  CHANGE_STATUS_COUNTRY,
  CHANGE_STATUS_CURRENCIES,
  CHANGE_STATUS_LANG_RL,
  CHANGE_STATUS_LANG_STATUS,
  CHANGE_STATUS_LANG_TR,
  CHANGE_STATUS_ZONE,
  DELETE_ITEM,
  DELETE_SERVICE,
  DELETE_SERVICE_CATEGORY,
  DELETE_SERVICE_TYPE,
  EXPORT_CITY,
  EXPORT_COUNTRY_TO_EXCEL,
  EXPORT_CURRENCIES,
  EXPORT_LANGUAGES_TO_EXCEL,
  EXPORT_ZONE,
  GET_ALL_CITY,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_COUNTRY,
  GET_ALL_CRITERIA,
  GET_ALL_CRITERIA_CATEGORY,
  GET_ALL_CRITERIA_TYPE,
  GET_ALL_CURRENCIES,
  GET_ALL_ITEM_SHORT_CODE,
  GET_ALL_SERVICE,
  GET_ALL_SERVICE_CATEGORY,
  GET_ALL_SERVICE_TYPE,
  MANAGE_CITY,
  MANAGE_CRITERIA_CATEGORY,
  MANAGE_CURRENCIES,
  MANAGE_LANGUAGES,
  MULTI_TENANCY_CONFIG,
  UPDATE_STATUS_SERVICE,
} from "../../APIs";
import ManageCity from "./ManageCity";
import ManageCountry from "./ManageCountry";
import ManageCriteria from "./ManageCriteria";
import ManageCriteriaCategory from "./ManageCriteriaCategory";
import ManageCriteriaType from "./ManageCriteriaType";
import ManageLongCode from "./ManageLongCode";
import ManageOperators from "./ManageOperators";
import ManageService from "./ManageService";
import ManageShortCode from "./ManageShortCode";
import ManageServiceType from "./ManageServiceType";
import ManageServiceCategory from "./ManageServiceCategory";
const Longcode = () => {
  let services = localStorage.getItem("services");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let columnsCriteriaType = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params?.isActive}
                  // onChange={() => {
                  //   handleSwitchChangeSwitchCriteriaType(data);
                  // }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [searchCountry, setSearchCountry] = useState("");
  const [searchType, setSearchType] = useState("");

  const [searchCategory, setSearchCategory] = useState("");
  const [paginationModalType, setPaginationModalType] = useState({
    page: 0,
    pageSize: 10,
  });

  const [searchService, setSearchService] = useState("");
  const [searchServiceCategory, setSearchServiceCategory] = useState("");
  const [searchServiceType, setSearchServiceType] = useState("");

  const [searchCity, setSearchCity] = useState("");
  const [searchDefault, setSearchDefault] = useState("");
  const [searchShortCode, setSearchShortCode] = useState("");
  const [searchLongCode, setSearchLongCode] = useState("");

  const [activeBtn, setActiveBtn] = useState("Longcode");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const [paginationModalLongCode, setPaginationModalLongCode] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [paginationModalShortCode, setPaginationModalShortCode] =
    React.useState({
      page: 0,
      pageSize: 5,
    });
  const [currenciesOptions, setCurrenciesOptions] = useState(false);

  const [isModalOpenSwitchLocationService, setModalOpenSwitchLocationService] =
    useState(false);
  const [isCheckedSwitchLocationService, setCheckedSwitchLocationService] =
    useState(false);

  const [
    isModalOpenSwitchLocationServiceCategory,
    setModalOpenSwitchLocationServiceCategory,
  ] = useState(false);
  const [
    isCheckedSwitchLocationServiceCategory,
    setCheckedSwitchLocationServiceCategory,
  ] = useState(false);

  const [
    isModalOpenSwitchLocationServiceType,
    setModalOpenSwitchLocationServiceType,
  ] = useState(false);
  const [
    isCheckedSwitchLocationServiceType,
    setCheckedSwitchLocationServiceType,
  ] = useState(false);

  const [switchStatesLocationService, setSwitchStatesLocationService] =
    useState({});
  const [isModalOpenSwitchLocationCountry, setModalOpenSwitchLocationCountry] =
    useState(false);
  const [isCheckedSwitchLocationCountry, setCheckedSwitchLocationCountry] =
    useState(false);
  const [switchStatesLocationCountry, setswitchStatesLocationCountry] =
    useState({});
  const [isModalOpenSwitchLocationCity, setModalOpenSwitchLocationCity] =
    useState(false);
  const [isModalOpenSwitchService, setModalOpenSwitchService] = useState(false);
  const [isCheckedSwitchLocationCity, setCheckedSwitchLocationCity] =
    useState(false);
  const [checkedSwitchService, setCheckedSwitchService] = useState(false);
  const [switchStatesLocationCity, setswitchStatesLocationCity] = useState({});
  const [isModalOpenSwitchCriteria, setModalOpenSwitchCriteria] =
    useState(false);
  const [isCheckedSwitchCriteria, setCheckedSwitchCriteria] = useState(false);
  const [switchStatesCriteria, setSwitchStatesCriteria] = useState({});
  const [isModalOpenSwitchCriteriaType, setModalOpenSwitchCriteriaType] =
    useState(false);
  const [isCheckedSwitchCriteriaType, setCheckedSwitchCriteriaType] =
    useState(false);
  const [switchStatesCriteriaType, setSwitchStatesCriteriaType] = useState({});
  const [
    isModalOpenSwitchCriteriaCategory,
    setModalOpenSwitchCriteriaCategory,
  ] = useState(false);
  const [isCheckedSwitchCriteriaCategory, setCheckedSwitchCriteriaCategory] =
    useState(false);
  const [switchStatesCriteriaCategory, setSwitchStatesCriteriaCategory] =
    useState({});
  const [isModalOpenSwitchShortCode, setModalOpenSwitchShortCode] =
    useState(false);
  const [isCheckedSwitchShortCode, setCheckedSwitchShortCode] = useState(false);
  const [switchStatesShortCode, setSwitchStatesShortCode] = useState({});
  const [isModalOpenSwitchOperators, setModalOpenSwitchOperators] =
    useState(false);
  const [isCheckedSwitchOperators, setCheckedSwitchOperators] = useState(false);
  const [switchStatesOperators, setSwitchStatesOperators] = useState({});
  const [
    isModalOpenSwitchOperatorsExlusive,
    setModalOpenSwitchOperatorsExlusive,
  ] = useState(false);
  const [isCheckedSwitchOperatorsExlusive, setCheckedSwitchOperatorsExlusive] =
    useState(false);
  const [switchStatesOperatorsExlusive, setSwitchStatesOperatorsExlusive] =
    useState({});

  const [isModalOpenSwitchLongCode, setModalOpenSwitchLongCode] =
    useState(false);
  const [isCheckedSwitchLongCode, setCheckedSwitchLongCode] = useState(false);
  const [switchStatesLongCode, setSwitchStatesLongCode] = useState({});
  const [isModalOpenSwitchLongCodeTR, setModalOpenSwitchLongCodeTR] =
    useState(false);
  const [isCheckedSwitchLongCodeTR, setCheckedSwitchLongCodeTR] =
    useState(false);
  const [switchStatesLongCodeTR, setSwitchStatesLongCodeTR] = useState({});
  const [isModalOpenSwitchLongCodeRL, setModalOpenSwitchLongCodeRL] =
    useState(false);
  const [isCheckedSwitchLongCodeRL, setCheckedSwitchLongCodeRL] =
    useState(false);
  const [switchStatesLongCodeRL, setSwitchStatesLongCodeRL] = useState({});
  const [ConfirmOpenLocation, setConfirmOpenLocation] = useState(false);
  const [ConfirmOpenServiceCategory, setConfirmOpenServiceCategory] =
    useState(false);
  const [ConfirmOpenServiceType, setConfirmOpenServiceType] = useState(false);

  const [confirmOpenDefault, setConfirmOpenDefault] = useState(false);

  const [modalContentLocation, setModalContentLocation] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenLocationCountry, setConfirmOpenLocationCountry] =
    useState(false);
  const [modalContentLocationCountry, setModalContentLocationCountry] =
    useState({
      onButtonClick: () => {
        console.log("Button Clicked");
      },
    });
  const [ConfirmOpenLocationCity, setConfirmOpenLocationCity] = useState(false);
  const [modalContentLocationCity, setModalContentLocationCity] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenCriteria, setConfirmOpenCriteria] = useState(false);
  const [modalContentCriteria, setModalContentCriteria] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenCriteriaType, setConfirmOpenCriteriaType] = useState(false);
  const [modalContentCriteriaType, setModalContentCriteriaType] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });

  const [ConfirmOpenCriteriaCategory, setConfirmOpenCriteriaCategory] =
    useState(false);
  const [modalContentCriteriaCategory, setModalContentCriteriaCategory] =
    useState({
      onButtonClick: () => {
        console.log("Button Clicked");
      },
    });
  const [ConfirmOpenShortCode, setConfirmOpenShortCode] = useState(false);
  const [modalContentShortCode, setModalContentShortCode] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenLongCode, setConfirmOpenLongCode] = useState(false);
  const [modalContentLongCode, setModalContentLongCode] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenOperators, setConfirmOpenOperators] = useState(false);
  const [modalContentOperators, setModalContentOperators] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [MangeLocationAddopen, setMangeLocationAddopen] = useState(false);

  const [MangeServiceCategoryAddopen, setMangeServiceCategoryAddopen] =
    useState(false);
  const [MangeServiceTypeAddopen, setMangeServiceTypeAddopen] = useState(false);

  const [MangeLocationEditopen, setMangeLocationEditopen] = useState(false);
  const [MangeServiceCategoryEditopen, setMangeServiceCategoryEditopen] =
    useState(false);
  const [MangeServiceTypeEditopen, setMangeServiceTypeEditopen] =
    useState(false);

  const [MangeLocationCountryAddopen, setMangeLocationCountryAddopen] =
    useState(false);
  const [
    mangeLocationCriteriaDefaultAddopen,
    setMangeLocationCriteriaDefaultAddopen,
  ] = useState(false);
  const [MangeLocationCountryEditopen, setMangeLocationCountryEditopen] =
    useState(false);
  const [MangeLocationCityAddopen, setMangeLocationCityAddopen] =
    useState(false);
  const [MangeLocationCityEditopen, setMangeLocationCityEditopen] =
    useState(false);
  const [mangeDefaultEditopen, setMangeDefaultEditopen] = useState(false);
  const [MangeCriteriaAddopen, setMangeCriteriaAddopen] = useState(false);
  const [MangeCriteriaEditopen, setMangeCriteriaEditopen] = useState(false);
  const [MangeCriteriaTypeAddopen, setMangeCriteriaTypeAddopen] =
    useState(false);
  const [MangeCriteriaTypeEditopen, setMangeCriteriaTypeEditopen] =
    useState(false);
  const [MangeCriteriaCategoryAddopen, setMangeCriteriaCategoryAddopen] =
    useState(false);
  const [MangeCriteriaCategoryEditopen, setMangeCriteriaCategoryEditopen] =
    useState(false);
  const [MangeShortCodeAddopen, setMangeShortCodeAddopen] = useState(false);
  const [MangeShortCodeEditopen, setMangeShortCodeEditopen] = useState(false);
  const [MangeLongCodeAddopen, setMangeLongCodeAddopen] = useState(false);
  const [MangeLongCodeEditopen, setMangeLongCodeEditopen] = useState(false);
  const [MangeOperatorsAddopen, setMangeOperatorsAddopen] = useState(false);
  const [MangeOperatorsEditopen, setMangeOperatorsEditopen] = useState(false);
  const [tableDataLocationService, setTableDataLocationService] = useState([]);

  const [
    tableDataLocationServiceCategory,
    setTableDataLocationServiceCategory,
  ] = useState([]);
  const [tableDataLocationServiceType, setTableDataLocationServiceType] =
    useState([]);

  const [tableDataCriteriaType, setTableDataCriteriaType] = useState([]);

  const [tableDataLocationCountry, setTableDataLocationCountry] = useState([]);
  const [tableDataLocationCity, setTableDataLocationCity] = useState([]);
  const [tableDataCriteriaDefault, setTableDataCriteriaDefault] = useState([]);
  const [tableDataShortCode, setTableDataShortCode] = useState([]);
  const [tableDataLongCode, setTableDataLongCode] = useState([]);

  const [tableDataCriteriaCategory, setTableDataCriteriaCategory] = useState(
    []
  );

  const [tableDataLocation, setTableDataLocation] = useState(
    tableDataLocationService
  );
  const [columnsLocation, setColumnsLocation] = useState([]);
  const [selectedFilterCriteria, setSelectedFilterCriteria] =
    useState("Criteria Type");
  const [tableDataCriteriaPage, setTableDataCriteriaPage] = useState(
    tableDataCriteriaType
  );
  const [columnsCriteriaPage, setColumnsCriteriaPage] =
    useState(columnsCriteriaType);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  const [refreshTableService, setRefreshTableService] = useState(false);

  const [refreshTableServiceCategory, setRefreshTableServiceCategory] =
    useState(false);
  const [refreshTableServiceType, setRefreshTableServiceType] = useState(false);

  const [refreshTableShortCode, setRefreshTableShortCode] = useState(false);
  const [refreshTableLongCode, setRefreshTableLongCode] = useState(false);

  const [refreshTableCategory, setRefreshTableCategory] = useState(false);
  const [refreshTableType, setRefreshTableType] = useState(false);

  const [zoneSearchName, setServiceSearchName] = useState("");
  const [paginationModalService, setPaginationModalService] = useState({
    page: 0,
    pageSize: 10,
  });

  const [paginationModalServiceCategory, setPaginationModalServiceCategory] =
    useState({
      page: 0,
      pageSize: 10,
    });
  const [paginationModalServiceType, setPaginationModalServiceType] = useState({
    page: 0,
    pageSize: 10,
  });

  const [refreshTableCountry, setRefreshTableCountry] = useState(false);
  const [countrySearchName, setCountrySearchName] = useState("");
  const [paginationModalCountry, setPaginationModalCountry] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModalDefault, setPaginationModalDefault] = useState({
    page: 0,
    pageSize: 10,
  });

  const [refreshTableCity, setRefreshTableCity] = useState(false);
  const [refreshTableDefault, setRefreshTableDefault] = useState(false);

  const [citySearchName, setCitySearchName] = useState("");
  const [paginationModalCity, setPaginationModalCity] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModalCategory, setPaginationModalCategory] = useState({
    page: 0,
    pageSize: 10,
  });
  const [resetPagination, setResetPagination] = useState(false);
  const [countryFilter, setCountryFilter] = useState("");
  const [CountryOptions, setCountryOptions] = useState([]);
  const [CountryOptionsRecordGuid, setCountryOptionsRecordGuid] = useState([]);
  const [openAdvancedSearchService, setOpenAdvancedSearchService] =
    useState(true);

  const [openAdvancedSearchCountry, setOpenAdvancedSearchCountry] =
    useState(true);
  const [openAdvancedSearchCity, setOpenAdvancedSearchCity] = useState(true);

  const [isCheckedSwitchServiceCategory, setCheckedSwitchServiceCategory] =
    useState(false);
  const [isCheckedSwitchServiceType, setCheckedSwitchServiceType] =
    useState(false);

  const [isCheckedSwitchCountry, setCheckedSwitchCountry] = useState(false);
  const [isCheckedSwitchCity, setCheckedSwitchCity] = useState(false);
  const [isModalOpenSwitchCountry, setModalOpenSwitchCountry] = useState(false);
  const [isModalOpenSwitchCity, setModalOpenSwitchCity] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedServiceCategory, setSelectedServiceCategory] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("");

  const [selectedDefault, setSelectedDefault] = useState("");
  const [selectedLongCode, setSelectedLongCode] = useState("");
  const [selectedShortCode, setSelectedShortCode] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [openExportModalService, setOpenExportModalService] = useState(false);

  const [openExportModalServiceCategory, setOpenExportModalServiceCategory] =
    useState(false);
  const [openExportModalServiceType, setOpenExportModalServiceType] =
    useState(false);

  const handleModalCloseSwitchLocationService = () => {
    setModalOpenSwitchLocationService(false);
  };

  const handleModalCloseSwitchLocationServiceCategory = () => {
    setModalOpenSwitchLocationServiceCategory(false);
  };

  const handleModalCloseSwitchLocationServiceType = () => {
    setModalOpenSwitchLocationServiceType(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);
  const exportService = () => {
    setIsLoading(true);
    setRefreshTableService(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_ZONE}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenExportModalService(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Service.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setOpenExportModalService(false);
        setIsLoading(false);
      });
  };

  const exportServiceCategory = () => {
    setIsLoading(true);
    setRefreshTableServiceCategory(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_ZONE}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenExportModalServiceCategory(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Service Category.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setOpenExportModalServiceCategory(false);
        setIsLoading(false);
      });
  };
  const exportServiceType = () => {
    setIsLoading(true);
    setRefreshTableServiceType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_ZONE}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setOpenExportModalServiceType(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `ServiceType.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setOpenExportModalServiceType(false);
        setIsLoading(false);
      });
  };

  const exportShortCode = () => {
    setIsLoading(true);
    setRefreshTableShortCode(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_CURRENCIES}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalShortCode(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Shortcode.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalShortCode(false);
        setIsLoading(false);
      });
  };
  // const exportLongCode = () => {
  //   setIsLoading(true);
  //   setRefreshTableLongCode(false);
  //   let newServices = JSON.parse(services);
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   setIsLoading(true);
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         newServices["configuration"]
  //       }${EXPORT_LANGUAGES}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setIsLoading(false);
  //       // setOpenExportModalLongCode(false);
  //       setOpenMessageAlert(true);

  //       setMessageData({
  //         message: "Downloaded Successfully ",
  //         type: "success",
  //       });
  //       const url = window.URL.createObjectURL(new Blob([res?.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `Longcode.csv`); // Set the desired file name
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((err) => {
  //       // toast.error("Something Went Wrong");
  //       setIsLoading(false);
  //     })
  //     .finally(() => {
  //       // this.setState({ loading: false });
  //       // setOpenExportModalLongCode(false);
  //       setIsLoading(false);
  //     });
  // };

  const exportLongCode = async () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    try {
      const response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_LANGUAGES_TO_EXCEL}`,
        {
          ...MULTI_TENANCY_CONFIG,
          responseType: "blob",
        }
      );

      // Set the response content type to Excel
      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const headers = response.headers;
      headers["Content-Type"] = contentType;

      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "languages.xlsx";
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  // const exportCountry = () => {
  //   setIsLoading(true);
  //   setRefreshTableCountry(false);
  //   let newServices = JSON.parse(services);
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   setIsLoading(true);
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${newServices["configuration"]
  //       }${EXPORT_COUNTRY}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setIsLoading(false);
  //       // setOpenExportModalCountry(false);
  //       setOpenMessageAlert(true);

  //       setMessageData({ message: "Downloaded Successfully ", type: "success" });
  //       const url = window.URL.createObjectURL(new Blob([res?.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `Country.csv`); // Set the desired file name
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((err) => {
  //       // toast.error("Something Went Wrong");
  //       if (err === "error: 401") {
  //         setIsLoading(false);
  //         exportCountry();
  //       }
  //       setOpenMessageAlert(true);
  //       setMessageData({
  //         message: err?.response?.data?.Message
  //           ? err?.response?.data?.Message
  //           : err?.response?.data?.Exception
  //             ? err?.response?.data?.Exception
  //             : err?.response?.data?.message,
  //         type: "error",
  //       });
  //     })
  //     .finally(() => {
  //       // this.setState({ loading: false });
  //       // setOpenExportModalCountry(false);
  //       setIsLoading(false);
  //     });
  // };

  const exportCountry = async () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    try {
      const response = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_COUNTRY_TO_EXCEL}`,
        {
          ...MULTI_TENANCY_CONFIG,
          responseType: "blob",
        }
      );

      // Set the response content type to Excel
      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const headers = response.headers;
      headers["Content-Type"] = contentType;

      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "countries.xlsx";
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  const exportCity = () => {
    setIsLoading(true);
    setRefreshTableCity(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_CITY}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `City.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportCity();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };
  const exportDefault = () => {
    setIsLoading(true);
    setRefreshTableCity(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${EXPORT_CITY}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        // setOpenExportModalCity(false);
        setOpenMessageAlert(true);

        setMessageData({
          message: "Downloaded Successfully ",
          type: "success",
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `City.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        if (err === "error: 401") {
          setIsLoading(false);
          exportCity();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        // this.setState({ loading: false });
        // setOpenExportModalCity(false);
        setIsLoading(false);
      });
  };

  const tableQueryExecuterService = () => {
    setIsLoading(true);
    setRefreshTableService(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${GET_ALL_SERVICE}?PageIndex=${
          resetPagination ? 1 : paginationModalService?.page + 1
        }&PageSize=${paginationModalService?.pageSize}${
          searchService ? `&keyword=${searchService}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationService({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterService();
        }
        setTableDataLocationService({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tableQueryExecuterServiceCategory = () => {
    setIsLoading(true);
    setRefreshTableServiceCategory(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${GET_ALL_SERVICE_CATEGORY}?PageIndex=${
          resetPagination ? 1 : paginationModalServiceCategory?.page + 1
        }&PageSize=${paginationModalServiceCategory?.pageSize}${
          searchServiceCategory ? `&keyword=${searchServiceCategory}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationServiceCategory({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterServiceCategory();
        }
        setTableDataLocationServiceCategory({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tableQueryExecuterServiceType = () => {
    setIsLoading(true);
    setRefreshTableServiceType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${GET_ALL_SERVICE_TYPE}?PageIndex=${
          resetPagination ? 1 : paginationModalServiceType?.page + 1
        }&PageSize=${paginationModalServiceType?.pageSize}${
          searchServiceType ? `&keyword=${searchServiceType}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationServiceType({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterServiceType();
        }
        setTableDataLocationServiceType({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tableQueryExecuterShortCode = () => {
    setIsLoading(true);
    setRefreshTableShortCode(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${GET_ALL_ITEM_SHORT_CODE}?PageIndex=${
          resetPagination ? 1 : paginationModalShortCode?.page + 1
        }&PageSize=${paginationModalShortCode?.pageSize}${
          searchShortCode ? `&SearchKeyword=${searchShortCode}` : ""
        }&typeTag=SHORT-CODE`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataShortCode({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterShortCode();
        }
        setTableDataShortCode({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterLongCode = () => {
    setIsLoading(true);
    setRefreshTableLongCode(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${GET_ALL_ITEM_SHORT_CODE}?PageIndex=${
          resetPagination ? 1 : paginationModalShortCode?.page + 1
        }&PageSize=${paginationModalShortCode?.pageSize}${
          searchShortCode ? `&SearchKeyword=${searchShortCode}` : ""
        }&typeTag=LONG-CODE`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLongCode({
          data: res?.data?.data?.items ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterLongCode();
        }
        setTableDataLongCode({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterDefault = () => {
    setIsLoading(true);
    setRefreshTableDefault(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CRITERIA}?PageIndex=${
          resetPagination ? 1 : paginationModalDefault?.page + 1
        }&PageSize=${paginationModalDefault?.pageSize}${
          searchDefault ? `&SearchKeyword=${searchDefault}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCriteriaDefault({
          data: res?.data?.data?.criteria ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterDefault();
        }
        setTableDataCriteriaDefault({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterType = () => {
    setIsLoading(true);
    setRefreshTableType(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CRITERIA_TYPE}?PageIndex=${
          resetPagination ? 1 : paginationModalType?.page + 1
        }&PageSize=${paginationModalType?.pageSize}${
          searchType ? `&SearchKeyword=${searchType}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCriteriaType({
          data: res?.data?.data?.criteriaTypes ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterType();
        }
        setTableDataCriteriaType({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterCategory = () => {
    setIsLoading(true);
    setRefreshTableCategory(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CRITERIA_CATEGORY}?PageIndex=${
          resetPagination ? 1 : paginationModalCategory?.page + 1
        }&PageSize=${paginationModalCategory?.pageSize}${
          searchCategory ? `&SearchKeyword=${searchCategory}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataCriteriaCategory({
          data: res?.data?.data?.criteriaCategories ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCategory();
        }
        setTableDataCriteriaCategory({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const tableQueryExecuterCountry = () => {
    setIsLoading(true);
    setRefreshTableCountry(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_COUNTRY}?PageIndex=${
          resetPagination ? 1 : paginationModalCountry?.page + 1
        }&PageSize=${paginationModalCountry?.pageSize}${
          searchCountry ? `&SearchKeyword=${searchCountry}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationCountry({
          data: res?.data?.data?.countries ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCountry();
        }
        setTableDataLocationCountry({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tableQueryExecuterCity = () => {
    setIsLoading(true);
    setRefreshTableCity(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CITY}?PageIndex=${
          resetPagination ? 1 : paginationModalCity?.page + 1
        }&PageSize=${paginationModalCity?.pageSize}${
          searchCity ? `&SearchKeyword=${searchCity}` : ""
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataLocationCity({
          data: res?.data?.data?.cities ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModelMNC({
        //   ...paginationModelMNC,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          tableQueryExecuterCity();
        }
        setTableDataLocationCity({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  useEffect(() => {
    if (
      activeBtn === "Allocate" &&
      (refreshTableService || paginationModalService)
    ) {
      if (!paginationModalService) {
        setPaginationModalService({
          page: 0,
          pageSize: paginationModalService?.pageSize
            ? paginationModalService?.pageSize
            : 10,
        });
      }
      tableQueryExecuterService();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableService,
    paginationModalService,
    searchService,
    // countryFilter,
  ]);

  useEffect(() => {
    if (
      activeBtn === "Category" &&
      (refreshTableServiceCategory || paginationModalServiceCategory)
    ) {
      if (!paginationModalServiceCategory) {
        setPaginationModalServiceCategory({
          page: 0,
          pageSize: paginationModalServiceCategory?.pageSize
            ? paginationModalServiceCategory?.pageSize
            : 10,
        });
      }
      tableQueryExecuterServiceCategory();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableServiceCategory,
    paginationModalServiceCategory,
    searchServiceCategory,
    // countryFilter,
  ]);
  useEffect(() => {
    if (
      activeBtn === "Type" &&
      (refreshTableServiceType || paginationModalServiceType)
    ) {
      if (!paginationModalServiceType) {
        setPaginationModalServiceType({
          page: 0,
          pageSize: paginationModalServiceType?.pageSize
            ? paginationModalServiceType?.pageSize
            : 10,
        });
      }
      tableQueryExecuterServiceType();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableServiceType,
    paginationModalServiceType,
    searchServiceType,
    // countryFilter,
  ]);

  useEffect(() => {
    if (
      activeBtn === "Longcode" &&
      (refreshTableLongCode || paginationModalLongCode)
    ) {
      if (!paginationModalLongCode) {
        setPaginationModalLongCode({
          page: 0,
          pageSize: paginationModalLongCode?.pageSize
            ? paginationModalLongCode?.pageSize
            : 10,
        });
      }
      tableQueryExecuterLongCode();
      // tableQueryExecuterMNC();
    }
  }, [refreshTableLongCode, paginationModalLongCode, searchLongCode]);
  useEffect(() => {
    if (
      activeBtn === "Shortcode" &&
      (refreshTableShortCode || paginationModalShortCode)
    ) {
      if (!paginationModalShortCode) {
        setPaginationModalShortCode({
          page: 0,
          pageSize: paginationModalShortCode?.pageSize
            ? paginationModalShortCode?.pageSize
            : 10,
        });
      }
      tableQueryExecuterShortCode();
      // tableQueryExecuterMNC();
    }
  }, [refreshTableShortCode, paginationModalShortCode, searchShortCode]);
  useEffect(() => {
    if (
      activeBtn === "Allocate" &&
      (refreshTableDefault || paginationModalDefault)
    ) {
      if (!paginationModalDefault) {
        setPaginationModalDefault({
          page: 0,
          pageSize: paginationModalDefault?.pageSize
            ? paginationModalDefault?.pageSize
            : 10,
        });
      }
      tableQueryExecuterDefault();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableDefault,
    paginationModalDefault,
    searchDefault,
    // countryFilter,
  ]);
  useEffect(() => {
    if (activeBtn === "Type" && (refreshTableType || paginationModalType)) {
      if (!paginationModalType) {
        setPaginationModalType({
          page: 0,
          pageSize: paginationModalType?.pageSize
            ? paginationModalType?.pageSize
            : 10,
        });
      }
      tableQueryExecuterType();
      // tableQueryExecuterMNC();
    }
  }, [
    refreshTableType,
    paginationModalType,
    searchType,
    // countryFilter,
  ]);

  useEffect(() => {
    if (searchCategory !== "") {
      setPaginationModalCategory({
        page: 0,
        pageSize: paginationModalCategory?.pageSize
          ? paginationModalCategory?.pageSize
          : 10,
      });
    }
  }, [searchCategory]);

  useEffect(() => {
    if (searchDefault !== "") {
      setPaginationModalDefault({
        page: 0,
        pageSize: paginationModalDefault?.pageSize
          ? paginationModalDefault?.pageSize
          : 10,
      });
    }
  }, [searchDefault]);

  useEffect(() => {
    if (searchType !== "") {
      setPaginationModalType({
        page: 0,
        pageSize: paginationModalType?.pageSize
          ? paginationModalType?.pageSize
          : 10,
      });
    }
  }, [searchType]);
  useEffect(() => {
    if (searchService !== "") {
      setPaginationModalService({
        page: 0,
        pageSize: paginationModalService?.pageSize
          ? paginationModalService?.pageSize
          : 10,
      });
    }
  }, [searchService]);

  useEffect(() => {
    if (searchServiceCategory !== "") {
      setPaginationModalServiceCategory({
        page: 0,
        pageSize: paginationModalServiceCategory?.pageSize
          ? paginationModalServiceCategory?.pageSize
          : 10,
      });
    }
  }, [searchServiceCategory]);

  useEffect(() => {
    if (searchServiceType !== "") {
      setPaginationModalServiceType({
        page: 0,
        pageSize: paginationModalServiceType?.pageSize
          ? paginationModalServiceType?.pageSize
          : 10,
      });
    }
  }, [searchServiceType]);
  useEffect(() => {
    if (searchCountry !== "") {
      setPaginationModalCountry({
        page: 0,
        pageSize: paginationModalCountry?.pageSize
          ? paginationModalCountry?.pageSize
          : 10,
      });
    }
  }, [searchCountry]);
  useEffect(() => {
    if (searchCity !== "") {
      setPaginationModalCity({
        page: 0,
        pageSize: paginationModalCity?.pageSize
          ? paginationModalCity?.pageSize
          : 10,
      });
    }
  }, [searchCity]);

  useEffect(() => {
    if (searchShortCode !== "") {
      setPaginationModalShortCode({
        page: 0,
        pageSize: paginationModalShortCode?.pageSize
          ? paginationModalShortCode?.pageSize
          : 10,
      });
    }
  }, [searchShortCode]);

  useEffect(() => {
    if (searchLongCode !== "") {
      setPaginationModalLongCode({
        page: 0,
        pageSize: paginationModalLongCode?.pageSize
          ? paginationModalLongCode?.pageSize
          : 10,
      });
    }
  }, [searchLongCode]);

  useEffect(() => {
    if (
      activeBtn === "Country" &&
      (refreshTableCountry || paginationModalCountry)
    ) {
      if (!paginationModalCountry) {
        setPaginationModalCountry({
          page: 0,
          pageSize: paginationModalCountry?.pageSize
            ? paginationModalCountry?.pageSize
            : 10,
        });
      }
      tableQueryExecuterCountry();
      // tableQueryExecuterPrefixes();
    }
  }, [refreshTableCountry, paginationModalCountry, searchCountry]);

  useEffect(() => {
    if (activeBtn === "City" && (refreshTableCity || paginationModalCity)) {
      if (!paginationModalCity) {
        setPaginationModalCity({
          page: 0,
          pageSize: paginationModalCity?.pageSize
            ? paginationModalCity?.pageSize
            : 10,
        });
      }
      tableQueryExecuterCity();
    }
  }, [
    refreshTableCity,
    paginationModalCity,
    searchCity,
    // countryFilter,
  ]);
  useEffect(() => {
    if (activeBtn === "Allocate") {
      setPaginationModalService({
        page: 0,
        pageSize: 10,
      });
      setSearchService("");
      setServiceSearchName("");
    }

    if (activeBtn === "Category") {
      setPaginationModalServiceCategory({
        page: 0,
        pageSize: 10,
      });
      setSearchServiceCategory("");
    }

    if (activeBtn === "Type") {
      setPaginationModalServiceType({
        page: 0,
        pageSize: 10,
      });
      setSearchServiceType("");
    }
    if (activeBtn === "Country") {
      setPaginationModalCountry({
        page: 0,
        pageSize: 10,
      });
      setCountrySearchName("");
      setSearchCountry("");
    }
    if (activeBtn === "City") {
      setPaginationModalCity({
        page: 0,
        pageSize: 10,
      });
      setCitySearchName("");
      setSearchCity("");
    }
    if (activeBtn === "Allocate") {
      setPaginationModalDefault({
        page: 0,
        pageSize: 10,
      });
      setSearchDefault("");
    }
    if (activeBtn === "Category") {
      setPaginationModalCategory({
        page: 0,
        pageSize: 10,
      });
      setSearchCategory("");
    }
    if (activeBtn === "Type") {
      setPaginationModalType({
        page: 0,
        pageSize: 10,
      });
      setSearchType("");
    }

    if (activeBtn === "Longcode") {
      setPaginationModalLongCode({
        page: 0,
        pageSize: 10,
      });
      setSearchLongCode("");
    }

    if (activeBtn === "Shortcode") {
      setPaginationModalShortCode({
        page: 0,
        pageSize: 10,
      });
      setSearchShortCode("");
    }
  }, [activeBtn]);
  const handleSwitchChangeSwitchLocationCountry = (rowData) => {
    setModalOpenSwitchLocationCountry(true);
    setCheckedSwitchLocationCountry(rowData?.recordGuid);
    setSelectedCountry(rowData);
  };

  const handleModalCloseSwitchLocationCountry = () => {
    setModalOpenSwitchLocationCountry(false);
  };

  const handleModalConfirmSwitchLocationCountry = () => {
    setModalOpenSwitchLocationCountry(false);
    // setCheckedSwitchLocation(!isCheckedSwitchLocation);
    setswitchStatesLocationCountry((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchLocationCountry]:
        !prevStates[isCheckedSwitchLocationCountry],
    }));
  };

  let columnsLocationService = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          row?.serviceDetails?.length !== 0 && row?.serviceDetails[0].name
        }`;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },

    {
      field: "serviceCategory",
      headerName: "Service Category",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          row?.serviceCategory?.serviceCategoryDetails?.length !== 0 &&
          row?.serviceCategory?.serviceCategoryDetails[0].name
        }`;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.price} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      headerName: "Status",
      field: "isActive",
      renderCell: (params) => {
        const rowId = params.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={params.row?.isActive}
                  onChange={() => {
                    setModalOpenSwitchLocationService(rowId);
                    setSelectedService(params.row);
                  }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  let columnsLocationServiceCategory = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          (row?.serviceCategoryDetails?.length !== 0 &&
            row?.serviceCategoryDetails[0].name) ||
          ""
        }`;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      headerName: "Allow Multiple Subscription",
      field: "allowMultipleSubscription",
      renderCell: (params) => {
        const rowId = params.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params.row?.allowMultipleSubscription}
                  // onChange={() => {
                  //   handleSwitchChangeSwitchLocationServiceCategory(rowId);
                  //   setSelectedServiceCategory(params.row);
                  // }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Status",
      field: "isActive",
      renderCell: (params) => {
        const rowId = params.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params.row?.isActive}

                  // onChange={() => {
                  //   handleSwitchChangeSwitchLocationServiceCategory(rowId);
                  //   setSelectedServiceCategory(params.row);
                  // }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  let columnsLocationServiceType = [
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      // valueGetter: (params) => {
      //   const row = params.row;
      //   return `${row?.serviceCategoryDetails[0].tag}`;
      // },
      // sortComparator: (v1, v2, cellParams1, cellParams2) => {
      //   return v1?.toString().localeCompare(v2?.toString());
      // },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          row?.serviceTypeDetails?.length !== 0 &&
          row?.serviceTypeDetails[0]?.name
        }`;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row?.recordGuid;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params.row?.isActive}
                  // onChange={() => {
                  //   handleSwitchChangeSwitchLocationServiceCategory(rowId);
                  //   setSelectedServiceCategory(params.row);
                  // }}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  const handleSwitchChangeSwitchLocationCity = (rowId) => {
    setModalOpenSwitchLocationCity(true);
    setCheckedSwitchLocationCity(rowId?.recordGuid);
    setSelectedCategory(rowId);
  };
  const handleSwitchChangeSwitchService = (rowId) => {
    setModalOpenSwitchService(true);
    setCheckedSwitchService(rowId);
  };
  const handleModalCloseSwitchLocationCity = () => {
    setModalOpenSwitchLocationCity(false);
  };

  const handleModalConfirmSwitchLocationCity = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLocationCity,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_CITY}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCity(true);
        setModalOpenSwitchLocationCity(false);
        // setRefreshTableCity(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLocationCity(isCheckedSwitchCity);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalCloseSwitchCriteria = () => {
    setModalOpenSwitchCriteria(false);
  };
  const handleModalConfirmSwitchCriteria = () => {
    setModalOpenSwitchCriteria(false);
    // setCheckedSwitchCriteria(!isCheckedSwitchCriteria);
    setSwitchStatesCriteria((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCriteria]: !prevStates[isCheckedSwitchCriteria],
    }));
  };

  const handleModalCloseSwitchCriteriaType = () => {
    setModalOpenSwitchCriteriaType(false);
  };
  const handleModalConfirmSwitchCriteriaType = () => {
    setModalOpenSwitchCriteriaType(false);
    // setCheckedSwitchCriteria(!isCheckedSwitchCriteria);
    setSwitchStatesCriteriaType((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCriteriaType]: !prevStates[isCheckedSwitchCriteriaType],
    }));
  };

  const handleSwitchChangeSwitchCriteriaCategory = (rowId) => {
    setModalOpenSwitchCriteriaCategory(true);
    setCheckedSwitchCriteriaCategory(rowId);
  };

  const handleModalCloseSwitchCriteriaCategory = () => {
    setModalOpenSwitchService(false);
  };
  const handleModalConfirmSwitchCriteriaCategory = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      recordGuid: isCheckedSwitchCriteriaCategory,
      statusTag: "ACTIVE",
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_CATEGORY}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCategory(true);
        setModalOpenSwitchService(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchCriteriaCategory(
            isCheckedSwitchCriteriaCategory
          );
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //

  const handleSwitchChangeSwitchShortCode = (rowId) => {
    setModalOpenSwitchShortCode(true);
    setCheckedSwitchShortCode(rowId?.recordGuid);
    setSelectedShortCode(rowId);
  };

  const handleModalCloseSwitchShortCode = () => {
    setModalOpenSwitchShortCode(false);
  };
  const handleModalConfirmSwitchShortCode = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchShortCode,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_CURRENCIES}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableShortCode(true);
        setModalOpenSwitchShortCode(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchShortCode(isCheckedSwitchShortCode);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //

  const handleSwitchChangeSwitchOperators = (rowId) => {
    setModalOpenSwitchOperators(true);
    setCheckedSwitchOperators(rowId);
  };

  const handleModalCloseSwitchOperators = () => {
    setModalOpenSwitchOperators(false);
  };
  const handleModalConfirmSwitchOperators = () => {
    setModalOpenSwitchOperators(false);
    // setCheckedSwitchOperators(!isCheckedSwitchOperators);
    setSwitchStatesOperators((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchOperators]: !prevStates[isCheckedSwitchOperators],
    }));
  };
  const handleSwitchChangeSwitchOperatorsExlusive = (rowId) => {
    setModalOpenSwitchOperatorsExlusive(true);
    setCheckedSwitchOperatorsExlusive(rowId);
  };

  const handleModalCloseSwitchOperatorsExlusive = () => {
    setModalOpenSwitchOperatorsExlusive(false);
  };
  const handleModalConfirmSwitchOperatorsExlusive = () => {
    setModalOpenSwitchOperatorsExlusive(false);
    // setCheckedSwitchOperators(!isCheckedSwitchOperators);
    setSwitchStatesOperatorsExlusive((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchOperatorsExlusive]:
        !prevStates[isCheckedSwitchOperatorsExlusive],
    }));
  };

  const handleSwitchChangeSwitchLongCode = (rowId) => {
    setModalOpenSwitchLongCode(true);
    setCheckedSwitchLongCode(rowId?.recordGuid);
  };

  const handleModalCloseSwitchLongCode = () => {
    setModalOpenSwitchLongCode(false);
  };
  const handleModalConfirmSwitchLongCode = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLongCode,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_LANG_STATUS}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableLongCode(true);
        setModalOpenSwitchLongCode(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLongCode(isCheckedSwitchLongCode);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  //

  const handleModalCloseSwitchService = () => {
    setModalOpenSwitchService(false);
  };
  const handleModalCloseSwitchCountry = () => {
    setModalOpenSwitchCountry(false);
  };

  const handleModalConfirmSwitchService = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      recordGuid: selectedService?.recordGuid,
      statusTag: selectedService?.isActive ? "INAACTIVE" : "ACTIVE",
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${UPDATE_STATUS_SERVICE}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableService(true);
        setModalOpenSwitchLocationService(false);
        // setRefreshTableService(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchService(checkedSwitchService);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })

      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalConfirmSwitchServiceCategory = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLocationServiceCategory,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_ZONE}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableServiceCategory(true);
        setModalOpenSwitchLocationServiceCategory(false);
        // setRefreshTableServiceCategory(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchServiceCategory(
            isCheckedSwitchServiceCategory
          );
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })

      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalConfirmSwitchServiceType = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLocationServiceType,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_ZONE}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableServiceType(true);
        setModalOpenSwitchLocationServiceType(false);
        // setRefreshTableServiceType(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchServiceType(isCheckedSwitchServiceType);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })

      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalConfirmSwitchCountry = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLocationCountry,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_COUNTRY}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableCountry(true);
        setModalOpenSwitchLocationCountry(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchCountry(isCheckedSwitchCountry);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalConfirmSwitchLongCodeRL = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLongCodeRL,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_LANG_RL}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableLongCode(true);
        setModalOpenSwitchLongCodeRL(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLongCodeRL(isCheckedSwitchLongCodeRL);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleModalCloseSwitchLongCodeTR = () => {
    setModalOpenSwitchLongCodeTR(false);
  };
  const handleModalConfirmSwitchLongCodeTR = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    let body = {
      RecordGuid: isCheckedSwitchLongCodeTR,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${CHANGE_STATUS_LANG_TR}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRefreshTableLongCode(true);
        setModalOpenSwitchLongCodeTR(false);
        // setRefreshTableCountry(false);
        setIsLoading(false);
        setMessageData({
          message: "Successfully Updated",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          setIsLoading(true);
          handleModalConfirmSwitchLongCodeTR(isCheckedSwitchLongCodeTR);
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSwitchChangeSwitchLongCodeRL = (rowId) => {
    setModalOpenSwitchLongCodeRL(true);
    setCheckedSwitchLongCodeRL(rowId?.recordGuid);
  };
  const handleSwitchChangeSwitchLongCodeTR = (rowId) => {
    setModalOpenSwitchLongCodeTR(true);
    setCheckedSwitchLongCodeTR(rowId?.recordGuid);
  };
  const handleModalCloseSwitchLongCodeRL = () => {
    setModalOpenSwitchLongCodeRL(false);
  };

  let columnsLocationCountry = [
    { headerName: "Name", field: "name", flex: 1, minWidth: 200 },
    {
      headerName: "Country ISO",
      field: "countryCode",
      flex: 1,
      minWidth: 100,
    },
    // { headerName: "ISO Code 3", field: "isoCode3",flex: 1, },
    { headerName: "Native Name", field: "nativeName", flex: 1, minWidth: 100 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params?.row?.isActive}
                  // onChange={() =>
                  //   handleSwitchChangeSwitchLocationCountry(rowId)
                  // }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  let columnsType = [
    { headerName: "Name", field: "name", flex: 1, minWidth: 200 },
    { headerName: "Description", field: "description", flex: 1, minWidth: 150 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch disabled={true} checked={params?.row?.isActive} />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  let columnsLocationCity = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Country",
      field: "country",
      renderCell: (params) => {
        const rowData = params?.row;
        return <>{rowData?.country?.name}</>;
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },

    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowData = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params?.row?.isActive}
                  onChange={() => handleSwitchChangeSwitchLocationCity(rowData)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  let columnsCategory = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },

    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: windowWidth < 1000 && 100,
      renderCell: (params) => {
        const data = params?.row;
        return <>{data?.type?.name}</>;
      },
      flex: 1,
    },
    // {
    //   headerName: "Country",
    //   field: "country",
    //   renderCell: (params) => {
    //     const rowData = params?.row;
    //     return <>{rowData?.country?.name}</>;
    //   },
    //   flex: 1,
    // },

    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowData = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={params?.row?.isActive}
                  onChange={() => handleSwitchChangeSwitchService(rowData)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  const tableDataCriteria = [
    {
      id: 1,
      name: "criteria 1",
      criteriaCategoryId: "12322",
      tenantId: "12323342",
      parentId: "22234234",
      status: false,
      tag: "tag 1",
    },
    {
      id: 2,
      name: "criteria 2",
      criteriaCategoryId: "1322",
      status: false,
      tag: "tag 2",
      tenantId: "12323002",
      parentId: "424342",
    },
    {
      id: 3,
      name: "criteria 3",
      criteriaCategoryId: "112233",
      status: false,
      tag: "tag 3",
      tenantId: "12323377",
      parentId: "4234234",
    },
  ];
  let columnsCriteria = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    // {
    //   headerName: "Type",
    //   field: "type",

    //   renderCell: (params) => {
    //     const data = params?.row;
    //     return <>{data?.type?.name}</>;
    //   },

    //   flex: 1,
    // },

    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params?.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={data?.isActive}
                  // onChange={() => handleSwitchChangeSwitchCriteria(data)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];

  let columnsCriteriaCategory = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Criteria Type",
      field: "criteriaType",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Parent ID",
      field: "parentId",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },

    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={switchStatesCriteriaCategory[rowId] || false}
                  onChange={() =>
                    handleSwitchChangeSwitchCriteriaCategory(rowId)
                  }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
  ];
  function formatDateOnly(dateTimeString) {
    const date = new Date(dateTimeString);
    const month = date.getMonth() + 1; // Month is zero-based
    const day = date.getDate();
    const year = date.getFullYear();

    // Padding single digits with leading zero if necessary
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedMonth}/${formattedDay}/${year}`;
  }
  let columnsShortCode = [
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.price} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.cost} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
      minWidth: windowWidth < 1000 && 100,
    },
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.provider?.name} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Country",
      field: "countryName",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Publish From",
      field: "publishFrom",
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          row?.publishFrom !== null ? formatDateOnly(row?.publishFrom) : ""
        } `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Publish To",
      field: "publishTo",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          row?.publishTo !== null ? formatDateOnly(row?.publishTo) : ""
        } `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={data?.isActive}
                  onChange={() => handleSwitchChangeSwitchShortCode(data)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataLang = [
    {
      id: 1,
      name: "Arabic",
      code: "",
      languageMl: "",
      tag: "Tag 1",
      parentId: "12312",
      usedForTranslation: "",
      rlt: "",
      status: "Inactive",
    },
    {
      id: 2,
      name: "English",
      code: "",
      languageMl: "",
      tag: "Tag 2",
      parentId: "43322",
      usedForTranslation: "",
      rlt: "",
      status: "Inactive",
    },
  ];
  let columnsLang = [
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.price} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.cost} ${row?.currencyCode} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
      minWidth: windowWidth < 1000 && 100,
    },
    {
      field: "provider",
      headerName: "Provider",
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.provider?.name} `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Country",
      field: "countryName",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Publish From",
      field: "publishFrom",
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          row?.publishFrom !== null ? formatDateOnly(row?.publishFrom) : ""
        } `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
      minWidth: windowWidth < 1000 && 100,
    },
    {
      headerName: "Publish To",
      field: "publishTo",
      flex: 1,
      minWidth: windowWidth < 1000 && 100,
      valueGetter: (params) => {
        const row = params.row;
        return `${
          row?.publishTo !== null ? formatDateOnly(row?.publishTo) : ""
        } `;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const data = params.row;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={data?.isActive}
                  onChange={() => handleSwitchChangeSwitchShortCode(data)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tableDataOper = [
    {
      id: 1,
      name: "operator 1",
      tag: "Tag 1",
      parentId: "12312",
      country: "U.S",
      exlusive: "",
      status: "Inactive",
    },
    {
      id: 2,
      name: "operator 2",
      tag: "Tag 2",
      parentId: "43322",
      country: "Germany",
      exlusive: "",
      status: "Inactive",
    },
    {
      id: 2,
      name: "operator 3",
      tag: "Tag 3",
      parentId: "23232",
      country: "Lebanon",
      exlusive: "",
      status: "Inactive",
    },
  ];
  let columnsOper = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      headerName: "Tag",
      field: "tag",
      flex: 1,
    },
    {
      headerName: "Parent ID",
      field: "parentId",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Exlusive",
      field: "exlusive",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={switchStatesOperatorsExlusive[rowId] || false}
                  onChange={() =>
                    handleSwitchChangeSwitchOperatorsExlusive(rowId)
                  }
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  disabled={true}
                  checked={switchStatesOperators[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchOperators(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  const tabs = [
    // {
    //   label: "Service",
    //   subTabs: [{ label: "Allocate" }, { label: "Category" }, { label: "Type" }],
    // },

    {
      label: "Longcode",
    },
    {
      label: "Allocate",
    },
  ];
  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptions(
          res?.data?.data?.countries.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryOptionsRecordGuid(
          res?.data?.data?.countries.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        setCountryFilter(res.data.data.countries[0].recordGuid);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllCurrencies = () => {
    let newServices = JSON.parse(services);
    setCurrenciesOptions(false);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CURRENCIES}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCurrenciesOptions(
          res?.data?.data?.currencies.map((x) => ({
            label: x?.name,
            value: x?.code,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCurrencies();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };

  const OpenConfirmModalLocation = (data, title, text, onButtonClick) => {
    setModalContentLocation({ title, text, onButtonClick });
    setSelectedService(data);
    setConfirmOpenLocation(true);
  };

  const OpenConfirmModalServiceCategory = (
    data,
    title,
    text,
    onButtonClick
  ) => {
    setSelectedServiceCategory(data);
    setConfirmOpenServiceCategory(true);
  };
  const OpenConfirmModalServiceType = (data, title, text, onButtonClick) => {
    setSelectedServiceType(data);
    setConfirmOpenServiceType(true);
  };
  const OpenConfirmModalDefault = (data, title, text, onButtonClick) => {
    setSelectedDefault(data);
    setConfirmOpenDefault(true);
  };
  const handleCloseModalLocation = () => {
    setConfirmOpenLocation(false);
  };

  const handleCloseModalServiceCategory = () => {
    setConfirmOpenServiceCategory(false);
  };

  const handleCloseModalServiceType = () => {
    setConfirmOpenServiceType(false);
  };

  const handleConfirmModalLocation = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    const requestBody = {
      recordGuid: selectedService?.recordGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance({
      method: "delete",
      url: `${localStorage.getItem("baseUrl")}${
        newServices["catalog"]
      }${DELETE_SERVICE}`,
      data: requestBody,
      headers: MULTI_TENANCY_CONFIG.headers, // Assuming MULTI_TENANCY_CONFIG contains the headers object
    })
      .then((res) => {
        setConfirmOpenLocation(false);
        setIsLoading(false);
        setRefreshTableService(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalService({
          page: 0,
          pageSize: paginationModalService?.pageSize
            ? paginationModalService?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalLocation();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConfirmModalServiceCategory = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    const requestBody = {
      recordGuid: selectedServiceCategory?.recordGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance({
      method: "delete",
      url: `${localStorage.getItem("baseUrl")}${
        newServices["catalog"]
      }${DELETE_SERVICE_CATEGORY}`,
      data: requestBody,
      headers: MULTI_TENANCY_CONFIG.headers, // Assuming MULTI_TENANCY_CONFIG contains the headers object
    })
      .then((res) => {
        setConfirmOpenServiceCategory(false);
        setIsLoading(false);
        setRefreshTableServiceCategory(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalServiceCategory({
          page: 0,
          pageSize: paginationModalServiceCategory?.pageSize
            ? paginationModalServiceCategory?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalServiceCategory();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConfirmModalServiceType = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    const requestBody = {
      recordGuid: selectedServiceType?.recordGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance({
      method: "delete",
      url: `${localStorage.getItem("baseUrl")}${
        newServices["catalog"]
      }${DELETE_SERVICE_TYPE}`,
      data: requestBody,
      headers: MULTI_TENANCY_CONFIG.headers, // Assuming MULTI_TENANCY_CONFIG contains the headers object
    })
      .then((res) => {
        setConfirmOpenServiceType(false);
        setIsLoading(false);
        setRefreshTableServiceType(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalServiceType({
          page: 0,
          pageSize: paginationModalServiceType?.pageSize
            ? paginationModalServiceType?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalServiceType();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConfirmModalLongCodeDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    const requestBody = {
      recordGuid: selectedLongCode?.recordGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance({
      method: "delete",
      url: `${localStorage.getItem("baseUrl")}${
        newServices["catalog"]
      }${DELETE_ITEM}`,
      data: requestBody,
      headers: MULTI_TENANCY_CONFIG.headers, // Assuming MULTI_TENANCY_CONFIG contains the headers object
    })
      .then((res) => {
        setConfirmOpenLongCode(false);
        setIsLoading(false);
        setRefreshTableLongCode(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalLongCode({
          page: 0,
          pageSize: paginationModalLongCode?.pageSize
            ? paginationModalLongCode?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalLongCode();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleConfirmModalShortCodeDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    const requestBody = {
      recordGuid: selectedShortCode?.recordGuid,
    };
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance({
      method: "delete",
      url: `${localStorage.getItem("baseUrl")}${
        newServices["catalog"]
      }${DELETE_ITEM}`,
      data: requestBody,
      headers: MULTI_TENANCY_CONFIG.headers, // Assuming MULTI_TENANCY_CONFIG contains the headers object
    })
      .then((res) => {
        setConfirmOpenShortCode(false);
        setIsLoading(false);
        setRefreshTableShortCode(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalShortCode({
          page: 0,
          pageSize: paginationModalShortCode?.pageSize
            ? paginationModalShortCode?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalShortCode();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleConfirmModalLocationCityDelete = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CITY}?RecordGuid=${selectedCity?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenLocationCity(false);
        setIsLoading(false);
        setRefreshTableCity(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setPaginationModalCity({
          page: 0,
          pageSize: paginationModalCity?.pageSize
            ? paginationModalCity?.pageSize
            : 10,
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalLocationCityDelete();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalLocationCountry = (title, text, onButtonClick) => {
    setModalContentLocationCountry({ title, text, onButtonClick });
    setConfirmOpenLocationCountry(true);
  };

  const handleConfirmModalLocationCountry = () => {
    setConfirmOpenLocationCountry(false);
  };

  //

  const OpenConfirmModalLocationCity = (data, title, text, onButtonClick) => {
    setModalContentLocationCity({ title, text, onButtonClick });
    setConfirmOpenLocationCity(true);
    setSelectedCity(data);
  };

  const handleConfirmModalLocationCity = (data) => {
    setConfirmOpenLocationCity(false);
    setSelectedCity(data);
  };

  const OpenConfirmModalCriteria = (title, text, onButtonClick) => {
    setModalContentCriteria({ title, text, onButtonClick });
    setConfirmOpenCriteria(true);
  };

  const handleConfirmModalCriteria = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CRITERIA_CATEGORY}?RecordGuid=${
          selectedCategory?.recordGuid
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenCriteriaCategory(false);
        setIsLoading(false);
        setRefreshTableCategory(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });
        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalCriteria();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalCriteriaType = (title, text, onButtonClick) => {
    setModalContentCriteriaType({ title, text, onButtonClick });
    setConfirmOpenCriteriaType(true);
  };

  const handleConfirmModalCriteriaType = () => {
    setConfirmOpenCriteriaType(false);
  };

  const OpenConfirmModalCriteriaCategory = (
    data,
    title,
    text,
    onButtonClick
  ) => {
    setModalContentCriteriaCategory({ title, text, onButtonClick });
    setConfirmOpenCriteriaCategory(!ConfirmOpenCriteriaCategory);
    setSelectedCategory(data);
  };

  const handleConfirmModalCriteriaCategory = (data) => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CRITERIA_CATEGORY}?RecordGuid=${
          selectedCategory?.recordGuid
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setConfirmOpenCriteriaCategory(false);
        setIsLoading(false);
        setRefreshTableCategory(true);
        setMessageData({
          message: res?.data?.message
            ? res?.data?.message
            : "Successfully Deleted",
          type: "success",
        });

        setOpenMessageAlert(true);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleConfirmModalCriteriaCategory();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
        setOpenMessageAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const OpenConfirmModalShortCode = (data, title, text, onButtonClick) => {
    setModalContentShortCode({ title, text, onButtonClick });
    setConfirmOpenShortCode(true);

    setSelectedShortCode(data);
  };

  const handleConfirmModalShortCode = () => {
    setConfirmOpenShortCode(false);
  };

  //

  const OpenConfirmModalLongCode = (data, title, text, onButtonClick) => {
    setModalContentLongCode({ title, text, onButtonClick });
    setConfirmOpenLongCode(true);
    setSelectedLongCode(data);
  };

  const handleConfirmModalLongCode = () => {
    setConfirmOpenLongCode(false);
  };
  const OpenConfirmModalOperators = (title, text, onButtonClick) => {
    setModalContentOperators({ title, text, onButtonClick });
    setConfirmOpenOperators(true);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleConfirmModalOperators = () => {
    setConfirmOpenOperators(false);
  };

  const handleAddMangeLocationopen = () => {
    setMangeLocationAddopen(true);
  };

  const handleAddMangeServiceCategoryopen = () => {
    setMangeServiceCategoryAddopen(true);
  };

  const handleAddMangeServiceTypeopen = () => {
    setMangeServiceTypeAddopen(true);
  };
  const handleAddMangeLocationclose = () => {
    setMangeLocationAddopen(false);
  };
  const handleEditMangeLocationopen = (data) => {
    setMangeLocationEditopen(true);
    setSelectedService(data);
  };
  const handleEditMangeServiceCategoryopen = (data) => {
    setMangeServiceCategoryEditopen(true);
    setSelectedServiceCategory(data);
  };

  const handleEditMangeServiceTypeopen = (data) => {
    setMangeServiceTypeEditopen(true);
    setSelectedServiceType(data);
  };
  const handleEditMangeLocationclose = () => {
    setMangeLocationEditopen(false);
  };
  const handleAddMangeLocationCountryopen = () => {
    setMangeLocationCountryAddopen(true);
  };
  const handleAddMangeLocationCriteriaDefaultopen = () => {
    setMangeLocationCriteriaDefaultAddopen(true);
  };

  const handleAddMangeLocationCountryclose = () => {
    setMangeLocationCountryAddopen(false);
  };
  const handleEditMangeLocationCountryopen = () => {
    setMangeLocationCountryEditopen(true);
  };

  const handleEditMangeLocationCountryclose = () => {
    setMangeLocationCountryEditopen(false);
  };
  const handleAddMangeLocationCityopen = (data) => {
    setSearchCity(data);
    setMangeLocationCityAddopen(true);
  };

  const handleAddMangeLocationCityclose = () => {
    setMangeLocationCityAddopen(false);
  };
  const handleEditMangeLocationCityopen = (data) => {
    setMangeLocationCityEditopen(true);
    setSelectedCity(data);
  };
  const handleEditMangeDefaultopen = (data) => {
    setMangeDefaultEditopen(true);
    setSelectedDefault(data);
  };
  const handleEditMangeLocationCityclose = () => {
    setMangeLocationCityEditopen(false);
  };

  const handleAddMangeCriteriaopen = () => {
    setMangeCriteriaAddopen(true);
  };
  const handleAddMangeCriteriaclose = () => {
    setMangeCriteriaAddopen(false);
  };
  const handleEditMangeCriteriaopen = () => {
    setMangeCriteriaEditopen(true);
  };

  const handleEditMangeCriteriaclose = () => {
    setMangeCriteriaEditopen(false);
  };

  const handleAddMangeCriteriaTypeopen = () => {
    setMangeCriteriaTypeAddopen(true);
  };

  const handleAddMangeCriteriaTypeclose = () => {
    setMangeCriteriaTypeAddopen(false);
  };
  const handleEditMangeCriteriaTypeopen = () => {
    setMangeCriteriaTypeEditopen(true);
  };

  const handleEditMangeCriteriaTypeclose = () => {
    setMangeCriteriaTypeEditopen(false);
  };

  const handleAddMangeCriteriaCategoryopen = () => {
    setMangeCriteriaCategoryAddopen(true);
  };

  const handleAddMangeCriteriaCategoryclose = () => {
    setMangeCriteriaCategoryAddopen(false);
  };
  const handleEditMangeCriteriaCategoryopen = (data) => {
    setMangeCriteriaCategoryEditopen(true);
    setSelectedCategory(data);
  };

  const handleEditMangeCriteriaCategoryclose = () => {
    setMangeCriteriaCategoryEditopen(false);
  };

  const handleAddMangeShortCodeopen = () => {
    setMangeShortCodeAddopen(true);
  };

  const handleAddMangeShortCodeclose = () => {
    setMangeShortCodeAddopen(false);
  };
  const handleEditMangeShortCodeopen = (data) => {
    setMangeShortCodeEditopen(true);
    setSelectedShortCode(data);
  };

  const handleEditMangeShortCodeclose = () => {
    setMangeShortCodeEditopen(false);
  };

  const handleAddMangeLongCodeopen = () => {
    setMangeLongCodeAddopen(true);
  };

  const handleAddMangeLongCodeclose = () => {
    setMangeLongCodeAddopen(false);
  };
  const handleEditMangeLongCodeopen = (data) => {
    setMangeLongCodeEditopen(true);
    setSelectedLongCode(data);
  };

  const handleEditMangeLongCodeclose = () => {
    setMangeLongCodeEditopen(false);
  };

  const handleAddMangeOperatorsopen = () => {
    setMangeOperatorsAddopen(true);
  };

  const handleAddMangeOperatorsclose = () => {
    setMangeOperatorsAddopen(false);
  };
  const handleEditMangeOperatorsopen = () => {
    setMangeOperatorsEditopen(true);
  };

  const handleEditMangeOperatorsclose = () => {
    setMangeOperatorsEditopen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCountries();
    getAllCurrencies();
  }, []);

  return (
    <div className="billing">
      <Grid container>
        <Grid item xs={6} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Services</Typography>
            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          // sx={{ marginTop: windowWidth < 1000 && "-25px" }}
          item
          xs={6}
          md={6}
          className="end"
        >
          {activeBtn === "Location" && (
            <Button className="contained">
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {/* {`Add ${selectedFilterLocation}`} */}
              </Typography>
            </Button>
          )}

          {activeBtn === "Allocate" && (
            <Grid container style={{ display: "flex", justifyContent: "end" }}>
              <Grid
                item
                xs={8}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  variant="contained"
                  className="contained"
                  onClick={handleAddMangeShortCodeopen}
                  style={{ marginRight: "10px" }}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>Add Shared</Typography>
                </Button>
                <Button
                  variant="contained"
                  className="contained"
                  onClick={handleAddMangeShortCodeopen}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>
                    Add Dedicated{" "}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}

          {activeBtn === "Category" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeServiceCategoryopen()}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography
                sx={{ fontSize: "14px" }}
              >{`Add Service Category`}</Typography>
            </Button>
          )}

          {activeBtn === "Type" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeServiceTypeopen()}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography
                sx={{ fontSize: "14px" }}
              >{`Add Service Type`}</Typography>
            </Button>
          )}

          {activeBtn === "Country" && (
            <Button
              className="contained"
              onClick={() => handleAddMangeLocationCountryopen()}
              style={{ visibility: "hidden" }}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>{`Add Country`}</Typography>
            </Button>
          )}

          {activeBtn === "Shortcode" && (
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeShortCodeopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Shortcode</Typography>
            </Button>
          )}
          {activeBtn === "Longcode" && (
            <Grid container style={{ display: "flex", justifyContent: "end" }}>
              <Grid
                item
                xs={8}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  variant="contained"
                  className="contained"
                  onClick={handleAddMangeLongCodeopen}
                  style={{ marginRight: "10px" }}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>Add Shared</Typography>
                </Button>
                <Button
                  variant="contained"
                  className="contained"
                  onClick={handleAddMangeLongCodeopen}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>
                    Add Dedicated{" "}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
          {activeBtn === "Operators" && (
            // <div style={{ width: "100%" }}>
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddMangeOperatorsopen}
            >
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>Add Operator</Typography>
            </Button>
            // </div>
          )}
        </Grid>
      </Grid>
      {windowWidth < 1000 && (
        <Grid
          style={{ marginLeft: "0px" }}
          container
          marginLeft={2}
          marginTop={5}
        >
          <HorizontalTabsSubtabs
            activeBtn={activeBtn}
            tabs={tabs}
            setActiveBtn={setActiveBtn}
          />
        </Grid>
      )}
      {windowWidth > 300 ? (
        <Grid container className="Top-spacing-data div-table">
          {windowWidth > 1000 && (
            <Grid
              item
              xs={windowWidth < 1800 ? 1.7 : 1.3}
              className="vertical-grid"
            >
              <VerticalTabs
                activeBtn={activeBtn}
                tabs={tabs}
                setActiveBtn={setActiveBtn}
              />
            </Grid>
          )}
          <Grid
            item
            xs={
              windowWidth < 1800 && windowWidth > 1000
                ? 10.3
                : windowWidth < 1000
                ? 12
                : 10.7
            }
          >
            {activeBtn === "Allocate" && (
              <Paper variant="CardTable">
                <Table
                  exportFunction={exportService}
                  rowCountState={tableDataLocationService?.totalCount}
                  data={tableDataLocationService?.data}
                  isLoading={isLoading}
                  columns={columnsLocationService}
                  pageIndex={
                    tableDataLocationService?.pageIndex
                      ? tableDataLocationService?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalService}
                  paginationModel={paginationModalService}
                  enableDelete={true}
                  enableEdit={true}
                  deleteFunction={OpenConfirmModalLocation}
                  editFunction={handleEditMangeLocationopen}
                  enableSearch={true}
                  search={searchService}
                  setSearch={setSearchService}
                  enableExportBtn={true}
                />
              </Paper>
            )}

            {activeBtn === "Category" && (
              <Paper variant="CardTable">
                <Table
                  exportFunction={exportServiceCategory}
                  rowCountState={tableDataLocationServiceCategory?.totalCount}
                  data={tableDataLocationServiceCategory?.data}
                  isLoading={isLoading}
                  columns={columnsLocationServiceCategory}
                  pageIndex={
                    tableDataLocationServiceCategory?.pageIndex
                      ? tableDataLocationServiceCategory?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalServiceCategory}
                  paginationModel={paginationModalServiceCategory}
                  enableDelete={true}
                  enableEdit={true}
                  deleteFunction={OpenConfirmModalServiceCategory}
                  editFunction={handleEditMangeServiceCategoryopen}
                  enableSearch={true}
                  search={searchServiceCategory}
                  setSearch={setSearchServiceCategory}
                  enableExportBtn={true}
                />
              </Paper>
            )}
            {activeBtn === "Type" && (
              <Paper variant="CardTable">
                <Table
                  exportFunction={exportServiceType}
                  rowCountState={tableDataLocationServiceType?.totalCount}
                  data={tableDataLocationServiceType?.data}
                  isLoading={isLoading}
                  columns={columnsLocationServiceType}
                  pageIndex={
                    tableDataLocationServiceType?.pageIndex
                      ? tableDataLocationServiceType?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalServiceType}
                  paginationModel={paginationModalServiceType}
                  enableDelete={true}
                  enableEdit={true}
                  deleteFunction={OpenConfirmModalServiceType}
                  editFunction={handleEditMangeServiceTypeopen}
                  enableSearch={true}
                  search={searchServiceType}
                  setSearch={setSearchServiceType}
                  enableExportBtn={true}
                />
              </Paper>
            )}
            {activeBtn === "Country" && (
              <Paper variant="CardTable">
                <Table
                  exportFunction={exportCountry}
                  rowCountState={tableDataLocationCountry?.totalCount}
                  data={tableDataLocationCountry?.data}
                  isLoading={isLoading}
                  columns={columnsLocationCountry}
                  pageIndex={
                    tableDataLocationCountry?.pageIndex
                      ? tableDataLocationCountry?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalCountry}
                  paginationModel={paginationModalCountry}
                  disableActions={true}
                  // enableDelete={true}
                  // enableEdit={true}
                  // deleteFunction={() => OpenConfirmModalLocationCountry()}
                  // editFunction={() => handleEditMangeLocationCountryopen()}
                  // enableSearch={true}
                  search={searchCountry}
                  setSearch={setSearchCountry}
                  // enableExportBtn={true}
                />
              </Paper>
            )}

            {activeBtn === "Shortcode" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataShortCode?.totalCount}
                  data={tableDataShortCode?.data}
                  isLoading={isLoading}
                  columns={columnsShortCode}
                  pageIndex={
                    tableDataShortCode?.pageIndex
                      ? tableDataShortCode?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalShortCode}
                  paginationModel={paginationModalShortCode}
                  enableDelete={true}
                  enableEdit={true}
                  // enableExportBtn={true}
                  deleteFunction={OpenConfirmModalShortCode}
                  editFunction={handleEditMangeShortCodeopen}
                  // enableSearch={true}
                  search={searchShortCode}
                  setSearch={setSearchShortCode}
                  exportFunction={exportShortCode}
                />
              </Paper>
            )}
            {activeBtn === "Longcode" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataLongCode?.totalCount}
                  data={tableDataLongCode?.data}
                  isLoading={isLoading}
                  columns={columnsLang}
                  pageIndex={
                    tableDataLongCode?.pageIndex
                      ? tableDataLongCode?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModalLongCode}
                  paginationModel={paginationModalLongCode}
                  enableDelete={true}
                  enableEdit={true}
                  // enableExportBtn={true}
                  deleteFunction={OpenConfirmModalLongCode}
                  editFunction={handleEditMangeLongCodeopen}
                  // enableSearch={true}
                  search={searchLongCode}
                  setSearch={setSearchLongCode}
                  exportFunction={exportLongCode}
                />
              </Paper>
            )}
            {activeBtn === "Operators" && (
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataOper?.totalCount}
                  data={tableDataOper}
                  columns={columnsOper}
                  pageIndex={
                    tableDataOper?.pageIndex ? tableDataOper?.pageIndex : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  enableDelete={true}
                  enableEdit={true}
                  // enableExportBtn={true}
                  deleteFunction={OpenConfirmModalOperators}
                  editFunction={handleEditMangeOperatorsopen}
                  enableSearch={true}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          md={10}
          lg={10}
          xs={
            windowWidth < 1800 && windowWidth > 1000
              ? 10.3
              : windowWidth < 1000
              ? 15
              : 10.7
          }
        >
          {activeBtn === "Allocate" && (
            <Paper variant="CardTable">
              <Table
                exportFunction={exportService}
                rowCountState={tableDataLocationService?.totalCount}
                data={tableDataLocationService?.data}
                isLoading={isLoading}
                columns={columnsLocationService}
                pageIndex={
                  tableDataLocationService?.pageIndex
                    ? tableDataLocationService?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModalService}
                paginationModel={paginationModalService}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalLocation}
                editFunction={handleEditMangeLocationopen}
                enableSearch={true}
                search={searchService}
                setSearch={setSearchService}
                enableExportBtn={true}
              />
            </Paper>
          )}
          {activeBtn === "Category" && (
            <Paper variant="CardTable">
              <Table
                exportFunction={exportServiceCategory}
                rowCountState={tableDataLocationServiceCategory?.totalCount}
                data={tableDataLocationServiceCategory?.data}
                isLoading={isLoading}
                columns={columnsLocationServiceCategory}
                pageIndex={
                  tableDataLocationServiceCategory?.pageIndex
                    ? tableDataLocationServiceCategory?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModalServiceCategory}
                paginationModel={paginationModalServiceCategory}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalServiceCategory}
                editFunction={handleEditMangeServiceCategoryopen}
                enableSearch={true}
                search={searchServiceCategory}
                setSearch={setSearchServiceCategory}
                enableExportBtn={true}
              />
            </Paper>
          )}
          {activeBtn === "Type" && (
            <Paper variant="CardTable">
              <Table
                exportFunction={exportServiceType}
                rowCountState={tableDataLocationServiceType?.totalCount}
                data={tableDataLocationServiceType?.data}
                isLoading={isLoading}
                columns={columnsLocationServiceType}
                pageIndex={
                  tableDataLocationServiceType?.pageIndex
                    ? tableDataLocationServiceType?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModalServiceType}
                paginationModel={paginationModalServiceType}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalServiceType}
                editFunction={handleEditMangeServiceTypeopen}
                enableSearch={true}
                search={searchServiceType}
                setSearch={setSearchServiceType}
                enableExportBtn={true}
              />
            </Paper>
          )}
          {activeBtn === "Shortcode" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataShortCode?.totalCount}
                data={tableDataShortCode?.data}
                isLoading={isLoading}
                columns={columnsShortCode}
                pageIndex={
                  tableDataShortCode?.pageIndex
                    ? tableDataShortCode?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModalShortCode}
                paginationModel={paginationModalShortCode}
                enableDelete={true}
                enableEdit={true}
                // enableExportBtn={true}
                deleteFunction={OpenConfirmModalShortCode}
                editFunction={handleEditMangeShortCodeopen}
                // enableSearch={true}
                search={searchShortCode}
                setSearch={setSearchShortCode}
                exportFunction={exportShortCode}
              />
            </Paper>
          )}
          {activeBtn === "Longcode" && (
            <Paper variant="CardTable">
              <Table
                rowCountState={tableDataLongCode?.totalCount}
                data={tableDataLongCode?.data}
                isLoading={isLoading}
                columns={columnsLang}
                pageIndex={
                  tableDataLongCode?.pageIndex
                    ? tableDataLongCode?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModalLongCode}
                paginationModel={paginationModalLongCode}
                enableDelete={true}
                enableEdit={true}
                // enableExportBtn={true}
                deleteFunction={OpenConfirmModalLongCode}
                editFunction={handleEditMangeLongCodeopen}
                // enableSearch={true}
                search={searchLongCode}
                setSearch={setSearchLongCode}
                exportFunction={exportLongCode}
              />
            </Paper>
          )}
        </Grid>
      )}
      {/*  
      function of delete button
      onClick={() => OpenConfirmModal("Modal Title", "Modal Text", () => console.log("Button Clicked"))} 
      */}

      {/* Delete location Modal */}
      {handleConfirmModalLocation && (
        <ConfirmationModal
          open={ConfirmOpenLocation}
          onClose={handleCloseModalLocation}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={handleConfirmModalLocation}
        />
      )}

      {handleConfirmModalServiceCategory && (
        <ConfirmationModal
          open={ConfirmOpenServiceCategory}
          onClose={handleCloseModalServiceCategory}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={handleConfirmModalServiceCategory}
        />
      )}

      {handleConfirmModalServiceType && (
        <ConfirmationModal
          open={ConfirmOpenServiceType}
          onClose={handleCloseModalServiceType}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={handleConfirmModalServiceType}
        />
      )}
      {/* Delete location Country Modal */}
      {handleConfirmModalLocationCountry && (
        <ConfirmationModal
          open={ConfirmOpenLocationCountry}
          onClose={handleConfirmModalLocationCountry}
          title="Delete"
          text="Are you sure you want to delete this country ?"
          onButtonClick={handleConfirmModalLocationCountry}
        />
      )}
      {/* Delete location City Modal */}
      {handleConfirmModalLocationCity && (
        <ConfirmationModal
          open={ConfirmOpenLocationCity}
          onClose={handleConfirmModalLocationCity}
          title="Delete"
          text="Are you sure you want to delete this city ?"
          onButtonClick={handleConfirmModalLocationCityDelete}
        />
      )}
      {/* Delete Criteria Modal */}
      {handleConfirmModalCriteria && (
        <ConfirmationModal
          open={ConfirmOpenCriteria}
          onClose={handleConfirmModalCriteria}
          title="Delete"
          text="Are you sure you want to delete this criteria ?"
          onButtonClick={handleConfirmModalCriteria}
        />
      )}
      {/* Delete Criteria Type Modal */}
      {handleConfirmModalCriteriaType && (
        <ConfirmationModal
          open={ConfirmOpenCriteriaType}
          onClose={handleConfirmModalCriteriaType}
          title="Delete"
          text="Are you sure you want to delete this criteria type?"
          onButtonClick={handleConfirmModalCriteria}
        />
      )}

      {/* Delete Criteria Category Modal */}
      {OpenConfirmModalCriteriaCategory && (
        <ConfirmationModal
          open={ConfirmOpenCriteriaCategory}
          onClose={OpenConfirmModalCriteriaCategory}
          title="Delete"
          text="Are you sure you want to delete this criteria category?"
          onButtonClick={handleConfirmModalCriteriaCategory}
        />
      )}
      {/* Delete Shortcode Modal */}
      {handleConfirmModalShortCode && (
        <ConfirmationModal
          open={ConfirmOpenShortCode}
          onClose={handleConfirmModalShortCode}
          title="Delete"
          text="Are you sure you want to delete this currency ?"
          onButtonClick={handleConfirmModalShortCodeDelete}
        />
      )}
      {/* Delete Longcode Modal */}
      {handleConfirmModalLongCode && (
        <ConfirmationModal
          open={ConfirmOpenLongCode}
          onClose={handleConfirmModalLongCode}
          title="Delete"
          text="Are you sure you want to delete this record ?"
          onButtonClick={handleConfirmModalLongCodeDelete}
        />
      )}
      {/* Delete Operators Modal */}
      {handleConfirmModalOperators && (
        <ConfirmationModal
          open={ConfirmOpenOperators}
          onClose={handleConfirmModalOperators}
          title="Delete "
          text="Are you sure you want to delete this operator ?"
          onButtonClick={handleConfirmModalOperators}
        />
      )}

      {/* Manage location Modal */}
      {handleAddMangeLocationopen && (
        <ManageService
          open={MangeLocationAddopen}
          setOpen={setMangeLocationAddopen}
          title={"Add"}
          setRefreshTableService={setRefreshTableService}
          setPaginationModalService={setPaginationModalService}
          setServiceSearchName={setServiceSearchName}
          selectedService={selectedService}
          paginationModalService={paginationModalService}
          currenciesOptions={currenciesOptions}
        />
      )}
      {handleEditMangeLocationopen && (
        <ManageService
          open={MangeLocationEditopen}
          setOpen={setMangeLocationEditopen}
          title={"Edit"}
          isEdit={true}
          setRefreshTableService={setRefreshTableService}
          setPaginationModalService={setPaginationModalService}
          setServiceSearchName={setServiceSearchName}
          selectedService={selectedService}
          paginationModalService={paginationModalService}
          currenciesOptions={currenciesOptions}
        />
      )}

      {handleAddMangeServiceCategoryopen && (
        <ManageServiceCategory
          open={MangeServiceCategoryAddopen}
          setOpen={setMangeServiceCategoryAddopen}
          title={"Add"}
          setRefreshTableServiceCategory={setRefreshTableServiceCategory}
          setPaginationModalServiceCategory={setPaginationModalServiceCategory}
          selectedServiceCategory={selectedServiceCategory}
          paginationModalServiceCategory={paginationModalServiceCategory}
        />
      )}
      {handleEditMangeServiceCategoryopen && (
        <ManageServiceCategory
          open={MangeServiceCategoryEditopen}
          setOpen={setMangeServiceCategoryEditopen}
          title={"Edit"}
          isEdit={true}
          setRefreshTableServiceCategory={setRefreshTableServiceCategory}
          setPaginationModalServiceCategory={setPaginationModalServiceCategory}
          selectedServiceCategory={selectedServiceCategory}
          paginationModalServiceCategory={paginationModalServiceCategory}
        />
      )}

      {handleAddMangeServiceTypeopen && (
        <ManageServiceType
          open={MangeServiceTypeAddopen}
          setOpen={setMangeServiceTypeAddopen}
          title={"Add"}
          setRefreshTableServiceType={setRefreshTableServiceType}
          setPaginationModalServiceType={setPaginationModalServiceType}
          selectedServiceType={selectedServiceType}
          paginationModalServiceType={paginationModalServiceType}
        />
      )}
      {handleEditMangeServiceTypeopen && (
        <ManageServiceType
          open={MangeServiceTypeEditopen}
          setOpen={setMangeServiceTypeEditopen}
          title={"Edit"}
          isEdit={true}
          setRefreshTableServiceType={setRefreshTableServiceType}
          setPaginationModalServiceType={setPaginationModalServiceType}
          selectedServiceType={selectedServiceType}
          paginationModalServiceType={paginationModalServiceType}
        />
      )}

      {/* Manage location Country Modal */}
      {handleAddMangeLocationCountryopen && (
        <ManageCountry
          open={MangeLocationCountryAddopen}
          setOpen={setMangeLocationCountryAddopen}
          title={"Add Country"}
        />
      )}
      {handleEditMangeLocationCountryopen && (
        <ManageCountry
          open={MangeLocationCountryEditopen}
          setOpen={setMangeLocationCountryEditopen}
          title={"Edit Country"}
        />
      )}

      {/* Manage location Modal  City*/}
      {handleAddMangeLocationCityopen && (
        <ManageCity
          open={MangeLocationCityAddopen}
          setOpen={setMangeLocationCityAddopen}
          title={"Add City"}
          CountryOptions={CountryOptions}
          // selectedCity={selectedCity}
          setPaginationModalCity={setPaginationModalCity}
          setRefreshTableCity={setRefreshTableCity}
          paginationModalCity={paginationModalCity}
          isEdit={false}
        />
      )}
      {handleEditMangeLocationCityopen && (
        <ManageCity
          open={MangeLocationCityEditopen}
          setOpen={setMangeLocationCityEditopen}
          title={"Edit City"}
          isEdit={true}
          selectedCity={selectedCity}
          CountryOptions={CountryOptions}
          setPaginationModalCity={setPaginationModalCity}
          setRefreshTableCity={setRefreshTableCity}
          paginationModalCity={paginationModalCity}
        />
      )}
      {/* Manage Criteria Modal */}
      {handleAddMangeCriteriaopen && (
        <ManageCriteria
          open={MangeCriteriaAddopen}
          setOpen={setMangeCriteriaAddopen}
          title={"Add Criteria"}
        />
      )}
      {handleEditMangeCriteriaopen && (
        <ManageCriteria
          open={MangeCriteriaEditopen}
          setOpen={setMangeCriteriaEditopen}
          title={"Edit Criteria"}
        />
      )}
      {/* Manage Criteria Type Modal */}
      {handleAddMangeCriteriaTypeopen && (
        <ManageCriteriaType
          open={MangeCriteriaTypeAddopen}
          setOpen={setMangeCriteriaTypeAddopen}
          title={"Add Criteria Type"}
        />
      )}
      {handleEditMangeCriteriaTypeopen && (
        <ManageCriteriaType
          open={MangeCriteriaTypeEditopen}
          setOpen={setMangeCriteriaTypeEditopen}
          title={"Edit Criteria Type"}
        />
      )}
      {/* Manage Criteria Category Modal */}
      {handleAddMangeCriteriaopen && (
        <ManageCriteriaCategory
          open={MangeCriteriaCategoryAddopen}
          setOpen={setMangeCriteriaCategoryAddopen}
          title={"Add Criteria Category"}
          selectedCategory={selectedCategory}
          setPaginationModalCategory={setPaginationModalCategory}
          setRefreshTableCategory={setRefreshTableCategory}
        />
      )}
      {handleEditMangeCriteriaCategoryopen && (
        <ManageCriteriaCategory
          open={MangeCriteriaCategoryEditopen}
          setOpen={setMangeCriteriaCategoryEditopen}
          title={"Edit Criteria Category"}
          selectedCategory={selectedCategory}
          isEdit={true}
          setPaginationModalCategory={setPaginationModalCategory}
          setRefreshTableCategory={setRefreshTableCategory}
        />
      )}
      {/* Manage Shortcode Modal */}
      {handleAddMangeShortCodeopen && (
        <ManageShortCode
          open={MangeShortCodeAddopen}
          setOpen={setMangeShortCodeAddopen}
          title={"Add"}
          // selectedShortCode={selectedShortCode}
          setPaginationModalShortCode={setPaginationModalShortCode}
          setRefreshTableShortCode={setRefreshTableShortCode}
          CountryOptions={CountryOptions}
          paginationModalShortCode={paginationModalShortCode}
          currenciesOptions={currenciesOptions}
        />
      )}
      {handleEditMangeShortCodeopen && (
        <ManageShortCode
          open={MangeShortCodeEditopen}
          setOpen={setMangeShortCodeEditopen}
          title={"Edit"}
          selectedShortCode={selectedShortCode}
          setPaginationModalShortCode={setPaginationModalShortCode}
          setRefreshTableShortCode={setRefreshTableShortCode}
          isEdit={true}
          CountryOptions={CountryOptions}
          currenciesOptions={currenciesOptions}
          paginationModalShortCode={paginationModalShortCode}
        />
      )}

      {/* Manage Longcode Modal */}
      {handleAddMangeLongCodeopen && (
        <ManageLongCode
          open={MangeLongCodeAddopen}
          setOpen={setMangeLongCodeAddopen}
          title={"Add"}
          // selectedLongCode={selectedLongCode}
          setPaginationModalLongCode={setPaginationModalLongCode}
          setRefreshTableLongCode={setRefreshTableLongCode}
          paginationModalLongCode={paginationModalLongCode}
          CountryOptions={CountryOptions}
          currenciesOptions={currenciesOptions}
        />
      )}
      {handleEditMangeLongCodeopen && (
        <ManageLongCode
          open={MangeLongCodeEditopen}
          setOpen={setMangeLongCodeEditopen}
          title={"Edit"}
          isEdit={true}
          selectedLongCode={selectedLongCode}
          setPaginationModalLongCode={setPaginationModalLongCode}
          setRefreshTableLongCode={setRefreshTableLongCode}
          paginationModalLongCode={paginationModalLongCode}
          CountryOptions={CountryOptions}
          currenciesOptions={currenciesOptions}
        />
      )}

      {/* Manage Operators Modal */}
      {handleAddMangeOperatorsopen && (
        <ManageOperators
          open={MangeOperatorsAddopen}
          setOpen={setMangeOperatorsAddopen}
          title={"Add Operator"}
        />
      )}
      {handleEditMangeOperatorsopen && (
        <ManageOperators
          open={MangeOperatorsEditopen}
          setOpen={setMangeOperatorsEditopen}
          title={"Edit Operator"}
        />
      )}
      {/* Swtich LocationService Modal */}
      {isModalOpenSwitchLocationService && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationService}
          onClose={handleModalCloseSwitchLocationService}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchService}
        />
      )}

      {isModalOpenSwitchLocationServiceCategory && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationServiceCategory}
          onClose={handleModalCloseSwitchLocationServiceCategory}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchServiceCategory}
        />
      )}
      {isModalOpenSwitchLocationServiceType && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationServiceType}
          onClose={handleModalCloseSwitchLocationServiceType}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchServiceType}
        />
      )}
      {/* Swtich location Country Modal */}
      {isModalOpenSwitchLocationCountry && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationCountry}
          onClose={handleModalCloseSwitchLocationCountry}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCountry}
        />
      )}

      {/* Swtich location City Modal */}
      {isModalOpenSwitchLocationCity && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationCity}
          onClose={handleModalCloseSwitchLocationCity}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchLocationCity}
        />
      )}
      {/* {isModalOpenSwitchCriteriaCategory && (
        <ConfirmationModal
          open={isModalOpenSwitchCriteriaCategory}
          onClose={handleModalCloseSwitchLocationCity}
          title="Change Status"
          text="Are you sure you want to change this City Status?"
          onButtonClick={handleModalConfirmSwitchLocationCity}
        />
      )} */}
      {/* Swtich Criteria Modal */}
      {isModalOpenSwitchCriteria && (
        <ConfirmationModal
          open={isModalOpenSwitchCriteria}
          onClose={handleModalCloseSwitchCriteria}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCriteria}
        />
      )}
      {/* Swtich Criteria Type Modal */}
      {isModalOpenSwitchCriteriaType && (
        <ConfirmationModal
          open={isModalOpenSwitchCriteriaType}
          onClose={handleModalCloseSwitchCriteriaType}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCriteriaType}
        />
      )}
      {/* Swtich Criteria Category Modal */}
      {isModalOpenSwitchService && (
        <ConfirmationModal
          open={isModalOpenSwitchService}
          onClose={handleModalCloseSwitchCriteriaCategory}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchCriteriaCategory}
        />
      )}
      {/* Swtich Shortcode Modal */}
      {isModalOpenSwitchShortCode && (
        <ConfirmationModal
          open={isModalOpenSwitchShortCode}
          onClose={handleModalCloseSwitchShortCode}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchShortCode}
        />
      )}
      {/* Swtich Longcode Modal */}
      {isModalOpenSwitchLongCode && (
        <ConfirmationModal
          open={isModalOpenSwitchLongCode}
          onClose={handleModalCloseSwitchLongCode}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchLongCode}
        />
      )}
      {isModalOpenSwitchLongCodeTR && (
        <ConfirmationModal
          open={isModalOpenSwitchLongCodeTR}
          onClose={handleModalCloseSwitchLongCodeTR}
          title="Change Status"
          text="Are you sure you want to change this translation status?"
          onButtonClick={handleModalConfirmSwitchLongCodeTR}
        />
      )}
      {isModalOpenSwitchLongCodeRL && (
        <ConfirmationModal
          open={isModalOpenSwitchLongCodeRL}
          onClose={handleModalCloseSwitchLongCodeRL}
          title="Change Status"
          text="Are you sure you want to change this RTL status?"
          onButtonClick={handleModalConfirmSwitchLongCodeRL}
        />
      )}
      {/* Swtich Operators Modal */}
      {isModalOpenSwitchOperators && (
        <ConfirmationModal
          open={isModalOpenSwitchOperators}
          onClose={handleModalCloseSwitchOperators}
          title="Change Status"
          text="Are you sure you want to change this status?"
          onButtonClick={handleModalConfirmSwitchOperators}
        />
      )}
      {/* Swtich Operators Exlusive Modal */}
      {isModalOpenSwitchOperatorsExlusive && (
        <ConfirmationModal
          open={isModalOpenSwitchOperatorsExlusive}
          onClose={handleModalCloseSwitchOperatorsExlusive}
          title="Change Status"
          text="Are you sure you want to change this Exlusive Status?"
          onButtonClick={handleModalConfirmSwitchOperatorsExlusive}
        />
      )}

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Longcode;
