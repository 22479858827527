import { Box, Typography } from "@mui/material";
import React from "react";
import montymobileLogo from "../Assets/monty-mobile-logo.svg";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
const NotHaveAccess = () => {
  return (
    <div style={{width:"100%"}}>
      <Box style={{marginLeft:"10px"}}>
        <img src={montymobileLogo} alt={"logo"} className="logo-style" />
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height:
            "85vh" /* Sets the height of the container to 100% of the viewport height */,
          textAlign: "center",
          width: "100%",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Typography style={{ fontSize: "35px", color: "grey" }}>
            You do not have any access to any page. Please contact adminstrator.
          </Typography>
          <SentimentDissatisfiedIcon style={{color: "grey",fontSize:"55px" }}/>
        </div>
      </div>
    </div>
  );
};

export default NotHaveAccess;
