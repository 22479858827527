import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./Authentication/SignIn";
import SignInKeyStore from "./Authentication/SignInKeyStore";
import SignInOmni from "./Authentication/SignInOmni";
import ApiKeyService from "./Service-Manager/Containers/ApiKeyService/ApiKeyService";
import BillingConfig from "./Service-Manager/Containers/Billing/BillingConfig";
import BillingPricingServices from "./Service-Manager/Containers/Billing/BillingPricingServices";
import Catalog from "./Service-Manager/Containers/CatalogManagment/Catalog";
import ChannelsDashboard from "./Service-Manager/Containers/ChannelsDashboard/ChannelsDashboard";
import Clients2 from "./Service-Manager/Containers/Clients-2/Billing";
import ClientsConfig from "./Service-Manager/Containers/ClientsConfig/ClientsConfig";
import ClientsDashboard from "./Service-Manager/Containers/ClientsDashboard/ClientsDashboard";
import Configuration from "./Service-Manager/Containers/Configuration/Configuration";
import DataSeed from "./Service-Manager/Containers/DataSeed/DataSeed";
import LandingPage from "./Service-Manager/Containers/LandingPage/LandingPage";
import ManageTenant from "./Service-Manager/Containers/ManageTenant/ManageTenant";
import MembersManagement from "./Service-Manager/Containers/MembersManagement/MembersManagement";
import NewProvider from "./Service-Manager/Containers/NewProvider/NewProvider";
import OTPStoreConfig from "./Service-Manager/Containers/OTP/OtpStoreConf";
import Pin from "./Service-Manager/Containers/OTP/Pin";
import Providers2 from "./Service-Manager/Containers/Providers/Billing";
import ProvidersDashboard from "./Service-Manager/Containers/ProvidersDashboard/ProvidersDashboard";
import Reasons from "./Service-Manager/Containers/Reasons/Reasons";
import Resellers from "./Service-Manager/Containers/Resellers/Resellers";
import ResellersConfig from "./Service-Manager/Containers/ResellersConfig/ResellersConfig";
import ResellersDashboard from "./Service-Manager/Containers/ResellersDashboard/ResellersDashboard";
import RolesPage from "./Service-Manager/Containers/Roles/Roles";
import RoutesServices from "./Service-Manager/Containers/RoutesServices/RoutesServices";
import URLStoreConfig from "./Service-Manager/Containers/ShortUrl/URLStoreConfig";
import UrlStore from "./Service-Manager/Containers/ShortUrl/UrlStore";
import SMSOneWay from "./Service-Manager/Containers/SmsOneWay/SmsOneWay";
import SMSTwoWay from "./Service-Manager/Containers/SmsTwoWay/SmsTwoWay";
import Users from "./Service-Manager/Containers/Users/Users";
import NavigationRouter from "./Service-Manager/HOCs/NavigationRouter";

import ProfitLoss from "./Service-Manager/Containers/Billing/ProfitLoss";

import NotHaveAccess from "./Authentication/NotHaveAccess";
import Costs from "./Service-Manager/Containers/Billing/Costs";
import Rates from "./Service-Manager/Containers/Billing/Rates";
import NewClient from "./Service-Manager/Containers/Clients/ManageClient";
import ManageResellers from "./Service-Manager/Containers/ManageResellers/ManageResellers";
import AddReseller from "./Service-Manager/Containers/ManageResellers/AddReseller";
import EditResellerDetails from "./Service-Manager/Containers/ManageResellers/EditResellerDetails";
import EditResellerConfig from "./Service-Manager/Containers/ManageResellers/EditResellerConfig";

import Services from "./Service-Manager/Containers/Services/Services";
import Shortcode from "./Service-Manager/Containers/Services/Shortcode";
import Longcode from "./Service-Manager/Containers/Services/Longcode";

export default function CombinedRoutes(props) {
  useEffect(() => { }, [window.location]);
  
  return (
    <Box sx={{ display: "flex" }}>
      {/* {window.location.pathname !== "/login-omni" &&
        // window.location.pathname !== "/" &&
        localStorage.getItem("roleName") !== "support" && (
          <SwipeableTemporaryDrawer />
        )} */}
      <BrowserRouter>
        <Routes>
          <Route element={<NavigationRouter />}>
            <Route
              key="/api-key-service"
              path="/api-key-service"
              element={<ApiKeyService />}
            />
            <Route path="/data-seed" key="/data-seed" element={<DataSeed />} />
            <Route
              path="/configuration-management"
              key="/configuration-management"
              element={<Configuration />}
            />
            <Route
              path="/routing"
              key="/routing"
              element={<RoutesServices />}
            />

            <Route
              path="/new-client"
              key="/new-client"
              element={<NewClient />}
            />
                <Route
              path="/shortcode"
              key="/shortcode"
              element={<Shortcode />}
            />
                  <Route
              path="/longcode"
              key="/longcode"
              element={<Longcode />}
            />
            <Route
              path="/client-management"
              key="/client-management"
              element={<Clients2 />}
            />

            <Route
              path="/clients-mg-dashboard"
              key="/clients-mg-dashboard"
              element={<ClientsDashboard />}
            />
            <Route
              path="/clients-management"
              key="/clients-management"
              element={<Clients2 />}
            />
            <Route
              path="/clients-config"
              key="/clients-config"
              element={<ClientsConfig />}
            />

            <Route
              path="/reasons-management"
              key="/reasons-management"
              element={<Reasons />}
            />

            <Route path="/users" key="/users" element={<Users />} />
            <Route
              path="/billing-configuration"
              key="/billing-configuration"
              element={<BillingConfig />}
            />
            <Route
              path="/billing-pricing"
              key="/billing-pricing"
              element={<BillingPricingServices />}
            />

            <Route
              path="/cost"
              key="/costs"
              exact
              element={<Costs selectedBtn="Costs" />}
            />
            <Route
              exact
              path="/rates"
              key="/rates"
              element={<Rates selectedBtn="Configured rates" />}
            />
            <Route
              path="/profit-loss"
              key="/profit-loss"
              element={<ProfitLoss selectedBtn="Profit Loss" isProfit={true} />}
            />

            <Route
              path="/resellers-config"
              key="/resellers-config"
              element={<ResellersConfig />}
            />
            <Route
              path="/providers-mg-dashboard"
              key="/providers-mg-dashboard"
              element={<ProvidersDashboard />}
            />
            <Route
              path="/providers-management"
              key="/providers-management"
              element={<Providers2 />}
            />
            {/* <Route
              path="/billing-service"
              key="/billing-service"
              element={<Billing />}
            /> */}
            <Route
              path="/catalog-management"
              key="/catalog-management"
              element={<Catalog />}
            />
            <Route
              path="/manage-tenant"
              key="/manage-tenant"
              element={<ManageTenant />}
            />
            <Route
              path="/channels-dashboard"
              key="/channels-dashboard"
              element={<ChannelsDashboard />}
            />
            <Route
              path="/users-management"
              key="/users-management"
              element={<MembersManagement />}
            />
            <Route
              path="/sms-one-way-management"
              key="/sms-one-way-management"
              element={<SMSOneWay />}
            />
            <Route
              path="/new-provider"
              key="/new-provider"
              element={<NewProvider />}
            />
            <Route
              path="/sms-two-way-management"
              key="/sms-two-way-management"
              element={<SMSTwoWay />}
            />
            <Route
              path="/resellers-mg-dashboard"
              key="/resellers-mg-dashboard"
              element={<ResellersDashboard />}
            />
            <Route
              path="/resellers-management"
              key="/resellers-management"
              element={<Resellers />}
            />
            <Route path="/url-store" key="/url-store" element={<UrlStore />} />
            <Route
              path="/url-store-config"
              key="/url-store-config"
              element={<URLStoreConfig />}
            />
            <Route path="/roles" key="/roles" element={<RolesPage />} />
            <Route
              path="/otp-store-config"
              key="/otp-store-config"
              element={<OTPStoreConfig />}
            />

            <Route
              path="/reseller-management"
              key="/reseller-management"
              element={<ManageResellers />}
            />
            <Route
              path="/add-reseller"
              key="/add-reseller"
              element={<AddReseller />}
            />
            <Route
              path="/reseller-config/:id"
              key="/reseller-config/:id"
              element={<EditResellerConfig />}
            />
            <Route
              path="/reseller-details/:id"
              key="/reseller-details/:id"
              element={<EditResellerDetails />}
            />

            <Route
              path="/routes-services"
              key="/routes-services"
              element={<RoutesServices />}
            />

            <Route
              path="/service-management"
              key="/service-management"
              element={<Services />}
            />

            <Route path="/pin" key="/pin" element={<Pin />} />
            <Route path="*" />
          </Route>
          {/* {(localStorage.getItem("typeOfLogin") !== null ||
            localStorage.getItem("typeOfLogin") !== "null") & (
            <Route element={<LayoutWithoutSideBar />}>
              <Route
                key="/clients-management"
                path="/clients-management"
                element={<Clients />}
              />
              <Route
                key="/providers-management"
                path="/providers-management"
                element={<Providers />}
              />
            </Route>
          )} */}
          <Route
            exact
            path="/"
            element={
              <Box
                component="main"
                className="main-box"
                sx={{ width: "100%", margin: "auto" }}
              >
                <SignIn />
              </Box>
            }
          />
          <Route
            exact
            path="/login-omni"
            element={
              <Box
                component="main"
                className="main-box"
                sx={{ width: "100%", margin: "auto" }}
              >
                <SignInOmni />
              </Box>
            }
          />
          <Route
            exact
            path="/login-keystore"
            element={
              <Box
                component="main"
                className="main-box"
                sx={{ width: "100%", margin: "auto" }}
              >
                <SignInKeyStore />
              </Box>
            }
          />
          <Route
            exact
            path="/landing-page"
            element={
              <Box
                component="main"
                className="main-box"
                sx={{ width: "100%", margin: "auto" }}
              >
                <LandingPage />
              </Box>
            }
          />
          <Route
            path="/not-found"
            key="/not-found"
            element={<NotHaveAccess />}
          />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}
