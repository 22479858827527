import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import { ADD_PREFIX, ADD_ZONE, MULTI_TENANCY_CONFIG } from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

const ManageLocation = ({
  open,
  setOpen,
  title,
  isEdit,
  setRefreshTableZone,
  setOpenAlert,
  setSearchZone,
  selectedZone,
  paginationModalZone,
  setPaginationModalZone,
}) => {
  let services = localStorage.getItem("services");

  const [name, setName] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [switchState, setSwitchState] = useState(false);

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Name: name,
      IsActive: switchState,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${ADD_ZONE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setName("");
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });
        setRefreshTableZone(true);
        setOpen(false);
        setPaginationModalZone({
          page: 0,
          pageSize: paginationModalZone?.pageSize
            ? paginationModalZone?.pageSize
            : 10,
        });
        // setOpenAlert(true);
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  useEffect(() => {
    {
      !isEdit && setName("");
      setSwitchState(true);
    }
  }, [open]);
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Name: name,
      IsActive: switchState,
      Tag: selectedZone?.tag,
      RecordGuid: selectedZone?.recordGuid,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${ADD_ZONE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setName("");
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });
        setRefreshTableZone(true);
        setOpen(false);
        setPaginationModalZone({
          page: 0,
          pageSize: paginationModalZone?.pageSize
            ? paginationModalZone?.pageSize
            : 10,
        });
        // setOpenAlert(true);
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitEdit();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (isEdit && selectedZone) {
      setName(selectedZone?.name);
      setSwitchState(selectedZone?.isActive);
    }
  }, [selectedZone, isEdit]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={isEdit ? submitEdit : submitAdd}
        disableFirstBtn={name === "" ? true : false}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>

            {/* <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm value={Tag} setValue={setTag}  options={TagOptions} placeholder={"Tag"}/>
            </Grid>
          </Grid> */}
            <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Status</span>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={switchState ? "Active " : "Inactive "}
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageLocation;
