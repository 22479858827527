import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import { Breadcrumbs, Button, Card, Dialog, DialogContent, FormControl, Grid, IconButton, TextField, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { SelectedResellerModal } from "./SelectedResellerModal";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Tooltip } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from "@mui/icons-material/Close";
import HorizantalTabs from "../../../Components/Tabs/ChannelTabs";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RouteIcon from '@mui/icons-material/Route';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DialogTitle from '@mui/material/DialogTitle';
import { borderRadius } from "@mui/system";

export default function ClientsDashboard() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState('2022-12-19');
  const [endDate, setEndDate] = useState('2023-12-19');
  const [newDate, setnewDate] = useState(false);

  const tabs = [
    {
      label: "By Traffic",
    },
    {
      label: "By Provider",
    },
    {
      label: "By Balance",
    },
  ];
  const optionsVip = {
    chart: {
      type: "pie",
    },
    title: {
      text: 'Types',
    },
    tooltip: {
      valueSuffix: "%",
    },
    credits: {
      enabled: false, // Set to false to hide the highcharts.com label
    },
    subtitle: {
      text: null,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 20,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: [
          {
            name: "Reseller",
            y: 27,
          },
          {
            name: "Business",
            sliced: true,
            selected: true,
            y: 73,
          },
        ],
      },
    ],
  };
  const optionsTraffiChannel = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Traffic By Channel",
    },
    tooltip: {
      valueSuffix: "%",
    },
    credits: {
      enabled: false, // Set to false to hide the highcharts.com label
    },
    subtitle: {
      text: null,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 10,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: [
          {
            name: "SMS",
            y: 2,
          },
          {
            name: "SMS Two Way",
            y: 5,
          },
          {
            name: "Push",
            y: 13,
          },
          {
            name: "Whatsapp",
            y: 11,
          },
          {
            name: "Viber",
            y: 30,
          },
          // {
          //   name: "Ash",
          //   y: 1.68,
          // },
        ],
      },
    ],
  };
  const optionsClientChannel ={
    chart: {
      type: "pie",
    },
    title: {
      text: "Clients By Channel",
    },
    tooltip: {
      valueSuffix: "%",
    },
    credits: {
      enabled: false, // Set to false to hide the highcharts.com label
    },
    subtitle: {
      text: null,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 10,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: [
          {
            name: "SMS",
            y: 7,
          },
          {
            name: "SMS Two Way",
            y: 5,
          },
          {
            name: "Push",
            y: 20,
          },
          {
            name: "Whatsapp",
            y: 11,
          },
          {
            name: "Viber",
            y: 30,
          },
          // {
          //   name: "Ash",
          //   y: 1.68,
          // },
        ],
      },
    ],
  };
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Categories",
    },
    tooltip: {
      valueSuffix: "%",
    },
    credits: {
      enabled: false, // Set to false to hide the highcharts.com label
    },
    subtitle: {
      text: null,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 10,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: [
          {
            name: "General",
            y: 55.02,
          },
          {
            name: "Bulk",
            sliced: true,
            selected: true,
            y: 26.71,
          },
          {
            name: "VIP",
            y: 1.09,
          },
          {
            name: "2way",
            y: 17.18,
          },
          // {
          //   name: "Ash",
          //   y: 1.68,
          // },
        ],
      },
    ],
  };
  const optionsColumns = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
      align: "left",
    },
    credits: {
      enabled: false, // Set to false to hide the highcharts.com label
    },
    subtitle: {
      text: null,
    },
    xAxis: {
      categories: ["USA", "China", "Brazil", "EU", "India", "Russia"],
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "1000 metric tons (MT)",
      },
    },
    tooltip: {
      valueSuffix: " (1000 MT)",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Corn",
        data: [406292, 260000, 107000, 68300, 27500, 14500],
      },
      {
        name: "Wheat",
        data: [51086, 136000, 5500, 141000, 107180, 77000],
      },
    ],
  };
  const optionsProgress = {
    chart: {
      type: "bar",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false, // Set to false to hide the highcharts.com label
    },
    subtitle: {
      text: null,
    },
    xAxis: {
      categories: ["Providers"],
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Channels",
      },
      gridLineWidth: 0, // Set gridLineWidth to 0 to remove grid lines
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: "{y}",
        },
      },
    },
    series: [
      {
        name: "SMS",
        data: [75], // Change this value to set the progress percentage
      },
      {
        name: "SMS Two Way",
        data: [50], // Change this value to set the progress percentage
      },
      {
        name: "Push",
        data: [31], // Change this value to set the progress percentage
      },
      {
        name: "Whatsapp",
        data: [25], // Change this value to set the progress percentage
      },
      {
        name: "Viber",
        data: [21], // Change this value to set the progress percentage
      },
    ],
  };
  const revenueByType = {
    chart: {
      height: 400, 
    },
  title: {
    text: false,
    useHTML: true,
    align: "left",
    style: {
      fontWeight: "bold",
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [
      "2023-09-11",
      "2023-09-12",
      "2023-09-13",
      "2023-09-14",
      "2023-09-15",
    ],
  },
  yAxis: {
    title: {
      text: 'Revenue',
    },
    tickInterval: 100,
    min: 0,
    max: 600,
  },
  series: newDate ?
  [
    {
      name: "SMS",
      data: [20, 120, 70, 200, 620],
    },
    {
      name: "MMP HLR",
      data: [100, 223, 155, 32, 76],
    },
    {
      name: "Push",
      data: [110, 153, 32, 11, 22],
    },
    {
      name: "MPP",
      data: [100, 132, 32, 232, 133],
    },
  ]
  : [
    {
      name: "SMS",
      data: [50, 290, 100, 300, 5620],
    },
    {
      name: "MMP HLR",
      data: [200, 123, 55, 132, 176],
    },
    {
      name: "Push",
      data: [210, 53, 232, 211, 422],
    },
    {
      name: "MPP",
      data: [1000, 332, 332, 2232, 3133],
    },
  ],
  };

  const costByType = {
    chart: {
      height: 400, 
    },
  title: {
    text: false,
    useHTML: true,
    align: "left",
    style: {
      fontWeight: "bold",
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [
      "2023-09-11",
      "2023-09-12",
      "2023-09-13",
      "2023-09-14",
      "2023-09-15",
    ],
  },
  yAxis: {
    title: {
      text: 'Revenue',
    },
    tickInterval: 100,
    min: 0,
    max: 600,
  },
  series: newDate ?
  [
    {
      name: "SMS",
      data: [120, 10, 170, 200, 420],
    },
    {
      name: "MMP HLR",
      data: [10, 23, 55, 132, 176],
    },
    {
      name: "Push",
      data: [10, 53, 132, 111, 122],
    },
    {
      name: "MPP",
      data: [10, 12, 232, 132, 233],
    },
  ]
  : [
    {
      name: "SMS",
      data: [100, 200, 300, 400, 500],
    },
    {
      name: "MMP HLR",
      data: [2, 123, 555, 32, 76],
    },
    {
      name: "Push",
      data: [21, 553, 32, 111, 222],
    },
    {
      name: "MPP",
      data: [10, 2332, 3232, 232, 333],
    },
  ],
  };

  const costByChannel = {
    chart: {
      height: 400, 
    },
  title: {
    text: false,
    useHTML: true,
    align: "left",
    style: {
      fontWeight: "bold",
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [
      "2023-09-11",
      "2023-09-12",
      "2023-09-13",
      "2023-09-14",
      "2023-09-15",
    ],
  },
  yAxis: {
    title: {
      text: 'Cost',
    },
    tickInterval: 100,
    min: 0,
    max: 600,
  },
  series: [
    {
      name: "Sms",
      data: [50, 180, 350, 100, 200],
    },
    {
      name: "Sms Two Way",
      data: [2, 12, 15, 122, 176],
    },
    {
      name: "Push",
      data: [210, 53, 132, 121, 222],
    },
    {
      name: "Whatsapp",
      data: [101, 1332, 2232, 1232, 3233],
    },
    {
      name: "Viber",
      data: [10, 122, 442, 50, 143],
    },
  ],
  };

  const CostRevenueType = {
    chart: {
      height: 400, 
    },
  title: {
    text: false,
    useHTML: true,
    align: "left",
    style: {
      fontWeight: "bold",
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [
      "2023-09-11",
      "2023-09-12",
      "2023-09-13",
      "2023-09-14",
      "2023-09-15",
    ],
  },
  yAxis: {
    title: {
      text: 'Type',
    },
    tickInterval: 100,
    min: 0,
    max: 600,
  },
  series: [
    {
      name: "Cost",
      data: [50, 180, 350, 100, 200],
    },
    {
      name: "Revenue",
      data: [2, 12, 15, 122, 176],
    },
  ],
  };

  const CostRevenueChannel = {
    chart: {
      height: 400, 
    },
  title: {
    text: false,
    useHTML: true,
    align: "left",
    style: {
      fontWeight: "bold",
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [
      "2023-09-11",
      "2023-09-12",
      "2023-09-13",
      "2023-09-14",
      "2023-09-15",
    ],
  },
  yAxis: {
    title: {
      text: 'Channel',
    },
    tickInterval: 100,
    min: 0,
    max: 600,
  },
  series: [
    {
      name: "Cost",
      data: [2, 12, 15, 122, 176],
    },
    {
      name: "Revenue",
      data: [50, 180, 350, 100, 200],
    },
  ],
  };
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([null, null]);
  const [activeType, setactiveType] = useState('By Traffic');

  useEffect(() => {
    console.log(activeType);
  })


  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (newTabLabel) => {
    // console.log("newTabLabel", newTabLabel);
    setactiveType(newTabLabel);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'startdate') {
      setStartDate(value);
    } else if (name === 'enddate') {
      setEndDate(value);
      setnewDate(true)
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">
              Clients
            </Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item xs={6} md={6}>
          <div style={{ display: "flex" }} className="end">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
              <DateField sx={{border:"1px solid #CA2A4B",borderRadius:"30px"}} label="Start Date" />
              <DateField sx={{border:"1px solid #CA2A4B",borderRadius:"30px"}}label="End Date" />

            </DemoContainer>
            </LocalizationProvider>
          </div>
        </Grid> */}
      </Grid>

<Grid className="end" container spacing={3}>
        <Grid item>
          <TextField
            key={"startdate"}
            id={"startdate"}
            name={"startdate"}
            variant="outlined"
            value={startDate}
            onChange={handleChange}
            // label="Start Date"
            // variant="date-picker"
            // sx={{
            //   border:'1px solid black',
            //   padding: '3px',
            //   borderRadius: '28px',
            // }}
            type={"date"}
            InputLabelProps={{ shrink: true }}
          /></Grid>
        <Grid item>
          <TextField
            key={"enddate"}
            id={"enddate"}
            name={"enddate"}
            variant="outlined"
            value={endDate}
            onChange={handleChange}
            // label="End Date"
            // sx={{
            //   border:'1px solid black',
            //   padding: '3px',
            //   borderRadius: '28px',
            // }}
            type={"date"}
            InputLabelProps={{ shrink: true }}
          /></Grid>
      </Grid>
      <Card className="kpi-card">
        <Grid container paddingTop={2} spacing={3}>
          {/* <Grid item xl={4} md={4} xs={12} className="col-border">
            <Grid container spacing={1}>
              <Typography className="kpi-title">
                Resellers
              </Typography>
            </Grid>

            <Grid container spacing={1}>
              <Typography
                className="kpi-data"
              >
                221 <ArrowUpwardIcon />
              </Typography>
            </Grid>
          </Grid> */}

          <Grid item xl={6} md={6} xs={12} className="col-border">
            <Grid container spacing={1}>
              <Typography className="kpi-title">
                Clients
              </Typography>
            </Grid>

            <Grid container spacing={1}>
              <Typography
                className="kpi-data"
              >
                {newDate ? '62' : '113'} &nbsp;<RemoveRedEyeIcon className="eye-icon" onClick={() => {navigate("/clients-management");localStorage.setItem("selectedTab","clients-management")}} />
              </Typography>
            </Grid>
          </Grid>

          <Grid item xl={6} md={6} xs={12}>
            <Grid container spacing={1}>
              <Typography className="kpi-title">
              Clients W/O Account
              </Typography>
            </Grid>

            <Grid container spacing={1}>
              <Typography
                className="kpi-data"
              >
                {newDate ? '88' : '181'} &nbsp;<RemoveRedEyeIcon className="eye-icon" onClick={() => {navigate("/clients-management");localStorage.setItem("selectedTab","clients-management")}}/>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>


      <Grid container spacing={2}>
      <Grid item xl={6} xs={12}>
            <Card className="top-5-card">
            <Grid className="card-dashboard" item lg={12} xs={12}>
            {/* <Card
            style={{ padding: "20px", borderRadius: "8px", marginTop: "20px" }}
          > */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Revenue By Type
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={revenueByType}
            />
            {/* </Card> */}
          </Grid>
            </Card>
      </Grid>
      <Grid item xl={6} xs={12}>
            <Card className="top-5-card">
            <Grid className="card-dashboard" item lg={12} xs={12}>
            {/* <Card
            style={{ padding: "20px", borderRadius: "8px", marginTop: "20px" }}
          > */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Cost By Type
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={costByType}
            />
            {/* </Card> */}
          </Grid>
            </Card>
      </Grid>

      <Grid item xl={12} xs={12}>
            <Card className="top-5-card">
            <Grid className="card-dashboard" item lg={12} xs={12}>
            {/* <Card
            style={{ padding: "20px", borderRadius: "8px", marginTop: "20px" }}
          > */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Cost Vs. Revenue By Type
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={CostRevenueType}
            />
            {/* </Card> */}
          </Grid>
            </Card>
      </Grid>

      <Grid item xl={6} xs={12}>
            <Card className="top-5-card">
            <Grid className="card-dashboard" item lg={12} xs={12}>
            {/* <Card
            style={{ padding: "20px", borderRadius: "8px", marginTop: "20px" }}
          > */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Cost By Channel
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={costByChannel}
            />
            {/* </Card> */}
          </Grid>
            </Card>
      </Grid>
      <Grid item xl={6} xs={12}>
            <Card className="top-5-card">
            <Grid className="card-dashboard" item lg={12} xs={12}>
            {/* <Card
            style={{ padding: "20px", borderRadius: "8px", marginTop: "20px" }}
          > */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Revenue By Channel
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={costByChannel}
            />
            {/* </Card> */}
          </Grid>
            </Card>
      </Grid>

      <Grid item xl={12} xs={12}>
            <Card className="top-5-card">
            <Grid className="card-dashboard" item lg={12} xs={12}>
            {/* <Card
            style={{ padding: "20px", borderRadius: "8px", marginTop: "20px" }}
          > */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Cost Vs. Revenue By Channel
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={CostRevenueChannel}
            />
            {/* </Card> */}
          </Grid>
            </Card>
      </Grid>

      <Grid item xl={12} xs={12}>
            <Card className="top-5-card">
            <Grid className="card-dashboard" item lg={12} xs={12}>
            {/* <Card
            style={{ padding: "20px", borderRadius: "8px", marginTop: "20px" }}
          > */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Top 5 Clients Per Channels
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsProgress}
            />
            {/* </Card> */}
          </Grid>
            </Card>
      </Grid>
      </Grid>
      
      <br />
      <Card className="main-card">
      <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
        <Grid container spacing={0} marginTop={4}>
        <Grid className="card-dashboard" item lg={3} xs={12}>
            {/* <Card style={{ padding: "20px", borderRadius: "8px" }}> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"
              >
                Client By Channel
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div> */}

            <HighchartsReact highcharts={Highcharts} options={optionsClientChannel} />
            {/* </Card> */}
          </Grid>
          <Grid className="card-dashboard" item lg={3} xs={12}>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Categories
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div> */}

            <HighchartsReact highcharts={Highcharts} options={options} />
          </Grid>
          <Grid className="card-dashboard" item lg={3} xs={12}>
            {/* <Card style={{ padding: "20px", borderRadius: "8px" }}> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Types
              </Typography>
              <div className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                  // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </div>
            </div> */}

            <HighchartsReact highcharts={Highcharts} options={optionsVip} />
            {/* </Card> */}
          </Grid>

          <Grid className="card-dashboard" item lg={3} xs={12}>
            {/* <Card style={{ padding: "20px", borderRadius: "8px" }}> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="charts-title"

              >
                Traffic By Channel
              </Typography>
             
            </div> */}

            <HighchartsReact highcharts={Highcharts} options={optionsTraffiChannel} />
            {/* </Card> */}
          </Grid>
        </Grid>
      </Card>
      {open && (
        <Dialog
          open={open}
          setOpen={setOpen}
          onClose={handleClose}
          overflowHidden
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        className="dashboard-dialog"
        >
          <DialogTitle className="center">{"QGlobal Resellers"}</DialogTitle>
          <DialogContent>
            {activeType == 'By Balance' ?
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card className="reseller-card">
                    <Grid container className="center">

                      <Grid item xs={3}>
                        <CurrencyExchangeIcon className="reseller-card-icon" />
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container>
                          <Typography className="reseller-card-name">Touch </Typography>&nbsp;
                          <Typography className="service-card-name"> (Omni) </Typography>
                        </Grid>
                        <Typography className="revenue">200$ </Typography>&nbsp;
                      </Grid>

                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className="reseller-card">
                    <Grid container className="center">

                      <Grid item xs={3}>
                        <CurrencyExchangeIcon className="reseller-card-icon" />
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container>
                          <Typography className="reseller-card-name">Alfa </Typography>&nbsp;
                          <Typography className="service-card-name"> (Push) </Typography>
                        </Grid>
                        <Typography className="revenue">450$ </Typography>&nbsp;
                      </Grid>

                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className="reseller-card">
                    <Grid container className="center">

                      <Grid item xs={3}>
                        <CurrencyExchangeIcon className="reseller-card-icon" />
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container>
                          <Typography className="reseller-card-name">U-share </Typography>&nbsp;
                          <Typography className="service-card-name"> (Omni) </Typography>
                        </Grid>
                        <Typography className="revenue">300$ </Typography>&nbsp;
                      </Grid>

                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              :
              activeType == 'By Traffic' ?
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Card className="reseller-card">
                      <Grid container className="center">

                        <Grid item xs={3}>
                          <RouteIcon className="reseller-card-icon" />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Typography className="reseller-card-name">SMS </Typography>&nbsp;
                            <Typography className="service-card-name"> (Omni) </Typography>
                          </Grid>
                          <Typography className="revenue">47 </Typography>&nbsp;
                        </Grid>

                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className="reseller-card">
                      <Grid container className="center">

                        <Grid item xs={3}>
                          <RouteIcon className="reseller-card-icon" />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Typography className="reseller-card-name">SMS </Typography>&nbsp;
                            <Typography className="service-card-name"> (Push) </Typography>
                          </Grid>
                          <Typography className="revenue">450 </Typography>&nbsp;
                        </Grid>

                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className="reseller-card">
                      <Grid container className="center">

                        <Grid item xs={3}>
                          <RouteIcon className="reseller-card-icon" />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Typography className="reseller-card-name">U-share </Typography>&nbsp;
                            <Typography className="service-card-name"> (Omni) </Typography>
                          </Grid>
                          <Typography className="revenue">30 </Typography>&nbsp;
                        </Grid>

                      </Grid>
                    </Card>
                  </Grid>
                </Grid>


                :
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Card className="reseller-card">
                      <Grid container className="center">

                        <Grid item xs={3}>
                          <PersonOutlineIcon className="reseller-card-icon-client" />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Typography className="reseller-card-name">Touch </Typography>&nbsp;
                            <Typography className="service-card-name"> (Omni) </Typography>
                          </Grid>
                          {/* <Typography className="revenue">200$ </Typography>&nbsp; */}
                        </Grid>

                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className="reseller-card">
                      <Grid container className="center">

                        <Grid item xs={3}>
                          <PersonOutlineIcon className="reseller-card-icon-client" />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Typography className="reseller-card-name">Alfa </Typography>&nbsp;
                            <Typography className="service-card-name"> (Push) </Typography>
                          </Grid>
                          {/* <Typography className="revenue">450$ </Typography>&nbsp; */}
                        </Grid>

                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className="reseller-card">
                      <Grid container className="center">

                        <Grid item xs={3}>
                          <PersonOutlineIcon className="reseller-card-icon-client" />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Typography className="reseller-card-name">U-share </Typography>&nbsp;
                            <Typography className="service-card-name"> (Omni) </Typography>
                          </Grid>
                          {/* <Typography className="revenue">300$ </Typography>&nbsp; */}
                        </Grid>

                      </Grid>
                    </Card>
                  </Grid>
                </Grid>

            }
            {/* <Grid container className="close-btn">
           <CloseIcon onClick={handleClose}/>
           </Grid> */}
            {/* <Grid container className="center" spacing={3}>
             <Grid item xs={6} fontWeight={'bold'}>
               Name
             </Grid>
             <Grid item xs={6} fontWeight={'bold'}>
               Revenue
             </Grid>
             <Grid item xs={6}>
               Omni
             </Grid>
             <Grid item xs={6}>
             <Typography className="revenue">
               545
               </Typography>
             </Grid>
             <Grid item xs={6}>
               Push
             </Grid>
             <Grid item xs={6}>
               <Typography className="revenue">
               53
               </Typography>
             </Grid>
             <Grid item xs={6}>
               SMS
             </Grid>
             <Grid item xs={6}>
               <Typography className="revenue">
               93
               </Typography>
             </Grid>
           </Grid> */}
            {/* <Table
               rowCountState={tableDataGroup?.totalCount}
               data={tableDataGroup?.data}
               isLoading={isLoadingGroup}
               columns={columnsGroups}
               pageIndex={
                 tableDataGroup?.pageIndex ? tableDataGroup?.pageIndex : 0
               }
               setPaginationModel={setPaginationModelGroup}
               paginationModel={paginationModelGroup}
               addBtnName={"Group"}
               setSearch={setSearchGroup}
               search={searchGroup}
               disableActions
             /> */}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
