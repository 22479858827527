import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  
  const ManagePin = ({ open, setOpen, title, onButtonClick }) => {
    const [Clients, setClients] =useState("");
    const [ClientsOptions, setClientsOptions] = useState(["Seconds","Minuts","Hours"]);
    const [KeyStoreStatus, setKeyStoreStatus] =useState("");
    const [KeyStoreStatusOptions, setKeyStoreStatusOptions] = useState(["Active","Pending"]);
    const [ExternalID, setExternalID] = useState("");
    const [MaximumLength, setMaximumLength] = useState("");
    const [KeyLength, setKeyLength] = useState("");
    const [TimeToLive, setTimeToLive] = useState("");
    return ( 
      
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
             <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm value={Clients} setValue={setClients}  options={ClientsOptions} placeholder={"Clients"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm value={KeyStoreStatus} setValue={setKeyStoreStatus}  options={KeyStoreStatusOptions} placeholder={"Key Store Status"}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"External ID"}
                  value={ExternalID}
                  setValue={setExternalID}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid className="dialog-grid-spacing" item xs={12} sm={6}>
            <TextFieldForm
                  placeholder={"Time To Live"}
                  value={TimeToLive}
                  setValue={setTimeToLive}
                />
              </Grid>
              <Grid className="dialog-grid-spacing" item xs={12} sm={6}>
                <SelectForm value={Clients} setValue={setClients}  options={ClientsOptions} placeholder={"Time"}/>
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default ManagePin;
  