import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, Breadcrumbs, Grid } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";

const steps = [
  {
    label: "Acoount Information",
  },
  {
    label: "Account Type",
  },
  {
    label: "Engagement",
  },
];

export default function AddReseller({ handleBackToTable, title }) {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [clientName, setClientName] = useState("");
  const [emailSender, setEmailSender] = useState("");
  const [username, setUsername] = useState("");

  const [industries, setIndustries] = useState("");
  const [industriesOptions, setIndustriesOptions] = useState([
    { label: "Hospitality", value: "Hospitality" },
    { label: "Retails", value: "Retails" },
    { label: "Telecome", value: "Telecome" },
  ]);
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [assignCategory, setAssignCategory] = useState("");
  const [assignCategoryOptions, setAssignCategoryOptions] = useState([
    { label: "Category 1", value: "Category 1" },
    { label: "Category 2", value: "Category 2" },
    ,
  ]);
  const [assignAccount, setAssignAccount] = useState("");
  const [assignAccountOptions, setAssignAccountOptions] = useState([
    { label: "Account 1", value: "Account 1" },
    { label: "Account 2", value: "Account 2" },
    ,
  ]);
  const [transitCost, setTransitCost] = useState("");
  const [alertEmail, setAlertEmail] = useState("");

  const [selectAccountType, setSelectAccountType] = useState("");
  const [selectAccountTypeOptions, setSelectAccountTypeOptions] = useState([
    { label: "Prepaid 1", value: "Prepaid 1" },
    { label: "PostPaid 2", value: "PostPaid 2" },
  ]);
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [connectivityType, setConnectivityType] = useState("");
  const [connectivityTypeOptions, setConnectivityTypeOptions] = useState([
    { label: "HTTP", value: "HTTP" },
    { label: "List", value: "List" },
  ]);

  const [serviceLookupMode, setServiceLookupMode] = useState("");
  const [serviceLookupModeOptions, setServiceLookupModeOptions] = useState([
    { label: "Request Per SMS", value: "Request Per SMS" },
    { label: "Database Update", value: "Database Update" },
  ]);

  const [numberOfThreads, setNumberOfThreads] = useState("");
  const [ip, setIp] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [queryDns, setQueryDns] = useState("");
  const [technicalEmail, setTechnicalEmail] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const [countryOptions, setCountryOptions] = useState([
    { label: "Country 1", value: "Country 1" },
    { label: "Country 2", value: "Country 2" },
  ]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const circleStyle = {
    width: 35, // Set the desired width
    height: 35, // Set the desired height
    borderRadius: "50%", // Ensures a circular shape
    fontSize: "0.95rem!important",
  };
  return (
    <>
      <Grid
        style={{ marginBottom: "50px" }}
        item
        xs={12}
        md={6}
        className="centerresponsive"
      >
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Typography
            style={{ cursor: "pointer" }}
            onClick={handleBackToTable}
            className="BreadcrumbsPage"
          >
            Resellers
          </Typography>
          <Typography className="breadcrumbactiveBtn">{title}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container>
        <Grid item xs={5} lg={2}>
          <Box sx={{ maxWidth: 400, display: "flex" }}>
            <Stepper
              sx={{
                width: "30px",
                height: "30px",
                "& .MuiStepConnector-line": {
                  height: "70px",
                  margin: "0px 0px 0px 5px",
                },
                "& .MuiStepIcon-root.Mui-completed ": {
                  color: "#c41035",
                },
                "& .MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
                  color: "#c41035",
                },
              }}
              activeStep={activeStep}
              orientation="vertical"
            >
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    StepIconProps={{
                      style: circleStyle,
                    }}
                    // optional={
                    //   index === 2 ? (
                    //     <Typography variant="caption">Last step</Typography>
                    //   ) : null
                    // }
                    style={{ width: "500px" }}
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    {/* <Typography>{step.description}</Typography> */}
                    {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                </div>
              </Box> */}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
        {activeStep === 0 && (
          <Grid
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "25px",
            }}
            item
            lg={10}
            xs={7}
          >
            <Grid style={{ marginBottom: "10px" }} container spacing={3}>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Client Name"}
                  value={clientName}
                  setValue={setClientName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Email Sender"}
                  value={emailSender}
                  setValue={setEmailSender}
                />
              </Grid>{" "}
            </Grid>
            <Grid style={{ marginBottom: "10px" }} container spacing={3}>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Username"}
                  value={username}
                  setValue={setUsername}
                />
              </Grid>
              <Grid item xs={6}>
                {/* <SelectFormNew
                  placeholder={"Industries"}
                  value={industries}
                  setValue={setIndustries}
                  options={industriesOptions}
                /> */}
              </Grid>{" "}
            </Grid>
            <Grid style={{ marginBottom: "10px" }} container spacing={3}>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Business Website"}
                  value={businessWebsite}
                  setValue={setBusinessWebsite}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Company Phone"}
                  value={companyPhone}
                  setValue={setCompanyPhone}
                />
              </Grid>{" "}
            </Grid>

            <Grid style={{ marginBottom: "10px" }} container spacing={3}>
              <Grid item xs={6}>
                {/* <SelectFormNew
                  placeholder={"Assign Category"}
                  value={assignCategory}
                  setValue={setAssignCategory}
                  options={assignCategoryOptions}
                /> */}
              </Grid>
              <Grid item xs={6}>
                {/* <SelectFormNew
                  placeholder={"Assign account manager"}
                  value={assignAccount}
                  setValue={setAssignAccount}
                  options={assignAccountOptions}
                /> */}
              </Grid>{" "}
            </Grid>
            <Grid style={{ marginTop: "20px" }} container>
              <Grid item xs={12}>
                <Accordion
                  sx={{
                    boxShadow: "none",
                    border: "1px solid #E0E0E0",
                    borderRadius: "20px",
                    "&.MuiPaper-root-MuiAccordion-root": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      backgroundColor: "#e8e8e8",
                      margin: "0px",
                      "& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
                        {
                          minHeight: "auto",
                        },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "0px!important",
                      },
                    }}
                  >
                    <Typography>Address Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "20px" }}>
                    <Grid
                      style={{ marginBottom: "10px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        {/* <SelectFormNew
                          placeholder={"Select Country"}
                          value={selectCountry}
                          setValue={setSelectCountry}
                          options={countryOptions}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid
                      style={{ marginBottom: "10px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Street"}
                          value={street}
                          setValue={setStreet}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"City"}
                          value={city}
                          setValue={setCity}
                        />
                      </Grid>{" "}
                    </Grid>
                    <Grid
                      style={{ marginBottom: "10px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Region"}
                          value={region}
                          setValue={setRegion}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"State"}
                          value={state}
                          setValue={setState}
                        />
                      </Grid>{" "}
                    </Grid>
                    <Grid
                      style={{ marginBottom: "10px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Zip"}
                          value={zip}
                          setValue={setZip}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Building"}
                          value={building}
                          setValue={setBuilding}
                        />
                      </Grid>{" "}
                    </Grid>
                    <Grid
                      style={{ marginBottom: "10px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Floor"}
                          value={floor}
                          setValue={setFloor}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Room Number"}
                          value={roomNumber}
                          setValue={setRoomNumber}
                        />
                      </Grid>{" "}
                    </Grid>
                    <Grid
                      style={{ marginBottom: "10px" }}
                      container
                      spacing={3}
                    >
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Address Line 1"}
                          value={addressLine1}
                          setValue={setAddressLine1}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldForm
                          placeholder={"Address Line 2"}
                          value={addressLine2}
                          setValue={setAddressLine2}
                        />
                      </Grid>{" "}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>

            <Grid className="end" style={{ marginTop: "20px" }} container>
              <Grid item={6} className="end"></Grid>
              <Grid className="end" item={6}>
                <Button
                  className="contained"
                  onClick={handleNext}
                  // sx={{ mt: 1, mr: 1 }}
                >
                  {steps.length === 2 ? "Finish" : "Continue"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {activeStep === 1 && (
          <Grid
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "25px",
            }}
            item
            lg={10}
            xs={7}
          >
            <Grid style={{ marginBottom: "10px" }} container spacing={3}>
              <Grid item xs={6}>
                {/* <SelectFormNew
                  placeholder={"Select account type"}
                  value={selectAccountType}
                  setValue={setSelectAccountType}
                  options={selectAccountTypeOptions}
                /> */}
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", justifyContent: "space-between" }}
              container
            >
              <Grid item={6}>
                <Button
                  disabled={steps.length === 0}
                  onClick={handleBack}
                  className="outline"

                  // sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item={6}>
                <Button
                  className="contained"
                  onClick={handleNext}
                  // sx={{ mt: 1, mr: 1 }}
                >
                  {steps.length === 2 ? "Finish" : "Continue"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {activeStep === 2 && (
          <Grid
            style={{
              background: "white",
              padding: "20px 20px 20px 20px",
              borderRadius: "25px",
            }}
            item
            lg={10}
            xs={7}
          >
            <Grid style={{ marginBottom: "10px" }} container spacing={3}>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Technical Email"}
                  value={technicalEmail}
                  setValue={setTechnicalEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Billing Email"}
                  value={billingEmail}
                  setValue={setBillingEmail}
                />
              </Grid>{" "}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextFieldForm
                  placeholder={"Alert Email"}
                  value={alertEmail}
                  setValue={setAlertEmail}
                />
              </Grid>
            </Grid>

            <Grid
              style={{ marginTop: "50px", justifyContent: "space-between" }}
              container
            >
              <Grid item={6}>
                <Button
                  disabled={steps.length === 0}
                  onClick={handleBack}
                  className="outline"

                  // sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item={6}>
                <Button
                  className="contained"
                  onClick={handleBackToTable} // sx={{ mt: 1, mr: 1 }}
                >
                  {"Finish"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
