import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import PasswordInput from "../../../Components/TextFields/PasswordInput";
import { withTranslation } from "react-i18next";
import axiosInstance from "../../../axiosInstance";
import {
  ASSIGN_ROLE,
  GET_ALL_CONTACT_TYPE,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import CloseIcon from "@mui/icons-material/Close";

import { ADD_USER, GET_POLICIES } from "../../../Authentication/Api";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
const ManageUser = ({
  open,
  setOpen,
  title,
  onButtonClick,
  isEdit,
  clientCategoryOptionsSearch,
  countryOptionsSearch,
  providerOptionsSearch,
  countryOptionsRes,
  setPaginationModel,
  setOpenAlert,
  setRefreshTable,
  setSmsOneWaySearch,
  selectedSms,
  paginationModel,
  t,
}) => {
  const [Role, setRole] = useState("");
  const [RoleOptions, setRoleOptions] = useState(false);
  const [ContactType, setContactType] = useState("");
  const [ContactTypeOptions, setContactTypeOptions] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Username, setUsername] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  useEffect(() => {
    if (isEdit && selectedSms) {
      setRole("");
      setFirstName("");
      setLastName("");
      setPassword("");
      setConfirmPassword("");
      setUsername("");
      setEmail("");
      setContactType("");

      // setRole(selectedSms?.policy?.id);
      // setFirstName(selectedSms?.firstName);
      // setLastName(selectedSms?.lastName);
      // setUsername(selectedSms?.username);
      // setEmail(selectedSms?.email);
    }
  }, [selectedSms, isEdit, open]);

  useEffect(() => {
    setRole("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setConfirmPassword("");
    setUsername("");
    setEmail("");
    setContactType("");
  }, []);
  useEffect(() => {
    setRole("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setConfirmPassword("");
    setUsername("");
    setEmail("");
    setContactType("");
  }, [isEdit]);
  {
    console.log("selectedSmsselectedSms", selectedSms);
  }
  useEffect(() => {
    // setOpenMessageAlert(true);
  }, [messageData]);
  useEffect(() => {
    setRole("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setConfirmPassword("");
    setUsername("");
    setEmail("");
    setContactType("");

    if (!selectedSms) {
      setRole("");
      setFirstName("");
      setLastName("");
      setPassword("");
      setConfirmPassword("");
      setUsername("");
      setEmail("");
      setContactType("");
    }
    {
      console.log("selectedSmsselectedSmsselectedSmsaa", selectedSms);
    }
    // {
    //   isEdit &&
    //   setRole(selectedSms?.policy?.id);
    //   setFirstName(selectedSms?.firstName);
    //   setLastName(selectedSms?.lastName);
    //   setUsername(selectedSms?.username);
    //   setEmail(selectedSms?.email);
    // }
  }, [open, isEdit, selectedSms]);

  useEffect(() => {
    if (
      (isEdit && selectedSms === undefined) ||
      (selectedSms !== undefined && Object.keys(selectedSms).length === 0)
    ) {
      setRole("");
      setFirstName("");
      setLastName("");
      setPassword("");
      setConfirmPassword("");
      setUsername("");
      setEmail("");
      setContactType("");
    } else {
      isEdit && setRole(selectedSms?.policy?.id);
      setFirstName(selectedSms?.firstName);
      setLastName(selectedSms?.lastName);
      setUsername(selectedSms?.username);
      setEmail(selectedSms?.email);
    }
  }, [isEdit, selectedSms]);
  useEffect(() => {
    setRoleOptions(false);
    getAllRoles();
    setRoleOptions(false);

    getAllContactTypes();
  }, []);

  const getAllRoles = () => {
    setRoleOptions(false);
    let newServices = JSON.parse(services);

    setRole("");

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_POLICIES}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setRoleOptions(
          res?.data?.data?.policies.map((x) => ({
            label: x?.name,
            value: x?.id,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllRoles();
        }
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };

  const getAllContactTypes = () => {
    setContactTypeOptions(false);
    let newServices = JSON.parse(services);
    setContactType("");

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let data = {
      CATEGORYTAGS: ["CONTACT_TYPE"],
      SearchKeyword: "",
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CONTACT_TYPE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setContactTypeOptions(
          res?.data?.data?.criteria.map((x) => ({
            label: x?.name,
            value: x?.tag,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllContactTypes();
        }
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const assignRole = (data) => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let body = {
      ExternalUserId: data,
      PolicyId: Role,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${ASSIGN_ROLE}`,
        body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: "Successfully Assigned role",
          type: "success",
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          assignRole();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      })
      .finally(() => {});
  };
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Username: Username,
      Password: Password,
      ProfileUrl: "sfdfd",
      Contacts: [
        {
          MobileNumber: "965487521",
          Email: Email,
          ContactType: ContactType,
          FirstName: FirstName,
          LastName: LastName,
          isActive: false,
        },
      ],
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${ADD_USER}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: data?.message || "Successfully Added",
          type: "success",
        });
        assignRole(res?.data?.data?.user?.externalUserId);
        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        setRole("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setConfirmPassword("");
        setUsername("");
        setEmail("");
        setContactType("");
      })
      .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.Exception
              ? err?.response?.data?.Exception
              : err?.response?.data?.message,
            type: "error",
          });
          setOpenMessageAlert(true);
        }
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      RecordGuid: selectedSms?.recordGuid,
      FirstName: FirstName,
      LastName: LastName,
      PolicyId: Role,
      Config: [],
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${ADD_USER}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });

        setPaginationModel({
          page: 0,
          pageSize: paginationModel?.pageSize ? paginationModel?.pageSize : 10,
        });
        setOpen(false);
        setRole("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setConfirmPassword("");
        setUsername("");
        setEmail("");
        setContactType("");
      })
      .catch((error) => {
        console.log("ASFasfasfasfas", error?.response?.data?.message);
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.message,
          type: "error",
        });
      });
  };
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={t("Save")}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={
          !isEdit
            ? FirstName === "" ||
              LastName === "" ||
              Username === "" ||
              Email === "" ||
              Password === "" ||
              ConfirmPassword === "" ||
              Role === "" ||
              ContactType === ""
            : FirstName === "" || LastName === "" || Role === ""
        }
        content={
          <>
            <Grid container>
              {console.log("FirstNameFirstName", FirstName)}

              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={t("First Name")}
                  value={FirstName}
                  setValue={setFirstName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={t("Last Name")}
                  value={LastName}
                  setValue={setLastName}
                />
              </Grid>
            </Grid>
            {!isEdit && (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={t("Username")}
                      value={Username}
                      setValue={setUsername}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={t("Email")}
                      value={Email}
                      setValue={setEmail}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <PasswordInput
                      placeholder={t("Password")}
                      value={Password}
                      setValue={setPassword}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <PasswordInput
                      placeholder={t("Confirm Password")}
                      value={ConfirmPassword}
                      setValue={setConfirmPassword}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectFormNew
                  value={Role}
                  setValue={setRole}
                  options={RoleOptions}
                  placeholder={t("Role")}
                  disabled={RoleOptions?.length === 0}
                />
              </Grid>
            </Grid>
            {!isEdit && (
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={ContactType}
                    setValue={setContactType}
                    options={ContactTypeOptions}
                    placeholder={t("Contact Type")}
                    disabled={ContactTypeOptions?.length === 0}
                  />
                </Grid>
              </Grid>
            )}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default withTranslation("translations")(ManageUser);
