import React, { createContext, useContext, useState } from 'react';
 
// Create the ThemeContext
const ThemeContext = createContext();
 
// Custom hook to use the theme context
export const useTheme = () => useContext(ThemeContext);
 
// ThemeProvider component to wrap your app with the context provider
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    mode: 'light',
    direction: 'ltr',
    primary: localStorage.getItem('primary')

  }); // Initial theme state
 
 
 
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};