import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import HorizantalTabs from "../../../Components/Tabs/ChannelTabs";
import {
  GET_ALL_TAX_CATEGORY,
  GET_ALL_TAX_TYPE,
  MANAGE_CATEGORY,
  MANAGE_TAXES,
  MULTI_TENANCY_CONFIG,
  UPDATE_TAXES,
} from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";

const ManageCategory = ({
  open,
  setOpen,
  title,
  onButtonClick,
  taxesTabs,
  langRes,
  isEdit,
  setPaginationModalCategory,
  paginationModalCategory,
  countryOptions,
  selectedCategory,
  language,
}) => {
  const [activeBtnCategoryTab, setActiveBtnCategoryTab] = useState("");
  const [langData, setLangData] = useState({});
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [taxType, setTaxType] = useState("");
  const [taxTypeOptions, setTaxTypeOptions] = useState([]);
  const [switchState, setSwitchState] = useState(true);
  const [Country, setCountry] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rate, setRate] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [objectData, setOjectData] = useState({
    Details: [{ Name: "", Description: "" }],
  });

  const [taxCategory, setTaxCategory] = useState("");
  const [taxCategoryOptions, setTaxCategoryOptions] = useState([]);
  const handleStartDateChange = (value) => {
    if (endDate && endDate < value) {
      setStartDate(value);
    } else {
      setStartDate(value);

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  const handleEndDateChange = (value) => {
    if (startDate < value) {
      setEndDate(value);
    } else {
      setEndDate("");

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  const submitAdd = () => {
    const outputObject = {
      Details: Object.keys(langData).map((language) => {
        let languageCode;
        switch (language) {
          case "English":
            languageCode = "en";
            break;
          case "Arabic":
            languageCode = "ar";
            break;
          case "French":
            languageCode = "fr";
            break;
          default:
            languageCode = "";
        }

        return {
          LanguageCode: languageCode,
          Name: langData[language].name,
          Description: langData[language].description,
        };
      }),
    };
    setOjectData(outputObject);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
    //   CountryCode: Country,
    //   TaxTypeGuid: taxType,
    //   Rate: rate,
    //   IsForDisplayOnly: switchState,
    //   FromDate: startDate,
    //   ToDate: endDate,
      ClientCategoryDetails: outputObject?.Details?.filter((item) => item.Name !== ""),
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["member"]
        }${MANAGE_CATEGORY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: data?.message ||"Successfully Added", type: "success" });

        setPaginationModalCategory({
          page: 0,
          pageSize: paginationModalCategory?.pageSize
            ? paginationModalCategory?.pageSize
            : 10,
        });
        setOpen(false);
        setCountry("");
        setRate("");
        setTaxType("");
        setTaxCategory("");
        setStartDate("");
        setEndDate("");
        setSwitchState(false);
      })
      .catch((error) => {
        console.log("ASFasfasfasfas", error?.response?.data?.message);
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
            ? error?.response?.data?.Exception
            : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    {
      console.log("asdasdsadas language", language);
    }
    let data = {
      RecordGuid: selectedCategory?.recordGuid,
      // TaxTypeGuid: taxType,
      Details: [
        { Name: name, Description: description, LanguageCode: language },
      ],
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${UPDATE_TAXES}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });
        setPaginationModalCategory({
          page: 0,
          pageSize: paginationModalCategory?.pageSize
            ? paginationModalCategory?.pageSize
            : 10,
        });
        setOpen(false);
        setCountry("");
        setRate("");
        setTaxType("");
        setTaxCategory("");
        setStartDate("");
        setEndDate("");
        setSwitchState(false);
        // setSmsOneWaySearch("");
      })
      .catch((error) => {
        console.log("ASFasfasfasfas", error);
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
            ? error?.response?.data?.Exception
            : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const getAllTaxCategory = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["billing"]
        }${GET_ALL_TAX_CATEGORY}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTaxCategoryOptions(
          res?.data?.data?.categories.map((x) => ({
            label: x?.name,
            value: x?.categoryId,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllTaxCategory();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllTaxType = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["billing"]
        }${GET_ALL_TAX_TYPE}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTaxTypeOptions(
          res?.data?.data?.taxTypes.map((x) => ({
            label: x?.name,
            value: x?.typeId,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllTaxType();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  useEffect(() => {
    setActiveBtnCategoryTab((langRes && langRes[0]?.label) || ""); // Set the active tab to the first label in langRes
    {
      !isEdit && setCountry("");
      setRate("");
      setTaxType("");
      setTaxCategory("");
      setStartDate("");
      setEndDate("");
      setSwitchState(false);
    }
    const initialLangData = {};
    langRes?.forEach((lang) => {
      initialLangData[lang.label] = { name: "", description: "" };
    });
    {
      !isEdit && setLangData(initialLangData);
    }
    getAllTaxCategory();
    getAllTaxType();
  }, [langRes, open]);
  useEffect(() => {
    {
      selectedCategory &&
        isEdit &&
        setTaxType(selectedCategory?.taxTypeRecordGuid);
      setName(
        selectedCategory?.detail !== null && selectedCategory?.detail?.name
      );
      setDescription(
        selectedCategory?.detail !== null &&
          selectedCategory?.detail?.description
      );
    }
  }, [isEdit, open, selectedCategory]);

  // Function to handle tab changes
  const handleTabChangeCategoryTabs = (newTabLabel) => {
    setActiveBtnCategoryTab(newTabLabel);
  };

  // Function to handle changes in name and description fields
  const handleFieldChange = (value, field, lang) => {
    setLangData((prevLangData) => ({
      ...prevLangData,
      [lang]: {
        ...prevLangData[lang],
        [field]: value,
      },
    }));
  };
  {
    console.log("AsfsafaaaasfasfsafsafselectedCategory", selectedCategory);
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };

  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={
          !isEdit
            ? Object.values(langData).every(
                (language) => language.name === ""
              ) 
            : taxType === "" || name === "" || description === ""
        }
        content={
          <>
            {/* {!isEdit && (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={Country}
                      setValue={setCountry}
                      options={countryOptions}
                      placeholder={"Country"}
                      disabled={countryOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid className="dialog-grid-spacing" item xs={6}>
                  <TextFieldForm
                    placeholder={"Rate"}
                    value={rate}
                    setValue={setRate}
                    positiveNumber
                  />
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={taxType}
                      setValue={setTaxType}
                      options={taxTypeOptions}
                      placeholder={"Tax Type"}
                      disabled={taxTypeOptions?.length === 0}

                      // disabled={isEdit}
                    />
                  </Grid>
                </Grid>
              </>
            )} */}

            {isEdit && (
              <>
                <Grid className="dialog-grid-spacing" item xs={6}>
                  <TextFieldForm
                    placeholder={"Name"}
                    value={name}
                    setValue={setName}
                  />
                </Grid>

                <Grid className="dialog-grid-spacing" item xs={6}>
                  <TextFieldForm
                    placeholder={"Description"}
                    value={description}
                    setValue={setDescription}
                  />
                </Grid>
              </>
            )}
            {!isEdit && (
              <>
                {/* <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={taxCategory}
                      setValue={setTaxCategory}
                      options={taxCategoryOptions}
                      placeholder={"Tax Category"}
                      disabled={taxCategoryOptions?.length === 0}

                      // disabled={isEdit}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"From Date"}
                      value={startDate}
                      // setValue={setStartDate}
                      onChangeFct={handleStartDateChange}
                      type="date"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"To Date"}
                      value={endDate}
                      // setValue={setEndDate}
                      onChangeFct={handleEndDateChange}
                      type="date"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                    Is For Display Only
                  </span>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switchState}
                          onChange={handleSwitchChange}
                        />
                      }
                      label={switchState ? "Active " : "Inactive "}
                    />
                  </Grid>
                </Grid>
                */}
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <HorizantalTabs
                      tabs={taxesTabs}
                      onTabChange={handleTabChangeCategoryTabs}
                    />
                  </Grid>
                </Grid> 
                {Object.entries(langData)?.map(([lang, langValues]) => (
                  <Grid container key={lang}>
                    <Grid
                      style={{
                        display: activeBtnCategoryTab !== lang && "none",
                      }}
                      className="dialog-grid-spacing"
                      item
                      xs={12}
                    >
                      <TextFieldForm
                        placeholder={`Name (${lang})`}
                        value={langValues?.name}
                        setValue={(value) =>
                          handleFieldChange(value, "name", lang)
                        }
                      />
                    </Grid>
                    <Grid
                      style={{
                        display: activeBtnCategoryTab !== lang && "none",
                      }}
                      className="dialog-grid-spacing"
                      item
                      xs={12}
                    >
                      <TextFieldForm
                        placeholder={`Description (${lang})`}
                        value={langValues?.description}
                        setValue={(value) =>
                          handleFieldChange(value, "description", lang)
                        }
                        disabled={activeBtnCategoryTab !== lang} // Disable if not active
                      />
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageCategory;
