import { Alert, FormControlLabel, Grid, Snackbar, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import {
  ADD_COST_PLAN,
  GET_ALL_COUNTRIES_OPTIONS,
  GET_ALL_CURRENCIES,
  GET_ALL_OPERATORS_OPTIONS,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import axiosInstance from "../../../axiosInstance";
const ManageCosts = ({
  open,
  setOpen,
  title,
  onButtonClick,
  providerCategoryImportOptions,
  setOpenAlert,
  setAlertMessage,
  isEdit,
  CostData,
  selectedCost,
  channelGuid,
  serviceGuid,
}) => {
  const [ProviderCategory, setProviderCategory] = useState("");
  const [services, setServices] = useState(localStorage.getItem("services"));
  const [Country, setCountry] = useState("");
  const [currencies, setCurrencies] = useState("");
  const [currenciesOptions, setCurrenciesOptions] = useState(false);
  const [CountryOptions, setCountryOptions] = useState([]);
  const [OperatorOptions, setOperatorOptions] = useState([]);
  const [Operator, setOperator] = useState("");
  const [isLoadingOperator, setIsLoadingOperator] = useState(false);

  // const [alertMesssage, setAlertMessage] = useState("");
  // const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    getAllOperatorName();
  }, [Country]);

  useEffect(() => {
    getAllCurrencies();
  }, []);

  const getAllCountries = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["configuration"]
        }${GET_ALL_COUNTRIES_OPTIONS}?SearchKeyword=&pageSize=1000`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCountryOptions(
          res?.data?.data?.countries.map((country) => ({
            label: country?.name,
            value: country?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllCountries();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };

  const getAllOperatorName = () => {
    setIsLoadingOperator(true);
    setOperatorOptions([]);
    let newServices = JSON.parse(services);
    {
      setOperator("");
    }

    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_OPERATORS_OPTIONS}?CountryGuid=${Country}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoadingOperator(false);

        setOperatorOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        setIsLoadingOperator(false);

        if (err === "error: 401") {
          this.getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        setIsLoadingOperator(false);

        // this.setState({ loading: false, resetPagination: false });
      });
  };

  const getAllCurrencies = () => {
    let newServices = JSON.parse(services);
    setCurrenciesOptions(false);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${GET_ALL_CURRENCIES}?SearchKeyword=`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCurrenciesOptions(
          res?.data?.data?.currencies.map((x) => ({
            label: x?.name,
            value: x?.code,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllOperatorName();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // this.setState({ loading: false, resetPagination: false });
      });
  };

  useEffect(() => {
    console.log(selectedCost);
  }, []);

  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      ServiceGuid: serviceGuid,
      ChannelGuid: channelGuid,
      OperatorGuid: Operator,
      CountryGuid: Country,
      CurrencyCode: currencies,
      //CountryCode: Country,
      OperationTypeTag: "GENERAL",
      ProviderCategoryGuid: ProviderCategory,
      IsDefault: true,
      Cost: Cost,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_COST_PLAN}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpen(false);
        setOpenAlert(true);
        setOperator("");
        setCurrencies("");
        setCountry("");
        setProviderCategory("");
        setCost("");
        setAlertMessage(data?.message);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    console.log(Country);
  }, [Country]);
  useEffect(() => {
    setOperator("");
    setCurrencies("");
    setCountry("");
    setProviderCategory("");
    setCost("");
  }, [open]);

  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      ServiceGuid: serviceGuid,
      ChannelGuid: channelGuid,
      OperatorGuid: selectedCost?.operatorGuid,
      CurrencyCode: selectedCost?.currencyCode,
      CountryGuid: selectedCost?.countryGuid,
      // CountryCode: selectedCost?.countryCode,
      OperationTypeTag: "GENERAL",
      ProviderCategoryGuid: selectedCost?.providerCategoryGuid,
      IsDefault: true,
      Cost: Cost,
      CostPlanGuid: selectedCost?.recordGuid,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["billing"]
        }${ADD_COST_PLAN}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpen(false);
        setOpenAlert(true);
        setAlertMessage(data?.message);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isEdit) {
      setCost(selectedCost?.cost);
    }
  }, [selectedCost]);

  const [Cost, setCost] = useState(isEdit ? selectedCost?.cost : "");
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      firstActionBtnFct={isEdit ? submitEdit : submitAdd}
      disableFirstBtn={
        title !== "Edit" &&
        (ProviderCategory === "" ||
          Country === "" ||
          Cost === "" ||
          currencies === "")
          ? true
          : title !== "Add" && Cost === ""
          ? true
          : false
      }
      content={
        <>
          {!isEdit && (
            <>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={ProviderCategory}
                    setValue={setProviderCategory}
                    options={providerCategoryImportOptions}
                    placeholder={"Provider Category"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={Country}
                    setValue={setCountry}
                    options={CountryOptions}
                    placeholder={"Country"}
                    // disabled={ProviderCategory === ""}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={currencies}
                    setValue={setCurrencies}
                    options={currenciesOptions}
                    placeholder={"Currency"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <SelectFormNew
                    value={Operator}
                    setValue={setOperator}
                    options={OperatorOptions}
                    placeholder={"Operator"}
                    disabled={OperatorOptions?.length === 0 || Country === ""}
                    isLoading={isLoadingOperator}
                    wait={"select a country"}
                  />
                  <span style={{color:"red",fontSize:"14px",marginLeft:"3px"}}>
                  *cost will be added to all operators if not any is selected
                  </span>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Cost"}
                value={Cost}
                setValue={setCost}
                // type={"number"}
                positiveNumberFloat
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageCosts;
