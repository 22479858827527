import { Alert, Grid, IconButton, Snackbar, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
// import { HandleApiError } from "../../../Utils/Functions";
// import { ADD_MENU } from "../../../../APIs/Roles";
// import { useSelector } from "react-redux";
// import Notification from "../../../Components/Notification/Notification";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconArray } from "../../../Utils/Constants";
// import TextFieldForm from "../../../Components/TextField/TextFieldForm";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import CloseIcon from "@mui/icons-material/Close";

const AddMenu = ({
  AddToggle,
  SetAddToggle,
  menu = null,
  handleSubmitButton,
  menus = 0,
  Name,
  setName,
  Path, 
  setPath,
  Icon, 
  setIcon,
  toggleType
}) => {
  // const [Name, SetName] = useState("");
  // const [Path, SetPath] = useState("");
  // const [Icon, SetIcon] = useState("");
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  useEffect(() => {
    if (AddToggle) {
      setName("");
      setPath("");
      setIcon("");
    }
  }, [AddToggle]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleSubmit = async () => {
    let formData = {
      Uri: Path,
      IconUri: Icon,
      position: 100,
      group: 2,
      MenuDetail: [
        {
          Name: Name,
          Description: Name,
          LanguageCode: "en",
        },
      ],
    };
    console.log("Aaaaaaaaaag",formData)
    if (menu) {
      formData["ParentId"] = menu?.recordGuid;
      formData["DisplayOrder"] = -1;
    } else {
      formData["DisplayOrder"] = menus;
    }
    // return console.log(formData)
    handleSubmitButton(formData);
  };

  if (menu) {
    return (
      <>
        <Grid container>
          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Name</span>
          <Grid className="dialog-grid-spacing" item xs={12}>
            <TextFieldForm value={Name} setValue={setName} />
          </Grid>
        </Grid>
        <Grid container>
          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Path</span>
          <Grid className="dialog-grid-spacing" item xs={12}>
            <TextFieldForm value={Path} setValue={setPath} />
          </Grid>
        </Grid>
        <Grid container>
          <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Icon</span>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid paddingLeft={0} item xs={11} className="icon-input-role">
                <Autocomplete
                  fullWidth
                  // id="combo-box-demo"
                  options={iconArray?.map((item, index) => {
                    return { id: index, label: item };
                  })}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <i className={`fa ${option.label} mr-1`} /> {option.label}
                    </li>
                  )}
                  disableClearable
                  onChange={(e, n) => setIcon(n?.label)}
                  renderInput={(params) => (
                    <TextField
                    sx={{
                      padding: "3.5px 4px 7.5px 5px",
                      "& .MuiInputBase-input": {
                        padding: "0.5px 4px 7.5px 5px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "0.5px 4px 7.5px 5px",
                      },
                      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                        padding: "0.5px 4px 7.5px 5px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                      maxWidth: "96%",
                    }}
                    {...params}
                  />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {Icon && <i className={`fa ${Icon} icon-member`}></i>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <CustomizedDialogs
          // open={open}
          setOpen={SetAddToggle}
          open={AddToggle}
          title={"Add Menu"}
          firstActionBtnName={"Save"}
          firstActionBtnFct={handleSubmit}
          // secondActionBtnName={"Cancel"}
          content={
            <>
              <Grid container>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Name</span>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm value={Name} setValue={setName} />
                </Grid>
              </Grid>
              <Grid container>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Path name
                </span>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm value={Path} setValue={setPath} />
                </Grid>
              </Grid>
              <Grid container>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Icon</span>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid
                      paddingLeft={0}
                      item
                      xs={11}
                      className="icon-input-role"
                    >
                      <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={iconArray?.map((item, index) => {
                          return { id: index, label: item };
                        })}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <i
                              style={{ marginRight: "5px" }}
                              className={`fa ${option.label} mr-1`}
                            />{" "}
                            {option.label}
                          </li>
                        )}
                        disableClearable
                        onChange={(e, n) => setIcon(n?.label)}
                        renderInput={(params) => (
                          <TextField
                          sx={{
                            padding: "3.5px 4px 7.5px 5px",
                            "& .MuiInputBase-input": {
                              padding: "0.5px 4px 7.5px 5px",
                            },
                            "& .MuiOutlinedInput-input": {
                              padding: "0.5px 4px 7.5px 5px",
                            },
                            "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                              padding: "0.5px 4px 7.5px 5px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            maxWidth: "96%",
                          }}
                          {...params}
                        />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {Icon && <i className={`fa ${Icon} icon-member`}></i>}
                    </Grid>
                  </Grid>
                </Grid>{" "}
              </Grid>
            </>
          }
        />
        <Snackbar
          style={{ marginTop: "40px" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
          open={openMessageAlert}
          autoHideDuration={3000}
          action={action}
        >
          <Alert
            variant="filled"
            severity={messageData?.type}
            onClose={handleClose}
            sx={{ width: "100%" }}
          >
            {messageData?.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
};

export default AddMenu;
