// import React, { useState } from "react";
// import Draggable from "react-draggable";
// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Button,
//   Typography,
// } from "@mui/material";
// import ReactDOMServer from "react-dom/server";
 
// const DraggableDiv = ({ title, content, expanded }) => {
//   const [position, setPosition] = useState({ x: 0, y: 0 });
 
//   const handleDrag = (e, ui) => {
//     // Update the position while dragging
//     setPosition({ x: ui.x, y: ui.y });
//   };
 
//   const handleDragStop = () => {
//     // Open a new window with the dragged content
//     const newWindow = window.open(
//       "",
//       "_blank",
//       "width=300,height=200,left=" +
//         position.x +
//         ",top=" +
//         position.y +
//         ",resizable=yes"
//     );
//     const componentString = ReactDOMServer.renderToString(
//       <Accordion
//         expanded={expanded}
//         style={{
//           boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 10px",
//           borderRadius: "20px",
//           marginBottom: "20px",
//           backgound: "red",
//         }}
//       >
//         <AccordionSummary
//           //   expandIcon={<ExpandMoreIcon />}
//           style={{ display: "none" }}
//           aria-controls="panel1a-content"
//           id="panel1a-header"
//         >
//           {/* <Typography style={{fontSize:"14px",color:"gray"}}>{title?title:"Advanced Search"}</Typography> */}
//         </AccordionSummary>
//         <AccordionDetails>{content}</AccordionDetails>
//         <div className="end" style={{ margin: "10px 40px 10px 0px" }}>
//           <Button className="contained">Search</Button>
//         </div>
//       </Accordion>
//     );
//     newWindow.document.write(componentString);
//   };
 
//   return (
//     <Draggable
//       handle=".handle"
//       defaultPosition={position}
//       onDrag={handleDrag}
//       onStop={handleDragStop}
//     >
//       <div
//         style={{ visibility: !expanded && "hidden", marginBottom: "7px" }}
//         className="draggable-div"
//       >
//         <div className="handle">drag me</div>
//         <div className="handle">
//           <Accordion
//             expanded={expanded}
//             style={{
//               boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 10px",
//               borderRadius: "20px",
//               marginBottom: "20px",
//             }}
//           >
//             <AccordionSummary
//               //   expandIcon={<ExpandMoreIcon />}
//               style={{ display: "none" }}
//               aria-controls="panel1a-content"
//               id="panel1a-header"
//             >
//               {/* <Typography style={{fontSize:"14px",color:"gray"}}>{title?title:"Advanced Search"}</Typography> */}
//             </AccordionSummary>
//             <AccordionDetails>{content}</AccordionDetails>
//             <div className="end" style={{ margin: "10px 40px 10px 0px" }}>
//               <Button className="contained">Search</Button>
//             </div>
//           </Accordion>
//         </div>
//       </div>
//     </Draggable>
//   );
// };
 
// export default DraggableDiv;
 
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid
} from "@mui/material";
 
export default function AdvancedSearch({
  title,
  content,
  expanded,
  handleSearchBtn,
  handleResetAll,
}) {
  return (
    <div
      // style={{
      //   visibility:
      //     localStorage.getItem("filterExpanded") === "true" &&
      //     localStorage.getItem("statusFilter") === "false"
      //       ? "hidden"
      //       : localStorage.getItem("filterExpanded") === "false" && !expanded
      //       ? "hidden"
      //       : "",
      //   marginBottom: "7px",
      // }}
      style={{
        visibility: !expanded && "hidden",
        marginBottom: "7px",
      }}
    >
      <Accordion
        // expanded={
        //   localStorage.getItem("filterExpanded") === "false"
        //     ? expanded
        //     : localStorage.getItem("filterExpanded") === "true" &&
        //       localStorage.getItem("statusFilter") === "true"
        //     ? true
        //     : localStorage.getItem("filterExpanded") === "true" &&
        //       localStorage.getItem("statusFilter") === "false"
        //     ? false
        //     : false
        // }
        expanded={expanded}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 10px",
          borderRadius: "20px",
          marginBottom: "20px",
          backgound: "red",
        }}
      >
        <AccordionSummary
          //   expandIcon={<ExpandMoreIcon />}
          style={{ display: "none" }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {/* <Typography style={{fontSize:"14px",color:"gray"}}>{title?title:"Advanced Search"}</Typography> */}
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
        <Grid container spacing={1} end marginBottom="20px">
 
          <Grid item xs={12} className="end">
            {handleResetAll && (
              <Button onClick={handleResetAll} className="contained">
                Reset All
              </Button>
            )}
            <Button
              style={{ marginLeft: "12px", marginRight: "8px" }}
              onClick={handleSearchBtn}
              className="contained"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Accordion>
    </div>
  );
}