import { useState } from 'react';
import { Box, FormControl, InputAdornment, IconButton, OutlinedInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function PasswordInput({ value, setValue, placeholder, adornment }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ width: "100%" }}>
      {placeholder && <span style={{ color: "#B3B3B3", fontSize: "15px" }}>{placeholder}</span>}
      <FormControl sx={{ width: "100%", borderRadius: "32px", border: "1px solid #E0E0E0" }}>
        <OutlinedInput
          size="small"
          id="header-search"
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          aria-describedby="header-search-text"
          inputProps={{ "aria-label": "weight" }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
