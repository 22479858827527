import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectForm({
  value,
  setValue,
  options,
  placeholder,
  multiple,
}) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <span style={{ color: "#B3B3B3", fontSize: "15px" }}>{placeholder}</span>

      <FormControl
        sx={{
          width: 300,
          borderRadius: "32px",
          border: "1px solid rgb(224, 224, 224)!important",
          width: "100%",
          
        }}
      >
        <Select
          multiple={multiple}
          displayEmpty
          value={value}
          onChange={(e) => setValue(e.target.value)}
          sx={{
            "& fieldset": { border: "none" },
          }}
          input={
            <OutlinedInput
              sx={{
                "& fieldset": { border: "none" },
              }}
              style={{
                borderRadius: "32px",
                border: "1px solid rgb(224, 224, 224)!important",
              }}
            />
          }
          size="small"
          variant="outlined"
          className="custom-select"
          renderValue={(selected) => {
            // if (selected.length === 0) {
            //   return (
            //     <Typography style={{ color: "#ADADAD" }}>
            //       {placeholder}
            //     </Typography>
            //   );
            // }
            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <span>{placeholder}</span>
          </MenuItem>
          {options &&
            options?.map((item) => (
              <MenuItem
                key={item}
                value={item}
                style={getStyles(item, personName, theme)}
              >
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
