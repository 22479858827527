import { GitHub, Google, Info, Security } from "@mui/icons-material";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel, 
  FormGroup, 
  Grid,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { ColorBox } from "material-ui-color";
import ThemeSelector from "../../../../Components/ThemeSelector/ThemeSelector";
import { useResellerContext } from "../ResellerContext";
import { useSelector } from "react-redux";
import { ConvertBlob, handleMessageError } from "../../../../Utils/functions";
import { useEffect, useState } from "react";
import CropImageComponent from "./CropImage";
import { GET_MEDIA_PATHS, MULTI_TENANCY_CONFIG, UPLOAD_MEDIA } from "../../../APIs";
import axiosInstance from "../../../../axiosInstance";
import CustomizedDialogs from "../../../../Components/Dialog/Dialog";
import { MyDropzone } from "../../../../Components/Buttons/ImportButton";


const ConfigurationSection = ({ pageType = "add" }) => {
  const { formik, GlobalData, SetGlobalData } = useResellerContext();
  const { media } = useSelector((state) => state.system);
  const [UploadedFile, SetUploadedFile] = useState([]);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);

  const [mediaPathToken, setMediaPathToken] = useState("");

  const { steps, activeStep } = GlobalData;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };

  const getMediaConfigPaths = async ()=>{

    try {
      MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
      MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  
      let mediaResponse = await axiosInstance.get(
        `${localStorage.getItem("baseUrl")}${GET_MEDIA_PATHS}`,
        MULTI_TENANCY_CONFIG
      );

      if (mediaResponse?.data?.data?.paths["omni-core-assets"]) {
        setMediaPathToken(mediaResponse?.data?.data?.paths["omni-core-assets"]);
      }

    } catch (e) {
      console.log(e)
    }

  }

  const handleUploadImage = async ({
    file,
    type = null,
    aspectRatio = null,
  }) => {
    if (aspectRatio) {
      SetGlobalData((prev) => {
        return {
          ...prev,
          image: {
            file,
            type,
            aspectRatio,
          },
        };
      });
    } else {
      await handleCroppedImage({ file, type });
    }
  };

  const handleCroppedImage = async ({ file, type = null }) => {
    let img =
      type == "FAVICON_LOGO"
        ? await ConvertBlob({ image: file, type: "file", output: "image/png" })
        : file;
    try {

      let data = new FormData();
      data.append("ContentType", "image/jpg");
      data.append("ThumbnailWidth", 50);
      data.append("ThumbnailHeight", 500);
      data.append("file", img);
      data.append("RefPath", mediaPathToken)

      let mediaResponse = await axiosInstance.post(
        `${localStorage.getItem("baseUrl")}${UPLOAD_MEDIA}`,
        data,
        {headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          Tenant: localStorage.getItem("tenant"),
        }}
      );

      // Handle the API response, check for success, and set the form field value
      if (mediaResponse?.data?.success) {
        SetUploadedFile((prev) => [
          ...prev,
          { type, file: mediaResponse?.data?.data?.result?.info?.identifier },
        ]);
        formik?.setFieldValue(
          type,
          mediaResponse?.data?.data?.result?.info?.identifier
        );
      } else {
        // Handle the case where the upload was not successful, e.g., show an error message.
        console.error("Media upload failed:", mediaResponse?.data?.error);
      }
    } catch (e) {
      console.log(e)
      setOpenMessageAlert(true)
      setMessageData({type:"error",message:"An error ocurred uploading media"})
    } finally {
      SetGlobalData((prev) => {
        return { ...prev, image: null };
      });
    }
  };

  useEffect(()=>{
    getMediaConfigPaths();
  },[])

  return (
    <>
    
      <CustomizedDialogs
        open={GlobalData?.image?.file}
        setOpen={() =>
          SetGlobalData((prev) => {
            return { ...prev, image: null };
          })
        }
        title={"Crop Image"}
        content={
          <CropImageComponent
            aspectRatio={GlobalData?.image?.aspectRatio}
            image={GlobalData?.image?.file}
            setCroppedImage={(file) =>
              handleCroppedImage({ file, type: GlobalData?.image?.type })
            }
          />
        }
      >
      </CustomizedDialogs>

      {activeStep == (pageType == "edit" ? 0 : 2) && (
        <>
          <Typography>
            <Info
              style={{
                color: "#C41035",
                fontSize: "25px",
                marginRight: "5px",
                marginBottom: "-7px",
              }}
            />
            Client Config
          </Typography>

          <Grid
            container
            style={{ marginBottom: "10px", marginTop: "8px" }}
            spacing={3}
          >
            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                    checked={formik.values["ALERTS_TOGGLE"]}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    name="ALERTS_TOGGLE"
                  />} 
                label="Alerts via email" 
                />
                {formik?.values?.ALERTS_TOGGLE && (
                  <Box sx={{ width: "100%", marginBottom: "8px" }}>
                  <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                    Email Address
                  </span>

                  <FormControl
                    sx={{
                      width: "100%",
                      borderRadius: "32px",
                      border: "1px solid #E0E0E0 ",
                      display: "grid",
                    }}
                  >
                    {" "}
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          border: "none",
                        },
                        padding: "5px",
                      }}
                        key={"ALERTS"}
                        id={"ALERTS"}
                        name={"ALERTS"}
                        className="w-25"
                        value={formik.values["ALERTS"]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched["ALERTS"] && Boolean(formik.errors["ALERTS"])
                        }
                        helperText={
                          formik.touched["ALERTS"] && formik.errors["ALERTS"]
                        }
                    />
                  </FormControl>
                  </Box>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["SENDER_TOGGLE"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="SENDER_TOGGLE"
                />} 
                label="Default Sender Id" 
                />
                {formik?.values?.SENDER_TOGGLE && (
                   <Box sx={{ width: "100%", marginBottom: "8px" }}>
                   <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                      Sender Id
                   </span>
 
                   <FormControl
                     sx={{
                       width: "100%",
                       borderRadius: "32px",
                       border: "1px solid #E0E0E0 ",
                       display: "grid",
                     }}
                   >
                     {" "}
                     <TextField
                       sx={{
                         "&.MuiTextField-root": {
                           border: "none",
                         },
                         padding: "5px",
                       }}
                       key={"SENDER"}
                       id={"SENDER"}
                       name={"SENDER"}
                       className="w-25"
                       value={formik.values["SENDER"]}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={
                         formik.touched["SENDER"] && Boolean(formik.errors["SENDER"])
                       }
                       helperText={
                         formik.touched["SENDER"] && formik.errors["SENDER"]
                       }
                     />
                   </FormControl>
                   </Box>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["API_TOGGLE"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="API_TOGGLE"
                />} 
                label="Default API" 
                />
                {formik?.values?.API_TOGGLE && (
                   <Box sx={{ width: "100%", marginBottom: "8px" }}>
                   <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                      API
                   </span>
 
                   <FormControl
                     sx={{
                       width: "100%",
                       borderRadius: "32px",
                       border: "1px solid #E0E0E0 ",
                       display: "grid",
                     }}
                   >
                     {" "}
                     <TextField
                       sx={{
                         "&.MuiTextField-root": {
                           border: "none",
                         },
                         padding: "5px",
                       }}
                       key={"API"}
                      id={"API"}
                      name={"API"}
                      className="w-25"
                      value={formik.values["API"]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched["API"] && Boolean(formik.errors["API"])}
                      helperText={formik.touched["API"] && formik.errors["API"]}
                     />
                   </FormControl>
                   </Box>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["NOTIFICATION_ALERT"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="NOTIFICATION_ALERT"
                />} 
                label="Notification Alert" 
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["KYC_ENABLED"]}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    name="KYC_ENABLED"
                  />} 
                label="Enable KYC" 
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                    checked={formik.values["TERMS_CONDITIONS_ENABLED"]}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    name="TERMS_CONDITIONS_ENABLED"
                />} 
                label="Enable Terms & Conditions" 
                />
              </FormGroup>
            </Grid>

          </Grid>
        </>
      )}

      {activeStep == (pageType == "edit" ? 1 : 3) && (
        <>
         <Typography>
            <Info
              style={{
                color: "#C41035",
                fontSize: "25px",
                marginRight: "5px",
                marginBottom: "-7px",
              }}
            />
            Login Config
          </Typography>

          <Grid
            container
            style={{ marginBottom: "10px", marginTop: "8px" }}
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color={"black"}
              >
                {" "}
                Right Container{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["SIGNUP"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="SIGNUP"
                />} 
                label="Allow Default Signup" 
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["COPYRIGHTS_TOGGLE"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="COPYRIGHTS_TOGGLE"
                />} 
                label="Copyright" 
                />
                {formik.values["COPYRIGHTS_TOGGLE"] && (
                   <Box sx={{ width: "100%", marginBottom: "8px" }}>
                   <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                      Copyrights
                   </span>
 
                   <FormControl
                     sx={{
                       width: "100%",
                       borderRadius: "32px",
                       border: "1px solid #E0E0E0 ",
                       display: "grid",
                     }}
                   >
                     {" "}
                     <TextField
                       sx={{
                         "&.MuiTextField-root": {
                           border: "none",
                         },
                         padding: "5px",
                       }}
                       key={"COPYRIGHTS"}
                        id={"COPYRIGHTS"}
                        name={"COPYRIGHTS"}
                        className="w-25"
                        value={formik.values["COPYRIGHTS"]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched["COPYRIGHTS"] &&
                          Boolean(formik.errors["COPYRIGHTS"])
                        }
                        helperText={
                          formik.touched["COPYRIGHTS"] &&
                          formik.errors["COPYRIGHTS"]
                        }
                     />
                   </FormControl>
                   </Box>
                )}
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              display={"flex"}
              direction={"row"}
            >
              <Typography variant="subtitle">
                Signin Method
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["DEFAULT_SIGNIN"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="DEFAULT_SIGNIN"
                />} 
                label="Allow Default Signin" 
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["GOOGLE"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="GOOGLE"
                />} 
                label="Allow Google" 
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6} lg={4} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["GITHUB"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="GITHUB"
                />} 
                label="Allow Github" 
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                color={"black"}
              >
                {" "}
                Left Container{" "}
              </Typography>
            </Grid>

            <Grid item xs={10} display={"flex"} direction={"row"}>
              <FormGroup>
                <FormControlLabel 
                control={<Checkbox
                  checked={formik.values["BANNER"]}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name="BANNER"
                />} 
                label="Make Image as background" 
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle" fontWeight={"bold"}>
                {" "}
                Upload Login Banner{" "}
              </Typography>
              <Grid container gap={4}>
                <Grid item xs={10}>
                  <MyDropzone
                    setFile={
                      (file)=>{
                        handleUploadImage({
                          file: file,
                          type: "BANNER_IMAGE",
                        })
                      }
                    }
                    nameProvided={true}
                    file={
                      UploadedFile?.find((item) => item?.type == "BANNER_IMAGE")
                      ?.file
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ width: "100%", marginBottom: "8px" }}>
                    <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                      Main Heading Text
                    </span>
                    <FormControl
                      sx={{
                        width: "100%",
                        borderRadius: "32px",
                        border: "1px solid #E0E0E0 ",
                        display: "grid",
                      }}
                    >
                      {" "}
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            border: "none",
                          },
                          padding: "5px",
                        }}
                          onBlur={formik?.handleBlur}
                          onChange={formik?.handleChange}
                          error={
                            formik.touched["PRIMARY_TEXT"] &&
                            Boolean(formik.errors["PRIMARY_TEXT"])
                          }
                          value={formik.values["PRIMARY_TEXT"]}
                          helperText={
                            formik.touched["PRIMARY_TEXT"] &&
                            formik.errors["PRIMARY_TEXT"]
                          }
                          name="PRIMARY_TEXT"
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: "100%", marginBottom: "8px" }}>
                    <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                      Secondary Heading Text
                    </span>
                    <FormControl
                      sx={{
                        width: "100%",
                        borderRadius: "32px",
                        border: "1px solid #E0E0E0 ",
                        display: "grid",
                      }}
                    >
                      {" "}
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            border: "none",
                          },
                          padding: "5px",
                        }}
                        onBlur={formik?.handleBlur}
                        onChange={formik?.handleChange}
                        value={formik.values["SECONDARY_TEXT"]}
                        error={
                          formik.touched["SECONDARY_TEXT"] &&
                          Boolean(formik.errors["SECONDARY_TEXT"])
                        }
                        helperText={
                          formik.touched["SECONDARY_TEXT"] &&
                          formik.errors["SECONDARY_TEXT"]
                        }
                        name="SECONDARY_TEXT"
                      />
                    </FormControl>
                  </Box>
                  
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"}>
              <Typography variant="subtitle" fontWeight={"bold"}>
                Background Color
              </Typography>

              <FormControl variant="standard">
                <ColorBox

                  value={formik?.values?.BACKGROUND_COLOR}
                  onChange={(color) => {
                    formik?.setFieldValue(
                      "BACKGROUND_COLOR",
                      `#${color?.hex}`
                    );
                  }}
                />
              </FormControl>
            </Grid>





          </Grid>
        </>
      )}

      {activeStep == (pageType == "edit" ? 2 : 4) && (
        <>
         <Typography>
            <Info
              style={{
                color: "#C41035",
                fontSize: "25px",
                marginRight: "5px",
                marginBottom: "-7px",
              }}
            />
            General Config
          </Typography>

          <Grid
            container
            style={{ marginBottom: "10px", marginTop: "8px" }}
            spacing={3}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Domain Name*
                </span>

                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "32px",
                    border: "1px solid #E0E0E0 ",
                    display: "grid",
                  }}
                >
                  {" "}
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        border: "none",
                      },
                      padding: "5px",
                    }}
                    key={"BusinessWebUrl"}
                    fullWidth
                    id={"BusinessWebUrl"}
                    name={"BusinessWebUrl"}
                    type={"text"}
                    value={formik.values?.BusinessWebUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.BusinessWebUrl &&
                      Boolean(formik.errors?.BusinessWebUrl)
                    }
                    helperText={
                      formik.touched?.BusinessWebUrl &&
                      formik.errors?.BusinessWebUrl
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Portal Name*
                </span>

                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "32px",
                    border: "1px solid #E0E0E0 ",
                    display: "grid",
                  }}
                >
                  {" "}
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        border: "none",
                      },
                      padding: "5px",
                    }}
                    key={"PORTAL_NAME"}
                    fullWidth
                    id={"PORTAL_NAME"}
                    name={"PORTAL_NAME"}
                    type={"text"}
                    value={formik.values?.PORTAL_NAME}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.PORTAL_NAME &&
                      Boolean(formik.errors?.PORTAL_NAME)
                    }
                    helperText={
                      formik.touched?.PORTAL_NAME && formik.errors?.PORTAL_NAME
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Google analytics
                </span>

                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "32px",
                    border: "1px solid #E0E0E0 ",
                    display: "grid",
                  }}
                >
                  {" "}
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        border: "none",
                      },
                      padding: "5px",
                    }}
                    key={"GOOGLE_ANALYTICS"}
                    fullWidth
                    id={"GOOGLE_ANALYTICS"}
                    name={"GOOGLE_ANALYTICS"}
                    type={"text"}
                    value={formik.values?.GOOGLE_ANALYTICS}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.GOOGLE_ANALYTICS &&
                      Boolean(formik.errors?.GOOGLE_ANALYTICS)
                    }
                    helperText={
                      formik.touched?.GOOGLE_ANALYTICS &&
                      formik.errors?.GOOGLE_ANALYTICS
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box sx={{ width: "100%", marginBottom: "8px" }}>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Google Tag
                </span>

                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "32px",
                    border: "1px solid #E0E0E0 ",
                    display: "grid",
                  }}
                >
                  {" "}
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        border: "none",
                      },
                      padding: "5px",
                    }}
                    key={"GOOGLE_TAG"}
                    fullWidth
                    id={"GOOGLE_TAG"}
                    name={"GOOGLE_TAG"}
                    type={"text"}
                    value={formik.values?.GOOGLE_TAG}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.GOOGLE_TAG &&
                      Boolean(formik.errors?.GOOGLE_TAG)
                    }
                    helperText={
                      formik.touched?.GOOGLE_TAG && formik.errors?.GOOGLE_TAG
                    }
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                color={"black"}
              >
                {" "}
                Upload Logo & Icon
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={4} rowSpacing={3} columnGap={4} rowGap={3}>
                <Grid item xs={10} md={5}>
                  <MyDropzone
                  setFile={
                    (file)=>{
                      handleUploadImage({
                        file: file,
                        type: "MAIN_LOGO",
                      })
                    }
                  }
                  nameProvided={true}
                  file={
                    UploadedFile?.find((item) => item?.type == "MAIN_LOGO")
                    ?.file
                  }
                  />
                </Grid>
                <Grid item xs={10} md={5}>
                  <MyDropzone
                  setFile={
                    (file)=>{
                      handleUploadImage({
                        file: file,
                        type: "FAVICON_LOGO",
                        aspectRatio: 1,
                      })
                    }
                  }
                  nameProvided={true}
                  file={
                    UploadedFile?.find((item) => item?.type == "FAVICON_LOGO")
                    ?.file
                  }
                />
                </Grid>
              </Grid>
             
            </Grid>

            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography variant="p">Login Alignment</Typography>
              <ToggleButtonGroup
                value={formik?.values?.DIRECTION}
                exclusive
                onChange={(e, newAlignment) =>
                  formik.setFieldValue("DIRECTION", newAlignment)
                }
                aria-label="text alignment"
              >
                <ToggleButton value="left" aria-label="left aligned">
                  <FormatAlignLeftIcon />
                </ToggleButton>
                <ToggleButton value="center" aria-label="centered">
                  <FormatAlignCenterIcon />
                </ToggleButton>
                <ToggleButton value="right" aria-label="right aligned">
                  <FormatAlignRightIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid item xs={10}>
              <ThemeSelector
                setPrimaryColor={(color) =>
                  formik?.setFieldValue("PRIMARY_COLOR", `#${color?.hex}`)
                }
                setSecondaryColor={(color) =>
                  formik?.setFieldValue("SECONDARY_COLOR", `#${color?.hex}`)
                }
                primaryColor={formik?.values?.PRIMARY_COLOR}
                secondaryColor={formik?.values?.SECONDARY_COLOR}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>


    </>
  )

}

export default ConfigurationSection;