import {
  Alert,
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
// import HorizantalTabs from "../../../Components/Tabs/HorizantalTabs";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import SelectForm from "../../../Components/Selects/SelectsForm";
import HorizantalTabs from "../../../Components/Tabs/HorizantalTabs";
import VerticalTabs from "../../../Components/Tabs/VerticcalTabs";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import SwipeDownIcon from "@mui/icons-material/SwipeDown";

import {
  ASSIGN_CATEGORY_PROVIDER,
  DELETE_PROVIDER,
  DELETE_PROVIDER_CATEGORY,
  EXPORT_PROVIDER,
  EXPORT_PROVIDER_CATEGORY,
  GET_ACCOUNTS_BY_PROVIDER,
  GET_ALL_PROVIDERS,
  GET_ALL_PROVIDER_CATEGORY,
  GET_PIN,
  MULTI_TENANCY_CONFIG,
  RESET_PASSWORD,
  SET_LOGIN,
  UNASSIGN_CATEGORY_PROVIDER,
} from "../../APIs";
import ManageAccounts from "./ManageAccounts";
import ManageRates from "./ManageRates";
import ManageAccountHistory from "./ManageTaxes";
import ManageWallet from "./ManageWallet";
// import ManageProvidersModal from "./ManageProvidersModal";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import PasswordInput from "../../../Components/TextFields/PasswordInput";
import NewProvider from "../NewProvider/NewProvider";
import ManageProviderCategory from "./ManageProviderCategory";
import HorizontalTabsSubtabs from "../../../Components/Tabs/HorizontalTabsSubtabs";

const Providers2 = () => {
  let services = localStorage.getItem("services");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);

  const [activeBtn, setActiveBtn] = useState("Providers");
  const [ProviderType, setProviderType] = useState("");
  const [providerCategorySearch, setProviderCategorySearch] = useState(""); //providers

  const [searchProvider, setSearchProvider] = useState("");
  const [searchProviderCategory, setSearchProviderCategory] = useState(""); //providers category

  const [isLoadingSentMail, setIsLoadingSentMail] = useState(false);
  const [tableDataProvidersCategory, setTableDataProvidersCategory] = useState(
    []
  );
  const [rowsSelectedData, setRowsSelectedData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [provider, setProvider] = useState("");
  const [accountType, setAccountType] = useState("");
  const [country, setCountry] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [category, setCategory] = useState("");
  const [categoriesOption, setCategoriesOptions] = useState(false);

  const [createdDate, setCreatedDate] = useState("");
  const [isOpenShowAccounts, setIsOpenShowAccounts] = useState(false);
  const [assignProviderCategory, setAssignProviderCategory] = useState(false);
  const [assignProviderCategoryOne, setAssignProviderCategoryOne] =
    useState(false);

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [ProviderTypeOptions, setProviderTypeOptions] = useState([
    "All",
    "Reseller",
    "Business",
  ]);
  const [providerOptions, setProviderOptions] = useState([
    "provider 1",
    "provider 2",
    "provider 3",
  ]);
  const [CountriesOptions, setCountriesOptions] = useState([
    "Lebanon",
    "Cyprus",
    "Germany",
  ]);

  const [statusOptions, setStatusOptions] = useState(["Active", "Inactive"]);

  const [ActiveBtnHorizantal, setActiveBtnHorizantal] = useState("");
  const [tableDataAccounts, setTableDataAccounts] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const [paginationModelAccounts, setPaginationModelAccounts] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [paginationModelProviders, setPaginationModelProviders] =
    React.useState({
      page: 0,
      pageSize: 10,
    });
  const [
    paginationModelProvidersCategory,
    setPaginationModelProvidersCategory,
  ] = React.useState({
    page: 0,
    pageSize: 10,
  });
  useEffect(() => {
    setActiveBtnHorizantal(
      localStorage.getItem("withoutAccout") === "withoutAccout"
        ? "Without Account"
        : "With Account"
    );
  }, []);

  // Accounts
  const [isModalOpenSwitchAccounts, setModalOpenSwitchAccounts] =
    useState(false);
  const [isCheckedSwitchAccounts, setCheckedSwitchAccounts] = useState(false);
  const [switchStatesAccounts, setswitchStatesAccounts] = useState({});
  const handleSwitchChangeSwitchAccounts = (rowId) => {
    setModalOpenSwitchAccounts(true);
    setCheckedSwitchAccounts(rowId);
  };

  const handleModalCloseSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
  };
  const handleModalShowAccounts = (data) => {
    setTableDataAccounts([]);
    setProviderData(data);
    tableQueryExecuterAccounts(data?.recordGuid);
    setIsOpenShowAccounts(!isOpenShowAccounts);
  };
  const handleModalConfirmSwitchAccounts = () => {
    setModalOpenSwitchAccounts(false);
    // setCheckedSwitchAccounts(!isCheckedSwitchAccounts);
    setswitchStatesAccounts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchAccounts]: !prevStates[isCheckedSwitchAccounts],
    }));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setCreatedDate(value);
  };

  //

  // Rates
  const [isModalOpenSwitchRates, setModalOpenSwitchRates] = useState(false);
  const [isCheckedSwitchRates, setCheckedSwitchRates] = useState(false);
  const [switchStatesRates, setSwitchStatesRates] = useState({});
  const handleSwitchChangeSwitchRates = (rowId) => {
    setModalOpenSwitchRates(true);
    setCheckedSwitchRates(rowId);
  };

  const exportProvider = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${EXPORT_PROVIDER}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Provider.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };

  const handleDeleteProvider = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${DELETE_PROVIDER}?ProviderGuid=${selectedProvider?.recordGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // toast.success("Rate Deleted");
        setOpenMessageAlert(true);

        setMessageData({
          message: data?.message,
          type: "success",
        });
        setConfirmOpenProviders(false);
        setPaginationModelProviders({
          page:0,
          pageSize: paginationModelProviders?.pageSize
            ? paginationModelProviders?.pageSize
            : 10,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleDeleteProvider();
        }
        // toast.error(err?.response?.data?.Message || "Somthing Went Wrong!");
      });
  };

  const exportProviderCategory = () => {
    setIsLoading(true);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${EXPORT_PROVIDER_CATEGORY}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setIsLoading(false);
        setMessageData({
          message: "Downloaded Successfully !",
          type: "success",
        });
        setOpenMessageAlert(true);
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Provider Category.csv`); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        // toast.error("Something Went Wrong");
        setIsLoading(false);
      })
      .finally(() => {
        // this.setState({ loading: false });
        setIsLoading(false);
      });
  };

  const handleDeleteProviderCategory = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .delete(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${DELETE_PROVIDER_CATEGORY}?RecordGuid=${selectedProviderCategory?.recordGuid
        }`,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // toast.success("Rate Deleted");
        setOpenMessageAlert(true);

        setMessageData({
          message: data?.message,
          type: "success",
        });
        setPaginationModelProvidersCategory({
          page:  0,
          pageSize: paginationModelProvidersCategory?.pageSize
            ? paginationModelProvidersCategory?.pageSize
            : 10,
        });
        setConfirmOpenWallet(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          handleDeleteProviderCategory();
        }
        // toast.error(err?.response?.data?.Message || "Somthing Went Wrong!");
      });
  };

  const handleModalCloseSwitchRates = () => {
    setModalOpenSwitchRates(false);
  };
  const handleModalConfirmSwitchRates = () => {
    setModalOpenSwitchRates(false);
    // setCheckedSwitchRates(!isCheckedSwitchRates);
    setSwitchStatesRates((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchRates]: !prevStates[isCheckedSwitchRates],
    }));
  };

  //
  // Costs
  const [isModalOpenSwitchCosts, setModalOpenSwitchCosts] = useState(false);
  const [isCheckedSwitchCosts, setCheckedSwitchCosts] = useState(false);
  const [switchStatesCosts, setSwitchStatesCosts] = useState({});
  const handleSwitchChangeSwitchCosts = (rowId) => {
    setModalOpenSwitchCosts(true);
    setCheckedSwitchCosts(rowId);
  };

  const handleModalCloseSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
  };
  const handleModalConfirmSwitchCosts = () => {
    setModalOpenSwitchCosts(false);
    // setCheckedSwitchCosts(!isCheckedSwitchCosts);
    setSwitchStatesCosts((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchCosts]: !prevStates[isCheckedSwitchCosts],
    }));
  };

  const assignFct = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let dataProvider = rowsSelectedData?.map((x) => x.recordGuid);

    let data = {
      CategoryId: category,
      ProviderIds: [selectedProvider?.recordGuid],
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${ASSIGN_CATEGORY_PROVIDER}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully assign !", type: "success" });

        setAssignProviderCategoryOne(false);
        setCategory("");
        setPaginationModelProviders({
          page: paginationModelProviders?.page
            ? paginationModelProviders?.page
            : 0,
          pageSize: paginationModelProviders?.pageSize
            ? paginationModelProviders?.pageSize
            : 10,
        });
        setSelectedRows([]);
      })
      .catch((error) => {
        setCategory("");

        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const assignFctMulti = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let dataProvider = rowsSelectedData?.map((x) => x.recordGuid);

    let data = {
      CategoryId: category,
      ProviderIds: dataProvider,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${ASSIGN_CATEGORY_PROVIDER}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully assign !", type: "success" });

        setAssignProviderCategory(false);
        setCategory("");
        setPaginationModelProviders({
          page: paginationModelProviders?.page
            ? paginationModelProviders?.page
            : 0,
          pageSize: paginationModelProviders?.pageSize
            ? paginationModelProviders?.pageSize
            : 10,
        });
        setSelectedRows([]);
      })
      .catch((error) => {
        setCategory("");

        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.message,
          type: "error",
        });
      });
  };
  useEffect(() => {
    if (activeBtn === "Providers") {
      tableQueryExecuterProviders();
    }
  }, [paginationModelProviders]);
  useEffect(() => {
    if (activeBtn === "Provider Category") {
      tableQueryExecuterProvidersCategory();
    }
  }, [paginationModelProvidersCategory]);

  useEffect(() => {
    if (providerData) {
      tableQueryExecuterAccounts();
    }
  }, [paginationModelAccounts]);

  useEffect(() => {
    if (activeBtn === "Providers") {
      GetAllProviderCategory();
      setSearchProvider("");

      tableQueryExecuterProviders();
      setPaginationModelProviders({
        page: 0,
        pageSize: 10,
      });
    }
    if (activeBtn === "Provider Category") {
      setSearchProviderCategory("");

      tableQueryExecuterProvidersCategory();
      setPaginationModelProvidersCategory({
        page: 0,
        pageSize: 10,
      });
    }
  }, [activeBtn]);
  const tableQueryExecuterProviders = () => {
    setSelectedRows([]);
    setIsLoading(true);
    // setSelectedRows([]);
    // setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);

    let data = {};
    if (providerCategorySearch) {
      data = {
        pageIndex: paginationModelProviders?.page + 1,
        pageSize: paginationModelProviders?.pageSize,
        search: searchProvider,
        typeTag: "",
        CategoryRecordGuid: providerCategorySearch,
      };
    } else {
      data = {
        pageIndex: paginationModelProviders?.page + 1,
        pageSize: paginationModelProviders?.pageSize,
        search: searchProvider,
        typeTag: "",
      };
    }

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["members"]
        }${GET_ALL_PROVIDERS}?PageIndex=${paginationModelProviders?.page + 1
        }&PageSize=${paginationModelProviders?.pageSize}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataProviders({
          data: res?.data?.data?.providers ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          tableQueryExecuterProviders();
        }
        setTableDataProviders({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const GetAllProviderCategory = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["members"]
        }${GET_ALL_PROVIDER_CATEGORY}`,
        // data,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setCategoriesOptions([
          ...res?.data?.data?.providerCategories?.map((x, i) => ({
            label: x.providerCategoryDetails[0]
              ? x.providerCategoryDetails[0].name
              : "",
            value: x.recordGuid,
          })),
        ]);
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          GetAllProviderCategory();
        }
      })
      .finally(() => { });
  };

  const tableQueryExecuterProvidersCategory = () => {
    setIsLoading(true);
    // setSelectedRows([]);
    // setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoading(true);
    // let data = {
    //   pageIndex: paginationModelProvidersCategory?.page + 1,
    //   pageSize: paginationModelProvidersCategory?.pageSize,
    //   search: searchProvider,
    //   typeTag: "",
    // };

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["members"]
        }${GET_ALL_PROVIDER_CATEGORY}?PageIndex=${paginationModelProvidersCategory?.page + 1
        }&PageSize=${paginationModelProvidersCategory?.pageSize
        }&Name=${searchProviderCategory}`,
        // data,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataProvidersCategory({
          data: res?.data?.data?.providerCategories ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          tableQueryExecuterProvidersCategory();
        }
        setTableDataProvidersCategory({
          data: [],
          totalCount: 0,
          isLoading: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tableQueryExecuterAccounts = (providerGuid) => {
    setIsLoadingAccounts(true);
    // setSelectedRows([]);
    // setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    setIsLoadingAccounts(true);

    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${newServices["billing"]
        }${GET_ACCOUNTS_BY_PROVIDER}?PageIndex=${paginationModelAccounts?.page + 1
        }&PageSize=${paginationModelAccounts?.pageSize
        }&ProviderGuid=${providerGuid}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setTableDataAccounts({
          data: res?.data?.data?.providerAccounts ?? [],
          totalCount: res?.data?.data?.totalRows,
          pageIndex: res?.data?.data?.pageIndex,
        });
        setIsLoadingAccounts(false);

        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          tableQueryExecuterAccounts();
        }
        setTableDataAccounts({ data: [], totalCount: 0, isLoading: false });
      })
      .finally(() => {
        setIsLoadingAccounts(false);
      });
  };

  //
  // AccountHistory
  const [isModalOpenSwitchAccountHistory, setModalOpenSwitchAccountHistory] =
    useState(false);
  const [isCheckedSwitchAccountHistory, setCheckedSwitchAccountHistory] =
    useState(false);
  const [switchStatesAccountHistory, setSwitchStatesAccountHistory] = useState(
    {}
  );
  const handleSwitchChangeSwitchAccountHistory = (rowId) => {
    setModalOpenSwitchAccountHistory(true);
    setCheckedSwitchAccountHistory(rowId);
  };

  const handleModalCloseSwitchAccountHistory = () => {
    setModalOpenSwitchAccountHistory(false);
  };
  const handleModalConfirmSwitchAccountHistory = () => {
    setModalOpenSwitchAccountHistory(false);
    // setCheckedSwitchAccountHistory(!isCheckedSwitchAccountHistory);
    setSwitchStatesAccountHistory((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchAccountHistory]:
        !prevStates[isCheckedSwitchAccountHistory],
    }));
  };
  //
  // Wallet
  const [isModalOpenSwitchWallet, setModalOpenSwitchWallet] = useState(false);
  const [isCheckedSwitchWallet, setCheckedSwitchWallet] = useState(false);
  const [openManageProviders, setOpenManageProvider] = useState(false);
  const [tableDataProviders, setTableDataProviders] = useState([]);

  const [switchStatesWallet, setSwitchStatesWallet] = useState({});
  const handleSwitchChangeSwitchWallet = (rowId) => {
    setModalOpenSwitchWallet(true);
    setCheckedSwitchWallet(rowId);
  };

  const handleModalCloseSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
  };
  const handleModalConfirmSwitchWallet = () => {
    setModalOpenSwitchWallet(false);
    // setCheckedSwitchWallet(!isCheckedSwitchAccountHistory);
    setSwitchStatesWallet((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchWallet]: !prevStates[isCheckedSwitchWallet],
    }));
  };
  //

  const [Zone, setZone] = React.useState("");

  const handleChangeZone = (event) => {
    setZone(event.target.value);
  };
  const [Rates, setRates] = React.useState("");

  const handleChangeRates = (event) => {
    setRates(event.target.value);
  };

  let columnsAccounts = [
    {
      field: "accountNumber",
      headerName: "Account Number",
      minWidth: 100,

      flex: 1,
    },
    {
      field: "lastRecharge",
      headerName: "Last Recharge",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "currentBalance",
      headerName: "Current Balance",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "previousBalance",
      headerName: "Previous Balance",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "lockedBalance",
      headerName: "Locked Balance",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "balanceLimit",
      headerName: "Balance Limit",
      flex: 1,
      minWidth: 100,
    },
  ];
  let columnsProviders = [
    // {
    //   field: "username",
    //   headerName: "Username",
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "FullName",
      headerName: "Full Name",
      minWidth: 100,

      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        return `${row?.firstName} ${row?.lastName}`;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString()); // Sorting strings
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 180,
    },

    {
      field: "telephoneNumber",
      headerName: "Telephone",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => {
        const row = params.row;
        const category =
        categoriesOption!==false&&
          categoriesOption?.length !== 0 &&
          categoriesOption?.find(
            (x) => x.value === row?.providerCategoryRecordGuid
          );
        return category ? category.label : "";
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
  ];

  const tableDataAccountsCategory = [
    {
      id: 1,
      name: "Test category With Account 1",
      description: "Test category With Account Description 1",
    },
    {
      id: 2,
      name: "Test category With Account 2",
      description: "Test category With Account Description 2",
    },
  ];
  let columnsAccountsCategory = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const tableDataAccountsStatus = [
    {
      id: 1,
      name: "Test status With Account 1",
      description: "Test status With Account Description 1",
    },
    {
      id: 2,
      name: "Test status With Account 2",
      description: "Test status With Account Description 2",
    },
  ];
  let columnsAccountsStatus = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const tableDataRates = [
    {
      id: 1,
      ProviderCategory: "Default",
      countryCode: "961",
      country: "Lebanon",
      operator: "Alfa",
      rate: "0.2 USD",
    },
  ];
  let columnsRates = [
    {
      field: "ProviderCategory",
      headerName: "Provider Without Account",
      flex: 1,
    },
    {
      headerName: "Country ISO",
      field: "countryCode",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];

  const tableDataCosts = [
    {
      id: 1,
      providerCategory: "providerCategory",
      countryCode: "961",
      country: "Lebanon",
      operator: "operator",
      cost: "U.S",
    },
  ];
  let columnsCosts = [
    {
      field: "providerCategory",
      headerName: "Provider Without Account",
      flex: 1,
    },
    {
      field: "countryCode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Cost",
      field: "cost",
      flex: 1,
    },
  ];

  const tableDataWallet = [
    {
      id: 1,
      ProviderCategory: "ProviderCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];
  let columnsWallet = [
    {
      field: "ProviderCategory",
      headerName: "Provider",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];
  let columnsProviderCategory = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        const rowData = params?.row;
        return rowData?.providerCategoryDetails[0]?.name || "";
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
    {
      field: "Description",
      headerName: "Description",
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => {
        const rowData = params?.row;
        return rowData?.providerCategoryDetails[0]?.description || "";
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return v1?.toString().localeCompare(v2?.toString());
      },
    },
  ];

  const tableDataAccountHistory = [
    {
      id: 1,
      ProviderCategory: "ProviderCategory",
      countrycode: "961",
      country: "leabanon",
      operator: "operator",
      rate: "12312",
    },
  ];
  let columnsAccountHistory = [
    {
      field: "ProviderCategory",
      headerName: "Provider Without Account",
      flex: 1,
    },
    {
      field: "countrycode",
      headerName: "Country ISO",
      flex: 1,
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
    },
    {
      headerName: "Operator",
      field: "operator",
      flex: 1,
    },
    {
      headerName: "Rate",
      field: "rate",
      flex: 1,
    },
  ];

  const tabs = [
    {
      label: "Providers",
    },
    {
      label: "Provider Category",
    },
    // {
    //   label: "Accounts",
    // },
    // {
    //   label: "History",
    // },
    // {
    //   label: "Ledger",
    // },
  ];
  const tabsHorizantal = [
    {
      label: "With Account",
    },
    {
      label: "Without Account",
    },
  ];

  // Delete Modal Accounts functions
  const [ConfirmOpenAccounts, setConfirmOpenAccounts] = useState(false);
  const [openViewProvider, setOpenViewProvider] = useState(false);

  const [modalContentProviders, setModalContentProviders] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const [ConfirmOpenProviders, setConfirmOpenProviders] = useState(false);

  const [modalContentAccounts, setModalContentAccounts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalAccounts = (title, text, onButtonClick) => {
    setModalContentAccounts({ title, text, onButtonClick });
    setConfirmOpenAccounts(true);
  };
  const OpenConfirmModalProviders = (data) => {
    setSelectedProvider(data);
    setConfirmOpenProviders(true);
  };
  const handleConfirmModalAccounts = () => {
    setConfirmOpenAccounts(false);
  };
  const handleConfirmModalProviders = () => {
    setConfirmOpenProviders(false);
  };

  //

  // Delete Modal Rates functions
  const [ConfirmOpenRates, setConfirmOpenRates] = useState(false);
  const [editProviderModal, setOpenEditProviderModal] = useState(false);

  const [openAdvancedSearchGroup, setOpenAdvancedSearchGroup] = useState(true);

  const [modalContentRates, setModalContentRates] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalRates = (title, text, onButtonClick) => {
    setModalContentRates({ title, text, onButtonClick });
    setConfirmOpenRates(true);
  };

  const handleConfirmModalRates = () => {
    setConfirmOpenRates(false);
  };

  //
  // Delete Modal Costs functions
  const [ConfirmOpenCosts, setConfirmOpenCosts] = useState(false);
  const [modalContentCosts, setModalContentCosts] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalCosts = (title, text, onButtonClick) => {
    setModalContentCosts({ title, text, onButtonClick });
    setConfirmOpenCosts(true);
  };

  const handleConfirmModalCosts = () => {
    setConfirmOpenCosts(false);
  };

  //
  // Delete Modal Wallet functions
  const [ConfirmOpenWallet, setConfirmOpenWallet] = useState(false);
  const [modalContentWallet, setModalContentWallet] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalWallet = (data, title, text, onButtonClick) => {
    setSelectedProviderCategory(data);
    setConfirmOpenWallet(true);
  };

  const handleConfirmModalWallet = () => {
    setConfirmOpenWallet(false);
  };

  //
  // Delete Modal AccountHistory functions
  const [ConfirmOpenAccountHistory, setConfirmOpenAccountHistory] =
    useState(false);
  const [modalContentAccountHistory, setModalContentAccountHistory] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalAccountHistory = (title, text, onButtonClick) => {
    setModalContentAccountHistory({ title, text, onButtonClick });
    setConfirmOpenAccountHistory(true);
  };

  const handleConfirmModalAccountHistory = () => {
    setConfirmOpenAccountHistory(false);
  };
  //
  const [newProvider, setNewProvider] = useState(false);

  // Manage Accounts
  const [MangeAccountsAddopen, setMangeAccountsAddopen] = useState(false);
  const [MangeAccountsEditopen, setMangeAccountsEditopen] = useState(false);
  const [openAddProviderModal, setOpenAddProviderModal] = useState(false);
  const [MangeProvidersEditopen, setMangeProvidersEditopen] = useState(false);

  const handleAddMangeAccountsopen = () => {
    setMangeAccountsAddopen(true);
  };
  const handleAddProviderOpen = () => {
    setOpenAddProviderModal(true);
  };
  const handleEditProviderOpen = (data) => {
    // navigate("/new-provider");
    // localStorage.setItem("selectedTab", "new-provider");
    // localStorage.setItem("recordGuid", data?.recordGuid);
    // localStorage.setItem("username", data?.username);
    setManageEditOpen(true);
    setSelectedProvider(data);

    // localStorage.setItem("goToNextPage", "true");

    // setOpenEditProviderModal(true);
  };
  const [manageConfigOpen, setManageConfigOpen] = useState(false);
  const [manageEditOpen, setManageEditOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [selectedProviderCategory, setSelectedProviderCategory] = useState({});

  const handleManageConfig = (data) => {
    setManageConfigOpen(true);
    setSelectedProvider(data);
    // navigate("/new-provider");
    // localStorage.setItem("selectedTab", "new-provider");
    // localStorage.setItem("recordGuid", data?.recordGuid);
    // localStorage.setItem("goToNextPage", "true");
    // localStorage.setItem("username", data?.username);

    // setOpenEditProviderModal(true);
  };

  const [unAssignCategoryOpen, setUnAssignCategoryOpen] = useState(false);
  const [selectedProviderUnAss, setSelectedProvideUnAss] = useState({});
  const [unAssignCategoryMultiOpen, setUnAssignCategoryMultiOpen] =
    useState(false);

  const unAssignFunction = (data) => {
    setUnAssignCategoryOpen(true);
    setSelectedProvideUnAss(data);
  };
  const unAssignFctMulti = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };

    let dataProvider = rowsSelectedData?.map((x) => x.recordGuid);

    let data = {
      ProviderIds: dataProvider,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${UNASSIGN_CATEGORY_PROVIDER}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully unassign !", type: "success" });

        setUnAssignCategoryMultiOpen(false);
        setPaginationModelProviders({
          page: paginationModelProviders?.page
            ? paginationModelProviders?.page
            : 0,
          pageSize: paginationModelProviders?.pageSize
            ? paginationModelProviders?.pageSize
            : 10,
        });
      })
      .catch((error) => {
        setCategory("");

        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const unAssignFct = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };

    let data = {
      ProviderIds: [selectedProviderUnAss?.recordGuid],
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${UNASSIGN_CATEGORY_PROVIDER}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully unassign !", type: "success" });

        setUnAssignCategoryOpen(false);
        setPaginationModelProviders({
          page: paginationModelProviders?.page
            ? paginationModelProviders?.page
            : 0,
          pageSize: paginationModelProviders?.pageSize
            ? paginationModelProviders?.pageSize
            : 10,
        });
      })
      .catch((error) => {
        setCategory("");

        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const handleManageConfigClose = (data) => {
    setManageConfigOpen(false);
    setSelectedProvider({});
  };
  const handleEditProviderClose = (data) => {
    setManageEditOpen(false);
    setSelectedProvider({});
  };
  const [openPopupSetLogin, setOpenPopupSetLogin] = useState(false);
  const [openPopupResetPass, setOpenPopupResetPass] = useState(false);

  const [usernameLogin, setUsernameLogin] = useState("");
  const [pin, setPin] = useState("");
  const [newPass, setNewPass] = useState("");
  const [username, setUsername] = useState("");
  const [providerData, setProviderData] = useState({});

  const [pass, setPass] = useState("");

  const handleSetLoginConfig = (data) => {
    setOpenPopupSetLogin(!openPopupSetLogin);
    setProviderData(data);
    setUsername(data?.username);
    setUsernameLogin(data?.username);
    // setOpenEditProviderModal(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const handleResetPass = (data) => {
    setUsername(data?.username);
    setUsernameLogin(data?.username);

    setOpenPopupResetPass(!openPopupResetPass);
    // setOpenEditProviderModal(true);
  };

  const handleGetPin = (data) => {
    setIsLoadingSentMail(true);
    setUsername(data?.username);
    setUsernameLogin(data?.username);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let dataForm = {
      UserName: data?.username,
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]}${GET_PIN}`,
        dataForm,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenPopupResetPass(true);
        setIsLoadingSentMail(false);

        setOpenPopupResetPass(!openPopupResetPass);
        // setGoToConfigPage(true);
        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((error) => {
        setIsLoadingSentMail(false);

        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const submitResetPass = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      UserName: usernameLogin,
      Pin: pin,
      NewPassword: newPass,
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${RESET_PASSWORD}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // setGoToConfigPage(true);
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully reseted !", type: "success" });
        setOpenPopupResetPass(false);
        setUsernameLogin("");
        setPin("");
        setNewPass("");
        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((error) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
          type: "error",
        });
      });
  };

  const submitSetLogin = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Username: username,
      Password: pass,
      ProviderGuid: providerData?.recordGuid,
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${newServices["member"]
        }${SET_LOGIN}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        // setGoToConfigPage(true);
        setOpenMessageAlert(true);
        setMessageData({
          message: "Successfully set login !",
          type: "success",
        });
        setUsername("");
        setPass("");
        setPaginationModelProviders({
          page: paginationModelProviders?.page
            ? paginationModelProviders?.page
            : 0,
          pageSize: paginationModelProviders?.pageSize
            ? paginationModelProviders?.pageSize
            : 10,
        });
        setOpenPopupSetLogin(false);

        // setGoBack(true);
        // setTimeout(setOpenAddClientModal(false), 4000);
        // setRefreshTableClient(true);
      })
      .catch((error) => {
        setOpenMessageAlert(true);
        setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
              ? error?.response?.data?.Exception
              : error?.response?.data?.message,
          type: "error",
        });
      });
  };
  const handleAddMangeAccountsclose = () => {
    setMangeAccountsAddopen(false);
  };
  const handleEditMangeAccountsopen = () => {
    setMangeAccountsEditopen(true);
  };
  const handleEditMangeProvidersOpen = () => {
    setMangeProvidersEditopen(true);
  };

  const handleEditMangeAccountsclose = () => {
    setMangeAccountsEditopen(false);
  };

  // Manage Rates
  const [MangeRatesAddopen, setMangeRatesAddopen] = useState(false);
  const [MangeRatesEditopen, setMangeRatesEditopen] = useState(false);

  const handleAddMangeRatesopen = () => {
    setMangeRatesAddopen(true);
  };

  const handleAddMangeRatesclose = () => {
    setMangeRatesAddopen(false);
  };
  const handleEditMangeRatesopen = () => {
    setMangeRatesEditopen(true);
  };

  const handleEditMangeRatesclose = () => {
    setMangeRatesEditopen(false);
  };

  // Manage Costs
  const [MangeProviderCategoryopen, setMangeProviderCategoryopen] =
    useState(false);
  const [ManageProviderCategoryEditOpen, setManageProviderCategoryEditOpen] =
    useState(false);

  const handleAddMangeProviderCategoryopen = () => {
    setMangeProviderCategoryopen(true);
  };

  const handleAddMangeCostsclose = () => {
    setMangeProviderCategoryopen(false);
  };
  const handleEditMangeCostsopen = (data) => {
    setSelectedProviderCategory(data);
    setManageProviderCategoryEditOpen(true);
  };

  const handleEditMangeCostsclose = () => {
    setManageProviderCategoryEditOpen(false);
  };

  // Manage Wallet
  const [MangeWalletAddopen, setMangeWalletAddopen] = useState(false);
  const [MangeWalletEditopen, setMangeWalletEditopen] = useState(false);

  const handleAddMangeWalletopen = () => {
    setMangeWalletAddopen(true);
  };

  const handleAddMangeWalletclose = () => {
    setMangeWalletAddopen(false);
  };
  const handleEditMangeWalletopen = () => {
    setMangeWalletEditopen(true);
  };

  const handleEditMangeWalletclose = () => {
    setMangeWalletEditopen(false);
  };

  // Manage AccountHistory
  const [MangeAccountHistoryAddopen, setMangeAccountHistoryAddopen] =
    useState(false);
  const [MangeAccountHistoryEditopen, setMangeAccountHistoryEditopen] =
    useState(false);

  const handleAddMangeAccountHistoryopen = () => {
    setMangeAccountHistoryAddopen(true);
  };

  const handleAddMangeAccountHistoryclose = () => {
    setMangeAccountHistoryAddopen(false);
  };
  const handleEditMangeAccountHistoryopen = () => {
    setMangeAccountHistoryEditopen(true);
  };

  const handleEditMangeAccountHistoryclose = () => {
    setMangeAccountHistoryEditopen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleOpenViewProvider = () => {
    setOpenViewProvider(!openViewProvider);
  };
  const handleOpenAdvancedSearchGroup = () => {
    setOpenAdvancedSearchGroup(!openAdvancedSearchGroup);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setPaginationModelProviders({
      page: 0,
      pageSize: 10,
    });
    tableQueryExecuterProviders();
  }, [manageEditOpen, manageConfigOpen, newProvider]);
  useEffect(() => {
    setPaginationModelProviders({
      page: 0,
      pageSize: paginationModelProviders?.pageSize
        ? paginationModelProviders?.pageSize
        : 10,
    });
    tableQueryExecuterProviders();
  }, [searchProvider, providerCategorySearch]);

  useEffect(() => {
    setPaginationModelProvidersCategory({
      page: 0,
      pageSize: paginationModelProvidersCategory?.pageSize
        ? paginationModelProvidersCategory?.pageSize
        : 10,
    });
    tableQueryExecuterProvidersCategory();
  }, [searchProviderCategory]);
  return (
    <>
      {!manageConfigOpen && !manageEditOpen && !newProvider && (
        <div className="billing">
          <Grid container>
            <Grid item xs={6} md={6} className="centerresponsive">
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <Typography className="BreadcrumbsPage">Management</Typography>
                <Typography className="breadcrumbactiveBtn">
                  {activeBtn}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              // sx={{ display: 'flex', justifyContent:'end'}}
              item
              xs={6}
              md={6}
              className="end"
            >
              {/* {activeBtn === "Location" && (
            <Button className="contained" onClick={getAddLocationFunction()}>
              <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
              <Typography sx={{ fontSize: "14px" }}>
                {`Add ${selectedFilterLocation}`}
              </Typography>
            </Button>
          )} */}
              {activeBtn === "Provider Category" && (
                <Button
                  className="contained"
                  onClick={() => handleAddMangeProviderCategoryopen()}
                >
                  <Add sx={{ fontSize: "17px", marginRight: "3px" }} />
                  <Typography sx={{ fontSize: "14px" }}>{`Add`}</Typography>
                </Button>
              )}
              {activeBtn === "Providers" && windowWidth > 1000 ? (<>
                <Button
                  variant="contained"
                  className="contained"
                  onClick={() => setAssignProviderCategory(true)}
                  style={{
                    transition: "all .2s",
                    marginRight: "8px",
                    opacity: selectedRows.length === 0 ? "0" : "1",
                    visibility: selectedRows.length === 0 && "hidden",
                  }}
                >
                  <TouchAppIcon sx={{ fontSize: "17px" }} /> &nbsp;
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginRight: "3px",
                      padding: "2px",
                    }}
                  >
                    Assign
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  className="contained"
                  onClick={() => setUnAssignCategoryMultiOpen(true)}
                  style={{
                    transition: "all .2s",
                    marginRight: "8px",
                    opacity: selectedRows.length === 0 ? "0" : "1",
                    visibility: selectedRows.length === 0 && "hidden",
                  }}
                >
                  <SwipeDownIcon sx={{ fontSize: "17px" }} /> &nbsp;
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginRight: "3px",
                      padding: "2px",
                    }}
                  >
                    Unassign
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  className="contained add-route-btn"
                  onClick={() => {
                    setNewProvider(true);
                  }}
                >
                  <Add sx={{ fontSize: "17px" }} /> &nbsp;
                  <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
                    New Provider
                  </Typography>
                </Button>
              </>
              )
                : activeBtn === "Providers" && windowWidth < 1000 &&
                <Button
                  variant="contained"
                  className="contained add-route-btn"
                  onClick={() => {
                    setNewProvider(true);
                  }}
                >
                  <Add sx={{ fontSize: "17px" }} /> &nbsp;
                  <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
                    New Provider
                  </Typography>
                </Button>

              }
            </Grid>

            {activeBtn === "Providers" && windowWidth < 1000 && 
            <Grid container className="end" marginTop={2}>
             <Button
             variant="contained"
             className="contained"
             onClick={() => setAssignProviderCategory(true)}
             style={{
               transition: "all .2s",
               marginRight: "8px",
               opacity: selectedRows.length === 0 ? "0" : "1",
               visibility: selectedRows.length === 0 && "hidden",
             }}
           >
             <TouchAppIcon sx={{ fontSize: "17px" }} /> &nbsp;
             <Typography
               sx={{
                 fontSize: "14px",
                 marginRight: "3px",
                 padding: "2px",
               }}
             >
               Assign
             </Typography>
           </Button>
           <Button
             variant="contained"
             className="contained"
             onClick={() => setUnAssignCategoryMultiOpen(true)}
             style={{
               transition: "all .2s",
               marginRight: "8px",
               opacity: selectedRows.length === 0 ? "0" : "1",
               visibility: selectedRows.length === 0 && "hidden",
             }}
           >
             <SwipeDownIcon sx={{ fontSize: "17px" }} /> &nbsp;
             <Typography
               sx={{
                 fontSize: "14px",
                 marginRight: "3px",
                 padding: "2px",
               }}
             >
               Unassign
             </Typography>
           </Button>
           </Grid>
            }
          </Grid>
          {windowWidth < 1000 && (
            <Grid
              style={{ marginBottom: "-38px", width: windowWidth < 500 ? '280px' : '380px' }}
              container
              // marginLeft={2}
              marginTop={5}
            >
              <HorizontalTabsSubtabs
                activeBtn={activeBtn}
                tabs={tabs}
                setActiveBtn={setActiveBtn}
              />            </Grid>
          )}
          {windowWidth > 1000 ? (
            <Grid container className="Top-spacing div-table">
              <Grid
                item
                xs={3.5}
                md={1.5}
                lg={2}
                className="vertical-grid"
                sx={{ paddingLeft: "2px" }}
              >
                <VerticalTabs
                  activeBtn={activeBtn}
                  tabs={tabs}
                  setActiveBtn={setActiveBtn}
                />
              </Grid>
              <Grid item xs={8.5} md={10.5} lg={10}>
                {activeBtn === "Accounts" && (
                  <>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid xs={12}>
                        {ActiveBtnHorizantal === "With Account" && (
                          <Card variant="CardTable">
                            <Table
                              rowCountState={tableDataAccounts?.totalCount}
                              data={tableDataAccounts}
                              // isLoading={isLoading}
                              columns={columnsAccounts}
                              pageIndex={
                                tableDataAccounts?.pageIndex
                                  ? tableDataAccounts?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModel}
                              paginationModel={paginationModel}
                              // enableDelete={true}
                              // enableEdit={true}
                              // deleteFunction={OpenConfirmModalAccounts}
                              // editFunction={handleEditMangeAccountsopen}
                              enableSearch={true}
                              enableExportBtn={true}
                              // disableActions={true}
                              // enableMore={true}
                              // moreFunction={handleOpenViewProvider}
                              advancedSearchFunction={
                                handleOpenAdvancedSearchGroup
                              }
                              advancedSearch={openAdvancedSearchGroup}
                              titleAdvancedSearch={"Advanced Search"}
                              contentAdvancedSearch={
                                <Grid spacing={3} container>
                                  <Grid lg={3} sm={4} xs={12} item>
                                    <SelectForm
                                      placeholder={"Provider Type"}
                                      value={ProviderType}
                                      setValue={setProviderType}
                                      options={ProviderTypeOptions}
                                    />
                                  </Grid>
                                  <Grid lg={3} sm={4} xs={12} item>
                                    <SelectForm
                                      placeholder={"Provider"}
                                      value={provider}
                                      setValue={setProvider}
                                      options={providerOptions}
                                    />
                                  </Grid>
                                  <Grid lg={3} sm={4} xs={12} item>
                                    <SelectForm
                                      placeholder={"Account Type"}
                                      value={country}
                                      setValue={setCountry}
                                      options={CountriesOptions}
                                    />{" "}
                                  </Grid>
                                  <Grid lg={3} sm={4} xs={12} item>
                                    <TextFieldForm
                                      value={date}
                                      setValue={setDate}
                                      placeholder={"Date"}
                                    />
                                  </Grid>
                                </Grid>
                              }
                            />
                          </Card>
                        )}

                        {ActiveBtnHorizantal === "Without Account" && (
                          <Paper variant="CardTable">
                            <Table
                              rowCountState={
                                tableDataAccountsCategory?.totalCount
                              }
                              data={tableDataAccountsCategory}
                              // isLoading={isLoading}
                              columns={columnsAccountsCategory}
                              pageIndex={
                                tableDataAccountsCategory?.pageIndex
                                  ? tableDataAccountsCategory?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModel}
                              paginationModel={paginationModel}
                              // enableDelete={true}
                              // enableEdit={true}
                              // deleteFunction={OpenConfirmModalAccounts}
                              // editFunction={handleEditMangeAccountsopen}
                              enableSearch={true}
                              enableExportBtn={true}
                              enableMore={true}
                              // disableActions={true}
                              moreFunction={handleOpenViewProvider}
                            />
                          </Paper>
                        )}

                        {ActiveBtnHorizantal === "Status" && (
                          <Paper variant="CardTable">
                            <Table
                              rowCountState={
                                tableDataAccountsStatus?.totalCount
                              }
                              data={tableDataAccountsStatus}
                              // isLoading={isLoading}
                              columns={columnsAccountsStatus}
                              pageIndex={
                                tableDataAccountsStatus?.pageIndex
                                  ? tableDataAccountsStatus?.pageIndex
                                  : 0
                              }
                              setPaginationModel={setPaginationModel}
                              paginationModel={paginationModel}
                              // enableDelete={true}
                              // enableEdit={true}
                              // deleteFunction={OpenConfirmModalAccounts}
                              // editFunction={handleEditMangeAccountsopen}
                              enableSearch={true}
                              enableExportBtn={true}
                              enableMore={true}
                              moreFunction={handleOpenViewProvider}
                            // disableActions={true}
                            />
                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
                {activeBtn === "Providers" && (
                  <>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={isLoadingSentMail}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid xs={12}>
                        <Card variant="CardTable">
                          {/* providers gird */}
                          <Table
                            enableAssign={true}
                            assignFunction={(data) => {
                              setAssignProviderCategoryOne(true);
                              setSelectedProvider(data);
                            }}
                            unAssignFunction={unAssignFunction}
                            setRowsSelectedData={setRowsSelectedData}
                            rowsSelectedData={rowsSelectedData}
                            enableCheckboxSelection={true}
                            setSearch={setSearchProvider}
                            largeActions={true}
                            enableSetLogin={true}
                            enableManageConfig={true}
                            enableResetPassword={true}
                            manageConfigFunction={handleManageConfig}
                            loginFunction={handleSetLoginConfig}
                            resetPasswordFunction={handleGetPin}
                            rowCountState={tableDataProviders?.totalCount}
                            data={tableDataProviders?.data}
                            isLoading={isLoading}
                            enableFilter2={true}
                            valueFilter={providerCategorySearch}
                            setValueFilter={setProviderCategorySearch}
                            optionsFilter={
                              categoriesOption 
                            }
                            placeholderFilter="Category"
                            columns={columnsProviders}
                            enableShow={true}
                            showFunction={handleModalShowAccounts}
                            pageIndex={
                              tableDataProviders?.pageIndex
                                ? tableDataProviders?.pageIndex
                                : 0
                            }
                            exportFunction={exportProvider}
                            setPaginationModel={setPaginationModelProviders}
                            paginationModel={paginationModelProviders}
                            enableDelete={true}
                            enableEdit={true}
                            deleteFunction={OpenConfirmModalProviders}
                            editFunction={handleEditProviderOpen}
                            enableSearch={true}
                            enableExportBtn={true}
                            setSelectedRows={setSelectedRows}
                            selectedRows={selectedRows}
                            // enableMore={true}
                            // moreFunction={handleOpenViewProvider}

                            // disableActions={true}
                            titleAdvancedSearch={"Advanced Search"}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                )}
                {activeBtn === "Ledger" && (
                  <>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid item xs={12}>
                        <Paper variant="CardTable">
                          <Table
                            rowCountState={tableDataRates?.totalCount}
                            data={tableDataRates}
                            // isLoading={isLoading}
                            columns={columnsRates}
                            pageIndex={
                              tableDataRates?.pageIndex
                                ? tableDataRates?.pageIndex
                                : 0
                            }
                            setPaginationModel={setPaginationModel}
                            paginationModel={paginationModel}
                            enableDelete={true}
                            enableEdit={true}
                            deleteFunction={OpenConfirmModalRates}
                            editFunction={handleEditMangeRatesopen}
                            enableSearch={true}
                            enableImportBtn={true}
                            enableExportBtn={true}
                          // disableActions={true}
                          // enableMore={true}
                          // moreFunction={handleOpenViewProvider}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                )}

                {activeBtn === "Costs" && (
                  <Paper
                    variant="CardTable"
                    sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                  >
                    <Table
                      rowCountState={tableDataCosts?.totalCount}
                      data={tableDataCosts}
                      // isLoading={isLoading}
                      columns={columnsCosts}
                      pageIndex={
                        tableDataCosts?.pageIndex
                          ? tableDataCosts?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModel}
                      paginationModel={paginationModel}
                      enableDelete={true}
                      enableEdit={true}
                      enableExportBtn={true}
                      deleteFunction={OpenConfirmModalCosts}
                      editFunction={handleEditMangeCostsopen}
                      enableSearch={true}
                      enableFilter={true}
                      enableImportBtn={true}
                      valueFilter={Zone}
                      setValueFilter={setZone}
                      // disableActions={true}
                      optionsFilter={["All"]}
                      placeholderFilter="Provider Without Account"
                    // enableMore={true}
                    // moreFunction={handleOpenViewProvider}
                    />
                  </Paper>
                )}
                {activeBtn === "Provider Category" && (
                  <Paper
                    variant="CardTable"
                    sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                  >
                    <Table
                      rowCountState={tableDataProvidersCategory?.totalCount}
                      data={tableDataProvidersCategory?.data}
                      isLoading={isLoading}
                      columns={columnsProviderCategory}
                      pageIndex={
                        tableDataProvidersCategory?.pageIndex
                          ? tableDataProvidersCategory?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModelProvidersCategory}
                      // disableActions={true}
                      paginationModel={paginationModelProvidersCategory}
                      enableDelete={true}
                      enableEdit={true}
                      enableExportBtn={true}
                      exportFunction={exportProviderCategory}
                      // enableImportBtn={true}
                      deleteFunction={OpenConfirmModalWallet}
                      editFunction={handleEditMangeCostsopen}
                      enableSearch={true}
                      setSearch={setSearchProviderCategory}
                    // valueFilter={Rates}
                    // setValueFilter={setRates}
                    // optionsFilter={["All", "Default", "vip"]}
                    // placeholderFilter="Provider Without Account"
                    // enableMore={true}
                    // moreFunction={handleOpenViewProvider}
                    />
                  </Paper>
                )}
                {activeBtn === "History" && (
                  <>
                    {/* <Grid container>
                    <Grid xs={12} item>
                      <HorizantalTabs
                        tabs={tabsHorizantal}
                        setActiveBtn={setActiveBtnHorizantal}
                      />
                    </Grid>
                  </Grid> */}
                    <Paper
                      variant="CardTable"
                      sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                    >
                      <Table
                        rowCountState={tableDataAccountHistory?.totalCount}
                        data={tableDataAccountHistory}
                        columns={columnsAccountHistory}
                        pageIndex={
                          tableDataAccountHistory?.pageIndex
                            ? tableDataAccountHistory?.pageIndex
                            : 0
                        }
                        setPaginationModel={setPaginationModel}
                        // disableActions={true}
                        paginationModel={paginationModel}
                        enableDelete={true}
                        enableEdit={true}
                        enableExportBtn={true}
                        deleteFunction={OpenConfirmModalAccountHistory}
                        editFunction={handleEditMangeAccountHistoryopen}
                        enableSearch={true}
                        valueFilter={Rates}
                        enableImportBtn={true}
                        setValueFilter={setRates}
                        optionsFilter={["All", "Default", "vip"]}
                        placeholderFilter="Provider Without Account"
                      // enableMore={true}
                      // moreFunction={handleOpenViewProvider}
                      />
                    </Paper>
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid xs={12} md={10} lg={10}>
              {activeBtn === "Accounts" && (
                <>
                  <Grid container sx={{ marginTop: "20px" }}>
                    <Grid xs={12}>
                      {ActiveBtnHorizantal === "With Account" && (
                        <Card variant="CardTable">
                          <Table
                            rowCountState={tableDataAccounts?.totalCount}
                            data={tableDataAccounts}
                            // isLoading={isLoading}
                            columns={columnsAccounts}
                            pageIndex={
                              tableDataAccounts?.pageIndex
                                ? tableDataAccounts?.pageIndex
                                : 0
                            }
                            setPaginationModel={setPaginationModel}
                            paginationModel={paginationModel}
                            // enableDelete={true}
                            // enableEdit={true}
                            // deleteFunction={OpenConfirmModalAccounts}
                            // editFunction={handleEditMangeAccountsopen}
                            enableSearch={true}
                            enableExportBtn={true}
                            // disableActions={true}
                            // enableMore={true}
                            // moreFunction={handleOpenViewProvider}
                            advancedSearchFunction={
                              handleOpenAdvancedSearchGroup
                            }
                            advancedSearch={openAdvancedSearchGroup}
                            titleAdvancedSearch={"Advanced Search"}
                            contentAdvancedSearch={
                              <Grid spacing={3} container>
                                <Grid xs={3} item>
                                  <SelectForm
                                    placeholder={"Provider Type"}
                                    value={ProviderType}
                                    setValue={setProviderType}
                                    options={ProviderTypeOptions}
                                  />
                                </Grid>
                                <Grid xs={3} item>
                                  <SelectForm
                                    placeholder={"Provider"}
                                    value={provider}
                                    setValue={setProvider}
                                    options={providerOptions}
                                  />
                                </Grid>
                                <Grid xs={3} item>
                                  <SelectForm
                                    placeholder={"Account Type"}
                                    value={country}
                                    setValue={setCountry}
                                    options={CountriesOptions}
                                  />{" "}
                                </Grid>
                                <Grid xs={3} item>
                                  <TextFieldForm
                                    value={date}
                                    setValue={setDate}
                                    placeholder={"Date"}
                                  />
                                </Grid>
                              </Grid>
                            }
                          />
                        </Card>
                      )}

                      {ActiveBtnHorizantal === "Without Account" && (
                        <Paper variant="CardTable">
                          <Table
                            rowCountState={
                              tableDataAccountsCategory?.totalCount
                            }
                            data={tableDataAccountsCategory}
                            // isLoading={isLoading}
                            columns={columnsAccountsCategory}
                            pageIndex={
                              tableDataAccountsCategory?.pageIndex
                                ? tableDataAccountsCategory?.pageIndex
                                : 0
                            }
                            setPaginationModel={setPaginationModel}
                            paginationModel={paginationModel}
                            // enableDelete={true}
                            // enableEdit={true}
                            // deleteFunction={OpenConfirmModalAccounts}
                            // editFunction={handleEditMangeAccountsopen}
                            enableSearch={true}
                            enableExportBtn={true}
                            enableMore={true}
                            // disableActions={true}
                            moreFunction={handleOpenViewProvider}
                          />
                        </Paper>
                      )}

                      {ActiveBtnHorizantal === "Status" && (
                        <Paper variant="CardTable">
                          <Table
                            rowCountState={tableDataAccountsStatus?.totalCount}
                            data={tableDataAccountsStatus}
                            // isLoading={isLoading}
                            columns={columnsAccountsStatus}
                            pageIndex={
                              tableDataAccountsStatus?.pageIndex
                                ? tableDataAccountsStatus?.pageIndex
                                : 0
                            }
                            setPaginationModel={setPaginationModel}
                            paginationModel={paginationModel}
                            // enableDelete={true}
                            // enableEdit={true}
                            // deleteFunction={OpenConfirmModalAccounts}
                            // editFunction={handleEditMangeAccountsopen}
                            enableSearch={true}
                            enableExportBtn={true}
                          // enableMore={true}
                          // moreFunction={handleOpenViewProvider}
                          // disableActions={true}
                          />
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {activeBtn === "Provider Category" && (
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                >
                  <Table
                    rowCountState={tableDataProvidersCategory?.totalCount}
                    data={tableDataProvidersCategory?.data}
                    isLoading={isLoading}
                    columns={columnsProviderCategory}
                    pageIndex={
                      tableDataProvidersCategory?.pageIndex
                        ? tableDataProvidersCategory?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModelProvidersCategory}
                    // disableActions={true}
                    paginationModel={paginationModelProvidersCategory}
                    enableDelete={true}
                    enableEdit={true}
                    enableExportBtn={true}
                    exportFunction={exportProviderCategory}
                    // enableImportBtn={true}
                    deleteFunction={OpenConfirmModalWallet}
                    editFunction={handleEditMangeCostsopen}
                    enableSearch={true}
                    setSearch={setSearchProviderCategory}
                  // valueFilter={Rates}
                  // setValueFilter={setRates}
                  // optionsFilter={["All", "Default", "vip"]}
                  // placeholderFilter="Provider Without Account"
                  // enableMore={true}
                  // moreFunction={handleOpenViewProvider}
                  />
                </Paper>
              )}
              {activeBtn === "Providers" && (
                <>
                  <Grid container sx={{ marginTop: "20px" }}>
                    <Grid xs={12}>
                      <Card variant="CardTable">
                      <Table
                            enableAssign={true}
                            assignFunction={(data) => {
                              setAssignProviderCategoryOne(true);
                              setSelectedProvider(data);
                            }}
                            unAssignFunction={unAssignFunction}
                            setRowsSelectedData={setRowsSelectedData}
                            rowsSelectedData={rowsSelectedData}
                            enableCheckboxSelection={true}
                            setSearch={setSearchProvider}
                            largeActions={true}
                            enableSetLogin={true}
                            enableManageConfig={true}
                            enableResetPassword={true}
                            manageConfigFunction={handleManageConfig}
                            loginFunction={handleSetLoginConfig}
                            resetPasswordFunction={handleGetPin}
                            rowCountState={tableDataProviders?.totalCount}
                            data={tableDataProviders?.data}
                            isLoading={isLoading}
                            enableFilter2={true}
                            valueFilter={providerCategorySearch}
                            setValueFilter={setProviderCategorySearch}
                            optionsFilter={
                              categoriesOption 
                            }
                            placeholderFilter="Category"
                            columns={columnsProviders}
                            enableShow={true}
                            showFunction={handleModalShowAccounts}
                            pageIndex={
                              tableDataProviders?.pageIndex
                                ? tableDataProviders?.pageIndex
                                : 0
                            }
                            exportFunction={exportProvider}
                            setPaginationModel={setPaginationModelProviders}
                            paginationModel={paginationModelProviders}
                            enableDelete={true}
                            enableEdit={true}
                            deleteFunction={OpenConfirmModalProviders}
                            editFunction={handleEditProviderOpen}
                            enableSearch={true}
                            enableExportBtn={true}
                            setSelectedRows={setSelectedRows}
                            selectedRows={selectedRows}
                            // enableMore={true}
                            // moreFunction={handleOpenViewProvider}

                            // disableActions={true}
                            titleAdvancedSearch={"Advanced Search"}
                          />
                      </Card>
                    </Grid>
                  </Grid>
                </>
              )}
              {activeBtn === "Ledger" && (
                <>
                  <Grid container sx={{ marginTop: "20px" }}>
                    <Grid item xs={12}>
                      <Paper variant="CardTable">
                        <Table
                          rowCountState={tableDataRates?.totalCount}
                          data={tableDataRates}
                          // isLoading={isLoading}
                          columns={columnsRates}
                          pageIndex={
                            tableDataRates?.pageIndex
                              ? tableDataRates?.pageIndex
                              : 0
                          }
                          setPaginationModel={setPaginationModel}
                          paginationModel={paginationModel}
                          enableDelete={true}
                          enableEdit={true}
                          deleteFunction={OpenConfirmModalRates}
                          editFunction={handleEditMangeRatesopen}
                          enableSearch={true}
                          enableImportBtn={true}
                          enableExportBtn={true}
                        // disableActions={true}
                        // enableMore={true}
                        // moreFunction={handleOpenViewProvider}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}

              {activeBtn === "Costs" && (
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                >
                  <Table
                    rowCountState={tableDataCosts?.totalCount}
                    data={tableDataCosts}
                    // isLoading={isLoading}
                    columns={columnsCosts}
                    pageIndex={
                      tableDataCosts?.pageIndex ? tableDataCosts?.pageIndex : 0
                    }
                    setPaginationModel={setPaginationModel}
                    paginationModel={paginationModel}
                    enableDelete={true}
                    enableEdit={true}
                    enableExportBtn={true}
                    deleteFunction={OpenConfirmModalCosts}
                    editFunction={handleEditMangeCostsopen}
                    enableSearch={true}
                    enableFilter={true}
                    enableImportBtn={true}
                    valueFilter={Zone}
                    setValueFilter={setZone}
                    // disableActions={true}
                    optionsFilter={["All"]}
                    placeholderFilter="Provider Without Account"
                  // enableMore={true}
                  // moreFunction={handleOpenViewProvider}
                  />
                </Paper>
              )}
              {activeBtn === "Wallet" && (
                <Paper
                  variant="CardTable"
                  sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                >
                  <Table
                    rowCountState={tableDataWallet?.totalCount}
                    data={tableDataWallet}
                    // isLoading={isLoading}
                    columns={columnsWallet}
                    pageIndex={
                      tableDataWallet?.pageIndex
                        ? tableDataWallet?.pageIndex
                        : 0
                    }
                    setPaginationModel={setPaginationModel}
                    // disableActions={true}
                    paginationModel={paginationModel}
                    enableDelete={true}
                    enableEdit={true}
                    enableExportBtn={true}
                    enableImportBtn={true}
                    deleteFunction={OpenConfirmModalWallet}
                    editFunction={handleEditMangeWalletopen}
                    enableSearch={true}
                    valueFilter={Rates}
                    setValueFilter={setRates}
                    optionsFilter={["All", "Default", "vip"]}
                    placeholderFilter="Provider Without Account"
                  // enableMore={true}
                  // moreFunction={handleOpenViewProvider}
                  />
                </Paper>
              )}
              {activeBtn === "History" && (
                <>
                  {/* <Grid container>
                    <Grid xs={12} item>
                      <HorizantalTabs
                        tabs={tabsHorizantal}
                        setActiveBtn={setActiveBtnHorizantal}
                      />
                    </Grid>
                  </Grid> */}
                  <Paper
                    variant="CardTable"
                    sx={{ borderTopLeftRadius: "20px", marginTop: "20px" }}
                  >
                    <Table
                      rowCountState={tableDataAccountHistory?.totalCount}
                      data={tableDataAccountHistory}
                      columns={columnsAccountHistory}
                      pageIndex={
                        tableDataAccountHistory?.pageIndex
                          ? tableDataAccountHistory?.pageIndex
                          : 0
                      }
                      setPaginationModel={setPaginationModel}
                      // disableActions={true}
                      paginationModel={paginationModel}
                      enableDelete={true}
                      enableEdit={true}
                      enableExportBtn={true}
                      deleteFunction={OpenConfirmModalAccountHistory}
                      editFunction={handleEditMangeAccountHistoryopen}
                      enableSearch={true}
                      valueFilter={Rates}
                      enableImportBtn={true}
                      setValueFilter={setRates}
                      optionsFilter={["All", "Default", "vip"]}
                      placeholderFilter="Provider Without Account"
                    // enableMore={true}
                    // moreFunction={handleOpenViewProvider}
                    />
                  </Paper>
                </>
              )}
            </Grid>
          )}
          {/* Delete Accounts Modal */}
          {handleConfirmModalAccounts && (
            <ConfirmationModal
              open={ConfirmOpenAccounts}
              onClose={handleConfirmModalAccounts}
              title="Delete"
              text="Are you sure you want to delete this cost?"
              onButtonClick={handleConfirmModalAccounts}
            />
          )}
          {handleConfirmModalProviders && (
            <ConfirmationModal
              open={ConfirmOpenProviders}
              onClose={handleConfirmModalProviders}
              title="Delete"
              text="Are you sure you want to delete this record?"
              onButtonClick={handleDeleteProvider}
            />
          )}
          {/* Delete Rates Modal */}
          {handleConfirmModalRates && (
            <ConfirmationModal
              open={ConfirmOpenRates}
              onClose={handleConfirmModalRates}
              title="Delete"
              text="Are you sure you want to delete this rate?"
              onButtonClick={handleConfirmModalRates}
            />
          )}
          {/* Delete Costs Modal */}
          {handleConfirmModalCosts && (
            <ConfirmationModal
              open={ConfirmOpenCosts}
              onClose={handleConfirmModalCosts}
              title="Delete"
              text="Are you sure you want to delete this cost ?"
              onButtonClick={handleConfirmModalCosts}
            />
          )}
          {/* Delete Wallet Modal */}
          {handleConfirmModalWallet && (
            <ConfirmationModal
              open={ConfirmOpenWallet}
              onClose={handleConfirmModalWallet}
              title="Delete"
              text="Are you sure you want to delete this record ?"
              onButtonClick={handleDeleteProviderCategory}
            />
          )}
          {/* Delete AccountHistory Modal */}
          {handleConfirmModalAccountHistory && (
            <ConfirmationModal
              open={ConfirmOpenAccountHistory}
              onClose={handleConfirmModalAccountHistory}
              title="Delete"
              text="Are you sure you want to delete this Taxe ?"
              onButtonClick={handleConfirmModalAccountHistory}
            />
          )}

          {/* Manage Accounts Modal */}
          {handleAddMangeAccountsopen && (
            <ManageAccounts
              open={MangeAccountsAddopen}
              setOpen={setMangeAccountsAddopen}
              title={"Add"}
            />
          )}
          {handleEditMangeAccountsopen && (
            <ManageAccounts
              open={MangeAccountsEditopen}
              setOpen={setMangeAccountsEditopen}
              title={"Edit"}
            />
          )}
          {openPopupSetLogin && (
            <CustomizedDialogs
              open={openPopupSetLogin}
              setOpen={setOpenPopupSetLogin}
              title={"Set Login"}
              secondActionBtnName={"Save"}
              // secondActionBtn={() => setGoToConfigPage(true)}
              secondActionBtn={() => {
                submitSetLogin();
              }}
              firstActionBtnName={"Cancel"}
              firstActionBtnFct={() => {
                setOpenPopupSetLogin(false);
                setUsername("");
                setPass("");
              }}
              // disableFirstBtn={
              //   !isEdit
              //     ? Country === "" || Operator === "" || Name === ""
              //     : Name === ""
              // }
              content={
                <div>
                  <TextFieldForm
                    placeholder={"Username"}
                    value={username}
                    setValue={setUsername}
                  // onChange={handleChange}
                  />
                  <PasswordInput
                    placeholder={"Password"}
                    value={pass}
                    setValue={setPass}
                    type="password"
                  />
                </div>
              }
            />
          )}

          {openPopupResetPass && (
            <CustomizedDialogs
              open={openPopupResetPass}
              setOpen={setOpenPopupResetPass}
              title={"Reset Password"}
              secondActionBtnName={"Save"}
              // secondActionBtn={() => setGoToConfigPage(true)}
              secondActionBtn={() => {
                submitResetPass();

                // setGoToConfigPage(true);
                // setShowPopup(false);
              }}
              firstActionBtnName={"Cancel"}
              firstActionBtnFct={() => {
                setOpenPopupResetPass(false);
                setUsernameLogin("");
                setPin("");
                setNewPass("");
              }}
              // disableFirstBtn={
              //   !isEdit
              //     ? Country === "" || Operator === "" || Name === ""
              //     : Name === ""
              // }
              content={
                <div>
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    A PIN has been sent to your email
                  </Typography>
                  <TextFieldForm
                    placeholder={"Pin"}
                    value={pin}
                    setValue={setPin}
                    positiveNumber={true}
                  />
                  <PasswordInput
                    placeholder={"New Password"}
                    value={newPass}
                    setValue={setNewPass}
                  />
                </div>
              }
            />
          )}

          {/* {handleAddProviderOpen && (
            <ManageProvidersModal
              open={openAddProviderModal}
              setOpen={setOpenAddProviderModal}
              title={"Add"}
            />
          )}

          {handleEditProviderOpen && (
            <ManageProvidersModal
              open={editProviderModal}
              setOpen={setOpenEditProviderModal}
              title={"Edit"}
            />
          )} */}

          {/* Manage Rates Modal */}
          {handleAddMangeRatesopen && (
            <ManageRates
              open={MangeRatesAddopen}
              setOpen={setMangeRatesAddopen}
              title={"Add"}
            />
          )}
          {handleEditMangeRatesopen && (
            <ManageRates
              open={MangeRatesEditopen}
              setOpen={setMangeRatesEditopen}
              title={"Edit"}
            />
          )}
          {/* Manage Costs Modal */}
          {handleAddMangeProviderCategoryopen && (
            <ManageProviderCategory
              open={MangeProviderCategoryopen}
              setOpen={setMangeProviderCategoryopen}
              title={"Add"}
              setPaginationModel={setPaginationModelProvidersCategory}
              paginationModel={paginationModelProvidersCategory}
            />
          )}
          {handleEditMangeCostsopen && (
            <ManageProviderCategory
              open={ManageProviderCategoryEditOpen}
              setOpen={setManageProviderCategoryEditOpen}
              title={"Edit"}
              isEdit={true}
              selectedProviderCategory={selectedProviderCategory}
              setPaginationModel={setPaginationModelProvidersCategory}
              paginationModel={paginationModelProvidersCategory}
            />
          )}

          {/* Manage Wallet Modal */}
          {handleAddMangeWalletopen && (
            <ManageWallet
              open={MangeWalletAddopen}
              setOpen={setMangeWalletAddopen}
              title={"Add"}
            />
          )}
          {handleEditMangeWalletopen && (
            <ManageWallet
              open={MangeWalletEditopen}
              setOpen={setMangeWalletEditopen}
              title={"Edit"}
            />
          )}

          {/* Manage AccountHistory Modal */}
          {handleAddMangeAccountHistoryopen && (
            <ManageAccountHistory
              open={MangeAccountHistoryAddopen}
              setOpen={setMangeAccountHistoryAddopen}
              title={"Add"}
            />
          )}
          {handleEditMangeAccountHistoryopen && (
            <ManageAccountHistory
              open={MangeAccountHistoryEditopen}
              setOpen={setMangeAccountHistoryEditopen}
              title={"Edit"}
            />
          )}

          {/* Swtich Accounts Modal */}
          {isModalOpenSwitchAccounts && (
            <ConfirmationModal
              open={isModalOpenSwitchAccounts}
              onClose={handleModalCloseSwitchAccounts}
              title="Switch Zone"
              text="Are you sure you want to Switch this Zone ?"
              onButtonClick={handleModalConfirmSwitchAccounts}
            />
          )}

          {/* Swtich Rates Modal */}
          {isModalOpenSwitchRates && (
            <ConfirmationModal
              open={isModalOpenSwitchRates}
              onClose={handleModalCloseSwitchRates}
              title="Switch Zone"
              text="Are you sure you want to Switch this Zone ?"
              onButtonClick={handleModalConfirmSwitchRates}
            />
          )}

          {/* Swtich Costs Modal */}
          {isModalOpenSwitchCosts && (
            <ConfirmationModal
              open={isModalOpenSwitchCosts}
              onClose={handleModalCloseSwitchCosts}
              title="Switch Zone"
              text="Are you sure you want to Switch this Zone ?"
              onButtonClick={handleModalConfirmSwitchCosts}
            />
          )}
          {/* Swtich Wallet Modal */}
          {isModalOpenSwitchWallet && (
            <ConfirmationModal
              open={isModalOpenSwitchWallet}
              onClose={handleModalCloseSwitchWallet}
              title="Switch Zone"
              text="Are you sure you want to Switch this Zone ?"
              onButtonClick={handleModalConfirmSwitchWallet}
            />
          )}

          {/* Swtich AccountHistory Modal */}
          {isModalOpenSwitchAccountHistory && (
            <ConfirmationModal
              open={isModalOpenSwitchAccountHistory}
              onClose={handleModalCloseSwitchAccountHistory}
              title="Switch Zone"
              text="Are you sure you want to Switch this Zone ?"
              onButtonClick={handleModalConfirmSwitchAccountHistory}
            />
          )}
        </div>
      )}

      {manageConfigOpen && (
        <NewProvider
          selectedProvider={selectedProvider}
          goToListing={handleManageConfigClose}
        />
      )}

      {manageEditOpen && (
        <NewProvider
          selectedProvider={selectedProvider}
          isEdit={true}
          goToListing={handleEditProviderClose}
        />
      )}
      {newProvider && (
        <NewProvider
          // selectedProvider={{}}
          // isEdit={true}
          isNewProvider={true}
          goToListing={() => setNewProvider(false)}
        />
      )}

      {isOpenShowAccounts && (
        <CustomizedDialogs
          open={isOpenShowAccounts}
          setOpen={setIsOpenShowAccounts}
          title={"Accounts"}
          size="lg"
          content={
            <>
              <Table
                disableHeaderColor={true}
                disableActions={true}
                rowCountState={tableDataAccounts?.totalCount}
                data={tableDataAccounts?.data}
                isLoading={isLoadingAccounts}
                columns={columnsAccounts}
                pageIndex={
                  tableDataAccounts?.pageIndex
                    ? tableDataAccounts?.pageIndex
                    : 0
                }
                setPaginationModel={setPaginationModelAccounts}
                paginationModel={paginationModelAccounts}
              />
            </>
          }
        />
      )}

      {assignProviderCategory && (
        <CustomizedDialogs
          open={assignProviderCategory}
          setOpen={setAssignProviderCategory}
          title={"Assign Category"}
          secondActionBtnName={"Save"}
          // secondActionBtn={() => setGoToConfigPage(true)}
          secondActionBtn={() => {
            assignFctMulti();

            // setGoToConfigPage(true);
            // setShowPopup(false);
          }}
          firstActionBtnName={"Cancel"}
          firstActionBtnFct={() => {
            setAssignProviderCategory(false);
            setCategory("");
          }}
          content={
            <>
              <SelectFormNew
                placementTop={true}
                placeholder={"Category"}
                value={category}
                setValue={setCategory}
                options={categoriesOption}
              />
            </>
          }
        />
      )}

      {assignProviderCategoryOne && (
        <CustomizedDialogs
          open={assignProviderCategoryOne}
          setOpen={setAssignProviderCategoryOne}
          title={"Assign Category"}
          secondActionBtnName={"Save"}
          // secondActionBtn={() => setGoToConfigPage(true)}
          secondActionBtn={() => {
            assignFct();

            // setGoToConfigPage(true);
            // setShowPopup(false);
          }}
          firstActionBtnName={"Cancel"}
          firstActionBtnFct={() => {
            setAssignProviderCategoryOne(false);
            setCategory("");
          }}
          content={
            <>
              <SelectFormNew
                placementTop={true}
                placeholder={"Category"}
                value={category}
                setValue={setCategory}
                options={categoriesOption}
              />
            </>
          }
        />
      )}

      {unAssignCategoryOpen && (
        <ConfirmationModal
          open={unAssignCategoryOpen}
          onClose={() => setUnAssignCategoryOpen(false)}
          title="Unassign Category"
          text="Are you sure you want to unassign provider from this category?"
          onButtonClick={unAssignFct}
        />
      )}
      {unAssignCategoryMultiOpen && (
        <ConfirmationModal
          open={unAssignCategoryMultiOpen}
          onClose={() => setUnAssignCategoryMultiOpen(false)}
          title="Unassign Category"
          text="Are you sure you want to unassign providers from this category?"
          onButtonClick={unAssignFctMulti}
        />
      )}
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Providers2;
