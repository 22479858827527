import {
    Grid
  } from "@mui/material";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  import { useState } from "react";
  
  const GenerateKeyModal = ({ open, setOpen, title, onButtonClick }) => {
    const [accessKeyName, setAccessKeyName] = useState("");
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Generate Key"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
            <Grid  container>
              <Grid  className="dialog-grid-spacing"  item xs={12}>
              <TextFieldForm
                  placeholder={"Access Key Name"}
                  value={accessKeyName}
                  setValue={setAccessKeyName}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default GenerateKeyModal;
  