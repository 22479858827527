import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import { MANAGE_CITY, MULTI_TENANCY_CONFIG } from "../../APIs";
import axiosInstance from "../../../axiosInstance";
import CloseIcon from "@mui/icons-material/Close";

const ManageCity = ({
  open,
  setOpen,
  title,
  isEdit,
  selectedCity,
  CountryOptions,
  setRefreshTableCity,
  setPaginationModalCity,
  paginationModalCity,
}) => {
  const [name, setName] = useState("");
  const [Country, setCountry] = useState("");
  const [switchState, setSwitchState] = useState(false);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [services, setServices] = useState(localStorage.getItem("services"));

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  useEffect(() => {
    {
      !isEdit && setName("");
      setCountry("");
    }
    {
      isEdit && setName(selectedCity?.name);
      setSwitchState(selectedCity?.isActive);
      setCountry(selectedCity&&selectedCity?.country?.recordGuid);
    }
  }, [open, isEdit]);
  useEffect(() => {
    if (isEdit && selectedCity) {
      setName(selectedCity?.name);
      setCountry(selectedCity&&selectedCity?.country?.recordGuid);
      setSwitchState(selectedCity?.isActive);
    }
  }, [selectedCity, isEdit, open]);
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      Name: name,
      CountryGuid: Country,
      IsActive: switchState,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CITY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
         setMessageData({ message: data?.message ||"Successfully Added", type: "success" });
        setPaginationModalCity({
          page: 0,
          pageSize: paginationModalCity?.pageSize
            ? paginationModalCity?.pageSize
            : 10,
        });
        setOpen(false);
        setName("");
        setCountry("");
      })
      .catch((error) => {
        setOpenMessageAlert(true);
       setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
            ? error?.response?.data?.Exception
            : error?.response?.data?.message,
          type: "error",
        });;
      });
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      recordGuid: selectedCity?.recordGuid,
      Name: name,
      IsActive: switchState,
      CountryGuid: Country,
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["configuration"]
        }${MANAGE_CITY}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Updated !", type: "success" });
        setPaginationModalCity({
          page: 0,
          pageSize: paginationModalCity?.pageSize
            ? paginationModalCity?.pageSize
            : 10,
        });
        setOpen(false);
        setCountry("");
        setName("");
        // setSmsOneWaySearch("");
      })
      .catch((error) => {
        setOpenMessageAlert(true);
       setMessageData({
          message: error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.Exception
            ? error?.response?.data?.Exception
            : error?.response?.data?.message,
          type: "error",
        });;
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={!isEdit ? submitAdd : submitEdit}
        disableFirstBtn={name === "" || Country === ""||typeof Country==="undefined"}
        content={
          <>
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <SelectFormNew
                  value={Country}
                  setValue={setCountry}
                  options={CountryOptions}
                  placeholder={"Country"}
                  disabled={CountryOptions?.length === 0}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Name"}
                  value={name}
                  setValue={setName}
                />
              </Grid>
            </Grid>

            <span style={{ color: "#B3B3B3", fontSize: "15px" }}>Status</span>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={switchState ? "Active " : "Inactive "}
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          sx={{ width: "100%" }}
          onClose={handleClose}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageCity;
