import * as Yup from "yup";
export const validationSchema = [
  Yup.object().shape({
    // TypeTag: Yup.string().required("Type is required"),
    // // BillingTag: Yup.string().required("Billing tag is required"),
    // TelephoneNumber: Yup.string().matches(
    //   /^[0-9]{8,}$/,
    //   "Telephone number must be at least 8 digits"
    // ),
    // MobileNumber: Yup.string()
    //   .matches(/^[0-9]{8,}$/, "Mobile number must be at least 8 digits")
    //   .required("Mobile Number is required"),
    // Email: Yup.string()
    //   .email("Invalid email format")
    //   .required("Email is required"),
    // FirstName: Yup.string().required("First Name is required"),
    // ContactType: Yup.string().required("Contact type is required"),
    // // CompanyWebsite: Yup.string().url("Invalid URL format"),
    // // CompanyEmail: Yup.string().email("Invalid email format"),
  }),
  Yup.object().shape({
    // Address: Yup.string().required("Address is required"),
  }),
];

export const validationSchemaAdd = [
  Yup.object().shape({
    // TypeTag: Yup.string().required("Type is required"),
    name: Yup.string().required("name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("email is required"),
    // businesswebsite: Yup.string().required("Business website is required"),
    // phone: Yup.string().required("Phone is required"),
  }),
  Yup.object().shape({
    Password: Yup.string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[!@#$%^&?*]/, "Password must contain at least one symbol")
      .min(10, "Password must be at least 10 characters long")
      .matches(/[0-9]/, "Password must contain at least one number"),
    Username: Yup.string().required("Username is required"),
    FirstName: Yup.string().required("First name is required"),

    // building: Yup.string().required("Building is required"),
    // floor: Yup.string().required("Floor is required"),
    address1: Yup.string().required("Address 1 is required"),
  }),
  Yup.object().shape({
    BillingEmail: Yup.string().email("Invalid billing email address"),
    TechnicalEmail: Yup.string().email("Invalid technical email address"),
    AlertsEmail: Yup.string().email("Invalid alerts email address"),
  }),
];

export const validationSchemaEdit = [
  Yup.object().shape({
    name: Yup.string().required("name is required"),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("email is required"),
    // businesswebsite: Yup.string().required("Business website is required"),
    // phone: Yup.string().required("Phone is required"),
  }),
  Yup.object().shape({
    // Password: Yup.string().required("Password is required"),
    // Username: Yup.string().required("Username is required"),
    FirstName: Yup.string().required("First name is required"),

    // building: Yup.string().required("Building is required"),
    // floor: Yup.string().required("Floor is required"),
    address1: Yup.string().required("Address 1 is required"),
  }),
  Yup.object().shape({
    BillingEmail: Yup.string().email("Invalid billing email address"),
    TechnicalEmail: Yup.string().email("Invalid technical email address"),
    AlertsEmail: Yup.string().email("Invalid alerts email address"),
  }),
];

export const validationSchemaLookupConfig = Yup.object().shape({
  ConnectivityTypeGuid: Yup.string().required("Connectivity Type is required"),
  ServiceLookupModeGuid: Yup.string().required(
    "Service Lookup Mode is required"
  ),
  NumberOfThreads: Yup.string().required("Number Of Threads is required"),
  Ip: Yup.string()
    .required("IP is required")
    .matches(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/, "Invalid IP address format"),
  Host: Yup.string().required("Host is required"),
  Port: Yup.string().required("Port is required"),
  QueryDns: Yup.string().required("Query DNS is required"),
});
export const validationSchemaSmtpConfig = Yup.object().shape({
  SmtpServer: Yup.string().required("Smtp Server is required"),
  SmtpUser: Yup.string().required("Smtp User is required"),
  SmtpPassword: Yup.string().required("Smtp Password is required"),
  SmtpPort: Yup.string().required("Smtp Port is required"),
  FromAddress: Yup.string().required("From Address is required"),
  ReplyAddress: Yup.string().required("Reply Address is required"),
});
export const validationSchemaWhatsappConfig = Yup.object().shape({
  WhatsappToken: Yup.string().required("Whatsapp Token is required"),
  OwnerBusinessId: Yup.string().required("Owner Business Id  is required"),
  OwnerBusinessName: Yup.string().required("Owner Business Name is required"),
  CampaignLimit: Yup.string().required("Campaign Limit is required"),
});
export const validationSchemaSmppConfig = Yup.object().shape({
  Username: Yup.string().required("Username is required"),
  Password: Yup.string().required("Password is required"),
  IpAddress: Yup.string()
    .required("IP Address is required")
    .matches(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/, "Invalid IP address format"),
  SourceTon: Yup.string().required("Source Ton is required"),
  SourceNpi: Yup.string().required("Source Npi is required"),
  DestinationTon: Yup.string().required("Destination Ton is required"),
  DestinationNpi: Yup.string().required("Destination Npi is required"),
  ShortCodeTonNpi: Yup.string().required("Short Code Ton Npi is required"),
  MaximumRetry: Yup.string().required("Maximum Retry is required"),
  ConnectionToOpen: Yup.string().required("Connection To Open is required"),
  EnquireLink: Yup.string().required("Enquire Link is required"),
  SubmitPerSecond: Yup.string().required("Submit Per Second is required"),
  ConnectionMode: Yup.string().required("Connection Mode is required"),
  TranslationPrefix: Yup.string().required("Translation Prefix is required"),
});

export const validationSchemaBankingDetails = Yup.object().shape({
  CountryGuid: Yup.string().required("Country is required"),
  IBAN: Yup.string().required("IBAN is required"),
  CodeSwiftBic: Yup.string().required("Code SWIFT BIC is required"),
  BankAccountNumber: Yup.string()
    .matches(/^[0-9-]+$/, "Field can only contain numbers and hyphen")
    .required("Bank Account Number is required"),
  RoutingNumber: Yup.string()
    .matches(/^[0-9-]+$/, "Field can only contain numbers and hyphen")
    .required("Routing Number is required"),
  BankName: Yup.string().required("Bank Name is required"),
  BankStreet: Yup.string().required("Bank Street is required"),
  BankPhoneNumber: Yup.string().required("Bank Phone Number is required"),
  BankPostCode: Yup.string().required("Bank Post Code is required"),
  BankCity: Yup.string().required("Bank City is required"),
  BankSwiftCorrespondent: Yup.string().required(
    "Bank Swift Correspondent is required"
  ),
});
