import axios from "axios";
import { KEYCLOAK_MEMBERS_REFRESH } from "./Service-Manager/APIs";

const axiosInstance = axios.create({
  // Your base configuration here
});
let newServices = JSON.parse(localStorage.getItem("services"));

let KEYCLOAK_MEMBERS_REFRESH_CHECK =
  newServices &&
  `${localStorage.getItem("baseUrl")}${
    newServices["members"]
  }${KEYCLOAK_MEMBERS_REFRESH}`;
// console.log("KEYCLOAK_MEMBERS_REFRESH_CHECK", KEYCLOAK_MEMBERS_REFRESH_CHECK);
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response?.status === 401) {
      try {
        await refreshToken(localStorage.getItem("refreshToken"));

        return Promise.reject("error: 401");
      } catch (refreshError) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const refreshToken = (_refreshToken) => {
  const CONFIGREFRESH = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      Authorization: "bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
      Tenant: localStorage.getItem("tenant"),
      // "Refresh-Token-Key": "dvImQ2RX4LN91/C+i3dv2rTRtOJ6MD0yxWUV4egFRIiATjDCuLsKk6qeGlBHdMYhA6WrioRJq5zUjMI1NbQHTg=="
    },
  };
  return axios
    .post(
      KEYCLOAK_MEMBERS_REFRESH_CHECK,
      { RefreshToken: _refreshToken },
      CONFIGREFRESH
    )
    .then((res) => {
      if (res?.data?.data && res?.data?.data?.accessToken) {
        localStorage.setItem("token", res?.data?.data?.accessToken);
      }
    })
    .catch((error) => {
      // localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    });
};

export default axiosInstance;
