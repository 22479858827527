import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
// import MuiCheckbox from "../../../Components/MuiCheckbox/MuiCheckbox";
import { AccountTree, Add, Delete, Edit } from "@mui/icons-material";
import { Draggable } from "react-beautiful-dnd";
import MuiCheckbox from "../../../Components/MuiCheckbox/MuiCheckbox";
import { ROLES } from "../../../Utils/Constants";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import PanToolIcon from '@mui/icons-material/PanTool';
const MenuCard = ({
  Menu,
  handleCheckbox,
  SetAddToggle,
  SetEditToggle,
  index,
  handleDeleteMenu,
  children,
  setToggleType,
  toggleType,
}) => {
  // const { role } = useSelector(state => state?.authentication)
  let role = localStorage.getItem("roleName");
  let token = localStorage.getItem("token");
  return (
    <Draggable draggableId={Menu?.recordGuid} index={index}>
      {(provided) => (
        <Card
          className="mt-4 card-menu-style"
          sx={{ marginBottom: "10px" }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Grid container padding={2}>
            <Grid item xs={8}>
              <Typography color={"black"} variant="h6">
              <DragHandleIcon className="icon-disable" size="small" style={{color:"#595959",marginBottom:"-5px"}}/>     {index + 1} - {Menu?.menuDetail[0]?.name}
              </Typography>
            </Grid>
            {/* {ROLES?.includes(role) && ( */}
            <Grid display={"flex"} justifyContent={"flex-end"} item xs={4}>
              <Tooltip title={"Add Action"} placement="top">
                <IconButton
                  onClick={() => {
                    SetAddToggle(true);
                    setToggleType("Action");
                  }}
                  className="mui-btn primary filled"
                >
                  <Add />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Add Child"} placement="top">
                <IconButton
                  onClick={() => {
                    SetAddToggle(true);
                    setToggleType("Child");
                  }}
                  size="small"
                  className="mui-btn primary filled"
                >
                  <AccountTree />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Edit"} placement="top">
                <IconButton
                  size="small"
                  onClick={() => SetEditToggle(true)}
                  className="mui-btn primary filled"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete"} placement="top">
                <IconButton
                  onClick={handleDeleteMenu}
                  size="small"
                  className="mui-btn primary filled"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* )} */}
          </Grid>

          <CardContent className="pt-0">
            <Grid container spacing={2}>
              {Menu?.menuAction.length > 0 &&
                Menu?.menuAction.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <FormControlLabel
                      control={
                        <MuiCheckbox
                          id={item?.recordGuid}
                          onChange={handleCheckbox}
                          checked={item?.hasAccess}
                        />
                      }
                      label={item.menuActionDetail[0]?.name}
                    />
                  </Grid>
                ))}
            </Grid>
          </CardContent>
          {children}
        </Card>
      )}
    </Draggable>
  );
};

export default MenuCard;
