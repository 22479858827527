import * as React from "react";
import CropperComponent from "../../../../Components/Cropper/CropperComponent";
import { Button } from "@mui/material";
import { fileToBase64 } from "../../../../Utils/functions";
import getCroppedImg from "../../../../Components/Cropper/GetCrop";

const CropImage = ({ image, setCroppedImage, imageSource = "file", aspectRatio }) => {
  const [pixels, SetPixels] = React.useState(null)
  

  const handleCroppedImage = async () => {
    let img = imageSource == "file" ? URL.createObjectURL(image) : image;
    try {
      const croppedImage = await getCroppedImg({
        imageSrc: img,
        pixelCrop: pixels,
        rotation: 0
      });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <React.Fragment>
      <CropperComponent
        image={image}
        imageSource={imageSource}
        setCroppedImage={SetPixels}
        aspectRatio={aspectRatio}
      />
      <Button className="contained" onClick={handleCroppedImage}>Save</Button>
    </React.Fragment>
  );
};

export default CropImage;
