import ChatIcon from "@mui/icons-material/Chat";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import SmsIcon from "@mui/icons-material/Sms";
import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import VerticalTabs from "../../../Components/Tabs/ChannelTabVertical";
import HorizantalTabs from "../../../Components/Tabs/ChannelTabs";
import axiosInstance from "../../../axiosInstance";
import {
  GET_ALL_CHANNELS,
  GET_ALL_SERVICES,
  MULTI_TENANCY_CONFIG,
} from "../../APIs";
import BillingPricing from "./BillingPricing";
import TableTabs from "../../../Components/Tabs/TableTabs";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function ProfitLoss({ selectedBtn, isProfit }) {
  const [activeBtn, setActiveBtn] = useState("Sms");
  const [services, setServices] = useState(localStorage.getItem("services"));

  const [allServices, setAllServices] = useState([]);
  const [allChannels, setAllChannels] = useState([]);

  const tabs = [
    {
      label: "Sms",
      icon: <SmsIcon className="chanel-icons" />,
    },
    {
      label: "Sms Two Way",
      icon: <ChatIcon className="chanel-icons" />,
      disabled: true,
    },
    {
      label: "MNP HLR",
      icon: <PodcastsIcon className="chanel-icons" /> 
      // ,disabled: true,
    },
    {
      label: "Whatsapp",
      icon: <WhatsAppIcon className="chanel-icons" />,
    },
  ];
  const tabsHors = [
    {
      label: "Sms",
      icon: <SmsIcon className="chanel-icons" />,
    },
    {
      label: "MNP HLR",
      icon: <PodcastsIcon className="chanel-icons" /> 
      // ,disabled: true,
    },
    {
      label: "Whatsapp",
      icon: <WhatsAppIcon className="chanel-icons" />,
    },
  ];
  const getAllServices = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    // setIsLoading(true);
    console.log(
      "ASfasfasfasfasfa",
      `${localStorage.getItem("baseUrl")}${GET_ALL_SERVICES}`
    );
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${GET_ALL_SERVICES}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setAllServices(res?.data?.data?.items);
        // setClientCategoryOptionsSearch([
        //   ...res?.data?.data?.clientCategories?.map((x, i) => ({
        //     label: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //   })),
        // ]);
        // setClientCategorySearch(
        //   res?.data?.data?.clientCategories
        //     ? res?.data?.data?.clientCategories[0]?.recordGuid
        //     : ""
        // );
        // setIsLoading(false);
        // this.setState({
        //   clientIdOptionsRes: res?.data?.data?.clientCategories.map((x, i) => ({
        //     text: x.clientCategoryDetails
        //       ? x.clientCategoryDetails[0]?.name
        //       : "",
        //     value: x.recordGuid,
        //     checked: i === 0 ? true : false,
        //   })),
        //   clientIdOptions: [
        //     ...this.state.clientIdOptions,
        //     ...res?.data?.data?.clientCategories.map((x, i) => ({
        //       text: x.clientCategoryDetails
        //         ? x.clientCategoryDetails[0]?.name
        //         : "",
        //       value: x.recordGuid,
        //       checked: false,
        //     })),
        //   ],
        //   client: res?.data?.data?.clientCategories[0].recordGuid,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllServices();
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const getAllChannelGuid = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    // setIsLoading(true);
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          newServices["notification"]
        }${GET_ALL_CHANNELS}`,
        // body,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setAllChannels(res?.data?.data?.channels);
        // setIsLoading(false);
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllChannelGuid();
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const isLargeScreen = window.innerWidth > 729;
  const handleTabChange = (newTabLabel) => {
    setActiveBtn(newTabLabel);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const renderLargeScreenLayout = () => (
    <Box>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Billing</Typography>
            <Typography className="BreadcrumbsPage">Profit & Loss</Typography>

            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid
        style={{ display: "flex", justifyContent: "end" }}
        container
        className="Top-spacing"
      >
        <Grid item lg={windowWidth < 1538 ? 10 :10.6}>
          <TableTabs
            style={{ marginBottom: "20px" }}
            tabs={tabs}
            onTabChange={handleTabChange}
          />
        </Grid>
      </Grid>
      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={12}>
          {activeBtn === "Sms" && (
            <BillingPricing
              isProfit={isProfit}
              selectedBtn={selectedBtn}
              serviceGuid={
                allServices.find((x) => x?.tag === "ONE_WAY_SMS")?.recordGuid
              }
              channelGuid={
                allChannels.find((x) => x?.tag === "SMS")?.recordGuid
              }
            />
          )}
          {/* {activeBtn === "Sms Two Way" && (
            <SMSTwoWayRoutes
              serviceGuid={
                allServices.find((x) => x?.tag === "TWO_WAY_SMS")?.recordGuid
              }
            />
          )} */}
          {activeBtn === "MNP HLR" && (
            <BillingPricing
              isProfit={isProfit}
              selectedBtn={selectedBtn}
              serviceGuid={
                allServices.find((x) => x?.tag === "NUMBER_LOOKUP")?.recordGuid
              }
              channelGuid={
                allChannels.find((x) => x?.tag === "NUMBER_LOOKUP")?.recordGuid
              }
            />
          )}
           {activeBtn === "Whatsapp" && (
            <BillingPricing
            isWhatsapp={true}

              isProfit={isProfit}
              selectedBtn={selectedBtn}
              serviceGuid={
                allServices.find((x) => x?.tag === "WHATSAPP")?.recordGuid
              }
              channelGuid={
                allChannels.find((x) => x?.tag === "WHATSAPP")?.recordGuid
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );

  const renderSmallScreenLayout = () => (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">Billing</Typography>
            <Typography className="BreadcrumbsPage">Profit & Loss</Typography>

            <Typography className="breadcrumbactiveBtn">{activeBtn}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={4}
          xs={12}
          className={isXsScreen ? "" : ""}
          sx={{
            mt: isXsScreen ? 2 : 0,
            order: isXsScreen ? 2 : 1,
          }}
        >
          <FormControl className="select-hover">
            <Select
              labelId="demo-simple-select-standard"
              id="demo-simple-select"
              value=""
              className="custom-select"
              variant="standard"
              displayEmpty
            >
              <MenuItem value="" disabled>
                {activeBtn}
              </MenuItem>
              <MenuItem>
                <VerticalTabs
                  activeBtn={activeBtn}
                  tabs={tabs}
                  windowWidth={windowWidth}
                  onTabChange={handleTabChange}
                />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid
          item
          sm={8}
          xs={12}
          className={isXsScreen ? "" : "end"}
          sx={{
            mt: isXsScreen ? 2 : 0,
            order: isXsScreen ? 1 : 2,
          }}
        ></Grid> */}
      </Grid>
      <Grid container className="Top-spacing">
        <Grid item sx={12} md={12} lg={12}>
          {activeBtn === "Sms" && (
            <BillingPricing
              isProfit={isProfit}
              selectedBtn={selectedBtn}
              serviceGuid={
                allServices.find((x) => x?.tag === "ONE_WAY_SMS")?.recordGuid
              }
              channelGuid={
                allChannels.find((x) => x?.tag === "SMS")?.recordGuid
              }
            />
          )}
          {/* {activeBtn === "Sms Two Way" && (
            <SMSTwoWayRoutes
              serviceGuid={
                allServices.find((x) => x?.tag === "TWO_WAY_SMS")?.recordGuid
              }
            />
          )} */}
          {activeBtn === "MNP HLR" && (
            <BillingPricing
              isProfit={isProfit}
              selectedBtn={selectedBtn}
              serviceGuid={
                allServices.find((x) => x?.tag === "NUMBER_LOOKUP")?.recordGuid
              }
              channelGuid={
                allChannels.find((x) => x?.tag === "NUMBER_LOOKUP")?.recordGuid
              }
            />
          )}
             {activeBtn === "Whatsapp" && (
            <BillingPricing
              isProfit={isProfit}
              isWhatsapp={true}
              selectedBtn={selectedBtn}
              serviceGuid={
                allServices.find((x) => x?.tag === "WHATSAPP")?.recordGuid
              }
              channelGuid={
                allChannels.find((x) => x?.tag === "WHATSAPP")?.recordGuid
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );

  useEffect(() => {
    getAllServices();
    getAllChannelGuid();
  }, []);

  return (
    <Box>
      {windowWidth > 1000
        ? renderLargeScreenLayout()
        : renderSmallScreenLayout()}
    </Box>
  );
}
export default ProfitLoss;
