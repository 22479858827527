import {
  Alert,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  ADD_MENU,
  ADD_MENU_ACTION,
  ADD_NEW_ROLE,
  ASSIGN_ACTION_SCOPES,
  DELETE_MENU,
  DELETE_ROLE,
  GET_ALL_MENU_API,
  GET_ALL_POLICIEs_API,
  SAVE_PERMISSIONS,
  UPDATE_MENU_ORDER,
} from "../../../Authentication/Roles";
// import { HandleApiError } from "../../../Utils/Functions";
import { Add, Delete, Reorder, Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddMenu from "./AddMenu";
import MenuCard from "./MenuCard";
// import Notification from "../../../Components/Notification/Notification";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import { ROLES } from "../../../Utils/Constants";
import AddToMenu from "./AddToMenu";
import EditMenu from "./EditMenu";
import SubMenuCard from "./SubMenuChid";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";

const RolesPage = (open, setOpen, title) => {
  let role = localStorage.getItem("roleName");
  let token = localStorage.getItem("token");
  const [ToggleEdit, SetToggleEdit] = useState(false);
  const [EditMenuItem, SetEditMenuItem] = useState(null);
  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [Name, setName] = useState("");
  const [Path, setPath] = useState("");
  const [Icon, setIcon] = useState("");
  const [AddToMenuToggle, SetAddToMenuToggle] = useState(false);
  const [AddRoleToggle, SetAddRoleToggle] = useState(false);
  const [AddToggle, SetAddToggle] = useState(false);
  const [toggleType, setToggleType] = useState("");

  const [policyRes, setPolicyRes] = useState(null);

  const [Policies, SetPolicies] = useState(false);

  const [Menu, SetMenu] = useState([]);
  const [Childs, SetChilds] = useState([]);

  const [SelectedMenu, SetSelectedMenu] = useState(null);

  const [Loading, SetLoading] = useState(false);

  const [RoleName, SetRoleName] = useState("");

  const GetPolicies = async () => {
    SetLoading(true);
    SetPolicies(false)
    try {
      let policiesResponse = await GET_ALL_POLICIEs_API({ token });
      // if (ROLES?.includes(role)) {
      //   console.log(policiesResponse);
      //   setPolicyRes(policiesResponse?.data?.data?.policies);
      //   SetPolicies(
      //     policiesResponse?.data?.data?.policies?.map((x) => ({
      //       label: x?.name,
      //       value: x?.id,
      //     }))
      //   );
      // } else {
      //   setPolicyRes(policiesResponse?.data?.data?.policies);

      //   SetPolicies(
      //     policiesResponse?.data?.data?.policies?.filter(
      //       (item) => !ROLES?.includes(item?.name)
      //     )
      //   );
      //   // SetPolicies(
      //   //   policiesResponse?.data?.data?.policies?.filter(
      //   //     (item) => !ROLES?.includes(item?.name)
      //   //   )
      //   // );//
      // }
      setPolicyRes(policiesResponse?.data?.data?.policies);
      SetPolicies(
        policiesResponse?.data?.data?.policies?.map((x) => ({
          label: x?.name,
          value: x?.id,
        }))
      );
    } catch (e) {
      // HandleApiError(e);
    } finally {
      SetLoading(false);
    }
  };

  const GetMenus = async ({ policy }) => {
    try {
      let menuResponse = await GET_ALL_MENU_API({ token, policy });
      SetMenu(
        menuResponse?.data?.data?.menu
          ?.filter((menu) => !menu?.parentGuid && menu?.group === 2)
          .sort((a, b) => a?.displayOrder - b?.displayOrder)
      );
      SetChilds(
        menuResponse?.data?.data?.menu?.filter((menu) => menu?.parentGuid)
      );
    } catch (e) {
      // HandleApiError(e);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUpdateOrder = async () => {
    try {
      let response = await UPDATE_MENU_ORDER({
        token,
        formData: {
          menuOrder: Menu?.filter((item) => !item?.parentGuid).map(
            (item, index) => {
              return { recordGuid: item?.recordGuid, displayOrder: index };
            }
          ),
        },
      });
      if (response?.data?.success) {
        setOpenModalSaveOrder(false);
        setOpenMessageAlert(true);
        setMessageData({ message: "Saved Successfully ", type: "success" });

        // Notification?.success("Menu updated successfully");
      }
    } catch (e) {
      setOpenMessageAlert(true);
      setMessageData({ message: "Not Saved ", type: "error" });

      // HandleApiError(e);
    }
  };
  const [openModalSaveChanges, setOpenModalSaveChanges] = useState(false);
  const handleOpenModalSaveChanges = () => {
    setOpenModalSaveChanges(!openModalSaveChanges);
  };
  const [openModalSaveOrder, setOpenModalSaveOrder] = useState(false);
  const handleOpenModalSaveOrder = () => {
    setOpenModalSaveOrder(!openModalSaveOrder);
  };

  const handleSavePermissions = async () => {
    const ActionsWithHasAccess = Menu.filter((item) => item.group === 2) // Filter items with group 2
      .flatMap((item) =>
        item.menuAction
          .filter((action) => action.hasAccess)
          .map((action) => action.recordGuid)
      );
    const ChildActionsWithHasAccess = Childs.filter((item) => item.group === 2) // Filter items with group 2
      .flatMap((item) =>
        item.menuAction
          .filter((action) => action.hasAccess)
          .map((action) => action.recordGuid)
      );
    const MenusWithHasAccess = [
      ...new Set(
        Menu.filter((item) => item.group === 2) // Filter items with group 2
          .flatMap(
            (item) =>
              item.menuAction
                .filter((action) => action.hasAccess) // Filter actions with hasAccess
                .map((action) => item.recordGuid) // Map to the recordGuid
          )
      ),
    ];
    const ChildMenusWithHasAccess = [
      ...new Set(
        Childs.filter((item) => item.group === 2) // Filter items with group 2
          .flatMap((item) =>
            item.menuAction
              .filter((action) => action.hasAccess)
              .map((action) => item.recordGuid)
          )
      ),
    ];
    try {
      let response = await SAVE_PERMISSIONS({
        token,
        formData: {
          MenuIds: [
            ...new Set(MenusWithHasAccess),
            ...new Set(ChildMenusWithHasAccess),
          ],
          PolicyId: SelectedPolicy,
          MenuActionIds: [
            ...new Set(ActionsWithHasAccess),
            ...new Set(ChildActionsWithHasAccess),
          ],
        },
      });
      if (response?.data?.success) {
        setOpenMessageAlert(true);
        setMessageData({ message: "Saved Successfully ", type: "success" });

        setOpenModalSaveChanges(false);
        await GetMenus({
          policy: policyRes?.find((x) => x.id === SelectedPolicy)?.name,
        });
        // Notification?.success("Permissions updated successfully");
      }
    } catch (e) {
      setOpenMessageAlert(true);
      setMessageData({ message: "Not Saved", type: "error" });
      // HandleApiError(e);
    }
  };

  const handleCheckbox = async ({ e, menu, type = "parent" }) => {
    console.log("menuu", menu);
    console.log("menuueeId", e?.target?.id);
    console.log("menuueeChecked", e?.target?.checked);

    console.log("menuuType", type);

    console.log("menuuMenu", Menu);
    console.log("menuuChilds", Childs);

    if (type == "parent") {
      // Create a new state object with the updated value
      const updatedMenuData = Menu.map((menuItem) => {
        if (menuItem.recordGuid === menu) {
          return {
            ...menuItem,
            menuAction: menuItem.menuAction.map((action) => {
              if (action.recordGuid === e?.target?.id) {
                return {
                  ...action,
                  hasAccess: e?.target?.checked,
                };
              }
              return action;
            }),
          };
        }
        return menuItem;
      });

      // Update the state with the new data
      SetMenu(updatedMenuData);
      console.log("menuupdatedMenuDataParent", updatedMenuData);
    } else {
      // Create a new state object with the updated value
      const updatedMenuData = Childs.map((menuItem) => {
        if (menuItem.recordGuid === menu) {
          return {
            ...menuItem,
            menuAction: menuItem.menuAction.map((action) => {
              if (action.recordGuid === e?.target?.id) {
                return {
                  ...action,
                  hasAccess: e?.target?.checked,
                };
              }
              return action;
            }),
          };
        }
        return menuItem;
      });
      // Update the state with the new data
      SetChilds(updatedMenuData);
      console.log("menuupdatedMenuDataChilds", updatedMenuData);
    }
  };

  // const handleDeleteMenu = async ({ id }) => {
  //   const result = await Swal.fire({
  //     title: "Confirm Deletion",

  //     text: "Are you sure you want to delete this menu?",

  //     icon: "warning",

  //     showCancelButton: true,

  //     confirmButtonColor: "#dd3333",

  //     cancelButtonColor: "#3085d6",

  //     confirmButtonText: "Yes, delete it!",

  //     cancelButtonText: "No, cancel",
  //   });

  //   // If the user confirms the deletion

  //   if (result.isConfirmed) {
  //     try {
  //       // Set loading to true while the deletion is in progress

  //       SetLoading(true);

  //       // Perform the deletion operation

  //       let deleteResponse = await DELETE_MENU({
  //         token,
  //         menu: id,
  //       });

  //       if (deleteResponse?.data?.success) {
  //         await GetMenus({ policy: SelectedPolicy?.name });
  //         // Notification?.success("Client Successfully Deleted");
  //         // Refresh your data or perform any necessary actions
  //       }
  //     } catch (e) {
  //       // HandleApiError(e);
  //     } finally {
  //       // Set loading back to false when the operation is complete
  //       SetLoading(false);
  //     }
  //   }
  // };

  // const handleDeleteRole = async () => {
  //   const result = await Swal.fire({
  //     title: "Confirm Deletion",

  //     text: "Are you sure you want to delete this role?",

  //     icon: "warning",

  //     showCancelButton: true,

  //     confirmButtonColor: "#dd3333",

  //     cancelButtonColor: "#3085d6",

  //     confirmButtonText: "Yes, delete it!",

  //     cancelButtonText: "No, cancel",
  //   });

  //   // If the user confirms the deletion

  //   if (result.isConfirmed) {
  //     try {
  //       // Set loading to true while the deletion is in progress

  //       SetLoading(true);

  //       // Perform the deletion operation

  //       let deleteResponse = await DELETE_ROLE({
  //         token,
  //         id: SelectedPolicy?.id,
  //       });

  //       if (deleteResponse?.data?.success) {
  //         SetSelectedPolicy(false);
  //         SetMenu([]);
  //         await GetPolicies();
  //         // Notification?.success("Role Successfully Deleted");
  //         // Refresh your data or perform any necessary actions
  //       }
  //     } catch (e) {
  //       // HandleApiError(e);
  //     } finally {
  //       // Set loading back to false when the operation is complete
  //       SetLoading(false);
  //     }
  //   }
  // };
  useEffect(() => {
    if (AddRoleToggle) {
      SetRoleName("");
    }
  }, [AddRoleToggle]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [menuToDelete, setMenuToDelete] = useState(null);

  const handleDeleteMenu = ({ id }) => {
    setMenuToDelete(id);
    setConfirmOpen(true);
  };

  const handleCloseModal = () => {
    setConfirmOpen(false);
    setMenuToDelete(null);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessageAlert(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleConfirmDeletion = async () => {
    try {
      SetLoading(true); // Assuming you have a loading state

      let deleteResponse = await DELETE_MENU({
        token,
        menu: menuToDelete,
      });

      if (deleteResponse?.data?.success) {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Deleted ", type: "success" });

        await GetMenus({
          policy: policyRes?.find((x) => x.id === SelectedPolicy)?.name,
        });
        // Notification?.success("Menu Successfully Deleted");
      }
    } catch (e) {
      // HandleApiError(e);
    } finally {
      SetLoading(false);
      setConfirmOpen(false); // Close the confirmation modal
    }
  };
  const [confirmOpenDeleteRole, setconfirmOpenDeleteRole] = useState(false);

  const handleDeleteRole = () => {
    setconfirmOpenDeleteRole(true);
  };

  const handleCloseModalDeleteRole = () => {
    setconfirmOpenDeleteRole(false);
  };

  const handleConfirmDeletionDeleteRole = async () => {
    try {
      SetLoading(true); // Assuming you have a loading state

      let deleteResponse = await DELETE_ROLE({
        token,
        id: SelectedPolicy,
      });

      if (deleteResponse?.data?.success) {
        setOpenMessageAlert(true);
        setMessageData({ message: "Successfully Deleted", type: "success" });
        SetSelectedPolicy(null);
        SetMenu([]);
        await GetPolicies();
        // Notification?.success("Role Successfully Deleted");
      }
    } catch (e) {
      setMessageData({ message: "Not Deleted", type: "error" });

      // HandleApiError(e);
    } finally {
      SetLoading(false);
      setconfirmOpenDeleteRole(false); // Close the confirmation modal
    }
  };

  const handleSubmitAddMenu = async (formData) => {
    try {
      let addResponse = await ADD_MENU({
        token,
        formData,
      });
      if (addResponse?.data?.success) {
        setOpenMessageAlert(true);
        setMessageData({ message: "Added Successfully ", type: "success" });

        // Notification.success("Page added successfully");
        SetAddToggle(false);
        SetAddToMenuToggle(false);

        GetMenus({
          policy: policyRes?.find((x) => x.id === SelectedPolicy)?.name,
        });
      }
    } catch (e) {
      // HandleApiError(e);
    }
  };

  const handleSubmitActionButton = async ({ formData, SelectedScope }) => {
    console.log("SelectedScopeSelectedScope", SelectedScope);
    try {
      let addResponse = await ADD_MENU_ACTION({
        token,
        formData,
      });

      let recordGuid = addResponse?.data?.data?.recordGuid[0];

      let scopeactionResponse = await ASSIGN_ACTION_SCOPES({
        token,
        formData: {
          MenuActionScopes: [
            {
              MenuActionGuid: recordGuid,
              ScopeGuid: SelectedScope,
            },
          ],
        },
      });

      if (scopeactionResponse?.data?.success) {
        setOpenMessageAlert(true);
        setMessageData({ message: "Added Successfully ", type: "success" });

        // Notification.success("Page added successfully");
        SetAddToMenuToggle(false);
        await GetMenus({
          policy: policyRes?.find((x) => x.id === SelectedPolicy)?.name,
        });
      }
    } catch (e) {
      // HandleApiError(e);
    }
  };

  const handleRoleAdd = async () => {
    try {
      let addResponse = await ADD_NEW_ROLE({
        token,
        formData: {
          Name: RoleName,
          Description: RoleName,
        },
      });
      if (addResponse?.data?.success) {
        // Notification?.success("Role added successfully");
        setOpenMessageAlert(true);
        setMessageData({ message: "Added Successfully ", type: "success" });

        GetPolicies();
        SetAddRoleToggle(false);
        SetRoleName("");
      }
    } catch (e) {
      // HandleApiError(e);
    }
  };
  const MenuList = React.memo(function QuoteList({ menus }) {
    return menus?.map((menu, index) => (
      <MenuCard
        setToggleType={setToggleType}
        toggleType={toggleType}
        handleCheckbox={(e) => {
          handleCheckbox({ e, menu: menu?.recordGuid });
        }}
        SetAddToggle={(e) => {
          SetSelectedMenu(menu);
          SetAddToMenuToggle(e);
        }}
        Menu={menu}
        index={index}
        key={menu.recordGuid}
        SetEditToggle={() => {
          SetEditMenuItem(menu);
          SetToggleEdit(true);
        }}
        handleDeleteMenu={() => handleDeleteMenu({ id: menu?.recordGuid })}
      >
        {Childs?.length > 0 && (
          <Grid container padding={2} spacing={4}>
            {Childs?.filter((item) => item?.parentGuid == menu?.recordGuid).map(
              (subMenu) => (
                <Grid item xs={12} lg={6} md={6}>
                  {" "}
                  <SubMenuCard
                    setToggleType={setToggleType}
                    SubMenu={subMenu}
                    SetAddToggle={(e) => {
                      SetSelectedMenu(subMenu);
                      SetAddToMenuToggle(e);
                    }}
                    handleDeleteMenu={() =>
                      handleDeleteMenu({ id: subMenu?.recordGuid })
                    }
                    SetEditToggle={() => {
                      SetEditMenuItem(subMenu);
                      SetToggleEdit(true);
                    }}
                    handleCheckbox={(e) => {
                      handleCheckbox({
                        e,
                        menu: subMenu?.recordGuid,
                        type: "child",
                      });
                    }}
                  />{" "}
                </Grid>
              )
            )}
          </Grid>
        )}
      </MenuCard>
    ));
  });

  const reorder = (list, startIndex, endIndex) => {
    console.log("listlist", list);
    const result = Array.from(list);
    const [removed] = result?.splice(startIndex, 1);
    result?.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result?.destination) {
      return;
    }

    if (result?.destination?.index === result?.source?.index) {
      return;
    }

    const tempMenu = reorder(
      Menu,
      result?.source?.index,
      result?.destination?.index
    );
    SetMenu(tempMenu);
  }

  const [SelectedPolicy, SetSelectedPolicy] = useState(null);

  useEffect(() => {
    GetPolicies();
  }, []);

  useEffect(() => {
    if (SelectedPolicy) {
      GetMenus({
        policy: policyRes?.find((x) => x.id === SelectedPolicy)?.name,
      });
    }
    SetMenu([]);
  }, [SelectedPolicy]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Typography className="BreadcrumbsPage">System Account</Typography>
            <Typography className="breadcrumbactiveBtn">Roles</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        // id="Roles"
        // style={{ height: "100vh", overflowY: "scroll", paddingBottom: 100 }}
        // alignItems={"flex-start"}
        style={{ marginTop: "50px" }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={6}
            lg={windowWidth < 1800 ? 2 : 2}
            sx={{ marginTop: "-25px", marginRight: "15px" }}
          >
            <SelectFormNew
              value={SelectedPolicy}
              setValue={SetSelectedPolicy}
              options={Policies}
              placeholder={"Role"}
              disabled={Policies?.length === 0}
            />
          </Grid>

          {/* {ROLES?.includes(role) && ( */}
          <Grid
            style={{ visibility: !SelectedPolicy && "hidden" }}
            item
            xs={12}
            md={6}
            lg={windowWidth < 1800 ? 1.8 : 1.4}
          >
            <Button
              fullWidth
              startIcon={<Delete />}
              onClick={handleDeleteRole}
              className="outline"
            >
              Delete Role
            </Button>
          </Grid>
          {/* )} */}
          {/* {ROLES?.includes(role) && ( */}
          <Grid cla item xs={12} md={6} lg={windowWidth < 1800 ? 1.8 : 1.4}>
            <Button
              style={{
                visibility: !SelectedPolicy && "hidden",
              }}
              fullWidth
              startIcon={<Reorder />}
              onClick={handleOpenModalSaveOrder}
              className="outline"
            >
              Save Order
            </Button>
          </Grid>
          {/* )} */}
          {/* {ROLES?.includes(role) && ( */}
          <Grid item xs={12} md={6} lg={windowWidth < 1800 ? 1.8 : 1.5}>
            <Button
              style={{
                visibility: !SelectedPolicy && "hidden",
              }}
              fullWidth
              startIcon={<Save />}
              onClick={handleOpenModalSaveChanges}
              className="outline"
            >
              Save Changes
            </Button>
          </Grid>
          {/* )} */}

          {/* {ROLES?.includes(role) && ( */}
          <Grid item lg={windowWidth < 1800 ? 0.9 : 0.5}></Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={windowWidth < 1800 ? 3.5 : 5}
            className="end"
          >
            <Button
              onClick={() => SetAddToggle(true)}
              fullWidth
              startIcon={<Add />}
              className="contained-roles"
            >
              Add Menu
            </Button>
            <Button
              fullWidth
              onClick={() => SetAddRoleToggle(true)}
              startIcon={<Add />}
              className="contained-roles"
              style={{ marginLeft: "15px" }}
            >
              Add Role
            </Button>
          </Grid>
          {/* )} */}

          {confirmOpenDeleteRole && (
            <ConfirmationModal
              open={confirmOpenDeleteRole}
              onClose={handleCloseModalDeleteRole}
              title="Delete"
              text="Are you sure you want to delete this role?"
              onButtonClick={handleConfirmDeletionDeleteRole}
            />
          )}

          {/* <div style={{width:"100%",display:"flex",justifyContent:"end"}}> */}

          {SelectedPolicy ? (
            <Grid style={{ marginTop: "30px" }} item xs={12}>
              <Grid container gap={3} className="mt-3">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div
                        style={{ width: "100%" }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        id={provided}
                      >
                        <MenuList menus={Menu} />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                fontSize: "40px",
                margin: "auto",
                color: "#877171",
                marginTop: "110px",
              }}
            >
              No Role Selected
            </div>
          )}
        </Grid>
        <EditMenu
          Menu={EditMenuItem}
          ToggleEdit={ToggleEdit}
          SetToggleEdit={(data) => {
            GetMenus({
              policy: policyRes?.find((x) => x.id === SelectedPolicy)?.name,
            });
            SetToggleEdit(data);
          }}
        />
        <AddMenu
          menus={Menu?.length}
          AddToggle={AddToggle}
          SetAddToggle={SetAddToggle}
          handleSubmitButton={handleSubmitAddMenu}
          Name={Name}
          setName={setName}
          Path={Path}
          setPath={setPath}
          Icon={Icon}
          setIcon={setIcon}
        />
        {AddToMenuToggle && (
          <AddToMenu
            AddToMenuToggle={AddToMenuToggle}
            SetAddToMenuToggle={SetAddToMenuToggle}
            menu={SelectedMenu}
            handleSubmitButton={handleSubmitAddMenu}
            handleSubmitActionButton={handleSubmitActionButton}
            toggleType={toggleType}
          />
        )}
        {openModalSaveOrder && (
          <ConfirmationModal
            open={openModalSaveOrder}
            onClose={handleOpenModalSaveOrder}
            title="Save Order "
            text="Are you sure you want to save order?"
            onButtonClick={handleUpdateOrder}
          />
        )}

        {openModalSaveChanges && (
          <ConfirmationModal
            open={openModalSaveChanges}
            onClose={handleOpenModalSaveChanges}
            title="Save Changes "
            text="Are you sure you want to save all changes?"
            onButtonClick={handleSavePermissions}
          />
        )}
        {confirmOpen && (
          <ConfirmationModal
            open={confirmOpen}
            onClose={handleCloseModal}
            title="Delete"
            text="Are you sure you want to delete this menu?"
            onButtonClick={handleConfirmDeletion}
          />
        )}

        {/* <MuiModal
        title="Add New Role"
        open={AddRoleToggle}
        handleClose={() => SetAddRoleToggle(false)}
      >
        <Grid container padding={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Role Name"
              onChange={(e) => SetRoleName(e?.target?.value)}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} marginTop={3}>
            <Button
              onClick={handleRoleAdd}
              fullWidth
              className="mui-btn primary filled"
            >
              Add role
            </Button>
          </Grid>
        </Grid>
      </MuiModal> */}

        <CustomizedDialogs
          setOpen={SetAddRoleToggle}
          open={AddRoleToggle}
          title={"Add new role"}
          firstActionBtnName={"Save"}
          firstActionBtnFct={handleRoleAdd}
          // secondActionBtnName={"Cancel"}
          content={
            <>
              <Grid container>
                <span style={{ color: "#B3B3B3", fontSize: "15px" }}>
                  Role name
                </span>
                <Grid className="dialog-grid-spacing" item xs={12}>
                  <TextFieldForm
                    // placeholder={"Enter your role name"}
                    value={RoleName}
                    setValue={SetRoleName}
                    // onChange={(e) => SetRoleName(e?.target?.value)}
                  />
                  {/* <TextField
              fullWidth
              // label="Role Name"
              onChange={(e) => SetRoleName(e?.target?.value)}
              // variant="standard"
            /> */}
                </Grid>
              </Grid>
            </>
          }
        />

        <Snackbar
          style={{ marginTop: "40px" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
          open={openMessageAlert}
          autoHideDuration={3000}
          action={action}
        >
          <Alert
            variant="filled"
            severity={messageData?.type}
            onClose={handleClose}
            sx={{ width: "100%" }}
          >
            {messageData?.message}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default RolesPage;
