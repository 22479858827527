import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ErrorIcon from "@mui/icons-material/Error";
import FlagIcon from "@mui/icons-material/Flag";
import IosShareIcon from "@mui/icons-material/IosShare";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import Table from "../../../Components/DynamicTable/Table";

function Push() {
  const [isLoading, setIsLoading] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const tableData = [
    {
      id: 1,
      country: "Lebanon",
      messages: "1209",
      deliveryRate: "",
      cost: "1588",
    },
  ];
  let columns = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "messages",
      headerName: "Messages",
      flex: 1,
    },
    {
      field: "deliveryRate",
      headerName: "Delivery Rate",
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" value={50} />
            </Box>
            <Box sx={{ minWidth: 35 }}>{/* <span>50%</span> */}</Box>
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
    },
  ];
  const ChannelAnalyze = {
    chart: {
      renderTo: "container",
      type: "line",
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Push",
        data: [100, 200, 300, 400, 500],
      },
    ],
  };

  const DeliverytimebyNetwork = {
    chart: {
      renderTo: "container",
      type: "line",
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Alfa",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Touch",
        data: [2, 60, 100, 120, 10],
      },
    ],
  };

  const BreakdownbyStatus = {
    chart: {
      type: "pie",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    series: [
      {
        name: "Status",
        colorByPoint: true,
        data: [
          {
            name: "Delivery Success",
            y: 60,
            sliced: true,
            selected: true,
          },
          {
            name: "Failed",
            y: 20,
          },
          {
            name: "Failed Delivered",
            y: 10,
          },
          {
            name: "Received",
            y: 10,
          },
        ],
      },
    ],
  };

  const Traffic = {
    chart: {
      renderTo: "container",
      type: "bar",
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: "Messages",
      },
      categories: [
        // "Self care",
        // "RBT",
        // "Credit Landing",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Self care",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "RBT",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Credit Landing",
        data: [100, 200, 300, 400, 500],
      },
    ],
  };
  const Subscribers = {
    chart: {
      renderTo: "container",
      type: "column",
      height: 300,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      // categories: [
      //   "Self Care",
      //   "RBT",
      //   "Credit Landing",
      // ],
    },
    yAxis: {
      title: {
        text: "Messages",
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Self Care",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "RBT",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Credit Landing",
        data: [100, 200, 300, 400, 500],
      },
    ],
  };

  const items = [
    {
      application: "Self Care",
      client: "Client A",
      description: "Description ",
    },
    {
      application: "RBT",
      client: "Client B",
      description: "Description",
    },
    {
      application: "Credit",
      client: "Client B",
      description: "Description",
    },
  ];
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box className="smschart-page">
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          onClick={handleClickOpen}
          sx={{ cursor: "pointer" }}
        >
          <Paper className="paper-channel" sx={{ height: 358 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography
                className="title-chart"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Total Applications
              </Typography>
              <Typography
                className="title-chart"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                1212333
              </Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />
                  &nbsp;
                  <span>Total Subscribers</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact highcharts={Highcharts} options={Subscribers} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />
                  &nbsp;
                  <span>Traffic by Application</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact highcharts={Highcharts} options={Traffic} />
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />
                  &nbsp;
                  <span>Channel Analyze</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact highcharts={Highcharts} options={ChannelAnalyze} />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />
                  &nbsp;
                  <span>Breakdown by Status</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={BreakdownbyStatus}
            />
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AccessAlarmIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />
                  &nbsp;
                  <span>Delivery time by Network</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={DeliverytimebyNetwork}
            />
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container sx={{ position: "relative" }}>
        <Grid item xs={12}>
          <Paper className="paper-channel">
            <Grid container sx={{ position: "absolute", zIndex: "200" }}>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FlagIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />
                  &nbsp;
                  <span> Delivery Rate by Country</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "40px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Table
              rowCountState={tableData?.totalCount}
              data={tableData}
              // isLoading={isLoading}
              columns={columns}
              // enableExportBtn={true}
              disableActions={true}
              pageIndex={tableData?.pageIndex ? tableData?.pageIndex : 0}
              setPaginationModel={setPaginationModel}
              paginationModel={paginationModel}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        overflowHidden
        className="dashboard-dialog"
      >
        <DialogTitle className="end">
          <Typography
            variant="h5"
            sx={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            x
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
            {items.map((item, index) => (
            <Card
              key={index}
              sx={{
                // width: "60%",
                marginBottom: 2,
                backgroundColor: "rgba(128, 128, 128, 0.1)",
              }}
            >
              <CardContent>
                <Grid container spacing={2} sx={{padding:'10px'}}>
                  <Grid container> 
                    <Grid item xs={6}> 
                    <Typography sx={{fontSize:'20px' , fontWeight:'bold' , color:'black'}}>
                      Application:
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>  
                    <Typography sx={{fontSize:'20px' , fontWeight:'normal' , color:'black' ,marginLeft:'5px'}}>
                    {item.application}
                    </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}> 
                    <Typography variant="h6" sx={{fontSize:'20px' , fontWeight:'bold' , color:'black'}}>
                      Description:
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>  
                    <Typography sx={{fontSize:'20px' , fontWeight:'normal' , color:'black',marginLeft:'5px'}}>
                    {item.description}
                    </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}> 
                    <Typography variant="h6" sx={{fontSize:'20px' , fontWeight:'bold' , color:'black'}}>
                      Client:
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>  
                    <Typography sx={{fontSize:'20px' , fontWeight:'normal' , color:'black',marginLeft:'5px'}} >
                    {item.client}
                    </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
            </Grid>
            <Grid item xs={6}>
            {items.map((item, index) => (
           <Card
           key={index}
           sx={{
             // width: "60%",
             marginBottom: 2,
             backgroundColor: "rgba(128, 128, 128, 0.1)",
           }}
         >
           <CardContent>
             <Grid container spacing={2} sx={{padding:'10px'}}>
               <Grid container> 
                 <Grid item xs={6}> 
                 <Typography sx={{fontSize:'20px' , fontWeight:'bold' , color:'black'}}>
                   Application:
                 </Typography>
                 </Grid>
                 <Grid item xs={6}>  
                 <Typography sx={{fontSize:'20px' , fontWeight:'normal' , color:'black' ,marginLeft:'5px'}}>
                 {item.application}
                 </Typography>
                 </Grid>
               </Grid>
               <Grid container>
                 <Grid item xs={6}> 
                 <Typography variant="h6" sx={{fontSize:'20px' , fontWeight:'bold' , color:'black'}}>
                   Description:
                 </Typography>
                 </Grid>
                 <Grid item xs={6}>  
                 <Typography sx={{fontSize:'20px' , fontWeight:'normal' , color:'black',marginLeft:'5px'}}>
                 {item.description}
                 </Typography>
                 </Grid>
               </Grid>
               <Grid container>
                 <Grid item xs={6}> 
                 <Typography variant="h6" sx={{fontSize:'20px' , fontWeight:'bold' , color:'black'}}>
                   Client:
                 </Typography>
                 </Grid>
                 <Grid item xs={6}>  
                 <Typography sx={{fontSize:'20px' , fontWeight:'normal' , color:'black',marginLeft:'5px'}} >
                 {item.client}
                 </Typography>
                 </Grid>
               </Grid>
             </Grid>
           </CardContent>
         </Card>
          ))}
            </Grid>
          </Grid>
       
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default Push;
