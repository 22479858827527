import {
    FormControlLabel,
    Grid,
    Switch
  } from "@mui/material";
  import React, { useState } from "react";
  import CustomizedDialogs from "../../../Components/Dialog/Dialog";
  import SelectForm from "../../../Components/Selects/SelectsForm";
  import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
  
  const ManageServiceFeature = ({ open, setOpen, title, onButtonClick }) => {
    const [Feature, setFeature] = useState("");
    const [Service, setService] = useState("");
  
    return (
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        // firstActionBtnFct={()=>console.log("test")}
        // secondActionBtnName={"Cancel"}
        content={
          <>
         
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Service"}
                  value={Service}
                  setValue={setService}
                />
              </Grid>
            </Grid>
      
            <Grid container>
              <Grid className="dialog-grid-spacing" item xs={12}>
                <TextFieldForm
                  placeholder={"Feature"}
                  value={Feature}
                  setValue={setFeature}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    );
  };
  
  export default ManageServiceFeature;
  