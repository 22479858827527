import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TableTabs({ tabs,style, setActiveBtn, onTabChange }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newTabLabel = tabs[newValue].label;
    onTabChange(newTabLabel);
  };

  return (
    <Box >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="horizantal-tab"
        style={{marginBottom:"-14px",marginLeft:"10px",...style}}

      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            {...a11yProps(index)}
            icon={tab.icon}
            disabled={tab?.disabled ? tab?.disabled : false}
            iconPosition="start"
            className="btns"
            sx={{
              mr: 1,
              background: "white",
              // border: "1px solid #c41035",
              borderRadius: "10px 10px 0px 0px",
              fontSize: "14px",
              fontWeight: "400",
              textTransform: "inherit",
              // minWidth: "116px",
              color: "#c41035",
              minHeight: "23px",
              padding: "20px 18px",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

TableTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default TableTabs;
