import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "../../../Components/DynamicTable/Table";
import LinearProgress from "@mui/material/LinearProgress";
import FlagIcon from "@mui/icons-material/Flag";
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import IosShareIcon from "@mui/icons-material/IosShare";
function SmsOneCharts() {
  const [isLoading, setIsLoading] = useState(true);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const tableData = [
    {
      id: 1,
      country: "Lebanon",
      messages: "1209",
      deliveryRate: "",
      cost: "1588",
    },
  ];
  let columns = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "messages",
      headerName: "Messages",
      flex: 1,
    },
    {
      field: "deliveryRate",
      headerName: "Delivery Rate",
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" value={50} />
            </Box>
            <Box sx={{ minWidth: 35 }}>{/* <span>50%</span> */}</Box>
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
    },
  ];
  const ChannelAnalyze = {
    chart: {
      renderTo: 'container', 
      type: 'line',
      height: 300, 
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "One Way",
        data: [100, 200, 300, 400, 500],
      },
    ],
  };

  const DeliverytimebyNetwork = {
    chart: {
      renderTo: 'container', 
      type: 'line',
      height: 300, 
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "2023-09-11",
        "2023-09-12",
        "2023-09-13",
        "2023-09-14",
        "2023-09-15",
      ],
    },
    yAxis: {
      title: {
        text: false,
      },
      tickInterval: 100,
      min: 0,
      max: 600,
    },
    series: [
      {
        name: "Alfa",
        data: [100, 200, 300, 400, 500],
      },
      {
        name: "Touch",
        data: [2, 60, 100, 120, 10],
      },
    ],
  };

  const BreakdownbyStatus = {
    chart: {
      type: "pie",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: false,
      useHTML: true,
      align: "left",
      style: {
        fontWeight: "bold",
      },
    },
    series: [
      {
        name: "Status",
        colorByPoint: true,
        data: [
          {
            name: "Delivery Success",
            y: 60,
            sliced: true,
            selected: true,
          },
          {
            name: "Failed",
            y: 20,
          },
          {
            name: "Failed Delivered",
            y: 10,
          },
          {
            name: "Received",
            y: 10,
          },
        ],
      },
    ],
  };
  return (
    <Box className="smschart-page">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcon
                    className="icon-color-primary"
                    sx={{ fontSize: "2.1875rem" }}
                  />&nbsp;
                  <span>Channel Analyze</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact highcharts={Highcharts} options={ChannelAnalyze} />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className="paper-channel">
            <Grid container>
              <Grid item xs={8}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                  className="icon-color-primary"
                  sx={{ fontSize: "2.1875rem" }}
                />&nbsp;
                  <span>Breakdown by Status</span>
                </Typography>
              </Grid>
              <Grid item xs={4} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={BreakdownbyStatus}
            />
          </Paper>
        </Grid>
      </Grid>
    
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className="paper-channel">
          <Grid container>
              <Grid item xs={11}>
                <Typography
                className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                   <AccessAlarmIcon
                  className="icon-color-primary"
                  sx={{ fontSize: "2.1875rem" }}
                />&nbsp;
                  <span>Delivery time by Network</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "10px" }}
                    className="btn-export-icon"
                    // color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <HighchartsReact
              highcharts={Highcharts}
              options={DeliverytimebyNetwork}
            />
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container sx={{position:'relative'}}>
        <Grid item xs={12}>
          <Paper className="paper-channel">
          <Grid container sx={{position:'absolute' , zIndex:'200'}}>
              <Grid item xs={11}>
                <Typography
                  className="title-chart"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FlagIcon
                  className="icon-color-primary"
                  sx={{ fontSize: "2.1875rem"}}
                />&nbsp;
                  <span>  Delivery Rate by Country</span>
                </Typography>
              </Grid>
              <Grid item xs={1} className="end">
                <Tooltip title={"Export"} placement="bottom">
                  <IconButton
                    style={{ marginRight: "40px" }}
                    color="inherit"
                    // onClick={() => handleOpenSwitchModal()}
                  >
                    <IosShareIcon
                      style={{ margin: "0px 0px 20px 0px", color: "#CA2A4B" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Table
              rowCountState={tableData?.totalCount}
              data={tableData}
              // isLoading={isLoading}
              columns={columns}
              // enableExportBtn={true}
              disableActions={true}
              pageIndex={tableData?.pageIndex ? tableData?.pageIndex : 0}
              setPaginationModel={setPaginationModel}
              paginationModel={paginationModel}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
export default SmsOneCharts;
