import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { ThemeProvider } from "./Components/Theme/Context.js";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import GoogleAnalytics from "./Service-Manager/GoogleAnalitics.js";
import "font-awesome/css/font-awesome.css";
import { persistor, store } from "./Redux/Redux";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>   
     <GoogleAnalytics />
    <ThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
