export const GET_ALL_RATES = `/api/v1/rateplan/get-by-client`; //done
export const EXPORT_ALL_RATES = `/api/v1/rateplan/export-to-excel`;
export const EXPORT_ALL_COSTS = `/api/v1/costplan/export-cost-plans-to-excel`;

export const token = "bearer ";
export const tenant = process.env.REACT_APP_BASE_TENANT;

export const MULTI_TENANCY_CONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    Authorization: token,
    "Content-Type": "application/json",
    Tenant: tenant,
  },
};
export const MULTI_TENANCY_CONFIG_LANG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    Authorization: token,
    "Content-Type": "application/json",
    Tenant: tenant,
  },
};
export const MULTI_TENANCY_CONFIG_SUBMIT_FILE = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    Authorization: token,
    "Content-Type": "application/json",
    Tenant: tenant,
  },
};
export const GET_ALL_CLIENTS_CATEGORY = `/api/admin/v1/client/categories`; //done
export const IMPORT_RATE = `/api/v1/rateplan/import`; //done
export const GET_ALL_CLIENTS_ID = `/api/admin/v1/client/name`; //done

export const KEYCLOAK_MEMBERS_REFRESH = "/api/v1/auth/refresh-token";

//Billing Management-Costs
export const DELETE_RATE = `/api/v1/rateplan`;
export const GET_ALL_COUNTRIES_OPTIONS = `/api/v1/Country/get-all`;
export const GET_ALL_OPERATORS_OPTIONS = `/api/v1/Operator/get-all`;
export const GET_ALL_SENDER_IDS_OPTIONS = `/notification/api/admin/v1/sender/get-all`;

export const GET_ALL_COSTS_PLANS = `/api/v1/costplan/get-all`;
export const GET_ALL_COSTS_PLANS_PROVIDER = `/api/v1/costplan/get-by-provider`;
export const ADD_COST_PLAN = `/api/v1/costplan`;

export const GET_ALL_PROVIDERS_CATEGORY = `/api/admin/v1/provider/categories`;
export const ADD_RATES = `/api/v1/rateplan`; //done
export const IMPORT_COSTS = `/api/v1/costplan/import`;
export const IMPORT_COSTS_WHATSAPP = `/api/v1/costplan/import-whatsapp-cost-plans`;

export const GET_ALL_CURRENCIES = `/api/v1/Currency/get-all`;
export const GET_ALL_COSTS_RATES = `/api/v1/ratecostplan/get_rate_cost_plans`;
export const GET_ALL_PROFIT_MARGIN = `/api/v1/clientcategoryprofitmargin/get-all`;
export const POPULATE_RATE_PLAN_PROFIT_MARGIN = `/api/v1/rateplan/populate_rate_plan_profit_margin`;

export const DOWNLOAD_COST_SAMPLE = `/api/v1/costplan/download-sample-excel`;
export const DOWNLOAD_COST_WHATSAPP_SAMPLE = `/api/v1/costplan/download-whatsapp-sample-excel`;
export const DOWNLOAD_RATE_SAMPLE = `/api/v1/rateplan/download-sample-excel`;

//configuration
export const GET_ALL_MNC = `/api/admin/v1/MNC/get-all`;
export const CHANGE_STATUS_MNC = `/api/admin/v1/MNC/toggle-status`;
export const GET_ALL_PREFIXES = `/api/admin/v1/Prefix/get-all`;
export const GET_ALL_OPERATORS = `/api/admin/v1/Operator/get-all`;
export const ADD_MNC = `/api/admin/v1/MNC`;
export const DELETE_MNC = `/api/admin/v1/MNC`;
export const ADD_PREFIX = `/api/admin/v1/Prefix`;
export const CHANGE_STATUS_PREFIXES = `/api/admin/v1/Prefix/toggle-status`;
export const ADD_OPERATOR = `/api/admin/v1/Operator`;
export const CHANGE_STATUS_OPERATORS = `/api/admin/v1/Operator/toggle-status`;
export const DELETE_PREFIXES = `/api/admin/v1/Prefix`;
export const DELETE_OPERATORS = `/api/admin/v1/Operator`;
export const CHANGE_STATUS_MAIN = `/api/admin/v1/MNC/toggle-is-main`;
export const EXPORT_PREFIXES = `/api/admin/v1/Prefix/export-prefixes-csv`;
export const IMPORT_PREFIX_FILE = `/api/admin/v1/Prefix/import-prefixes-csv`;
export const DOWNLOAD_PREFIX_SAMPLE = `/api/admin/v1/Prefix/download-prefix-sample-csv`;

//dataSeed
export const GET_ALL_ZONE = `/api/admin/v1/Zone/get-system`;
export const GET_ALL_CRITERIA_CATEGORY = `/api/admin/v1/Criteria/category/get-all`;

export const GET_ALL_COUNTRY = `/api/admin/v1/Country/get-system`;
export const GET_ALL_CITY = `/api/admin/v1/City/get-system`;
export const ADD_ZONE = `/api/admin/v1/Zone`;
export const CHANGE_STATUS_ZONE = `/api/admin/v1/Zone/toggle-status`;
export const CHANGE_STATUS_COUNTRY = `/api/admin/v1/Country/toggle-status`;

export const GET_ALL_CLIENTS = `/api/admin/v1/client/get-all`;
export const CHANGE_STATUS_CLIENT = `/api/admin/v1/client/set-active-status`;
export const DELETE_CLIENT = `/api/admin/v1/client`;
export const ADD_CLIENT = `/api/admin/v1/client`;

export const NON_ADMIN_MEMBER_URL = `${process.env.REACT_APP_API_NON_ADMIN_DEFAULT_MEMBER_URL}`;
export const GET_ALL_INDUSTRY = `/api/v1/user/get-client-users`;
export const GET_ALLUSERS = `/api/v1/user/get-client-users`;
export const GET_ALL_SMS_ONE_WAY = `/api/admin/v1/RoutePlan/OneWay`;
export const ADD_SMS_ONE_WAY = `/api/admin/v1/RoutePlan/OneWay`;
export const GET_ALL_PROVIDER = `/catalog/api/v1/Service/GetProviderByService`;
export const DELETE_SMS = `/api/admin/v1/RoutePlan/OneWay`;
export const CHANGE_STATUS_BLOCK = `/api/admin/v1/RoutePlan/OneWay`;
export const CHANGE_STATUS_LOCK = `/api/admin/v1/RoutePlan/OneWay`;
export const MULTI_DELETE = `/api/admin/v1/RoutePlan/OneWay`;
export const GET_ALL_SERVICES = `/catalog/api/v1/Service/GetAll`;
export const EXPORT_SMS_ONE_WAY = `/api/admin/v1/RoutePlan/OneWay/Export/Csv`;
export const TWO_WAY = `/api/admin/v1/RoutePlan/TwoWay/LongCode`;
export const EXPORT_SMS_TWO_WAY_LONG = `/api/admin/v1/RoutePlan/TwoWay/LongCode/Export/Csv`;
export const TWO_WAY_SHORT_CODE = `/api/admin/v1/RoutePlan/TwoWay/ShortCode`;
export const EXPORT_SMS_TWO_WAY_SHORT = `/api/admin/v1/RoutePlan/TwoWay/ShortCode/Export/Csv`;
export const DELETE_ZONE = `/api/admin/v1/Zone`;
export const EXPORT_ZONE = `/api/admin/v1/Zone/export-zones-csv`;
export const EXPORT_COUNTRY = `/api/admin/v1/Country/export-countries-csv`;
export const EXPORT_CITY = `/api/admin/v1/City/export-cities-csv`;
export const MANAGE_CITY = `/api/admin/v1/City`;
export const CHANGE_STATUS_CATEGORY = `/api/admin/v1/Category/toggle-status`;
export const CHANGE_STATUS_CITY = `/api/admin/v1/City/toggle-status`;
export const MANAGE_CRITERIA_CATEGORY = `/api/admin/v1/Criteria/category`;
export const GET_ALL_CRITERIA_TYPE = `/api/admin/v1/Criteria/type/get-all`;
export const GET_ALL_CRITERIA = `/api/admin/v1/Criteria/get-all`;
export const GET_ALL_CURRENCIES_DATA_SEED = `/api/admin/v1/Currency/get-system`;
export const GET_ALL_LANGUAGES_DATA_SEED = `/api/admin/v1/language/get-system`;
export const CHANGE_STATUS_LANG_RL = `/api/admin/v1/Language/toggle-is-rtl`;
export const CHANGE_STATUS_LANG_STATUS = `/api/admin/v1/Language/toggle-status`;
export const CHANGE_STATUS_LANG_TR = `/api/admin/v1/Language/toggle-used-for-translation`;
export const CHANGE_STATUS_CURRENCIES = `/api/admin/v1/Currency/toggle-status`;
export const EXPORT_LANGUAGES = `/api/admin/v1/language/export-languages-csv`;
export const EXPORT_CURRENCIES = `/api/admin/v1/Currency/export-currencies-csv`;
export const MANAGE_CURRENCIES = `/api/admin/v1/Currency`;
export const MANAGE_LANGUAGES = `/api/admin/v1/Language`;
export const GET_ALL_ACCOUNTS_TYPE = `/api/v1/AccountType/get-all`;
export const GET_ALL_ACCOUNTS_STATUS = `/api/v1/AccountStatus/get-all`;
export const EXPORT_ACCOUNTS_TYPE = `/api/v1/AccountType/export-to-excel`;

export const EXPORT_ACCOUNTS_STATUS = `/api/v1/AccountStatus/export-to-excel`;
export const GET_ALL_LANGUAGES = `/api/v1/language/get-all`;
export const MANAGE_TAXES = `/api/v1/tax/add`;
export const UPDATE_TAXES = `/api/v1/tax/update`;
export const DELETE_TAXES = `/api/v1/tax/delete`;
export const EXPORT_TAXES = `/api/v1/tax/export-to-excel`;

export const GET_ALL_TAX_TYPE = `/api/v1/TaxType/gettaxTypes`;

export const GET_ALL_TAX_CATEGORY = `/api/v1/TaxCategory/get-all`;
export const GET_ALL_TAXES = `/api/v1/tax/gettaxes`;
export const GET_ALL_EXCHANGE_RATES = `/api/v1/exchangerate/get-all`;

export const GET_ALL_CLIENT_ACCOUNTS = `/api/admin/v1/clientaccount/get-all`;
export const MANAGE_EXCHANGE_RATES_DELETE = `/api/v1/exchangerate/delete-exchange-rate`;
export const MANAGE_EXCHANGE_RATES_ADD = `/api/v1/exchangerate/add-exchange-rate`;
export const MANAGE_EXCHANGE_RATES_UPDATE = `/api/v1/exchangerate/update-exchange-rate`;
export const GET_ALL_SYSYTEM_INFO = `/api/v1/Tenant/get-default-config`;
export const EXPORT_EXCHANGE_RATES = `/api/v1/exchangerate/export-to-excel`;
export const EXPORT_MNC = `/api/admin/v1/MNC/export-mncs-csv`;
export const EXPORT_OPERATORS = `/api/admin/v1/Operator/export-operators-csv`;

export const MANAGE_CATEGORY = `/api/admin/v1/Category`;
export const GET_ALL_USERS = `/api/admin/v1/user/get-all`;
export const DELETE_USER = `/api/admin/v1/user`;
export const ADD_USER = "/api/admin/v1/user/contact";
export const SELECTED_USER = "/api/admin/v1/user";
export const ASSIGN_ROLE = "/api/admin/v1/user/assign-role";
export const GET_ALL_REASONS = "/api/admin/v1/criteria/get-all";
export const GET_ALL_CONTACT_TYPE = `/api/v1/criteria/get-all?=null`;

export const GET_ALL_MNP_HLR = `/api/admin/v1/RoutePlan/NumberLookup`;
export const EXPORT_MNP_HLR = `/api/admin/v1/RoutePlan/NumberLookup/Export/Csv`;
export const MANAGE_MNP_HLR = `/api/admin/v1/RoutePlan/NumberLookup`;
export const GET_ALL_CTIES = `/api/v1/city/get-all`;
export const GET_ALL_INDUSTRIES = `/api/admin/v1/Industry/get-all`;

export const ADD_PROVIDER = `/api/admin/v1/provider/add-default-type-without-login`;
export const EDIT_PROVIDER = `/api/admin/v1/provider`;

export const GET_ALL_CRITERIA_SPECIFIC = `/api/v1/criteria/get-all`;

export const GET_ALL_PROVIDERS = `/api/admin/v1/provider/get-all`;
export const GET_PROVIDER_ID = `/api/admin/v1/provider/info`;
export const GET_CLIENT_BY_ID = `/api/admin/v1/client`;
export const GET_CLIENT_ADDRESS_BY_ID = `/api/v1/address/client`;

export const DELETE_PROVIDER = `/api/admin/v1/provider`;
export const EXPORT_PROVIDER = `/api/admin/v1/provider/export-csv`;
export const DELETE_PROVIDER_CATEGORY = `/api/admin/v1/providercategory`;
export const EXPORT_PROVIDER_CATEGORY = `/api/admin/v1/providercategory/export-csv`;

export const SMPP_CONFIG = `/api/admin/v1/SmppConfig`;

export const GET_MNPHLR_BY_ID = `/api/admin/v1/LookupConfiguration/get-all`;
export const GET_SMPP_BY_ID = `/api/admin/v1/SmppConfig/get-all`;
export const GET_SMTP_BY_ID = `/api/admin/v1/SmtpConfiguration/get-all`;
export const LOOKUP_CONFIG = `/api/admin/v1/LookupConfiguration`;
export const SMTP_CONFIG =`/api/admin/v1/SmtpConfiguration`;
export const WHATSAPP_CONFIG =`/api/admin/v1/WhatsappConfig`;
export const RESET_PASSWORD =`/api/v1/user/custom-reset-password`;
export const SET_LOGIN =`/api/admin/v1/Login/add-login`;
export const GET_PIN =`/api/v1/user/request-reset-password`;
export const GET_ACCOUNTS_BY_PROVIDER =`/api/v1/provideraccount/get-all-by-provider`;
export const GET_ACCOUNTS_BY_CLIENT =`/api/v1/clientaccount/get-all-by-client`;

export const GET_ALL_CHANNELS =`/api/admin/v1/Channel/get-all`;
export const GET_ALL_PROVIDER_CATEGORY =`/api/admin/v1/providercategory/get-all`;
export const MANAGE_PROVIDER_CATEGORY =`/api/admin/v1/providercategory`;
export const ASSIGN_CATEGORY_PROVIDER =`/api/admin/v1/ProviderCategory/assign-provider`;
export const UNASSIGN_CATEGORY_PROVIDER =`/api/admin/v1/ProviderCategory/unassign-Provider`;
export const EXPORT_COUNTRY_TO_EXCEL = `/api/admin/v1/Country/export-countries-excel`;
export const EXPORT_LANGUAGES_TO_EXCEL = `/api/admin/v1/language/export-languages-excel`;
export const CHANGE_STATUS_LOCK_RATES = `/api/v1/rateplan/lock-unlock-rate-plan`;

export const GET_ALL_MISSING_RATES = `/api/v1/rateplan/get-missing-rate-plans-by-client-category`; 

//Reseller APIs
export const ADD_NEW_RESELLER = "/api/admin/v1/client";
export const UPDATE_RESELLER = "/api/admin/v1/client";
export const GET_ADDRESS = "/api/v1/address/client";
export const GET_ALL_RESELLERS = "/api/admin/v1/client/get-all";
export const GET_SAAS_CONFIG = "/api/admin/v1/client/get-saas-config";
export const GET_ALL_CATEGORIES = "/api/admin/v1/Category/get-all";
export const UPLOAD_MEDIA = "/media/api/v1/File/upload-image";
export const GET_MEDIA_PATHS = "/media/api/v1/Config/get-config-media-paths";


export const EXPORT_MISSING_RATES = `/api/v1/rateplan/export-missing-rate-plan-to-excel`; 

export const MANAGE_REASONS = `/api/admin/v1/Criteria/add-routing-reasons`; 
export const Delete_REASON = `/api/admin/v1/Criteria/delete-routing-reasons`; 
export const MANAGE_REASONS_UPDATE = `/api/admin/v1/Criteria/update-routing-reasons`; 
export const EXPORT_CLIENTS_CSV = `/api/admin/v1/client/export-csv`; 

export const GET_WHATSAPP_BY_ID = `/api/admin/v1/WhatsappConfig/get-all`;
export const GET_ALL_ITEM_SHORT_CODE = `/api/v1/Item/GetAll`;
export const GET_ALL_SERVICE = `/api/v1/Service/GetAll`;
export const GET_ALL_SERVICE_CATEGORY = `/api/v1/ServiceCategory/GetAll`;
export const GET_ALL_SERVICE_TYPE = `/api/v1/ServiceType/GetAll`;
export const UPDATE_SERVICE = `/api/v1/Service`;
export const ADD_SERVICE = `/api/v1/Service/add-service-basic`;

export const DELETE_SERVICE = `/api/v1/Service`;


export const POPULATE_WHATSAPP = `/api/v1/rateplan/populate_whatsapp_rate_plan_profit_margin`;
export const DELETE_SERVICE_CATEGORY = `/api/v1/ServiceCategory`;
export const DELETE_SERVICE_TYPE = `/api/v1/ServiceType`;
export const ADD_SERVICE_CATEGORY = `/api/v1/ServiceCategory/add-service-category-basic`;
export const UPDATE_SERVICE_CATEGORY = `/api/v1/ServiceCategory`;
export const ADD_BANKING_DETAILS = `/api/v1/bankinginfo/add-client-banking-info`;
export const ADD_SERVICE_TYPE = `/api/v1/ServiceType`;
export const UPDATE_SERVICE_TYPE = `/api/v1/ServiceType`;
export const MANAGE_ITEM = `/api/v1/Item`;
export const GET_ALL_ITEM_CATEGORY = `/api/v1/ItemCategory/GetAll`;
export const GET_ALL_ITEM_TYPE = `/api/v1/ItemType/GetAll`;

export const DELETE_ITEM = `/api/v1/Item`;

export const GET_BANKING_INFO = `/api/v1/bankinginfo/get-banking-info-by-client`;
export const UPDATE_BANKING_INFO = `/api/v1/bankinginfo/update-banking-info`;
export const UPDATE_STATUS_SERVICE = `/api/v1/Service/Status`;

