import { FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";

const ManageTaxes = ({ open, setOpen, title, onButtonClick }) => {
  const [ProviderCategory, setProviderCategory] = useState("");
  const [ProviderCategoryOptions, setProviderCategoryOptions] = useState([
    "default",
    "vip",
  ]);
  const [Country, setCountry] = useState("");
  const [CountryOptions, setCountryOptions] = useState([
    "Country 1",
    "Country 2",
    "Country 3",
    "Country 4",
  ]);
  const [Operator, setOperator] = useState("");
  const [OperatorOptions, setOperatorOptions] = useState([
    "Operator 1",
    "Operator 2",
    "Operator 3",
    "Operator 4",
  ]);
  const [Cost, setCost] = useState("");
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm
                value={ProviderCategory}
                setValue={setProviderCategory}
                options={ProviderCategoryOptions}
                placeholder={"ProviderCategory"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm
                value={Country}
                setValue={setCountry}
                options={CountryOptions}
                placeholder={"Country"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <SelectForm
                value={Operator}
                setValue={setOperator}
                options={OperatorOptions}
                placeholder={"Operator"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Cost"}
                value={Cost}
                setValue={setCost}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageTaxes;
