import Add from "@mui/icons-material/Add";
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Typography
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import ManageOtp from "./ManageOtp";
const OTPStoreConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  // OTPStoreConfig
  const [isModalOpenSwitchOTPStoreConfig, setModalOpenOTPStoreConfig] = useState(false);
  const [isCheckedSwitchOTPStoreConfig, setCheckedSwitchOTPStoreConfig] = useState(false);
  const [switchStatesOTPStoreConfig, setswitchStatesOTPStoreConfig] = useState({});
  const handleSwitchChangeSwitchOTPStoreConfig = (rowId) => {
    setModalOpenOTPStoreConfig(true);
    setCheckedSwitchOTPStoreConfig(rowId);
  };

  const handleModalCloseSwitchOTPStoreConfig = () => {
    setModalOpenOTPStoreConfig(false);
  };
  const handleModalConfirmSwitchOTPStoreConfig = () => {
    setModalOpenOTPStoreConfig(false);
    // setCheckedSwitchOTPStoreConfig(!isCheckedSwitchOTPStoreConfig);
    setswitchStatesOTPStoreConfig((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchOTPStoreConfig]: !prevStates[isCheckedSwitchOTPStoreConfig],
    }));
  };

  //

 

  const [Client, setClient] = React.useState("9mobile");

  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [Category, setCategory] = React.useState("9mobile");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const tableDataOTPStoreConfig = [
  ];
  let columnsOTPStoreConfig = [
    {
      field: "client",
      headerName: " Client",
      flex: 1,
    },
    {
      field: "MinLenght",
      headerName: "Min Length",
      flex: 1,
    },
    {
      field: "MaxLenght",
      headerName: "Max Length",
      flex: 1,
    },
    {
      field: "KeyLenght",
      headerName: "Key Length",
      flex: 1,
    },
    {
      field: "TimeToLive",
      headerName: "Time To Live",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesOTPStoreConfig[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchOTPStoreConfig(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  // Delete Modal OTPStoreConfig functions
  const [ConfirmOpenOTPStoreConfig, setConfirmOpenOTPStoreConfig] = useState(false);
  const [modalContentOTPStoreConfig, setmodalContentOTPStoreConfig] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalOTPStoreConfig = (title, text, onButtonClick) => {
    setmodalContentOTPStoreConfig({ title, text, onButtonClick });
    setConfirmOpenOTPStoreConfig(true);
  };

  const handleConfirmModalOTPStoreConfig = () => {
    setConfirmOpenOTPStoreConfig(false);
  };

  //
  // Manage Routing
  const [ManageOTPStoreConfigAddopen, setManageOTPStoreConfigAddopen] = useState(false);
  const [ManageOTPStoreConfigEditopen, setManageOTPStoreConfigEditopen] = useState(false);

  const handleAddManageOTPStoreConfigopen = () => {
    setManageOTPStoreConfigAddopen(true);
  };

  const handleAddMangeOTPStoreConfigclose = () => {
    setManageOTPStoreConfigAddopen(false);
  };
  const handleEditManageOTPStoreConfigopen = () => {
    setManageOTPStoreConfigEditopen(true);
  };

  const handleEditMangeOTPStoreConfigclose = () => {
    setManageOTPStoreConfigEditopen(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} className="centerresponsive">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
              <Typography className="BreadcrumbsPage">OTP Store Config</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6} className="end">
            <Button
              variant="contained"
              className="contained"
              onClick={handleAddManageOTPStoreConfigopen}
            >
              <Add sx={{ fontSize: "17px"}}  /> &nbsp;
              <Typography sx={{ fontSize: "14px",marginRight: "3px"  }}>Add OTP Store Config</Typography>
            </Button>
        </Grid>
      </Grid>
      <Grid container className="Top-spacing div-table">
        <Grid item xs={12}>
            <Paper variant="CardTable" sx={{borderTopLeftRadius:'20px'}}>
              <Table
                rowCountState={tableDataOTPStoreConfig?.totalCount}
                data={tableDataOTPStoreConfig}
                // isLoading={isLoading}
                columns={columnsOTPStoreConfig}
                pageIndex={
                  tableDataOTPStoreConfig?.pageIndex ? tableDataOTPStoreConfig?.pageIndex : 0
                }
                setPaginationModel={setPaginationModel}
                paginationModel={paginationModel}
                enableDelete={true}
                enableEdit={true}
                deleteFunction={OpenConfirmModalOTPStoreConfig}
                editFunction={handleEditManageOTPStoreConfigopen}
                enableSearch={true}
                enableExportBtn={true}
                valueFilter={Client}
                setValueFilter={setClient}
                optionsFilter={["9mobile", "hassan", "test2"]}
                // placeholderFilter="Client"
                enableFilter={true}
                // enableFilterBtn={true}
              />
          </Paper>
        </Grid>
      </Grid>

      {/* Delete OTPStoreConfig Modal */}
      {handleConfirmModalOTPStoreConfig && (
        <ConfirmationModal
          open={ConfirmOpenOTPStoreConfig}
          onClose={handleConfirmModalOTPStoreConfig}
          title="Delete"
          text="Are you sure you want to delete this OTP Store Config?"
          onButtonClick={handleConfirmModalOTPStoreConfig}
        />
      )}
     

      {/* Manage OTPStoreConfig Modal */}
      {handleAddManageOTPStoreConfigopen && (
        <ManageOtp
          open={ManageOTPStoreConfigAddopen}
          setOpen={setManageOTPStoreConfigAddopen}
          title={"Add OTP Store Config"}
        />
      )}
      {handleEditManageOTPStoreConfigopen && (
        <ManageOtp
          open={ManageOTPStoreConfigEditopen}
          setOpen={setManageOTPStoreConfigEditopen}
          title={"Edit OTP Store Config"}
        />
      )}

      {/* Swtich OTPStoreConfig Modal */}
      {isModalOpenSwitchOTPStoreConfig && (
        <ConfirmationModal
          open={isModalOpenSwitchOTPStoreConfig}
          onClose={handleModalCloseSwitchOTPStoreConfig}
          title="Change Status"
          text="Are you sure you want to change this OTP Store Config Status?"
          onButtonClick={handleModalConfirmSwitchOTPStoreConfig}
        />
      )}
      <div>
      </div>
    </div>
  );
};

export default OTPStoreConfig;
