import { Checkbox, FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectForm from "../../../Components/Selects/SelectsForm";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";

const ManageWebhooks = ({ open, setOpen, title, onButtonClick }) => {
  const [Tag, setTag] = useState("");
  const [url, setUrl] = useState("");

  const [TagOptions, setTagOptions] = useState([
    "tag 1",
    "tag 2",
    "tag 3",
    "tag 4",
  ]);
  const [name, setName] = useState("");
  const [ParentID, setParentID] = useState("");
  const [Country, setCountry] = useState("");
  const [CountryOptions, setCountryOptions] = useState([
    "Canada",
    "Lebanon",
    "China",
    "Brasil",
  ]);
  const [IsoCode, setIsoCode] = useState("");
  const [Zone, setZone] = useState("");
  const [Mcc, setMcc] = useState("");
  const [IsoCode3, setIsoCode3] = useState("");
  const [TimeZoneID, setTimeZoneID] = useState("");
  const [switchState, setSwitchState] = useState(false);

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      firstActionBtnName={"Save"}
      // firstActionBtnFct={()=>console.log("test")}
      // secondActionBtnName={"Cancel"}
      content={
        <>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Server"}
                value={name}
                setValue={setName}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Port"}
                value={IsoCode3}
                setValue={setIsoCode3}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"UserName"}
                value={TimeZoneID}
                setValue={setTimeZoneID}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Password"}
                value={Mcc}
                setValue={setMcc}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <TextFieldForm
                placeholder={"Url"}
                value={url}
                setValue={setUrl}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className="dialog-grid-spacing" item xs={12}>
              <FormControlLabel
                control={<Checkbox 
style={{color:"#C61035"}} defaultChecked />}
                label="SMTP SSL"
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ManageWebhooks;
