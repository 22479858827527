export const loginConfig = {
  count: 2,
  page: 1,
  projects: [
    {
      id: 1,
      name: "Omni-Channel",
      PolicyId: "ebea483e-3890-4c84-ac12-889c4b4170c6",
      enviroments: [
        {
          id: 1,
          name: "Software Dev",
          baseUrl: "https://mm-omni-api-software-dev.montylocal.net",
          tenants: ["3d936a5d-1d56-450b-a04c-f1a7b5c2d5d4"],
          services: [
            {
              id: "3",
              name: "channels-management",
              endpointName: "channels-management",
              endpoint: "/channels-management",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                  name: "Channels",
                  icon: "SettingsApplications",
                  path: "channels-management",
                  parent_page_name: [null],
                  endpointName: "routing",
                  endpoint: "/routing",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26f5",
                      name: "Dashboard",
                      icon: "key",
                      path: "channels-dashboard",
                      parentPageName: "Channels",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    // {
                    //   pageId: "c8ab6209-5464-4f95-b2e9-c111405f2614",
                    //   name: "Routing",
                    //   icon: "settingsSuggest",
                    //   path: "routes-services",
                    //   parentPageName: "Channels",
                    //   parent_page_name: [
                    //     "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                    //   ],
                    // },

                    // {
                    //   pageId: "c8ab6209-5464-4f95-b2e9-c111405f26f3",
                    //   name: "SMS",
                    //   icon: "Sms",
                    //   path: "sms-one-way-management",
                    //   parentPageName: "Channels",

                    //   parent_page_name: [
                    //     "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                    //   ],
                    // },
                    // {
                    //   pageId: "c8ab6209-5464-4f95-b2e9-c111405f2612",
                    //   name: "SMS Two Way",
                    //   icon: "Chat",
                    //   path: "sms-two-way-management",
                    //   parentPageName: "Channels",

                    //   parent_page_name: [
                    //     "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                    //   ],
                    // },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f2677",
                      name: "Push",
                      icon: "PushPin",
                      path: "push-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f2687",
                      name: "Whatsapp",
                      icon: "WhatsApp",
                      path: "whatsapp-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f2655",
                      name: "Viber",
                      icon: "Vibration",
                      path: "viber-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                  ],
                },
              ],
            },

            // {
            //   pageId: "50b076bc-4e5f-4df4-b480-10aadbebe425",
            //   name: "Channels",
            //   endpoint: "/channels-management",
            //   endpointName: "channels-management",
            //   icon: "key",
            //   path: "channels-management",
            //   parent_page_name: [null],
            // },

            {
              id: "3",
              name: "providers-management",
              endpointName: "providers-management",
              endpoint: "/providers-management",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                  name: "Providers",
                  icon: "SettingsApplications",
                  path: "providers-mg-dashboard",
                  parent_page_name: [null],
                  endpointName: "routing",
                  endpoint: "/routing",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "New Provider",
                      icon: "PersonAdd",
                      path: "new-provider",
                      parentPageName: "Providers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                      name: "Dashboard",
                      icon: "insights",
                      path: "providers-mg-dashboard",
                      parentPageName: "Providers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "Providers",
                      icon: "groups",
                      path: "providers-management",
                      parentPageName: "Providers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                      ],
                    },
                  ],
                },
              ],
            },

            {
              id: "3",
              name: "resellers-management",
              endpointName: "resellers-management",
              endpoint: "/resellers-management",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                  name: "Resellers",
                  icon: "ManageAccounts",
                  path: "resellers-mg-dashboard",
                  parent_page_name: [null],
                  endpointName: "resellers-management",
                  endpoint: "/resellers-management",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                      name: "Dashboard",
                      icon: "insights",
                      path: "resellers-mg-dashboard",
                      parentPageName: "Resellers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "Resellers",
                      icon: "store",
                      path: "resellers-management",
                      parentPageName: "Resellers",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                  ],
                },
              ],
            },

            {
              id: "3",
              name: "clients-management",
              endpointName: "catalog",
              endpoint: "/catalog",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                  name: "Clients",
                  icon: "ManageAccounts",
                  path: "clients-mg-dashboard",
                  parent_page_name: [null],
                  endpointName: "clients-management",
                  endpoint: "/clients-management",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                      name: "Dashboard",
                      icon: "insights",
                      path: "clients-mg-dashboard",
                      parentPageName: "Clients",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "Clients",
                      icon: "groups",
                      path: "clients-management",
                      parentPageName: "Clients",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                  ],
                },
              ],
            },

            // {
            //   id: "2",
            //   endpointName: "resellers-config",
            //   endpoint: "/resellers-config",

            //   pages: [
            //     {
            //       pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
            //       name: "Resellers Config",
            //       icon: "engineering",
            //       path: "resellers-config",
            //       parent_page_name: [null],
            //       hasChildren: false,
            //     },
            //   ],
            // },
            // {
            //   id: "2",
            //   endpointName: "clients-config",
            //   endpoint: "/clients-config",

            //   pages: [
            //     {
            //       pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
            //       name: "Clients Config",
            //       icon: "engineering",
            //       path: "clients-config",
            //       parent_page_name: [null],
            //       hasChildren: false,
            //     },
            //   ],
            // },



            {
              id: "5",
              name: "routing",
              endpointName: "routing",
              endpoint: "/routing",
              pages: [
                {
                  pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
                  name: "Routing",
                  icon: "engineering",
                  path: "routing",
                  parent_page_name: [null],
                  endpointName: "resellers-management",
                  endpoint: "/resellers-management",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f261f",
                      name: "Routing Config",
                      icon: "engineering",
                      path: "routing",
                      parentPageName: "Routing",
                      parent_page_name: [
                        "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9184-4980c403e6b8",
                      name: "Reasons",
                      icon: "store",
                      path: "routing-reasons",
                      parentPageName: "Routing",
                      parent_page_name: [
                        "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
                      ],
                    },
                  ],
                },
              ],
            },

            {
              id: "3",
              name: "billing",
              endpointName: "billing",
              endpoint: "/billing",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                  name: "Billing",
                  icon: "MonetizationOn",
                  path: "billing-configuration",
                  parent_page_name: [null],
                  endpointName: "billing",
                  endpoint: "/billing",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f76ff",
                      name: "Configuration",
                      icon: "currencyExchange",
                      path: "billing-configuration",
                      parentPageName: "Billing",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e618",
                      name: "Pricing",
                      icon: "priceChange",
                      path: "billing-pricing",
                      parentPageName: "Billing",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd0q",
                      ],
                    },
                  ],
                },
              ],
            },

            // {
            //   id: "1",
            //   endpointName: "members",
            //   endpoint: "/member",
            //   pages: [
            //     {
            //       pageId: "50b076bc-4e5f-4df4-b480-10aadbebe420",
            //       name: "Api Key Service",
            //       endpoint: "/apikey",
            //       endpointName: "apiKey",
            //       icon: "key",
            //       path: "api-key-service",
            //       parent_page_name: [null],
            //     },
            //     {
            //       pageId: "b023cb43-9663-4dfc-960e-e3b4b3a2de8d",
            //       name: "Catalog",
            //       icon: "book",
            //       endpoint: "/catalog",
            //       endpointName: "catalog",
            //       path: "catalog-management",
            //       parent_page_name: [null],
            //     },
            //   ],
            // },

            {
              id: "1",
              endpointName: "members",
              endpoint: "/member",
              pages: [
                {
                  pageId: "0ba00fc2-3f8f-4e95-a1e3-e81eab5fd071",
                  name: "Users",
                  endpoint: "/member",
                  endpointName: "member",
                  icon: "groups",
                  path: "users-management",
                  parent_page_name: [null],
                },
              ],
            },
            {
              id: "2",
              endpointName: "configuration",
              endpoint: "/configuration",

              pages: [
                {
                  pageId: "71210e8f-f226-4ef8-bc08-d7e793915eb8",
                  name: "Configuration",
                  icon: "settings",
                  path: "configuration-management",
                  parent_page_name: [null],
                },
              ],
            },

            {
              id: "6",
              name: "dataSeed",
              endpointName: "notification",
              endpoint: "/notification",

              pages: [
                {
                  pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c4",
                  name: "Data Seed",
                  icon: "emojiObjects",
                  path: "data-seed",
                  parent_page_name: [null],
                  hasChildren: false,
                },
              ],
            },

            {
              id: "2",
              endpointName: "roles",
              endpoint: "/roles",

              pages: [
                {
                  pageId: "71210e8f-f226-4ef8-bc08-d7e791915eb8",
                  name: "Roles",
                  icon: "settings",
                  path: "Roles",
                  parent_page_name: [null],
                },
              ],
            },
          ],
        },
        {
          id: 1,
          name: "Software QA",
          tenants: ["3d936a5d-1d56-450b-a04c-f1a7b5c2d5d4"],
          baseUrl: "https://mm-omni-api-software-qa.montylocal.net",
          services: [
            {
              id: "3",
              name: "channels-management",
              endpointName: "channels-management",
              endpoint: "/channels-management",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                  name: "Channels",
                  icon: "SettingsApplications",
                  path: "channels-management",
                  parent_page_name: [null],
                  endpointName: "routing",
                  endpoint: "/routing",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26f5",
                      name: "Dashboard",
                      icon: "key",
                      path: "channels-dashboard",
                      parentPageName: "Channels",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26f3",
                      name: "SMS",
                      icon: "Sms",
                      path: "sms-one-way-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f2612",
                      name: "SMS Two Way",
                      icon: "Chat",
                      path: "sms-two-way-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f2677",
                      name: "Push",
                      icon: "PushPin",
                      path: "push-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f2687",
                      name: "Whatsapp",
                      icon: "WhatsApp",
                      path: "whatsapp-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f2655",
                      name: "Viber",
                      icon: "Vibration",
                      path: "viber-management",
                      parentPageName: "Channels",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9k",
                      ],
                    },
                  ],
                },
              ],
            },

            // {
            //   pageId: "50b076bc-4e5f-4df4-b480-10aadbebe425",
            //   name: "Channels",
            //   endpoint: "/channels-management",
            //   endpointName: "channels-management",
            //   icon: "key",
            //   path: "channels-management",
            //   parent_page_name: [null],
            // },

            {
              id: "3",
              name: "providers-management",
              endpointName: "providers-management",
              endpoint: "/providers-management",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                  name: "Providers",
                  icon: "SettingsApplications",
                  path: "providers-mg-dashboard",
                  parent_page_name: [null],
                  endpointName: "routing",
                  endpoint: "/routing",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "New Provider",
                      icon: "PersonAdd",
                      path: "new-provider",
                      parentPageName: "Providers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                      ],
                    },
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                      name: "Dashboard",
                      icon: "insights",
                      path: "providers-mg-dashboard",
                      parentPageName: "Providers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "Providers",
                      icon: "groups",
                      path: "providers-management",
                      parentPageName: "Providers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9e",
                      ],
                    },
                  ],
                },
              ],
            },

            {
              id: "3",
              name: "resellers-management",
              endpointName: "resellers-management",
              endpoint: "/resellers-management",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                  name: "Resellers",
                  icon: "ManageAccounts",
                  path: "resellers-mg-dashboard",
                  parent_page_name: [null],
                  endpointName: "resellers-management",
                  endpoint: "/resellers-management",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                      name: "Dashboard",
                      icon: "insights",
                      path: "resellers-mg-dashboard",
                      parentPageName: "Resellers",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "Resellers",
                      icon: "store",
                      path: "resellers-management",
                      parentPageName: "Resellers",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                  ],
                },
              ],
            },

            {
              id: "3",
              name: "clients-management",
              endpointName: "clients-management",
              endpoint: "/clients-management",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                  name: "Clients",
                  icon: "ManageAccounts",
                  path: "clients-mg-dashboard",
                  parent_page_name: [null],
                  endpointName: "clients-management",
                  endpoint: "/clients-management",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                      name: "Dashboard",
                      icon: "insights",
                      path: "clients-mg-dashboard",
                      parentPageName: "Clients",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "Clients",
                      icon: "groups",
                      path: "clients-management",
                      parentPageName: "Clients",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9q",
                      ],
                    },
                  ],
                },
              ],
            },

            // {
            //   id: "2",
            //   endpointName: "routing",
            //   endpoint: "/routing",

            //   pages: [
            //     {
            //       pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c8",
            //       name: "Routing",
            //       icon: "engineering",
            //       path: "routing",
            //       parent_page_name: [null],
            //       hasChildren: false,
            //     },
            //   ],
            // },
            {
              id: "2",
              endpointName: "billing",
              endpoint: "/billing",

              pages: [
                {
                  pageId: "71210e8f-f226-4ef8-bc08-d7e793915eb1",
                  name: "Billing",
                  icon: "receipt",
                  path: "billing-service",
                  parent_page_name: [null],
                  // hasChildren: true,
                },
              ],
            },

            // {
            //   id: "1",
            //   endpointName: "members",
            //   endpoint: "/member",
            //   pages: [
            //     {
            //       pageId: "50b076bc-4e5f-4df4-b480-10aadbebe420",
            //       name: "Api Key Service",
            //       endpoint: "/apikey",
            //       endpointName: "apiKey",
            //       icon: "key",
            //       path: "api-key-service",
            //       parent_page_name: [null],
            //     },
            //     {
            //       pageId: "b023cb43-9663-4dfc-960e-e3b4b3a2de8d",
            //       name: "Catalog",
            //       icon: "book",
            //       endpoint: "/catalog",
            //       endpointName: "catalog",
            //       path: "catalog-management",
            //       parent_page_name: [null],
            //     },
            //   ],
            // },

            {
              id: "1",
              endpointName: "members",
              endpoint: "/member",
              pages: [
                {
                  pageId: "0ba00fc2-3f8f-4e95-a1e3-e81eab5fd071",
                  name: "Users",
                  endpoint: "/member",
                  endpointName: "member",
                  icon: "groups",
                  path: "users-management",
                  parent_page_name: [null],
                },
              ],
            },
            {
              id: "2",
              endpointName: "configuration",
              endpoint: "/configuration",

              pages: [
                {
                  pageId: "71210e8f-f226-4ef8-bc08-d7e793915eb8",
                  name: "Configuration",
                  icon: "settings",
                  path: "configuration-management",
                  parent_page_name: [null],
                },
              ],
            },

            {
              id: "6",
              name: "dataSeed",
              endpointName: "data-seed",
              endpoint: "/data-seed",

              pages: [
                {
                  pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c4",
                  name: "Data Seed",
                  icon: "emojiObjects",
                  path: "data-seed",
                  parent_page_name: [null],
                  hasChildren: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Push Notification",
      policyId: "9290f912-c1bc-447d-a3d1-047b2ba07b7d",

      enviroments: [
        {
          id: 1,
          name: "Software Dev",
          baseUrl: "https://mm-omni-api-software-dev.montylocal.net",
          tenants: ["4b529bfb-4732-427e-a655-fbc1ce8b54d2"],
          services: [
            {
              id: "1",
              endpointName: "members",
              endpoint: "/member",
              pages: [
                {
                  pageId: "50b076bc-4e5f-4df4-b480-10aadbebe420",
                  name: "Api Key Service",
                  endpoint: "/apikey",
                  endpointName: "apiKey",
                  icon: "key",
                  path: "api-key-service",
                  parent_page_name: [null],
                },
                {
                  pageId: "b023cb43-9663-4dfc-960e-e3b4b3a2de8d",
                  name: "Catalog",
                  icon: "book",
                  endpoint: "/member",
                  endpointName: "member",
                  path: "catalog-management",
                  parent_page_name: [null],
                },
              ],
            },
            {
              id: "1",
              endpointName: "members",
              endpoint: "/member",
              pages: [
                {
                  pageId: "0ba00fc2-3f8f-4e95-a1e3-e81eab5fd071",
                  name: "Users",
                  endpoint: "/member",
                  endpointName: "member",
                  icon: "users",
                  path: "users-management",
                  hasChildren: true,
                  parent_page_name: [null],
                },
              ],
            },
            {
              id: "2",
              endpointName: "configuration",
              endpoint: "/configuration",

              pages: [
                {
                  pageId: "71210e8f-f226-4ef8-bc08-d7e793915eb8",
                  name: "Configurations",
                  icon: "cog",
                  path: "tenant-management",
                  parent_page_name: [null],
                  hasChildren: true,
                },
                {
                  pageId: "9f2c9a26-2ee6-4160-a443-bc16dac9a8ff",
                  name: "Tenant",
                  icon: "wrench",
                  path: "tenant-management",
                  parent_page_name: ["71210e8f-f226-4ef8-bc08-d7e793915eb8"],
                },
              ],
            },

            {
              id: "6",
              name: "dataSeed",
              endpointName: "data-seed",
              endpoint: "/data-seed",

              pages: [
                {
                  pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c4",
                  name: "Data Seed",
                  icon: "seedling",
                  path: "data-seed",
                  parent_page_name: [null],
                  hasChildren: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "BPal",
      policyId: "9290f912-c1bc-447d-a3d1-047b2ba07b7d",

      enviroments: [
        {
          id: 1,
          name: "Software Dev",
          baseUrl: "https://mm-omni-api-software-dev.montylocal.net",
          tenants: ["0337dd34-7efb-4bb7-a6a2-e7ea9b208108"],
          services: [
            {
              id: "1",
              endpointName: "members",
              endpoint: "/member",
              pages: [
                {
                  pageId: "50b076bc-4e5f-4df4-b480-10aadbebe420",
                  name: "Api Key Service",
                  endpoint: "/apikey",
                  endpointName: "apiKey",
                  icon: "key",
                  path: "api-key-service",
                  parent_page_name: [null],
                },
                {
                  pageId: "b023cb43-9663-4dfc-960e-e3b4b3a2de8d",
                  name: "Catalog",
                  icon: "book",
                  endpoint: "/member",
                  endpointName: "member",
                  path: "catalog-management",
                  parent_page_name: [null],
                },
              ],
            },
            {
              id: "1",
              endpointName: "members",
              endpoint: "/member",
              pages: [
                {
                  pageId: "0ba00fc2-3f8f-4e95-a1e3-e81eab5fd071",
                  name: "Users",
                  endpoint: "/member",
                  endpointName: "member",
                  icon: "users",
                  path: "users-management",
                  hasChildren: true,
                  parent_page_name: [null],
                },
              ],
            },
            {
              id: "2",
              endpointName: "configuration",
              endpoint: "/configuration",

              pages: [
                {
                  pageId: "71210e8f-f226-4ef8-bc08-d7e793915eb8",
                  name: "Configurations",
                  icon: "cog",
                  path: "tenant-management",
                  parent_page_name: [null],
                  hasChildren: true,
                },
                {
                  pageId: "9f2c9a26-2ee6-4160-a443-bc16dac9a8ff",
                  name: "Tenant",
                  icon: "wrench",
                  path: "tenant-management",
                  parent_page_name: ["71210e8f-f226-4ef8-bc08-d7e793915eb8"],
                },
              ],
            },
            {
              id: "6",
              name: "dataSeed",
              endpointName: "data-seed",
              endpoint: "/data-seed",

              pages: [
                {
                  pageId: "e79aa4c7-7179-4977-8fba-4aff8b49b4c4",
                  name: "Data Seed",
                  icon: "seedling",
                  path: "data-seed",
                  parent_page_name: [null],
                  hasChildren: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "KeyStore",
      policyId: "9290f912-c1bc-447d-a3d1-047b2ba07b7d",

      enviroments: [
        {
          id: 1,
          name: "Software Dev",
          baseUrl: "https://mm-omni-api-software-dev.montylocal.net",
          tenants: ["3d936a5d-1d56-450b-a04c-f1a7b5c2d5d4"],
          services: [
            {
              id: "3",
              name: "shortUrl",
              endpointName: "members",
              endpoint: "/member",
              pages: [
                {
                  pageId: "9b56914b-1de3-43dc-b9b1-dc65e2aecd9b",
                  name: "Short URL",
                  icon: "link",
                  path: "url-store-config",
                  parent_page_name: [null],
                  endpointName: "members",
                  endpoint: "/member",
                  hasChildren: true,
                  children: [
                    {
                      pageId: "c8ab6209-5464-4f95-b2e9-c111405f26ff",
                      name: "URL Store",
                      icon: "link",
                      path: "url-store",
                      parentPageName: "Short URL",

                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9b",
                      ],
                    },
                    {
                      pageId: "9a752c2f-2b00-4e16-9c84-4980c403e6b8",
                      name: "URL Store Config",
                      icon: "storage",
                      path: "url-store-config",
                      parent_page_name: [
                        "9b56914b-1de3-43dc-b9b1-dc65e2aecd9b",
                      ],
                      parentPageName: "Short URL",
                    },
                  ],
                },
              ],
            },

            {
              id: "4",
              name: "otp",
              endpointName: "keystore",
              endpoint: "/keystore",

              pages: [
                {
                  pageId: "b78d3ff1-f5ef-4673-9706-ec8a823b7aaf",
                  name: "OTP",
                  icon: "key",
                  path: "otp-store-config",
                  parent_page_name: [null],
                  hasChildren: true,
                  children: [
                    {
                      parentPageName: "OTP",
                      pageId: "0b00cb11-b456-486a-b684-3f5f011f6e60",
                      name: "OTP Store Config",
                      path: "otp-store-config",
                      icon: "storage",
                      parent_page_name: [
                        "b78d3ff1-f5ef-4673-9706-ec8a823b7aaf",
                      ],
                    },
                    {
                      pageId: "8c8146d3-5fda-43ee-b2a2-9dd71c82ae3d",
                      name: "Pin",
                      icon: "room",
                      path: "pin",
                      parentPageName: "OTP",
                      parent_page_name: [
                        "b78d3ff1-f5ef-4673-9706-ec8a823b7aaf",
                      ],
                    },
                  ],
                },
                // },
              ],
            },
          ],
        },
      ],
    },
  ],
};
