import axios from "axios";
import axiosInstance from "../axiosInstance";
import { MULTI_TENANCY_CONFIG } from "../Service-Manager/APIs";
// import { MEMBER_URL, NEWCONFIG, POLICY_LINK } from "./index_new";
let newServices = JSON.parse(localStorage.getItem("services"));

let memberUrl = `${localStorage.getItem("baseUrl")}${newServices&&newServices["member"]}/api/admin/v1`;
MULTI_TENANCY_CONFIG.headers.Authorization =
"Bearer " + localStorage.getItem("token");
MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
export const GET_ALL_POLICIEs_API = async ({ token }) => {

  try {
    return await axiosInstance.get(`${memberUrl}/policy/get-all`,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(`${memberUrl}/policy/get-all`,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const ADD_NEW_ROLE = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(`${memberUrl}/policy`, formData,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(`${memberUrl}/policy`, formData,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const DELETE_ROLE = async ({ token, id }) => {
  try {
    return await axiosInstance.delete(`${memberUrl}/Policy?Id=${id}`,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.delete(`${memberUrl}/Policy?Id=${id}`,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const GET_ALL_SCOPES_API = async ({ token }) => {
  try {
    return await axiosInstance.get(`${memberUrl}/Menu/get-scopes`,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(`${memberUrl}/Menu/get-scopes`,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const UPDATE_MENU_ORDER = async ({ token, formData }) => {

  try {
    return await axiosInstance.put(`${memberUrl}/Menu/menu-order`, formData,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.put(`${memberUrl}/Menu/menu-order`, formData,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const UPDATE_MENU = async ({ token, formData }) => {
  try {
    return await axiosInstance.put(`${memberUrl}/Menu`, formData,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.put(`${memberUrl}/Menu`, formData,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const ADD_MENU_ACTION = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(`${memberUrl}/Menu/menu-action`, formData,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(
        `${memberUrl}/Menu/menu-action`,
        formData
      ,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const SAVE_PERMISSIONS = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(`${memberUrl}/permission`, formData, MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(`${memberUrl}/permission`, formData, MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const ASSIGN_ACTION_SCOPES = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(
      `${memberUrl}/Menu/assign-menu-action-scope`,
      formData
    ,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(
        `${memberUrl}/Menu/assign-menu-action-scope`,
        formData
      ,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const DELETE_MENU_ACTION = async ({ token, menu }) => {
  try {
    return await axiosInstance.delete(
      `${memberUrl}/Menu/menu-action?RecordGuid=${menu}`
    ,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.delete(
        `${memberUrl}/Menu/menu-action?RecordGuid=${menu}`
      ,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};

export const DELETE_MENU = async ({ token, menu }) => {
  try {
    return await axiosInstance.delete(`${memberUrl}/Menu?RecordGuid=${menu}`,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.delete(`${memberUrl}/Menu?RecordGuid=${menu}`,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};
export const ADD_MENU = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(`${memberUrl}/Menu`, formData,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(`${memberUrl}/Menu`, formData,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};
export const GET_ALL_MENU_API = async ({ token, policy }) => {
  try {
    return await axiosInstance.get(
      `${memberUrl}/permission/get-policy-menu-map?PolicyName=${policy}`,MULTI_TENANCY_CONFIG);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `${memberUrl}/permission/get-policy-menu-map?PolicyName=${policy}`,MULTI_TENANCY_CONFIG);
    else throw e;
  }
};
