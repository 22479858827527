import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { config } from "react-spring";
import Carousel from "react-spring-3d-carousel";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
// CustomPrevArrow component
const CustomPrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{ position: "absolute", top: "50%", left: 10, zIndex: 1 }}
  >
    {"<"}
  </button>
);

// CustomNextArrow component
const CustomNextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{ position: "absolute", top: "50%", right: 10, zIndex: 1 }}
  >
    {">"}
  </button>
);

export default function CarouselComponent(props) {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(4);
  const [showArrows, setShowArrows] = useState(true);
  const [goToSlide, setGoToSlide] = useState(0);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
        prevArrow={<CustomPrevArrow />}
        nextArrow={<CustomNextArrow />}
      />
      {props.isTablet&&
      <div style={{bottom:"11%",position:"relative",zIndex:"100"}}>
      <IconButton
        onClick={() => {
          setGoToSlide(goToSlide - 1);
        }}
      >
        <ArrowCircleLeftIcon style={{fontSize:"32px",color:"white"}} />
      </IconButton>
      {/* <Button
        onClick={() => {
          setGoToSlide(goToSlide - 1);
        }}
      >
        Left Arrow
      </Button> */}
      &nbsp; &nbsp; &nbsp; &nbsp;
      <IconButton
        onClick={() => {
          setGoToSlide(goToSlide + 1);
        }}
      >
        <ArrowCircleRightIcon style={{fontSize:"32px",color:"white"}} />
      </IconButton>
    </div>
      
      }
      
    </div>
  );
}
