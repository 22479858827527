import { Box, FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export default function TextFieldForm({ value, setValue,placeholder,adornment }) {
  return (
    <Box sx={{ width: "100%"}}>
       {placeholder&& <span style={{color:"#B3B3B3",fontSize:"15px"}}>{placeholder}</span>}

      <FormControl
        sx={{
          width: "100%",
          borderRadius: "32px",
          border:"1px solid #E0E0E0 "
        }}
      >
        <OutlinedInput
          size="small"
          id="header-search"
          value={value}
          multiline
          rows={2}
          maxRows={4}
          onChange={(e) => setValue(e.target.value)}
          aria-describedby="header-search-text"
          inputProps={{
            "aria-label": "weight",
          }}
          startAdornment={adornment&&
            <InputAdornment position="start">
              <PersonOutlineIcon />
            </InputAdornment>
          }
          // endAdornment={adornment&&
          //   <InputAdornment position="end">
          //     <PersonOutlineIcon />
          //   </InputAdornment>
          // }
        //   placeholder={placeholder}
        />
      </FormControl>
    </Box>
  );
}
