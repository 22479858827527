import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../../Components/Dialog/Dialog";
import SelectFormNew from "../../../Components/Selects/SelectFormNew";
import TextFieldForm from "../../../Components/TextFields/TextFieldForm";
import axiosInstance from "../../../axiosInstance";
import {
  ADD_SERVICE,
  GET_ALL_ITEM_CATEGORY,
  GET_ALL_ITEM_TYPE,
  GET_ALL_PROVIDERS,
  GET_ALL_SERVICE_CATEGORY,
  GET_ALL_SERVICE_TYPE,
  MANAGE_ITEM,
  MULTI_TENANCY_CONFIG,
  UPDATE_SERVICE,
} from "../../APIs";

const ManageLongCode = ({
  open,
  setOpen,
  title,
  isEdit,
  setRefreshTableLongCode,
  setOpenAlert,
  setSearchService,
  selectedLongCode,
  paginationModalLongCode,
  setPaginationModalLongCode,
  currenciesOptions,
  CountryOptions,
}) => {
  let services = localStorage.getItem("services");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");

  const [messageData, setMessageData] = useState({ message: "", type: "" });
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [serviceCategoryOptions, setServiceCategoryOptions] = useState(false);
  const [serviceTypeOptions, setServiceTypeOptions] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [provider, setProvider] = useState("");

  const [currency, setCurrency] = useState("");
  const [nbOfSubscription, setNbOfSubscription] = useState("");
  const [nbOfUnSubscription, setNbOfUnSubscription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [isAutoRenew, setIsAutoRenew] = useState(false);
  const [isOneTime, setIsOneTime] = useState(false);
  const [isApi, setIsApi] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [providerOptions, setProviderOptions] = useState(false);

  const [serviceCategory, setServiceCategory] = useState(false);
  const [price, setPrice] = useState("");
  const [country, setCountry] = useState("");

  const [cost, setCost] = useState("");
  const handleSwitchChangeActive = () => {
    setIsActive(!isActive);
  };

  const handleSwitchChange = () => {
    setSwitchState(!switchState);
  };
  const handleSwitchChangePublished = () => {
    setIsPublished(!isPublished);
  };

  const handleSwitchChangeAutoRenew = () => {
    setIsAutoRenew(!isAutoRenew);
  };

  const handleSwitchChangeOneTime = () => {
    setIsOneTime(!isOneTime);
  };
  const handleSwitchChangeApi = () => {
    setIsApi(!isApi);
  };

  const handleStartDateChange = (value) => {
    if (endDate && endDate >= value) {
      setStartDate(value);
    } else {
      if (endDate === "") {
        setStartDate(value);
      } else {
        // setStartDate("");
      }
      // setStartDate(value);

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  const handleEndDateChange = (value) => {
    if (startDate <= value) {
      setEndDate(value);
    } else {
      setEndDate("");

      // Alert the user or handle the validation error appropriately
      // alert('End date must be later than start date');
    }
  };
  const submitAdd = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      tag: tag,
      itemCategoryGuid: serviceCategory,
      ItemTypeTag: "LONG-CODE",
      price: parseFloat(price),
      cost: parseFloat(cost),
      currencyCode: currency,
      statusTag: "ACTIVE",
      isPublished: isPublished,
      isAutoRenew: isAutoRenew,
      isOneTime: isOneTime,
      publishFrom: startDate,
      publishTo: endDate,
      countryTag: country,
      providerGuid: provider,
      isActive: isActive,
    };
    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${MANAGE_ITEM}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setName("");
        setOpenMessageAlert(true);
        setMessageData({
          message: data?.message || "Successfully Added",
          type: "success",
        });
        setRefreshTableLongCode(true);
        setOpen(false);
        setPaginationModalLongCode({
          page: 0,
          pageSize: paginationModalLongCode?.pageSize
            ? paginationModalLongCode?.pageSize
            : 10,
        });
        // setOpenAlert(true);
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };
  useEffect(() => {
    getAllProviders();
    getAllItemCategory();
    // getAllItemType();
    {
      !isEdit && setName("");
      setSwitchState(true);
    }
  }, [open]);

  const getAllProviders = () => {
    // setSelectedRows([]);
    // setRefreshTable(false);
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    let data = {
      pageIndex: 1,
      pageSize: 500,
      search: "",
      typeTag: "",
    };

    axiosInstance
      .post(
        `${localStorage.getItem("baseUrl")}${
          newServices["members"]
        }${GET_ALL_PROVIDERS}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setProviderOptions(
          res?.data?.data?.providers.map((x) => ({
            label: x?.name,
            value: x?.recordGuid,
          }))
        );
        // setPaginationModel({
        //   ...paginationModel,
        //   page: res?.data?.data?.pageIndex,
        // });
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllProviders();
        }
      })
      .finally(() => {});
  };
  const submitEdit = () => {
    let newServices = JSON.parse(services);
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    MULTI_TENANCY_CONFIG.headers = {
      ...MULTI_TENANCY_CONFIG.headers,
    };
    let data = {
      recordGuid: selectedLongCode?.recordGuid,
      price: parseFloat(price),
      currencyCode: currency,

      SERVICEDETAILS: [
        {
          LANGUAGECODE: "EN",
          NAME: name,
          DESCRIPTION: description,
        },
      ],
    };
    axiosInstance
      .put(
        `${localStorage.getItem("baseUrl")}${
          newServices["catalog"]
        }${UPDATE_SERVICE}`,
        data,
        MULTI_TENANCY_CONFIG
      )
      .then(({ data }) => {
        setName("");
        setOpenMessageAlert(true);
        setMessageData({
          message: data?.message || "Successfully Added",
          type: "success",
        });
        setRefreshTableLongCode(true);
        setOpen(false);
        setPaginationModalLongCode({
          page: 0,
          pageSize: paginationModalLongCode?.pageSize
            ? paginationModalLongCode?.pageSize
            : 10,
        });
        // setOpenAlert(true);
      })
       .catch((err) => {
        if (err === "error: 401") {
          submitAdd();
        } else {
          setOpenMessageAlert(true);
          setMessageData({
            message: err?.response?.data?.Message
              ? err?.response?.data?.Message
              : err?.response?.data?.message,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    console.log("selectedLongCode", selectedLongCode);
    if (isEdit) {
      // setPrice(selectedLongCode?.price);
      // setName(
      //   selectedLongCode?.serviceDetails &&
      //     selectedLongCode?.serviceDetails?.length !== 0
      //     ? selectedLongCode?.serviceDetails[0]?.name
      //     : ""
      // );
      // setDescription(
      //   selectedLongCode?.serviceDetails &&
      //     selectedLongCode?.serviceDetails?.length !== 0
      //     ? selectedLongCode?.serviceDetails[0]?.description
      //     : ""
      // );
      // setCurrency(selectedLongCode?.currency?.code);
    } else {
      setServiceCategory("");
      setCurrency("");
      setPrice("");
      setCost("");
      setTag("");
      setCountry("");
      setIsPublished(false);
      setIsAutoRenew(false);
      setIsOneTime(false);
      setIsApi(false);
      setIsActive(false);
      setProvider("");
      setStartDate("");
      setEndDate("");
    }
  }, [open, isEdit]);

  const getAllItemCategory = () => {
    let service = JSON.parse(services);
    // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
    MULTI_TENANCY_CONFIG.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
    MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
    axiosInstance
      .get(
        `${localStorage.getItem("baseUrl")}${
          service["catalog"]
        }${GET_ALL_ITEM_CATEGORY}`,
        MULTI_TENANCY_CONFIG
      )
      .then((res) => {
        setServiceCategoryOptions(
          res?.data?.data?.items.map((x) => ({
            label: x?.tag,
            value: x?.recordGuid,
          }))
        );
      })
      .catch((err) => {
        if (err === "error: 401") {
          getAllItemCategory();
        }
        // toast.error(
        //   err?.response?.data?.message ??
        //     "An Error Ocurred Retrieving Countries"
        // );
      })
      .finally(() => {
        // setState({ loading: false, resetPagination: false });
      });
  };
  // const getAllItemType = () => {
  //   let service = JSON.parse(services);
  //   // configuration.headers.Authorization = "Bearer " + localStorage.getItem("token");;
  //   MULTI_TENANCY_CONFIG.headers.Authorization =
  //     "Bearer " + localStorage.getItem("token");
  //   MULTI_TENANCY_CONFIG.headers.Tenant = localStorage.getItem("tenant");
  //   axiosInstance
  //     .get(
  //       `${localStorage.getItem("baseUrl")}${
  //         service["catalog"]
  //       }${GET_ALL_ITEM_TYPE}`,
  //       MULTI_TENANCY_CONFIG
  //     )
  //     .then((res) => {
  //       setServiceTypeOptions(
  //         res?.data?.data?.items.map((x) => ({
  //           label: x?.tag,
  //           value: x?.recordGuid,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       if (err === "error: 401") {
  //         getAllItemType();
  //       }
  //       // toast.error(
  //       //   err?.response?.data?.message ??
  //       //     "An Error Ocurred Retrieving Countries"
  //       // );
  //     })
  //     .finally(() => {
  //       // setState({ loading: false, resetPagination: false });
  //     });
  // };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessageAlert(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <CustomizedDialogs
        open={open}
        setOpen={setOpen}
        title={title}
        firstActionBtnName={"Save"}
        firstActionBtnFct={isEdit ? submitEdit : submitAdd}
        disableFirstBtn={
          isEdit
            ? tag === "" ||
              serviceCategory === "" ||
              price === "" ||
              cost === "" ||
              currency === "" ||
              startDate === "" ||
              endDate === "" ||
              country === "" ||
              provider === ""
            : tag === "" ||
              serviceCategory === "" ||
              price === "" ||
              cost === "" ||
              currency === "" ||
              startDate === "" ||
              endDate === "" ||
              country === "" ||
              provider === ""
        }
        // secondActionBtnName={"Cancel"}
        content={
          <>
            {!isEdit ? (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Longcode"}
                      value={tag}
                      setValue={setTag}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Description"}
                      value={description}
                      setValue={setDescription}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Setup fee (Price)"}
                      value={price}
                      setValue={setPrice}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Cost"}
                      value={cost}
                      setValue={setCost}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={serviceCategory}
                      setValue={setServiceCategory}
                      options={serviceCategoryOptions}
                      placeholder={"Item Category"}
                      disabled={serviceCategoryOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={provider}
                      setValue={setProvider}
                      options={providerOptions}
                      placeholder={"Provider"}
                      disabled={providerOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={country}
                      setValue={setCountry}
                      options={CountryOptions}
                      placeholder={"Country"}
                      disabled={CountryOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={currency}
                      setValue={setCurrency}
                      options={currenciesOptions}
                      placeholder={"Currency"}
                      disabled={currenciesOptions?.length === 0}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Publish From"}
                      value={startDate}
                      // setValue={setStartDate}
                      onChangeFct={handleStartDateChange}
                      type="date"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Publish To"}
                      value={endDate}
                      // setValue={setEndDate}
                      onChangeFct={handleEndDateChange}
                      type="date"
                    />
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                          }}
                        >
                          Published
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isPublished}
                              onChange={handleSwitchChangePublished}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                            marginRight: "20px",
                          }}
                        >
                          Auto renew
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isAutoRenew}
                              onChange={handleSwitchChangeAutoRenew}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                          }}
                        >
                          One Time
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isOneTime}
                              onChange={handleSwitchChangeOneTime}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                            marginRight: "20px",
                          }}
                        >
                          Api
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isApi}
                              onChange={handleSwitchChangeApi}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: "5px" }} container>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#B3B3B3",
                        fontSize: "15px",
                      }}
                    >
                      Active
                    </span>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControlLabel
                      style={{ marginTop: "-5px" }}
                      control={
                        <Switch
                          checked={isActive}
                          onChange={handleSwitchChangeActive}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Longcode"}
                      value={tag}
                      setValue={setTag}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Setup fee (Price)"}
                      value={price}
                      setValue={setPrice}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Cost"}
                      value={cost}
                      setValue={setCost}
                      positiveNumberFloat={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={serviceCategory}
                      setValue={setServiceCategory}
                      options={serviceCategoryOptions}
                      placeholder={"Item Category"}
                      disabled={serviceCategoryOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={provider}
                      setValue={setProvider}
                      options={providerOptions}
                      placeholder={"Provider"}
                      disabled={providerOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={country}
                      setValue={setCountry}
                      options={CountryOptions}
                      placeholder={"Country"}
                      disabled={CountryOptions?.length === 0}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <SelectFormNew
                      value={currency}
                      setValue={setCurrency}
                      options={currenciesOptions}
                      placeholder={"Currency"}
                      disabled={currenciesOptions?.length === 0}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Publish From"}
                      value={startDate}
                      // setValue={setStartDate}
                      onChangeFct={handleStartDateChange}
                      type="date"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid className="dialog-grid-spacing" item xs={12}>
                    <TextFieldForm
                      placeholder={"Publish To"}
                      value={endDate}
                      // setValue={setEndDate}
                      onChangeFct={handleEndDateChange}
                      type="date"
                    />
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                          }}
                        >
                          Published
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isPublished}
                              onChange={handleSwitchChangePublished}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                            marginRight: "20px",
                          }}
                        >
                          Auto renew
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isAutoRenew}
                              onChange={handleSwitchChangeAutoRenew}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                          }}
                        >
                          One Time
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isOneTime}
                              onChange={handleSwitchChangeOneTime}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span
                          style={{
                            color: "#B3B3B3",
                            fontSize: "15px",
                            marginRight: "20px",
                          }}
                        >
                          Api
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          style={{ marginTop: "-5px" }}
                          control={
                            <Switch
                              checked={isApi}
                              onChange={handleSwitchChangeApi}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: "5px" }} container>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#B3B3B3",
                        fontSize: "15px",
                      }}
                    >
                      Active
                    </span>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControlLabel
                      style={{ marginTop: "-5px" }}
                      control={
                        <Switch
                          checked={isActive}
                          onChange={handleSwitchChangeActive}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        }
      />
      <Snackbar
        style={{ marginTop: "40px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        open={openMessageAlert}
        autoHideDuration={3000}
        action={action}
      >
        <Alert
          variant="filled"
          severity={messageData?.type}
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          {messageData?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageLongCode;
