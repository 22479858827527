import Add from "@mui/icons-material/Add";
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import ConfirmationModal from "../../../Components/Dialog/ConfirmationModal";
import Table from "../../../Components/DynamicTable/Table";
import SwipeRightAltIcon from "@mui/icons-material/SwipeRightAlt";
import AddReseller from "./ManageURLStoreCon";
const ResellersConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [tableData, setTableData] = useState({ pageIndex: 1 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  // ResellersConfig
  const [isModalOpenSwitchURLStoreConfig, setModalOpenURLStoreConfig] =
    useState(false);
  const [isCheckedSwitchURLStoreConfig, setCheckedSwitchURLStoreConfig] =
    useState(false);
  const [switchStatesURLStoreConfig, setswitchStatesURLStoreConfig] = useState(
    {}
  );
  const handleSwitchChangeSwitchURLStoreConfig = (rowId) => {
    setModalOpenURLStoreConfig(true);
    setCheckedSwitchURLStoreConfig(rowId);
  };

  const handleModalCloseSwitchURLStoreConfig = () => {
    setModalOpenURLStoreConfig(false);
  };
  const handleModalConfirmSwitchURLStoreConfig = () => {
    setModalOpenURLStoreConfig(false);
    // setCheckedSwitchURLStoreConfig(!isCheckedSwitchURLStoreConfig);
    setswitchStatesURLStoreConfig((prevStates) => ({
      ...prevStates,
      [isCheckedSwitchURLStoreConfig]:
        !prevStates[isCheckedSwitchURLStoreConfig],
    }));
  };

  //

  const [Client, setClient] = React.useState("9mobile");
  const [isCheckedSwitchLocationZone, setCheckedSwitchLocationZone] =
    useState(false);
  const [isModalOpenSwitchLocationZone, setModalOpenSwitchLocationZone] =
    useState(false);
  const handleChangeClient = (event) => {
    setClient(event.target.value);
  };
  const [Category, setCategory] = React.useState("9mobile");
  const handleSwitchChangeSwitchLocationZone = (rowId) => {
    setModalOpenSwitchLocationZone(true);
    setCheckedSwitchLocationZone(rowId);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const [switchStatesLocationZone, setSwitchStatesLocationZone] = useState({});

  const tableDataURLStoreConfig = [
    {
      id: 1,
      name: "Hamza Jomaa",
      username: "hamzajomaa99",
      email: "hamza.montymobile@gmail.com",
    },
    {
      id: 1,
      name: "Naim Jaber",
      username: "naim.jaber800",
      email: "naimjaber800@gmail.com",
    },
  ];
  let columnsURLStoreConfig = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStatesLocationZone[rowId] || false}
                  onChange={() => handleSwitchChangeSwitchLocationZone(rowId)}
                />
              }
            />
          </Box>
        );
      },
      flex: 1,
    },
  ];

  // Delete Modal ResellersConfig functions
  const [ConfirmOpenURLStoreConfig, setConfirmOpenURLStoreConfig] =
    useState(false);
  const [modalContentURLStoreConfig, setmodalContentURLStoreConfig] = useState({
    onButtonClick: () => {
      console.log("Button Clicked");
    },
  });
  const OpenConfirmModalURLStoreConfig = (title, text, onButtonClick) => {
    setmodalContentURLStoreConfig({ title, text, onButtonClick });
    setConfirmOpenURLStoreConfig(true);
  };

  const handleConfirmModalURLStoreConfig = () => {
    setConfirmOpenURLStoreConfig(false);
  };

  //
  // Manage ResellersConfig
  const [ManageURLStoreConfigAddopen, setManageURLStoreConfigAddopen] =
    useState(false);
  const [ManageURLStoreConfigEditopen, setManageURLStoreConfigEditopen] =
    useState(false);

  const handleAddManageURLStoreConfigopen = () => {
    setManageURLStoreConfigAddopen(true);
  };
  const handleBackToTable = () => {
    setManageURLStoreConfigAddopen(false);
    setManageURLStoreConfigEditopen(false);
  };
  const handleAddMangeRequestclose = () => {
    setManageURLStoreConfigAddopen(false);
  };
  const handleEditManageURLStoreConfigopen = () => {
    setManageURLStoreConfigEditopen(true);
  };

  const handleEditMangeRequestclose = () => {
    setManageURLStoreConfigEditopen(false);
  };
  const handleModalCloseSwitchLocationZone = () => {
    setModalOpenSwitchLocationZone(false);
  };

  return (
    <div>
      {!ManageURLStoreConfigAddopen && !ManageURLStoreConfigEditopen ? (
        <>
          <Grid container>
            <Grid item xs={12} md={6} className="centerresponsive">
              <Breadcrumbs
                aria-label="breadcr
              umb"
                className="breadcrumb"
              >
                {/* <Typography className="BreadcrumbsPage">
                  Resellers Config
                </Typography> */}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={6} className="end">
              <Button
                variant="contained"
                className="contained"
                onClick={handleAddManageURLStoreConfigopen}
              >
                <Add sx={{ fontSize: "17px" }} /> &nbsp;
                <Typography sx={{ fontSize: "14px", marginRight: "3px" }}>
                  Add ResellersConfig
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container className="Top-spacing div-table">
            <Grid item xs={12}>
              <Paper variant="CardTable">
                <Table
                  rowCountState={tableDataURLStoreConfig?.totalCount}
                  data={tableDataURLStoreConfig}
                  // isLoading={isLoading}
                  columns={columnsURLStoreConfig}
                  pageIndex={
                    tableDataURLStoreConfig?.pageIndex
                      ? tableDataURLStoreConfig?.pageIndex
                      : 0
                  }
                  setPaginationModel={setPaginationModel}
                  paginationModel={paginationModel}
                  enableDelete={true}
                  enableEdit={true}
                  deleteFunction={OpenConfirmModalURLStoreConfig}
                  editFunction={handleEditManageURLStoreConfigopen}
                  enableSearch={true}
                  enableExportBtn={true}
                  valueFilter={Client}
                  setValueFilter={setClient}
                  // optionsFilter={["9mobile", "hassan", "test2"]}
                  // placeholderFilter="Client"
                  // enableFilter={true}
                  // enableFilterBtn={true}
                />
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <AddReseller
            open={ManageURLStoreConfigAddopen}
            setOpen={setManageURLStoreConfigAddopen}
            title={
              ManageURLStoreConfigAddopen ? "New Reseller" : "Edit Reseller"
            }
            handleBackToTable={handleBackToTable}
          />
        </>
      )}

      {/* Delete ResellersConfig Modal */}
      {handleConfirmModalURLStoreConfig && (
        <ConfirmationModal
          open={ConfirmOpenURLStoreConfig}
          onClose={handleConfirmModalURLStoreConfig}
          title="Delete"
          text="Are you sure you want to delete this Reseller?"
          onButtonClick={handleConfirmModalURLStoreConfig}
        />
      )}
      {/* {handleEditManageURLStoreConfigopen && (
        <AddReseller
          open={ManageURLStoreConfigEditopen}
          setOpen={setManageURLStoreConfigEditopen}
          title={"Edit ResellersConfig"}
        />
      )} */}

      {/* Swtich ResellersConfig Modal */}
      {isModalOpenSwitchURLStoreConfig && (
        <ConfirmationModal
          open={isModalOpenSwitchURLStoreConfig}
          onClose={handleModalCloseSwitchURLStoreConfig}
          title="Change Status"
          text="Are you sure you want to change this ResellersConfig Status?"
          onButtonClick={handleModalConfirmSwitchURLStoreConfig}
        />
      )}
      {isModalOpenSwitchLocationZone && (
        <ConfirmationModal
          open={isModalOpenSwitchLocationZone}
          onClose={handleModalCloseSwitchLocationZone}
          title="Change Status"
          text="Are you sure you want to change this Reseller Status?"
          onButtonClick={handleModalCloseSwitchLocationZone}
        />
      )}
      <div></div>
    </div>
  );
};

export default ResellersConfig;
